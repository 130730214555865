// SearchPage.jsx

import React, { useState } from 'react';
import {
  Container,
  Box,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Slider,
  Button,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import { Star } from '@mui/icons-material';

import './dealPicker.scss';

const SearchPage = () => {
  const [category, setCategory] = useState('');
  const [priceRange, setPriceRange] = useState([0, 1000]);
  const [discountRange, setDiscountRange] = useState([0, 50]);
  const [sortOption, setSortOption] = useState('');
  const [isPrime, setIsPrime] = useState(false);
  const [isAdvancedFiltersOpen, setIsAdvancedFiltersOpen] = useState(false);
  const [customerReview, setCustomerReview] = useState(0);
  const [brandSearch, setBrandSearch] = useState('');

  const handleCategoryChange = (event) => {
    setCategory(event.target.value);
  };

  const handleSortOptionChange = (event) => {
    setSortOption(event.target.value);
  };

  const handleCustomerReviewChange = (event, newValue) => {
    setCustomerReview(newValue);
  };

  return (
    <Container className="search-page-container" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '50%' }}>
      <div className="search-box">
        <TextField label="Search" variant="outlined" fullWidth />
      </div>
      <div className="category-dropdown">
        <FormControl variant="outlined" fullWidth>
          <InputLabel>Category</InputLabel>
          <Select value={category} onChange={handleCategoryChange}>
            <MenuItem value="">All</MenuItem>
            <MenuItem value="electronics">Electronics</MenuItem>
            <MenuItem value="clothing">Clothing</MenuItem>
            {/* Add more categories here */}
          </Select>
        </FormControl>
      </div>
      <div className="price-range">
        <Typography>Price Range</Typography>
        <Slider
          value={priceRange}
          onChange={(event, newValue) => setPriceRange(newValue)}
          valueLabelDisplay="auto"
          valueLabelFormat={(value) => `$${value}`}
          min={0}
          max={1000}
        />
      </div>
      <div className="discount-range">
        <Typography>Discount Range</Typography>
        <Slider
          value={discountRange}
          onChange={(event, newValue) => setDiscountRange(newValue)}
          valueLabelDisplay="auto"
          valueLabelFormat={(value) => `${value}%`}
          min={0}
          max={50}
        />
      </div>
      <div className="sort-dropdown">
        <FormControl variant="outlined" fullWidth>
          <InputLabel>Sort by</InputLabel>
          <Select value={sortOption} onChange={handleSortOptionChange}>
            <MenuItem value="relevance">Relevance</MenuItem>
            <MenuItem value="customerReview">Customer Review</MenuItem>
            <MenuItem value="priceLowHigh">Price Low to High</MenuItem>
            <MenuItem value="priceHighLow">Price High to Low</MenuItem>
            <MenuItem value="newestArrival">Newest Arrival</MenuItem>
          </Select>
        </FormControl>
      </div>
      <div className="prime-toggle">
        <ToggleButtonGroup value={isPrime} exclusive onChange={() => setIsPrime(!isPrime)}>
          <ToggleButton value={true}>Prime</ToggleButton>
          <ToggleButton value={false}>All</ToggleButton>
        </ToggleButtonGroup>
      </div>
      <div className="advanced-filters">
        <Button onClick={() => setIsAdvancedFiltersOpen(!isAdvancedFiltersOpen)}>
          {isAdvancedFiltersOpen ? 'Hide Advanced Filters' : 'Show Advanced Filters'}
        </Button>
        {isAdvancedFiltersOpen && (
          <div className="customer-review">
            <Typography>Customer Review</Typography>
            <Slider
              value={customerReview}
              onChange={handleCustomerReviewChange}
              valueLabelDisplay="auto"
              valueLabelFormat={(value) => (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {value} <Star color="primary" />
                </div>
              )}
              min={1}
              max={5}
            />
          </div>
        )}
        {isAdvancedFiltersOpen && (
          <div className="brand-search">
            <TextField label="Brand Search" variant="outlined" fullWidth />
          </div>
        )}
      </div>
    </Container>
  );
};

export default SearchPage;
