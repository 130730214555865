const Input = 
[
  {
    "id": 21083,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683419552",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "10999",
    "dicos": "56",
    "catagory":"Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "Redmi 80 cm (32 inches) Android 11 Series HD Ready Smart LED TV | L32M6-RA/L32M7-RA (Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HIjyog"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42daiRp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42daiRp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42f7sLM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42f7sLM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3piz6sE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VBPPTO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M1offn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M2qzCY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B12qGz"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@10999"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71L-lTQnJiL._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Home Theater, TV & Video \n                Televisions \n                 Smart Televisions \n                 Smart Televisions \nPryce10999 \nDicos56 \n\n❌Regular Price  : ₹   19999  /- 😱"
     }
    ]
   },
   {
    "id": 21084,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683419629",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1299",
    "dicos": "48",
    "catagory":"Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "Intex Musique Style Bluetooth in Ear Wireless Neckband with Up to 24H Playtime ASAP Charge, Dual Connectivity, Inbuilt AI Assistant and Magnet Earbuds Lock (Mint Green)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nC5Ook"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B2j7BG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NLlvUX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HO61vr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NMeX8r"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B3MS4M"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HO636x"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HL9xqq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HLfO5B"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HK8N4V"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1299"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/41xS46U6w3L._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Headphones, Earbuds & Accessories \n                Headphones \n                 In-Ear \n                 In-Ear \nPryce1299 \nDicos48 \n\n❌Regular Price  : ₹ 1999   /- 😱"
     }
    ]
   },
   {
    "id": 21085,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683419722",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1899",
    "dicos": "73",
    "catagory":"Musical",
    "text_entities": [
     {
      "type": "plain",
      "text": "Juârez Acoustic Guitar, 38 Inch Cutaway, 038C with Bag, Strings, Pick and Strap, Black✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HLYVHS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pm4RRi"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/416PI3K"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/416PI3K"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42xSRdQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42qARC4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42OBIwX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pj9Y4K"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42zuvAH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M1ouHj"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1899"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71y1TJbygLL._SY450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Musical"
     },
     {
      "type": "plain",
      "text": " Instruments\n\n                Musical Instruments \n                Guitars, Basses & Gear \n                Guitars, Basses & Kits \n                Guitar & Bass Kits \n                Guitar & Bass Kits \nPryce1899 \nDicos73 \n\n❌Regular Price  : ₹  4999    /- 😱"
     }
    ]
   },
   {
    "id": 21086,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683419797",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "254",
    "dicos": "85",
    "catagory":"Luggage",
    "text_entities": [
     {
      "type": "plain",
      "text": "INSTABUYZ Boys and Girls Fabric School Bag✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42AkXFD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nw3teH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NKNlAo"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NTyRya"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44D0MZq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NKNoMA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NKNoMA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nw3zD5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NJmEMu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NGdWyJ"
     },
     {
      "type": "plain",
      "text": "\n\n @254\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/91tzXTRWbRL._UX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Luggage"
     },
     {
      "type": "plain",
      "text": " & Bags\n\n                Bags, Wallets and Luggage \n                Bags & Backpacks \n                 School Bags & Sets \nPryce254 \nDicos85 \n\n❌Regular Price  : ₹ 999   /- 😱"
     }
    ]
   },
   {
    "id": 21087,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683419849",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "828",
    "dicos": "45",
    "catagory":"Tools",
    "text_entities": [
     {
      "type": "plain",
      "text": "AmazonBasics Screwdriver and Nut Driver Bit Set - 48-Piece, Chrome Vanadium and S2 Steel✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42f7zaa"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42d3NOf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NGDoUM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VU58aJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NH2J0V"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41b4BSr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NK0LwE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VBQ0hW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LzVmpg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41d3bH2"
     },
     {
      "type": "plain",
      "text": "\n\n @828\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71c8LHflEML._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Tools"
     },
     {
      "type": "plain",
      "text": " & Home Improvement\n\n                Industrial & Scientific \n                Power & Hand Tools \n                Power Tool Accessories \n                 Screwdriver Accessories \n                 Screwdriver Accessories \nPryce828 \nDicos45 \n\n❌Regular Price  : ₹  1500   /- 😱"
     }
    ]
   },
   {
    "id": 21088,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683419922",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "299",
    "dicos": "85",
    "catagory":"Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "Tygot Adjustable Aluminium Alloy Tripod Stand Holder for Mobile Phones & Camera, 360 mm -1060 mm (42 Inch), 1/4 inch Screw + Mobile Holder Bracket✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LCBy4F"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LEYa4y"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M3amgO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LHwc83"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LIhE8s"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pbvzw1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LCBEJz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42w2R7r"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M1XszC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M1XszC"
     },
     {
      "type": "plain",
      "text": "\n\n @299\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51jx8hycZUL._SY450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Cameras & Photography \n                Accessories \n                Tripods & Monopods \n                Tripods & Monopods \nPryce299 \nDicos85 \n\n❌Regular Price  : ₹  1399  /- 😱"
     }
    ]
   },
   {
    "id": 21089,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683420000",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "130",
    "dicos": "67",
    "catagory":"Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "ZEBRONICS Zeb-Bro Wired Earphone (White)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44t7WiG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44uMg5X"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pdhEFQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44DeF9I"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NOIZIC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NGDDiE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pja6Bg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/417fFQx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41b2Jtc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VE2eXb"
     },
     {
      "type": "plain",
      "text": "\n\n @130\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51a2VoP4P8L._SY879_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Headphones, Earbuds & Accessories \n                Headphones \n                 In-Ear \n                 In-Ear \nPryce130 \nDicos67 \n\n❌Regular Price  : ₹   399  /- 😱"
     }
    ]
   },
   {
    "id": 21090,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683420075",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "338",
    "dicos": "58",
    "catagory":"Tools",
    "text_entities": [
     {
      "type": "plain",
      "text": "EcoLink Multiplug Socket 6A || Pack of 4✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42daznp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nCjH5P"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nAe5Jg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pfqZgi"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42f7L9o"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42cdD36"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44yVApj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42oqA9S"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44DeXxk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42daGzl"
     },
     {
      "type": "plain",
      "text": "\n\n @338\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61CjkYbJCdL._SX466_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Tools"
     },
     {
      "type": "plain",
      "text": " & Home Improvement\n\n                Home Improvement \n                Electrical \n                 Adapters & Multi-Outlets \nPryce338 \nDicos58 \n\n❌Regular Price  : ₹  799   /- 😱"
     }
    ]
   },
   {
    "id": 21091,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683420153",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "139",
    "dicos": "65",
    "catagory":"Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "ZEBRONICS Zeb-Bro in Ear Wired Earphones with Mic, 3.5mm Audio Jack, 10mm Drivers, Phone/Tablet Compatible(Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HKAudM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HLXmd8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VGiB5I"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VE7Y3c"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nJIXqN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VLr8V6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B13hHh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pelnmG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M42Cvd"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AXInsJ"
     },
     {
      "type": "plain",
      "text": "\n\n @139\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/31DYx7AhW6L._SX300_SY300_QL70_FMwebp_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Headphones, Earbuds & Accessories \n                Headphones \n                 In-Ear \n                 In-Ear \nPryce139 \nDicos65 \n\n❌Regular Price  : ₹   399  /- 😱"
     }
    ]
   },
   {
    "id": 21092,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683420231",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "2749",
    "dicos": "58",
    "catagory":"Tools",
    "text_entities": [
     {
      "type": "plain",
      "text": "Amazon Basics Clamber Pro - Foldable Step Ladder, Firm Grip, 5 Steps (Aluminium Steps, Blue & Satin)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M1QWJf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nzXPYL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NKNHHe"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3BkoNqR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B4BcPr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HLUP2w"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HLagrE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NOo1JV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HMbfYq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HKAFps"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@2749"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51MVXxnZ8RL._SY879_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Tools"
     },
     {
      "type": "plain",
      "text": " & Home Improvement\n\n                Home & Kitchen \n                Home & kitchen | Prime shopping days \n                Prime Units \n                 HI \n                 HI \nPryce2749 \nDicos58 \n\n❌Regular Price  : ₹  4999   /- 😱"
     }
    ]
   },
   {
    "id": 21093,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683420301",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1259",
    "dicos": "79",
    "catagory":"Musical",
    "text_entities": [
     {
      "type": "plain",
      "text": "Boya by-MM1 Super-Cardioid Shotgun Microphone with Real Time Monitoring Compatible with iPhone/Android Smartphones, DSLR Cameras Camcorders for Live Streaming Audio Recording✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HMB5eX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41e3PV1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41cSynT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HLFe2M"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pjwTNm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M47MHA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M47MHA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41gwE2W"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M47Ozc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41bYrSf"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1259"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51T6FEDoEvL._SX450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Musical"
     },
     {
      "type": "plain",
      "text": " Instruments\n\n                Musical Instruments \n                Microphones \n                 Condenser \nPryce1259 \nDicos79 \n\n❌Regular Price  : ₹  3999  /- 😱"
     }
    ]
   },
   {
    "id": 21094,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683420378",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1299",
    "dicos": "51",
    "catagory":"Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Bajaj Frore 1200 mm Ceiling Fan (Brown)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M4GK2N"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3McwPZ7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M32cFm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M47XTg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44zoHc9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NLbSW1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44EOjnR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42aJpxv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nw4dR1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NLVJQu"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1299"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/41XJ2wk4M1L._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Heating, Cooling & Air Quality \n                Fans \n                 Ceiling Fans \n                 Ceiling Fans \nPryce1299 \nDicos51 \n\n❌Regular Price  : ₹ 1999    /- 😱"
     }
    ]
   },
   {
    "id": 21095,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683420458",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1999",
    "dicos": "60",
    "catagory":"Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Havells Glaze 1200mm 1 Star Energy Saving Ceiling Fan (Elegant White Gold, Pack of 1)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pjasYC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LGY6RD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VCWQUc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/418Lybv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LE2S2j"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41fgA1v"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LIi52A"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LIi52A"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pizJm0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pizJm0"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1999"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/410nxidfTEL._SX466_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Heating, Cooling & Air Quality \n                Fans \n                 Ceiling Fans \n                 Ceiling Fans \nPryce1999 \nDicos60 \n\n❌Regular Price  : ₹  3999   /- 😱"
     }
    ]
   },
   {
    "id": 21096,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683420534",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "2149",
    "dicos": "43",
    "catagory":"Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Polycab Aery 400 mm Pedestal Fan(Sky Blue)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M04jIT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42OCXMD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M2rJOQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42n7alA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42xTWCq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44yBFH4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LIstHB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lxzw5O"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NKO1FW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ntpWsQ"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@2149"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61HJ0zv9OYL._SX466_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Heating, Cooling & Air Quality \n                Fans \n                 Pedestal Fans \n                 Pedestal Fans \nPryce2149 \nDicos43 \n\n❌Regular Price  : ₹  3499  /- 😱"
     }
    ]
   },
   {
    "id": 21097,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683420621",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "794",
    "dicos": "24",
    "catagory":"Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Milton Copper Crown 1100 Water Bottle, 1.09 Litre, 1 Piece, Copper✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NJnx7M"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NHS5Hd"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44yr5zV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44DDxOI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VBU3Ld"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41eBL3A"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/416QBJC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44ElFn5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44AlyZy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42bpfU1"
     },
     {
      "type": "plain",
      "text": "\n\n @794\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61P8naoHkHS._SX466_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Dining \n                Kitchen Storage & Containers \n                 Water Bottles \n                 Water Bottles \nPryce794 \nDicos24 \n\n❌Regular Price  : ₹ 1045    /- 😱"
     }
    ]
   },
   {
    "id": 21098,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683420672",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "368",
    "dicos": "85",
    "catagory":"Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Kuber Industries Unbreakable Small Multipurpose Storage Baskets with lid|Design-Netted|Material-Plastic|Shape-Oval|Color-Brown,Beige and Grey|Pack of 3✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42iBPB2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VE8Jcy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HMbBye"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nA8y5w"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LD8Twd"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AZY0j9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VMac0z"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M3RUVa"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VGhRNL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LZXCHS"
     },
     {
      "type": "plain",
      "text": "\n\n @368\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71MsOfy1WOL._SX466_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Home Storage & Organisation \n                Boxes, Baskets & Bins \n                Storage Baskets \n                Storage Baskets \nPryce368 \nDicos85 \n\n❌Regular Price  : ₹ 1499   /- 😱"
     }
    ]
   },
   {
    "id": 21099,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683420741",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "2899",
    "dicos": "45",
    "catagory":"Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Prestige Svachh Aluminium 2+3+5 Litre Combo Outer Lid Cooker with Induction Base, Silver✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VMah4n"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HLaOxI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HLaOxI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AYonGm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AZnixM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B24eiD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AYTfGC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B6vOLq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nuqPkV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HLjp3t"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@2899"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/8114ySmCRrL._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Dining \n                Cookware \n                Pots & Pans \n                Pots & Pans \nPryce2899 \nDicos45 \n\n❌Regular Price  : ₹   4999   /- 😱"
     }
    ]
   },
   {
    "id": 21100,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683420811",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1999",
    "dicos": "43",
    "catagory":"Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Wipro Vesta 1.2 l Outer Lid Multicooker Kettle | Concurrent Cooking |Cool Touch body | Dual Temperature Control |Triple Protection |SS 304 Rust free (White, 600 Watt, Plastic)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B2kcte"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41d3VvO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41d3VvO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41d3VvO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pm5RF2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HNqb8Z"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HO7O3z"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nF2AAr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/416QVYQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HNYsVI"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1999"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/41bmt4Hf7HL._SX466_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Home Appliances \n                Small Kitchen Appliances \n                Kettles & Hot Water Dispensers \n                Kettles & Hot Water Dispensers \nPryce1999 \nDicos43 \n\n❌Regular Price  : ₹  2999    /- 😱"
     }
    ]
   },
   {
    "id": 21101,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683420880",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1194",
    "dicos": "NaN",
    "catagory":"Car",
    "text_entities": [
     {
      "type": "plain",
      "text": "Vega Crux Dx Anthracite Helmet-M✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LZXLuU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M3b8ue"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nyZUEt"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3payCo9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42xkPqo"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M04gh8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M4WAuk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42xl0C4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42sx9YQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M2evl9"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1194"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/41Xp+qCegOL._SX300_SY300_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Car"
     },
     {
      "type": "plain",
      "text": " & Motorbike\n\n                Car & Motorbike \n                Motorbike Accessories & Parts \n                Helmets \n                 Flip-Up Helmets \n                 Flip-Up Helmets \nPryce1194 \nDicosNaN"
     }
    ]
   },
   {
    "id": 21102,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683420953",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1299",
    "dicos": "48",
    "catagory":"Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "realme Buds Wireless 2 Neo Bluetooth in Ear Earphones with Mic, Fast Charging & Up to 17Hrs Playtime (Blue)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42vGXkR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3phsydN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44zuLRW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3O3hkUH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nw4PWP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44zuNcw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nwEUhI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NLcyL3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41bJlvW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41b3BxY"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1299"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61UtY2UWYYS._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Headphones, Earbuds & Accessories \n                Headphones \n                 In-Ear \n                 In-Ear \nPryce1299 \nDicos48 \n\n❌Regular Price  : ₹   1999  /- 😱"
     }
    ]
   },
   {
    "id": 21103,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683421025",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "129",
    "dicos": "68",
    "catagory":"Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Fun Homes Multiuses Small M 15 Plastic Tray/Basket/Organizer Without Lid- Pack of 2 (Grey) -46FH0119✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LGRzGJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pjb9RI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LE3GnR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LFya9h"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nHcNw3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LIsQ4X"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3phV1QJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LzWwB8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nC78Yk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nC78Yk"
     },
     {
      "type": "plain",
      "text": "\n\n @129\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71ZN-f3UdSL._SX522_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Home Storage & Organisation \n                Boxes, Baskets & Bins \n                Storage Baskets \n                Storage Baskets \nPryce129 \nDicos68 \n\n❌Regular Price  : ₹  399  /- 😱"
     }
    ]
   },
   {
    "id": 21104,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683421098",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "752",
    "dicos": "41",
    "catagory":"Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Cello Eliza Plastic Laundry Bag/Basket, 50 litres, Light Grey✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VMaCUH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42AABAN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pilLR1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NLWvwS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NLqjtq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NTAg7U"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NLwGwF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44DukWA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44DfHCC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44CcfYZ"
     },
     {
      "type": "plain",
      "text": "\n\n @752\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51CyJ9dUiWL._SX300_SY300_QL70_FMwebp_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Home Storage & Organisation \n                Laundry Organization \n                 Laundry Baskets \n                 Laundry Baskets \nPryce752 \nDicos41 \n\n❌Regular Price  : ₹  1099  /- 😱"
     }
    ]
   },
   {
    "id": 21105,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683421145",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "428",
    "dicos": "46",
    "catagory":"Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "ZEBRONICS Zeb-Fame 5watts 2.0 Multi Media Speakers with AUX, USB and Volume Control (Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44yEyYr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44BF7k3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3O3hw6n"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44D1Xbi"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44yCqjo"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42aK7e9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42aK7e9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42aK7e9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44AmgWI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41fhcUR"
     },
     {
      "type": "plain",
      "text": "\n\n @428\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/91zCZLVUg8L._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Computers & Accessories \n                Accessories & Peripherals \n                Audio & Video Accessories \n                 PC Speakers \n                 PC Speakers \nPryce428 \nDicos46 \n\n❌Regular Price  : ₹  799   /- 😱"
     }
    ]
   },
   {
    "id": 21106,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683421216",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "2599",
    "dicos": "56",
    "catagory":"Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Bajaj Splendora 3 Litre 3KW IWH Instant Water Heater (Geyser), White✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41b637l"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44MZHhM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VImIhM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VMaRPB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42orzXC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42orB1G"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44zpYzX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nCkMKV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nCkMKV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VGjuey"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@2599"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51gCF-g7GIL._SX466_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Heating, Cooling & Air Quality \n                Water Heaters & Geysers \n                 Instant Water Heaters \n                 Instant Water Heaters \nPryce2599 \nDicos56 \n\n❌Regular Price  : ₹  4999  /- 😱"
     }
    ]
   },
   {
    "id": 21107,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683421281",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1099",
    "dicos": "54",
    "catagory":"Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "Artis BT18 Wireless Bluetooth Speaker with USB, FM, TF Card, Mobile Phone Holder with Hands Free Calling (Black-Grey) (10W RMS Output)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VCQ6ps"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nBZIEp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LGYXBP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VGjACW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3phVFO9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nC7ka0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B2ksbG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VImV4y"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LGZ00t"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nEVsnu"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1099"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71ABJ09X0ZL._SY450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Home Audio \n                Speakers \n                 Bluetooth Speakers \n                 Bluetooth Speakers \nPryce1099 \nDicos54 \n\n❌Regular Price  : ₹   1949  /- 😱"
     }
    ]
   },
   {
    "id": 21108,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683421332",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "488",
    "dicos": "62",
    "catagory":"Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Halonix 20-watt LED Batten/Tubelight | Streak square 4-ft LED Batten for Living Room & Bedroom | Cool day light, Pack of 2✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VBRvN6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LNa2Se"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LNa38K"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B3wKAi"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B1Xr8w"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AXJNU5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AZ1W3R"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AZAbrZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AZAiDV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AXJQ2d"
     },
     {
      "type": "plain",
      "text": "\n\n @488\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61lgzxCxgnL._SX466_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Indoor Lighting \n                 Tube Lights & Battens \nPryce488 \nDicos62 \n\n❌Regular Price  : ₹  1199   /- 😱"
     }
    ]
   },
   {
    "id": 21109,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683421398",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "299",
    "dicos": "50",
    "catagory":"Tools",
    "text_entities": [
     {
      "type": "plain",
      "text": "SYSKA SSK-PW-0302A-WGY Power Wheel with Surge Protection, Strong ABS Material Body, 4 Meter Long (White-Grey)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3BkpNv7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nzYWaT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3I9bHk3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3I9bNrV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HLjXGz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nDI6YO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HMchng"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HLYuxo"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pm6kag"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41e4SUX"
     },
     {
      "type": "plain",
      "text": "\n\n @299\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61sh8i5XIYL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Tools"
     },
     {
      "type": "plain",
      "text": " & Home Improvement\n\n                Electronics \n                Power Accessories \n                 Surge Protectors \nPryce299 \nDicos50 \n\n❌Regular Price  : ₹ 599   /- 😱"
     }
    ]
   },
   {
    "id": 21110,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683421468",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "2699",
    "dicos": "46",
    "catagory":"Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Bajaj Energos 26 1200mm (48 inch) Energy Efficient 5 Star Rated BLDC Ceiling Fan with Remote, Glossy White✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41aqdi4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41e4XIf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41eaAWS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p5HD1N"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/417hCMR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pjbC6q"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nEWQqs"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42vHgMx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42vHgMx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nz0lyB"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@2699"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/41f4d4jQWVL._SX466_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Heating, Cooling & Air Quality \n                Fans \n                 Ceiling Fans \n                 Ceiling Fans \nPryce2699 \nDicos46 \n\n❌Regular Price  : ₹  3999   /- 😱"
     }
    ]
   },
   {
    "id": 21111,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683421535",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1299",
    "dicos": "84",
    "catagory":"Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "boAt Wave Electra with 1.81\" HD Display, Smart Calling with Ultra-Seamless BT Calling Chip,20 Built-in Watch Faces,100 + Sports Modes,Menu Personalization,in-Built Games(Charcoal Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42qCBeA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42AmqM9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pfj82p"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NKP20I"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44EPAv9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NLDPNz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NLs9dx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NLDQRD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44CcSlj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44Emigr"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1299"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71jEWr4wTFL._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Electronics \n                Wearable Technology \n                 Smart Watches \nPryce1299 \nDicos84 \n\n❌Regular Price  : ₹  4999   /- 😱"
     }
    ]
   },
   {
    "id": 21112,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683421604",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "18499",
    "dicos": "8",
    "catagory":"Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "OnePlus Nord CE 2 Lite 5G (Blue Tide, 6GB RAM, 128GB Storage)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42dn16o"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LGSxTn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LItrnd"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LF03hE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VMbjgL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LJucwa"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42ODKx5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LxluRu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p6T9tO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42uTlRZ"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@18499"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71AvQd3VzqL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Mobiles & Accessories \n                Smartphones & Basic Mobiles \n                 Smartphones \n                 Smartphones \nPryce18499 \nDicos8 \n\n❌Regular Price  : ₹ 19499   /- 😱"
     }
    ]
   },
   {
    "id": 21113,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683421671",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "2235",
    "dicos": "32",
    "catagory":"Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Bajaj Splendid 1200W Induction Cooktop with Pan Sensor and Voltage Pro Technology, Black✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42wGrTK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42ylDvc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42ykEuC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44EPDaj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pgPhGQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nz0AcZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M052uy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42tZiyL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/429uT9h"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44BOYXd"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@2235"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71SEfU2UlpL._SX466_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Dining \n                 Gas Stoves \nPryce2235 \nDicos32 \n\n❌Regular Price  : ₹ 3199   /- 😱"
     }
    ]
   },
   {
    "id": 21114,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683421717",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "219",
    "dicos": "67",
    "catagory":"Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Kuber Industries 30 Biodegradable Garbage Bags Small|Plastic Dustbin Bags|Trash Bags For Kitchen, Office, Warehouse, Pantry or Washroom 5 LTR (Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LJukf8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LF2YXI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44xMR6J"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3O3i5x1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3O3i5x1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3O3i6kz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44t9WHI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3phtnDp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NLsqx5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VBhcgN"
     },
     {
      "type": "plain",
      "text": "\n\n @219\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/612crXk4EsL._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Home Storage & Organisation \n                 Storage \nPryce219 \nDicos67 \n\n❌Regular Price  : ₹ 499   /- 😱"
     }
    ]
   },
   {
    "id": 21115,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683421784",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "5947",
    "dicos": "57",
    "catagory":"Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "Acer EK220Q 21.5 Inch (54.61 Cm) Full HD (1920x1080 Pixels) VA Panel LCD Monitor with LED Back Light I 250 Nits I HDMI, VGA Ports (Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pfslro"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44EPSSL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41eaWNc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44EmRH5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VE9Ed0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B1Y3uQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41fhMSx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pjynqU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AZ2gQ7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B6wqRe"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@5947"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/8150iUXkc5L._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Computers & Accessories \n                2021 \n                 Gaming Monitors \nPryce5947 \nDicos57 \n\n❌Regular Price  : ₹ 11499   /- 😱"
     }
    ]
   },
   {
    "id": 21116,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683421851",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "2889",
    "dicos": "33",
    "catagory":"Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "Qubo Outdoor Security Camera (Black) from Hero Group | Made in India | IP65 All-Weather | 2MP 1080p Full HD | CCTV Wi-Fi Camera | Night Vision | Mobile App Connectivity | Cloud & SD Card Recording✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3peCCUM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VEx6qE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VGqhVv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HLoWa5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VEanLi"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nEVRq0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42cfrZW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42a3y6x"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B3IvXI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LDxPE4"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@2889"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/31RK2ukll1L._SY450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Cameras & Photography \n                Security Cameras \n                 Dome Cameras \n                 Dome Cameras \nPryce2889 \nDicos33 \n\n❌Regular Price  : ₹ 3999   /- 😱"
     }
    ]
   },
   {
    "id": 21117,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683421917",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "9990",
    "dicos": "48",
    "catagory":"Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Voltas beko 8.2 kg Semi Automatic twin tub Washing machine (WTT82) Burgundy✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HHCZxB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B7Y5Bj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AZIG6c"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AZAMtJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B3ORpK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B13nP7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VMbJUn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pemRgK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HO8mqd"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HHyzH6"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@9990"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/614ITPf9D4L._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Large Appliances \n                Washing Machines & Dryers \n                 Semi-Automatic \n                 Semi-Automatic \nPryce9990 \nDicos48 \n\n❌Regular Price  : ₹ 16499   /- 😱"
     }
    ]
   },
   {
    "id": 21118,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683421985",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1098",
    "dicos": "76",
    "catagory":"Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "GOVO GOCRUSH 421 5W Bluetooth Speaker, 1800mAh Battery with 10 Hrs Playtime, IPX7, ABS Fabric and TWS Feature (Platinum Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HLhJXR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ntrbZ2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3O3imQz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B15wKH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NLqUeE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nuNs8N"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nzt5ak"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NLdqPP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AZIWSI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HQcNRl"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1098"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71n+cnjT08L._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Home Audio \n                Speakers \n                 Bluetooth Speakers \n                 Bluetooth Speakers \nPryce1098 \nDicos76 \n\n❌Regular Price  : ₹ 3499   /- 😱"
     }
    ]
   },
   {
    "id": 21119,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683422052",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "599",
    "dicos": "50",
    "catagory":"Health",
    "text_entities": [
     {
      "type": "plain",
      "text": "Oral B Pro Expert Electric Toothbrush for adults, Battery Operated with replaceable brush head,Pack of 1, Multicolor✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HNrkxj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41hZ64p"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HM1zgM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M2suaw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M2elKp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HLplcB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nEXtQQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M08OEq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HNW5Ce"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LZDyFx"
     },
     {
      "type": "plain",
      "text": "\n\n @599\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51Omsg6f1AL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Health"
     },
     {
      "type": "plain",
      "text": " & Personal Care\n\n                Health & Personal Care \n                Oral Care \n                Toothbrushes & Accessories \n                Electric Toothbrushes & Accessories \n                Electric Toothbrushes & Accessories \nPryce599 \nDicos50 \n\n❌Regular Price  : ₹ 1099   /- 😱"
     }
    ]
   },
   {
    "id": 21120,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683422122",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1799",
    "dicos": "71",
    "catagory":"Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Lifelong LLGS18 Glass Top 3 Burner Gas Stove, Manual Ignition, LPG Compatible, Black (ISI Certified, Door Step Service, 1 Year Warranty)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M08Zj4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pbxCQJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pazTvr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42wGFKA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ntrqTW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NGFMLe"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42wDKkZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42wDKBv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42wDGBL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41bKp2U"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1799"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51bMcjdgQeL._SX466_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & dining \n                Steal deals \n                 Gas Stoves from Top Brands \n                 Gas Stoves from Top Brands \nPryce1799 \nDicos71 \n\n❌Regular Price  : ₹ 3899   /- 😱"
     }
    ]
   },
   {
    "id": 21121,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683422197",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "649",
    "dicos": "58",
    "catagory":"Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Pigeon 1.5 litre Hot Kettle and Stainless Steel Water Bottle Combo used for boiling Water, Making Tea and Coffee, Instant Noodles, Soup, 1500 Watt with Auto Shut- off Feature - (Silver)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pfjPJ3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LIjJRO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44yD54m"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/429ZT8R"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pbmYtj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3peWDun"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44zvR00"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42xm9JS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LxARcQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42f0oi4"
     },
     {
      "type": "plain",
      "text": "\n\n @649\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51VvdjBgB5L._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Home Appliances \n                Small Kitchen Appliances \n                Kettles & Hot Water Dispensers \n                Kettles & Hot Water Dispensers \nPryce649 \nDicos58 \n\n❌Regular Price  : ₹ 1250   /- 😱"
     }
    ]
   },
   {
    "id": 21122,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683422264",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "280",
    "dicos": "30",
    "catagory":"Beauty",
    "text_entities": [
     {
      "type": "plain",
      "text": "Navratna Gold Ayurvedic Oil |Non Sticky and Non Greasy |Mild Fragrance| Goodness of Almonds and 9 Ayurvedic Herbs |Relieves Body Aches, Sleeplessness, Headache and Fatigue, 500ml✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42ABE3H"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LE0c4Q"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VE4kX3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LIAGM1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p6TIUs"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pfjXZ3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VGqCaJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pinjut"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42AniAp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pbn2Jz"
     },
     {
      "type": "plain",
      "text": "\n\n @280\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61tb2EySHUS._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Beauty"
     },
     {
      "type": "plain",
      "text": "\n\n                Beauty \n                Hair Care \n                 Hair Oils \nPryce280 \nDicos30 \n\n❌Regular Price  : ₹ 399   /- 😱"
     }
    ]
   },
   {
    "id": 21123,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683422334",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "22999",
    "dicos": "27",
    "catagory":"Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Havells Gracia Alkaline Water Purifier, Hot, Warm and Ambient dispensing, Cu+Zn+ Alkaline+natural minerals, 8 stage Purification, 6.8L SS tank, RO+UV+UV LED, 24*7 Tank Sanitization, (Silver & Black) ✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VGqEiR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42cxYFw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M1TjM9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nEXEeY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LE4LMr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VDkYpI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44yDhR8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44Dh4RM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44xNqxn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LzXUDQ"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@22999"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51T3Hgic1bL._SX466_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Home Appliances \n                Water Purifiers & Accessories \n                 Water Filters & Purifiers \n                 Water Filters & Purifiers \nPryce22999 \nDicos27 \n\n❌Regular Price  : ₹ 29999   /- 😱"
     }
    ]
   },
   {
    "id": 21124,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683422403",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "6302",
    "dicos": "23",
    "catagory":"Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Borosil Prima 24 L Oven Toaster & Grill, Motorised Rotisserie & Convection Heating, 5 Heating Modes, Black✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nw5SGf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B13Dh3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AZ2N4z"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42a0cAx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/418Nalz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41rUHMJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41e1WaX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LIiBNS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B2FWFc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42f0Fl6"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@6302"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/619Oju54bwL._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Home Appliances \n                Small Kitchen Appliances \n                 Oven Toaster Grills \n                 Oven Toaster Grills \nPryce6302 \nDicos23 \n\n❌Regular Price  : ₹ 7499   /- 😱"
     }
    ]
   },
   {
    "id": 21125,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683422474",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1989",
    "dicos": "60",
    "catagory":"Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Havells Glaze 1200mm 1 Star Energy Saving Ceiling Fan (Pearl White Copper, Pack of 1)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B3PgbK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HMAg69"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AZ3bA3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HLcnf4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nCIcQo"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HImwZW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HImBgc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HMAlXv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HMD2bh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3piBwre"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1989"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/31qC5fOOoQL._SX466_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Heating, Cooling & Air Quality \n                Fans \n                 Ceiling Fans \n                 Ceiling Fans \nPryce1989 \nDicos60 \n\n❌Regular Price  : ₹ 4499   /- 😱"
     }
    ]
   },
   {
    "id": 21126,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683422543",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "2799",
    "dicos": "86",
    "catagory":"Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "Vibez by Lifelong Bold Smartwatch For Men Bluetooth Calling 1.32\" HD Display|24x7 Heart Rate & Blood Oxygen Tracking|Sports Mode|IP67|Sleep Monitor|7 days Battery Backup(VBSWM99, 1 Year Manufacturer Warranty, Blue)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VMcaxZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VG7ABd"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pkedxi"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VCOJaq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41b5eM6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M1XyaB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VDlmVc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HNI6w2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M1zSTx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B2nEE4"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@2799"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61FAA4G9omL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Electronics \n                Wearable Technology \n                 Smart Watches \nPryce2799 \nDicos86 \n\n❌Regular Price  : ₹  14999  /- 😱"
     }
    ]
   },
   {
    "id": 21127,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683422609",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "409",
    "dicos": "54",
    "catagory":"Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Pigeon Ivory 1000W Dry Iron✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NFquGC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M1A5WP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M1XJmh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M3TLJC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nC8sdK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M1rjrT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NOqwfh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B2Gz1w"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B2lTa4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NOKTce"
     },
     {
      "type": "plain",
      "text": "\n\n @409\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/41psIgdZkCL._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Home Appliances \n                Vacuum, Cleaning & Ironing \n                Irons, Steamers & Accessories \n                Irons, Steamers & Accessories \nPryce409 \nDicos54 \n\n❌Regular Price  : ₹ 749   /- 😱"
     }
    ]
   },
   {
    "id": 21128,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683422656",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "399",
    "dicos": "56",
    "catagory":"Garden",
    "text_entities": [
     {
      "type": "plain",
      "text": "Amazon Brand - Solimo Anti-Mosquito Racquet, Insect Killer Bat with Rechargeable 500 mAh Battery and LED Light, Black✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NFqA0W"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HLHHKA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NFqAOu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M2eX2F"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nF4np9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HKD9Ei"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NLrpFy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41c0oy3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41gYmN4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NHUrWz"
     },
     {
      "type": "plain",
      "text": "\n\n @399\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/410znRh-okL._SX300_SY300_QL70_FMwebp_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Garden"
     },
     {
      "type": "plain",
      "text": " & Outdoors\n\n                Outdoor Living \n                Pest Control \n                 Fly Swatters \nPryce399 \nDicos56 \n\n❌Regular Price  : ₹850    /- 😱"
     }
    ]
   },
   {
    "id": 21129,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683422727",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "7999",
    "dicos": "58",
    "catagory":"Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "LOOOOT💥💥💥Havells FAB Absoulety Safe RO + UV Water Purifer, GHWRHFB015, Beige & White\n✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nusmrb"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NGGF6w"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42qDOm8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41gySzk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M068FJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42n9jxE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42n9rgC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/417iX6l"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41aI5Jx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42ukm85"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@7999"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/518NK6aOSfL._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Home Appliances \n                Water Purifiers & Accessories \n                 Water Filters & Purifiers \n                 Water Filters & Purifiers \nPryce7999 \nDicos58 \n\n❌Regular Price  : ₹16499    /- 😱"
     }
    ]
   },
   {
    "id": 21130,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683422796",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "35782",
    "dicos": "50",
    "catagory":"Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "Lenovo V15 G3 (82TTA01EIN) Laptop (Intel Core i3-1215U/ 8GB RAM/ 512GB SSD/DOS/ 15.6\" FHD/ 1 Year Warranty), Iron Grey✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LE0MQ4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44DvTDW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42ymmwq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p5Jm7h"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p5Jm7h"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3paAXiV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42n9yJ4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42cg0Ty"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42wHG5m"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42u06Uj"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@35782"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/41Y+ZfcYQnL._SY450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Computers & Accessories \n                Laptops \n                 Traditional Laptops \nPryce35782 \nDicos50 \n\n❌Regular Price  : ₹60999    /- 😱"
     }
    ]
   },
   {
    "id": 21131,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683422866",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "299",
    "dicos": "70",
    "catagory":"Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Digital Alarm Clock,Battery Operated Small Desk Clocks,with Date,Indoor Temperature,Smart Night Light,LCD Electronic Clock for Bedroom Home Office - White✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44taXj0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M06goH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LJc2um"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44yXUwx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VGmVlt"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42a0APv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42rsyqp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44zr7rf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/428Pomg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42aLBoJ"
     },
     {
      "type": "plain",
      "text": "\n\n @299\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61eBxfmK4YL._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Home & Décor \n                Clocks \n                 Alarm Clocks \n                 Alarm Clocks \nPryce299 \nDicos70 \n\n❌Regular Price  : ₹ 850   /- 😱"
     }
    ]
   },
   {
    "id": 21132,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683422931",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "453",
    "dicos": "89",
    "catagory":"Clothing",
    "text_entities": [
     {
      "type": "plain",
      "text": "RIVER Ashish N Soni Premium Designer Men's Festive Kurta with Pintucks in 100% Cotton_RS2ASKU012B_Blue✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44BGOxV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44yz6ou"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LF44Tk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VGrcFr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44yY1rX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VU8jPH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M2f12p"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44Eo0OT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44O9cgL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LE5f5d"
     },
     {
      "type": "plain",
      "text": "\n\n @453\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/612v7SihuaL._UX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Clothing"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Clothing & Accessories \n                Men \n                Ethnic Wear \n                 Kurtas \n                 Kurtas \nPryce453 \nDicos89 \n\n❌Regular Price  : ₹ 2499   /- 😱"
     }
    ]
   },
   {
    "id": 21133,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683422999",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "748",
    "dicos": "50",
    "catagory":"Grocery",
    "text_entities": [
     {
      "type": "plain",
      "text": "Nature Purify 100% Natural and Premium Healthy Mix Dry Fruits and Nuts | Healthy for Every Occasion Fresh and Healthy Dry Fruits (1 Kg)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pint4T"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LIl4rO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VGrhcd"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44Cec7L"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44DhJ5I"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VGnvQb"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VGnvQb"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VCRJn4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44EQS9t"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42iDNkU"
     },
     {
      "type": "plain",
      "text": "\n\n @748\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61hfjUwfuWL._SY741_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Grocery"
     },
     {
      "type": "plain",
      "text": " & Gourmet Foods\n\n                Grocery & Gourmet Foods \n                Dried Fruits, Nuts & Seeds \n                Nuts & Seeds \n                 Mixed Nuts \n                 Mixed Nuts \nPryce748 \nDicos50 \n\n❌Regular Price  : ₹ 1250   /- 😱"
     }
    ]
   },
   {
    "id": 21134,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683423064",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "359",
    "dicos": "87",
    "catagory":"Clothing",
    "text_entities": [
     {
      "type": "plain",
      "text": "Desi belle Women's Regular Fit T-Shirt✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/429wtYL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NLyDJv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pkQAEv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B2meJS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LCEbDz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41rVm0F"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nJLpO1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nJLpO1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B2GNFU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41c0F43"
     },
     {
      "type": "plain",
      "text": "\n\n @359\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71NnRbwQd4L._UX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Clothing"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Clothing & Accessories \n                Women \n                Western Wear \n                Tops, T-Shirts & Shirts \n                Tops, T-Shirts & Shirts \nPryce359 \nDicos87 \n\n❌Regular Price  : ₹ 1499   /- 😱"
     }
    ]
   },
   {
    "id": 21135,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683423132",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "2998",
    "dicos": "50",
    "catagory":"Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "AGARO 33398 Rapid 1000-Watt, 10-Litre Wet & Dry Vacuum Cleaner, with Blower Function (Red & Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B26mH9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HIngyc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nAhg3E"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nC1RQt"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AYVcTs"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HMdI58"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HKDpDg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HLZSjA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HM2z4w"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HQdMB1"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@2998"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/715l1odFnbL._SX522_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Home Appliances \n                Vacuum, Cleaning & Ironing \n                Vacuums & Floor Care \n                Vacuums & Floor Care \nPryce2998 \nDicos50 \n\n❌Regular Price  : ₹4499   /- 😱"
     }
    ]
   },
   {
    "id": 21136,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683423198",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "524",
    "dicos": "70",
    "catagory":"Beauty",
    "text_entities": [
     {
      "type": "plain",
      "text": "Livon Damage Protect Serum For Women & Men, Protection Up To 250°C & 2X Less Hair Breakage, 100 ml (Pack of 2) With Syska Hair Dryer✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HKDJ4W"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HMdWJw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HLlEDV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VGnBav"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41d64aQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nBR3ls"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nz1JRP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HOa0rT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HLXM36"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M2ttYg"
     },
     {
      "type": "plain",
      "text": "\n\n @524\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61f8tTo+XzL._SX569_PIbundle-3,TopRight,0,0_AA569SH20_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Beauty"
     },
     {
      "type": "plain",
      "text": "\n\n                Beauty \n                Hair Care \n                Styling \n                 Hair Serums \n                 Hair Serums \nPryce524 \nDicos70 \n\n❌Regular Price  : ₹ 1250   /- 😱"
     }
    ]
   },
   {
    "id": 21137,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683423265",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "699",
    "dicos": "61",
    "catagory":"Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "Artis AR-4MS 4 Universal Sockets with Multi Switch Surge Protector✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M0a0aS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LZEE47"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M3dl90"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M06GfI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B2H0ca"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NHEIGP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nztYQc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nusE1f"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://www.amazon.in/dp/B0B8Z26QYC?&amp;linkCode=sl1&amp;tag=avi1deals9-21&amp;linkId=30c526ec2886745a7882665c7610baee&amp;language=en_IN&amp;ref_=as_li_ss_tl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://www.amazon.in/dp/B0B8Z26QYC?&amp;linkCode=sl1&amp;tag=avi1deals9-21&amp;linkId=30c526ec2886745a7882665c7610baee&amp;language=en_IN&amp;ref_=as_li_ss_tl"
     },
     {
      "type": "plain",
      "text": "\n\n @699\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61fGYChK8JL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Power Accessories \n                 Surge Protectors \nPryce699 \nDicos61 \n\n❌Regular Price  : ₹  1550 /- 😱"
     }
    ]
   },
   {
    "id": 21138,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683423333",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "2659",
    "dicos": "56",
    "catagory":"Health",
    "text_entities": [
     {
      "type": "plain",
      "text": "Lifelong Gun Massager Deep Tissue Body Massage Machine For Pain Relief | 6 Specialized Heads | LED Touch Panel |3000 Stroke per min| 30 Speed Level Massager Machine For Full Body with Premium Carry Bag (1 Year Warranty, Black,LLGM09)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NLeymx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NNBEcw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NNBEJy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41b7PW3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41e6WfF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HKch7v"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NLpalF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pjdcVU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nCJ87m"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LJvreO"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@2659"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61r8+Af6isL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Health"
     },
     {
      "type": "plain",
      "text": " & Personal Care\n\n                Health & Personal Care \n                Health Care \n                Massage & Relaxation \n                Electric Massagers \n                Electric Massagers \nPryce2659 \nDicos56 \n\n❌Regular Price  : ₹ 4599   /- 😱"
     }
    ]
   },
   {
    "id": 21139,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683423399",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "853",
    "dicos": "68",
    "catagory":"Health",
    "text_entities": [
     {
      "type": "plain",
      "text": "Beurer Professional Foldable 1600 Watts Hair Dryer With 2 Ultra Heat & Speed Settings, Pink Limited Edition (3 Years Warranty)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nEX6pa"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41e2WMf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42qLR2q"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p5JFPt"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LxBVgQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42wEMxn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42w5KVP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42wI2sI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LHxeku"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42thoAQ"
     },
     {
      "type": "plain",
      "text": "\n\n @853\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/41yw6IvR1IL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Health"
     },
     {
      "type": "plain",
      "text": " & Personal Care\n\n                Beauty \n                Hair Care \n                Hair Styling Tools \n                Hair Dryers & Accessories \n                Hair Dryers & Accessories \nPryce853 \nDicos68 \n\n❌Regular Price  : ₹ 1999   /- 😱"
     }
    ]
   },
   {
    "id": 21140,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683423444",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "199",
    "dicos": "92",
    "catagory":"Shoes",
    "text_entities": [
     {
      "type": "plain",
      "text": "Bagsy Malone Women's Western (Croco Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pbyLI1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42qM7OW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/427whsO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42qM4me"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pbzaKx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42ukWmh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pinCFt"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p80sBs"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42wF1IN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44tbBNs"
     },
     {
      "type": "plain",
      "text": "\n\n @199\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61UPLk3FtpL._UY625_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Shoes"
     },
     {
      "type": "plain",
      "text": " & Handbags\n\n                Fashion \n                Pongal || Fashion accessories \n                Handbags \n                 Women wallets \n                 Women wallets \nPryce199 \nDicos92 \n\n❌Regular Price  : ₹ 1499   /- 😱"
     }
    ]
   },
   {
    "id": 21141,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683423487",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "690",
    "dicos": "54",
    "catagory":"Clothing",
    "text_entities": [
     {
      "type": "plain",
      "text": "Gear Camo Maxis Water Resistant Hybrid Travel Duffle & Backpack/Gym Bag for Men/Women✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44D3WMM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42w6aLT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://www.amazon.in/dp/B0B8YMS4RM?&amp;linkCode=sl1&amp;tag=deals103-21&amp;linkId=0973b8431105c5d3db95c8c0084696a5&amp;language=en_IN&amp;ref_=as_li_ss_tl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3phuUcD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42otI5C"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44BHjrN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44DHjaQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42d72VV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44yYnPj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44yzymG"
     },
     {
      "type": "plain",
      "text": "\n\n @690\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/91aAJzk0zmL._UX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Clothing"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Bags, Wallets and Luggage \n                Luggage \n                 Travel Duffles \nPryce690 \nDicos54 \n\n❌Regular Price  : ₹1250    /- 😱"
     }
    ]
   },
   {
    "id": 21142,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683423555",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "880",
    "dicos": "75",
    "catagory":"Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "jaspo Power Kids Fiber Skateboard for Beginners (Black, 6 Years & Above, 26\" inches)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44DhV4W"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VB6PJX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VGlun4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LCEoXn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VBWFsv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LIjwOk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44zrEtf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VCS3Ci"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VIzSer"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44yEp7k"
     },
     {
      "type": "plain",
      "text": "\n\n @880\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/41UHKceC0OL._SX300_SY300_QL70_FMwebp_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Sports, Fitness & Outdoors \n                Skates, Skateboards & Scooters \n                Skateboarding \n                Skateboards & Casterboards \n                Skateboards & Casterboards \nPryce880 \nDicos75 \n\n❌Regular Price  : ₹ 2299   /- 😱"
     }
    ]
   },
   {
    "id": 21143,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683423622",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "2405",
    "dicos": "63",
    "catagory":"Health",
    "text_entities": [
     {
      "type": "plain",
      "text": "Lifelong LLM378 Electric Foldable Foot Spa Massager with 8 Manual Massage Rollers, Digital Panel & Water Heating Technology for Pain Relief| Pedicure Machine| Foot Massager (1 Year Warranty, Brown)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LFURKj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VGlCD4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VIoFe6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VGo7VZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42brNBz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42ddX1B"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42cmnpI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42a1c7L"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VBTL72"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LNbUdI"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@2405"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51J7pFQSV3L._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Health"
     },
     {
      "type": "plain",
      "text": " & Personal Care\n\n                Health & Personal Care \n                Health Care \n                Massage & Relaxation \n                Electric Massagers \n                Electric Massagers \nPryce2405 \nDicos63 \n\n❌Regular Price  : ₹ 5499   /- 😱"
     }
    ]
   },
   {
    "id": 21144,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683423665",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "649",
    "dicos": "35",
    "catagory":"Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "GREENCHEF Marco Aluminium Pressure Cooker Outer Lid 3 Litre capacity, ISI Certified, Silver Colour✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/427wH2m"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M2uvne"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44vEjxr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VB78V7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44yzL9s"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nw71O3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AYqPN4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LGUwHj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NFrkDg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AYVr0O"
     },
     {
      "type": "plain",
      "text": "\n\n @649\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61HVIvLiVRL._SX466_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Dining \n                Cookware \n                Pots & Pans \n                Pots & Pans \nPryce649 \nDicos35 \n\n❌Regular Price  : ₹  950  /- 😱"
     }
    ]
   },
   {
    "id": 21145,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683423733",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "409",
    "dicos": "54",
    "catagory":"Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Pigeon Ivory 1000W Dry Iron✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3phvdnN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AYqVnU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AZ4eQv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nAhIPo"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B1ZVDS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NHFdAH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41boJEa"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HIo8Tu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B00Ogb"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B3KdbA"
     },
     {
      "type": "plain",
      "text": "\n\n @409\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/41psIgdZkCL._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Home Appliances \n                Vacuum, Cleaning & Ironing \n                Irons, Steamers & Accessories \n                Irons, Steamers & Accessories \nPryce409 \nDicos54 \n\n❌Regular Price  : ₹ 750   /- 😱"
     }
    ]
   },
   {
    "id": 21146,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683423800",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1500",
    "dicos": "70",
    "catagory":"Watches",
    "text_entities": [
     {
      "type": "plain",
      "text": "French Connection Analog Dial Unisex's Watch✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HMDTbZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B01Rwz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HOa5f7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HHAg7q"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41bLSpW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41b8d6X"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pm8kPO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HOahep"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42dulPv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HM0wO2"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1500"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71xGc7qlrWL._UY500_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Watches"
     },
     {
      "type": "plain",
      "text": "\n\n                Fashion \n                Onam 2021 \n                Watches and fashion jewellery \n                 Watches top brands \n                 Watches top brands \nPryce1500 \nDicos70 \n\n❌Regular Price  : ₹3999    /- 😱"
     }
    ]
   },
   {
    "id": 21147,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683423867",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1499",
    "dicos": "50",
    "catagory":"Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "realme TechLife Buds T100 Bluetooth Truly Wireless in Ear Earbuds with mic, AI ENC for Calls, Google Fast Pair, 28 Hours Total Playback with Fast Charging and Low Latency Gaming Mode (Blue)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42de4Kz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/416xMpS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HLID1y"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nz2lXD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LZEXvN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M35bh2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M20OTe"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VE5Nwx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nBRMmG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LZFe1N"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1499"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61q65Ob+nrL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Headphones, Earbuds & Accessories \n                Headphones \n                 In-Ear \n                 In-Ear \nPryce1499 \nDicos50 \n\n❌Regular Price  : ₹  2499  /- 😱"
     }
    ]
   },
   {
    "id": 21148,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683423935",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1599",
    "dicos": "60",
    "catagory":"Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "Oppo Enco Buds Bluetooth True Wireless in Ear Earbuds(TWS) with Mic, 24H Battery Life, Supports Dolby Atmos Noise Cancellation During Calls, IP54 Dust & Water Resistant,(White, True Wireless)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VIAelh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nyXdTp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M2ubEU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nw7tMf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NKRg04"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3phYpep"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NLpmkT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NMCtlQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NLuxB1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NLpDEr"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1599"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51+zP3-RQXL._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Headphones, Earbuds & Accessories \n                Headphones \n                 In-Ear \n                 In-Ear \nPryce1599 \nDicos60 \n\n❌Regular Price  : ₹ 3499   /- 😱"
     }
    ]
   },
   {
    "id": 21149,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683424001",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1260",
    "dicos": "66",
    "catagory":"Toys",
    "text_entities": [
     {
      "type": "plain",
      "text": "Brunte Tuk Tuk Baby Orange Magic Swing Car for Kids Twister Ride on for Heavy Weight Capacity 3years Plus with Marks Free Wheels, Red (BTT09)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nw7KyL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41fk1oV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HLYu0g"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nF5tBh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HHAr2A"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LIC2GB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42szF1e"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42OFIgX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42ynjou"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HKcTdj"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1260"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/41wngn4tzHL._SX450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Toys"
     },
     {
      "type": "plain",
      "text": " & Games\n\n                Toys & Games \n                Bikes, Trikes & Ride-Ons \n                 Ride-Ons \nPryce1260 \nDicos66 \n\n❌Regular Price  : ₹  2999   /- 😱"
     }
    ]
   },
   {
    "id": 21150,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683424069",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "120",
    "dicos": "20",
    "catagory":"Office",
    "text_entities": [
     {
      "type": "plain",
      "text": "Reynolds AEROSLIM BP 25 CT JAR - BLUE Ball Pen I Lightweight Ball Pen With Comfortable Grip for Extra Smooth Writing I School and Office Stationery | 0.7mm Tip Size✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42yihrJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44DiwDI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3paCaqt"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42a5kVf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42del03"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42awAmL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42bs87j"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44yugrl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44DI3g8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44zXAgW"
     },
     {
      "type": "plain",
      "text": "\n\n @120\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51eugaXWF9L._SY450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Office"
     },
     {
      "type": "plain",
      "text": " Products\n\n                Office Products \n                Office Paper Products \n                Paper \n                Stationery \n                Stationery \nPryce120 \nDicos20 \n\n❌Regular Price  : ₹ 150   /- 😱"
     }
    ]
   },
   {
    "id": 21151,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683424135",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1599",
    "dicos": "49",
    "catagory":"Baby",
    "text_entities": [
     {
      "type": "plain",
      "text": "Amazon Brand - Solimo Baby Walker with Push Handle, Blue (Fabric Print May Vary)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3piotpF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LFBd1d"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VGkRtv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LxCzuM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nHfrlt"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42devVd"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VE6cPz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LzZBkG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LDAqxO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42fbHHc"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1599"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/81uamblPp5L._SY741_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Baby"
     },
     {
      "type": "plain",
      "text": "\n\n                Baby \n                Activity & Play Time \n                 Walkers \nPryce1599 \nDicos49 \n\n❌Regular Price  : ₹   2999  /- 😱"
     }
    ]
   },
   {
    "id": 21152,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683424179",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "359",
    "dicos": "70",
    "catagory":"Clothing",
    "text_entities": [
     {
      "type": "plain",
      "text": "Reebok Men's Slim Fit T-Shirts✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42h9r1Z"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VGWjR6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M07DUn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M4ZCic"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LNcwA2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LHAmNd"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B801tz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M35Ajy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nEZkVO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nA1aHh"
     },
     {
      "type": "plain",
      "text": "\n\n @359\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51d-8vw-JNL._UY741_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Clothing"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Fashion \n                Apparel Steal Deals \n                Apparel_1 \n                Men \n                Men \nPryce359 \nDicos70 \n\n❌Regular Price  : ₹  999   /- 😱"
     }
    ]
   },
   {
    "id": 21153,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683424246",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "529",
    "dicos": "47",
    "catagory":"Clothing",
    "text_entities": [
     {
      "type": "plain",
      "text": "Amazon Brand - Solimo 8L Polyester Canvas Backpack✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LDcDOh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LBHLhj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NHFzr1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NLZ9mi"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B2oTTK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B2njkU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B4ED8N"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://www.amazon.in/dp/B0B46TCVTH?th=1&amp;linkCode=sl1&amp;tag=avi1deals8-21&amp;linkId=abce1c6bd0b7245393a23ed065fda9c5&amp;language=en_IN&amp;ref_=as_li_ss_tl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HLdOKu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NKoJry"
     },
     {
      "type": "plain",
      "text": "\n\n @529\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/81BBiCr3B3L._UY741_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Clothing"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Bags, Wallets and Luggage \n                Bags & Backpacks \n                Backpacks \n                 Casual Backpacks \n                 Casual Backpacks \nPryce529 \nDicos47 \n\n❌Regular Price  : ₹  999  /- 😱"
     }
    ]
   },
   {
    "id": 21154,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683424311",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "249",
    "dicos": "50",
    "catagory":"Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Wonder Plastic Sigma Snacker Microwave Safe Bowl Set, 4 pc 500 ml, Pink Color, Made in India, KBS02129✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B17j2n"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HLjx35"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AYrKx0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B15G4J"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41cW0yR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41bpkpo"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HO1B7Y"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HHAP12"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HHCG5F"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41cW7dL"
     },
     {
      "type": "plain",
      "text": "\n\n @249\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51DWG4lLHCL._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Dining \n                Tableware \n                Dinnerware & Serving Pieces \n                Dinnerware & Serving Pieces \nPryce249 \nDicos50 \n\n❌Regular Price  : ₹ 500    /- 😱"
     }
    ]
   },
   {
    "id": 21155,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683424377",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "595",
    "dicos": "85",
    "catagory":"Clothing",
    "text_entities": [
     {
      "type": "plain",
      "text": "RIVER Men's Regular Fit Shirt✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41cIafJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42d7PWT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HQeOwT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LZFttJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41kC7WM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LZFuhh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M35GHW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nCa0o4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LYja7D"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M2v6oY"
     },
     {
      "type": "plain",
      "text": "\n\n @595\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/81rAPC6jdYL._UY500_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Clothing"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Fashion \n                Designer Boutique \n                Trends \n                t \n                t \nPryce595 \nDicos85 \n\n❌Regular Price  : ₹  2999   /- 😱"
     }
    ]
   },
   {
    "id": 21156,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683424445",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "129",
    "dicos": "26",
    "catagory":"Office",
    "text_entities": [
     {
      "type": "plain",
      "text": "Cello Jetta Ball pens | 3 blue, 2 black pens|Jumbo refill ball pen| Retractable ball pen set for students | Ballpen pack✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M35KHF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M2v9Ba"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VGsqk1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NK4Zo0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VGmkQK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M2gJRn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VG92Ub"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NTCC6K"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NHVGVJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3peoPh8"
     },
     {
      "type": "plain",
      "text": "\n\n @129\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51IdPfrj1TL._SY450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Office"
     },
     {
      "type": "plain",
      "text": " Products\n\n                Office Products \n                Office Paper Products \n                Paper \n                Stationery \n                Stationery \nPryce129 \nDicos26 \n\n❌Regular Price  : ₹  175    /- 😱"
     }
    ]
   },
   {
    "id": 21157,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683424512",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "148",
    "dicos": "72",
    "catagory":"Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Cockatoo (10 KG - 40 Kg) Adjustable Hand Grip| Hand Gripper for Men & Women|Hand Grip Strengthener (6 Month Warranty)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NH6JOZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ntts6w"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nutWJD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41e3QZ9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NTD4BY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pkRZuL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pjAElW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41b9fjl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/416yy6g"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41bMADC"
     },
     {
      "type": "plain",
      "text": "\n\n @148\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71+cZ8KaWJL._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Sports, Fitness & Outdoors \n                Exercise & Fitness \n                Strength Training Equipment \n                Strength Training Devices \n                Strength Training Devices \nPryce148 \nDicos72 \n\n❌Regular Price  : ₹ 499    /- 😱"
     }
    ]
   },
   {
    "id": 21158,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683424562",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "399",
    "dicos": "56",
    "catagory":"Garden",
    "text_entities": [
     {
      "type": "plain",
      "text": "Amazon Brand - Solimo Anti-Mosquito Racquet, Insect Killer Bat with Rechargeable 500 mAh Battery and LED Light, Black✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LE2l0o"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LE2l0o"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nEY8By"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HMf286"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HMf286"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HLrHrX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HObfqZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HLecsq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41c26zt"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HLrLId"
     },
     {
      "type": "plain",
      "text": "\n\n @399\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/81PI0QPkaUL._SY879_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Garden"
     },
     {
      "type": "plain",
      "text": " & Outdoors\n\n                Outdoor Living \n                Pest Control \n                 Fly Swatters \nPryce399 \nDicos56 \n\n❌Regular Price  : ₹  999   /- 😱"
     }
    ]
   },
   {
    "id": 21159,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683424630",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "449",
    "dicos": "47",
    "catagory":"Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Amazon Brand - Solimo Plastic Storage Containers with Sliding Mouth (Set of 6, 1100ml, Blue)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p6VB3u"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42ynlfI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p5KGqL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42App7j"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42u1Eh5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42um8pL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44yZ8rD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44yHGn9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42deFvN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44ES0dd"
     },
     {
      "type": "plain",
      "text": "\n\n @449\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/81Ek10Iq1uL._SX522_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Dining \n                Kitchen Storage & Containers \n                 Jars & Containers \n                 Jars & Containers \nPryce449 \nDicos47 \n\n❌Regular Price  : ₹  799  /- 😱"
     }
    ]
   },
   {
    "id": 21160,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683424698",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "159",
    "dicos": "80",
    "catagory":"Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "HAANS Shakeit Protein Shaker Bottle, 500ml (Color-Grey, Plastic, Pack of 1)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42cA2xg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42bsKtD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44BIhEr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pioRo7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42ouZtq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VBM8gR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VU9TkB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42aMZrr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/429xUq7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44zYnhU"
     },
     {
      "type": "plain",
      "text": "\n\n @159\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/615vwtdTRBL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Health & Personal Care \n                Diet & Nutrition \n                Sports Supplements \n                 Supplements Shakers \n                 Supplements Shakers \nPryce159 \nDicos80 \n\n❌Regular Price  : ₹  799    /- 😱"
     }
    ]
   },
   {
    "id": 21161,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683424765",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "199",
    "dicos": "80",
    "catagory":"Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "BSB HOME® Glace Cotton 160 TC Football Printed Double Bedsheets with 2 King Size Pillow Cover, (90X90 Inches, 7 x 7.5 Feet, Blue and Grey)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3phwbAr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VG9bHd"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LICyV3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pepj6W"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44vFuNn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nJN2eB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VBUFQY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VGlnaV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42axkIz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pipmPb"
     },
     {
      "type": "plain",
      "text": "\n\n @199\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51ztm8UK7hL.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Home Furnishing \n                Bedding & Linen \n                Bedsheets \n                Bedsheets \nPryce199 \nDicos80 \n\n❌Regular Price  : ₹    899 /- 😱"
     }
    ]
   },
   {
    "id": 21162,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683424832",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "999",
    "dicos": "60",
    "catagory":"Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "KENT 16105 Crisp Pop Up Toaster 700Watts | 2 Slice Automatic Pop up electric Toaster | 6 Heating Modes | Auto Shut off | Removable bread crumbs tray | White✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lxog9m"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nJNjhD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/423o88N"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nJNp8Z"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LImB12"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LFBT6L"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LFBT6L"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B20MV6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B20MV6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nAitIe"
     },
     {
      "type": "plain",
      "text": "\n\n @999\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/317sL7bpeRL.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Home Appliances \n                Small Kitchen Appliances \n                 Pop-up Toasters \n                 Pop-up Toasters \nPryce999 \nDicos60 \n\n❌Regular Price  : ₹   1999  /- 😱"
     }
    ]
   },
   {
    "id": 21163,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683424901",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "499",
    "dicos": "75",
    "catagory":"Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "PHILIPS Wiz Wi-Fi Enabled B22 9-Watt LED Smart Bulb, Compatible with Amazon Alexa and Google Assistant(16M Colours +Shades of White + Dimmable + Tunable),Pack of 1✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B2prJi"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AYWJsG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44OaGaP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NHGDev"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nzvvFW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nzvvFW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AYWRIG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B6zdKc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41aJGz1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B16bM9"
     },
     {
      "type": "plain",
      "text": "\n\n @499\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/41h6q1-dr3L._SY445_SX342_QL70_FMwebp_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Indoor Lighting \n                Light Bulbs \n                 LED Bulbs \n                 LED Bulbs \nPryce499 \nDicos75 \n\n❌Regular Price  : ₹  1399    /- 😱"
     }
    ]
   },
   {
    "id": 21164,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683424947",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "99",
    "dicos": "80",
    "catagory":"Clothing",
    "text_entities": [
     {
      "type": "plain",
      "text": "PalmTree Girls' Orange Knits Top | KnitsHand-wash | Regular Fit✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HMF6A3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B3LejU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nCKcYU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HLs9GF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HLs9GF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41rX6XL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HLetLY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HMFcrp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HLeuQ2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/4172TBP"
     },
     {
      "type": "plain",
      "text": "\n\n @99\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/A1zXQfYGtXL._UX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Clothing"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Clothing & Accessories \n                Baby \n                Baby Girls \n                Tops, T-Shirts & Shirts \n                Tops, T-Shirts & Shirts \nPryce99 \nDicos80 \n\n❌Regular Price  : ₹ 499   /- 😱"
     }
    ]
   },
   {
    "id": 21165,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683425015",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "299",
    "dicos": "57",
    "catagory":"Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Attro Nonstick Grill Sandwich Toaster,Black✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HLZy4g"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HObLVX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LZG0Mf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M1ZBeN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M36nRx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nF0hgQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3McAQwF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42ovqUA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/428RxOQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42dq1Qc"
     },
     {
      "type": "plain",
      "text": "\n\n @299\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71A768jXBPL._SX522_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Home Appliances \n                Small Kitchen Appliances \n                 Sandwich Makers \n                 Sandwich Makers \nPryce299 \nDicos57 \n\n❌Regular Price  : ₹  699  /- 😱"
     }
    ]
   },
   {
    "id": 21166,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683425082",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "124",
    "dicos": "59",
    "catagory":"Tools",
    "text_entities": [
     {
      "type": "plain",
      "text": "Wipro 3 way multiplug with built in Surge Protector✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nF07Gg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NKS1WY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NLtjWI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NQYBeV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NLqzsr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NH7kQJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VE6VQN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pePb2z"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NTDRCW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NLqCED"
     },
     {
      "type": "plain",
      "text": "\n\n @124\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61YtQOOAq2L._SX466_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Tools"
     },
     {
      "type": "plain",
      "text": " & Home Improvement\n\n                Electronics \n                Power Accessories \n                 Surge Protectors \nPryce124 \nDicos59 \n\n❌Regular Price  : ₹  300    /- 😱"
     }
    ]
   },
   {
    "id": 21167,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683425148",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "198",
    "dicos": "90",
    "catagory":"Shoes",
    "text_entities": [
     {
      "type": "plain",
      "text": "Bagsy Malone Women's Vegan Leather Iconic Sling Bag | Ladies Purse Handbag✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NH7yY5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pjePD0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nEYKak"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LFWd7R"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nJNUzT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41i24WB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42wJviI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42nbMrU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LIl7Uk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42u1WVd"
     },
     {
      "type": "plain",
      "text": "\n\n @198\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61cRPXirwGL._UY695_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Shoes"
     },
     {
      "type": "plain",
      "text": " & Handbags\n\n                Fashion \n                Pongal || Fashion accessories \n                Handbags \n                 Women wallets \n                 Women wallets \nPryce198 \nDicos90 \n\n❌Regular Price  : ₹  1499   /- 😱"
     }
    ]
   },
   {
    "id": 21168,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683425214",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "170",
    "dicos": "NaN",
    "catagory":"Beauty",
    "text_entities": [
     {
      "type": "plain",
      "text": "L.A. COLORS Eyeliner Pencil Matte Finish, Silver✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42ynNdU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HLePCi"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42vKT57"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42xp852"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42yjyiv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42AEkON"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42w7yOB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42AqaNH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44OaJDx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44EpYif"
     },
     {
      "type": "plain",
      "text": "\n\n @170\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/318HrD217CL.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Beauty"
     },
     {
      "type": "plain",
      "text": "\n\n                Beauty \n                Make-up \n                Eyes \n                 Eyeliners \n                 Eyeliners \nPryce170 \nDicosNaN \n\n❌Regular Price  : ₹  170    /- 😱"
     }
    ]
   },
   {
    "id": 21169,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683425257",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "62",
    "dicos": "50",
    "catagory":"Beauty",
    "text_entities": [
     {
      "type": "plain",
      "text": "Himalaya Purifying Neem Micellar Water 100ml, Cleanser for Soft Skin, Remove waterproof makeup, Cleanses Oil, Dirt, Impurities and get Glowing Skin✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42uWYaz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44yIcl5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42d8AiH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42ypisW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42fcqYW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/427yggK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VGlFP3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42hau1V"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VMeWTV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3phwFXh"
     },
     {
      "type": "plain",
      "text": "\n\n @62\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51FlLI9eByL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Beauty"
     },
     {
      "type": "plain",
      "text": "\n\n                Beauty \n                Skin Care \n                Face \n                Cleansing Creams & Milks \n                Cleansing Creams & Milks \nPryce62 \nDicos50 \n\n❌Regular Price  : ₹  126   /- 😱"
     }
    ]
   },
   {
    "id": 21170,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683425301",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "460",
    "dicos": "80",
    "catagory":"Clothing",
    "text_entities": [
     {
      "type": "plain",
      "text": "hummel Men's Cotton & Polyester Roundneck Sweatshirt✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3phZtPr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VBkDnH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pepRtw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VCTGzU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VGlL9n"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44ExIAS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44yvrXN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42ciX6A"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42cDyYj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42cAGea"
     },
     {
      "type": "plain",
      "text": "\n\n @460\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61JFXiYQoLL._UY741_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Clothing"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Clothing & Accessories \n                Men \n                Winter Wear \n                Sweatshirts & Hoodies \n                Sweatshirts & Hoodies \nPryce460 \nDicos80 \n\n❌Regular Price  : ₹  1999    /- 😱"
     }
    ]
   },
   {
    "id": 21171,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683425366",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "714",
    "dicos": "69",
    "catagory":"Shoes",
    "text_entities": [
     {
      "type": "plain",
      "text": "Lotto Womens Sancia Running Shoes✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nK4oYK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42coiuq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LH2no9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42f3faM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LIn4jO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LFWxDB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LHBP6b"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pePxWX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LGW4RD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LF6Doq"
     },
     {
      "type": "plain",
      "text": "\n\n @714\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/819e91NRTbL._UY695_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Shoes"
     },
     {
      "type": "plain",
      "text": " & Handbags\n\n                Shoes & Handbags \n                Shoes \n                Women's Shoes \n                Sports & Outdoor Shoes \n                Sports & Outdoor Shoes \nPryce714 \nDicos69 \n\n❌Regular Price  : ₹  1999    /- 😱"
     }
    ]
   },
   {
    "id": 21172,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683425434",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "999",
    "dicos": "70",
    "catagory":"Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Pigeon Basics Non Induction Base Nons tick Aluminium Cookware set, including Nonstick Dosa Tawa, Nonstick Kadai With Glass Lid, and Nonstick Frying Pan, (Pink)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B3RVSM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M1HZzs"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LF3oNI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M2w1pq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nCpcBv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B02aHN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B031YX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AZDGi7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nAdg3c"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44DjVtY"
     },
     {
      "type": "plain",
      "text": "\n\n @999\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61JlllBcGpL._SX522_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Dining \n                Cookware \n                Pots & Pans \n                Pots & Pans \nPryce999 \nDicos70 \n\n❌Regular Price  : ₹  2499  /- 😱"
     }
    ]
   },
   {
    "id": 21173,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683425501",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "99",
    "dicos": "89",
    "catagory":"Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Sehaz Artworks Key Holder for Home | Wall Stylish Key Stand | Key Hanger | Key Chain Holders for Wall (7 Hooks, 5 Birds)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HHBO1e"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B2q8Co"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B2cNd7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B18nmT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nAdHKS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NNDMRy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nF6HfR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NLvNUL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HMFKgX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41fllbn"
     },
     {
      "type": "plain",
      "text": "\n\n @99\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/418Z-65Bv0L._SX300_SY300_QL70_FMwebp_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Home Storage & Organisation \n                Home Storage Hooks \n                 Key Holders \n                 Key Holders \nPryce99 \nDicos89 \n\n❌Regular Price  : ₹  499  /- 😱"
     }
    ]
   },
   {
    "id": 21174,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683425571",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "299",
    "dicos": "70",
    "catagory":"Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Digital Alarm Clock,Battery Operated Small Desk Clocks,with Date,Indoor Temperature,Smart Night Light,LCD Electronic Clock for Bedroom Home Office - White✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41aKeoz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41aKeoz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41i2uw9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pjKKTP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HM4IgA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/418Q3mp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/418QaOR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HMFX3J"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M2xVpZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LYkojf"
     },
     {
      "type": "plain",
      "text": "\n\n @299\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61eBxfmK4YL._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Home & Décor \n                Clocks \n                 Alarm Clocks \n                 Alarm Clocks \nPryce299 \nDicos70 \n\n❌Regular Price  : ₹  899    /- 😱"
     }
    ]
   },
   {
    "id": 21175,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683425640",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "149",
    "dicos": "75",
    "catagory":"Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Amazon Brand - Solimo Plastic 500 ml Large Vegetable Chopper with 3 Blades, Blue✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nyYud9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M4Ls0t"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M3fXUm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M3fXUm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M20h3P"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NLtMIs"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nCbzSY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VCTWyS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VD1KAA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NJrBoy"
     },
     {
      "type": "plain",
      "text": "\n\n @149\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/41Aii85Bg-L._SX300_SY300_QL70_FMwebp_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Dining \n                Kitchen Tools \n                 Manual Choppers & Chippers \n                 Manual Choppers & Chippers \nPryce149 \nDicos75 \n\n❌Regular Price  : ₹  600    /- 😱"
     }
    ]
   },
   {
    "id": 21176,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683425689",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "159",
    "dicos": "71",
    "catagory":"Toys",
    "text_entities": [
     {
      "type": "plain",
      "text": "Vibgyor Vibes Chinese Puzzle Wooden Tangram for Kids✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VMfmcX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VGa5n5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VGLa35"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NMkuMf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NNDUk0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B03lXF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://www.amazon.in/dp/B07ZB8Y8XM?smid=A2GTG1HPYW8M2P&amp;psc=1&amp;th=1&amp;linkCode=sl1&amp;tag=avi1deals7-21&amp;linkId=b054dfc3a1190a5ae4552edcea611092&amp;language=en_IN&amp;ref_=as_li_ss_tl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NKSGHW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nCpxnL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NGjych"
     },
     {
      "type": "plain",
      "text": "\n\n @159\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71T3hIKvtnL._SX450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Toys"
     },
     {
      "type": "plain",
      "text": " & Games\n\n                Toys & Games \n                Puzzles \n                 Wooden Puzzles \nPryce159 \nDicos71 \n\n❌Regular Price  : ₹  549   /- 😱"
     }
    ]
   },
   {
    "id": 21177,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683425754",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "91",
    "dicos": "51",
    "catagory":"Beauty",
    "text_entities": [
     {
      "type": "plain",
      "text": "Everyuth Naturals Purifying Neem Face Wash, 150gm, Tube✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LE7hST"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/416VGla"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41baCP1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42wJXNW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42yolAu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LEsg8f"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41e5cDd"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42vLt2N"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nFLuSX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pbB9yt"
     },
     {
      "type": "plain",
      "text": "\n\n @91\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71ZlFa8Iw6L._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Beauty"
     },
     {
      "type": "plain",
      "text": "\n\n                Beauty \n                Skin Care \n                Face \n                Cleansing Creams & Milks \n                Cleansing Creams & Milks \nPryce91 \nDicos51 \n\n❌Regular Price  : ₹  185    /- 😱"
     }
    ]
   },
   {
    "id": 21178,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683425800",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "240",
    "dicos": "31",
    "catagory":"Beauty",
    "text_entities": [
     {
      "type": "plain",
      "text": "Khadi Organique Sunscreen Lotion Cream (SPF 40)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42vLi7D"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HLt5Lb"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42xYAQS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42wHgvH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3paE5LH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42zAx4j"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42vLDHr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pbB7GR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pipIoP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44xQOIB"
     },
     {
      "type": "plain",
      "text": "\n\n @240\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51tkdgCPIWL._SY450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Beauty"
     },
     {
      "type": "plain",
      "text": "\n\n                Beauty \n                Skin Care \n                Face \n                Sunscreen & Aftercare \n                Sunscreen & Aftercare \nPryce240 \nDicos31 \n\n❌Regular Price  : ₹   349  /- 😱"
     }
    ]
   },
   {
    "id": 21179,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683425867",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "339",
    "dicos": "57",
    "catagory":"Pet",
    "text_entities": [
     {
      "type": "plain",
      "text": "AmazonBasics Tie-Out Cable/Leash for Dogs up to 41 Kg, 25 Feet✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42fddcm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3peGo0o"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42rvp2B"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44tefmm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3peZHGT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VCRmsO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44xQWrz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44tegqq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44BSOQ7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pfniHz"
     },
     {
      "type": "plain",
      "text": "\n\n @339\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/41SMfZw2YKL._SY300_SX300_QL70_FMwebp_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Pet"
     },
     {
      "type": "plain",
      "text": " Supplies\n\n                Pet Supplies \n                Dogs \n                Collars, Harnesses & Leashes \n                Leashes \n                Leashes \nPryce339 \nDicos57 \n\n❌Regular Price  : ₹   749  /- 😱"
     }
    ]
   },
   {
    "id": 21180,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683425935",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "189",
    "dicos": "56",
    "catagory":"Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Amazon Brand - Solimo Fridge Organizer Set (15 CM*16 CM, 4 pieces)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44DkdRA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VUbcA1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LBJ285"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VHsjoA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LGWzLv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3peZQKr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pkhuN6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LEslc3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VLwkZ6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VE7JFj"
     },
     {
      "type": "plain",
      "text": "\n\n @189\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71JOJW+fPBL._SX522_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Large Appliances \n                Parts & Accessories \n                Refrigerator Parts & Accessories \n                Refrigerator Parts & Accessories \nPryce189 \nDicos56 \n\n❌Regular Price  : ₹  425   /- 😱"
     }
    ]
   },
   {
    "id": 21181,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683426002",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "93",
    "dicos": "50",
    "catagory":"Beauty",
    "text_entities": [
     {
      "type": "plain",
      "text": "Nivea Face Wash For Normal Skin, Milk Delights Saffron, 100ml✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LGWGXr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VBNwjz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LFCZzp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M3X0Ri"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M3X0Ri"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LZVpMy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nEZCM8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LE7BRB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M2ifD3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pkhDjC"
     },
     {
      "type": "plain",
      "text": "\n\n @93\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71cOkJ0eTGL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Beauty"
     },
     {
      "type": "plain",
      "text": "\n\n                Beauty \n                Skin Care \n                Face \n                Cleansing Creams & Milks \n                Cleansing Creams & Milks \nPryce93 \nDicos50 \n\n❌Regular Price  : ₹   185   /- 😱"
     }
    ]
   },
   {
    "id": 21182,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683426045",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "49",
    "dicos": "74",
    "catagory":"Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Crystal Nylon Small Ladle (Color May Vary)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44ztNFj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AZMqEM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44EqPiX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AXNHwd"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NLw9L5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nJOHkl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nwJ7Sy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AZsm5g"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44teH42"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nK5eVo"
     },
     {
      "type": "plain",
      "text": "\n\n @49\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51co7ktQBQL._SX522_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Dining \n                Kitchen Tools \n                 Ladles \n                 Ladles \nPryce49 \nDicos74 \n\n❌Regular Price  : ₹  190   /- 😱"
     }
    ]
   },
   {
    "id": 21183,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683426090",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "98",
    "dicos": "41",
    "catagory":"Grocery",
    "text_entities": [
     {
      "type": "plain",
      "text": "Tea Treasure Organic Spearmint Tea - 10 Pyramid Tea Bags | Tea for PCOS & PCOD |Antioxidants Rich Refreshing Tea | Helps Cure Hormonal Imbalance | Cure Acne & Facial Hair | Spearmint Tea Bags✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NKSSa8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AZ6oj5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AZMKmY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NKq7KM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AZsCBg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NMEqyG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41flUC1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B2oUXW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B2p8hK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HHDXJZ"
     },
     {
      "type": "plain",
      "text": "\n\n @98\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/81vgGKJskaL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Grocery"
     },
     {
      "type": "plain",
      "text": " & Gourmet Foods\n\n                Grocery & Gourmet Foods \n                Coffee, Tea & Beverages \n                Tea \n                Fruit & Herbal Tea \n                Fruit & Herbal Tea \nPryce98 \nDicos41 \n\n❌Regular Price  : ₹  165  /- 😱"
     }
    ]
   },
   {
    "id": 21184,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683426134",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "453",
    "dicos": "89",
    "catagory":"Clothing",
    "text_entities": [
     {
      "type": "plain",
      "text": "RIVER Ashish N Soni Premium Designer Men's Festive Kurta with Pintucks in 100% Cotton_RS2ASKU012B_Blue✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HO3f9E"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nCqbSd"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nAjGiK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HLla0H"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HMDYwB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41e9Il5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HMGhiX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41gBD3E"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HLfB2a"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HJP8BW"
     },
     {
      "type": "plain",
      "text": "\n\n @453\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/612v7SihuaL._UX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Clothing"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Clothing & Accessories \n                Men \n                Ethnic Wear \n                 Kurtas \n                 Kurtas \nPryce453 \nDicos89 \n\n❌Regular Price  : ₹  1999    /- 😱"
     }
    ]
   },
   {
    "id": 21185,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683426202",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "799",
    "dicos": "70",
    "catagory":"Clothing",
    "text_entities": [
     {
      "type": "plain",
      "text": "VIP Polypropylene 48 Cms Travel Bag(DFENDEBRN_Brown)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pjC4wM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42d9rzV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HNM1sK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41c3xxR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pmaI9e"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41cXRnj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42cjDJa"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M51rf2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M09fOS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3McCdvj"
     },
     {
      "type": "plain",
      "text": "\n\n @799\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61xivaarweL._UX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Clothing"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Bags, Wallets and Luggage \n                Luggage \n                 Travel Duffles \nPryce799 \nDicos70 \n\n❌Regular Price  : ₹  1999   /- 😱"
     }
    ]
   },
   {
    "id": 21186,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683426270",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "2998",
    "dicos": "50",
    "catagory":"Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "AGARO 33398 Rapid 1000-Watt, 10-Litre Wet & Dry Vacuum Cleaner, with Blower Function (Red & Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/429zfgD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M20CU9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VEdGCa"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M2iJt1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LZHvKn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NLIjnn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M1uksd"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VCUuEW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NLrYPJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NQZAvD"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@2998"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/715l1odFnbL._SX522_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Home Appliances \n                Vacuum, Cleaning & Ironing \n                Vacuums & Floor Care \n                Vacuums & Floor Care \nPryce2998 \nDicos50 \n\n❌Regular Price  : ₹  4999   /- 😱"
     }
    ]
   },
   {
    "id": 21187,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683426316",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "649",
    "dicos": "35",
    "catagory":"Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "GREENCHEF Marco Aluminium Pressure Cooker Outer Lid 3 Litre capacity, ISI Certified, Silver Colour✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VIr20u"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NLInDD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NLwqO7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NTEPz4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nA35LZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NMExu6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ntv0xm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NK6VNi"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B3T5O8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NOOmrg"
     },
     {
      "type": "plain",
      "text": "\n\n @649\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61HVIvLiVRL._SX466_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Dining \n                Cookware \n                Pots & Pans \n                Pots & Pans \nPryce649 \nDicos35 \n\n❌Regular Price  : ₹ 999   /- 😱"
     }
    ]
   },
   {
    "id": 21188,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683426364",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "649",
    "dicos": "58",
    "catagory":"Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Pigeon 1.5 litre Hot Kettle and Stainless Steel Water Bottle Combo used for boiling Water, Making Tea and Coffee, Instant Noodles, Soup, 1500 Watt with Auto Shut- off Feature - (Silver)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B1cU8V"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NM0Z6G"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41h1HvA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41h1HvA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B1cWxz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41fmkIB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AZN59e"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AZN59e"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/416W7Mk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pkTCZp"
     },
     {
      "type": "plain",
      "text": "\n\n @649\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51VvdjBgB5L._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Home Appliances \n                Small Kitchen Appliances \n                Kettles & Hot Water Dispensers \n                Kettles & Hot Water Dispensers \nPryce649 \nDicos58 \n\n❌Regular Price  : ₹  1299   /- 😱"
     }
    ]
   },
   {
    "id": 21189,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683426431",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "148",
    "dicos": "50",
    "catagory":"Beauty",
    "text_entities": [
     {
      "type": "plain",
      "text": "REALMAN Fresh Spirit Deodorant, Strong Men’s Body Spray, Long Lasting Fragrance, 200ml✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LDehPX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LxEDTy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://www.amazon.in/dp/B0B2WRR826?smid=A15APWRK6P7LBV&amp;th=1&amp;linkCode=sl1&amp;tag=deals103-21&amp;linkId=de87a33ec2bcf18834caec777e0ef0b9&amp;language=en_IN&amp;ref_=as_li_ss_tl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LDCiGQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42xZ9tY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p5Mb8n"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42ndaL8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42zB06z"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42w8T85"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42wKv6s"
     },
     {
      "type": "plain",
      "text": "\n\n @148\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61wK3MiUlnL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Beauty"
     },
     {
      "type": "plain",
      "text": "\n\n                Beauty \n                Bath & Body \n                Deodorants & Antiperspirants \n                 Deodorant \n                 Deodorant \nPryce148 \nDicos50 \n\n❌Regular Price  : ₹ 299  /- 😱"
     }
    ]
   },
   {
    "id": 21190,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683426476",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "470",
    "dicos": "88",
    "catagory":"Clothing",
    "text_entities": [
     {
      "type": "plain",
      "text": "RIVER Men's Regular Fit Shirt✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42u5t5Y"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42xqfSg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42tk55q"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42nddXk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p6XbCs"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42Ar1xT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3piq92r"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42ykzaj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42yqf4u"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44yJk8j"
     },
     {
      "type": "plain",
      "text": "\n\n @470\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/613Q6HLjVOL._UY500_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Clothing"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Fashion \n                Designer Boutique \n                Trends \n                t \n                t \nPryce470 \nDicos88 \n\n❌Regular Price  : ₹ 2999   /- 😱"
     }
    ]
   },
   {
    "id": 21191,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683426542",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "130",
    "dicos": "50",
    "catagory":"Beauty",
    "text_entities": [
     {
      "type": "plain",
      "text": "Bajaj 100% Pure Coconut Oil 600 ml✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42ayJyP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44D5RB2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42f4iaI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pbqWCd"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44DkJz0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42rvVO5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3peH3Pq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VEdOSa"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VEBbes"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3phxM9p"
     },
     {
      "type": "plain",
      "text": "\n\n @130\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51bP5mNwo3L._SY450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Beauty"
     },
     {
      "type": "plain",
      "text": "\n\n                Beauty \n                Hair Care \n                 Hair Oils \nPryce130 \nDicos50 \n\n❌Regular Price  : ₹  261   /- 😱"
     }
    ]
   },
   {
    "id": 21192,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683426590",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "8999",
    "dicos": "68",
    "catagory":"Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "iFFALCON 80 cm (32 inches) HD Ready Smart LED TV 32F53 (Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pkhOvi"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44xRrSt"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44ywyGX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44ETV1p"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44zzy5S"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nEZU5G"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LImUsw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3peqT8S"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VGnYSq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pgU7nu"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@8999"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/714jds2jOkL._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Home Theater, TV & Video \n                Televisions \n                 Smart Televisions \n                 Smart Televisions \nPryce8999 \nDicos68 \n\n❌Regular Price  : ₹  24999   /- 😱"
     }
    ]
   },
   {
    "id": 21193,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683426634",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "792",
    "dicos": "72",
    "catagory":"Shoes",
    "text_entities": [
     {
      "type": "plain",
      "text": "Puma Men's Slyde DP Sneakers✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42arpmU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VUbKWB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42iHbfC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42cpiPc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42ck5qQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LBJFyt"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pi147T"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VGuoRr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42d9XOn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42d9XOn"
     },
     {
      "type": "plain",
      "text": "\n\n @792\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/81pupidVOHL._UX575_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Shoes"
     },
     {
      "type": "plain",
      "text": " & Handbags\n\n                Shoes & Handbags \n                Shoes \n                Women's Shoes \n                Casual Shoes \n                Casual Shoes \nPryce792 \nDicos72 \n\n❌Regular Price  : ₹  1999  /- 😱"
     }
    ]
   },
   {
    "id": 21194,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683426682",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1499",
    "dicos": "50",
    "catagory":"Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "Cosmic Byte Vulcan RGB Headphone Stand, Mouse Bungee and 4 Port USB 2.0 HUB with RGB Touch Control Button (Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VEBBl2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M3gSnM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42dxij3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M51Uhi"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M2wpnK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LDeC5b"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VIrnQO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LFDAB9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44zzF1i"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44ywW8n"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1499"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61Y8u4etjAL._SY606_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Computers & Accessories \n                Accessories & Peripherals \n                PC Gaming Peripherals \n                 Gaming Mice \n                 Gaming Mice \nPryce1499 \nDicos50 \n\n❌Regular Price  : ₹  24999   /- 😱"
     }
    ]
   },
   {
    "id": 21195,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683426731",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "998",
    "dicos": "33",
    "catagory":"Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Pigeon by Stovekraft 2 Slice Auto Pop up Toaster. A Smart Bread Toaster for Your Home (750 Watt) (black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B17mLz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LA2cLs"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LFDEAT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B3MCmC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LFDFVt"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LFDGsv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nAk0hs"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nz50AB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LH3C6N"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44BTuF9"
     },
     {
      "type": "plain",
      "text": "\n\n @998\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51x15vgzI2L._SX522_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Home Appliances \n                Small Kitchen Appliances \n                 Pop-up Toasters \n                 Pop-up Toasters \nPryce998 \nDicos33 \n\n❌Regular Price  : ₹  1399   /- 😱"
     }
    ]
   },
   {
    "id": 21196,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683426778",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "165",
    "dicos": "83",
    "catagory":"Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "boAt Micro USB 550 Stress Resistant, Tangle-Free, Sturdy Cable with 3A Fast Charging & 480mbps Data Transmission, 10000+ Bends Lifespan and Extended 1.5m Length(Martian Red)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44Dl2d8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44yJJYn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44D7qik"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AZt8iG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B82u7j"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NFul6y"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B3MROy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B2rfC4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nuRFt7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B2pWmM"
     },
     {
      "type": "plain",
      "text": "\n\n @165\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61Zeu+gvDHL._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Computers & Accessories \n                Accessories & Peripherals \n                Cables & Accessories \n                Cables \n                Cables \nPryce165 \nDicos83 \n\n❌Regular Price  : ₹   899  /- 😱"
     }
    ]
   },
   {
    "id": 21197,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683426826",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "227",
    "dicos": "62",
    "catagory":"Beauty",
    "text_entities": [
     {
      "type": "plain",
      "text": "CGG COSMETICS PLUM VIBES BODY MIST no Sulphate & Parabens|long lasting fragnance|no alcohol|men & women 100ML✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HNMhIe"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HOcNkR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B2KlIe"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nAf10i"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nDMlDI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HOcVAN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B2rpJG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HNvqFH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HHH9FJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HM3x0O"
     },
     {
      "type": "plain",
      "text": "\n\n @227\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51VNoiHGhRL._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Beauty"
     },
     {
      "type": "plain",
      "text": "\n\n                Beauty \n                Fragrance \n                 Body Sprays & Mists \nPryce227 \nDicos62 \n\n❌Regular Price  : ₹ 599   /- 😱"
     }
    ]
   },
   {
    "id": 21198,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683426892",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "129",
    "dicos": "35",
    "catagory":"Beauty",
    "text_entities": [
     {
      "type": "plain",
      "text": "FACESCANADA Weightless Matte Finish Foundation - Beige 03, 18ml Ultra Blendable Smooth Texture, Natural Matte Finish, Anti-Ageing, Grape Extract, Shea Butter, Olive Seed Oil Enriched✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/417ma5T"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HKGPpA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41kE1GU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HO3OQO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pjgjNA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3I9gFgH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HHESdp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HM1Kc0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/416WxSU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/428T4o4"
     },
     {
      "type": "plain",
      "text": "\n\n @129\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61smfOEODeS._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Beauty"
     },
     {
      "type": "plain",
      "text": "\n\n                Beauty \n                Make-up \n                Face \n                 Foundation \n                 Foundation \nPryce129 \nDicos35 \n\n❌Regular Price  : ₹  199   /- 😱"
     }
    ]
   },
   {
    "id": 21199,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683426938",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "649",
    "dicos": "50",
    "catagory":"Health",
    "text_entities": [
     {
      "type": "plain",
      "text": "Amazon Brand - Presto! Matic Top Load Detergent Powder - 6 kg✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nBUpVA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3McCEpr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M3Y0Vy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M23LmM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M23Tmg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M2jnGX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M2j6DL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42bR6U2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M37Mrh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nz5dUp"
     },
     {
      "type": "plain",
      "text": "\n\n @649\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61JSo5lnG4L._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Health"
     },
     {
      "type": "plain",
      "text": " & Personal Care\n\n                Health & Personal Care \n                Household Supplies \n                Laundry \n                Laundry Detergents \n                Laundry Detergents \nPryce649 \nDicos50 \n\n❌Regular Price  : ₹  1199   /- 😱"
     }
    ]
   },
   {
    "id": 21200,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683426984",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "109",
    "dicos": "45",
    "catagory":"Beauty",
    "text_entities": [
     {
      "type": "plain",
      "text": "Colorbar Blend-Itude Makeup Sponge, Black✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VCV3yy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VDp5SG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M1XaZD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M383KQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NMETkq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nuvX8F"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NNEPRu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NNEKNG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M48IvB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VHtkNq"
     },
     {
      "type": "plain",
      "text": "\n\n @109\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71BV07+n+RL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Beauty"
     },
     {
      "type": "plain",
      "text": "\n\n                Beauty \n                Tools & Accessories \n                Make-up Brushes & Tools \n                Face \n                Face \nPryce109 \nDicos45 \n\n❌Regular Price  : ₹  199    /- 😱"
     }
    ]
   },
   {
    "id": 21201,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683427029",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "289",
    "dicos": "84",
    "catagory":"Clothing",
    "text_entities": [
     {
      "type": "plain",
      "text": "Integriti Men's Regular Fit Track Pants✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NLC4zT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NGKpVC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NLxcuv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NKqIMw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3O3nrs7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NK7PcE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B2rtZW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NHXVZ9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NFuG9k"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NLswoL"
     },
     {
      "type": "plain",
      "text": "\n\n @289\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/617n49Cw7-L._UX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Clothing"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Clothing & Accessories \n                Men \n                Sportswear \n                Track Pants \n                Track Pants \nPryce289 \nDicos84 \n\n❌Regular Price  : ₹   1199  /- 😱"
     }
    ]
   },
   {
    "id": 21202,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683427074",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "2405",
    "dicos": "63",
    "catagory":"Health",
    "text_entities": [
     {
      "type": "plain",
      "text": "Lifelong LLM378 Electric Foldable Foot Spa Massager with 8 Manual Massage Rollers, Digital Panel & Water Heating Technology for Pain Relief| Pedicure Machine| Foot Massager (1 Year Warranty, Brown)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41eaJtp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NHIFv9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/4174Oq1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B17N8F"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B03Ukj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B3BS7w"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/418RrFD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41bskCa"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41e6gHd"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/416AvQ8"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@2405"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51J7pFQSV3L._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Health"
     },
     {
      "type": "plain",
      "text": " & Personal Care\n\n                Health & Personal Care \n                Health Care \n                Massage & Relaxation \n                Electric Massagers \n                Electric Massagers \nPryce2405 \nDicos63 \n\n❌Regular Price  : ₹  4999    /- 😱"
     }
    ]
   },
   {
    "id": 21203,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683427123",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "5837",
    "dicos": "55",
    "catagory":"Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "Crucial MX500 1TB SATA 6.35 cm (2.5-inch) 7mm Internal SSD (CT1000MX500SSD1)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pmbsv2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41b9B9Y"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HMF0bX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41d9fiM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41d9fiM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42qHtQU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42uo9SR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pbCxkF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nDMFSW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LF7SE6"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@5837"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/81rg-38AdJL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Computers & Accessories \n                Components \n                 Internal Solid State Drives \nPryce5837 \nDicos55 \n\n❌Regular Price  : ₹  11999   /- 😱"
     }
    ]
   },
   {
    "id": 21204,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683427191",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1599",
    "dicos": "60",
    "catagory":"Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "Oppo Enco Buds Bluetooth True Wireless in Ear Earbuds(TWS) with Mic, 24H Battery Life, Supports Dolby Atmos Noise Cancellation During Calls, IP54 Dust & Water Resistant,(White, True Wireless)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42yqlsS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nCMiIg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3paF52p"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42tkIvO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42ypB6G"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p5MRKX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42cBW0S"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42zBLfV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42dGeoy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42teZGn"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1599"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/31L3NMP0gML._SX300_SY300_QL70_FMwebp_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Headphones, Earbuds & Accessories \n                Headphones \n                 In-Ear \n                 In-Ear \nPryce1599 \nDicos60 \n\n❌Regular Price  : ₹   2999   /- 😱"
     }
    ]
   },
   {
    "id": 21205,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683427238",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1499",
    "dicos": "50",
    "catagory":"Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "realme TechLife Buds T100 Bluetooth Truly Wireless in Ear Earbuds with mic, AI ENC for Calls, Google Fast Pair, 28 Hours Total Playback with Fast Charging and Low Latency Gaming Mode (Blue)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42u63AG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42a8isR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42ckvgU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42w9CWR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42dai3B"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42drIx2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pfIhdk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3peHppe"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42dhv3V"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42cF38T"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1499"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61q65Ob+nrL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Headphones, Earbuds & Accessories \n                Headphones \n                 In-Ear \n                 In-Ear \nPryce1499 \nDicos50 \n\n❌Regular Price  : ₹   2499   /- 😱"
     }
    ]
   },
   {
    "id": 21206,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683427283",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "115",
    "dicos": "50",
    "catagory":"Grocery",
    "text_entities": [
     {
      "type": "plain",
      "text": "Chheda's Salt N Pepper Banana Chips - Crispy Banana Chips (350g Pack of 1)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44yCWho"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VGZb0k"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VC06PV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44OcCjB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/427A8pM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VIrLie"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VCVkl4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44ArrpC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VGZicg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VGr7Sf"
     },
     {
      "type": "plain",
      "text": "\n\n @115\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71ndWd6UERL._SY606_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Grocery"
     },
     {
      "type": "plain",
      "text": " & Gourmet Foods\n\n                Grocery & Gourmet Foods \n                Snacks & Sweets \n                Snack Foods \n                Chips \n                Chips \nPryce115 \nDicos50 \n\n❌Regular Price  : ₹  210  /- 😱"
     }
    ]
   },
   {
    "id": 21207,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683427333",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "90",
    "dicos": "42",
    "catagory":"Grocery",
    "text_entities": [
     {
      "type": "plain",
      "text": "UNIBIC Fruit & Nut Cookies, 500 g✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VBaCqF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VBm7hL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44Dljga"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M0abBV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44EUoRd"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VHtDYA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44EUqbN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VBOkVD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42iHF5q"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44D7QoU"
     },
     {
      "type": "plain",
      "text": "\n\n @90\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/81JhuEwbGOL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Grocery"
     },
     {
      "type": "plain",
      "text": " & Gourmet Foods\n\n                Grocery & Gourmet Foods \n                Snacks & Sweets \n                Snack Foods \n                Biscuits & Cookies \n                Biscuits & Cookies \nPryce90 \nDicos42 \n\n❌Regular Price  : ₹ 140   /- 😱"
     }
    ]
   },
   {
    "id": 21208,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683427383",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "999",
    "dicos": "75",
    "catagory":"Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Amazon Basics 6 Piece Non-Stick Aluminium Cookware Set, Saucepan, Fry Pan (without Induction Base), Black✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LBKrvn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42hc8R9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VDpLYe"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LxFnbi"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pdoivM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VGnN9v"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42dhRrh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LIEBsd"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LF4Yz8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LDf8A9"
     },
     {
      "type": "plain",
      "text": "\n\n @999\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/81vJqi8AwYL._SX522_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Dining \n                Cookware \n                Pots & Pans \n                Pots & Pans \nPryce999 \nDicos75 \n\n❌Regular Price  : ₹  2499  /- 😱"
     }
    ]
   },
   {
    "id": 21209,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683427431",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "399",
    "dicos": "79",
    "catagory":"Clothing",
    "text_entities": [
     {
      "type": "plain",
      "text": "The Indian Garage Co Adult Men's Slim Fit Jogger (TIG/AW20/JOGGER-2-04_Blue_2XL)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LBKDuB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LDDxWw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VBaTdb"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://www.amazon.in/dp/B08L6QQKV2?smid=A1WYWER0W24N8S&amp;psc=1&amp;th=1&amp;linkCode=sl1&amp;tag=avi1deals-21&amp;linkId=661bb7daec122d3a6e40ca4173e45fa2&amp;language=en_IN&amp;ref_=as_li_ss_tl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LIELjj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44ErKjp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nzxnys"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44DAdTG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44tfOke"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LInDde"
     },
     {
      "type": "plain",
      "text": "\n\n @399\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/711J085HldL._UY500_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Clothing"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Clothing & Accessories \n                Men \n                Trousers \n                 Casual Trousers \n                 Casual Trousers \nPryce399 \nDicos79 \n\n❌Regular Price  : ₹ 999   /- 😱"
     }
    ]
   },
   {
    "id": 21210,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683427498",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "3299",
    "dicos": "43",
    "catagory":"Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Prestige Wet Grinder PWG 08, 200 watt, Black✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B17TNz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44N5fZE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AZFesr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B3NzLI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AZNM2k"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B04kqT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nAflMy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AYYRka"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B2KMCm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HMH3fR"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@3299"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51Rr+vTbstL._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Home Appliances \n                Small Kitchen Appliances \n                Mills & Grinders \n                Mills & Grinders \nPryce3299 \nDicos43 \n\n❌Regular Price  : ₹ 4999   /- 😱"
     }
    ]
   },
   {
    "id": 21211,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683427547",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "41490",
    "dicos": "41",
    "catagory":"Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "Sony Bravia 108 cm (43 inches) 4K Ultra HD Smart LED Google TV KD-43X74K (Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HO4c1I"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B04MW3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B1dNhL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41eaT3Z"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B2rYDi"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HLm5hF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41bP44U"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HOdoTD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HKfvrD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HLMwDG"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@41490"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/81wxS8abrgL._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Home Theater, TV & Video \n                Televisions \n                 Smart Televisions \n                 Smart Televisions \nPryce41490 \nDicos41 \n\n❌Regular Price  : ₹  60999  /- 😱"
     }
    ]
   },
   {
    "id": 21212,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683427599",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1299",
    "dicos": "80",
    "catagory":"Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "Gionee STYLFIT GSW11 Smartwatch with Large 1.8 IPS Display, SpO2 & Stress Monitor, Heart Rate Monitor & 200+ Watch Faces(State Silver)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/418REZr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HLmnFh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/418RL7j"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M21QyJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LYlPhD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M24Ef6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42rwM1f"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M0az4i"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M2zpk3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42dy2ER"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1299"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71MhwVch5QL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Electronics \n                Wearable Technology \n                 Smart Watches \nPryce1299 \nDicos80 \n\n❌Regular Price  : ₹4499    /- 😱"
     }
    ]
   },
   {
    "id": 21213,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683427645",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1999",
    "dicos": "60",
    "catagory":"Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Havells Glaze 1200mm 1 Star Energy Saving Ceiling Fan (Elegant White Gold, Pack of 1)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VIrZ94"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LYlNGx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NMm6Wj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VDq0T8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NFv6MW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NHIQqj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NLJBid"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VGv7SF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NTFMHE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NLJFhX"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1999"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/410nxidfTEL._SX466_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Heating, Cooling & Air Quality \n                Fans \n                 Ceiling Fans \n                 Ceiling Fans \nPryce1999 \nDicos60 \n\n❌Regular Price  : ₹ 4499   /- 😱"
     }
    ]
   },
   {
    "id": 21214,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683427691",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "203",
    "dicos": "75",
    "catagory":"Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Kolorr Stitch Pedal Waste Bin Modern Design Trash Can Plastic Dustbin - 7L (Daiso Grey)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NGLmNG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NLCIgN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41fngN7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/416X6fu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/416Xfj2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pmbRO4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41egjMm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LA33Ma"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LGXUC1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LInMgM"
     },
     {
      "type": "plain",
      "text": "\n\n @203\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/318y4y86VSL._SX300_SY300_QL70_FMwebp_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Home Storage & Organisation \n                Waste & Recycling \n                 Dustbins \n                 Dustbins \nPryce203 \nDicos75 \n\n❌Regular Price  : ₹ 499   /- 😱"
     }
    ]
   },
   {
    "id": 21215,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683427738",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1469",
    "dicos": "NaN",
    "catagory":"Car",
    "text_entities": [
     {
      "type": "plain",
      "text": "Off Road D/V Gangster White M.Blue Helmet-M✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HMFDCl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42tfgsT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3paFt0R"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42vN20F"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42tfBvF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42Asl3P"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42Asl3P"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42sCOhy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42t3Wgm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nF8mlB"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1469"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61Y3vxVgW2L._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Car"
     },
     {
      "type": "plain",
      "text": " & Motorbike\n\n                Car & Motorbike \n                Motorbike Accessories & Parts \n                Helmets \n                 Full Face Helmets \n                 Full Face Helmets \nPryce1469 \nDicosNaN"
     }
    ]
   },
   {
    "id": 21216,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683427788",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "449",
    "dicos": "77",
    "catagory":"Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "ASUS WT200 Wireless Mouse, Blue✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42uZ8qH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44A0JgK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44yDmV0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42y0fG6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42qI1WY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42vjy2T"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42oxUSU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pdoDP4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44yIp7Q"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42feBf4"
     },
     {
      "type": "plain",
      "text": "\n\n @449\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/31BSWfbGmHL._SX300_SY300_QL70_FMwebp_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Computers & Accessories \n                Accessories & Peripherals \n                Keyboards, Mice & Input Devices \n                 Mice \n                 Mice \nPryce449 \nDicos77 \n\n❌Regular Price  : ₹ 1499   /- 😱"
     }
    ]
   },
   {
    "id": 21217,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683427835",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "697",
    "dicos": "68",
    "catagory":"Shoes",
    "text_entities": [
     {
      "type": "plain",
      "text": "Centrino Mens Formal Shoes✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42y0kcS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pbDKZf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42bRP7I"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42wa87f"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42db00L"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44yIw3g"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VGrqMT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42dsvhu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42f5Gdq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44N5pQK"
     },
     {
      "type": "plain",
      "text": "\n\n @697\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61AjKQSFMhL._UY575_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Shoes"
     },
     {
      "type": "plain",
      "text": " & Handbags\n\n                Fashion \n                Steal deals PD \n                Last chance to buy: Min 60% off \n                Last chance to buy: Min 70% off \n                Last chance to buy: Min 70% off \nPryce697 \nDicos68 \n\n❌Regular Price  : ₹ 1999   /- 😱"
     }
    ]
   },
   {
    "id": 21218,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683427881",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "442",
    "dicos": "72",
    "catagory":"Baby",
    "text_entities": [
     {
      "type": "plain",
      "text": "OYO BABY - 100% Waterproof Mattress Protector/Reusable Bed Cover/Hypoallergenic/Breathable Absorbent Bed Protector/Dust-Proof Cotton Terry Bed Protector (60\"x72\") x (Skirting 10\")✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44z2gUp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VIs90a"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VIEotp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VGvdtv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44yxNG7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VBX7XG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3phzis7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VIshwG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VEf0oC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VGMPWn"
     },
     {
      "type": "plain",
      "text": "\n\n @442\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71KEWmw8zAL._SY450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Baby"
     },
     {
      "type": "plain",
      "text": "\n\n                Home & Kitchen \n                Home Furnishing \n                Bedding & Linen \n                 Mattresses Protectors \n                 Mattresses Protectors \nPryce442 \nDicos72 \n\n❌Regular Price  : ₹  1550  /- 😱"
     }
    ]
   },
   {
    "id": 21219,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683427933",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "143",
    "dicos": "76",
    "catagory":"Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "eCraftIndia handicrafted Decorative Wall/Door/Window Hanging Bells Wind Chimes Showpiece for Home Decor, Wall Decor, Pooja Room Temple, Diwali Gift, Corporate Gift✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44vInOd"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44zvhzn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nF0PDa"
     },
     {
      "type": "plain",
      "text": "\n\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44N5FPI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pes7ku"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pes7B0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pi27Vn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LHDTuX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42a92hD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42a92hD"
     },
     {
      "type": "plain",
      "text": "\n\n @143\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61uzBgdClDL._SX522_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Home & Décor \n                Decorative Accessories \n                 Bells \n                 Bells \nPryce143 \nDicos76 \n\n❌Regular Price  : ₹ 499   /- 😱"
     }
    ]
   },
   {
    "id": 21220,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683427980",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1899",
    "dicos": "46",
    "catagory":"Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "realme Buds Air 3 Neo True Wireless in-Ear Earbuds with Mic, 30 hrs Playtime with Fast Charging and Dolby Atmos Support (Starry Blue)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nK6Iiq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42dHeZQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LE9fTh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42dsGcE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/429AEnp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LJzYxQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LIo5Is"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3piGaWc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M03OzC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nz0nqf"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1899"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61wnFKjF1EL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Headphones, Earbuds & Accessories \n                Headphones \n                 In-Ear \n                 In-Ear \nPryce1899 \nDicos46 \n\n❌Regular Price  : ₹ 3099   /- 😱"
     }
    ]
   },
   {
    "id": 21221,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683428029",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "2299",
    "dicos": "54",
    "catagory":"Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "realme Buds Air 3S Bluetooth Truly Wireless in Ear Earbuds, 11mm Triple Titanium Driver, with Mic AI ENC for Calls, Dual Device Pairing, 30hrs Total Playback with Fast Charging (Bass Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M0b5PM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44yDZxQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44CiP1D"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M49icL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LA3tCe"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LHBtwq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44yKMrh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3McDCC5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pirwhB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B050MT"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@2299"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61R-dJO92TL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Headphones, Earbuds & Accessories \n                Headphones \n                 In-Ear \n                 In-Ear \nPryce2299 \nDicos54 \n\n❌Regular Price  : ₹ 4199   /- 😱"
     }
    ]
   },
   {
    "id": 21222,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683428075",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "249",
    "dicos": "69",
    "catagory":"Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "Mivi Rock and Roll E5 Wired In Ear Earphones with HD Sound and Extra Powerful Bass with Mic, Tangle Free & Extra Length Cable | Earphones for Mobile | Black  ✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B83uZ7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44D8rXG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3O3o30T"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B3O34u"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44Dm4Wy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AYuzhA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nC5VjH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AZ8rnh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HHInAP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B2qSHO"
     },
     {
      "type": "plain",
      "text": "\n\n @249\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/41tDRLWBPlL._SX300_SY300_QL70_FMwebp_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Headphones, Earbuds & Accessories \n                Headphones \n                 In-Ear \n                 In-Ear \nPryce249 \nDicos69 \n\n❌Regular Price  : ₹650    /- 😱"
     }
    ]
   },
   {
    "id": 21223,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683428123",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "449",
    "dicos": "76",
    "catagory":"Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "PTron Tangent Duo Bluetooth 5.2 Wireless in Ear Headphones, 13mm Driver, Deep Bass, HD Calls, Fast Charging Type-C Neckband, Dual Pairing, Voice Assistant & IPX4 Water Resistant (Black/Grey)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NLCMx3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NH9ZKd"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nuwNCl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3BkvwB7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B2qZDe"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NK8Y3W"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HHFHmv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HIrWEg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41cLrvx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nCN08o"
     },
     {
      "type": "plain",
      "text": "\n\n @449\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51dqU2OmQnL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Headphones, Earbuds & Accessories \n                Headphones \n                 In-Ear \n                 In-Ear \nPryce449 \nDicos76 \n\n❌Regular Price  : ₹  1250  /- 😱"
     }
    ]
   },
   {
    "id": 21224,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683428168",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1895",
    "dicos": "73",
    "catagory":"Watches",
    "text_entities": [
     {
      "type": "plain",
      "text": "Fastrack Reflex Vox Smartwatch|Alexa Built-in|Bright HD Display|Upto 10 Days Battery|5 ATM Water Resistance|Multiple Sports Modes|100+ Watchfaces|24x7 HRM|Sp02|Stress Monitor|Camera & Music Control✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B6BRzC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41c55YH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AZOA7m"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nFNcDR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41cZkdj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HM2u0M"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HKgesR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41e71Qz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HM2vlm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41dczuk"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1895"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51fgQjZgXGL._UX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Watches"
     },
     {
      "type": "plain",
      "text": "\n\n                Fashion \n                Onam 2021 \n                Watches and fashion jewellery \n                 Watches top brands \n                 Watches top brands \nPryce1895 \nDicos73 \n\n❌Regular Price  : ₹ 4499   /- 😱"
     }
    ]
   },
   {
    "id": 21225,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683428214",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1499",
    "dicos": "80",
    "catagory":"Watches",
    "text_entities": [
     {
      "type": "plain",
      "text": "Maxima Nitro 1.39\" Ultra HD One Tap Connection Bluetooth Calling Smartwatch, Premium Metallic Design, AI Voice Assistant, 600 Nits Display, HR & SpO2 Monitor,100+ Excercise Modes, Inbuilt Games✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HMG7bD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pjMVGZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41egIOS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HM7e6w"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42cqvpI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HKI6No"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pkViSH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HM2LAQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M1YdJ3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HOelvb"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1499"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61gMNE99Y6L._UX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Watches"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Wearable Technology \n                 Smart Watches \nPryce1499 \nDicos80 \n\n❌Regular Price  : ₹  4499  /- 😱"
     }
    ]
   },
   {
    "id": 21226,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683428260",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1595",
    "dicos": "54",
    "catagory":"Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "New Fastrack Reflex Beat+ 1.69” UltraVU Display|500 Nits Brightness|60 Sports Modes|24*7 Heart Rate Monitor|SpO2 Monitor|Sleep Tracker|IP68 Water Resistant|Music & Camera Control✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nF8IIX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nBVSLA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LZXcBg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M4eLQO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42bS8PU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M2kfex"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pbssUV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3O3o8BJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NMmy6X"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M2yCja"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1595"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71E-b8o-1hL._UX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Fashion \n                Onam 2021 \n                Watches and fashion jewellery \n                 Watches top brands \n                 Watches top brands \nPryce1595 \nDicos54 \n\n❌Regular Price  : ₹  2999  /- 😱"
     }
    ]
   },
   {
    "id": 21227,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683428307",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1075",
    "dicos": "49",
    "catagory":"Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Usha 3720 700-Watt 2-Slice Pop-up Toaster (Blue)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M4NFch"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M4NLk9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nuTg25"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VGpALY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M2y50w"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NR1GeZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NHJpQX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NMIZJc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NR1zQB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NLiUdn"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1075"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/41VqWCrVulL._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Home Appliances \n                Small Kitchen Appliances \n                 Pop-up Toasters \n                 Pop-up Toasters \nPryce1075 \nDicos49 \n\n❌Regular Price  : ₹ 1999   /- 😱"
     }
    ]
   },
   {
    "id": 21228,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683428376",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "299",
    "dicos": "83",
    "catagory":"Watches",
    "text_entities": [
     {
      "type": "plain",
      "text": "TIMEWEAR Analog Day Date Functioning Stainless Steel Chain Watch for Men✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NHYMZR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B83Lv7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NLwqOo"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41cLJm7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NGlRMt"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pjhqwK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nzyrCs"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/416XEly"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AZuJFc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LE5ihu"
     },
     {
      "type": "plain",
      "text": "\n\n @299\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/81nj6IlZpVL._UY500_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Watches"
     },
     {
      "type": "plain",
      "text": "\n\n                Watches \n                Trends \n                Men \n                 Chain link bands \n                 Chain link bands \nPryce299 \nDicos83 \n\n❌Regular Price  : ₹ 1199   /- 😱"
     }
    ]
   },
   {
    "id": 21229,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683428422",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "870",
    "dicos": "56",
    "catagory":"Shoes",
    "text_entities": [
     {
      "type": "plain",
      "text": "Reebok mens Rbk Fulgere Slide Flip-Flop✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LJgLMC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LJgXeO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pkVqBF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LBLlYN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B052V5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41baLlQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/418StBv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42ney0i"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41fnXGd"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p5NPa3"
     },
     {
      "type": "plain",
      "text": "\n\n @870\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71L1d22pvQL._UX575_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Shoes"
     },
     {
      "type": "plain",
      "text": " & Handbags\n\n                Shoes & Handbags \n                Shoes \n                Men's Shoes \n                 Flip-Flops & Slippers \n                 Flip-Flops & Slippers \nPryce870 \nDicos56 \n\n❌Regular Price  : ₹ 1699   /- 😱"
     }
    ]
   },
   {
    "id": 21230,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683428467",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "119",
    "dicos": "60",
    "catagory":"Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Fun Homes Pizza Cutter|Stainless Steel Extra Sharp Blade|Plastic Stylish Slicer with Handle Grip for Sandwich|Burger (Multicolor)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pjEbRe"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42tlRn6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42xrL6U"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42ym0pd"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p6YWj2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42uZD43"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42ym20j"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HLq0uL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pbEwp7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42u7fUG"
     },
     {
      "type": "plain",
      "text": "\n\n @119\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51+rcZ+LiuL._SX466_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Dining \n                Kitchen Tools \n                 Pizza Cutters \n                 Pizza Cutters \nPryce119 \nDicos60 \n\n❌Regular Price  : ₹250    /- 😱"
     }
    ]
   },
   {
    "id": 21231,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683428513",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "4799",
    "dicos": "58",
    "catagory":"Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Hindware Smart Appliances Cruzo 17L Personal Air Cooler in Summer with exclusive Insect and Dust free Filter Technology, with Ice Chamber & Honeycomb Pad, Inverter Compatible (Black & White)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42t4F14"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42yrpfY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/423qXGV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42dyCT3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44z2FpT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pfJn8W"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44BULMr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42dt1Ms"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44xTg1L"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44yKY9Z"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@4799"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/517xJ7Ikl-L._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Heating, Cooling & Air Quality \n                Air Coolers \n                 Portable \n                 Portable \nPryce4799 \nDicos58 \n\n❌Regular Price  : ₹ 8499   /- 😱"
     }
    ]
   },
   {
    "id": 21232,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683428561",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "28988",
    "dicos": "59",
    "catagory":"Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "AGARO Supreme Espresso Coffee Maker With Grinder, 20 Bars Semi Automatic, Dual Heating System, Steam Wand And Adjustable Milk Frothing, All in One Espresso, Cappuccino, Steam Espresso Maker✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/428UNty"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VCTPDA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VGcjCX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VC1Etd"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44A1EOe"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3peSarN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44Oe1GT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42aAps7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44zvUJf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3phzLun"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@28988"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71ZkWGIyZ9L._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Home Appliances \n                Coffee, Tea & Espresso \n                 Espresso Machines \n                 Espresso Machines \nPryce28988 \nDicos59 \n\n❌Regular Price  : ₹   54999 /- 😱"
     }
    ]
   },
   {
    "id": 21233,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683428608",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "2758",
    "dicos": "39",
    "catagory":"Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Inalsa Oven MasterChef 16BK OTG (16 Liters) with Temperature Selection-1300W, 4-Stage Heat Selection, Includes Baking Pan, SS Grill Tray, Tray Handle (Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VBnDjX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VBnDjX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VBnDjX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42yqXhS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pkjNje"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LEuzbp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VGvUD7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pfyKmq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/416XSZW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42bvQ0J"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@2758"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61k164kIGGL._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Home Appliances \n                Small Kitchen Appliances \n                 Oven Toaster Grills \n                 Oven Toaster Grills \nPryce2758 \nDicos39 \n\n❌Regular Price  : ₹  4199  /- 😱"
     }
    ]
   },
   {
    "id": 21234,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683428656",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "23999",
    "dicos": "44",
    "catagory":"Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "Redmi 108 cm (43 inches) 4K Ultra HD Android Smart LED TV X43 | L43R7-7AIN (Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LBLqvz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M39oBl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42oyCj2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M2Ajgr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LCJ67v"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pesFH4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nz6s61"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42dbIuX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VGvXPj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42vkvbt"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@23999"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/7132bixhytL._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Home Theater, TV & Video \n                Televisions \n                 Smart Televisions \n                 Smart Televisions \nPryce23999 \nDicos44 \n\n❌Regular Price  : ₹  34999  /- 😱"
     }
    ]
   },
   {
    "id": 21235,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683428702",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "252",
    "dicos": "60",
    "catagory":"Beauty",
    "text_entities": [
     {
      "type": "plain",
      "text": "Nivea Soft Light Moisturiser, 200ml And Nivea Naturally Good, Natural Avocado Body Lotion, 200ml✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nHiFW7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LGYBLD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3peIP32"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42zDkdN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42qQtFM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M2AmsD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nz0YrZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nz6uuF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42OKv1V"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/427BroG"
     },
     {
      "type": "plain",
      "text": "\n\n @252\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51v002ADB1L._SX569_PIbundle-2,TopRight,0,0_AA569SH20_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Beauty"
     },
     {
      "type": "plain",
      "text": "\n\n                Beauty \n                Bath & Body \n                Body Washes \n                 Body Lotions \n                 Body Lotions \nPryce252 \nDicos60 \n\n❌Regular Price  : ₹ 549   /- 😱"
     }
    ]
   },
   {
    "id": 21236,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683428752",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "99",
    "dicos": "73",
    "catagory":"Beauty",
    "text_entities": [
     {
      "type": "plain",
      "text": "Qraa Antibacterial Body Wash With Goodness Of Tulsi & Cucumber, Germ Protection Shower Gel, Soap Free, pH Balanced, Long Lasting Moisturizing, Sulfate Free Shower Gel 200ml✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42a9FI1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44zvWRn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44EVsEH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3piszyd"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/429Blx1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44D8UsU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nAls3o"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44z34sp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3phAeN9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/428VjYw"
     },
     {
      "type": "plain",
      "text": "\n\n @99\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/616LwWJgwSL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Beauty"
     },
     {
      "type": "plain",
      "text": "\n\n                Beauty \n                Bath & Body \n                Cleansers \n                 Body Wash Gels \n                 Body Wash Gels \nPryce99 \nDicos73 \n\n❌Regular Price  : ₹  249  /- 😱"
     }
    ]
   },
   {
    "id": 21237,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683428797",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "249",
    "dicos": "83",
    "catagory":"All",
    "text_entities": [
     {
      "type": "plain",
      "text": "Yashika Womens Solid Net Saree With Blouse Piece✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3paGUfL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LA4f26"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LHEI71"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44D8Wkw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nzyAG0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NOw09R"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B05G4T"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pfpTRP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44N6FmW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B05qmv"
     },
     {
      "type": "plain",
      "text": "\n\n @249\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71lDgj1rKhL._UY741_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#All"
     },
     {
      "type": "plain",
      "text": "\n\n                Fashion \n                Apparel Steal Deals \n                Apparel_1 \n                Women \n                Women \nPryce249 \nDicos83 \n\n❌Regular Price  : ₹ 1099   /- 😱"
     }
    ]
   },
   {
    "id": 21238,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683428864",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "123",
    "dicos": "86",
    "catagory":"Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Archies Valentine Gifts Polyester Blend Love Cushion (Standard)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/427BwZw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LF9s90"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LJhs8G"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B2fmff"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pesJGO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42dc4Sj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NMnf03"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B846hn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HO25ee"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LBM5x3"
     },
     {
      "type": "plain",
      "text": "\n\n @123\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/81J1R+V4fML._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Home Furnishing \n                Cushions & Cushion Covers \n                 Cushions \n                 Cushions \nPryce123 \nDicos86 \n\n❌Regular Price  : ₹ 649   /- 😱"
     }
    ]
   },
   {
    "id": 21239,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683428931",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "2824",
    "dicos": "72",
    "catagory":"Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Happer Premium Stainless Steel Double Supported 4 Layer Cloth Drying Stand with Thread Lock Mechanism and Breaking Wheels (King Jumbo, Blue)  ✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B6Cfy4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HNNS0G"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nAgyU6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41ehwTU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41h3u3M"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B3OLia"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HLhJHc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nFNJWn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pmcLtW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/416Y2k0"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@2824"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71jyXwLI26L._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Home Storage & Organisation \n                Laundry Organization \n                 Drying Racks \n                 Drying Racks \nPryce2824 \nDicos72 \n\n❌Regular Price  : ₹ 7499   /- 😱"
     }
    ]
   },
   {
    "id": 21240,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683428999",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "224",
    "dicos": "31",
    "catagory":"Garden",
    "text_entities": [
     {
      "type": "plain",
      "text": "Good knight Gold Flash Liquid Vapourizer | Mosquito Repellent Refill | Pack of 4 (45ml each)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41s0h1D"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41bQiNy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HO5SZ4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HM3bqU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HM7S3W"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41btKfY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HNO1RM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41cMgo7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HOeTkJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nC6KZP"
     },
     {
      "type": "plain",
      "text": "\n\n @224\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71LtQCvMu4L._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Garden"
     },
     {
      "type": "plain",
      "text": " & Outdoors\n\n                Health & Personal Care \n                Household Supplies \n                Indoor Insect & Pest Control \n                 Sprays \n                 Sprays \nPryce224 \nDicos31 \n\n❌Regular Price  : ₹   299 /- 😱"
     }
    ]
   },
   {
    "id": 21241,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683429066",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "199",
    "dicos": "60",
    "catagory":"Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "PAPER PLANE DESIGN Inspirational Motivational Wall Abstract Poster (45 cm x 30 cm x 0.2 cm) (Pack of 10) (Poster C) Multicolor✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42bwb3v"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LZJONx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M1Zjo9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M401RC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M2ziVK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42crjLg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M2yKPy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M2yKPy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LZY1dk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NLDg6l"
     },
     {
      "type": "plain",
      "text": "\n\n @199\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71wWy+qj39L._SX466_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Home & kitchen | Prime shopping days \n                Prime Units \n                 Home \n                 Home \nPryce199 \nDicos60 \n\n❌Regular Price  : ₹ 450   /- 😱"
     }
    ]
   },
   {
    "id": 21242,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683429133",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "360",
    "dicos": "4",
    "catagory":"Beauty",
    "text_entities": [
     {
      "type": "plain",
      "text": "Vaseline Intensive Care Deep Moisture Nourishing Body Lotion 400 ml, Daily Moisturizer for Dry Skin, Gives Non-Greasy, Glowing Skin - For Men & Women✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M4asoD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NMJumy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NLDnih"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NKVp48"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NR2ikN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VUdFdL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NOQeAi"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VH0V9S"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NHKlEX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NM3iqm"
     },
     {
      "type": "plain",
      "text": "\n\n @360\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/31tQ2BoB1vL._SX300_SY300_QL70_FMwebp_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Beauty"
     },
     {
      "type": "plain",
      "text": "\n\n                Beauty \n                Bath & Body \n                Body Washes \n                 Body Lotions \n                 Body Lotions \nPryce360 \nDicos4 \n\n❌Regular Price  : ₹  370  /- 😱"
     }
    ]
   },
   {
    "id": 21243,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683429203",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "5699",
    "dicos": "43",
    "catagory":"Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Crompton Marvel Neo Inverter Compatible Portable Personal Air Cooler (40L, White).✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nAgFiu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/416CcNu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NLySEj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41btRYW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B3Vp7O"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B3Vsk0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B3OV9g"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41c5ZEz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AZGImv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42waXwR"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@5699"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/41V0bcp5nsL._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Heating, Cooling & Air Quality \n                Air Coolers \n                 Portable \n                 Portable \nPryce5699 \nDicos43 \n\n❌Regular Price  : ₹ 8999   /- 😱"
     }
    ]
   },
   {
    "id": 21244,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683429247",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "112",
    "dicos": "50",
    "catagory":"Beauty",
    "text_entities": [
     {
      "type": "plain",
      "text": "AXE Alaska 3 In 1 Body, Face & Hair Wash for Men, Long-Lasting Refreshing Ocean Air & Bergamot Fragrance Natural Origin Ingredients,Removes Odor & Bacteria, No Parabens, Dermatologically Tested, 250ml✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LDFbaE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42ymsUr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p5OCYz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LH5Rad"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HKgS9L"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42sDS52"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p6ZvJG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41bbvHE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42sDT96"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42t5jvw"
     },
     {
      "type": "plain",
      "text": "\n\n @112\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51c6KZeIFUL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Beauty"
     },
     {
      "type": "plain",
      "text": "\n\n                Beauty \n                Bath & Body \n                Cleansers \n                 Body Wash Gels \n                 Body Wash Gels \nPryce112 \nDicos50 \n\n❌Regular Price  : ₹ 210   /- 😱"
     }
    ]
   },
   {
    "id": 21245,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683429315",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "11999",
    "dicos": "70",
    "catagory":"Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "Creative Stage 360 2.1 240W Soundbar with Dolby Atmos and Subwoofer for TV/Computers/Ultrawide Monitors, Surround Sound with HDMI ARC/2.0, Bluetooth, Optical Input, and IR Remote✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42sDUtG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42y1tkG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44Et7P5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42ymQ5l"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44thtGu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44thtGu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42wMSGo"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42vOgsN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42yscNY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42yrKPS"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@11999"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61rL5eKZcqL._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Home Audio \n                Speakers \n                 Soundbar Speakers \n                 Soundbar Speakers \nPryce11999 \nDicos70 \n\n❌Regular Price  : ₹  29999  /- 😱"
     }
    ]
   },
   {
    "id": 21246,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683429382",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1999",
    "dicos": "56",
    "catagory":"Watches",
    "text_entities": [
     {
      "type": "plain",
      "text": "TIMEX Analog Blue Dial Women's Watch-TWEL155SMU08✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42sEF60"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pdqinM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44EtnO3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44D8ItK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VBcuQd"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VGOs6r"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VEaKFD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/423rOr7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44yLMvx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44EtLvZ"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1999"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51RZJv5tX9L._UX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Watches"
     },
     {
      "type": "plain",
      "text": "\n\n                Fashion \n                Onam 2021 \n                Watches and fashion jewellery \n                 Watches top brands \n                 Watches top brands \nPryce1999 \nDicos56 \n\n❌Regular Price  : ₹ 3999   /- 😱"
     }
    ]
   },
   {
    "id": 21247,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683429449",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "270",
    "dicos": "40",
    "catagory":"Car",
    "text_entities": [
     {
      "type": "plain",
      "text": "Probiker Synthetic Leather Motorcycle Gloves (Black, M)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VH13WU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LJB8tc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VCUODM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VEgrU2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42fglVE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LEvo3Z"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M0cfd9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M0cfd9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VGwK2J"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LxsNIU"
     },
     {
      "type": "plain",
      "text": "\n\n @270\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/714GthecvgL._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Car"
     },
     {
      "type": "plain",
      "text": " & Motorbike\n\n                Car & Motorbike \n                Motorbike Accessories & Parts \n                Protective Gear & Clothing \n                 Gloves \n                 Gloves \nPryce270 \nDicos40 \n\n❌Regular Price  : ₹ 399   /- 😱"
     }
    ]
   },
   {
    "id": 21248,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683429516",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "399",
    "dicos": "20",
    "catagory":"Office",
    "text_entities": [
     {
      "type": "plain",
      "text": "Doms Smart Stationery Kit (12 pcs in KIT) with Transparent Zipper Bag✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LFFH83"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nJRlXj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nCexXC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LNhywq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44D9sPu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44yM3yz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AZ0vCm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44AtD0k"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LEvwAv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44ti0bs"
     },
     {
      "type": "plain",
      "text": "\n\n @399\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61mxonFNhRL._SY450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Office"
     },
     {
      "type": "plain",
      "text": " Products\n\n                Home & Kitchen \n                Craft Materials \n                 Scrapbooking \nPryce399 \nDicos20 \n\n❌Regular Price  : ₹ 450   /- 😱"
     }
    ]
   },
   {
    "id": 21249,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683429583",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "21208",
    "dicos": "48",
    "catagory":"Industrial",
    "text_entities": [
     {
      "type": "plain",
      "text": "Rockwell 315 Ltr 5 Star Double Door Convertible GREEN Deep Freezer-GFR350DDUC (10 yr Warranty on body,Upto 53% Power Saving,100% copper coil)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pfKC86"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44ti2jA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HLwmdr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44DMRCc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B2s4Li"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nuynnL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HHGFPF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B2jFYc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nAmf4m"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B4JaIl"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@21208"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51i2S7-65DL._SX466_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Industrial"
     },
     {
      "type": "plain",
      "text": " & Scientific\n\n                Home & Kitchen \n                Home & kitchen | Prime shopping days \n                Affinity ASINs | 328 \n                 1 \n                 1 \nPryce21208 \nDicos48 \n\n❌Regular Price  : ₹  34999  /- 😱"
     }
    ]
   },
   {
    "id": 21250,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683429653",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "9999",
    "dicos": "69",
    "catagory":"Sports",
    "text_entities": [
     {
      "type": "plain",
      "text": "INALSA Fittyfy Manual Treadmill FFTM 410M- 5in1(Jogger,Stepper,Twister,Push-Up Bar,Heart Rate Sensor)|3Level Inclination|120Kg Max.User Weight|HomeGym & Multifunction,(Free Installation Assistance) ✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B2tSUs"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HMJ0cb"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41ei3Fo"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HOfov7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41dbC5a"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nCO24g"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nFOkY7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HM5VVk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pjikt8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B06jeP"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@9999"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51DWPMu6TyS._SY450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Sports"
     },
     {
      "type": "plain",
      "text": ", Fitness & Outdoors\n\n                Sports, Fitness & Outdoors \n                Exercise & Fitness \n                Exercise Machines \n                 Treadmills \n                 Treadmills \nPryce9999 \nDicos69 \n\n❌Regular Price  : ₹  21599  /- 😱"
     }
    ]
   },
   {
    "id": 21251,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683429722",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "2299",
    "dicos": "40",
    "catagory":"Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Bajaj Esteem 400 mm Pedestal Fan (White)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pjOdSl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M1xgFf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LYnweZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/416YU8g"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nDOX4u"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M1xiNn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nz1S7R"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42aBjF1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42aBjF1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HMH9Ex"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@2299"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51UQW8aGMGL._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Heating, Cooling & Air Quality \n                Fans \n                 Pedestal Fans \n                 Pedestal Fans \nPryce2299 \nDicos40 \n\n❌Regular Price  : ₹ 2999   /- 😱"
     }
    ]
   },
   {
    "id": 21252,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683429790",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1599",
    "dicos": "51",
    "catagory":"Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Prestige Iris 1.0 1200 Watt Glass Induction Cooktop with Push Button (Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nCeIlK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nz7tuR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nz7A9L"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NMHhHU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M54Z0Q"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42ozOTy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42djWn5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nwc0OL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NR2HUl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nz26Mf"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1599"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/81n9qrP0+RL._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Home Appliances \n                Small Kitchen Appliances \n                 Induction Cooktop \n                 Induction Cooktop \nPryce1599 \nDicos51 \n\n❌Regular Price  : ₹2999    /- 😱"
     }
    ]
   },
   {
    "id": 21253,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683429859",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "799",
    "dicos": "73",
    "catagory":"Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "Amazon Basics Bluetooth 5.0 Truly Wireless in Ear Earbuds, Up to 38 Hours Playtime, IPX-5 Rated, Type-C Charging Case, Touch Controls, Voice Assistant, Optional Single Side Use for Phone Calls, Blue✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NLL0oZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nuUmed"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NKVR2k"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VIu8Sa"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VGdpi3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VEgXkY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41eiv6y"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VIGcCH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NOR2Fk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41cMMCz"
     },
     {
      "type": "plain",
      "text": "\n\n @799\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/31faciSGpwL._SX300_SY300_QL70_FMwebp_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Headphones, Earbuds & Accessories \n                Headphones \n                 In-Ear \n                 In-Ear \nPryce799 \nDicos73 \n\n❌Regular Price  : ₹ 2099   /- 😱"
     }
    ]
   },
   {
    "id": 21254,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683429926",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "398",
    "dicos": "75",
    "catagory":"Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "PTron Newly Launched Musicbot Lite 5W Mini Bluetooth Speaker with 6Hrs Playtime, Immersive Sound, 40mm Driver, BT5.1 with Strong Connectivity, Portable Design, Integrated Music and Call Control (Blue)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NORaVk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41i5Z5L"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41i5Z5L"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pjOq85"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41dbXEY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41axbUg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LHD31k"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B3Er9E"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B2MTWO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42qRv4C"
     },
     {
      "type": "plain",
      "text": "\n\n @398\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71w5KeziHLL._SY450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Home Audio \n                Speakers \n                 Bluetooth Speakers \n                 Bluetooth Speakers \nPryce398 \nDicos75 \n\n❌Regular Price  : ₹1250    /- 😱"
     }
    ]
   },
   {
    "id": 21255,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683429994",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "399",
    "dicos": "56",
    "catagory":"Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "ZEBRONICS Zeb-Grace Wired Over The Ear Headphone with Mic (Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42AI177"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3I9j4YL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42thqZx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42u8qDA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42vlrfZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42t5PJY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42zEfuL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HLOoMI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42AIeap"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42wKEqp"
     },
     {
      "type": "plain",
      "text": "\n\n @399\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/615IRoY9BlL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Computers & Accessories \n                Accessories & Peripherals \n                Audio & Video Accessories \n                 PC Headsets \n                 PC Headsets \nPryce399 \nDicos56 \n\n❌Regular Price  : ₹750    /- 😱"
     }
    ]
   },
   {
    "id": 21256,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683430061",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "249",
    "dicos": "80",
    "catagory":"Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "AmazonBasics iPad Tablet Sleeve Case with Front Pocket, 10 Inch, Grey✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HKJyiO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44D9BT2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44yFKLs"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pfzIiy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pfzIiy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42yt2dY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44CkGDD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p70euo"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42aBvUL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VH1uk0"
     },
     {
      "type": "plain",
      "text": "\n\n @249\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/A1mM+KY30eL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Computers & Accessories \n                Accessories & Peripherals \n                Tablet Accessories \n                Bags,Cases & Sleeves \n                Bags,Cases & Sleeves \nPryce249 \nDicos80 \n\n❌Regular Price  : ₹ 999   /- 😱"
     }
    ]
   },
   {
    "id": 21257,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683430129",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1699",
    "dicos": "66",
    "catagory":"Health",
    "text_entities": [
     {
      "type": "plain",
      "text": "Lifelong LLM306 500W Electric Foot Spa Machine with 8 Manual Rollers, Digital Panel, Bubble Bath & Water Heating Technology for Pedicure, Pain relief & Foot Care, Brown✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VGpKml"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3piI4WQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VGPgIv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42aBC2D"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pisZ7B"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42cHCI3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pisZV9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VD5kuw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/428WirG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44EC5vM"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1699"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71xxpEYR4rL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Health"
     },
     {
      "type": "plain",
      "text": " & Personal Care\n\n                Health & Personal Care \n                Health Care \n                Massage & Relaxation \n                Electric Massagers \n                Electric Massagers \nPryce1699 \nDicos66 \n\n❌Regular Price  : ₹ 3999   /- 😱"
     }
    ]
   },
   {
    "id": 21258,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683430171",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "479",
    "dicos": "52",
    "catagory":"Shoes",
    "text_entities": [
     {
      "type": "plain",
      "text": "Centrino mens 7805-4 Slipper✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42dAaMR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42bTDO2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42oAgBe"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VLzHPK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44xUGt7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42aS82J"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/429CNPZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42dutyo"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VHw4dG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VHwnoQ"
     },
     {
      "type": "plain",
      "text": "\n\n @479\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/614x2RAKAiL._UX575_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Shoes"
     },
     {
      "type": "plain",
      "text": " & Handbags\n\n                Shoes & Handbags \n                Shoes \n                Men's Shoes \n                 Flip-Flops & Slippers \n                 Flip-Flops & Slippers \nPryce479 \nDicos52 \n\n❌Regular Price  : ₹ 850   /- 😱"
     }
    ]
   },
   {
    "id": 21259,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683430240",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "2999",
    "dicos": "46",
    "catagory":"Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "USHA Thunderbolt 800-Watt Copper Motor Mixer Grinder with 3 Jars and 5 Years Motor Warranty(Magenta)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LJBVdE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pdr3x8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3petUpI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VGPEGX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VGPEGX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LHFX6b"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AZabgj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LF7N3c"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LGZNP7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nAhpEi"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@2999"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71Icf0Pw3AL._SX522_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Home Appliances \n                Small Kitchen Appliances \n                 Mixer Grinders \n                 Mixer Grinders \nPryce2999 \nDicos46 \n\n❌Regular Price  : ₹ 4699   /- 😱"
     }
    ]
   },
   {
    "id": 21260,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683430308",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "6999",
    "dicos": "75",
    "catagory":"Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Lifelong Bold MTB 26T Mountain Bikes, Disc Brake 21 Speed Gear Cycles, Frame Size: 18 inches| Free Installation Assistance, Ideal for Adults 14+ Years (LLBC2694, Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B2chvR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HM3Pok"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HLocSD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44EWzEn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B6DgpS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3McFKK5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HQjjrh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B2gU93"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Bkx5it"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Bkx5Pv"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@6999"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71D935sK2bL._SX466_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Sports, Fitness & Outdoors \n                Cycling \n                Cycles \n                 Comfort Bikes \n                 Comfort Bikes \nPryce6999 \nDicos75 \n\n❌Regular Price  : ₹ 16999   /- 😱"
     }
    ]
   },
   {
    "id": 21261,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683430375",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1299",
    "dicos": "57",
    "catagory":"Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "ACO® Ventilating Exhaust Fan 10AP for Home, Bathroom and Kitchen | 100mm | 100% Copper Motor (Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NMKerQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pjiLDM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B1apDv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B3WjRK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HItPkk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41axqyE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B2sDVq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41bc8B0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HLooBl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HLop8n"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1299"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/6175vPTegHL._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Heating, Cooling & Air Quality \n                Fans \n                 Exhaust Fans \n                 Exhaust Fans \nPryce1299 \nDicos57 \n\n❌Regular Price  : ₹2499   /- 😱"
     }
    ]
   },
   {
    "id": 21262,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683430443",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1500",
    "dicos": "50",
    "catagory":"Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Faber 1.2 Liter Multi Cooker with 700 watt, FMC 1.2 BK, Black, Small, (MC 1.2L) - Stainless Steel, Outer Lid✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41buX70"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41e91bx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HM90EI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41den6A"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HO3jWS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HKhGvj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41i6pJn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M2lMBj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M1GS2L"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42aSer7"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1500"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51xAGoqHPwL._SX466_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Home Appliances \n                Small Kitchen Appliances \n                 Rice & Pasta Cookers \n                 Rice & Pasta Cookers \nPryce1500 \nDicos50 \n\n❌Regular Price  : ₹ 2499   /- 😱"
     }
    ]
   },
   {
    "id": 21263,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683430486",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "599",
    "dicos": "NaN",
    "catagory":"Beauty",
    "text_entities": [
     {
      "type": "plain",
      "text": "PROTOUCH Hair Oil For Men And Women | Helps to Reduces Hair Fall & Dandruff | Helps Hair Growth | 100 Ml (Pack of 1)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M20sfr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M4bmBx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LZZgt0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nz8jaZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42aumnu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HMHGq1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42aBS1B"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M4gG7Y"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M40VgY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ntye3W"
     },
     {
      "type": "plain",
      "text": "\n\n @599\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51YToHIYDjL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Beauty"
     },
     {
      "type": "plain",
      "text": "\n\n                Beauty \n                Hair Care \n                 Hair Oils \nPryce599 \nDicosNaN"
     }
    ]
   },
   {
    "id": 21264,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683430554",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "479",
    "dicos": "47",
    "catagory":"Health",
    "text_entities": [
     {
      "type": "plain",
      "text": "Rasayanam Liquid Biotin & Collagen for Hair Growth 25,000mcg (50 ml Sugar-free Berry Flavour) | Supports Glowing Skin, Hair Growth & Healthy Nails | Stronger Than Tablets & Capsules to reduce hair fall for Men & Women✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42f7XW0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NGnjhT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NJvjP0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NR3rJ7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NLA2j9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NTHMj8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M2zYu8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NLkyM5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LZLjv9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/416DxE0"
     },
     {
      "type": "plain",
      "text": "\n\n @479\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71qBH6dFMkL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Health"
     },
     {
      "type": "plain",
      "text": " & Personal Care\n\n                Health & Personal Care \n                Diet & Nutrition \n                Vitamins, Minerals & Supplements \n                Vitamins \n                Vitamins \nPryce479 \nDicos47 \n\n❌Regular Price  : ₹ 799   /- 😱"
     }
    ]
   },
   {
    "id": 21265,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683430603",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "582",
    "dicos": "35",
    "catagory":"All",
    "text_entities": [
     {
      "type": "plain",
      "text": "Brillare Onion Hair Oil Shots with Bakuchiol & Coconut Hair Oil | for Hair Fall Control | 100% Natural Hair Oil✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41ej8wW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NMI9MG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nF36yc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41bcoQu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3piItZm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41dcuXu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NLA76r"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3O3pQ67"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pjG1S8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LIrGGE"
     },
     {
      "type": "plain",
      "text": "\n\n @582\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61MSyQAXIKL._SX522_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#All"
     },
     {
      "type": "plain",
      "text": "\n\n                Beauty \n                Hair Care \n                 Hair Oils \nPryce582 \nDicos35 \n\n❌Regular Price  : ₹ 850   /- 😱"
     }
    ]
   },
   {
    "id": 21266,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683430653",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "139",
    "dicos": "72",
    "catagory":"Baby",
    "text_entities": [
     {
      "type": "plain",
      "text": "Rusabl Neem Dual Tooth Comb Without Handle For Men And Women, Pure Neem Wood, 100% Wooden, Comb With Wooden Teeth (Wide tooth)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NGNgxO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41i6AV3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NM4kTg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42vlJ6z"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42wNyvq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AZQlBu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42vP0y5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42u6pHt"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3paHTfX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42ur5il"
     },
     {
      "type": "plain",
      "text": "\n\n @139\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61ooiOpiXtL._SY450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Baby"
     },
     {
      "type": "plain",
      "text": "\n\n                Beauty \n                Hair Care \n                Hair Styling Tools \n                 Combs \n                 Combs \nPryce139 \nDicos72 \n\n❌Regular Price  : ₹ 399   /- 😱"
     }
    ]
   },
   {
    "id": 21267,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683430721",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "14000",
    "dicos": "60",
    "catagory":"Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "Dell Professional 24 inches, 1920 x 1080 Pixels Full HD Monitor - Wall Mountable, Height Adjustable, IPS Panel with HDMI, VGA DP & USB Ports - P2422H (Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B26h6c"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42thN6n"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3I9jIp9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42thTLh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nFPbIj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42xu0Hm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42tnSja"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HM9cDW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44OfEEv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HM6T3U"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@14000"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61gwkgOCxWL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Computers & Accessories \n                2021 \n                 Gaming Monitors \nPryce14000 \nDicos60 \n\n❌Regular Price  : ₹ 26999   /- 😱"
     }
    ]
   },
   {
    "id": 21268,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683430790",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "549",
    "dicos": "88",
    "catagory":"Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "HAMMER Airflow Truly Wireless Earbuds (TWS), Bluetooth 5.0, 3-4 Hours Playtime, 10M Range, Wireless Headphone with Magnetic Charging Case (300mah), in-Built Mic (White)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nFPe6X"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p5PV9V"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42u77V9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44ClqIV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p5PVGX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44D9vuI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42dJeBk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/427CULI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pfLkSO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42dARFX"
     },
     {
      "type": "plain",
      "text": "\n\n @549\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/41pbu1Ncy-L._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Headphones, Earbuds & Accessories \n                Headphones \n                 In-Ear \n                 In-Ear \nPryce549 \nDicos88 \n\n❌Regular Price  : ₹ 1999   /- 😱"
     }
    ]
   },
   {
    "id": 21269,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683430857",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "489",
    "dicos": "62",
    "catagory":"Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "Ant Esports GS100 2.0 Multimedia Aux Connectivity, USB Powered and Volume Control Gaming Speaker (Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42aSvu9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pdrlUK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42abc0J"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42heM9x"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42bUb6y"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44EX2X9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VEbQRL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44EuZY7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44DNvzC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44yAdEH"
     },
     {
      "type": "plain",
      "text": "\n\n @489\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71isljGkLxL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Computers & Accessories \n                Accessories & Peripherals \n                Audio & Video Accessories \n                 PC Speakers \n                 PC Speakers \nPryce489 \nDicos62 \n\n❌Regular Price  : ₹ 999   /- 😱"
     }
    ]
   },
   {
    "id": 21270,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683430926",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1349",
    "dicos": "66",
    "catagory":"Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "ZEBRONICS Sound Bomb X1 3-in-1 Wireless Bluetooth v5.0 In Ear Earbuds + Speaker Combo with 30 Hour Backup, Built-in LED Torch, Call Function, Voice Asst, Type C and Splash Proof Portable Design (Blue)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44Dag6Y"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42f8kjm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44zy4IR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LEccmP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LA5Xk2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VDsIbg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LA5Yo6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VEF432"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VUf1Fn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pf4dFl"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1349"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61bANayG3IL._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Home Audio \n                Speakers \n                 Bluetooth Speakers \n                 Bluetooth Speakers \nPryce1349 \nDicos66 \n\n❌Regular Price  : ₹  3499  /- 😱"
     }
    ]
   },
   {
    "id": 21271,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683430995",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "899",
    "dicos": "85",
    "catagory":"Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "boAt Wave Prime47 Smart Watch with 1.69\" HD Display, 700+ Active Modes, ASAP Charge, Live Cricket Scores, Crest App Health Ecosystem, HR & SpO2 Monitoring(Royal Blue)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VBpMMx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nK8RL0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nAn25k"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M27d0I"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B6DBc8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M3kuGm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LHDOYe"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B2uQA4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LIBCzV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nz8weh"
     },
     {
      "type": "plain",
      "text": "\n\n @899\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61c1JhpcwWL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Electronics \n                Wearable Technology \n                 Smart Watches \nPryce899 \nDicos85 \n\n❌Regular Price  : ₹  3499  /- 😱"
     }
    ]
   },
   {
    "id": 21272,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683431062",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "5661",
    "dicos": "71",
    "catagory":"Musical",
    "text_entities": [
     {
      "type": "plain",
      "text": "AKG K 240 Wired Over the Ear Headphone without Mic (Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LE7JAE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44yGEaO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LHGzsv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M27jp6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B07drJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AZbbkz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B3F4jw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B26rdO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44EXg0r"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44D9OFS"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@5661"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61Ca+bjvjoL._SY679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Musical"
     },
     {
      "type": "plain",
      "text": " Instruments\n\n                Electronics \n                Headphones, Earbuds & Accessories \n                Headphones \n                 Over-Ear \n                 Over-Ear \nPryce5661 \nDicos71 \n\n❌Regular Price  : ₹ 14999   /- 😱"
     }
    ]
   },
   {
    "id": 21273,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683431132",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1499",
    "dicos": "50",
    "catagory":"Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "realme TechLife Buds T100 Bluetooth Truly Wireless in Ear Earbuds with mic, AI ENC for Calls, Google Fast Pair, 28 Hours Total Playback with Fast Charging and Low Latency Gaming Mode (Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NTHZ5U"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44tjhPM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B2kFvq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3O3q9Oj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41axYoc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nAi9JA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nAiagC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41ejq70"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nC8odX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41i6Mnf"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1499"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61aasAbKvvL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Headphones, Earbuds & Accessories \n                Headphones \n                 In-Ear \n                 In-Ear \nPryce1499 \nDicos50 \n\n❌Regular Price  : ₹ 2499   /- 😱"
     }
    ]
   },
   {
    "id": 21274,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683431199",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "609",
    "dicos": "77",
    "catagory":"Clothing",
    "text_entities": [
     {
      "type": "plain",
      "text": "Integriti mens TRACK SUIT✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nCOZtm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M55Pe0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HHKLaL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HQk17V"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M1MNou"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HLs4D1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NKutl6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nwduIP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42hf407"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42dl7D1"
     },
     {
      "type": "plain",
      "text": "\n\n @609\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71+dmQp6Q+L._UY500_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Clothing"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Clothing & Accessories \n                Men \n                Sportswear \n                Sets \n                Sets \nPryce609 \nDicos77 \n\n❌Regular Price  : ₹  1999  /- 😱"
     }
    ]
   },
   {
    "id": 21275,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683431267",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "395",
    "dicos": "21",
    "catagory":"Beauty",
    "text_entities": [
     {
      "type": "plain",
      "text": "LAKMÉ Lip Color Power Pink (Matte)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NLM3oV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LH7Rzf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B2kRe8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LCLew1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41aPuIP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41s272x"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42vmk8j"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B2hXG1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41ayl24"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42urLUV"
     },
     {
      "type": "plain",
      "text": "\n\n @395\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/519Aqh-6cfL._SY450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Beauty"
     },
     {
      "type": "plain",
      "text": "\n\n                Beauty \n                Make-up \n                Lips \n                 Lipsticks \n                 Lipsticks \nPryce395 \nDicos21 \n\n❌Regular Price  : ₹450    /- 😱"
     }
    ]
   },
   {
    "id": 21276,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683431332",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "427",
    "dicos": "87",
    "catagory":"Shoes",
    "text_entities": [
     {
      "type": "plain",
      "text": "Koel By Lavie Women's Blocky Lg Tote Handbag✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42yt4lO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44EvilJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44yAsQ7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42qT3LY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42deikD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42v29av"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42hf9kr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44ClXdT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42abAwd"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42tiwo7"
     },
     {
      "type": "plain",
      "text": "\n\n @427\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/81jShMvkNqL._UY741_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Shoes"
     },
     {
      "type": "plain",
      "text": " & Handbags\n\n                Fashion \n                Steal deals \n                Last chance to buy - Great Summer Sale \n                Handbags \n                Handbags \nPryce427 \nDicos87 \n\n❌Regular Price  : ₹  1999  /- 😱"
     }
    ]
   },
   {
    "id": 21277,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683431401",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "16490",
    "dicos": "71",
    "catagory":"Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Amazon Basics 6 Kg 5 Star Fully Automatic Front Load Washing Machine (White/Silver, Built in Heater)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42oBaO8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VUfdV7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44OgrFt"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44A4brI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44DotR4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3peuAvg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VEhFi6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42byaEZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44uWzqF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44tjtyu"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@16490"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71he-OXPgvL._SX466_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Large Appliances \n                Washing Machines & Dryers \n                 All-in-One Washers & Dryers \n                 All-in-One Washers & Dryers \nPryce16490 \nDicos71 \n\n❌Regular Price  : ₹  44999  /- 😱"
     }
    ]
   },
   {
    "id": 21278,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683431469",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1299",
    "dicos": "57",
    "catagory":"Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "F&D 11 Watts F-203G 2.1 Wired Channel Multimedia Speakers System (Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44A4gvw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42bV6Uy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42iLiby"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VMjUQz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44DDxhC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VEcQ8t"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42fhTiq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pi5hIJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3piubb5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VDtncK"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1299"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51-7Z9G1NaL._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Electronics \n                Home Audio \n                Speakers \n                 Multimedia Speaker Systems \n                 Multimedia Speaker Systems \nPryce1299 \nDicos57 \n\n❌Regular Price  : ₹ 2499   /- 😱"
     }
    ]
   },
   {
    "id": 21279,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683431536",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "11999",
    "dicos": "70",
    "catagory":"Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "Creative Stage 360 2.1 240W Soundbar with Dolby Atmos and Subwoofer for TV/Computers/Ultrawide Monitors, Surround Sound with HDMI ARC/2.0, Bluetooth, Optical Input, and IR Remote✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LDioeR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VLAUGM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B2kZu8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B4Kxqt"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LxIEr6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LFbwOi"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LFHtpJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LE87PC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LDHieA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M0dLMn"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@11999"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61rL5eKZcqL._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Home Audio \n                Speakers \n                 Soundbar Speakers \n                 Soundbar Speakers \nPryce11999 \nDicos70 \n\n❌Regular Price  : ₹ 24999   /- 😱"
     }
    ]
   },
   {
    "id": 21280,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683431606",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "667",
    "dicos": "73",
    "catagory":"Garden",
    "text_entities": [
     {
      "type": "plain",
      "text": "Story@home Foldable Mosquito Net, Aesthetic Net with Soft Mesh & 2 Side Zipper Openings, Fancy Mosquito Net for Indoor & Outdoor Use, Queen-XL Size, Baby Pink ✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M0e1ej"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nz3gHB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M0dTMO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LHEuNg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nF4cKk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LHH0mD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LBOdVz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HKKZOe"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HLPPe4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44DoDrE"
     },
     {
      "type": "plain",
      "text": "\n\n @667\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/719ijz8aRyL._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Garden"
     },
     {
      "type": "plain",
      "text": " & Outdoors\n\n                Baby \n                Bedding, Furniture & Room Décor \n                Bedding \n                Baby Bedding \n                Baby Bedding \nPryce667 \nDicos73 \n\n❌Regular Price  : ₹1999    /- 😱"
     }
    ]
   },
   {
    "id": 21281,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683431674",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "169",
    "dicos": "83",
    "catagory":"Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "Gizga Essentials USB Bluetooth Adapter for PC, 5.0 Bluetooth Dongle Receiver Support Windows 11/10/8.1/8/7 for Desktop, Laptop, Mouse, Keyboard, Printers, Headsets, Speakers, PS4/ Xbox Controllers✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AZx2rQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44BXKV9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3I9kouH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HJTCbK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44CmvAt"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HMkAzs"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HO7H8m"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NM4M3U"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41fqwrP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pjGROM"
     },
     {
      "type": "plain",
      "text": "\n\n @169\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51taWWDA1fL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Computers & Accessories \n                Networking Devices \n                Network Adapters \n                 Bluetooth Adapters \n                 Bluetooth Adapters \nPryce169 \nDicos83 \n\n❌Regular Price  : ₹ 499   /- 😱"
     }
    ]
   },
   {
    "id": 21282,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683431741",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "2898",
    "dicos": "74",
    "catagory":"Clothing",
    "text_entities": [
     {
      "type": "plain",
      "text": "Genie Foliage Trolley Bag Medium Size, 66 cms Coral Printed Hard Side Travel Bag for Women, 8 Wheel Luggage Suitcase for Travelling✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NKWXLu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NLMgZf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41eabDV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HHLeK3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M2CZL1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M1HHbR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M0e2zQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nF699S"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M2nwL1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M56Brq"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@2898"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61-m8+C-+5L._UX522_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Clothing"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Bags, Wallets and Luggage \n                Luggage \n                 Suitcases & Trolley Bags \nPryce2898 \nDicos74 \n\n❌Regular Price  : ₹  8499  /- 😱"
     }
    ]
   },
   {
    "id": 21283,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683431785",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "819",
    "dicos": "54",
    "catagory":"Luggage",
    "text_entities": [
     {
      "type": "plain",
      "text": "CHRISTOPOLO® Men's Olive Solid Leather Wallet (CPMW-054-OLIVE)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NHMhgH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B2vNbC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41ayxOQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LZM9YP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AZRuZO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nAnQam"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M21yb3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M1NhuO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/416EwUI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nz9mYt"
     },
     {
      "type": "plain",
      "text": "\n\n @819\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/611fggJkV5L._SX450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Luggage"
     },
     {
      "type": "plain",
      "text": " & Bags\n\n                Bags, Wallets and Luggage \n                Wallets, Card Cases & Money Organisers \n                Men's \n                 Wallets \n                 Wallets \nPryce819 \nDicos54 \n\n❌Regular Price  : ₹1499    /- 😱"
     }
    ]
   },
   {
    "id": 21284,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683431851",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "4575",
    "dicos": "80",
    "catagory":"Sports",
    "text_entities": [
     {
      "type": "plain",
      "text": "KAMACHI 200W Ultra Thin Full Body Vibration Plate Crazy Fit Massage Exercise Machine, Oscillating Platform Massager with Remote✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B1hCn7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NMLATs"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HHLgSb"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NI1sXp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3O3qRer"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NLvQ3d"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NJwbTM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/427DV6u"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42coeLq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nwOqBs"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@4575"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61pPg7UYuNL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Sports"
     },
     {
      "type": "plain",
      "text": ", Fitness & Outdoors\n\n                Sports, Fitness & Outdoors \n                Exercise & Fitness \n                Exercise Machines \n                 Vibration Platform Machines \n                 Vibration Platform Machines \nPryce4575 \nDicos80 \n\n❌Regular Price  : ₹ 14999   /- 😱"
     }
    ]
   },
   {
    "id": 21285,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683431919",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "586",
    "dicos": "61",
    "catagory":"Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "AmazonBasics 5W Bluetooth 5.0 Speaker, Upto 36 Hrs Playtime, True Wireless Technology, Built in Mic, Multiple Connectivity Modes (Grey)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3O3qTmz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nK9BzM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41eL17Q"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41eL17Q"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/418VbXH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LE8h9G"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pbvit3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42cok5K"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pkYnSL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42dK5Sy"
     },
     {
      "type": "plain",
      "text": "\n\n @586\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/91PVrSZXunL._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Home Audio \n                Speakers \n                 Multimedia Speaker Systems \n                 Multimedia Speaker Systems \nPryce586 \nDicos61 \n\n❌Regular Price  : ₹ 1250   /- 😱"
     }
    ]
   },
   {
    "id": 21286,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683431987",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "32490",
    "dicos": "47",
    "catagory":"Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Haier 1.2 Ton 3 Star Inverter Split AC (Copper, Antibacterial Filter, 2023 Model, HSU15V-TMS3BE-INV, White)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M07xx6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nF6kly"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42nhWZ4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42sGrUI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42AvL6F"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42wOBLS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42tiQmP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nz3LBt"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VEi3gy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42OMCmn"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@32490"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51pEClNNP-L._SX466_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Heating, Cooling & Air Quality \n                Air Conditioners \n                 Split-System Air Conditioners \n                 Split-System Air Conditioners \nPryce32490 \nDicos47 \n\n❌Regular Price  : ₹  49999  /- 😱"
     }
    ]
   },
   {
    "id": 21287,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683432056",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "2199",
    "dicos": "61",
    "catagory":"Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Pigeon by Stovekraft Aster 3 Burner Gas Stove with High Powered Brass Burner Gas Cooktop, Cooktop with Glass Top and Powder Coated Body, Black, Manual Ignition, Standard (14267)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3O3r2X9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44z5Iyl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nAiKei"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pium6f"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pium6f"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3peLqKk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B27F8U"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B2dCTp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42dKa8O"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B2OrQC"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@2199"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/513mmhW0sBL._SX466_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Diwali 2022 \n                Gas stoves \n                 Three burner stoves \n                 Three burner stoves \nPryce2199 \nDicos61 \n\n❌Regular Price  : ₹ 4499   /- 😱"
     }
    ]
   },
   {
    "id": 21288,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683432126",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "7599",
    "dicos": "37",
    "catagory":"Industrial",
    "text_entities": [
     {
      "type": "plain",
      "text": "V-Guard Rejive UV UF Water Purifier, Multi Stage Purification, Next Generation UV Quartz Glass Chamber & Advanced UF Membrane, 6.5 Litre, Blue Black (Not Suitable for tanker or borewell water)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41bfBiH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44zyRcN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44OgUaH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HMLeZ5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VD6LJq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HLsPMn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nCPXpu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44zEMP2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42tj2T5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42deS1N"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@7599"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61qfXbMXQPL._SX466_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Industrial"
     },
     {
      "type": "plain",
      "text": " & Scientific\n\n                Home & Kitchen \n                Kitchen & Home Appliances \n                Water Purifiers & Accessories \n                 Water Filters & Purifiers \n                 Water Filters & Purifiers \nPryce7599 \nDicos37 \n\n❌Regular Price  : ₹ 10999   /- 😱"
     }
    ]
   },
   {
    "id": 21289,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683432199",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "519",
    "dicos": "74",
    "catagory":"Clothing",
    "text_entities": [
     {
      "type": "plain",
      "text": "The Indian Garage Co Men Pants✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VD6PZG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p87bvc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42f9wDm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/429ErB9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VEdlPT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VEGbzK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LICvsf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44Db9MQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42a8tV7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LNjy7U"
     },
     {
      "type": "plain",
      "text": "\n\n @519\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/81B1djWuvcL._UY500_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Clothing"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Clothing & Accessories \n                Men \n                Trousers \n                 Casual Trousers \n                 Casual Trousers \nPryce519 \nDicos74 \n\n❌Regular Price  : ₹ 1499   /- 😱"
     }
    ]
   },
   {
    "id": 21290,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683432270",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "449",
    "dicos": "55",
    "catagory":"Tools",
    "text_entities": [
     {
      "type": "plain",
      "text": "Joie Unbreakable Air Tight Square Plastic Containers Set for Kitchen Storage (Green, 1100ml) - Pack of 6✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42ctWwC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44yM5GG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VGyGbv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VBqYiZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pfMOfQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44BNUT5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LICAfx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B4L77D"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HO83fc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AXTr9f"
     },
     {
      "type": "plain",
      "text": "\n\n @449\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71UXs0e6U8L._SX466_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Tools"
     },
     {
      "type": "plain",
      "text": " & Home Improvement\n\n                Home & Kitchen \n                Kitchen & Dining \n                Kitchen Storage & Containers \n                 Jars & Containers \n                 Jars & Containers \nPryce449 \nDicos55 \n\n❌Regular Price  : ₹ 850   /- 😱"
     }
    ]
   },
   {
    "id": 21291,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683432324",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "964",
    "dicos": "84",
    "catagory":"Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Faber Ozonizer, Fruit & Vegetable Cleaner (Oxypure Iris)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HKjnsF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3I9kSRx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B1hUdH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nJTWAx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nDQKXg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nCQdos"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LBOKXz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B3XRew"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LICIM3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LHF65w"
     },
     {
      "type": "plain",
      "text": "\n\n @964\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61gaDqSV3YL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Home Appliances \n                Water Purifiers & Accessories \n                 Water Filters & Purifiers \n                 Water Filters & Purifiers \nPryce964 \nDicos84 \n\n❌Regular Price  : ₹ 2999   /- 😱"
     }
    ]
   },
   {
    "id": 21292,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683432377",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "499",
    "dicos": "75",
    "catagory":"Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "Amazon Basics Flexi Octopus Tripod with 360 Degree Rotating Head✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3I9l57h"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M262yt"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M0eqyi"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LEdu19"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41dfCmg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41cOwvB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M222Op"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LExWPB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LICNzl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HKLNme"
     },
     {
      "type": "plain",
      "text": "\n\n @499\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/613y-yDBdmL._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Cameras & Photography \n                Accessories \n                Tripods & Monopods \n                Tripods & Monopods \nPryce499 \nDicos75 \n\n❌Regular Price  : ₹1499    /- 😱"
     }
    ]
   },
   {
    "id": 21293,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683432449",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1300",
    "dicos": "60",
    "catagory":"Watches",
    "text_entities": [
     {
      "type": "plain",
      "text": "FCUK Analog Unisex Adult Watch (Dial Colored Strap)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M28hBK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M2Cd0E"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M3chSI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M0hISm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NHMFMb"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M1NSwy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NTJp0e"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M42j3a"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M26hJT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M07Yre"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1300"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61nUcaNpR+L._UY500_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Watches"
     },
     {
      "type": "plain",
      "text": "\n\n                Fashion \n                Onam 2021 \n                Watches and fashion jewellery \n                 Watches top brands \n                 Watches top brands \nPryce1300 \nDicos60 \n\n❌Regular Price  : ₹2499    /- 😱"
     }
    ]
   },
   {
    "id": 21294,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683432500",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "287",
    "dicos": "81",
    "catagory":"Health",
    "text_entities": [
     {
      "type": "plain",
      "text": "Relief Metatarsal Strap - Small (Right)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M07ZeM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3BkyIwB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NHdG2x"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NMJB1A"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B1c4cd"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NHMKiX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41eaXRl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nweUmD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nuBg85"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41dfJhG"
     },
     {
      "type": "plain",
      "text": "\n\n @287\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71VFzjoH+uL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Health"
     },
     {
      "type": "plain",
      "text": " & Personal Care\n\n                Health & Personal Care \n                Home Medical Supplies & Equipment \n                Braces, Splints & Supports \n                 Foot Supports \n                 Foot Supports \nPryce287 \nDicos81 \n\n❌Regular Price  : ₹  1199  /- 😱"
     }
    ]
   },
   {
    "id": 21295,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683432572",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "349",
    "dicos": "65",
    "catagory":"Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "Anciary USB 2.0 2.5 Inch SATA to USB External Hard Drive Enclosure HDD / SSD, Black Hard Disk Box Enclosure (Pack of 1)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pjlcpU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pmfK5C"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41i8bu1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41bT9Gg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LA7erk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HLkMiC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pivuXH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3peLKZy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M0eJrZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41bgbgn"
     },
     {
      "type": "plain",
      "text": "\n\n @349\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/414Y9WnzKOL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Computers & Accessories \n                External Devices & Data Storage \n                 External Solid State Drives \nPryce349 \nDicos65 \n\n❌Regular Price  : ₹ 850   /- 😱"
     }
    ]
   },
   {
    "id": 21296,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683432646",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "8024",
    "dicos": "63",
    "catagory":"Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "CP PLUS 2.4MP Night Vision Outdoor Bullet Camera (2 Pcs), 4CH DVR Without HD, 4CH Power Supply, 90MT Co-axial Cable, 1TB Hard Disk, HDMI Cable, BNC & DC✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41bgcAX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42hg5oX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pfBUqi"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pfBUqi"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42tpv0g"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M3cOUH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42ypQi7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42tpwBm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42acIjr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VDtXXY"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@8024"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/610ZDxr0-FL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Cameras & Photography \n                Security Cameras \n                 Bullet Cameras \n                 Bullet Cameras \nPryce8024 \nDicos63 \n\n❌Regular Price  : ₹ 11699   /- 😱"
     }
    ]
   },
   {
    "id": 21297,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683432717",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "294",
    "dicos": "71",
    "catagory":"Shoes",
    "text_entities": [
     {
      "type": "plain",
      "text": "Walkway by Metro Brands Women's Sneakers✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42AwlkR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44Cngtj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VBf20J"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VGrFax"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NGOVn2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VLBDYw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NM5NsK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44yHX9I"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44z6viN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VHygBW"
     },
     {
      "type": "plain",
      "text": "\n\n @294\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71DlpHX710L._UY575_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Shoes"
     },
     {
      "type": "plain",
      "text": " & Handbags\n\n                Shoes & Handbags \n                Shoes \n                Women's Shoes \n                Casual Shoes \n                Casual Shoes \nPryce294 \nDicos71 \n\n❌Regular Price  : ₹ 850   /- 😱"
     }
    ]
   },
   {
    "id": 21298,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683432788",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "505",
    "dicos": "41",
    "catagory":"Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "SYSKA (RDL-R-8W 3000K Yellow, Cool Day Light, Polycarbonate Led Slim Downlight✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NOSZSa"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NKXRYo"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nuBFHD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/429EXPB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42dmd1B"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VD7dr6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VGRIPd"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44A57MK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44tksia"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nCwjdb"
     },
     {
      "type": "plain",
      "text": "\n\n @505\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71ZA2C4lm3L._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Indoor Lighting \n                Light Bulbs \n                 LED Bulbs \n                 LED Bulbs \nPryce505 \nDicos41 \n\n❌Regular Price  : ₹799    /- 😱"
     }
    ]
   },
   {
    "id": 21299,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683432863",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "758",
    "dicos": "70",
    "catagory":"Shoes",
    "text_entities": [
     {
      "type": "plain",
      "text": "Puma Unisex-Adult Geo Slide✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nAoMvo"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42iMfAE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42iMfAE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HIvBlu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44BOkJ9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41den6w"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nFQBm7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nFQBm7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pdsMCC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42acUPH"
     },
     {
      "type": "plain",
      "text": "\n\n @758\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51-nmbM-qvL._UY675_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Shoes"
     },
     {
      "type": "plain",
      "text": " & Handbags\n\n                Shoes & Handbags \n                Shoes \n                Men's Shoes \n                 Flip-Flops & Slippers \n                 Flip-Flops & Slippers \nPryce758 \nDicos70 \n\n❌Regular Price  : ₹ 1999   /- 😱"
     }
    ]
   },
   {
    "id": 21300,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683432936",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "208",
    "dicos": "50",
    "catagory":"Beauty",
    "text_entities": [
     {
      "type": "plain",
      "text": "Bajaj 100% Pure Coconut Oil 1 litre✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HO8J4e"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42vQZT3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42yq6O7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LDIheM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LIs1sI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AZJPuH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42AwAMN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42AwAMN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3peMhe0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pft0Jv"
     },
     {
      "type": "plain",
      "text": "\n\n @208\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51IFuaXYGvL._SY450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Beauty"
     },
     {
      "type": "plain",
      "text": "\n\n                Grocery & Gourmet Foods \n                Cooking & Baking Supplies \n                Oils & Ghee \n                Oils \n                Oils \nPryce208 \nDicos50 \n\n❌Regular Price  : ₹ 399   /- 😱"
     }
    ]
   },
   {
    "id": 21301,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683433010",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "9183",
    "dicos": "63",
    "catagory":"Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "CP PLUS 4 Channal HD DVR 1080p 1Pcs,Outdoor Wired Camera 2.4 MP 2Pcs,Indoor Camera 2.4 MP 2Pcs,1 TB Hard Disk with USEWELL BNC/DC Full Combo Set✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pfCioK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B2elE7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B2uXM8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44Db9wo"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nFcS3x"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LItm2U"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VH4n4k"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pewjRg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VC2172"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pi6mAh"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@9183"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61v-ALTM7kL._SX450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Cameras & Photography \n                Security Cameras \n                 Bullet Cameras \n                 Bullet Cameras \nPryce9183 \nDicos63 \n\n❌Regular Price  : ₹  19999  /- 😱"
     }
    ]
   },
   {
    "id": 21302,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683433082",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "742",
    "dicos": "79",
    "catagory":"Clothing",
    "text_entities": [
     {
      "type": "plain",
      "text": "Aristocrat Polyester 28 Cms Travel Bag(DFCAD53ERED_Red)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lxw3nC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LFIA8T"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M22yMl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LIDfxx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M089CU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41kIKs8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LIJEsF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41bgqbh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pjI6gU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nza4oB"
     },
     {
      "type": "plain",
      "text": "\n\n @742\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/616w+Q9xWNL._UX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Clothing"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Fashion \n                Carry ons to flex \n                Duffles \n                 Duffles \n                 Duffles \nPryce742 \nDicos79 \n\n❌Regular Price  : ₹ 1999   /- 😱"
     }
    ]
   },
   {
    "id": 21303,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683433157",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "847",
    "dicos": "58",
    "catagory":"Shoes",
    "text_entities": [
     {
      "type": "plain",
      "text": "Bourge Mens Loire-z116 Running Shoes✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44DEPJu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M2oKpB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3phDmZp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M1OpP4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M1AjgF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://www.amazon.in/Bourge-Loire-153-Running-Shoes-8-Loire-153-08/dp/B07Z897FWP?th=1&amp;psc=1&amp;linkCode=sl1&amp;tag=avi1deals6-21&amp;linkId=06a01adc23c5dd8134d2b838498b7781&amp;language=en_IN&amp;ref_=as_li_ss_tl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M4iDRQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NI2bb5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44ExFoD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nzC8YQ"
     },
     {
      "type": "plain",
      "text": "\n\n @847\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/81CuOljgBeL._UX575_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Shoes"
     },
     {
      "type": "plain",
      "text": " & Handbags\n\n                Shoes & Handbags \n                Shoes \n                Men's Shoes \n                Sports & Outdoor Shoes \n                Sports & Outdoor Shoes \nPryce847 \nDicos58 \n\n❌Regular Price  : ₹1699    /- 😱"
     }
    ]
   },
   {
    "id": 21304,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683433228",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1024",
    "dicos": "53",
    "catagory":"Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Larah by Borosil Glass Flore Thali Set (White) - 18 Pieces✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M3dlGb"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nuWN0l"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NMqEMn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B2v628"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NMMWh0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B1f3kX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NLGt5T"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NLNpA1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41deFKE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HJUD3y"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1024"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51KgWd-Oy-L._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Dining \n                Tableware \n                Dinnerware & Serving Pieces \n                Dinnerware & Serving Pieces \nPryce1024 \nDicos53 \n\n❌Regular Price  : ₹2099    /- 😱"
     }
    ]
   },
   {
    "id": 21305,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683433301",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "648",
    "dicos": "57",
    "catagory":"Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "RPM Euro Games Laptop/PC Controller Wired for Windows - 7, 8, 8.1, 10 and XP, Ps3(Upgraded with XYAB Buttons)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pjIig8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LDIBdu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LNkrgK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LxwoGU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41dgTd2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41h7sJI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nF5ll6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41efvHl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42oCwII"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42cGOmG"
     },
     {
      "type": "plain",
      "text": "\n\n @648\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51TzieA2kpL._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Computers & Accessories \n                Accessories & Peripherals \n                PC Gaming Peripherals \n                 Gamepads \n                 Gamepads \nPryce648 \nDicos57 \n\n❌Regular Price  : ₹ 1199   /- 😱"
     }
    ]
   },
   {
    "id": 21306,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683433373",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "398",
    "dicos": "87",
    "catagory":"Luggage",
    "text_entities": [
     {
      "type": "plain",
      "text": "M MEDLER Epoch Nylon 55 litres Waterproof Strolley Duffle Bag- 2 Wheels - Luggage Bag - (Navy Blue)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LFIWfJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42ubBeu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pbINc9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42v3jCT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42yvwsq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42wN7RH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42wN7RH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M1OBOi"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42faoYE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44DEVkk"
     },
     {
      "type": "plain",
      "text": "\n\n @398\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71tzEgzCFcL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Luggage"
     },
     {
      "type": "plain",
      "text": " & Bags\n\n                Bags, Wallets and Luggage \n                Luggage \n                 Travel Duffles \nPryce398 \nDicos87 \n\n❌Regular Price  : ₹  1699  /- 😱"
     }
    ]
   },
   {
    "id": 21307,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683433448",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "2155",
    "dicos": "54",
    "catagory":"Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "Cello Riser Duroplast Multi Utility Rectangular Table, Scissor Fold and Height Adjustable, Grey (Plastic)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VBftIp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NKwrSw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NKYhhq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nwfKzN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42dmK3B"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44AwFle"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44EYUPF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pf6cth"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VEj34i"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44BZzBt"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@2155"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/611a0NkGXOL._SX466_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Home & Kitchen \n                Furniture \n                Study & Home Office Furniture \n                Tables \n                Tables \nPryce2155 \nDicos54 \n\n❌Regular Price  : ₹ 3999   /- 😱"
     }
    ]
   },
   {
    "id": 21308,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683433521",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "195",
    "dicos": "60",
    "catagory":"Car",
    "text_entities": [
     {
      "type": "plain",
      "text": "Vega Kavach-1 Face Shield, Face Protection shield, Full Face Protector✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42dD4Bf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42dD4Bf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AXUpCp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41d3nX3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41d3nX3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B2vv4E"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AZynyS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B2eTKb"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3phDIiH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41dhmfi"
     },
     {
      "type": "plain",
      "text": "\n\n @195\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/618j4BFfcFL._SY450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Car"
     },
     {
      "type": "plain",
      "text": " & Motorbike\n\n                Car & Motorbike \n                Motorbike Accessories & Parts \n                Helmets \n                 Full Face Helmets \n                 Full Face Helmets \nPryce195 \nDicos60 \n\n❌Regular Price  : ₹  399  /- 😱"
     }
    ]
   },
   {
    "id": 21309,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683433599",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "299",
    "dicos": "75",
    "catagory":"Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "AmazonBasics Wireless Mouse, 2.4 GHz with USB Nano Receiver, Optical Tracking, for PC/Mac/Laptop/Tablet (Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nApjgS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HIwyKA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42dD8kt"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VBfITP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42cpDBG"
     },
     {
      "type": "plain",
      "text": "\n\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HKMBYi"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HKMBYi"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HO9f2a"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42awRpS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42yvLUm"
     },
     {
      "type": "plain",
      "text": "\n\n @299\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/514-U9TxXkL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Computers & Accessories \n                Accessories & Peripherals \n                Keyboards, Mice & Input Devices \n                 Mice \n                 Mice \nPryce299 \nDicos75 \n\n❌Regular Price  : ₹ 999   /- 😱"
     }
    ]
   },
   {
    "id": 21310,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683433671",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "105",
    "dicos": "50",
    "catagory":"Beauty",
    "text_entities": [
     {
      "type": "plain",
      "text": "Engage Spirit for Her Deodorant for Women, Cheerful & Jolly, Skin Friendly, 150ml✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pdtsb8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pdtsb8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pdtsb8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pdtsb8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42t9kA6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LBQ5h3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pbwHjj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LA82wm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/427FvFs"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42y61Yi"
     },
     {
      "type": "plain",
      "text": "\n\n @105\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51-6u6auBSL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Beauty"
     },
     {
      "type": "plain",
      "text": "\n\n                Beauty \n                Bath & Body \n                Deodorants & Antiperspirants \n                 Deodorant \n                 Deodorant \nPryce105 \nDicos50 \n\n❌Regular Price  : ₹ 200   /- 😱"
     }
    ]
   },
   {
    "id": 21311,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683433745",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "505",
    "dicos": "41",
    "catagory":"Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "SYSKA (SSK-RDL-S-8W 4000K Warm White, Rdl Led Slim Downlight (Cool Day Light, Polycarbonate)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B4Mbs9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HOics5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VIJkOX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B3Hm26"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HLzOEV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://www.amazon.in/dp/B07ZRY3WQ3?smid=A2AL6IVND0I91F&amp;psc=1&amp;th=1&amp;linkCode=sl1&amp;tag=avi1deals6-21&amp;linkId=1bf92017cd9c0fbb6bb6e2b814885ba9&amp;language=en_IN&amp;ref_=as_li_ss_tl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VD0SvU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44yISXI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HMlYCa"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HM9fQi"
     },
     {
      "type": "plain",
      "text": "\n\n @505\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71VPBkiGrsL._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Indoor Lighting \n                Light Bulbs \n                 LED Bulbs \n                 LED Bulbs \nPryce505 \nDicos41 \n\n❌Regular Price  : ₹ 799   /- 😱"
     }
    ]
   },
   {
    "id": 21312,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683433827",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "4999",
    "dicos": "71",
    "catagory":"Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "Skullcandy Riff Wireless 2 on-Ear Headphones Black✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3phDRCL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HQm3oz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LEey59"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pjRnWd"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://www.amazon.in/dp/B0B75MQJQR?&amp;linkCode=sl1&amp;tag=avi1deals5-21&amp;linkId=03a13305159caf42134038f2432debbb&amp;language=en_IN&amp;ref_=as_li_ss_tl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LIsP0I"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nJVhax"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/417aoZv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/417rOoB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41eMbQK"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@4999"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71cKqKY3dmL._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Headphones, Earbuds & Accessories \n                Headphones \n                 On-Ear \n                 On-Ear \nPryce4999 \nDicos71 \n\n❌Regular Price  : ₹ 11599   /- 😱"
     }
    ]
   },
   {
    "id": 21313,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683433902",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "619",
    "dicos": "56",
    "catagory":"Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "ZEBRONICS Zeb-Bang Wireless Bluetooth Over The Ear Headphone with Mic and and Playback time 16 hrs (Blue)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41dhuvi"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M3dOIr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M2phI7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NNJzGM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NLNROJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44C0e5V"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NGPTja"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NMrsAT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NFAsb0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nuCJeB"
     },
     {
      "type": "plain",
      "text": "\n\n @619\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71JmAlJ8ggL._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Headphones, Earbuds & Accessories \n                Headphones \n                 Over-Ear \n                 Over-Ear \nPryce619 \nDicos56 \n\n❌Regular Price  : ₹ 1199   /- 😱"
     }
    ]
   },
   {
    "id": 21314,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683433975",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "105",
    "dicos": "61",
    "catagory":"Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Eveready 0.5-Watt LED Deco Bulb (White and Pack of 3)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NLGIhj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nJVoCZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pjIImI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41aRhxx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B3HE9c"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/418Wxlf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41aRlNN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/416G9Sk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/416G9Sk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41dhFXu"
     },
     {
      "type": "plain",
      "text": "\n\n @105\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51YzFHQmHAL._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Indoor Lighting \n                Light Bulbs \n                 LED Bulbs \n                 LED Bulbs \nPryce105 \nDicos61 \n\n❌Regular Price  : ₹249    /- 😱"
     }
    ]
   },
   {
    "id": 21315,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683434032",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "481",
    "dicos": "85",
    "catagory":"Clothing",
    "text_entities": [
     {
      "type": "plain",
      "text": "RIVER Men's Regular Fit Shirt✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41bhqMz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42yvgty"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42vRYCJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42yvUHM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42ywchs"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HLRnoo"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nCRyeY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nFdJBh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HKkY1D"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HLlT1M"
     },
     {
      "type": "plain",
      "text": "\n\n @481\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71ZRtVK0TgL._UY500_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Clothing"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Fashion \n                Designer Boutique \n                Trends \n                t \n                t \nPryce481 \nDicos85 \n\n❌Regular Price  : ₹ 1499   /- 😱"
     }
    ]
   },
   {
    "id": 21316,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683434103",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "289",
    "dicos": "86",
    "catagory":"Shoes",
    "text_entities": [
     {
      "type": "plain",
      "text": "Amazon Brand - Symbol Womens Zoe Sneakers✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M29ARa"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42dghFB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42dghFB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44yNlcS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M3dxVW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pbwUD7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M09fyw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M27Fw5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42a9MmZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VBs6TL"
     },
     {
      "type": "plain",
      "text": "\n\n @289\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/81iDAcM89rL._UX575_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Shoes"
     },
     {
      "type": "plain",
      "text": " & Handbags\n\n                Shoes & Handbags \n                Shoes \n                Women's Shoes \n                Casual Shoes \n                Casual Shoes \nPryce289 \nDicos86 \n\n❌Regular Price  : ₹ 1499   /- 😱"
     }
    ]
   },
   {
    "id": 21317,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683434179",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "7849",
    "dicos": "50",
    "catagory":"Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "Acer HA220Q 21.5 Inch (54.61 Cm) Full HD IPS Ultra Slim (6.6Mm Thick) LCD Monitor with LED Back Light Technology I Frameless Design I AMD Free Sync I Eye Care Features I Stereo Speakers (White)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pdtWxY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VEjA6i"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VLCJDC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pewVq2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NKx3aM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44uYlbj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VCYpBM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44NaCYO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44DqAEu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VEHBu4"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@7849"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71wRe7xkcSL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Computers & Accessories \n                2021 \n                 Gaming Monitors \nPryce7849 \nDicos50 \n\n❌Regular Price  : ₹ 12499   /- 😱"
     }
    ]
   },
   {
    "id": 21318,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683434253",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "299",
    "dicos": "63",
    "catagory":"Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "KridayKraft Metal Ganesha ji Statue,Ganpati Wall Hanging Sculpture Lord Ganesh Idol Lucky Feng Shui Wall Decor (Gold)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B09ZNB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42hhtrF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41s4J0l"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41s4J0l"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VC6pmz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B1jD2F"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VBsnWN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VGT2Sb"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VGgFKj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VDvwFk"
     },
     {
      "type": "plain",
      "text": "\n\n @299\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71mP1uIKSbL._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Home & Kitchen \n                Home & Décor \n                Decorative Accessories \n                 Idols & Figurines \n                 Idols & Figurines \nPryce299 \nDicos63 \n\n❌Regular Price  : ₹ 650   /- 😱"
     }
    ]
   },
   {
    "id": 21319,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683434327",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "259",
    "dicos": "48",
    "catagory":"Toys",
    "text_entities": [
     {
      "type": "plain",
      "text": "Party Propz Happy Birthday Banner Decoration Kit - Set of 34Pcs | Birthday Decoration Items | Birthday Balloons for Decoration | Happy Birthday Decoration for Husband | Decorative Items for Birthday✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42wf1x7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42sIwju"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B3ZjO0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LHGk0C"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42wQOHa"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LJloX0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B8861n"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42y6BFs"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M0g7uH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42nk5E6"
     },
     {
      "type": "plain",
      "text": "\n\n @259\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71KXgvizZ7L._SY450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Toys"
     },
     {
      "type": "plain",
      "text": " & Games\n\n                Toys & Games \n                Party Supplies \n                Decorations \n                 Balloons \n                 Balloons \nPryce259 \nDicos48 \n\n❌Regular Price  : ₹ 450   /- 😱"
     }
    ]
   },
   {
    "id": 21320,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683434404",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "448",
    "dicos": "55",
    "catagory":"Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "JBL C50HI, Wired in Ear Headphones with Mic, One Button Multi-Function Remote, Lightweight & Comfortable fit (Red)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42aF5hF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B3SPiq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42ywwga"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3BkAaPz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3BkAaPz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B2wzFG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B2fBaj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HLrEwz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B1dR0V"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44xYavH"
     },
     {
      "type": "plain",
      "text": "\n\n @448\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/31b53zRE8vL._SX300_SY300_QL70_FMwebp_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Headphones, Earbuds & Accessories \n                Headphones \n                 In-Ear \n                 In-Ear \nPryce448 \nDicos55 \n\n❌Regular Price  : ₹  850  /- 😱"
     }
    ]
   },
   {
    "id": 21321,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683434479",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "798",
    "dicos": "82",
    "catagory":"Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "Boult Audio FXCharge Wireless in Ear Bluetooth Neckband with ENC Mic, 32H Playtime, Type-C Fast Charging (5Mins=7.5Hrs Playtime), Dual Pairing, Made in India, Biggest 14.2mm Drivers Ear Phones (Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HIxpuM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HM6RZK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HLmh0e"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HQmAqz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HMKWlf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44DcO52"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41egfw7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44uYyeB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44Oit8z"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41byaDA"
     },
     {
      "type": "plain",
      "text": "\n\n @798\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/6161gqzzYlL._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Headphones, Earbuds & Accessories \n                Headphones \n                 In-Ear \n                 In-Ear \nPryce798 \nDicos82 \n\n❌Regular Price  : ₹ 1999   /- 😱"
     }
    ]
   },
   {
    "id": 21322,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683434555",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "4264",
    "dicos": "20",
    "catagory":"Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Wonderchef Instant Water Heater (3L)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41fsIzz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nF7ZHO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M2DVz6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M29OYw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://www.amazon.in/dp/B07Z53PM1Q?&amp;linkCode=sl1&amp;tag=avi1deals5-21&amp;linkId=bf012ed21a321c06faeb78d4ed2624c6&amp;language=en_IN&amp;ref_=as_li_ss_tl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LIuO5m"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M4ejlB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M58vZ6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LFea6G"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LH2ZKB"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@4264"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/31GeFORTDzL._SX300_SY300_QL70_FMwebp_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Heating, Cooling & Air Quality \n                Water Heaters & Geysers \n                 Instant Water Heaters \n                 Instant Water Heaters \nPryce4264 \nDicos20 \n\n❌Regular Price  : ₹  5099  /- 😱"
     }
    ]
   },
   {
    "id": 21323,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683434628",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "7599",
    "dicos": "62",
    "catagory":"Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Alstorm Brio Indian 3 Burner Auto Ignition Gas Stove (Brio 3BNR white-black, Brass Gas Hobtop)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nKbuMS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3piwzi3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44Dccwk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nF8otO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3phEADZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NTL99M"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NLy9mT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NLHrz3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3McJdZ7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ntBrk0"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@7599"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/718cXBhq72L._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Dining \n                 Gas Stoves \nPryce7599 \nDicos62 \n\n❌Regular Price  : ₹   16499 /- 😱"
     }
    ]
   },
   {
    "id": 21324,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683434704",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1499",
    "dicos": "85",
    "catagory":"Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "Fire-Boltt Ring 3 Smart Watch 1.8 Biggest Display with Advanced Bluetooth Calling Chip, Voice Assistance,118 Sports Modes, in Built Calculator & Games, SpO2, Heart Rate Monitoring (Rose-Gold) ✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44zAAyN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NGqlCN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B3SYCu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NJyHtc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NJyHtc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M0gEgb"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NOAkWD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NLOA2p"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LCO5oJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LIExbR"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1499"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61Yi8xC7q6L._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Electronics \n                Wearable Technology \n                 Smart Watches \nPryce1499 \nDicos85 \n\n❌Regular Price  : ₹6499    /- 😱"
     }
    ]
   },
   {
    "id": 21325,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683434756",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "6216",
    "dicos": "82",
    "catagory":"Sports",
    "text_entities": [
     {
      "type": "plain",
      "text": "Spanco 8 in 1 Double Support Multipurpose Weight Lifting Bench(Incline/Decline/Flat/Leg Exerciser/Lats Excerciser/Dips Stand/Push Up Stand)- 85 kg Holding Capacity for Full Body Workout✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41aRP6z"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LEfiXZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41caQWj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HMmHDo"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41aRXTB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pl0oOP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41bUEnS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pl0qWX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41aS3dV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HQmWxp"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@6216"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51M8FmpklML._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Sports"
     },
     {
      "type": "plain",
      "text": ", Fitness & Outdoors\n\n                Sports, Fitness & Outdoors \n                Exercise & Fitness \n                Strength Training Equipment \n                Benches \n                Benches \nPryce6216 \nDicos82 \n\n❌Regular Price  : ₹ 19999   /- 😱"
     }
    ]
   },
   {
    "id": 21326,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683434827",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "209",
    "dicos": "65",
    "catagory":"Clothing",
    "text_entities": [
     {
      "type": "plain",
      "text": "Pubg By Free Authority Men Men Tshirt✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42AxJUB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42aactz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VMm4Qb"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NM7sP0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VGxBk3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44tlWJg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VMm8zp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VGgWNl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VEHZZy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VD1GRs"
     },
     {
      "type": "plain",
      "text": "\n\n @209\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61zzdSoYULL._UX522_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Clothing"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Clothing & Accessories \n                Men \n                T-Shirts & Polos \n                 T-Shirts \n                 T-Shirts \nPryce209 \nDicos65 \n\n❌Regular Price  : ₹ 550   /- 😱"
     }
    ]
   },
   {
    "id": 21327,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683434902",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "699",
    "dicos": "50",
    "catagory":"Grocery",
    "text_entities": [
     {
      "type": "plain",
      "text": "LILA DRY FRUITS 100% Natural Premium 1000g Whole Cashews | Whole Crunchy Cashew | Premium Kaju nuts | Nutritious & Delicious | Gluten Free | Source of Minerals & Vitamins | Dry Fruits✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42aan8d"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42bAWtT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41ft9Kd"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42aW6s9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B2QwvU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HM7676"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HM7676"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HLSaWo"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HLAH0d"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VGtHaF"
     },
     {
      "type": "plain",
      "text": "\n\n @699\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/41ic5hseryL._SY606_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Grocery"
     },
     {
      "type": "plain",
      "text": " & Gourmet Foods\n\n                Grocery & Gourmet Foods \n                Dried Fruits, Nuts & Seeds \n                Nuts & Seeds \n                 Cashews \n                 Cashews \nPryce699 \nDicos50 \n\n❌Regular Price  : ₹ 1199   /- 😱"
     }
    ]
   },
   {
    "id": 21328,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683434973",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "4264",
    "dicos": "20",
    "catagory":"Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Wonderchef Instant Water Heater (3L)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B88z3D"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B0aHKL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AZTGk0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nF6kSk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HM7dzy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HMcjM8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42y7g9U"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B403Ti"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42rCAYB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AYAgMu"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@4264"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51SavqDcbBL._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Heating, Cooling & Air Quality \n                Water Heaters & Geysers \n                 Instant Water Heaters \n                 Instant Water Heaters \nPryce4264 \nDicos20 \n\n❌Regular Price  : ₹  4999   /- 😱"
     }
    ]
   },
   {
    "id": 21329,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683435049",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "554",
    "dicos": "38",
    "catagory":"Grocery",
    "text_entities": [
     {
      "type": "plain",
      "text": "GreenFinity Healthy & Tasty Jumbo Pumpkin, Jumbo Sunflower - 250g, Flax Seeds, Chia Seeds - 300g Jar | Seeds for Eating | Immunity Booster Diet | Protein and Rich in Fibre✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42aW9El"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HLsfhN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HLsfhN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nFeA4X"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AZezfh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nCyb5H"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/423wbCx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42iNYpC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VEfxXD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VUi1S9"
     },
     {
      "type": "plain",
      "text": "\n\n @554\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/81MuACxAsIL._SX522_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Grocery"
     },
     {
      "type": "plain",
      "text": " & Gourmet Foods\n\n                Grocery & Gourmet Foods \n                Dried Fruits, Nuts & Seeds \n                Nuts & Seeds \n                 Mixed Nuts \n                 Mixed Nuts \nPryce554 \nDicos38 \n\n❌Regular Price  : ₹  899   /- 😱"
     }
    ]
   },
   {
    "id": 21330,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683435125",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "2295",
    "dicos": "50",
    "catagory":"Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Havells Instanio 1-Litre 3KW Instant Water Heater (Geyser), White Blue, Wall Mounting✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VGhfYv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44Dcy68"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nFSvDh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VMmwxR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VMmwxR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41d4Ah3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M2pTgT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41bigJd"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41eNoaK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M4jVMG"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@2295"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51zfltcF4hL._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Heating, Cooling & Air Quality \n                Water Heaters & Geysers \n                 Instant Water Heaters \n                 Instant Water Heaters \nPryce2295 \nDicos50 \n\n❌Regular Price  : ₹  3999   /- 😱"
     }
    ]
   },
   {
    "id": 21331,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683435199",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1499",
    "dicos": "52",
    "catagory":"Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Pigeon Ruby 1.8 Litre Single pot (Aluminium) Electric rice cooker✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41bii3N"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LxLrR6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M44fZu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M2FOvB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3McJA5X"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nF6zgc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M2pkDD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nCj6RK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pixMWN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M3f0LV"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1499"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/31QVVD9P3bL._SX466_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Home Appliances \n                Small Kitchen Appliances \n                 Rice & Pasta Cookers \n                 Rice & Pasta Cookers \nPryce1499 \nDicos52 \n\n❌Regular Price  : ₹   2499   /- 😱"
     }
    ]
   },
   {
    "id": 21332,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683435251",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1080",
    "dicos": "82",
    "catagory":"Shoes",
    "text_entities": [
     {
      "type": "plain",
      "text": "Red Tape Men Blue Walking Shoes✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nCj8Jm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M2q9fR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M0afCM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NMOgAu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NM7DtE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NKZqFK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NMsNYr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pfuvaB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NOB4Lp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44zH80k"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1080"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/81D78+n0FhL._UY575_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Shoes"
     },
     {
      "type": "plain",
      "text": " & Handbags\n\n                Shoes & Handbags \n                Shoes \n                Men's Shoes \n                Sports & Outdoor Shoes \n                Sports & Outdoor Shoes \nPryce1080 \nDicos82 \n\n❌Regular Price  : ₹   3999  /- 😱"
     }
    ]
   },
   {
    "id": 21333,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683435326",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "31990",
    "dicos": "50",
    "catagory":"Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Haier 1.5 Ton 3 Star Inverter Split AC (Copper, Antibacterial Filter, 2023 Model, HSU17V-TMS3BE-INV, White)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44DcGTa"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pjntBs"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NGqNAZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pjSsgJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nwgULF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B89jFX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NR81qN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41cQMmz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/416H59i"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LHHj0O"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@31990"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/518szMqoI5L._SX466_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Heating, Cooling & Air Quality \n                Air Conditioners \n                 Split-System Air Conditioners \n                 Split-System Air Conditioners \nPryce31990 \nDicos50 \n\n❌Regular Price  : ₹   49999  /- 😱"
     }
    ]
   },
   {
    "id": 21334,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683435402",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "698",
    "dicos": "42",
    "catagory":"Video",
    "text_entities": [
     {
      "type": "plain",
      "text": "Cosmic Byte Blazar Wired Over-Ear Headphone with Mic for PC, Mobiles, PS5, PS4, Xbox One, Tablets (Red)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LEAoFN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42tleKj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NM83Ae"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42y7q10"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/417bQep"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nHnYov"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LHHtFs"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LEfNBl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HKlSv3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://www.amazon.in/Cosmic-Byte-Blazar-Headphone-Red/dp/B08BJDXMN6?&amp;linkCode=sl1&amp;tag=avi1deals10-21&amp;linkId=755875e03dfffe42d3ddd7821739c5c9&amp;language=en_IN&amp;ref_=as_li_ss_tl"
     },
     {
      "type": "plain",
      "text": "\n\n @698\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/7113PTDNKXL._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Video"
     },
     {
      "type": "plain",
      "text": " Games\n\n                Video Games \n                PlayStation 4 \n                Accessories \n                 Headsets \n                 Headsets \nPryce698 \nDicos42 \n\n❌Regular Price  : ₹  1099   /- 😱"
     }
    ]
   },
   {
    "id": 21335,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683435476",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "494",
    "dicos": "69",
    "catagory":"Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Home Elite 210 TC Pure Cotton King Size Bedsheet with 2 Pillow Covers (Black, 100x108 inch)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42nkXsm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pjJWOQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M4krdA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M4krdA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M1KKAP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44NbJYu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42y7JsG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M28DZf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44Ojopx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pbxZuF"
     },
     {
      "type": "plain",
      "text": "\n\n @494\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71JBPQAmlVL._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Home Furnishing \n                Bedding & Linen \n                Bedsheets \n                Bedsheets \nPryce494 \nDicos69 \n\n❌Regular Price  : ₹   1299  /- 😱"
     }
    ]
   },
   {
    "id": 21336,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683435531",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1499",
    "dicos": "73",
    "catagory":"Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Milton Pro Cook Induction Kitchen Jewel Set of 5 (Fry pan 24 cm/1.6 litres; Kadhai 24 cm/2.5 litres with Glass lid; Tawa 25 cm; Nylon Laddle and Spatula), Peach | Dishwasher | Hot Plate | Flame Safe✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42dyz9K"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VGv09M"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3peO0A0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pf7HHV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VHAlhe"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VIyc4S"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/429GVzt"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VGtRPj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VGTRuf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VIyitg"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1499"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71n60BBalEL._SX522_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Dining \n                Cookware \n                Pots & Pans \n                Pots & Pans \nPryce1499 \nDicos73 \n\n❌Regular Price  : ₹  3999  /- 😱"
     }
    ]
   },
   {
    "id": 21337,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683435610",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "18999",
    "dicos": "55",
    "catagory":"Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "Adorn India Chandler L Shape 5 Seater Sofa Set Plain (Right Hand Side) (Grey)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42aaQqZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3piMD3q"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NLzuKr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NKy3vy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44tmIWG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VGu7hf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VDa7Mw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B2kT5v"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B2kT5v"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44yDvI3"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@18999"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/91DLld8mL7L._SX522_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Home & Kitchen \n                Furniture \n                Living Room Furniture \n                 Sofas & Couches \n                 Sofas & Couches \nPryce18999 \nDicos55 \n\n❌Regular Price  : ₹   36999    /- 😱"
     }
    ]
   },
   {
    "id": 21338,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683435666",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "527",
    "dicos": "82",
    "catagory":"Shoes",
    "text_entities": [
     {
      "type": "plain",
      "text": "Burwood Men's Bwd 66 Sandals✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42cIKvs"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pi87NT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VDaaba"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3piMPzG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41aBwXy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B1gFev"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41edHhB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LIvszO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VEg63H"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VEg6Rf"
     },
     {
      "type": "plain",
      "text": "\n\n @527\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71P+67To3cL._UY575_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Shoes"
     },
     {
      "type": "plain",
      "text": " & Handbags\n\n                Fashion \n                Steal Deals - Great Freedom Festival \n                Last chance to buy : Min 60% off \n                MFA ASINs \n                MFA ASINs \nPryce527 \nDicos82 \n\n❌Regular Price  : ₹  1999   /- 😱"
     }
    ]
   },
   {
    "id": 21339,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683435737",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "229",
    "dicos": "76",
    "catagory":"Health",
    "text_entities": [
     {
      "type": "plain",
      "text": "Zmoly Nova 1000w NV-1290 ThermoProtect, Cool Shot for Quick Drying with care Men's And Women's Salon Style foldable hair dryer.✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HLvIwH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HLvIwH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42ywHIs"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HMd4os"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AZUfdC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42taDz0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AZ5sLs"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B3IXF8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B2yQ3y"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42oETv6"
     },
     {
      "type": "plain",
      "text": "\n\n @229\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61rwJ2G2jVL._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Health"
     },
     {
      "type": "plain",
      "text": " & Personal Care\n\n                Beauty \n                Hair Care \n                Hair Styling Tools \n                Hair Dryers & Accessories \n                Hair Dryers & Accessories \nPryce229 \nDicos76 \n\n❌Regular Price  : ₹  899   /- 😱"
     }
    ]
   },
   {
    "id": 21340,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683435800",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "149",
    "dicos": "63",
    "catagory":"Beauty",
    "text_entities": [
     {
      "type": "plain",
      "text": "Soulflower Lavender Essential Oil | Skin, Acne, Hair Fall, Relaxing Sleep, Aromatherapy & Body | Lavandula Angustifolia | Pure, Organic, Natural & Undiluted, Ecocert Certified | 10ml✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42dyXVK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42sJEne"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AZAkeG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AZAkeG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nFf9M7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HOk5VH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HO7GkK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HMnC6O"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44xZa2V"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VIyzfM"
     },
     {
      "type": "plain",
      "text": "\n\n @149\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/610ETPY7H3L._SY879_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Beauty"
     },
     {
      "type": "plain",
      "text": "\n\n                Beauty \n                Bath & Body \n                Bath Additives \n                 Bath Oils \n                 Bath Oils \nPryce149 \nDicos63 \n\n❌Regular Price  : ₹  400  /- 😱"
     }
    ]
   },
   {
    "id": 21341,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683435880",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "4015",
    "dicos": "49",
    "catagory":"Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Pigeon By Stovekraft Oven Toaster Grill (12624) 30 Liters Otg With Rotisserie, Oven Toaster And Grill For Grilling And Baking Cakes (Grey), Standard✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HQnHXh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VEIYZK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VEIYZK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41bjcNJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LYsloB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LBS9Wl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LHJPUL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LG5nRN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M1KVvZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LH4ecJ"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@4015"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51SHRLSCS0L._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Home Appliances \n                Small Kitchen Appliances \n                 Oven Toaster Grills \n                 Oven Toaster Grills \nPryce4015 \nDicos49 \n\n❌Regular Price  : ₹  6999   /- 😱"
     }
    ]
   },
   {
    "id": 21342,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683435954",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "14999",
    "dicos": "25",
    "catagory":"Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "iQOO Z6 Lite 5G (Stellar Green, 6GB RAM, 128GB Storage) with Charger | World's First Snapdragon 4 Gen 1 | Best in-Segment 120Hz Refresh Rate | Travel Adaptor Included in The Box✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LDKeYE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LxM7pC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nCjzU0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M3eNs8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NOVcNs"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NLPmMR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NKyeac"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M2bd1e"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44tmYoC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M03zoa"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@14999"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61VbKHdE0rL._SX466_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Mobiles & Accessories \n                Smartphones & Basic Mobiles \n                 Smartphones \n                 Smartphones \nPryce14999 \nDicos25 \n\n❌Regular Price  : ₹   19999  /- 😱"
     }
    ]
   },
   {
    "id": 21343,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683436031",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "10999",
    "dicos": "27",
    "catagory":"Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "Redmi 11 Prime (Peppy Purple, 4GB RAM 64GB ROM) | Prime Design | High Performance Helio G99 | 50 MP AI Triple Cam | 5000 mAh | 22.5W✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M3pbzY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NLA3nx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NMLZ8y"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41fu4KF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41iaUnf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NGrxGh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B4OdZj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3O3uyRl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B2aUx6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B2yZnC"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@10999"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51TuDBH70FL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Mobiles & Accessories \n                Smartphones & Basic Mobiles \n                 Smartphones \n                 Smartphones \nPryce10999 \nDicos27 \n\n❌Regular Price  : ₹   14999  /- 😱"
     }
    ]
   },
   {
    "id": 21344,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683436106",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "649",
    "dicos": "58",
    "catagory":"Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Pigeon 1.5 litre Hot Kettle and Stainless Steel Water Bottle Combo used for boiling Water, Making Tea and Coffee, Instant Noodles, Soup, 1500 Watt with Auto Shut- off Feature - (Silver)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41bVGQM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41bVGQM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LH4usd"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42wP94h"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HLTiJC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HLTiJC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HOkg3j"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42vqKMr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HObeUa"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42uvHVH"
     },
     {
      "type": "plain",
      "text": "\n\n @649\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51VvdjBgB5L._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Home Appliances \n                Small Kitchen Appliances \n                Kettles & Hot Water Dispensers \n                Kettles & Hot Water Dispensers \nPryce649 \nDicos58 \n\n❌Regular Price  : ₹  1299   /- 😱"
     }
    ]
   },
   {
    "id": 21345,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683436180",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "130",
    "dicos": "50",
    "catagory":"Beauty",
    "text_entities": [
     {
      "type": "plain",
      "text": "Bajaj 100% Pure Coconut Oil 600 ml✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42vqNrB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42vqYTN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44Ncb98"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M1QISe"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/427H8TA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44uZX4R"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42aWYgp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42vr4e7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pixU8z"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42rDhkF"
     },
     {
      "type": "plain",
      "text": "\n\n @130\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51bP5mNwo3L._SY450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Beauty"
     },
     {
      "type": "plain",
      "text": "\n\n                Beauty \n                Hair Care \n                 Hair Oils \nPryce130 \nDicos50 \n\n❌Regular Price  : ₹  261   /- 😱"
     }
    ]
   },
   {
    "id": 21346,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683436257",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "41490",
    "dicos": "41",
    "catagory":"Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "Sony Bravia 108 cm (43 inches) 4K Ultra HD Smart LED Google TV KD-43X74K (Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42doGsT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3piNalW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VGBPrP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42flA7M"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VBVTeZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pdvFDs"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VILrCn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VEJdE8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VEJdE8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NKfbNi"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@41490"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/81wxS8abrgL._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Home Theater, TV & Video \n                Televisions \n                 Smart Televisions \n                 Smart Televisions \nPryce41490 \nDicos41 \n\n❌Regular Price  : ₹  60999  /- 😱"
     }
    ]
   },
   {
    "id": 21347,
    "type": "message",
    "date": "2023-05-07",
    "date_unixtime": "1683436310",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1299",
    "dicos": "80",
    "catagory":"Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "Gionee STYLFIT GSW11 Smartwatch with Large 1.8 IPS Display, SpO2 & Stress Monitor, Heart Rate Monitor & 200+ Watch Faces(State Silver)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44NcfFU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pi8yYx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nwhWav"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VGC0mZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VGvJI2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NMMcsm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NMMcsm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42rDyEd"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VGi7wf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B6HAp6"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1299"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71MhwVch5QL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Electronics \n                Wearable Technology \n                 Smart Watches \nPryce1299 \nDicos80 \n\n❌Regular Price  : ₹4499    /- 😱"
     }
    ]
   },
  {
    "id": 20850,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683354634",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "3720",
    "dicos": "71",
    "catagory": "Office",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   HP 126A Original LaserJet Toner Cartridge, Cyan✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HKo85E"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3MaWm4V"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42pcLaW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LLy14e"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44A3L4z"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44BC6jI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NEFlRD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44CSBwi"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AZscuN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VAflc4"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@3720"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71kJkSEfjoL._SY450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Office"
     },
     {
      "type": "plain",
      "text": " Products\n\n                Computers & Accessories \n                Printers, Inks & Accessories \n                Inks, Toners & Cartridges \nPryce3720 \nDicos71 \n\n❌Regular Price  : ₹ 8999   /- 😱"
     }
    ]
   },
   {
    "id": 20851,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683354699",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "55990",
    "dicos": "44",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Sony Bravia 139 cm (55 inches) 4K Ultra HD Smart LED Google TV KD-55X74K (Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lyn45M"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B0IjbK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HMqwsy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HMqwsy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NIKXKw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LZVIH1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HFUzSI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M0TkzR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41qjvVn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41qjEbn"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@55990"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/81wxS8abrgL._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Home Theater, TV & Video \n                Televisions \nPryce55990 \nDicos44 \n\n❌Regular Price  : ₹  74999   /- 😱"
     }
    ]
   },
   {
    "id": 20852,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683354771",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "66999",
    "dicos": "16",
    "catagory": "All",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Apple iPhone 14 (128 GB) - Midnight✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nuuX4n"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pfRBhl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41eXRD8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41eXRD8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41eXRD8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pcKXbI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nuv7sv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VENEPl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LvYOBi"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LvYOBi"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@66999"
     },
     {
      "type": "plain",
      "text": "\n \n "
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61cwywLZR-L._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#All"
     },
     {
      "type": "plain",
      "text": "\n\nPryce66999 \nDicos16 \n\n❌Regular Price  : ₹  75999   /- 😱"
     }
    ]
   },
   {
    "id": 20853,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683354871",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "299",
    "dicos": "63",
    "catagory": "Grocery",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Bevzilla 200 Gram 100% Arabica Instant Classic Strong Coffee Powder | Make 100 Cups | Strong Coffee| Classic Coffee| Espresso, Latte & Cappucino| Hot & Cold Coffee | Unbreakable Jar✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B26M0i"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HJHYOf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HGKUuY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VCuLwh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nAMc3P"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VBdUtY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HMydih"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HMydih"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VEGU3L"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HFUFty"
     },
     {
      "type": "plain",
      "text": "\n\n @299\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71xwcJP81WL._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Grocery"
     },
     {
      "type": "plain",
      "text": " & Gourmet Foods\n\n                Grocery & Gourmet Foods \n                Coffee, Tea & Beverages \n                Coffee \nPryce299 \nDicos63 \n\n❌Regular Price  : ₹ 749   /- 😱"
     }
    ]
   },
   {
    "id": 20854,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683354945",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "11999",
    "dicos": "71",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   ZEBRONICS Zeb-Juke BAR 9450 PRO Dolby 5.1 soundbar with Dolby Audio, 525 Watts Output, Dual Rear Satellites, 16.5cm Powerful subwoofer, HDMI ARC, Optical, BT v5.0, LED Display, Wall Mount, AUX, Black✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HJ34MC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42uo4yB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42wKfV8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nDuxbR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3I7D2Dd"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3I7D2Dd"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HK4Vkg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LCPPOL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42fw9Yj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42dxA9Q"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@11999"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71aM3pVHzPL._SY450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Home Audio \n                Speakers \nPryce11999 \nDicos71 \n\n❌Regular Price  : ₹  29999  /- 😱"
     }
    ]
   },
   {
    "id": 20855,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683355019",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "94",
    "dicos": "33",
    "catagory": "Beauty",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Fogg Scent Tycoon 30 ml✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nAnjoZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LYwRUe"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LYwRUe"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LYavlI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41d0LIu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44tbWjn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/419MGvA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44yLh4A"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44BunT2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NJNSmo"
     },
     {
      "type": "plain",
      "text": "\n\n @94\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51ITc0M-viL._SY450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Beauty"
     },
     {
      "type": "plain",
      "text": "\n\n                Beauty \n                Bath & Body \n                Deodorants & Antiperspirants \nPryce94 \nDicos33 \n\n❌Regular Price  : ₹  149   /- 😱"
     }
    ]
   },
   {
    "id": 20856,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683355094",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "2512",
    "dicos": "69",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Cello Novelty Big Plastic 2 Door Cupboard with 3 Shelves (Brown and Beige)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44x1yXM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VAkZuP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VAkZuP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nxQLfe"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nxQLfe"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nrPkza"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nrPkza"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B4VBne"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pglUVb"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VJRMh6"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@2512"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71hMwwqIXOL._SX466_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Home & Kitchen \n                Furniture \n                Bedroom Furniture \nPryce2512 \nDicos69 \n\n❌Regular Price  : ₹    4999 /- 😱"
     }
    ]
   },
   {
    "id": 20857,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683355166",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "2099",
    "dicos": "65",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Hindware Smart Appliances Moneta BLDC Fan 1200 MM Aerodynamic 3 Blades Ceiling Fan for Home comes with Inverter Stabilization Technology, Energy Efficient and Less Power Consumption (White)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LFWesj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LHAo7M"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HJ3pyS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nAyFta"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nAyFta"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41ab9Rm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42tjPDp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42tEoQb"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B0McgI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42qTUwg"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@2099"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/411gWbyz7KL._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Heating, Cooling & Air Quality \n                Fans \nPryce2099 \nDicos65 \n\n❌Regular Price  : ₹  5999  /- 😱"
     }
    ]
   },
   {
    "id": 20858,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683355238",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1799",
    "dicos": "70",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   EDYELL Newly C5S Wireless Bluetooth Earbud, ENC Nosie Cancelling HD Mic, LED Power Display, 120 Hours Playtime 3500mah Charging Case, 13mm Dynamic Drivers, Fast Charging, IPX7 Water Resistant, Touch Controls and Voice Assistant✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B6mMhv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B6mMhv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44rzOnk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NF7gko"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M0Doh5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pfS1Er"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HJB5fQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HGLIju"
     },
     {
      "type": "plain",
      "text": "\n\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NILOLe"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1799"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61lLLlgV9kL._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Headphones, Earbuds & Accessories \n                Headphones \nPryce1799 \nDicos70 \n\n❌Regular Price  : ₹   4999  /- 😱"
     }
    ]
   },
   {
    "id": 20859,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683355309",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "299",
    "dicos": "85",
    "catagory": "Clothing",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Desi belle Women's Regular Fit T-Shirt✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/415hqhi"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41iZrnO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41fm8sO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pi6oYB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nrPDKk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41eYlJs"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41eYlJs"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NJRQv1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41dFUF7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M2uksf"
     },
     {
      "type": "plain",
      "text": "\n\n @299\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71mGsESwpYL._UX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Clothing"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Fashion \n                Apparel Steal Deals \n                Apparel_1 \nPryce299 \nDicos85 \n\n❌Regular Price  : ₹ 1399   /- 😱"
     }
    ]
   },
   {
    "id": 20860,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683355365",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "379",
    "dicos": "45",
    "catagory": "Sports",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   NIVIA Deflate Round 01 Polyester Gym Bag/Unisex Gym Bags/Adjustable Shoulder Bag for Men/Duffle Gym Bags for Men/Carry Gym Accessories/Fitness Bag/Sports &Travel Bag/Gym kit Bag✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41apCNh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/429NTV3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41fmu2C"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41b4czt"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pd1c8w"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M0Dzcf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42aDF6Q"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M1Drcu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42bNQYE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42mRrTK"
     },
     {
      "type": "plain",
      "text": "\n\n @379\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/81vIhM57OBL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Sports"
     },
     {
      "type": "plain",
      "text": ", Fitness & Outdoors\n\n                Bags, Wallets and Luggage \n                Bags & Backpacks \n                Gym Bags \nPryce379 \nDicos45 \n\n❌Regular Price  : ₹   695  /- 😱"
     }
    ]
   },
   {
    "id": 20861,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683355436",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "14990",
    "dicos": "53",
    "catagory": "Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   AXL Laptop (Vayu Book) Newly Launched Thin & Light | 14.1 Inch HD Display (4GB/128GB SSD | 1920 * 1080 FHD IPS | HD Gemini Lake N4020 | Windows 11 Home | UHD Graphics 600 | Space Grey✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NJRTHd"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NGjcT1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NEGLeV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42sqJsO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VEHrmh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42bCJiD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nxRa1e"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42b0uHp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/428pIWN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AXhTYa"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@14990"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/5126jVNmaCL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Computers & Accessories \n                Laptops \n                 Traditional Laptops \nPryce14990 \nDicos53 \n\n❌Regular Price  : ₹  25999   /- 😱"
     }
    ]
   },
   {
    "id": 20862,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683355510",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "2949",
    "dicos": "53",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Prestige Iris 750 Watt Mixer Grinder with 3 Stainless Steel Jar + 1 Juicer Jar (White and Blue)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B6n5sF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VCybiC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42v6zhH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42sqLAW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42sqLAW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HG0fvF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VzHPmh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VCztKw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p8ZYuR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HM6YnU"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@2949"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/81+M1QrVNIL._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Home Appliances \n                Small Kitchen Appliances \nPryce2949 \nDicos53 \n\n❌Regular Price  : ₹  4999   /- 😱"
     }
    ]
   },
   {
    "id": 20863,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683355581",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "249",
    "dicos": "17",
    "catagory": "Grocery",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Christopher Cocoa, Chocolate Mocha, 50g (Instant Coffee Cocoa Powder, No sugar, Vegan)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LvZFC0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44wRXAj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44u3oZD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44DfcIP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44wbRv7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44x1SWu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44C5CG8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44wc6X3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44wc6X3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44u3ujX"
     },
     {
      "type": "plain",
      "text": "\n\n @249\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71OEcmlg9lL._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Grocery"
     },
     {
      "type": "plain",
      "text": " & Gourmet Foods\n\n                Grocery & Gourmet Foods \n                Coffee, Tea & Beverages \n                Coffee \nPryce249 \nDicos17 \n\n❌Regular Price  : ₹  299  /- 😱"
     }
    ]
   },
   {
    "id": 20864,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683355651",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1372",
    "dicos": "72",
    "catagory": "Watches",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Daniel Klein Analog Black Dial Women's Watch-DK11637-6✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3piFd08"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LEhSgH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LvZRBe"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LCQOyr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3I7DEJ1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B15aDU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B15bHY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HKnogM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M0RJdi"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nB80wg"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1372"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61LSLpL42qL._UY741_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Watches"
     },
     {
      "type": "plain",
      "text": "\n\n                Fashion \n                Onam 2021 \n                Watches and fashion jewellery \nPryce1372 \nDicos72 \n\n❌Regular Price  : ₹  3999   /- 😱"
     }
    ]
   },
   {
    "id": 20865,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683355724",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "18999",
    "dicos": "34",
    "catagory": "Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Samsung Galaxy Tab A8 10.5 inches Display, RAM 4 GB, ROM 64 GB Expandable, Wi-Fi+LTE Tablets, Silver,SM-X205NZSEINU✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HIdbkI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HLT6u5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3I7DTDV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44yM59C"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NL49HE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M2uMqr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M0jUct"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LWJsHn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/419Nroq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M0UBH9"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@18999"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/91afx8hEj9L._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Computers & Accessories \n                2021 \n                 Coupon eligible tablets \nPryce18999 \nDicos34 \n\n❌Regular Price  : ₹   25999  /- 😱"
     }
    ]
   },
   {
    "id": 20866,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683355797",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "3299",
    "dicos": "65",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Preethi Blue Leaf Diamond MG-214 mixer grinder 750 watt (Blue/White), 3 jars & Flexi Lid, FBT motor with 2yr Guarantee & Lifelong Free Service✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VAlPaX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41aq12f"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VAbImp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pjgUij"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M399pX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/419ujXM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VF7uKr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/415i52g"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/419ulPo"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/425UfVk"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@3299"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51HsWbTBzwL._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Home Appliances \n                Small Kitchen Appliances \nPryce3299 \nDicos65 \n\n❌Regular Price  : ₹  8499  /- 😱"
     }
    ]
   },
   {
    "id": 20867,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683355869",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "399",
    "dicos": "50",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Portronics Power Plate 4 with 4 Power Sockets + 1 USB Port Power Converter with Extension I Cord 1.5 Meter I 1500 Watts Max (White)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42dyaEy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42dphuw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42bwlrp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42bwlrp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NF85K0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42wKYFQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42rxVFt"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LByjdD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42yZ9dr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VJSwmo"
     },
     {
      "type": "plain",
      "text": "\n\n @399\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/3190RTqNglS.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Power Accessories \n                 Extension Cords \nPryce399 \nDicos50 \n\n❌Regular Price  : ₹  799  /- 😱"
     }
    ]
   },
   {
    "id": 20868,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683355942",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "30999",
    "dicos": "64",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   iFFALCON 147 cm (58 inches) 4K Ultra HD Smart LED Google TV iFF58U62 (Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42wKPlo"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42wKPlo"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/425UwYm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LLzwPU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AWQbee"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LGHA48"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42adVYe"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/425UzU2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LDZvZt"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/421Kg3n"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@30999"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/714OTuxxnfL._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Home Theater, TV & Video \n                Televisions \nPryce30999 \nDicos64 \n\n❌Regular Price  : ₹  54999  /- 😱"
     }
    ]
   },
   {
    "id": 20869,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683356014",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "395",
    "dicos": "80",
    "catagory": "Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   HP w100 480P 30 FPS Digital Webcam with Built-in Mic, Plug and Play Setup, Wide-Angle View for Video Calling on Skype, Zoom, Microsoft Teams and Other Apps (Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42p29Zy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42roW7c"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HMzsxX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p48MCf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VzIofT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42vJtaU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42roZzU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VCWRrm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VCWRrm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HKsZne"
     },
     {
      "type": "plain",
      "text": "\n\n @395\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61HfJn05shL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Computers & Accessories \n                Accessories & Peripherals \n                Audio & Video Accessories \nPryce395 \nDicos80 \n\n❌Regular Price  : ₹     /- 😱"
     }
    ]
   },
   {
    "id": 20870,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683356087",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1999",
    "dicos": "82",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   beatXP Vega 1.43\" AMOLED 466 * 466px Display One-Tap BT 5.2 Calling AI Voice Assistant Smartwatch (Electric Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42ae4ee"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42mSjru"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M2AlFk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LLzFmq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42wl7xs"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44Lo8fw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nAgV14"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44BvjH2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pdJLEV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44AhFUr"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1999"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/91NoOjOQOdL._SY450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Wearable Technology \n                 Smart Watches \nPryce1999 \nDicos82 \n\n❌Regular Price  : ₹  6999   /- 😱"
     }
    ]
   },
   {
    "id": 20871,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683356158",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1645",
    "dicos": "67",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   NutriPro Juicer Mixer Grinder - Smoothie Maker - 500 Watts (2 Jar, Silver)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44x7Lmz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44x7Lmz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44LojYe"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44COPTB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44A5RBt"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nyr26n"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44x88xt"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B0JOqo"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pdbUfp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pdbUfp"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1645"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71PkRff5mdL._SX466_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Home Appliances \n                Small Kitchen Appliances \nPryce1645 \nDicos67 \n\n❌Regular Price  : ₹  3999   /- 😱"
     }
    ]
   },
   {
    "id": 20872,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683356230",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "389",
    "dicos": "35",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Athom Living Light Weight Cotton Premium Waffle Border Bath Towel 75x150 cm Pack of 2✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41grFiF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nxRVHC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42smZaH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42qV0YU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B32aa9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AWQANM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nyH4x4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nyH4x4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HGMsoM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LYrIeQ"
     },
     {
      "type": "plain",
      "text": "\n\n @389\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71b1S-CVN2L._SY679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Home Furnishing \n                Bathroom Linen \nPryce389 \nDicos35 \n\n❌Regular Price  : ₹  599   /- 😱"
     }
    ]
   },
   {
    "id": 20873,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683356297",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "140",
    "dicos": "50",
    "catagory": "Beauty",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Park Avenue Cool Blue Soap Pack of 125 * 4 (500gm)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nC7aiS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M0SwLi"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M0SwLi"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M0SwLi"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HK6m28"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NMRZ0T"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LYxYmG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44MzBLV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44C6eLW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NFuoiN"
     },
     {
      "type": "plain",
      "text": "\n\n @140\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/81VZi7VFmRL._SX569_PIbundle-4,TopRight,0,0_AA569SH20_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Beauty"
     },
     {
      "type": "plain",
      "text": "\n\n                Beauty \n                Bath & Body \n                Cleansers \nPryce140 \nDicos50 \n\n❌Regular Price  : ₹   280  /- 😱"
     }
    ]
   },
   {
    "id": 20874,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683356371",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "196",
    "dicos": "67",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Kolorr Stitch 7L Plastic Pedal Modern Design Waste Bin (Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41aqsJV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NNfc30"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NFuw1L"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/415ryqp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NL4KZU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VEwjG3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VEwjG3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M0V783"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NF8CM0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M0Ug7q"
     },
     {
      "type": "plain",
      "text": "\n\n @196\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/816-WyQmYgL._SX466_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Home Storage & Organisation \n                Waste & Recycling \nPryce196 \nDicos67 \n\n❌Regular Price  : ₹  599  /- 😱"
     }
    ]
   },
   {
    "id": 20875,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683358775",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1299",
    "dicos": "79",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Lifelong LLGS10 Glass Top, 2 Burner Manual Glass Gas Stove, Black (ISI Certified)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44xVPB3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/414Yhfm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44C0odq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41d4N3A"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/419Rktw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41cxP3B"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41fqn7I"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42bRMZq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/414YoHO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41d4YMi"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1299"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71Q5pFUtYmL._SX466_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & dining \n                Steal deals \nPryce1299 \nDicos79 \n\n❌Regular Price  : ₹   4999  /- 😱"
     }
    ]
   },
   {
    "id": 20876,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683358850",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1799",
    "dicos": "71",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Lifelong LLGS18 Glass Top 3 Burner Gas Stove, Manual Ignition, LPG Compatible, Black (ISI Certified, Door Step Service, 1 Year Warranty)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VFpowg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VFpowg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42mVEXy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M0HiX7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VSBaUx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pcPL0K"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pgqpix"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pgqpix"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VzLEI9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VzLEI9"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1799"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51bMcjdgQeL._SX466_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & dining \n                Steal deals \nPryce1799 \nDicos71 \n\n❌Regular Price  : ₹  4999   /- 😱"
     }
    ]
   },
   {
    "id": 20877,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683358924",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "999",
    "dicos": "70",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Pigeon Basics Non Induction Base Nons tick Aluminium Cookware set, including Nonstick Dosa Tawa, Nonstick Kadai With Glass Lid, and Nonstick Frying Pan, (Pink)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42wosN0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LEl6kj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LEl6kj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AYsjXt"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nFKLRB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LGMkXG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LGMkXG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/428QEGc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AYvKNN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AXMKnk"
     },
     {
      "type": "plain",
      "text": "\n\n @999\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61JlllBcGpL._SX522_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Dining \n                Cookware \nPryce999 \nDicos70 \n\n❌Regular Price  : ₹   2499  /- 😱"
     }
    ]
   },
   {
    "id": 20878,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683358995",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "2099",
    "dicos": "62",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Lifelong Llwh106 Flash 3 Litres Instant Water Heater For Home Use, 8 Bar Pressure,Power On/Off Indicator And Advanced Safety, (3000W, Isi Certified, 2 Years Warranty), Wall Mounting✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LLD3xE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42wOKPw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42svgvk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VCCn1Q"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B0Bzu9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HJMOen"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HJMOen"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nBcbbq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HKE4EC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3I7HC4n"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@2099"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/613ys7U16kL._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Heating, Cooling & Air Quality \n                Water Heaters & Geysers \nPryce2099 \nDicos62 \n\n❌Regular Price  : ₹  3999   /- 😱"
     }
    ]
   },
   {
    "id": 20879,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683359137",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1699",
    "dicos": "77",
    "catagory": "Clothing",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   kamiliant by American TouristerHarrier Spinner Polypropylene (PP) 56 Cm Small Crimson Red Cabin Hard Luggage✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42vapHK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LWN24h"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42dCd3I"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M1yYGU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pe7tAW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44LrZJw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VzM4yd"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VzM4yd"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LGLLgk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nrTLtO"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1699"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/719yO4IKh2L._UY741_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Clothing"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Bags, Wallets and Luggage \n                Luggage \n                 Suitcases & Trolley Bags \nPryce1699 \nDicos77 \n\n❌Regular Price  : ₹  4999    /- 😱"
     }
    ]
   },
   {
    "id": 20880,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683360264",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "4498",
    "dicos": "39",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Bajaj PMH 25 DLX 24L Personal Air Cooler for home with DuraMarine Pump (2-Yr Warranty by Bajaj) Anti-Bacterial Hexacool Master, TurboFan Technology, 3-SpeedControl, Portable AC, White Cooler for Room✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LYAbO3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LYAbO3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42lBHjI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42lBHjI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42rDfIX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/428uB29"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42uu2zv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p6uIN4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B2iaJi"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HJNIaL"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@4498"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61o4Iv+vdBL._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Heating, Cooling & Air Quality \n                Air Coolers \nPryce4498 \nDicos39 \n\n❌Regular Price  : ₹   6499  /- 😱"
     }
    ]
   },
   {
    "id": 20881,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683360610",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "3950",
    "dicos": "64",
    "catagory": "Baby",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Fisher-Price Rover Steel Stroller Cum Pram for Baby(Red)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3I7JbPN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41dLDuB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HIiZL2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VBmoBc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HIj01y"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pcRnHQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NKr4mC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pcRoLU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pcRoLU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M0Jmyv"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@3950"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/81F9cDEMbeL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Baby"
     },
     {
      "type": "plain",
      "text": "\n\n                Baby \n                Newborn Baby Store \n                Travel \nPryce3950 \nDicos64 \n\n❌Regular Price  : ₹  9999   /- 😱"
     }
    ]
   },
   {
    "id": 20882,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683360688",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1423",
    "dicos": "58",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Lifelong Duos 500 Watt 2 Jar Mixer Grinder with 3 Speed Control and 1100 Watt Dry Non-Stick soleplate Iron Super Combo (Black, 1 Year Warranty)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nAlOHs"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NKrb1w"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M100hp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NFcywg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NFcywg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nrVmzO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NS4cBk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NS4cBk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41b9Q4D"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3picmIZ"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1423"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61whl+GwjiL._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Home Appliances \n                Small Kitchen Appliances \nPryce1423 \nDicos58 \n\n❌Regular Price  : ₹   2999   /- 😱"
     }
    ]
   },
   {
    "id": 20883,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683360768",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "3289",
    "dicos": "59",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Pigeon Healthifry Digital Air Fryer, 360° High Speed Air Circulation Technology 1200 W with Non-Stick 4.2 L Basket - Green✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42b1PhA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41dLZRX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LH3qop"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LCWkRF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42aJjWA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LGO3ME"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/425ZqVg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LBDQ3T"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LG20tZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LG20tZ"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@3289"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61mfMId4dkL._SX466_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Home Appliances \n                Small Kitchen Appliances \nPryce3289 \nDicos59 \n\n❌Regular Price  : ₹  4999   /- 😱"
     }
    ]
   },
   {
    "id": 20884,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683360847",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "2899",
    "dicos": "47",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Lifelong Aspire 1000-Watt, 10-Litre Wet & Dry Vacuum Cleaner, Blower Function - for Home/Office/Car use with High Power Suction; with Multiple Accessories; 1 Year Warranty (Red & Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42c7u6S"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LyuQN0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AWf75D"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42NdfrR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42NdfrR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42z4stn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AYcOyQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ph6ikg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VBuMR6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42uuwWl"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@2899"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/610rACbO6QL._SX522_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Home Appliances \n                Vacuum, Cleaning & Ironing \nPryce2899 \nDicos47 \n\n❌Regular Price  : ₹  4999   /- 😱"
     }
    ]
   },
   {
    "id": 20885,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683360921",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "907",
    "dicos": "35",
    "catagory": "Beauty",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   The Moms Co Ultimate Head to Toe Set - A Box of Nourishment for Skin & Hair with goodness of Cocoa, Shea Butter & 8 Powerful Oils✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42wMIP1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B1azLc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HJH6sW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/429gJ85"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/429gJ85"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B0rw8s"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pdrCab"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42bTTMQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42bTTMQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AYcXlS"
     },
     {
      "type": "plain",
      "text": "\n\n @907\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71cbZYZ7RDL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Beauty"
     },
     {
      "type": "plain",
      "text": "\n\n                Beauty \n                Bath & Body \n                 Bath Sets & Kits \nPryce907 \nDicos35 \n\n❌Regular Price  : ₹  1299   /- 😱"
     }
    ]
   },
   {
    "id": 20886,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683360996",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "336",
    "dicos": "53",
    "catagory": "Beauty",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Nivea Deodorant Roll On, Whitening Smooth Skin For Women, 50ml (Pack Of 3)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3piKqVK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VH4tcj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44rG5iS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44BA8jC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M3emhv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44AmPQj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NKNOCP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HKblzS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HKblzS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42ru3UW"
     },
     {
      "type": "plain",
      "text": "\n\n @336\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51eYK39mEbS._SX569_PIbundle-3,TopRight,0,0_AA569SH20_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Beauty"
     },
     {
      "type": "plain",
      "text": "\n\n                Beauty \n                Bath & Body \n                Deodorants & Antiperspirants \nPryce336 \nDicos53 \n\n❌Regular Price  : ₹   747  /- 😱"
     }
    ]
   },
   {
    "id": 20887,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683361077",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "4998",
    "dicos": "72",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   SAVYA HOME® APEX Chairs™ Apollo Chrome Base HIGH Back Office CHAIR2✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NK2nGF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LGOir2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VBv0Ys"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LFtZKl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p95Nsd"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VSDeff"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44wY8Ev"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44xd5X5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44xXGWx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41fsyrU"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@4998"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/81yJVI6Za5L._SX522_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Home & Kitchen \n                Furniture \n                Study & Home Office Furniture \nPryce4998 \nDicos72 \n\n❌Regular Price  : ₹  12999   /- 😱"
     }
    ]
   },
   {
    "id": 20888,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683361694",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "7499",
    "dicos": "43",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Havells Fab UV Storage 7 Litre UV + UF Purified Water Purifier with 5 Stages and transparent removab (Not Suitable for Borewell or tanker water)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42607hk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B37F8N"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nATjcx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42fD0Rx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AWW0bA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M0cCoW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pe9Svs"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pfmUsA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nAFJWI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nCcCSS"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@7499"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71JDhyVRe3L._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Home Appliances \n                Water Purifiers & Accessories \nPryce7499 \nDicos43 \n\n❌Regular Price  : ₹  12999   /- 😱"
     }
    ]
   },
   {
    "id": 20889,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683361774",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "7899",
    "dicos": "51",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Havells Aquas 7 Litre RO+UF with 5 Stages Purification Water Purifier (White & Blue)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44x4uDQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44x4uDQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VJYEeo"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NKOtUP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nBeknu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nBeknu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VH5dhB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NFAjo1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LHHNnA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VArGNt"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@7899"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51mmACaG3sL._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Home Appliances \n                Water Purifiers & Accessories \nPryce7899 \nDicos51 \n\n❌Regular Price  : ₹  13999    /- 😱"
     }
    ]
   },
   {
    "id": 20890,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683361830",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "2959",
    "dicos": "64",
    "catagory": "Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "APC 700VA Line Interactive UPS✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VBnFZ0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VENs2l"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nIwJyC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LCwdKv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41awdHx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LBZvJu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/415wRWR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41brdCx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42y1GEL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pfYQpx"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@2959"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/91vWFtX6xAL._SY741_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Computers & Accessories \n                Accessories & Peripherals \n                 Uninterrupted Power Supplies \nPryce2959 \nDicos64 \n\n❌Regular Price  : ₹  7599    /- 😱"
     }
    ]
   },
   {
    "id": 20891,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683361908",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "129",
    "dicos": "74",
    "catagory": "Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "Portronics Toad 101 Wired Optical Mouse with 1200 DPI, Plug & Play, Hi-Optical Tracking, 1.25M Cable Length, 30 Million Click Life(Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nATnJj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B37SsB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HMFdvz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pdPP09"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41fthcC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41fthcC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41fthcC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41fthcC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LYDYfo"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LYDYfo"
     },
     {
      "type": "plain",
      "text": "\n\n @129\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51czxrySrVL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Computers & Accessories \n                Accessories & Peripherals \n                Keyboards, Mice & Input Devices \nPryce129 \nDicos74 \n\n❌Regular Price  : ₹  499   /- 😱"
     }
    ]
   },
   {
    "id": 20892,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683361965",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "480",
    "dicos": "NaN",
    "catagory": "Beauty",
    "text_entities": [
     {
      "type": "plain",
      "text": "Dove Deeply Nourishing Body Wash, With Moisturisers For Softer, Smoother Skin, For All Skin Type, 800 ml✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NNkj3c"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NJVdSY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42mYqMs"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LYDw0u"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M1BBbK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nrWovG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NFdErS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/419D6sr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NPsuw9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/428TdYQ"
     },
     {
      "type": "plain",
      "text": "\n\n @480\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/419w4PBH11L._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Beauty"
     },
     {
      "type": "plain",
      "text": "\n\n                Beauty \n                Bath & Body \n                Cleansers \nPryce480 \nDicosNaN"
     }
    ]
   },
   {
    "id": 20893,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683362042",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1499",
    "dicos": "79",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "TAGG Sonic Angle 2 14W Portable Bluetooth Speakers Wireless with Dedicated Bass Radiator || Dual Stereo, Waterproof, 10 hrs Continuous Battery Life- Active Black✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42c8kAy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LGOeY8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LGOeY8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/419Dib9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NJVtRW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NJVtRW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LvUPo8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/4291kob"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LB9i2l"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LvUS3i"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1499"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/81p5TIowTtL._SY450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Home Audio \n                Speakers \nPryce1499 \nDicos79 \n\n❌Regular Price  : ₹    4999 /- 😱"
     }
    ]
   },
   {
    "id": 20894,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683362120",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "31999",
    "dicos": "46",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Lloyd 1.5 Ton 3 Star Inverter Split AC (5 in 1 Convertible, Copper, Anti-Viral + PM 2.5 Filter, 2023 Model, White, GLS18I3FWAMC)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LvUT7m"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B1brPY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42yWFvH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B0sBNy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VEUynJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42uvFgB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B1btr4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B1btr4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AWWnTw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nATGnr"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@31999"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/31UISB90sYL._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Heating, Cooling & Air Quality \n                Air Conditioners \nPryce31999 \nDicos46 \n\n❌Regular Price  : ₹   54999  /- 😱"
     }
    ]
   },
   {
    "id": 20895,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683362198",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1999",
    "dicos": "76",
    "catagory": "Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "Boult Dive+ with 1.85\" HD Display, Bluetooth Calling Smartwatch, 500 Nits Brightness, 7 Days Battery Life, 150+ Watch Faces, 100+ Sport Modes, IP68 Waterproof Smart Watch (Jet Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HGbrbV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HGSB4k"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42dwFWM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42aKvcw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HMyLF0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pbPcEi"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HG1YkY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AY3IlR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/427kYAQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LYEgmu"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1999"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71cJhcUbjBL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Electronics \n                Wearable Technology \n                 Smart Watches \nPryce1999 \nDicos76 \n\n❌Regular Price  : ₹  6999   /- 😱"
     }
    ]
   },
   {
    "id": 20896,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683362274",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1599",
    "dicos": "36",
    "catagory": "Health",
    "text_entities": [
     {
      "type": "plain",
      "text": "Braun Beard Trimmer BT3221 and Hair Clipper for Men, Lifetime Sharp Blades, 20 Length Settings, 50mins cordless trimming, Black/Volt Green✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AXoTnU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VBw1Qg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M3fstD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VAikBf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HL8GpT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44AbKyz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HGbBA3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nAvm5b"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HL8I0Z"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LYxT2w"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1599"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/81Z4u5trnyL._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Health"
     },
     {
      "type": "plain",
      "text": " & Personal Care\n\n                Health & Personal Care \n                Personal Care \n                 Shaving, Waxing & Beard Care \nPryce1599 \nDicos36 \n\n❌Regular Price  : ₹ 1699    /- 😱"
     }
    ]
   },
   {
    "id": 20897,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683362350",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "3620",
    "dicos": "20",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Crompton Ginie Neo Personal Air Cooler - 10L, White and Light Blue✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nrWC60"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NHWwl8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LEobAT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44AnNMr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44Luzzc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42uvNg5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pgQ0Id"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NFepkI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42stzxV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LB9uP7"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@3620"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51+085ENXjL._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Heating, Cooling & Air Quality \n                Air Coolers \nPryce3620 \nDicos20 \n\n❌Regular Price  : ₹  4399   /- 😱"
     }
    ]
   },
   {
    "id": 20898,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683362439",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1501",
    "dicos": "50",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Pigeon by Stovekraft Titanium Induction Base Inner Lid Hard Anodised Aluminium Pressure Cooker, 5 litres, Black, Standard (12422)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LYBPPJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44AnP71"
     },
     {
      "type": "plain",
      "text": "\n\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44xvh2N"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pi3zqw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44ytXwu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44uaoFT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44wZEqb"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B0AHWz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B2kfF6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B2kfF6"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1501"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/411FIPpBwtS._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Dining \n                Cookware \nPryce1501 \nDicos50 \n\n❌Regular Price  : ₹   2499  /- 😱"
     }
    ]
   },
   {
    "id": 20899,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683362500",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "6464",
    "dicos": "39",
    "catagory": "Sports",
    "text_entities": [
     {
      "type": "plain",
      "text": "VESCO 24-T Drift Cycle for Big Kid's MTB Mountain Bike | Dual Disk Brake & Front Suspension Single Speed Bicycle for Boys and Girls | 16 inches Frame | Ideal for 9-14 Years (Grey)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44Ac5Bl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B0t4PO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B0t4PO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B0QcxS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nyMSGS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B2eBDc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B2eBDc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HIHaJv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LB9COB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B0EF1f"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@6464"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71pw1dqIXHL._SX466_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Sports"
     },
     {
      "type": "plain",
      "text": ", Fitness & Outdoors\n\n                Sports, Fitness & Outdoors \n                Cycling \n                Cycles \nPryce6464 \nDicos39 \n\n❌Regular Price  : ₹  9999   /- 😱"
     }
    ]
   },
   {
    "id": 20900,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683362583",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "2280",
    "dicos": "50",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Orient Electric Laurel BEE Star Rated Ceiling Fan for Home | High Speed | Designer | Rust Proof Blades | Smooth Motor Operation (Metallic Bronze, Pack of 1)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42lE1qW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44DlBUn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HMGlzf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HMGlzf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LYxUDC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LYxUDC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HL73Z1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HL73Z1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HG2pM8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HL7535"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@2280"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/41+lzhouvBL._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Heating, Cooling & Air Quality \n                Fans \nPryce2280 \nDicos50 \n\n❌Regular Price  : ₹  2999   /- 😱"
     }
    ]
   },
   {
    "id": 20901,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683362640",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "899",
    "dicos": "53",
    "catagory": "Musical",
    "text_entities": [
     {
      "type": "plain",
      "text": "Zabel Condenser Microphone with Shock Mount and Foam Shield - Blue✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M0YuvG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VCFnv8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VzOXz3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M107JW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/415xLmd"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NHWB8q"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41dNEa9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41dNEa9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41dNEa9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VBlvZw"
     },
     {
      "type": "plain",
      "text": "\n\n @899\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71qiDwy3vxL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Musical"
     },
     {
      "type": "plain",
      "text": " Instruments\n\n                Musical Instruments \n                Microphones \n                 Condenser \nPryce899 \nDicos53 \n\n❌Regular Price  : ₹ 1699   /- 😱"
     }
    ]
   },
   {
    "id": 20902,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683362719",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "639",
    "dicos": "65",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Lifelong Hard Anodized Kadai 3 Litre, Induction and Gas Compatible, Black (with Glass Lid) (Kadhai)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VBlwN4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LWQhZv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3O1Q8Wn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3O1Q8Wn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M2rFyo"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NJko7W"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41air7G"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NFBbJj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42bJMrB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41cz1UH"
     },
     {
      "type": "plain",
      "text": "\n\n @639\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61m2ZGWy1QL._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Dining \n                Cookware \nPryce639 \nDicos65 \n\n❌Regular Price  : ₹  1599   /- 😱"
     }
    ]
   },
   {
    "id": 20903,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683362778",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "998",
    "dicos": "75",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "boAt Rockerz 450 Wonder Woman Edition Bluetooth On Ear Headphones with Mic, Upto 15 Hours Playback, 40MM Drivers, Padded Ear Cushions, Integrated Controls and Dual Modes(Amazonian Red)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NPt3pL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42aKKnW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42bJQaP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lw6Zxu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41gy6SR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41bENFO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NMYEbp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41bbDGT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NG84p1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nuDu7p"
     },
     {
      "type": "plain",
      "text": "\n\n @998\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61mD5YyWSrL._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Headphones, Earbuds & Accessories \n                Headphones \nPryce998 \nDicos75 \n\n❌Regular Price  : ₹  2999   /- 😱"
     }
    ]
   },
   {
    "id": 20904,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683362860",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "28999",
    "dicos": "45",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Cruise 1.5 Ton 3 Star Inverter Split AC with 7-Stage Air Filtration (100% Copper, Convertible 4-in-1, PM 2.5 Filter, 2022 Model, CWCVBH-VQ1W183, White)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pdtb83"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pdtb83"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VAiGrz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VBoqRQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LBFuT7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LBFuT7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nyxQkr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VzBJlX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VD3UQQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p5pmle"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@28999"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/41i6xkmxtJL._SX466_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Heating, Cooling & Air Quality \n                Air Conditioners \nPryce28999 \nDicos45 \n\n❌Regular Price  : ₹  51999   /- 😱"
     }
    ]
   },
   {
    "id": 20905,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683362941",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "7499",
    "dicos": "83",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "ECOVACS Robotic Vacuum Cleaner (DEEBOT 500)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LCxhOv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B2kNLa"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AY4r6z"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42pl6LM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B6v4WF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42uwki5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42r1W8o"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p5pvoM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nyNdcC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42bVIJG"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@7499"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/81mGr2+ImBL._SX522_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Home Appliances \n                Vacuum, Cleaning & Ironing \nPryce7499 \nDicos83 \n\n❌Regular Price  : ₹  24999   /- 😱"
     }
    ]
   },
   {
    "id": 20906,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683363076",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "399",
    "dicos": "66",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Pigeon Inox Hydra 700 ml Sipper (Pack of 3, Black, Steel/Chrome, Steel)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M0YQCw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nBfm2Q"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LB9Wgh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LEoNqb"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44Cct2k"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p4g5Kb"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44Aond5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LH120P"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44yufDA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44yufDA"
     },
     {
      "type": "plain",
      "text": "\n\n @399\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71JmRJsGhUL._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Dining \n                Kitchen Storage & Containers \nPryce399 \nDicos66 \n\n❌Regular Price  : ₹  999   /- 😱"
     }
    ]
   },
   {
    "id": 20907,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683363151",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "894",
    "dicos": "75",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Wipro Aluminium Non-Rechargeable P27010_4 14-Watt Led Light (Pack of 4, Rectangular, Yellow) ✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VCFMOa"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VH6p4z"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pgtXkR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44CcRhi"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44BuMF6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3piemRv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44ubaCN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44yujDk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VEOBXH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pdQCy9"
     },
     {
      "type": "plain",
      "text": "\n\n @894\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51lgy5ZE1ML._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Indoor Lighting \n                Light Bulbs \nPryce894 \nDicos75 \n\n❌Regular Price  : ₹ 2499   /- 😱"
     }
    ]
   },
   {
    "id": 20908,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683363212",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "799",
    "dicos": "73",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "Amazon Basics Bluetooth 5.0 Truly Wireless in Ear Earbuds with mic, Up to 38 Hours Playtime, IPX-5 Rated, Type-C Charging Case, Touch Controls, Voice Asst Black✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pdjs1J"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://www.amazon.in/dp/B0B8YZRR9M?th=1&amp;linkCode=sl1&amp;tag=bmvavinash05-21&amp;linkId=771f4a2934d5b53e2d44f40975b1ee24&amp;language=en_IN&amp;ref_=as_li_ss_tl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pfZZ0j"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pfZZ0j"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44x9wAa"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44x9wAa"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41czBll"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B2flIu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AXpHsW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41fukt4"
     },
     {
      "type": "plain",
      "text": "\n\n @799\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61eTrIO5GcL._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Headphones, Earbuds & Accessories \n                Headphones \nPryce799 \nDicos73 \n\n❌Regular Price  : ₹  2499   /- 😱"
     }
    ]
   },
   {
    "id": 20909,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683363287",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "699",
    "dicos": "78",
    "catagory": "Health",
    "text_entities": [
     {
      "type": "plain",
      "text": "Wahl Color Trim Beard, Black & Silver (09891-124)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B39jY1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nAUxo9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AYvI8H"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42Nfrj5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nAUEjz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HMsclM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B25vGi"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nBfsYg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42z6fP7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HKHibc"
     },
     {
      "type": "plain",
      "text": "\n\n @699\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61cU7cwy5RL._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Health"
     },
     {
      "type": "plain",
      "text": " & Personal Care\n\n                Beauty \n                Hair Care \n                Hair Cutting Tools \nPryce699 \nDicos78 \n\n❌Regular Price  : ₹  1999   /- 😱"
     }
    ]
   },
   {
    "id": 20910,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683363369",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "198",
    "dicos": "60",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Philips 9-Watts Multipack B22 LED Cool Day White LED Bulb, Pack of 2, (Ace Saver)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nCeafE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44ubpxH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44AJWdr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HGTRo4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44rHQN0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M10CDO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44MGhJZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41j7hxK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41bBsXe"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LZYhc9"
     },
     {
      "type": "plain",
      "text": "\n\n @198\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71KmoHMs2eL._SX466_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Indoor Lighting \n                Light Bulbs \nPryce198 \nDicos60 \n\n❌Regular Price  : ₹  499   /- 😱"
     }
    ]
   },
   {
    "id": 20911,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683363451",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "499",
    "dicos": "76",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Wipro 9W B22D WiFi LED Smart Bulb with Music Sync Function, Compatible with Amazon Alexa and Google Assistant (16M Colours, Warm White/Neutral White/White + Dimmable) Pack of 1✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NFBNi5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NKPNaf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LYEwSi"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VEOGKZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M0KVwd"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VFWht6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VFWht6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VFWht6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VFWht6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LYjcfQ"
     },
     {
      "type": "plain",
      "text": "\n\n @499\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51T0RbnCy6L._SX466_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Indoor Lighting \n                Light Bulbs \nPryce499 \nDicos76 \n\n❌Regular Price  : ₹  1699   /- 😱"
     }
    ]
   },
   {
    "id": 20912,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683363527",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "199",
    "dicos": "92",
    "catagory": "Shoes",
    "text_entities": [
     {
      "type": "plain",
      "text": "Bagsy Malone Women's Vegan Leather Iconic Sling Bag | Ladies Purse Handbag✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/415yqnH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/418YbDC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41gyHE5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://www.amazon.in/Bagsy-Malone-Iconic-Sling-Bag/dp/B0BGZGQ1CX?th=1&amp;linkCode=sl1&amp;tag=avi1deals-21&amp;linkId=3caf3d6bd2a74279a83edc1ec6d547b2&amp;language=en_IN&amp;ref_=as_li_ss_tl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41bsGJ3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NMZ8yf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/427lLBO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NJl2Co"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NJl2Co"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NJWLMM"
     },
     {
      "type": "plain",
      "text": "\n\n @199\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71lR1Qye8lL._UY625_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Shoes"
     },
     {
      "type": "plain",
      "text": " & Handbags\n\n                Fashion \n                Pongal || Fashion accessories \n                Handbags \nPryce199 \nDicos92 \n\n❌Regular Price  : ₹   999   /- \n😱"
     }
    ]
   },
   {
    "id": 20913,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683363612",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "18999",
    "dicos": "14",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "Oppo A78 5G (Glowing Blue, 8GB RAM, 128 Storage) | 5000 mAh Battery with 33W SUPERVOOC Charger| 50MP AI Camera | 90Hz Refresh Rate | with No Cost EMI/Additional Exchange s✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41fuD7c"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/419C6F0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LBFSRz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LywWwm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LGQb76"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42dFHTQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LFvLLv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LG2UXs"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42y38qH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LBG17z"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@18999"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/8104evx11QL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Mobiles & Accessories \n                Smartphones & Basic Mobiles \nPryce18999 \nDicos14 \n\n❌Regular Price  : ₹  20999   /- 😱"
     }
    ]
   },
   {
    "id": 20914,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683363688",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "4109",
    "dicos": "2",
    "catagory": "Watches",
    "text_entities": [
     {
      "type": "plain",
      "text": "Fastrack Mean Machines Analog-Digital White Dial Men's Watch-NN38054PM01✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LvWfPu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/429VK4Z"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AXpZQy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pdXo6W"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42p9sAs"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/421RCE1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pa5MnX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HJWEg9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42alrCq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HKuF04"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@4109"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61gejolUn1L._UX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Watches"
     },
     {
      "type": "plain",
      "text": "\n\n                Fashion \n                Onam 2021 \n                Watches and fashion jewellery \nPryce4109 \nDicos2 \n\n❌Regular Price  : ₹  4195   /- 😱"
     }
    ]
   },
   {
    "id": 20915,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683363769",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1098",
    "dicos": "78",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "Boult Audio X30 True Wireless in Ear Earbuds with 40H Playtime, Quad Mic ENC, 45ms Xtreme Low Latency, Made in India, Type-C Fast Charging, 3 Equalizer Modes (Rock, BoomX, Hi-Fi), BT 5.1 (Blue)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VSF0gp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VBxcPG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3I7LFO7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nxpPMP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nxpPMP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pg0ck7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lyx66W"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44LvHTs"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NDYlQe"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42suChl"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1098"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71a7tGjL8tL._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Computers & Accessories \n                Accessories & Peripherals \n                PC Gaming Peripherals \nPryce1098 \nDicos78 \n\n❌Regular Price  : ₹   3999  /- 😱"
     }
    ]
   },
   {
    "id": 20916,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683363846",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "849",
    "dicos": "66",
    "catagory": "Health",
    "text_entities": [
     {
      "type": "plain",
      "text": "WAHL WCHS6-1724 Beard Straightening Brush; Advanced Ceramic Tourmaline Technology; Max Heat: 180 ℃; Lightweight & Portable ✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VSFa7v"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pg0wzl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ph8QyQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44C4QJa"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pgW4jN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44rIfz0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44MGzR5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nxZzBO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lw7WWA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B1cTBU"
     },
     {
      "type": "plain",
      "text": "\n\n @849\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51snVVoCDSL._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Health"
     },
     {
      "type": "plain",
      "text": " & Personal Care\n\n                Beauty \n                Hair Care \n                Hair Styling Tools \nPryce849 \nDicos66 \n\n❌Regular Price  : ₹  1999   /- 😱"
     }
    ]
   },
   {
    "id": 20917,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683363928",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1498",
    "dicos": "70",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "boAt Airdopes 413ANC True Wireless in Ear Earbuds with Active Noise Cancellation, 2 Mics ENx Tech, Signature Sound, Touch Gesture, 20 Hours Playback, ASAP Charge & Ambient Mode(Verve Purple)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LyxbHM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LyxbHM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LyxbHM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HGcLeT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AWXOkS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42y3Sft"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HM1hqh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HMsOb4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nxpXfh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HJOzID"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1498"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61xYH01ElDL._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Headphones, Earbuds & Accessories \n                Headphones \nPryce1498 \nDicos70 \n\n❌Regular Price  : ₹  3999    /- 😱"
     }
    ]
   },
   {
    "id": 20918,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683364003",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "939",
    "dicos": "55",
    "catagory": "Tools",
    "text_entities": [
     {
      "type": "plain",
      "text": "Amazon Basics Metal Alphabet And Number Stamp Kit Tools Set With Wood Box - 5/32 Inch✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HG3Lqc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nxqgGX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pi52gw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pgWk2f"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NG8P1l"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3piMZXS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NDYwuS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NDYwuS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NMZrcn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ph8Xug"
     },
     {
      "type": "plain",
      "text": "\n\n @939\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/91OfpbawYSL._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Tools"
     },
     {
      "type": "plain",
      "text": " & Home Improvement\n\n                Toys & Games \n                Learning & Education \n                 Special Needs Developmental Toys \nPryce939 \nDicos55 \n\n❌Regular Price  : ₹   1699  /- 😱"
     }
    ]
   },
   {
    "id": 20919,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683364078",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "34",
    "dicos": "32",
    "catagory": "Grocery",
    "text_entities": [
     {
      "type": "plain",
      "text": "Cadbury Fuse Fit Chocolate Snack Bar with Almonds and Peanuts,40g✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/419eKiD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/419eKiD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41j82a4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41j82a4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nvaL2i"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42aUsXc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42bWoic"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/417kZ6x"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41bCdQ4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42b4FmK"
     },
     {
      "type": "plain",
      "text": "\n\n @34\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61rVoSLgtJL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Grocery"
     },
     {
      "type": "plain",
      "text": " & Gourmet Foods\n\n                Grocery & Gourmet Foods \n                Snacks & Sweets \n                Snack Foods \nPryce34 \nDicos32 \n\n❌Regular Price  : ₹ 50   /- 😱"
     }
    ]
   },
   {
    "id": 20920,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683364160",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "2511",
    "dicos": "54",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Bajaj GX 8 750 DLX 750W Mixer Grinder with 3 Jars, White✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42b4Qyq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/417l3mN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/417l3mN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LBaGC5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LDAkGq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nIgWQp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LvWES0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VCGB9I"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VCGB9I"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VCGB9I"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@2511"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71Qoqbb4cVL._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Home Appliances \n                Small Kitchen Appliances \nPryce2511 \nDicos54 \n\n❌Regular Price  : ₹ 4999   /- 😱"
     }
    ]
   },
   {
    "id": 20921,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683364240",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "136",
    "dicos": "66",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Cello Modustack Glassy Storage Jar, Stackable, Clear, 500ml, Grey✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LFwvQN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42r30co"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p6y5Ue"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42wTCDU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AZC2gd"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42wsODQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VFX3q0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42uxtWV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3BiXtJz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42fF0t1"
     },
     {
      "type": "plain",
      "text": "\n\n @136\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61byXWoGYsL._SX522_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Dining \n                Kitchen Storage & Containers \nPryce136 \nDicos66 \n\n❌Regular Price  : ₹350    /- 😱"
     }
    ]
   },
   {
    "id": 20922,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683364317",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "2889",
    "dicos": "33",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "Qubo Outdoor Security Camera (Black) from Hero Group | Made in India | IP65 All-Weather | 2MP 1080p Full HD | CCTV Wi-Fi Camera | Night Vision | Mobile App Connectivity | Cloud & SD Card Recording✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AWY2bI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HKvbLy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HKvbLy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HKxFJY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HMIdYN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HMt5e6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HIlNYA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HGdbBZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44x14Rx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nAoOnc"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@2889"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/31RK2ukll1L._SY450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Cameras & Photography \n                Security Cameras \nPryce2889 \nDicos33 \n\n❌Regular Price  : ₹  3999  /- 😱"
     }
    ]
   },
   {
    "id": 20923,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683364400",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1099",
    "dicos": "63",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Wonderchef Valencia Non-Stick Cookware 3 Piece Set | Kadhai with Lid, Fry Pan, Dosa Tawa | Induction Friendly | Cool Touch Bakelite Handles | Pure Grade Aluminium| PFOA Free| 2 Years Warranty | Black✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VEWuwv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LZZ6lf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44BvFNW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nFOZIX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pg0PKv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pg0PKv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pg0PKv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pg0PKv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VERzM2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VERzM2"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1099"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/41hjf6kl+bL._SX522_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Dining \n                Cookware \nPryce1099 \nDicos63 \n\n❌Regular Price  : ₹ 2799   /- 😱"
     }
    ]
   },
   {
    "id": 20924,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683364478",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1099",
    "dicos": "63",
    "catagory": "Clothing",
    "text_entities": [
     {
      "type": "plain",
      "text": "Aristocrat Polyester 53 cms Red Travel Duffle✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42NggbF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42NggbF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/415Q3U9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44ApBoH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44CdRSA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41ajFji"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/415zjg1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44LwzYe"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44CWEsr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AZDK19"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1099"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51QWj-DxJHL._UY741_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Clothing"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Fashion \n                Carry ons to flex \n                Duffles \nPryce1099 \nDicos63 \n\n❌Regular Price  : ₹ 1999   /- 😱"
     }
    ]
   },
   {
    "id": 20925,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683364557",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1399",
    "dicos": "77",
    "catagory": "Watches",
    "text_entities": [
     {
      "type": "plain",
      "text": "Maxima New Samurai Smartwatch 1.85\", 1st Time Call Accept Feature, Hindi Language Support, 600Nits Brightness, Longer Battery, in-App GPS,100+ Watch Faces, Multisport Modes,HR/SpO2 (Blue)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nDD7r5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nEgqTB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nDDbHl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B2mdW0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B2mdW0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LYGnGW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LYGnGW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41fvlBo"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41fvlBo"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41fvlBo"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1399"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61me-IcSRyL._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Watches"
     },
     {
      "type": "plain",
      "text": "\n\n                Fashion \n                Onam 2021 \n                Watches and fashion jewellery \nPryce1399 \nDicos77 \n\n❌Regular Price  : ₹ 3899   /- 😱"
     }
    ]
   },
   {
    "id": 20926,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683364633",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "599",
    "dicos": "63",
    "catagory": "Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "Redgear MS-150 Wired Gamepad with 2 Digital triggers, 2 Analog Sticks, Ergonomic Design, 1.8 m Durable Cable, X Input and Direct Input(Blood Red)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/419fft1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NGrFph"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LYFv4W"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LYFv4W"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M2CWz5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NKQP67"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M3w9oQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LYzWDK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pdkP0n"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VBxVAo"
     },
     {
      "type": "plain",
      "text": "\n\n @599\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51z0eLuBPDL._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Computers & Accessories \n                Accessories & Peripherals \n                PC Gaming Peripherals \nPryce599 \nDicos63 \n\n❌Regular Price  : ₹ 1350   /- 😱"
     }
    ]
   },
   {
    "id": 20927,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683364716",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "2512",
    "dicos": "69",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "Cello Novelty Big Plastic 2 Door Cupboard with 3 Shelves (Brown and Beige) ✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/428yGmV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/419Wgi2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/4153BiQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/4153BiQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3phKtAO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/417lz4d"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41bCUc8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pgSLcx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nIhnKx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LCyQfq"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@2512"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71hMwwqIXOL._SX466_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Home & Kitchen \n                Furniture \n                Bedroom Furniture \nPryce2512 \nDicos69 \n\n❌Regular Price  : ₹ 6499   /- 😱"
     }
    ]
   },
   {
    "id": 20928,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683364794",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "242",
    "dicos": "80",
    "catagory": "Clothing",
    "text_entities": [
     {
      "type": "plain",
      "text": "Deniklo Men T-Shirt✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LFpY8R"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42y4xxt"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42vRr3O"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p9976F"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/421SPLz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/429WSFL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pdYHTo"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HG8PKT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HG8PKT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44BNRXo"
     },
     {
      "type": "plain",
      "text": "\n\n @242\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71MYABh0gNL._UY500_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Clothing"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Fashion \n                The Plus Size Store \n                aug art- clothing ingress \nPryce242 \nDicos80 \n\n❌Regular Price  : ₹ 1050   /- 😱"
     }
    ]
   },
   {
    "id": 20929,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683364869",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "48",
    "dicos": "88",
    "catagory": "Sports",
    "text_entities": [
     {
      "type": "plain",
      "text": "Simran Sports PVC Hand Grip Pack of 1 (A51)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pgWPJF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HJPhph"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44Dngt5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NIVJ3q"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VFuCbm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42wtw3Y"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nrYRGs"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VApIww"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VBnHQK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VFfStp"
     },
     {
      "type": "plain",
      "text": "\n\n @48\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/41DquWoJcML.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Sports"
     },
     {
      "type": "plain",
      "text": ", Fitness & Outdoors\n\n                Sports, Fitness & Outdoors \n                Exercise & Fitness \n                Strength Training Equipment \nPryce48 \nDicos88 \n\n❌Regular Price  : ₹  210  /- 😱"
     }
    ]
   },
   {
    "id": 20930,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683364943",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1399",
    "dicos": "72",
    "catagory": "Video",
    "text_entities": [
     {
      "type": "plain",
      "text": "Ant Esports Champions Bundle X – 3 in 1, Gaming RGB Mouse + Gaming RGB Headset + Gaming Mouse pad – Black✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NN0oBt"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44yvLpg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/417lE81"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41gA9Gx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/419fB2P"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44yvNxo"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44x1QxV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41fvKUq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44BwCpu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AZEbsj"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1399"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/81c-axMwbyL._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Video"
     },
     {
      "type": "plain",
      "text": " Games\n\n                Computers & Accessories \n                Accessories & Peripherals \n                PC Gaming Peripherals \nPryce1399 \nDicos72 \n\n❌Regular Price  : ₹3899   /- 😱"
     }
    ]
   },
   {
    "id": 20931,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683365015",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "209",
    "dicos": "88",
    "catagory": "Clothing",
    "text_entities": [
     {
      "type": "plain",
      "text": "Imfashini Women's Regular Blouse✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44C64UM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LGQZIY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HMB3nA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LGRN0E"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LBHr1T"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p4hBfl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42z7WvX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p4hC2T"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42vfrUx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LC2wcM"
     },
     {
      "type": "plain",
      "text": "\n\n @209\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61MS2jNWUEL._UX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Clothing"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Clothing & Accessories \n                Women \n                Western Wear \nPryce209 \nDicos88 \n\n❌Regular Price  : ₹ 1199   /- 😱"
     }
    ]
   },
   {
    "id": 20932,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683365094",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1999",
    "dicos": "76",
    "catagory": "Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "Boult Dive+ with 1.85\" HD Display, Bluetooth Calling Smartwatch, 500 Nits Brightness, 7 Days Battery Life, 150+ Watch Faces, 100+ Sport Modes, IP68 Waterproof Smart Watch (Jet Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nDvjFS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M1NnCO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LYkHL0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pdSM0J"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LYcRRB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44BwN48"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44BwOoI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M05tF9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M2Dx3N"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pha1yg"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1999"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71cJhcUbjBL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Electronics \n                Wearable Technology \n                 Smart Watches \nPryce1999 \nDicos76 \n\n❌Regular Price  : ₹ 6499   /- 😱"
     }
    ]
   },
   {
    "id": 20933,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683365172",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "203",
    "dicos": "75",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Kolorr Stitch Pedal Waste Bin Modern Design Trash Can Plastic Dustbin - 7L (Daiso Grey)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LZZL6d"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NJYfXp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LYHdDA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VD62bi"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VD62bi"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VD62bi"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VD62bi"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42dzdUQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3O1SnsL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NJNmEJ"
     },
     {
      "type": "plain",
      "text": "\n\n @203\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71RTha4fptL._SY741_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Home Storage & Organisation \n                Waste & Recycling \nPryce203 \nDicos75 \n\n❌Regular Price  : ₹  650  /- 😱"
     }
    ]
   },
   {
    "id": 20934,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683365253",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "999",
    "dicos": "70",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Pigeon Basics Non Induction Base Nons tick Aluminium Cookware set, including Nonstick Dosa Tawa, Nonstick Kadai With Glass Lid, and Nonstick Frying Pan, (Pink)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NJNnbL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pi6i3e"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LvXNcg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/419DJ5A"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/419DJ5A"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42aVORM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41bH7N2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41dQdZP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LI0yr8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42b9ZGz"
     },
     {
      "type": "plain",
      "text": "\n\n @999\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61JlllBcGpL._SX522_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Dining \n                Cookware \nPryce999 \nDicos70 \n\n❌Regular Price  : ₹  2499  /- 😱"
     }
    ]
   },
   {
    "id": 20935,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683365335",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1399",
    "dicos": "50",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Cello Prima Induction Base Non-Stick Aluminium Pan Cookware Set, 3-Pieces, Cherry Red✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LG4k4e"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B0vX36"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B0DxLd"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B0DxLd"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VEQQdz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VCHGyi"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VCHGyi"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VCHGyi"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3BiYCAR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HM2Wfv"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1399"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/7169jlr-0mL._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Dining \n                Cookware \nPryce1399 \nDicos50 \n\n❌Regular Price  : ₹  2350  /- 😱"
     }
    ]
   },
   {
    "id": 20936,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683365414",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "798",
    "dicos": "68",
    "catagory": "Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "TP-Link USB WiFi Dongle 300Mbps High Gain Wireless Network Wi-Fi Adapter for PC Desktop and Laptops. Supports Win10/8.1/8/7/XP, Linux 2.6.24-4.9.60, Mac OS 10.9-10.15 (TL-WN822N)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pdaH7G"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42cbeFs"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/428yOCZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HJdwUm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HJdwUm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42q1FmJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M0fzWy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44BEzLi"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44BEzLi"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Mb6PNL"
     },
     {
      "type": "plain",
      "text": "\n\n @798\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61qxYy+hF7L._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Computers & Accessories \n                Networking Devices \n                Network Adapters \nPryce798 \nDicos68 \n\n❌Regular Price  : ₹ 1999   /- 😱"
     }
    ]
   },
   {
    "id": 20937,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683365494",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "209",
    "dicos": "90",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Status Polypropylene Door Mat (Pack of 2) - 58 cm x 38 cm (Purple Grey)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LWSILD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VSGUxz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VCF03H"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nuGjFx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B1ekAi"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41dQn3n"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44rKjqK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LDzBF8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3phLbxY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HKDozi"
     },
     {
      "type": "plain",
      "text": "\n\n @209\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/91DISQ4e1xL._SX522_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Home Furnishing \n                Carpets & Rugs \nPryce209 \nDicos90 \n\n❌Regular Price  : ₹ 999   /- 😱"
     }
    ]
   },
   {
    "id": 20938,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683365575",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "4498",
    "dicos": "39",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Bajaj PMH 25 DLX 24L Personal Air Cooler for home with DuraMarine Pump (2-Yr Warranty by Bajaj) Anti-Bacterial Hexacool Master, TurboFan Technology, 3-SpeedControl, Portable AC, White Cooler for Room✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nDEhmr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B2nvAk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M0NT3Z"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44LxTua"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41azGWz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HG5yLW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NFDPid"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NFDPid"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NFDPid"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ntocQh"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@4498"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61o4Iv+vdBL._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Heating, Cooling & Air Quality \n                Air Coolers \nPryce4498 \nDicos39 \n\n❌Regular Price  : ₹  6999  /- 😱"
     }
    ]
   },
   {
    "id": 20939,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683365628",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "569",
    "dicos": "80",
    "catagory": "Clothing",
    "text_entities": [
     {
      "type": "plain",
      "text": "AEROPOSTALE Women's Jeggings✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NFgRYs"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VAlg0J"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NK6z9n"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M2E59R"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M14gxp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NJnhFO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VKL6z9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3O1SLYf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M1F7ms"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41fwIA2"
     },
     {
      "type": "plain",
      "text": "\n\n @569\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71Mo5+G9B5L._UX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Clothing"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Clothing & Accessories \n                Women \n                Western Wear \nPryce569 \nDicos80 \n\n❌Regular Price  : ₹  1699  /- 😱"
     }
    ]
   },
   {
    "id": 20940,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683365704",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "609",
    "dicos": "80",
    "catagory": "Clothing",
    "text_entities": [
     {
      "type": "plain",
      "text": "AEROPOSTALE Women's Skinny Jeans✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/421TBYZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pkG7ZG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NFEi3X"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/421TFId"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42q1KXz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42q1QOV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VEY01F"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NNoagG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42bGioL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/417mv8J"
     },
     {
      "type": "plain",
      "text": "\n\n @609\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61-q8BadhzL._UX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Clothing"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Clothing & Accessories \n                Women \n                Western Wear \nPryce609 \nDicos80 \n\n❌Regular Price  : ₹ 1999   /- 😱"
     }
    ]
   },
   {
    "id": 20941,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683365780",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "248",
    "dicos": "53",
    "catagory": "Grocery",
    "text_entities": [
     {
      "type": "plain",
      "text": "Kwality Muesli (MIXED FRUIT, 700g [Pack of 1])✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VFgBuD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VzEZ0F"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nIiuKd"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nIAvYO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LHLjyi"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VAlrsV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VK2kwI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pcVV0S"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44CfaAY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AZJGY1"
     },
     {
      "type": "plain",
      "text": "\n\n @248\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/81U1jsBoBkL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Grocery"
     },
     {
      "type": "plain",
      "text": " & Gourmet Foods\n\n                Grocery & Gourmet Foods \n                Cereal & Muesli \n                Muesli & Granola Cereals \nPryce248 \nDicos53 \n\n❌Regular Price  : ₹ 499   /- 😱"
     }
    ]
   },
   {
    "id": 20942,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683365943",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "10098",
    "dicos": "40",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "duroflex Livein Orthopedic - Pressure Relieving, Memory Foam, Roll Pack, 8 Inch Single Size Medium Firm Mattress with Superior Comfort and Anti Microbial Fabric (78x36x8 Inches)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HM3m5z"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HIK1Cd"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42dA2Nq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HInHII"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HMuPUG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42hbW4A"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VSHnQl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M134Kw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VBzqi0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VBzqi0"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@10098"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61t-D56mn1L._SX522_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Home & Kitchen \n                Furniture \n                Bedroom Furniture \nPryce10098 \nDicos40 \n\n❌Regular Price  : ₹ 15499   /- 😱"
     }
    ]
   },
   {
    "id": 20943,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683366025",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "189",
    "dicos": "81",
    "catagory": "Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "Ant Esports MP290 Gaming Mouse Pad-L- Large with Stitched Edges, Waterproof Non-Slip Base for Gaming & Office – Black✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M2uCyY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VEYlBt"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NKSj0b"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NKSj0b"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NKSj0b"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VAr8qQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NK6SB3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pdmvqH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pdTLhr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NJZllZ"
     },
     {
      "type": "plain",
      "text": "\n\n @189\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/91WvhurSovL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Computers & Accessories \n                Accessories & Peripherals \n                Keyboards, Mice & Input Devices \nPryce189 \nDicos81 \n\n❌Regular Price  : ₹ 599   /- 😱"
     }
    ]
   },
   {
    "id": 20944,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683366083",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "117",
    "dicos": "67",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Philips Deco Mini Base B22 0.5-Watt LED Bulb (Pack of 3, Red)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44Af0Kj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41buWQx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41gBqgN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nAXIw5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pdTQSh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pjqJwH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41qumP7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B56Hso"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B56Hso"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LD0MQn"
     },
     {
      "type": "plain",
      "text": "\n\n @117\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51cZe6Ia2yL._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Indoor Lighting \n                Light Bulbs \nPryce117 \nDicos67 \n\n❌Regular Price  : ₹299    /- 😱"
     }
    ]
   },
   {
    "id": 20945,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683366139",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "126",
    "dicos": "65",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "PHILIPS Deco Mini Base B22 0.5-Watt LED Bulb (Yellow) - Pack of 3✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44yWouk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41f86HA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41alkFy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LFyJQ9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LBIJtL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HKJQ9g"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LLJsZI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LLJsZI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ny1N48"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LH43yb"
     },
     {
      "type": "plain",
      "text": "\n\n @126\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/416yu4BPgiL._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Indoor Lighting \n                Light Bulbs \nPryce126 \nDicos65 \n\n❌Regular Price  : ₹ 299   /- 😱"
     }
    ]
   },
   {
    "id": 20946,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683366219",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "399",
    "dicos": "76",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Pigeon by Stovekraft Kitchen Tools & Cutting Board Combo (400ml Chopper & Storage, 3 Knives Set, 1 Kitchen Scissor, 1 Chopping Board)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42pc7tW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42pc7tW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nxCyix"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HJM9JU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HMIUBr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M0zqoE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41cCt1B"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/419gSqD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nCgGm6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nCgHXc"
     },
     {
      "type": "plain",
      "text": "\n\n @399\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71o0D2CybaL._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Dining \n                Kitchen Tools \nPryce399 \nDicos76 \n\n❌Regular Price  : ₹ 999   /- 😱"
     }
    ]
   },
   {
    "id": 20947,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683366276",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "119",
    "dicos": "85",
    "catagory": "Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "GIZGA essentials (35cm x 25cm Extended Gaming Mouse Pad, Laptop Desk Mat, Computer Mouse Pad with Smooth Mouse Control, Mercerized Surface, Antifray Stitched Embroidery Edges, Anti-Slip Rubber Base✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M1OFxE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LYegrl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VETYGy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VD7bj6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VD7bj6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NKwDS2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NFhBNe"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M3xJag"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NIBdQw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NIBdQw"
     },
     {
      "type": "plain",
      "text": "\n\n @119\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51gxDXYYdfL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Computers & Accessories \n                Accessories & Peripherals \n                Keyboards, Mice & Input Devices \nPryce119 \nDicos85 \n\n❌Regular Price  : ₹ 499   /- 😱"
     }
    ]
   },
   {
    "id": 20948,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683366355",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1106",
    "dicos": "47",
    "catagory": "Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "Live Tech PS13 Power Strip with Qualcomm USB 3.0 | Surge Protector Power Strip | 2500W 10A | 2m Extension Cord | 4 Universal Socket 4 USB Type A for iPad iPhone Galaxy Android Tablets & More✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3phbd4I"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3phbd4I"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41beyiP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ntoZRf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42aNYI4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42bYgHP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3O1TA3h"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/415B9NX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pihAV7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/428zFUd"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1106"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51gShmrVbdS._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Electronics \n                Power Accessories \n                 Surge Protectors \nPryce1106 \nDicos47 \n\n❌Regular Price  : ₹1999    /- 😱"
     }
    ]
   },
   {
    "id": 20949,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683366412",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "105",
    "dicos": "50",
    "catagory": "Beauty",
    "text_entities": [
     {
      "type": "plain",
      "text": "Vatika Ayurvedic Shampoo for Hair Fall Control & Damage free Strong and Shiny hair- 340ml✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/415s9Z6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LLJGQy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LC47iM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LI224G"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LH4b0D"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LFz6u1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42Ni84b"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42ttNoj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42ttNoj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42ttNoj"
     },
     {
      "type": "plain",
      "text": "\n\n @105\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61dQ1bHPHIL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Beauty"
     },
     {
      "type": "plain",
      "text": "\n\n                Beauty \n                Hair Care \n                Shampoo & Conditioner \nPryce105 \nDicos50 \n\n❌Regular Price  : ₹ 199   /- 😱"
     }
    ]
   },
   {
    "id": 20950,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683366472",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "2997",
    "dicos": "50",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "ZEBRONICS Zeb-Twist 5.1 Multimedia Speaker with Bluetooth Supporting,USB,AUX,Built-in FM and Remote Control(Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pa8n1b"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pa8n1b"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ny1WEI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ny1WEI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42n2wEk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42hcesc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nDF1bd"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nyKbVQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nyKbVQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/428AT1H"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@2997"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/81h777RrPEL._SY450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Home Audio \n                Speakers \nPryce2997 \nDicos50 \n\n❌Regular Price  : ₹  4999  /- 😱"
     }
    ]
   },
   {
    "id": 20951,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683366532",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "209",
    "dicos": "50",
    "catagory": "Grocery",
    "text_entities": [
     {
      "type": "plain",
      "text": "Hershey's Kisses Milk Choclates Almond, 100g (Pack of 3)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/428zUP7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HLcsj3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HLasqL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HJTzNh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44Arz8z"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VFhp2D"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M2EFV5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VzFHLn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VK3eJC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44tmMWB"
     },
     {
      "type": "plain",
      "text": "\n\n @209\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51wyVsgC9jL._SX569_PIbundle-3,TopRight,0,0_AA569SH20_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Grocery"
     },
     {
      "type": "plain",
      "text": " & Gourmet Foods\n\n                Grocery & Gourmet Foods \n                Snacks & Sweets \n                Chocolate \nPryce209 \nDicos50 \n\n❌Regular Price  : ₹ 399   /- 😱"
     }
    ]
   },
   {
    "id": 20952,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683366585",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "249",
    "dicos": "75",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "ALTRA QUARTZ Analog Dark Brown Clock - Peacock✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pdmWkP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44x9acS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LCB7an"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LGSuH6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M3jdiJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M01cBD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LH975L"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M0Ojap"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42vTGUM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NL8J8W"
     },
     {
      "type": "plain",
      "text": "\n\n @249\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/41Xye72J03L._SX300_SY300_QL70_FMwebp_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Home & Décor \n                Clocks \nPryce249 \nDicos75 \n \n❌Regular Price  : ₹ 850   /- 😱"
     }
    ]
   },
   {
    "id": 20953,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683366641",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1501",
    "dicos": "50",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Pigeon by Stovekraft Titanium Induction Base Inner Lid Hard Anodised Aluminium Pressure Cooker, 5 litres, Black, Standard (12422)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NGtMtd"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NGtMtd"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44xhWaL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44xhWaL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NN2fpV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VKM4vh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VAwwKD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44BQsAC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44D47rw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44ANeNP"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1501"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/411FIPpBwtS._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Dining \n                Cookware \nPryce1501 \nDicos50 \n\n❌Regular Price  : ₹  2499  /- 😱"
     }
    ]
   },
   {
    "id": 20954,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683366695",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "886",
    "dicos": "51",
    "catagory": "Clothing",
    "text_entities": [
     {
      "type": "plain",
      "text": "Sugr Girl Girl's Polyester Skater Knee-Length Casual Dress (GJOGDRS20005D06_Mint_11Y)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/415seMo"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nFRB9J"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41cCQcv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B0Fbwn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pi7sM8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ny2eeM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/415SdTL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B6yvwx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nDFev1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42wS1Or"
     },
     {
      "type": "plain",
      "text": "\n\n @886\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/7174pDcWA+L._UX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Clothing"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Clothing & Accessories \n                Girls \n                 Dresses & Jumpsuits \nPryce886 \nDicos51 \n\n❌Regular Price  : ₹ 1650   /- 😱"
     }
    ]
   },
   {
    "id": 20955,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683366749",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "2499",
    "dicos": "55",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Bajaj Energos 12DC5R 1200mm Duck White Ceiling Fan✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42wWtx0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LGSHdm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HG6tvS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LYBBJu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HG6xvC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42wvtxk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HM4vtT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M1OXEK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HKyFh6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42poy9c"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@2499"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51PrSr1NmPL._SX466_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Heating, Cooling & Air Quality \n                Fans \nPryce2499 \nDicos55 \n\n❌Regular Price  : ₹4499  /- 😱"
     }
    ]
   },
   {
    "id": 20956,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683366806",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "2511",
    "dicos": "54",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Bajaj GX 8 750 DLX 750W Mixer Grinder with 3 Jars, White✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M151Xh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44CZ5v5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HGg4CP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44Dp4Cn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44Dp4Cn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HMJE9H"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HIoNUQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HIoNUQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NGtRNx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44yX82A"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@2511"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71Qoqbb4cVL._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Home Appliances \n                Small Kitchen Appliances \nPryce2511 \nDicos54 \n\n❌Regular Price  : ₹ 4799   /- 😱"
     }
    ]
   },
   {
    "id": 20957,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683366860",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "329",
    "dicos": "81",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "pTron Volta FC12 20W QC3.0 Smart USB Charger, Made in India, Auto-detect Technology, Multi-Layer Protection, Fast Charging Power Adaptor Without Cable for All Android & iOS Devices (Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M07u4b"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NKSVTx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NNoVX4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LYeNtl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pcX2h4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M15bhl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NMboze"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M1P8Qq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VFhKlV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VFhKlV"
     },
     {
      "type": "plain",
      "text": "\n\n @329\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/41eQgR-O-5L._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Mobiles & Accessories \n                Mobile Accessories \nPryce329 \nDicos81 \n\n❌Regular Price  : ₹ 1250   /- 😱"
     }
    ]
   },
   {
    "id": 20958,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683366915",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "6071",
    "dicos": "62",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "Samsung Galaxy Buds Live Bluetooth Truly Wireless in Ear Earbuds with Mic, Onyx✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M0PeI3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M1GeTa"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NN2vFp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42aOxBG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/4284Qzh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NGu8Qz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pii0uF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NK7BCh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NK3LZN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NK086r"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@6071"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/615xn0oFzuL._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Headphones, Earbuds & Accessories \n                Headphones \nPryce6071 \nDicos62 \n\n❌Regular Price  : ₹  12499  /- 😱"
     }
    ]
   },
   {
    "id": 20959,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683366974",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "499",
    "dicos": "75",
    "catagory": "Tools",
    "text_entities": [
     {
      "type": "plain",
      "text": "Lifelong LLBAHF09 ABS Health Faucet with SS-304 Grade 1.25 Meter Flexible Hose Pipe and Wall Hook, White (Jet Spray for Toilet)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NN2Ojx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42aOHZO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/419FcZE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/419FcZE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41aB4bJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LCBx0w"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42fHGXB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LBdtLz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/419FhMW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42bZd2M"
     },
     {
      "type": "plain",
      "text": "\n\n @499\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61+3mLM6VmL._SX466_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Tools"
     },
     {
      "type": "plain",
      "text": " & Home Improvement\n\n                Home & Kitchen \n                Kitchen & dining \n                Steal deals \nPryce499 \nDicos75 \n\n❌Regular Price  : ₹ 1499   /- 😱"
     }
    ]
   },
   {
    "id": 20960,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683367030",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "549",
    "dicos": "63",
    "catagory": "Tools",
    "text_entities": [
     {
      "type": "plain",
      "text": "Lifelong ABS Health Faucet with SS|1 Meter Flexible Hose Pipe and Wall Hook|Jet Spray for Toilet (LLBAHFB01, Black, 1 Year Warranty)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/421VkgV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pjrK7Z"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p6AX3s"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p6AX3s"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LBJxyN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LH9ptn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AXDShN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AY9La3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VBTl0e"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42rQuJC"
     },
     {
      "type": "plain",
      "text": "\n\n @549\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61ljAYE4t3L._SX466_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Tools"
     },
     {
      "type": "plain",
      "text": " & Home Improvement\n\n                Home Improvement \n                Kitchen & Bath Fixtures \n                Bathroom Fixtures \nPryce549 \nDicos63 \n\n❌Regular Price  : ₹  1250  /- 😱"
     }
    ]
   },
   {
    "id": 20961,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683367088",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "284",
    "dicos": "72",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "PANCA Stainless Steel Sauce Pan 2 Litre Large Stainless Steel Plain Milk Pan/Sauce Pan/Tea Pan/Flat Base Tapeli Patila Sauce Pot Milk Boiler Cookware Set with Handle✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42rJUmr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B0OHzG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B0UiGg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B0UiGg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Bj0oSx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AX15AG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B6yGYJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42wW8tW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42bHpEX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B6yM2x"
     },
     {
      "type": "plain",
      "text": "\n\n @284\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51pOilmPr5L._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Dining \n                Cookware \nPryce284 \nDicos72 \n\n❌Regular Price  : ₹850    /- 😱"
     }
    ]
   },
   {
    "id": 20962,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683367160",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "499",
    "dicos": "58",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Cello Diamond Plastic Kitchen Storage Containers with Air Tight Lids, Clear, 18Pcs (Capacity - 6-300ml,6-650ml,6-1200ml)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3I7OX3V"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42bOuFN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HGaP5R"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/428Bpg9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/429YWO1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/4285AEz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VFZKI8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VFwCQU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/4296kJt"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M3yqQU"
     },
     {
      "type": "plain",
      "text": "\n\n @499\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71IDj6g4XqL._SX522_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Dining \n                Kitchen Storage & Containers \nPryce499 \nDicos58 \n\n❌Regular Price  : ₹ 1050   /- 😱"
     }
    ]
   },
   {
    "id": 20963,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683367207",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "115",
    "dicos": "54",
    "catagory": "Grocery",
    "text_entities": [
     {
      "type": "plain",
      "text": "Amazon Brand - Solimo 100% Pure Coconut Oil, 600 ml✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VBsT74"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pg40lp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HIp6Pu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HGaZdt"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M0hbQ6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42NiRCr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44BGe3u"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42y6KsL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44ANso9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LYHTsq"
     },
     {
      "type": "plain",
      "text": "\n\n @115\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71AoXFzolcL._SY606_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Grocery"
     },
     {
      "type": "plain",
      "text": " & Gourmet Foods\n\n                Beauty \n                Hair Care \n                 Hair Oils \nPryce115 \nDicos54 \n\n❌Regular Price  : ₹ 210   /- 😱"
     }
    ]
   },
   {
    "id": 20964,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683367256",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "584",
    "dicos": "58",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Bajaj DX-6 1000W Dry Iron with Advance Soleplate and Anti-bacterial German Coating Technology, White✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42tQ59x"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42vhrfC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p5tAJC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nDwh4I"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LGU9g0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LD1HjN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44xizkD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pefVjE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pcXrQC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44tnoLT"
     },
     {
      "type": "plain",
      "text": "\n\n @584\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/31ke2NdHJ-L._SY300_SX300_QL70_FMwebp_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Home Appliances \n                Vacuum, Cleaning & Ironing \nPryce584 \nDicos58 \n\n❌Regular Price  : ₹  1199  /- 😱"
     }
    ]
   },
   {
    "id": 20965,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683367304",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "599",
    "dicos": "73",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "Ambrane Bassband Pro Bluetooth Wireless in Ear Earphones with Mic (Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44ByNJG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44ByNJG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44LzWhQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44Asw0D"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44DprwL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ny2AC8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/419Fn7g"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41bvZQt"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B0FC9Z"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B0xTbS"
     },
     {
      "type": "plain",
      "text": "\n\n @599\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61DH45WSXML._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Headphones, Earbuds & Accessories \n                Headphones \nPryce599 \nDicos73 \n\n❌Regular Price  : ₹ 1699   /- 😱"
     }
    ]
   },
   {
    "id": 20966,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683367353",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "639",
    "dicos": "65",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Lifelong Hard Anodized Kadai 3 Litre, Induction and Gas Compatible, Black (with Glass Lid) (Kadhai)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B3cYoJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42vUd9e"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42rznaS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42sy9w7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HMiO1k"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nxDvY9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42r6P1e"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HLbcMz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nxDIKV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LWUyMx"
     },
     {
      "type": "plain",
      "text": "\n\n @639\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61m2ZGWy1QL._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Dining \n                Cookware \nPryce639 \nDicos65 \n\n❌Regular Price  : ₹ 1499   /- 😱"
     }
    ]
   },
   {
    "id": 20967,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683367401",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "659",
    "dicos": "51",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Pigeon by Stovekraft Amaze Plus Electric Kettle (14313) with Stainless Steel Body, 1.8 litre, used for boiling Water, making tea and coffee, instant noodles, soup etc. 1500 Watt (Silver)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p9bLcB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44y3gIt"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/415SN3T"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41bfunl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44BRaOi"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HG7jZy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44wnAd7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M3yHmU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41bJ6ks"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3phMUDs"
     },
     {
      "type": "plain",
      "text": "\n\n @659\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51DGcy8eBCL._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Home Appliances \n                Small Kitchen Appliances \nPryce659 \nDicos51 \n\n❌Regular Price  : ₹ 1199   /- 😱"
     }
    ]
   },
   {
    "id": 20968,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683367446",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "267",
    "dicos": "73",
    "catagory": "Clothing",
    "text_entities": [
     {
      "type": "plain",
      "text": "Puma Girl's Regular T-Shirt✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LYCsKc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44BzdQg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44tnJ17"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NE2qUy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M0hwCm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NPx3qh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NJp1Pm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3phcdWw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NGcvQH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LYCEco"
     },
     {
      "type": "plain",
      "text": "\n\n @267\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/41VGBIyyCGL._UX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Clothing"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Fashion \n                Apparel Steal Deals \n                Apparel_1 \nPryce267 \nDicos73 \n\n❌Regular Price  : ₹ 850   /- 😱"
     }
    ]
   },
   {
    "id": 20969,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683367491",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "384",
    "dicos": "73",
    "catagory": "Shoes",
    "text_entities": [
     {
      "type": "plain",
      "text": "Puma Men's Roger DP Rubber Hawaii Thong Sandals✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NKy5ns"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NK83jX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LYfy5F"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NKTywn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VAxdUf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NPxdxT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NFG5WJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42aP72k"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NKyd6q"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pi8szS"
     },
     {
      "type": "plain",
      "text": "\n\n @384\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/81u9OlCbWsL._UX500_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Shoes"
     },
     {
      "type": "plain",
      "text": " & Handbags\n\n                Shoes & Handbags \n                Shoes \n                Men's Shoes \nPryce384 \nDicos73 \n\n❌Regular Price  : ₹ 1199   /- 😱"
     }
    ]
   },
   {
    "id": 20970,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683367561",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "2280",
    "dicos": "50",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Orient Electric Laurel BEE Star Rated Ceiling Fan for Home | High Speed | Designer | Rust Proof Blades | Smooth Motor Operation (Metallic Bronze, Pack of 1)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pdcObC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42aXR8q"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nFSwqH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nFSwqH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/419Isnx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LCBZvK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LLKKE2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LC59LG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LCC2rl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LLKFQK"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@2280"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/41+lzhouvBL._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Heating, Cooling & Air Quality \n                Fans \nPryce2280 \nDicos50 \n\n❌Regular Price  : ₹ 4099   /- 😱"
     }
    ]
   },
   {
    "id": 20971,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683367630",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1902",
    "dicos": "59",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Inalsa Steam Iron Onyx 2200 Watt, Quick Heat Up with up to 30 g/min steam, 100 g steam Boost, Water Spray, Scratch Resistant Ceramic Soleplate, Vertical steam, Anti Drip & Anti Calc Functions.✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LDBCRI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pe1ImG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AYix7O"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B0FTK3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AXUPZc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B0XZvu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ny2RoE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AXULsq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pa9xd3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AZLhwZ"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1902"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61SEItZIbQL._SX466_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Home Appliances \n                Vacuum, Cleaning & Ironing \nPryce1902 \nDicos59 \n\n❌Regular Price  : ₹ 3999   /- 😱"
     }
    ]
   },
   {
    "id": 20972,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683367698",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "98",
    "dicos": "78",
    "catagory": "Sports",
    "text_entities": [
     {
      "type": "plain",
      "text": "Cockatoo CS-01 Shaker Bottle (Black, 700 Milliliters, Plastic)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nyCcIj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nBjM9W"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HOOiE7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3phcAAo"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nDG0Ir"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42bZKln"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42aYajA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3I7Px1B"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HJUAVB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HMwCJo"
     },
     {
      "type": "plain",
      "text": "\n\n @98\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51OZcrtHduL._SY741_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Sports"
     },
     {
      "type": "plain",
      "text": ", Fitness & Outdoors\n\n                Home & Kitchen \n                Best selling products \n                Home & Kitchen | Unrec \nPryce98 \nDicos78 \n\n❌Regular Price  : ₹ 350   /- 😱"
     }
    ]
   },
   {
    "id": 20973,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683367766",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "2079",
    "dicos": "60",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Pigeon by Stovekraft 2 Burner Glass Cook Top Gas Stove (Manual Ignition), Tawa with Stainless Steel Body and Nonstick Fry Pan Cookware Combo (Black, 240mm, 250mm, 14722)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nBjUWY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M0vlkp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M0vlkp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44DpE31"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44BGI9O"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M0PQxl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LGUzD6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44BGJdS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LBKpDz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44y3uPP"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@2079"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61wrnEQPveL._SX466_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Dining \n                Cookware \nPryce2079 \nDicos60 \n\n❌Regular Price  : ₹4499    /- 😱"
     }
    ]
   },
   {
    "id": 20974,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683367816",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "2099",
    "dicos": "58",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Lifelong LLHBZ05 Infinia Plus 400W Hand Blender Machine with Whisker, Chopping Attachment & Multipurpose Jar|Stainless Steel Blade & Detachable Shaft|Soups, Smoothies, Sauces (1 Year Warranty, Silver)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NESfz3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NFjlGg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42syBdN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NFjsla"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NN3qFR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p6BWRc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pcXWKu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44D052j"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ns1PuA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44xjuS7"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@2099"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51qdBiZU2dL._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Home Appliances \n                Small Kitchen Appliances \nPryce2099 \nDicos58 \n\n❌Regular Price  : ₹  4499  /- 😱"
     }
    ]
   },
   {
    "id": 20975,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683367842",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": 466,
    "dicos": 466,
    "catagory": "",
    "text_entities": [
     {
      "type": "plain",
      "text": "INALSA Vacuum Cleaner Gusto Pro-1200W with Powerful Blower Function and 1.5L Reusable Cloth Dust Bag, Powerful 16KPA Suction, Lightweight & Compact, (Red/Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nuIEQP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44D0iCD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41bFBKM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pi8HLi"
     },
     {
      "type": "plain",
      "text": "\n\n❌Regular Price  : ₹   4999 /- 😱"
     }
    ]
   },
   {
    "id": 20976,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683367890",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "98",
    "dicos": "64",
    "catagory": "Grocery",
    "text_entities": [
     {
      "type": "plain",
      "text": "Keya Fusilli Pasta 1kg, 100% Durum Wheat Pasta | Vegetarian | No MSG | Low in Calories | No Trans Fats | Healthy | Cooked in 10 Minutes✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nyS4KS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AWlLsB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B2pvbO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LFApsV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B0G7Rp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AZLsbD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LyAzT0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HKhWu8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HM5wSJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42wXncS"
     },
     {
      "type": "plain",
      "text": "\n\n @98\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61DDttvs0VL._SX466_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Grocery"
     },
     {
      "type": "plain",
      "text": " & Gourmet Foods\n\n                Grocery & Gourmet Foods \n                Pasta & Noodles \n                Pasta \nPryce98 \nDicos64 \n\n❌Regular Price  : ₹ 199   /- 😱"
     }
    ]
   },
   {
    "id": 20977,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683367938",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "2499",
    "dicos": "57",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Pigeon by Stovekraft Hard Anodised Aluminium Pressure Cooker Combo - 2 L , 3 L and 5 L Induction Base Outer Lid Cooker (14414, Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HKLAiO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HMx1eS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42r7ks8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HKLE22"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HJgbxk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M3z6Ws"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nCitrk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42y7luv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nAs2qO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42syIWL"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@2499"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51jCZU4McCL._SX522_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Dining \n                Cookware \nPryce2499 \nDicos57 \n\n❌Regular Price  : ₹ 4499   /- 😱"
     }
    ]
   },
   {
    "id": 20978,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683367986",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "2280",
    "dicos": "50",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Orient Electric Laurel BEE Star Rated Ceiling Fan for Home | High Speed | Designer | Rust Proof Blades | Smooth Motor Operation (Metallic Bronze, Pack of 1) ✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/419iocj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41jbuBy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41gDDsB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M1Q7A6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/419Zd26"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pg50WH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M1H4iM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41aBP4z"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NESqud"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NI1X3w"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@2280"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/41+lzhouvBL._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Heating, Cooling & Air Quality \n                Fans \nPryce2280 \nDicos50 \n\n❌Regular Price  : ₹3999   /- 😱"
     }
    ]
   },
   {
    "id": 20979,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683368035",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "2511",
    "dicos": "54",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Bajaj GX 8 750 DLX 750W Mixer Grinder with 3 Jars, White✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M3kqGN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Mb9neN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nxu5fh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M3kshT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NI21Ai"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NJpzEU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NJpykk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NSaBwm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NMczi8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NJQaBL"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@2511"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71Qoqbb4cVL._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Home Appliances \n                Small Kitchen Appliances \nPryce2511 \nDicos54 \n\n❌Regular Price  : ₹ 4499   /- 😱"
     }
    ]
   },
   {
    "id": 20980,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683368081",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "319",
    "dicos": "55",
    "catagory": "Tools",
    "text_entities": [
     {
      "type": "plain",
      "text": "Benelave By Hindware BLACP50218 ABS Rain Flow 100 mm Hand Shower for Bathroom✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M1Qrii"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/417oGcp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/415TpXf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M1FdKP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ns23lq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NE30BI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LYJ0IC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VCMtji"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41dSHaB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VF0ihb"
     },
     {
      "type": "plain",
      "text": "\n\n @319\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51jfu3XxigL._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Tools"
     },
     {
      "type": "plain",
      "text": " & Home Improvement\n\n                Home Improvement \n                Kitchen & Bath Fixtures \n                Bathroom Fixtures \nPryce319 \nDicos55 \n\n❌Regular Price  : ₹ 650  /- 😱"
     }
    ]
   },
   {
    "id": 20981,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683368126",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "439",
    "dicos": "67",
    "catagory": "Tools",
    "text_entities": [
     {
      "type": "plain",
      "text": "Benelave by Hindware BLACP50320 Health Faucet Jet Spray for Bathroom with 1-Meter Long Stainless Steel Tube & Wall Hook, Round Button, Made of ABS✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/419ZnGK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VAsNg4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41ddwmm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/415tHSU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pi94FG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41ddzP4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NICTJO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NN3K7x"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/429mRgB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42b90X4"
     },
     {
      "type": "plain",
      "text": "\n\n @439\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51coZTruaGL._SX466_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Tools"
     },
     {
      "type": "plain",
      "text": " & Home Improvement\n\n                Home Improvement \n                Kitchen & Bath Fixtures \n                Bathroom Fixtures \nPryce439 \nDicos67 \n\n❌Regular Price  : ₹1099   /- 😱"
     }
    ]
   },
   {
    "id": 20982,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683368196",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "399",
    "dicos": "66",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Pigeon Inox Hydra 700 ml Sipper (Pack of 3, Black, Steel/Chrome, Steel)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/429ZK5v"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42dJYqm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nvePQ6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VG0GfC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VG0GfC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VEVXuu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LEtIaD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LDE5M2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LDDZUG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3piR6Di"
     },
     {
      "type": "plain",
      "text": "\n\n @399\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71JmRJsGhUL._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Dining \n                Kitchen Storage & Containers \nPryce399 \nDicos66 \n\n❌Regular Price  : ₹999    /- 😱"
     }
    ]
   },
   {
    "id": 20983,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683368263",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "217",
    "dicos": "89",
    "catagory": "Baby",
    "text_entities": [
     {
      "type": "plain",
      "text": "Febox Foldable King Size Mosquito Net (White)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LG7m8C"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nyLDYi"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42c08QR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B0Vr0w"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nyLG6q"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B0yLNG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AXEZOv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42wwGES"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VK4Ym8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42he9Nq"
     },
     {
      "type": "plain",
      "text": "\n\n @217\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71HDhLAPYDL._SY450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Baby"
     },
     {
      "type": "plain",
      "text": "\n\n                Outdoor Living \n                Pest Control \n                 Mosquito Nets \nPryce217 \nDicos89 \n\n❌Regular Price  : ₹  1499  /- 😱"
     }
    ]
   },
   {
    "id": 20984,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683368331",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "524",
    "dicos": "25",
    "catagory": "Beauty",
    "text_entities": [
     {
      "type": "plain",
      "text": "Maybelline New York Setting Spray, Transfer-proof, 24H Oil-Control Formula With Witch Hazel, Fit Me Matte + Poreless, 60 ml✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AXF9FB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VCHxuJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nDGChd"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/428Bm41"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nDGIW7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HKilwE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LG8KIj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42zbpdX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44wooyF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HGYyOI"
     },
     {
      "type": "plain",
      "text": "\n\n @524\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/41SQIPQz3lL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Beauty"
     },
     {
      "type": "plain",
      "text": "\n\n                Beauty \n                Make-up \n                Face \nPryce524 \nDicos25 \n \n❌Regular Price  : ₹  650  /- 😱"
     }
    ]
   },
   {
    "id": 20985,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683368375",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "3519",
    "dicos": "55",
    "catagory": "Tools",
    "text_entities": [
     {
      "type": "plain",
      "text": "Benelave By Hindware BLSCP28501 Brass Single Lever Wall Mixer W/O Telephonic Shower Arrangement TRIGON✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44MKvBl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LAbI1f"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42wwMMK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LCCNAH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42pqa2K"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LGV4gs"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LGV540"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44Atfin"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ph0Gq7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M0ywZr"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@3519"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51F3+x0ugbL._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Tools"
     },
     {
      "type": "plain",
      "text": " & Home Improvement\n\n                Home Improvement \n                Kitchen & Bath Fixtures \n                Bathroom Fixtures \nPryce3519 \nDicos55 \n\n❌Regular Price  : ₹  6999  /- 😱"
     }
    ]
   },
   {
    "id": 20986,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683368420",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "849",
    "dicos": "70",
    "catagory": "Clothing",
    "text_entities": [
     {
      "type": "plain",
      "text": "Aristocrat Spark 53cm Polyester Small Red Duffle Bag✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44D5JBA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44ChoQQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M15nNG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M09ppp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pg5gF9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NIZ3eU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44AOTmx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VzUjKF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nuJ5ur"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pdVVxz"
     },
     {
      "type": "plain",
      "text": "\n\n @849\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61sQW72viwL._UX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Clothing"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Bags, Wallets and Luggage \n                Luggage \n                 Travel Duffles \nPryce849 \nDicos70 \n\n❌Regular Price  : ₹ 1999   /- 😱"
     }
    ]
   },
   {
    "id": 20987,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683368487",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "811",
    "dicos": "88",
    "catagory": "Clothing",
    "text_entities": [
     {
      "type": "plain",
      "text": "Murano Junio 60 cm Polyester 61 LTR Duffel Bag/Travel Bag- Navy Blue✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pdoDPd"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B2aRBo"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B58Y6U"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nIDIaO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LCCV39"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AX2GGG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41aChjh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3I7Qp6n"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M0w4C9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41dT2Kp"
     },
     {
      "type": "plain",
      "text": "\n\n @811\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/81NhbKHW1fL._UX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Clothing"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Bags, Wallets and Luggage \n                Luggage \n                 Travel Duffles \nPryce811 \nDicos88 \n\n❌Regular Price  : ₹ 3499   /- 😱"
     }
    ]
   },
   {
    "id": 20988,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683368554",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "143",
    "dicos": "64",
    "catagory": "Beauty",
    "text_entities": [
     {
      "type": "plain",
      "text": "Man-Up Onion Oil Concentrate For Promote Hair Growth, Repair Hair Damaged & Hair Fall Control- 25ml✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/417p5vr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M3zEeY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nDGRZF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42vj4de"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M09wkP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42vVvkA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M3zNPy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M09xoT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NNqog2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NGdxMz"
     },
     {
      "type": "plain",
      "text": "\n\n @143\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61V0H951-wS._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Beauty"
     },
     {
      "type": "plain",
      "text": "\n\n                Beauty \n                Hair Care \n                 Hair Oils \nPryce143 \nDicos64 \n\n❌Regular Price  : ₹ 350   /- 😱"
     }
    ]
   },
   {
    "id": 20989,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683368622",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "2699",
    "dicos": "80",
    "catagory": "Clothing",
    "text_entities": [
     {
      "type": "plain",
      "text": "Aristocrat Polyester Hard 76 Cms Luggage- Suitcase(Judeb76Blu_Blue)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41dT4lv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nt2MCV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ntr2EV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41jckOI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/415U7nn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/415u6EU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NK92AF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M3A7he"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pcYyjg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nALTGk"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@2699"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51gkcOo5RcL._UX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Clothing"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Bags, Wallets and Luggage \n                Luggage \n                 Suitcases & Trolley Bags \nPryce2699 \nDicos80 \n\n❌Regular Price  : ₹8999    /- 😱"
     }
    ]
   },
   {
    "id": 20990,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683368690",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "3679",
    "dicos": "78",
    "catagory": "Clothing",
    "text_entities": [
     {
      "type": "plain",
      "text": "Nasher Miles Dalhousie Hard-Sided Polycarbonate Check-in Luggage Lavender 24 inch |65cm Trolley Bag✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Mba2gh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M2NNsO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pdoL19"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VCL646"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VzUyW5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VCL78a"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/428CFjn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42fJ7VZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42apUoG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ns2Um8"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@3679"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61n6Tng-WdL._SY741_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Clothing"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Bags, Wallets and Luggage \n                Luggage \n                 Suitcases & Trolley Bags \nPryce3679 \nDicos78 \n\n❌Regular Price  : ₹9999    /- 😱"
     }
    ]
   },
   {
    "id": 20991,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683368757",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "8899",
    "dicos": "80",
    "catagory": "Clothing",
    "text_entities": [
     {
      "type": "plain",
      "text": "Nasher Miles Mumbai Hard-Sided Polypropylene Luggage Set of 3 Red Trolley Bags (55, 65 & 75 cm)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NNqPaa"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42rScL2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LCD86r"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42z2toZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LHOlCG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VHbnyf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p6D0EG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LD2XU3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42pqpLc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VBrzRM"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@8899"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71xCw7rAxaS._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Clothing"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Bags, Wallets and Luggage \n                Luggage \n                 Luggage Sets \nPryce8899 \nDicos80 \n\n❌Regular Price  : ₹ 24999   /- 😱"
     }
    ]
   },
   {
    "id": 20992,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683368825",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "403",
    "dicos": "43",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Cello Fusion Plastic Pedal Dustbin, Big, Yellow✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LD2UaP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B0W0ra"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42ceoJk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42bPV79"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B2qrgk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42rStxy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HG8DM0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VCLfEG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HMEtGW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VzUZQd"
     },
     {
      "type": "plain",
      "text": "\n\n @403\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61xiwl-9CeL._SX522_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Home Storage & Organisation \n                Waste & Recycling \nPryce403 \nDicos43 \n\n❌Regular Price  : ₹  650  /- 😱"
     }
    ]
   },
   {
    "id": 20993,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683368870",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "597",
    "dicos": "78",
    "catagory": "Shoes",
    "text_entities": [
     {
      "type": "plain",
      "text": "Nelle Harper Women Handbag✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42vjjVz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VAot0h"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HKGtzm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HMkh7Q"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HJTVDJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HMExqa"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HKGIdK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HKAcnm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HGi7H1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42bJi4v"
     },
     {
      "type": "plain",
      "text": "\n\n @597\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51v7zEDlkvL._UY500_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Shoes"
     },
     {
      "type": "plain",
      "text": " & Handbags\n\n                Shoes & Handbags \n                Handbags, Purses & Clutches \n                Handbags \nPryce597 \nDicos78 \n\n❌Regular Price  : ₹ 1699   /- 😱"
     }
    ]
   },
   {
    "id": 20994,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683368936",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "75",
    "dicos": "53",
    "catagory": "Beauty",
    "text_entities": [
     {
      "type": "plain",
      "text": "Everyuth Naturals Hydrating & Exfoliating Walnut Apricot Scrub, 100gm, Tube✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42wUvML"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M141SY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LwcYlW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M3AfNK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LI4yIa"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LI4yIa"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ph1pYn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44x5QhV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M0QeM9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44DqX1V"
     },
     {
      "type": "plain",
      "text": "\n\n @75\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/611GJT0l8ZL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Beauty"
     },
     {
      "type": "plain",
      "text": "\n\n                Beauty \n                Skin Care \n                Face \nPryce75 \nDicos53 \n\n❌Regular Price  : ₹  139  /- 😱"
     }
    ]
   },
   {
    "id": 20995,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683369003",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "199",
    "dicos": "92",
    "catagory": "Shoes",
    "text_entities": [
     {
      "type": "plain",
      "text": "Bagsy Malone Women's Vegan Leather Iconic Sling Bag | Ladies Purse Handbag✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44LBoki"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44y9V5q"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VAtJ44"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NN4ppx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B2bcnE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LHbrtv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NLhGPs"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AXvnTM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NE3Hei"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lwd4di"
     },
     {
      "type": "plain",
      "text": "\n\n @199\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/91WkHrO4ozL._UY625_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Shoes"
     },
     {
      "type": "plain",
      "text": " & Handbags\n\n                Fashion \n                Pongal || Fashion accessories \n                Handbags \nPryce199 \nDicos92 \n\n❌Regular Price  : ₹ 1250   /- 😱"
     }
    ]
   },
   {
    "id": 20996,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683369069",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "597",
    "dicos": "83",
    "catagory": "Shoes",
    "text_entities": [
     {
      "type": "plain",
      "text": "Koel Women's Tia 2c Tote bag✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NJqx40"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nAt6uO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M1RgHU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M17lxt"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M03LUh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HOPQxV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NJR8xT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LYKVx0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3O1WdCb"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NI3aYC"
     },
     {
      "type": "plain",
      "text": "\n\n @597\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/7107WRiEYHL._UY500_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Shoes"
     },
     {
      "type": "plain",
      "text": " & Handbags\n\n                Shoes & Handbags \n                Handbags, Purses & Clutches \n                Handbags \nPryce597 \nDicos83 \n\n❌Regular Price  : ₹  1799  /- 😱"
     }
    ]
   },
   {
    "id": 20997,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683369115",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "198",
    "dicos": "90",
    "catagory": "Health",
    "text_entities": [
     {
      "type": "plain",
      "text": "beatXP Kitchen Scale Multipurpose Portable Electronic Digital Weighing Scale | Weight Machine With Back light LCD Display | White |10 kg | 2 Year Warranty |✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pik1Hf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41bKS56"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44MLtxt"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/419JXSH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pkJdNi"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M0zrsR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VBCGde"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42bae4C"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44uhhHf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/4194kja"
     },
     {
      "type": "plain",
      "text": "\n\n @198\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61Is7sICRJL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Health"
     },
     {
      "type": "plain",
      "text": " & Personal Care\n\n                Home & Kitchen \n                Kitchen & Home Appliances \n                Small Kitchen Appliances \nPryce198 \nDicos90 \n\n❌Regular Price  : ₹ 999   /- 😱"
     }
    ]
   },
   {
    "id": 20998,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683369184",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "2184",
    "dicos": "74",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "Coir FIT Health Boom 4.5'Inch Single Size Rebounded Foam Mattress(72x30x4.5, Grey)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44yZtuo"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42z3flT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42twEh1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/4298719"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42rBprw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42ceQHw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/429ocE9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/4287oNR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LAco6N"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42bJP6v"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@2184"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71ip4mZxnlL._SX522_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Home & Kitchen \n                Furniture \n                Bedroom Furniture \nPryce2184 \nDicos74 \n\n❌Regular Price  : ₹6499    /- 😱"
     }
    ]
   },
   {
    "id": 20999,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683369228",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "579",
    "dicos": "82",
    "catagory": "Shoes",
    "text_entities": [
     {
      "type": "plain",
      "text": "Caprese Ginty Magnetic Closure Faux Leather Womens Casual Wallet(Green, Small)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LI4NTA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LD3zJl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lw1nDe"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LEbzdd"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/428DybH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LFurs9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LBLQ4V"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nIEB38"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B0AaUs"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LDFoKW"
     },
     {
      "type": "plain",
      "text": "\n\n @579\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/518gHLS3p7L._UY500_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Shoes"
     },
     {
      "type": "plain",
      "text": " & Handbags\n\n                Bags, Wallets and Luggage \n                Wallets, Card Cases & Money Organisers \n                Women's \nPryce579 \nDicos82 \n\n❌Regular Price  : ₹ 2199   /- 😱"
     }
    ]
   },
   {
    "id": 21000,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683369296",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "99",
    "dicos": "63",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "wipro Garnet 3W Mini LED Downlight 2700K✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LAcyep"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AYBWW7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VBuZ6W"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AXvQp0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HMysKi"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HMF4bE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HM7dj3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HMNQWV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VHc0YD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42wYZmy"
     },
     {
      "type": "plain",
      "text": "\n\n @99\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71xavH7JstL._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Indoor Lighting \n                Light Bulbs \nPryce99 \nDicos63 \n\n❌Regular Price  : ₹ 210   /- 😱"
     }
    ]
   },
   {
    "id": 21001,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683369362",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "569",
    "dicos": "77",
    "catagory": "Health",
    "text_entities": [
     {
      "type": "plain",
      "text": "AGARO CM2107 Sonic Facial Cleansing Massager, Ultra Hygienic Soft Silicone Facial Cleansing Brush for Deep Cleansing, Skin Care, Gentle Exfoliating and Heated Massaging Waterproof & Dustproof Vibrating Facial Brush, Purple✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42r8xQc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LDFBhc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42baOiO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42900BO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42vjSPb"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LDFDFQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HKNa4e"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M0jp1U"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LYK5jE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M0wYyx"
     },
     {
      "type": "plain",
      "text": "\n\n @569\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61Ul0MQ2tqS._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Health"
     },
     {
      "type": "plain",
      "text": " & Personal Care\n\n                Beauty \n                Skin Care \n                Face \nPryce569 \nDicos77 \n\n❌Regular Price  : ₹1899    /- 😱"
     }
    ]
   },
   {
    "id": 21002,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683369409",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "849",
    "dicos": "58",
    "catagory": "Health",
    "text_entities": [
     {
      "type": "plain",
      "text": "VGR V-722 Professional 2 in 1 Cordless Epilator for Women, Runtime: 50 minutes (White, Pink)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LWWzbz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HG9qws"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LYhzif"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M14BA8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LYElGM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M3ma2N"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44D1ZQv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HIrsOk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NJqSDO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44BSTmK"
     },
     {
      "type": "plain",
      "text": "\n\n @849\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51uIBA1BPgL._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Health"
     },
     {
      "type": "plain",
      "text": " & Personal Care\n\n                Health & Personal Care \n                Personal Care \n                Shaving, Waxing & Beard Care \nPryce849 \nDicos58 \n\n❌Regular Price  : ₹  1750  /- 😱"
     }
    ]
   },
   {
    "id": 21003,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683369454",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "849",
    "dicos": "66",
    "catagory": "Health",
    "text_entities": [
     {
      "type": "plain",
      "text": "WAHL WCHS6-1724 Beard Straightening Brush; Advanced Ceramic Tourmaline Technology; Max Heat: 180 ℃; Lightweight & Portable✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44yaIDq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LYoMyO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M049lH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44BTiFM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44rOuTs"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NKeVOl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nDzRfq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LYoOGW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44xg7KW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44xg7KW"
     },
     {
      "type": "plain",
      "text": "\n\n @849\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51snVVoCDSL._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Health"
     },
     {
      "type": "plain",
      "text": " & Personal Care\n\n                Beauty \n                Hair Care \n                Hair Styling Tools \nPryce849 \nDicos66 \n\n❌Regular Price  : ₹  1999  /- 😱"
     }
    ]
   },
   {
    "id": 21004,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683369523",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "158",
    "dicos": "45",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Bajaj B22 12-Watt Led Bulb (Cool Day Light) (Cool Day Light)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44xlmdB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NIEaAA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VBVRUe"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Bj3eqF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NKf9VH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NK6uCv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3piSllW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ny4Mtm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Bj3q9n"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AZN317"
     },
     {
      "type": "plain",
      "text": "\n\n @158\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/615fTRoZntL._SY679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Indoor Lighting \n                Light Bulbs \nPryce158 \nDicos45 \n\n❌Regular Price  : ₹ 250   /- 😱"
     }
    ]
   },
   {
    "id": 21005,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683369592",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "198",
    "dicos": "60",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Philips 9-Watts Multipack B22 LED Cool Day White LED Bulb, Pack of 2, (Ace Saver)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NE4oUW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LYEwBW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VBVYPE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NGxenF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nyTRzA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HKjZ1i"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HGiW2z"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HJWuWf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3phP5qC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AYkCk8"
     },
     {
      "type": "plain",
      "text": "\n\n @198\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71KmoHMs2eL._SX466_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Indoor Lighting \n                Light Bulbs \nPryce198 \nDicos60 \n\n❌Regular Price  : ₹ 649   /- 😱"
     }
    ]
   },
   {
    "id": 21006,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683369664",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "208",
    "dicos": "50",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "PHILIPS Deco Mini 0.5-Watt Base LED Bulb (Orange) - Pack of 6✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/419k0mn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B2rmxi"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M1RZJ8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nyEebp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AX4D5Y"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p6DPNM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M3BfkY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LWWWCZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HJWFAT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M3BgW4"
     },
     {
      "type": "plain",
      "text": "\n\n @208\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61pV3lZkmJL._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Indoor Lighting \n                Light Bulbs \nPryce208 \nDicos50 \n\n❌Regular Price  : ₹ 399   /- 😱"
     }
    ]
   },
   {
    "id": 21007,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683369711",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "293",
    "dicos": "58",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Crompton Galaxy Copper String Light - Red✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/4194BCI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NNrTea"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42wZhd8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/415Eb4N"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HMMprz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HJWLIL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41fcAOq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NEUnXz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/4194Hu4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pdXfR3"
     },
     {
      "type": "plain",
      "text": "\n\n @293\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71Bw0w6TLEL._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Indoor Lighting \n                Specialty Lighting \nPryce293 \nDicos58 \n\n❌Regular Price  : ₹ 650   /- 😱"
     }
    ]
   },
   {
    "id": 21008,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683369780",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "99",
    "dicos": "83",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "FLiX (Beetel) 3in1 (Type C|Micro|Iphone Lightening) Textured Pattern 3A Fast Charging Cable with QC & PD Support for Type C,Micro USB & Lightning Iphone Cable,Made in India,1.5 Meter Long Cable(T101)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nvgoxs"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42dLitk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pkJQq8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41a1uKG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41a1uKG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/4298N6H"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44D2KZR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VBDjDC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41cItHB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pjuAd9"
     },
     {
      "type": "plain",
      "text": "\n\n @99\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/716JYaBVN3L._SY606_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Computers & Accessories \n                Accessories & Peripherals \n                Cables & Accessories \nPryce99 \nDicos83 \n\n❌Regular Price  : ₹ 350   /- 😱"
     }
    ]
   },
   {
    "id": 21009,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683369828",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "998",
    "dicos": "77",
    "catagory": "Health",
    "text_entities": [
     {
      "type": "plain",
      "text": "Lifelong LLGM63 Corded Electric Strike Gun Massager for Pain Relief with 4 Massage Heads, Remove Muscle Fatigue and Increase Blood Circulation|Full Body Massager Machine For Pain Relief (1 Year Warranty, Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41cG0gn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M3Bi08"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VBDrTC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p4m0in"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42wxZDM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p9dTkB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42wy1eS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M18bKD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VzJ2tT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VAuvOw"
     },
     {
      "type": "plain",
      "text": "\n\n @998\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51HFrnFu8OL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Health"
     },
     {
      "type": "plain",
      "text": " & Personal Care\n\n                Health & Personal Care \n                Health Care \n                Massage & Relaxation \nPryce998 \nDicos77 \n\n❌Regular Price  : ₹ 2499   /- 😱"
     }
    ]
   },
   {
    "id": 21010,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683369875",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1199",
    "dicos": "66",
    "catagory": "Health",
    "text_entities": [
     {
      "type": "plain",
      "text": "Lifelong LLS18 Steamer Medical Facial Steamer Inhaler Vaporizer for Face, Nose with Nano-Ionic Technology with 65ML Water Tank (1 Year Warranty) - White✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42bb55k"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VCJ2ZT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VCMhk2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M0RYFl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42hfYKg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/4287PYv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/427sls0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/429oWcp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B10a28"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/421XYDn"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1199"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/41x08tUFBpL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Health"
     },
     {
      "type": "plain",
      "text": " & Personal Care\n\n                Beauty \n                Tools & Accessories \n                Skin Care Tools \nPryce1199 \nDicos66 \n\n❌Regular Price  : ₹  2750  /- 😱"
     }
    ]
   },
   {
    "id": 21011,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683369921",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1899",
    "dicos": "58",
    "catagory": "Health",
    "text_entities": [
     {
      "type": "plain",
      "text": "Lifelong LLDC27 Table Top Flosser | 8 360-degree Rotating Jet Tips | 10 Pressure Modes and 600ml Tank for Flossing at Home | Oral Irrigator| I Removes plaque (1 Year Warranty, White)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NK72Z5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nyNgVU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pbWeZI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42n5Y1K"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NGeZP1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Bj3J41"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AYcYX9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VH1jWe"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NLbRSe"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pcZZya"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1899"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/518laAERYvL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Health"
     },
     {
      "type": "plain",
      "text": " & Personal Care\n\n                Health & Personal Care \n                Oral Care \n                 Dental Floss \nPryce1899 \nDicos58 \n\n❌Regular Price  : ₹ 3750   /- 😱"
     }
    ]
   },
   {
    "id": 21012,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683369968",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "4109",
    "dicos": "2",
    "catagory": "Watches",
    "text_entities": [
     {
      "type": "plain",
      "text": "Fastrack Mean Machines Analog-Digital White Dial Men's Watch-NN38054PM01✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VFzygo"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HKHQy0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HMMuLT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LDE76y"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VCJ9EN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VAzBub"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HKkl88"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LG9f5c"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AX4LCu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HIrMN2"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@4109"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61gejolUn1L._UX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Watches"
     },
     {
      "type": "plain",
      "text": "\n\n                Fashion \n                Onam 2021 \n                Watches and fashion jewellery \nPryce4109 \nDicos2 \n\n❌Regular Price  : ₹ 4195   /- 😱"
     }
    ]
   },
   {
    "id": 21013,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683370037",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "440",
    "dicos": "14",
    "catagory": "Tools",
    "text_entities": [
     {
      "type": "plain",
      "text": "anchor by panasonic 22061 6A Power Strip 4 Socket Spike Guard with Single Switch (1.5 m, White and Blue)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42sFvQi"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nyErLJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LG9sp0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p4mqVZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42rBZFI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AZI3tj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44BTDZ4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42vkyUQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HH0p68"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HKNJuS"
     },
     {
      "type": "plain",
      "text": "\n\n @440\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61pfU-SvN7L._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Tools"
     },
     {
      "type": "plain",
      "text": " & Home Improvement\n\n                Electronics \n                Power Accessories \n                 Extension Cords \nPryce440 \nDicos14 \n\n❌Regular Price  : ₹  450  /- 😱"
     }
    ]
   },
   {
    "id": 21014,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683370082",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "886",
    "dicos": "75",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Wipro Aluminium Non-Rechargeable P27010_4 14-Watt Led Light (Pack of 4, Rectangular, Yellow)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44MMmGj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44xCsIh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HKBnmM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44BJqMw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44x7ekD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HM8whX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B5at52"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HKDFlY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LH8cCf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B2cy1I"
     },
     {
      "type": "plain",
      "text": "\n\n @886\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51lgy5ZE1ML._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Indoor Lighting \n                Light Bulbs \nPryce886 \nDicos75 \n\n❌Regular Price  : ₹2999    /- 😱"
     }
    ]
   },
   {
    "id": 21015,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683370152",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "998",
    "dicos": "33",
    "catagory": "Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "Portronics Key2-A Combo of Multimedia Wireless Keyboard & Mouse, Compact Light-Weight for PCs, Laptops and Smart TV, White✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HJi7pA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M1GUIb"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M0aSMr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LG9EVg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44x7zUr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44BC3Vq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HGavEw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HH0wyA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44xcB3g"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44D7vTg"
     },
     {
      "type": "plain",
      "text": "\n\n @998\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/512xcc7u1FL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Computers & Accessories \n                Accessories & Peripherals \n                Keyboards, Mice & Input Devices \nPryce998 \nDicos33 \n\n❌Regular Price  : ₹1350    /- 😱"
     }
    ]
   },
   {
    "id": 21016,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683370201",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "239",
    "dicos": "77",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "ARCHIES Pagdi Ganpati Showpiece Essential Idol|Idols for Home Decoration|HallwaysDiwali Decoration for Home Decor|Diwali Gift Items✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HJij8i"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NI47Aa"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NGxEuf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NKfDuZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pd06K6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VF21TN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ntsszf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M1Stiq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M1HcyL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LYKToG"
     },
     {
      "type": "plain",
      "text": "\n\n @239\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71xtv9+C98L._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Home & Décor \n                Decorative Accessories \nPryce239 \nDicos77 \n\n❌Regular Price  : ₹ 999   /- 😱"
     }
    ]
   },
   {
    "id": 21017,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683370250",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "499",
    "dicos": "76",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Wipro 9W B22D WiFi LED Smart Bulb with Music Sync Function, Compatible with Amazon Alexa and Google Assistant (16M Colours, Warm White/Neutral White/White + Dimmable) Pack of 1✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NJrZ6s"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41jdNoc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/415vyHm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B2cDT4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nANAna"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nAuI7Q"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ny5YNm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41fBduq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3phPNEi"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pjuPF5"
     },
     {
      "type": "plain",
      "text": "\n\n @499\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51T0RbnCy6L._SX466_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Indoor Lighting \n                Light Bulbs \nPryce499 \nDicos76 \n\n❌Regular Price  : ₹  1499  /- 😱"
     }
    ]
   },
   {
    "id": 21018,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683370297",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "3950",
    "dicos": "64",
    "catagory": "Baby",
    "text_entities": [
     {
      "type": "plain",
      "text": "Fisher-Price Rover Steel Stroller Cum Pram for Baby(Red)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ntsF5v"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41cG7Zl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B2mmci"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/4158J6A"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AYFZld"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B2rT2g"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42sZyOh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NN5X2P"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NKbbfH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HKkDvK"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@3950"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/81F9cDEMbeL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Baby"
     },
     {
      "type": "plain",
      "text": "\n\n                Baby \n                Newborn Baby Store \n                Travel \nPryce3950 \nDicos64 \n\n❌Regular Price  : ₹ 7999   /- 😱"
     }
    ]
   },
   {
    "id": 21019,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683370343",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "155",
    "dicos": "53",
    "catagory": "Beauty",
    "text_entities": [
     {
      "type": "plain",
      "text": "NIVEA Dry Skin Facewash Combo for Women - Milk Delight Honey 100ml Facewash & Milk Delight Honey 50ml Facewash (Free)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NPzM2Z"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NN5ZHZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pib9kY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42sBF9N"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pabTIV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/429pa3f"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3phQ0Y6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HGaIri"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42arhnk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HKIjAg"
     },
     {
      "type": "plain",
      "text": "\n\n @155\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51C4h0FVd9L._SX569_PIbundle-2,TopRight,0,0_AA569SH20_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Beauty"
     },
     {
      "type": "plain",
      "text": "\n\n                Beauty \n                Skin Care \n                Face \nPryce155 \nDicos53 \n\n❌Regular Price  : ₹ 450   /- 😱"
     }
    ]
   },
   {
    "id": 21020,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683370391",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1799",
    "dicos": "71",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Lifelong LLGS18 Glass Top 3 Burner Gas Stove, Manual Ignition, LPG Compatible, Black (ISI Certified, Door Step Service, 1 Year Warranty)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/419L4BR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44yB7AS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/4158V5O"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42bbHIa"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pjv6rB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42rCmQC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44D3aiT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/421Ypxv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pabZQN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42sZIVT"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1799"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51bMcjdgQeL._SX466_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & dining \n                Steal deals \nPryce1799 \nDicos71 \n\n❌Regular Price  : ₹   4499 /- 😱"
     }
    ]
   },
   {
    "id": 21021,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683370440",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "149",
    "dicos": "79",
    "catagory": "Toys",
    "text_entities": [
     {
      "type": "plain",
      "text": "Amfin Party Golden Metallic Fringe Foil Curtain 2.5ft ,(Pack Of 2)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41gGyl3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42vkxQF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VBX1iy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VApNQN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42y9INV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M15vN2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3piT7PS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pdqjZ1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pe4TuC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M2IFoz"
     },
     {
      "type": "plain",
      "text": "\n\n @149\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/81JhwahF2vL._SX466_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Toys"
     },
     {
      "type": "plain",
      "text": " & Games\n\n                Home & Kitchen \n                Bedding & Home Furnishing \n                 Home Furnishing \nPryce149 \nDicos79 \n\n❌Regular Price  : ₹  449  /- 😱"
     }
    ]
   },
   {
    "id": 21022,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683370489",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "2180",
    "dicos": "51",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Amazon Brand - Umi Estrella Wooden Bedside Table with Storage Drawer Nightstand (Brown)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VBEaEk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B0XLEM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pgAOe5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VzWpdv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/427sKL2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42arqXU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M0xZ9P"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M0xZ9P"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42fKNif"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B0LVKx"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@2180"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/81FxIxCGOzL._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Furniture \n                Bedroom Furniture \nPryce2180 \nDicos51 \n\n❌Regular Price  : ₹3999    /- 😱"
     }
    ]
   },
   {
    "id": 21023,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683370557",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1998",
    "dicos": "46",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Lifelong 750 Watt Juicer Mixer Grinder, 4 Jars for Grinding, Mixing, and Juice at home, 1.5L Juicer with Fruit Filter (LLMG75, Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B10IFe"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VH1TDo"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VF2wx3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NNsAEi"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NKWedp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/4299Fbt"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NK7A15"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NNsJrk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VG32Lu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LBMPC9"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1998"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71Yq6g2yEyL._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Home Appliances \n                Small Kitchen Appliances \nPryce1998 \nDicos46 \n\n❌Regular Price  : ₹  3199  /- 😱"
     }
    ]
   },
   {
    "id": 21024,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683370605",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "111",
    "dicos": "78",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "FLiX (Beetel Rush 18CC USB Type C to Type C Dash Smartphone Charging Cable, upto 18W 3A super fast charging, Made in India, 1 Meter Cable Red, XCD-RTCC118✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LCEX3m"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HK3pi1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LDGQNo"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LG9YDs"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LH8wRt"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LCEUV9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LAduzr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LC7WEE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LFvE2F"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LC8c6A"
     },
     {
      "type": "plain",
      "text": "\n\n @111\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/41xe0L+yXLL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Computers & Accessories \n                Accessories & Peripherals \n                Cables & Accessories \nPryce111 \nDicos78 \n\n❌Regular Price  : ₹ 350   /- 😱"
     }
    ]
   },
   {
    "id": 21025,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683370651",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "149",
    "dicos": "63",
    "catagory": "Beauty",
    "text_entities": [
     {
      "type": "plain",
      "text": "Soulflower Lavender Essential Oil | Skin, Acne, Hair Fall, Relaxing Sleep, Aromatherapy & Body | Lavandula Angustifolia | Pure, Organic, Natural & Undiluted, Ecocert Certified | 10ml✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LHQMFk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42wYWqY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44ybtwg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42psjeO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B0IPGz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44AQF7b"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AYGc7Z"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42tyf6v"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ph2UFZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pfvnMo"
     },
     {
      "type": "plain",
      "text": "\n\n @149\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/610ETPY7H3L._SY879_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Beauty"
     },
     {
      "type": "plain",
      "text": "\n\n                Beauty \n                Bath & Body \n                Bath Additives \nPryce149 \nDicos63 \n\n❌Regular Price  : ₹  499  /- 😱"
     }
    ]
   },
   {
    "id": 21026,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683370723",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "18999",
    "dicos": "14",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "Oppo A78 5G (Glowing Black, 8GB RAM, 128 Storage) | 5000 mAh Battery with 33W SUPERVOOC Charger| 50MP AI Camera | 90Hz Refresh Rate | with No Cost EMI/Additional Exchange s✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B0MaoV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44y6g7H"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44AR0Xv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HKIsUk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AYdEf9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LDF66K"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AYlwNy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nyUTeW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HORkrZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HORlMz"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@18999"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/81LrmorQeQL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Mobiles & Accessories \n                Smartphones & Basic Mobiles \nPryce18999 \nDicos14 \n\n❌Regular Price  : ₹   20999  /- 😱"
     }
    ]
   },
   {
    "id": 21027,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683370790",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1499",
    "dicos": "53",
    "catagory": "Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "ZEBRONICS ZEB-K5000MW Bluetooth Wireless Keyboard with Easy-Switch for Up to 3 Devices for PC, Laptop, Windows, Mac, Chrome OS, Android, iPad OS, 6 Months Backup with Type C Charging (Space Grey)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HMPdVz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M0S7sd"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HKIBak"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HMGnHA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44ybHDC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44MMZj9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nANUCo"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nBmI6s"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HM9j2p"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HGbiFu"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1499"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71fIf2kTs9L._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Computers & Accessories \n                Accessories & Peripherals \n                Keyboards, Mice & Input Devices \nPryce1499 \nDicos53 \n\n❌Regular Price  : ₹ 2499   /- 😱"
     }
    ]
   },
   {
    "id": 21028,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683370859",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1896",
    "dicos": "61",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "CP PLUS 3 MP Full HD Wi-Fi PT Security Video Camera | 360°View with Motion Detection | Night Vision | Two Way Talk | Supports Alexa & OK Google | 15 Mtr, White - CP-E34A✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nxwN4r"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VEYmW2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VAAanN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NIFnYE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LYLAyi"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NE5n7A"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pjvgiH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VH2dC6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VCJQ0R"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VzWMVr"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1896"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/41ld7bu4E3L._SX466_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Cameras & Photography \n                Security Cameras \nPryce1896 \nDicos61 \n\n❌Regular Price  : ₹  3499   /- 😱"
     }
    ]
   },
   {
    "id": 21029,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683370903",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "297",
    "dicos": "67",
    "catagory": "Sports",
    "text_entities": [
     {
      "type": "plain",
      "text": "SPORTS HUBB PROTONER PUSH UP BARS PVC MATERIAL FOLDABLE✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AZJSqb"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41biK23"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41apEog"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nttL17"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41bztT3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41apMEg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41apMEg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42NlRyH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/428EJb7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42wWErN"
     },
     {
      "type": "plain",
      "text": "\n\n @297\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/31k4+X4sUxL.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Sports"
     },
     {
      "type": "plain",
      "text": ", Fitness & Outdoors\n\n                Sports, Fitness & Outdoors \n                Exercise & Fitness \n                Strength Training Equipment \nPryce297 \nDicos67 \n\n❌Regular Price  : ₹  799    /- 😱"
     }
    ]
   },
   {
    "id": 21030,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683370973",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "17090",
    "dicos": "26",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Samsung 223 L 3 Star Digital Inverter Direct Cool Single Door Refrigerator (RR24C2Y23S8/NL, Silver, Elegant Inox)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p6FaUO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p6FaUO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42sGmR0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VBtMwy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p6FgMa"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42NlZOH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p5wM84"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44yBAD8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44rPWVU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42sGz6K"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@17090"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/615scRHGkgL._SY445_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Large Appliances \n                 Refrigerators \nPryce17090 \nDicos26 \n\n❌Regular Price  : ₹   21999  /- 😱"
     }
    ]
   },
   {
    "id": 21031,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683371042",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "2949",
    "dicos": "53",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Prestige Iris 750 Watt Mixer Grinder with 3 Stainless Steel Jar + 1 Juicer Jar (White and Blue)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VFlAeP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VF2VzF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VBENOc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VBXQYG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AXY0A6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VCNqrQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42bRYbl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LWYhcZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AZOsop"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M0Eo4M"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@2949"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/81+M1QrVNIL._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Home Appliances \n                Small Kitchen Appliances \nPryce2949 \nDicos53 \n\n❌Regular Price  : ₹  4999   /- 😱"
     }
    ]
   },
   {
    "id": 21032,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683371113",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "799",
    "dicos": "75",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "pTron Bassbuds Eon Truly Wireless in Ear Earbuds with Mic,ENC, 13mm Driver, Stereo Sound, BT 5.3 Headphone, Quick Pairing, Touch Control, Fast Charging & 30Hrs Playtime, IPX4 & Voice Asst (Grey/Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VCNtUy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pe5Frw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nxHuUB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VBwGkO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42c2HlX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LEwBZ1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pa2e5b"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NLcJGu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NJSU21"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LH921R"
     },
     {
      "type": "plain",
      "text": "\n\n @799\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61ae1vRurNL._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Headphones, Earbuds & Accessories \n                Headphones \nPryce799 \nDicos75 \n\n❌Regular Price  : ₹  2499   /- 😱"
     }
    ]
   },
   {
    "id": 21033,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683371164",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "189",
    "dicos": "81",
    "catagory": "Sports",
    "text_entities": [
     {
      "type": "plain",
      "text": "XTRIM Resistance Bands with Non-Slip Grip Handles & Stretchable Bands, Ideal for Glute Work, Toning Booty Legs, Butt, Hips✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p5wUEA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LH949Z"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NGg9dl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lw3fMg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LC8BWE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42t0xxX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44xDCDD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44BVc9o"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44Aw1UP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44z1eru"
     },
     {
      "type": "plain",
      "text": "\n\n @189\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61TLqzH2EaL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Sports"
     },
     {
      "type": "plain",
      "text": ", Fitness & Outdoors\n\n                Sports, Fitness & Outdoors \n                Exercise & Fitness \n                Pilates Equipment \nPryce189 \nDicos81 \n\n❌Regular Price  : ₹  899   /- 😱"
     }
    ]
   },
   {
    "id": 21034,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683371233",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "289",
    "dicos": "58",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Bajaj 9W B22D Led White Inverter Lamp, (830328)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44yBMCm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LHRLFw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nyOArQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44D3ZIv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LEx7Gr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LEx7Gr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pacIS1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B6DgGp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nIGpcq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AZJgRn"
     },
     {
      "type": "plain",
      "text": "\n\n @289\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/411As9nBA7L._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Indoor Lighting \n                Light Bulbs \nPryce289 \nDicos58 \n\n❌Regular Price  : ₹  599    /- 😱"
     }
    ]
   },
   {
    "id": 21035,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683371281",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "449",
    "dicos": "76",
    "catagory": "Sports",
    "text_entities": [
     {
      "type": "plain",
      "text": "FitBox Sports Dumbbells Combo Weigth Plates & Hand Grips 8kg, Black✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AYm3Py"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HKPacK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42pt0EW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B0N44N"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nCl8RQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nBn5xS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LExell"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B2nhtg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pgZBPf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M0yz7v"
     },
     {
      "type": "plain",
      "text": "\n\n @449\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/618TJMTB9NL._SX466_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Sports"
     },
     {
      "type": "plain",
      "text": ", Fitness & Outdoors\n\n                Sports, Fitness & Outdoors \n                Exercise & Fitness \n                Strength Training Equipment \nPryce449 \nDicos76 \n\n❌Regular Price  : ₹  1399   /- 😱"
     }
    ]
   },
   {
    "id": 21036,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683371347",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "8998",
    "dicos": "59",
    "catagory": "Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "TP-Link Deco M4 Whole Home Mesh Wi-Fi System, Seamless Roaming and Speedy (AC1200), Work with Amazon Echo/Alexa, Router and Wi-Fi Booster, Parent Control Router, Pack of 3✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HJQGMq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HKJfog"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LYqsZ8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HGg3yv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HIPLvL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nAvyl0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M0ECZG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nAE8A9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LWYxbX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LYqxvU"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@8998"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/31Sk95Z+G9L._SY300_SX300_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Computers & Accessories \n                2021 \n                Networking - Light usage \nPryce8998 \nDicos59 \n\n❌Regular Price  : ₹  16999    /- 😱"
     }
    ]
   },
   {
    "id": 21037,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683371395",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "7906",
    "dicos": "27",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Havells Brina 50 Litres Window Air Cooler with Powerful Blower, Woodowol Pads and Ice Chamber (50L, White, Brown)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M05Z65"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NGyQxJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M0BCg1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nAOrnS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M0yYH3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NKWOb5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NKc4F3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44rQhrE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NKWPMb"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44z1oPC"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@7906"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71usAC3VBDL._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Heating, Cooling & Air Quality \n                Air Coolers \nPryce7906 \nDicos27 \n\n❌Regular Price  : ₹   9999  /- 😱"
     }
    ]
   },
   {
    "id": 21038,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683371461",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "2295",
    "dicos": "NaN",
    "catagory": "Watches",
    "text_entities": [
     {
      "type": "plain",
      "text": "Fastrack Analog Silver Dial Women's Watch-NM6112SM01 / NL6112SM01/NN6112SM01✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44x8Xq7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pjvRRt"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41bMZpy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VAADGz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NJtVfe"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3phgclW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NLktrU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NFKCIJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/4196fUU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41cJQWL"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@2295"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71P9I6WANuL._UY741_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Watches"
     },
     {
      "type": "plain",
      "text": "\n\n                Fashion \n                Onam 2021 \n                Watches and fashion jewellery \nPryce2295 \nDicosNaN"
     }
    ]
   },
   {
    "id": 21039,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683371517",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "269",
    "dicos": "55",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Fun Homes Lightweight Foldable Multipurpose Storage bag, Cloth Organiser, Travel Bag With Zippered Closure And Handle (Royal Blue)-HS43FUNH26648✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/428EkWd"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3phRly6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/419lNYD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41bjxjx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pbXRGO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pdBtwH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/415GpBb"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pe5Zqe"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41bjAMf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nyG3oL"
     },
     {
      "type": "plain",
      "text": "\n\n @269\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61HPb5yQXPL._SX466_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Home Storage & Organisation \n                Clothing & Wardrobe Storage \nPryce269 \nDicos55 \n\n❌Regular Price  : ₹  599  /- 😱"
     }
    ]
   },
   {
    "id": 21040,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683371562",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1749",
    "dicos": "84",
    "catagory": "Health",
    "text_entities": [
     {
      "type": "plain",
      "text": "NUTRIMUSCLE MASSIVE GOLD MUSCLE MASS GAINER Powder- 12 LBS - Pack of 5.44 KGS - CHOCO TREAT✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HKJn7e"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42Nmubx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42Nmokb"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3piU6Q4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HH1JWE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42rUIAY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42um8pX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VAqObz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HKlLQ0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HKF9wy"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1749"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71LjE8tE7lL._SY879_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Health"
     },
     {
      "type": "plain",
      "text": " & Personal Care\n\n                Health & Personal Care \n                Diet & Nutrition \n                Sports Supplements \nPryce1749 \nDicos84 \n\n❌Regular Price  : ₹  5999   /- 😱"
     }
    ]
   },
   {
    "id": 21041,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683371632",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "299",
    "dicos": "NaN",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "by Nilkamal Aquacado (Turquoise Blue and Rust, Set of 6 Face Towel)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42vYE3S"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3peknyS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/421ZLs5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pdYFLn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44ARFIt"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41aqzFe"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44x9kkv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pa2EZj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44rQtHo"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pe67Ge"
     },
     {
      "type": "plain",
      "text": "\n\n @299\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/91qKZRGQqkL._SX522_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Home Furnishing \n                Bathroom Linen \nPryce299 \nDicosNaN"
     }
    ]
   },
   {
    "id": 21042,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683371698",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "249",
    "dicos": "58",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Fun Homes Medium Size Soft Absorbent Cotton Face Towel, Newborn Bath Towel, Hand Towel, 30\"x18\"- Pack of 2 (White) -HS43FUNH26620✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pd1GM2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M3orel"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VG3WaQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42695eu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LwfQiI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42t182F"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42bMdtZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p5xqm0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42rDUKq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LGbJAv"
     },
     {
      "type": "plain",
      "text": "\n\n @249\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61e64iA9RLL._SX522_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Home Furnishing \n                Bathroom Linen \nPryce249 \nDicos58 \n\n❌Regular Price  : ₹   599  /- 😱"
     }
    ]
   },
   {
    "id": 21043,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683371765",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "999",
    "dicos": "33",
    "catagory": "Car",
    "text_entities": [
     {
      "type": "plain",
      "text": "Oshotto Car Backseat Storage Organizer Phone Holder,Multi-Pocket for Bottles, Tissue Boxes,Kids Toy Storage and Great Travel Accessory Compatible with Tata Hexa (Grey)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pe6bFY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44MNJEX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44xnzFV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nIp1EJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44BKQGQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44ARRHH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44CkzYM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LBOac9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44x9GYn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AYHm3l"
     },
     {
      "type": "plain",
      "text": "\n\n @999\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61x2mZPK5kL._SY679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Car"
     },
     {
      "type": "plain",
      "text": " & Motorbike\n\n                Car & Motorbike \n                Car Accessories \n                Interior Accessories \nPryce999 \nDicos33 \n\n❌Regular Price  : ₹  1399  /- 😱"
     }
    ]
   },
   {
    "id": 21044,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683371831",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "224",
    "dicos": "55",
    "catagory": "Clothing",
    "text_entities": [
     {
      "type": "plain",
      "text": "GEAR Black and Orange 49 cms Casual Backpack (BKPCARYON0106)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42lNu1s"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42rE2JU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LDG56W"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ph0nf7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3padgY5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AYEAev"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nBntwk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M3DqVG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M3DqVG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HK4Ehb"
     },
     {
      "type": "plain",
      "text": "\n\n @224\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/81-2lWPAp7L._SY879_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Clothing"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Bags, Wallets and Luggage \n                Bags & Backpacks \n                Backpacks \nPryce224 \nDicos55 \n\n❌Regular Price  : ₹   499  /- 😱"
     }
    ]
   },
   {
    "id": 21045,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683371899",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "119",
    "dicos": "85",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "pTron Volta 12W USB Charger, Fast Charging, Made in India, BIS Certified Single Port USB Wall Adapter Without Cable for All iOS & Android Devices (Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42c3HGE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HJjWCW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HLf5RF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44BVSeW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M3DKDS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pfwImo"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LYGDpm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NFndqM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M3DMeY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M1KQIY"
     },
     {
      "type": "plain",
      "text": "\n\n @119\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/41W997ySj1L._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Mobiles & Accessories \n                Mobile Accessories \nPryce119 \nDicos85 \n\n❌Regular Price  : ₹  699   /- 😱"
     }
    ]
   },
   {
    "id": 21046,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683371966",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "399",
    "dicos": "34",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Attro Helix Unbreakable PET Fridge Water Bottle Set For Office,Sports,School,Travelling,Gym,Yoga-BPA And Leak Free,Set Of 4,1000 ml,Multicolor HELIX_SO4_ASSRTD✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M3DTXW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M2ACYw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NI6anQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VSNap3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NJ2nXq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NKCeb0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NN7aan"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VK8xZy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/415GJjn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NKcKKB"
     },
     {
      "type": "plain",
      "text": "\n\n @399\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71VRnkE81VS._SX466_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Dining \n                Kitchen Storage & Containers \nPryce399 \nDicos34 \n\n❌Regular Price  : ₹  549   /- 😱"
     }
    ]
   },
   {
    "id": 21047,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683372032",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "85",
    "dicos": "53",
    "catagory": "Grocery",
    "text_entities": [
     {
      "type": "plain",
      "text": "UNIBIC Oat's Digestive Cookies, 600 g✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NLkThY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/417sqdX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NKXX2n"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NLdGP4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42n7DEw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42b21x4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nyWd1o"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41a3Itw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/417sIS5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HMBmyG"
     },
     {
      "type": "plain",
      "text": "\n\n @85\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61RRSYOGtTL._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Grocery"
     },
     {
      "type": "plain",
      "text": " & Gourmet Foods\n\n                Grocery & Gourmet Foods \n                Snacks & Sweets \n                Snack Foods \nPryce85 \nDicos53 \n\n❌Regular Price  : ₹ 180    /- 😱"
     }
    ]
   },
   {
    "id": 21048,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683372099",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "258",
    "dicos": "46",
    "catagory": "Grocery",
    "text_entities": [
     {
      "type": "plain",
      "text": "Hershey's Exotic Dark Chocolate - Californian Almond Sprinkled with BlackBerry Flavor 30g ( Pack of 8)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41cPZCi"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nBnLmU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HJZ26L"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HMOELv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42x1qWe"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42wY6KL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nBnNew"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41ar16o"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41cQkVA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42rObX1"
     },
     {
      "type": "plain",
      "text": "\n\n @258\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61kAIDQZvWS._SX679_PIbundle-8,TopRight,0,0_AA679SH20_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Grocery"
     },
     {
      "type": "plain",
      "text": " & Gourmet Foods\n\n                Grocery & Gourmet Foods \n                Snacks & Sweets \n                Chocolate \nPryce258 \nDicos46 \n\n❌Regular Price  : ₹  480    /- 😱"
     }
    ]
   },
   {
    "id": 21049,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683372165",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "719",
    "dicos": "35",
    "catagory": "Sports",
    "text_entities": [
     {
      "type": "plain",
      "text": "CHARGED unisex-adult T SHIRT✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3phRZf0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44BL2py"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41qAo2d"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41qAo2d"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42chk8O"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44BEbwo"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42c3CCV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M2ALLy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B2oi4y"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42b2bVc"
     },
     {
      "type": "plain",
      "text": "\n\n @719\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/81Eneh6dDUL._UX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Sports"
     },
     {
      "type": "plain",
      "text": ", Fitness & Outdoors\n\n                Clothing & Accessories \n                Women \n                Sportswear \nPryce719 \nDicos35 \n\n❌Regular Price  : ₹   999   /- 😱"
     }
    ]
   },
   {
    "id": 21050,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683372210",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "262",
    "dicos": "50",
    "catagory": "Beauty",
    "text_entities": [
     {
      "type": "plain",
      "text": "Nivea Pearl And Beauty Deodorant 48 Hours, 150ml And Nivea Naturally Good, Natural Avocado Body Lotion, 200ml ✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B0CXgo"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AYmQju"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LYkgAn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B5cj5W"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VSNoMV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M0U0Ft"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nxy5MP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pd2kJs"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HGlduD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VzLDUF"
     },
     {
      "type": "plain",
      "text": "\n\n @262\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61-KpYSRnBL._SX569_PIbundle-2,TopRight,0,0_AA569SH20_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Beauty"
     },
     {
      "type": "plain",
      "text": "\n\n                Beauty \n                Bath & Body \n                Deodorants & Antiperspirants \nPryce262 \nDicos50 \n\n❌Regular Price  : ₹ 524    /- 😱"
     }
    ]
   },
   {
    "id": 21051,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683372345",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "299",
    "dicos": "79",
    "catagory": "Clothing",
    "text_entities": [
     {
      "type": "plain",
      "text": "Jogger Leggings Strretchable Cloth✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42bT6M7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42bT6M7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LGbLIG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LGYgZu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LCGHcP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ph4qb9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44ts4RX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LAfzLL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44BLbt6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44BWktE"
     },
     {
      "type": "plain",
      "text": "\n\n @299\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51rLNZnUU6L._UX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Clothing"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Clothing & Accessories \n                Women \n                Western Wear \nPryce299 \nDicos79 \n\n❌Regular Price  : ₹   999  /- 😱"
     }
    ]
   },
   {
    "id": 21052,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683372413",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "699",
    "dicos": "77",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "Ambrane Dots Hype True Wireless Earbuds with 28 Hours Playtime, BoostedBass™ Low Energy Consumption Bluetooth V5.2, Responsive Touch Controls, Voice Assistance, IPX4 (White)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42phLw8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42wYngL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44rRwqO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lw4FX6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42sIjNk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AZL9NZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LGYDmQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42tzP8r"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B0ZIkA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42hijow"
     },
     {
      "type": "plain",
      "text": "\n\n @699\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51mJsWUh5JL._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Headphones, Earbuds & Accessories \n                Headphones \nPryce699 \nDicos77 \n\n❌Regular Price  : ₹  1999   /- 😱"
     }
    ]
   },
   {
    "id": 21053,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683372481",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "4990",
    "dicos": "69",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "INALSA EKON 60BK 1050 m³/hr Pyramid Kitchen Chimney With Elegant Look|Push Button Control|Efficient Dual LED Lamps & Double Baffle Filter|5 Year Warranty on Motor (Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42bTaLR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42938h2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nDCDBm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nxIF6t"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nxygHZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HMHIOC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HGhe0T"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ph4FTB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HMQWKx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M0Fy08"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@4990"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/41e4YpkyIiL._SX466_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Large Appliances \n                 Range Hoods & Chimneys \nPryce4990 \nDicos69 \n\n❌Regular Price  : ₹  11999  /- 😱"
     }
    ]
   },
   {
    "id": 21054,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683372550",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "3999",
    "dicos": "82",
    "catagory": "Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "Corsair Vengeance Performance SODIMM Memory 16GB (2x8GB) DDR4 3200MHz CL22 Unbuffered for 8th Generation or Newer Intel Core i7, and AMD Ryzen 4000 Series Notebooks✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nxymPR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ns6iNS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pels9U"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NKYaCH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M1aQnB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VBG6wA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NE75Wy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VDcS0s"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VEMPpx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VF4eP5"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@3999"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71USnvbnjIL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Computers & Accessories \n                Components \n                 Memory \nPryce3999 \nDicos82 \n\n❌Regular Price  : ₹  12999   /- 😱"
     }
    ]
   },
   {
    "id": 21055,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683372617",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "898",
    "dicos": "80",
    "catagory": "Health",
    "text_entities": [
     {
      "type": "plain",
      "text": "Beurer HR 8000 rotary shaver Precision cutting system with 3 spring-loaded dual-ring shaver heads 2-in-1 beard and sideburn styler as well as pop-up contour trimmer with 3 years warranty✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nAPCni"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M07dyd"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nt6PiB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nt6PiB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VBGew4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/415Hf0N"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41bKbIY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NE7jgm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NJUmBv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41dXdpz"
     },
     {
      "type": "plain",
      "text": "\n\n @898\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51m7cwaKcdL._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Health"
     },
     {
      "type": "plain",
      "text": " & Personal Care\n\n                Health & Personal Care \n                Personal Care \n                 Shaving, Waxing & Beard Care \nPryce898 \nDicos80 \n\n❌Regular Price  : ₹  2999   /- 😱"
     }
    ]
   },
   {
    "id": 21056,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683372685",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1406",
    "dicos": "72",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Faber Marvel 500W Blender Mixer Grinder, || low-noise, up to 18000 rpm speed || 1 year comprehensive warranty (FMG MARVEL 500W 3J PW) Peach White✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41bOggk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HMBWwm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/4197Da4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p6GQ0y"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/419n8i7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42sEbNh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42t2aM5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AWqlqN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AYFurp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M0CNfr"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1406"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61rlJGIqpeL._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Home Appliances \n                Small Kitchen Appliances \nPryce1406 \nDicos72 \n\n❌Regular Price  : ₹  3999    /- 😱"
     }
    ]
   },
   {
    "id": 21057,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683372754",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "929",
    "dicos": "69",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "ZEBRONICS Zeb Yoga N1 Wireless in Ear Neckband with 50ms Low Latency Gaming Mode, 20H Backup, Voice Assistant, Splash Proof, BT v5.2, Type C, Dual Pairing and Call Function (Blue)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LYs4Ca"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41dXAAt"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3I7Ux6n"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VACafN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41aGSSz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NJ3juU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NSfmpI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3O1ZHEL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42x21aq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p6H1ce"
     },
     {
      "type": "plain",
      "text": "\n\n @929\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/619hNgaSV0L._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Headphones, Earbuds & Accessories \n                Headphones \nPryce929 \nDicos69 \n\n❌Regular Price  : ₹  1999   /- 😱"
     }
    ]
   },
   {
    "id": 21058,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683372822",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "869",
    "dicos": "57",
    "catagory": "Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "Tizum ZW78- Full HD 1080p Webcam, Widescreen Viewing Angle, Auto Light Correction, Noise-Reducing Mic, for Skype, FaceTime, Hangouts, Xbox, PC/Mac/Laptop/MacBook/Tablet✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3phhKfK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42sEpUD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LGYQXa"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LFFqlf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LFFLEx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LEyAwr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LDH8no"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LGYXC4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42bTSbZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ph0Wpf"
     },
     {
      "type": "plain",
      "text": "\n\n @869\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/41xvT64fXQL._SX300_SY300_QL70_FMwebp_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Computers & Accessories \n                Accessories & Peripherals \n                Audio & Video Accessories \nPryce869 \nDicos57 \n\n❌Regular Price  : ₹  1699   /- 😱"
     }
    ]
   },
   {
    "id": 21059,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683372890",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "919",
    "dicos": "63",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "ZEBRONICS Zeb-DUKE1 Wireless Bluetooth 5.0 Over Ear Headphone with Voice Assistant, AUX Port, Call Function, 34Hrs* Battery Backup, Dual Pairing, Media & Volume Control with mic (Green)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42aUGOg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44xiWvw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M0D0PL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ny8N0U"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M0n0wW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42tV9uz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pg9i0f"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B6EEZD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M3EHvW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pem8fs"
     },
     {
      "type": "plain",
      "text": "\n\n @919\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61UmV5OukDL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Headphones, Earbuds & Accessories \n                Headphones \nPryce919 \nDicos63 \n\n❌Regular Price  : ₹   1999   /- 😱"
     }
    ]
   },
   {
    "id": 21060,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683372959",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "9490",
    "dicos": "41",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "EGate i9 Pro-Max 1080p Native Full HD Projector 4k Support | 4500 L (420 ANSI) | 210\" (533 cm) Large Screen | VGA, AV, HDMI, SD Card, USB, Audio Out | (E03i31) Black✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42rWt12"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42puAXo"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p6HjQm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42wAYvY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42b35ky"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42puDCy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LDJ6Em"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LyFB1Q"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42puFKG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3I7UAiz"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@9490"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71GU85VOpnL._SX450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Home Theater, TV & Video \n                 Projectors \nPryce9490 \nDicos41 \n\n❌Regular Price  : ₹  13999   /- 😱"
     }
    ]
   },
   {
    "id": 21061,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683373029",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "699",
    "dicos": "65",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "WeCool Moonwalk M1 ENC True Wireless in Ear Earbuds with Mic, Titanium Drivers for Rich Bass Experience, 40+ Hours Play Time, Type C Fast Charging, Low Latency, BT 5.3, IPX5, Deep Bass (Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LYshFs"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44xaTin"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pdilPq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/429ck4X"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HLgfN1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HMnSCS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HKKXpG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HMCl1Q"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44AT9m1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nAQ1Gk"
     },
     {
      "type": "plain",
      "text": "\n\n @699\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61S0j2RXjcL._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Headphones, Earbuds & Accessories \n                Headphones \nPryce699 \nDicos65 \n\n❌Regular Price  : ₹  1499   /- 😱"
     }
    ]
   },
   {
    "id": 21062,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683373074",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "509",
    "dicos": "35",
    "catagory": "Beauty",
    "text_entities": [
     {
      "type": "plain",
      "text": "WOW Skin Science Activated Charcoal Foaming Face Wash Combo Pack- Consist of Foaming Face Wash with Built-In Brush & Refill Pack - No Parabens, Sulphate, Silicones & Color - Net Vol. 350mL✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pemmTQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nBoN2g"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ph60K7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3phhL3i"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NFLGMJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44xfLEa"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3MberzP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M18y7W"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M0UUll"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M1bstp"
     },
     {
      "type": "plain",
      "text": "\n\n @509\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/41WF8BZpEjS._SX300_SY300_QL70_FMwebp_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Beauty"
     },
     {
      "type": "plain",
      "text": "\n\n                Beauty \n                Skin Care \n                Face \nPryce509 \nDicos35 \n\n❌Regular Price  : ₹  699  /- 😱"
     }
    ]
   },
   {
    "id": 21063,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683373118",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "199",
    "dicos": "NaN",
    "catagory": "Grocery",
    "text_entities": [
     {
      "type": "plain",
      "text": "Oleev Kitchen Junior Peanut Butter, FOR KIDS (4-12 yrs) | Choco Hazlenut | GrowX Formula with Vitamins, Calcium and Iron | Rich in Protein | Creamy | 350 g✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NGAEXx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VCLPlP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LYlhbF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VF4QUT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NI7iYC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nuNCwX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nuNDRx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/415yq76"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VzMqF7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41aGZgX"
     },
     {
      "type": "plain",
      "text": "\n\n @199\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/810U4k-ojqL._SX522_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Grocery"
     },
     {
      "type": "plain",
      "text": " & Gourmet Foods\n\n                Grocery & Gourmet Foods \n                Jams, Honey & Spreads \n                 Nut Butters \nPryce199 \nDicosNaN"
     }
    ]
   },
   {
    "id": 21064,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683373186",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "167",
    "dicos": "33",
    "catagory": "Grocery",
    "text_entities": [
     {
      "type": "plain",
      "text": "Nut Toppers Premium Dates, 400 g✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NGhT6n"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/415HTvf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B6EWjb"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/417tFd7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3phSNR4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41a4I0K"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B139Yo"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41fEn1g"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41bBGOl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nuNOMH"
     },
     {
      "type": "plain",
      "text": "\n\n @167\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/418DYzWlNnL._SY300_SX300_QL70_FMwebp_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Grocery"
     },
     {
      "type": "plain",
      "text": " & Gourmet Foods\n\n                Grocery & Gourmet Foods \n                Dried Fruits, Nuts & Seeds \n                Dried Fruits \nPryce167 \nDicos33 \n\n❌Regular Price  : ₹  249    /- 😱"
     }
    ]
   },
   {
    "id": 21065,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683373255",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1499",
    "dicos": "52",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Pigeon by Stovekraft Joy 1.8 Liter Electric Rice Cooker 700 Watt, White✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NGAUWv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42x322C"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42bhr4v"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nDLVx9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/429cskX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41bBQoV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41bLrvG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pjxONN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42x2Vnk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3piVKRK"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1499"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51Xj32RdshL._SX466_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Home Appliances \n                Small Kitchen Appliances \nPryce1499 \nDicos52 \n\n❌Regular Price  : ₹  2999   /- 😱"
     }
    ]
   },
   {
    "id": 21066,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683373325",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1499",
    "dicos": "85",
    "catagory": "Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": ", \n    Fire-Boltt Phoenix Smart Watch with Bluetooth Calling 1.3\",120+ Sports Modes, 240 * 240 PX High Res with SpO2, Heart Rate Monitoring & IP67 Rating, Rs 100 Off on UPI (Gold Pink)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VHfwlN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42vnNeY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pfybsU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/415c59G"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B13eLG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41cJwHB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B1lC78"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B1lC78"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42wBpX8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M0V5gv"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1499"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/718pWEv++fL._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Electronics \n                Wearable Technology \n                 Smart Watches \nPryce1499 \nDicos85 \n\n❌Regular Price  : ₹   6999  /- 😱"
     }
    ]
   },
   {
    "id": 21067,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683373394",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1314",
    "dicos": "56",
    "catagory": "Tools",
    "text_entities": [
     {
      "type": "plain",
      "text": "KHADIJA Metal 5 Compartment Tool Box (Red Grey)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LYltHV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AYnRYQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M0Vb7R"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HGmyl9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pdtboL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ntwbwJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NIHH1O"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VHfNFl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nvki9A"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/4221uO5"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1314"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71NqDDibpVL._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Tools"
     },
     {
      "type": "plain",
      "text": " & Home Improvement\n\n                Home Improvement \n                Power & Hand Tools \n                Tool Organisers \nPryce1314 \nDicos56 \n\n❌Regular Price  : ₹  2999    /- 😱"
     }
    ]
   },
   {
    "id": 21068,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683373437",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "185",
    "dicos": "50",
    "catagory": "Beauty",
    "text_entities": [
     {
      "type": "plain",
      "text": "Everyuth Anti-Acne Anti Marks Tulsi Turmeric Face Wash Pack of 2✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NKZxRR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HGdGfq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/426aIc6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/428GuFj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VAxSoE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HMCCBU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nuNVYD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3O208Pp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VG5Isw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pe8atU"
     },
     {
      "type": "plain",
      "text": "\n\n @185\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/81ylrXdcsjL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Beauty"
     },
     {
      "type": "plain",
      "text": "\n\n                Beauty \n                Skin Care \n                Face \nPryce185 \nDicos50 \n\n❌Regular Price  : ₹  370   /- 😱"
     }
    ]
   },
   {
    "id": 21069,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683373506",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "4499",
    "dicos": "72",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "Samsung Galaxy Buds Live Bluetooth Truly Wireless in Ear Earbuds with Mic, Upto 21 Hours Playtime, Mystic Black✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VDdZgE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3paeMJL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42vndOd"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nDBMQU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LGdw8H"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LEzngT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p9hxeh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LCHKJS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42rPMMv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LFGllH"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@4499"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61G5JoU9tTL._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Headphones, Earbuds & Accessories \n                Headphones \nPryce4499 \nDicos72 \n\n❌Regular Price  : ₹  12999   /- 😱"
     }
    ]
   },
   {
    "id": 21070,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683373574",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "616",
    "dicos": "59",
    "catagory": "Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "CLAW G9X Wired in Ear Earphones with Mic (Red)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nDBQQE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44ttFXX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44Dvu4r"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lw5YW0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42bUqyz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LCIcYv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LCIcYv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LCaSRG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B13sT2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B0LZKr"
     },
     {
      "type": "plain",
      "text": "\n\n @616\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51FPIXVfMGL._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Electronics \n                Headphones, Earbuds & Accessories \n                Headphones \nPryce616 \nDicos59 \n\n❌Regular Price  : ₹   1299   /- 😱"
     }
    ]
   },
   {
    "id": 21071,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683373618",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "299",
    "dicos": "80",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "STRIFF Laptop Stand for Desk, Ergonomic Computer Stand Laptop Riser, Phone and Tablet Stand for Desk, Portable Laptop Elevator Holder Compatible with MacBook, Laptop,Tablet(WhiteSky)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LyGaJ0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AZMlAX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AX8ike"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LFGFRr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HMIBXs"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LYsOHs"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44xGhND"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44xGikF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nAQCYA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M082XP"
     },
     {
      "type": "plain",
      "text": "\n\n @299\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61E-F2ts2ML._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Computers & Accessories \n                Accessories & Peripherals \n                Laptop Accessories \nPryce299 \nDicos80 \n\n❌Regular Price  : ₹   999  /- 😱"
     }
    ]
   },
   {
    "id": 21072,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683373686",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "696",
    "dicos": "30",
    "catagory": "Beauty",
    "text_entities": [
     {
      "type": "plain",
      "text": "Lakmé Absolute Infinity Eye Shadow Palette Matte Finish, Pink Paradise, 12g✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HK0LsL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NKemE9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VzZw5b"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3O20l59"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NE8lce"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M1KVML"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M2M2Mf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41fgqHk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nyY4TU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B119zu"
     },
     {
      "type": "plain",
      "text": "\n\n @696\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/31jNcMpJqaL._SX300_SY300_QL70_FMwebp_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Beauty"
     },
     {
      "type": "plain",
      "text": "\n\n                Beauty \n                Make-up \n                Eyes \nPryce696 \nDicos30 \n\n❌Regular Price  : ₹   1050   /- 😱"
     }
    ]
   },
   {
    "id": 21073,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683373765",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "3499",
    "dicos": "65",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "boAt Stone Symphony Portable Bluetooth Speaker with 20W RMS Stereo Sound, Party LEDs, TWS Feature, Carry Strap, Multi-Compatibility Modes, FM, IPX5, Built-in Mic(Midnight Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NK6Vgr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NK6Vgr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NK6Vgr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NK6Vgr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42x3oWC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42uGEXn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42wBSZo"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42sFfRh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/427vMPq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/427vMPq"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@3499"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71gFF575dXL._SY450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Home Audio \n                Speakers \nPryce3499 \nDicos65 \n\n❌Regular Price  : ₹ 8999   /- 😱"
     }
    ]
   },
   {
    "id": 21074,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683373844",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "13490",
    "dicos": "39",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Godrej 9 Kg 5 Star Semi-Automatic Top Loading Washing Machine (WS EDGEPRO 90 5.0 PPB3 WNRD, Wine Red, Rain Shower Spin, With 3 Wash Programs)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41fgOpg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pieBMs"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41fh1sy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41fh1sy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nyR6OO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42sJMmO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VCSdtm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42c5yeA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/429t24h"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42bf5mm"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@13490"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51wiJXBRaFL._SX466_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Large Appliances \n                Washing Machines & Dryers \nPryce13490 \nDicos39 \n\n❌Regular Price  : ₹   19999   /- 😱"
     }
    ]
   },
   {
    "id": 21075,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683373917",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1204",
    "dicos": "20",
    "catagory": "Shoes",
    "text_entities": [
     {
      "type": "plain",
      "text": "TANMAN TOYS BATA Ballerina For Women, Size 5, (6515992)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42a5NHf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nBpw3u"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NNvA3w"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42aVYc4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VCQVP0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VKTwXh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/429dg9t"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pdtO1B"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NPCVQl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NPCVQl"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1204"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71CobTA93zL._UX625_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Shoes"
     },
     {
      "type": "plain",
      "text": " & Handbags\n\n                Shoes & Handbags \n                Shoes \n                Women's Shoes \nPryce1204 \nDicos20 \n\n❌Regular Price  : ₹   1499   /- 😱"
     }
    ]
   },
   {
    "id": 21076,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683373995",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "6420",
    "dicos": "54",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "Samsung Galaxy Buds 2 | Wireless in Ear Earbuds Active Noise Cancellation, Auto Switch Feature, Up to 20hrs Battery Life, (Graphite)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42tW2Dp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nDCm12"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LCInmD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LAhmAt"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42x01Pt"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nFYxUj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44DvMIz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nDCqOk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pgaqRx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LEfLtt"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@6420"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51X0kXtlBCL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Headphones, Earbuds & Accessories \n                Headphones \nPryce6420 \nDicos54 \n\n❌Regular Price  : ₹   10999   /- 😱"
     }
    ]
   },
   {
    "id": 21077,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683374073",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "499",
    "dicos": "26",
    "catagory": "Beauty",
    "text_entities": [
     {
      "type": "plain",
      "text": "Park Avenue Original Deodorant Set For Men 150ml Each (Combo Of 3)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B0VFEQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LGdPjU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44xpOJl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LGdQnY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LGdQnY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B6FUfj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B13Xwo"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HGjdST"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42c5DPv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B0PXm9"
     },
     {
      "type": "plain",
      "text": "\n\n @499\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/817tF0q5+ML._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Beauty"
     },
     {
      "type": "plain",
      "text": "\n\n                Beauty \n                Bath & Body \n                Deodorants & Antiperspirants \nPryce499 \nDicos26 \n\n❌Regular Price  : ₹ 399    /- 😱"
     }
    ]
   },
   {
    "id": 21078,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683374174",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "8299",
    "dicos": "50",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Livpure Glo Star RO+UV+UF+Mineraliser - 7 L Storage, 15 LPH Water Purifier for Home, Suitable for Borewell, Tanker, Municipal Water (Blue)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HKFgIo"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44CfuzC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NE8Lzk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M0og38"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HJYKwP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3phj7Lq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3phj7Lq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nARfBq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VG6jug"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NKE77A"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@8299"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/6192jC2Lz2L._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Home Appliances \n                Water Purifiers & Accessories \nPryce8299 \nDicos50 \n\n❌Regular Price  : ₹   14999  /- 😱"
     }
    ]
   },
   {
    "id": 21079,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683374252",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "6799",
    "dicos": "48",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "VW 80 cm (32 inches) Frameless Series HD Ready LED TV VW32A (Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pdtXSH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NJ4RVK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NMi3JK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NIISOM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NJ4ZEI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41bMPyo"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41jimPm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42aWg2E"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42aWgzG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42dHOH6"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@6799"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/714MfYgM9rL._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Home Theater, TV & Video \n                Televisions \nPryce6799 \nDicos48 \n\n❌Regular Price  : ₹  11999   /- 😱"
     }
    ]
   },
   {
    "id": 21080,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683374326",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "169",
    "dicos": "89",
    "catagory": "Clothing",
    "text_entities": [
     {
      "type": "plain",
      "text": "Captain America by Kidsville Boy's Regular Joggers✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/415zCaA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41999sM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42aWl6s"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/429tDD3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/428cs4P"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VF63vp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HMDzds"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42wCkH4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HH4pUc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41djQKC"
     },
     {
      "type": "plain",
      "text": "\n\n @169\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/91o8UP8njUL._UY741_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Clothing"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Fashion \n                Apparel Steal Deals \n                Apparel_1 \nPryce169 \nDicos89 \n\n❌Regular Price  : ₹  899    /- 😱"
     }
    ]
   },
   {
    "id": 21081,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683374406",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "7499",
    "dicos": "43",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Havells Fab UV Storage 7 Litre UV + UF Purified Water Purifier with 5 Stages and transparent removab (Not Suitable for Borewell or tanker water)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42pvDXk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/417uZN7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/417uZN7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42uJMm5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/419oTvJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44Az5Aj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42uL94d"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44xH01j"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nAyBcW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/428cBFp"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@7499"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71JDhyVRe3L._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Home Appliances \n                Water Purifiers & Accessories \nPryce7499 \nDicos43 \n\n❌Regular Price  : ₹  11999   /- 😱"
     }
    ]
   },
   {
    "id": 21082,
    "type": "message",
    "date": "2023-05-06",
    "date_unixtime": "1683374485",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1199",
    "dicos": "65",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Lifelong LLMG23 Power Pro 500-Watt Mixer Grinder with 3 Jars (Liquidizing, Wet Grinding and Chutney Jar), Stainless Steel blades, 1 Year Warranty (Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M2ME4v"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LHcqd5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44MQiXB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LYNq1d"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B5eEhe"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42avpDQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B6Gytf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://www.amazon.in/dp/B09X5C9VLK?th=1&amp;linkCode=sl1&amp;tag=avi1deals8-21&amp;linkId=7fd6788f21b24846f388768cb5a20f83&amp;language=en_IN&amp;ref_=as_li_ss_tl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B1mTeq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41bQWdS"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1199"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61zhqJg1bTL._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Home Appliances \n                Small Kitchen Appliances \nPryce1199 \nDicos65 \n\n❌Regular Price  : ₹   2499   /- 😱"
     }
    ]
   },
  {
    "id": 20548,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683250190",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "499",
    "dicos": "81",
    "catagory":"Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀pTron Bassbuds Duo in Ear Earbuds with 32Hrs Total Playtime, Bluetooth 5.1 Wireless Headphones, Stereo Audio, Touch Control TWS, with Mic, Type-C Fast Charging, IPX4 & Voice Assistance (White)   ✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42pGtfV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AXdLre"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HL3VfN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44zqdv0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44zqdv0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44nZ0Ls"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41Y9XBT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HG9U5J"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p8cydY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HL41UH"
     },
     {
      "type": "plain",
      "text": "\n\n @499\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/414we1a2KDL._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Headphones, Earbuds & Accessories \n                Headphones \nPryce499 \nDicos81 \n \n❌Regular Price  : ₹ 1,499   /- 😱"
     }
    ]
   },
   {
    "id": 20549,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683250262",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "299",
    "dicos": "90",
    "catagory":"Shoes",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀Bagsy Malone Women's Vegan Leather Stylish Tote Bag Pack of 5 | Ladies Purse Handbag   ✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VDtJjF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vy52VI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VxGGeS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AWJxEE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LyMksv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ASA3KN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AVUBBX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AXdVik"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nC7bmL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42t7QFQ"
     },
     {
      "type": "plain",
      "text": "\n\n @299\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71LyIpj3V+L._UY500_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Shoes"
     },
     {
      "type": "plain",
      "text": " & Handbags\n\n                Shoes & Handbags \n                Handbags, Purses & Clutches \n                Handbags \nPryce299 \nDicos90 \n \n❌Regular Price  : ₹ 1,499   /- 😱"
     }
    ]
   },
   {
    "id": 20550,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683250335",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "369",
    "dicos": "89",
    "catagory":"Shoes",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀Bagsy Malone Handcrafted Vegan Leather Color-Blocked Tote with Front Pocket A Stylish and Sustainable Everyday Bag with High Capacity Room   ✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/425ijYv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/426jeId"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/4285XPp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LXay15"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42jjXWk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41Yakwh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/424Uhg1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VxGHiW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LZPQhi"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LZPQhi"
     },
     {
      "type": "plain",
      "text": "\n\n @369\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/712ZH2P32fL._UX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Shoes"
     },
     {
      "type": "plain",
      "text": " & Handbags\n\n                Shoes & Handbags \n                Handbags, Purses & Clutches \n                Handbags \nPryce369 \nDicos89 \n \n❌Regular Price  : ₹ 999   /- 😱"
     }
    ]
   },
   {
    "id": 20551,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683250413",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "449",
    "dicos": "75",
    "catagory":"Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀Redgear Cloak Wired RGB Wired Over Ear Gaming Headphones with Mic for PC   ✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VDcHlY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AWVfzd"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AX5y6s"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VDu2ej"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AYA1B8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AYA1B8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HCshZi"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nwSDVD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pcNIK5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LWrTHl"
     },
     {
      "type": "plain",
      "text": "\n\n @449\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61O0rXhhP6L._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Computers & Accessories \n                Accessories & Peripherals \n                PC Gaming Peripherals \nPryce449 \nDicos75 \n \n❌Regular Price  : ₹ 999   /- 😱"
     }
    ]
   },
   {
    "id": 20552,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683250494",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "5499",
    "dicos": "63",
    "catagory":"Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀Blaupunkt Germany's SBW200 160 Watt 2.1 Soundbar with 8 Inch(20cm) Subwoofer, HDMI-ARC, AUX, Bluetooth and Remote Control   ✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LV0c1Q"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/416RFgz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LI0sQy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/416hHAy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LZCpOl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LZCpOl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LTctUl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AWJYPi"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lzk35d"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lzk35d"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@5499"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61MDpAvYnUS._SY450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Home Audio \n                Speakers \nPryce5499 \nDicos63 \n \n❌Regular Price  : ₹ ₹7,999   /- 😱"
     }
    ]
   },
   {
    "id": 20553,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683250575",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "4995",
    "dicos": "75",
    "catagory":"Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀Instant Pot Air Fryer Vortex 4 Litre Essential 360 Degree EvenCrisp Technology Uses 95 % less Oil Nonstick and Dishwasher Safe Basket Fast Cooking Includes Free App with over 100 Recipes Vortex   ✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42oKUrx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44y9doy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42mjPpr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44vbWPK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44IZAUv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44yoLss"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LWsbOr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ntNjlZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44tncfA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44zr6Um"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@4995"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71IN+z1WhyL._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Home Appliances \n                Small Kitchen Appliances \nPryce4995 \nDicos75 \n\n❌Regular Price  : ₹ ₹20,079 /- 😱"
     }
    ]
   },
   {
    "id": 20554,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683250644",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "299",
    "dicos": "90",
    "catagory":"Shoes",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀Bagsy Malone Women's Vegan Leather Stylish Tote Bag Pack of 5 | Ladies Purse Handbag   ✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/413Ckxb"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42vtwkZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LUViBW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ntWump"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LWslW3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/416SlCD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LWiYpn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LWiYpn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/415gmdm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/425EEFr"
     },
     {
      "type": "plain",
      "text": "\n\n @299\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71S42YlbttL._UY500_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Shoes"
     },
     {
      "type": "plain",
      "text": " & Handbags\n\n                Shoes & Handbags \n                Handbags, Purses & Clutches \n                Handbags \nPryce299 \nDicos90 \n \n❌Regular Price  : ₹ ₹2,014   /- 😱"
     }
    ]
   },
   {
    "id": 20555,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683250726",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1149",
    "dicos": "48",
    "catagory":"Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀HP 330 Wireless Black Keyboard and Mouse Set with Numeric Keypad, 2.4GHz Wireless Connection and 1600 DPI, USB Receiver, LED Indicators, 3 Years Warranty, Black   ✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41cVGQp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/427mhzU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B2O0FL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LzSEQK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44y9SGy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44y9SGy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AYmTf4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AX67gA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LDsqNj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LAqEMT"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1149"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51Wi8LCipoL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Computers & Accessories \n                Accessories & Peripherals \n                Keyboards, Mice & Input Devices \nPryce1149 \nDicos48 \n \n❌Regular Price  : ₹ 1,999   /- 😱"
     }
    ]
   },
   {
    "id": 20556,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683250855",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "999",
    "dicos": "67",
    "catagory":"Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀Noise Buds VS201 V2 in-Ear Truly Wireless Earbuds with Dual Equalizer | Total 14-Hour Playtime | Full Touch Control | with Mic | IPX5 Water Resistance and Bluetooth v5.1 (Charcoal Black)  ✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LUzZAr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NBzht6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/411SFmd"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/427bN3g"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AXx17Y"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42tfmAz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/427WeZ9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44zrp1s"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44zrp1s"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3paddM1"
     },
     {
      "type": "plain",
      "text": "\n\n @999\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61xftZKXvwL._SY879_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Headphones, Earbuds & Accessories \n                Headphones \nPryce999 \nDicos67 \n \n❌Regular Price  : ₹ 1,999   /- 😱"
     }
    ]
   },
   {
    "id": 20557,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683251001",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "15989",
    "dicos": "20",
    "catagory":"Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀 Samsung Galaxy Buds2 Pro  Flat Rs.6,500 off with ICICI & Kotak Credit Cards😱✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HEGmoM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HEGmoM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LXVYGM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LXVYGM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3peo0og"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pdebaj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/417Zwu8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lxz07T"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44tAWqC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/415h3U0"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@15989"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61Qqg+T8nsL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Headphones, Earbuds & Accessories \n                Headphones \nPryce15989 \nDicos20 \n\n❌Regular Price  : ₹ 15,989   /- 😱"
     }
    ]
   },
   {
    "id": 20558,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683251093",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "10999",
    "dicos": "56",
    "catagory":"Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "REDMI (32 inches) Android 11 LED TV 👍 \n₹1,099 OFF with ICICI Cards💥✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/416T075"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/4287tRB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44zK1Oz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44zK1Oz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pdcytn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nyyVJ8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nyyVJ8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VwO1LR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VwO1LR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LZRiAg"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@10999"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71L-lTQnJiL._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Home Theater, TV & Video \n                Televisions \nPryce10999 \nDicos56"
     }
    ]
   },
   {
    "id": 20559,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683251190",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "5499",
    "dicos": "63",
    "catagory":"Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀Blaupunkt Germany's SBW200 160 Watt 2.1 Soundbar with 8 Inch(20cm) Subwoofer, HDMI-ARC, AUX, Bluetooth and Remote Control   ✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pgTFW4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nwHx30"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nwHx30"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nwHx30"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nwHx30"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NHuMgK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NGgnkX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nv1fMq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nv1fMq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HCtIqE"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@5499"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61MDpAvYnUS._SY450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Home Audio \n                Speakers \nPryce5499 \nDicos63 \n \n❌Regular Price  : ₹ ₹7,999   /- 😱"
     }
    ]
   },
   {
    "id": 20560,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683251301",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "4995",
    "dicos": "75",
    "catagory":"Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀Instant Pot Air Fryer Vortex 4 Litre Essential 360 Degree EvenCrisp Technology Uses 95 % less Oil Nonstick and Dishwasher Safe Basket Fast Cooking Includes Free App with over 100 Recipes Vortex   ✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HIHR5A"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/424UArf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44wAeZx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44wAeZx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42o5urW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VBBkPZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VBBwPd"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p6c345"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nxCtLG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nxCtLG"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@4995"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71IN+z1WhyL._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Home Appliances \n                Small Kitchen Appliances \nPryce4995 \nDicos75 \n\n❌Regular Price  : ₹ ₹20,079 /- 😱"
     }
    ]
   },
   {
    "id": 20561,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683251404",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "36990",
    "dicos": "34",
    "catagory":"Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀HONOR MagicBook 15, AMD Ryzen 5 5500U 15.6-inch (39.62 cm) FHD IPS Anti-Glare Thin and Light Laptop (8GB/256GB PCIe SSD/Windows 11/ Metal Body/Fingerprint Login/1.54Kg), Gray, BohrM-WDQ9CHNE   ✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lwr4Ut"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AUaGbc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AUaGbc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vy7coi"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pfHFEj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/417VQbK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VAWWvx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/415xL5x"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/415xL5x"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/425FOAN"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@36990"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71tHNTGasKL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Computers & Accessories \n                Laptops \n                 Traditional Laptops \nPryce36990 \nDicos34 \n\n❌Regular Price  : ₹ 49,999   /- 😱"
     }
    ]
   },
   {
    "id": 20562,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683251498",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "15999",
    "dicos": "45",
    "catagory":"Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   LG Ultrawide 29Wl50S 29 Inches (73 Cm) Wfhd LCD 2560 X 1080 Pixels IPS Display - HDR 10, AMD Freesync, Inbuilt 5W Speaker, Srgb 99%, Multi Tasking Monitor, Hdmi X 2 (Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VBh40L"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VBh40L"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44wF09x"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AVWw9D"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44J1jcr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HFxu2i"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LZ4fKC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44sCaCp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HHAxY1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HITXM6"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@15999"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61dVD28WPrL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Computers & Accessories \n                2021 \n                 Gaming Monitors \nPryce15999 \nDicos45 \n\n❌Regular Price  : ₹ 26999   /- 😱"
     }
    ]
   },
   {
    "id": 20563,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683251591",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "199",
    "dicos": "60",
    "catagory":"Beauty",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Body cupid Olive Oil Hair Mask - 200 ml✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NFHegI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VwHJfa"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42sdvwc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41YcdsR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p5vwlj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p1LdKk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vz5J14"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VwcZuN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vwd15T"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vwd15T"
     },
     {
      "type": "plain",
      "text": "\n\n @199\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61zq-H3yyAS._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Beauty"
     },
     {
      "type": "plain",
      "text": "\n\n                Beauty \n                Hair Care \n                 Hair Masks & Packs \nPryce199 \nDicos60 \n\n❌Regular Price  : ₹  410  /- 😱"
     }
    ]
   },
   {
    "id": 20564,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683251694",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "329",
    "dicos": "86",
    "catagory":"Clothing",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀  GRECIILOOKS Men’s Night Suit| Casual Wear| Lounge-wear Nightdress Pajama Set | Summer Night Wear | Printed Casual Sleepwear| Pajama Shorts Night Suits Set| Beach Wear ✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/424VmVb"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44xYPxm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44xYPxm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44toF5B"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44sCrFr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44sCrFr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44sCrFr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HCwCLZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AYFOX6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HGcmZZ"
     },
     {
      "type": "plain",
      "text": "\n\n @329\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61k-h8Q2C1L._UX466_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Clothing"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Clothing & Accessories \n                Men \n                Sleep & Lounge Wear \nPryce329 \nDicos86 \n\n❌Regular Price  : ₹  1499  /- 😱"
     }
    ]
   },
   {
    "id": 20565,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683251783",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "459",
    "dicos": "82",
    "catagory":"All",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Yashika Womens Solid Net Saree With Blouse Piece✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HCtYFN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HCtYFN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LxZgPn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LE60v0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/415Vcfc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LWkyHP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41cXhWp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41620cr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/424jgQX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nzRTza"
     },
     {
      "type": "plain",
      "text": "\n\n @459\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/616wDPFsYaL._UX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#All"
     },
     {
      "type": "plain",
      "text": "\n\n                Clothing & Accessories \n                Women \n                Ethnic Wear \nPryce459 \nDicos82 \n\n❌Regular Price  : ₹  1699  /- 😱"
     }
    ]
   },
   {
    "id": 20566,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683251876",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "329",
    "dicos": "45",
    "catagory":"Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   KiTech KB-021 Wired Keyboard✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/416GVyE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/416GVyE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/416k6LA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/417QIoh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LXoxE0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LCvF7l"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VwPamD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LDughb"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AVXoer"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AVXoer"
     },
     {
      "type": "plain",
      "text": "\n\n @329\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51czjOSaOKL._SX300_SY300_QL70_FMwebp_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Computers & Accessories \n                Accessories & Peripherals \n                Keyboards, Mice & Input Devices \nPryce329 \nDicos45 \n\n❌Regular Price  : ₹ 550   /- 😱"
     }
    ]
   },
   {
    "id": 20567,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683251962",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "889",
    "dicos": "80",
    "catagory":"Clothing",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   The Indian Garage Co Mens Jacket✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B2Q0Oh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42upitV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LXd5ID"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AYCEmu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HH9LP8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NYefVV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NYemRl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LXXKYs"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NJLCeK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NGa9kN"
     },
     {
      "type": "plain",
      "text": "\n\n @889\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51IT8Vxz+HL._UX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Clothing"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Clothing & Accessories \n                Men \n                Winter Wear \nPryce889 \nDicos80 \n\n❌Regular Price  : ₹ 2899   /- 😱"
     }
    ]
   },
   {
    "id": 20568,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683252051",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "401",
    "dicos": "50",
    "catagory":"Beauty",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Fogg Oriental Extreme Perfume, Long-Lasting Perfume, Eau De Parfum For Men, 100ml✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NHwEpM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vy8i3o"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p5w8r7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LDp4tB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VwdDsd"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3paiRO6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42vnMrv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/426mcwl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44uiaiU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44uiaiU"
     },
     {
      "type": "plain",
      "text": "\n\n @401\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/41UhrT+LIOL._SY300_SX300_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Beauty"
     },
     {
      "type": "plain",
      "text": "\n\n                Beauty \n                Fragrance \n                 Perfume \nPryce401 \nDicos50 \n\n❌Regular Price  : ₹ 650   /- 😱"
     }
    ]
   },
   {
    "id": 20569,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683252152",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "8405",
    "dicos": "58",
    "catagory":"Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Faber RO+ MAT+Vitamin C,7 Liters Water purifier with 7Stage, Upto 2500 TDS, Black✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44vfdP2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44uioXi"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44uioXi"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LAIZt1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HGZumq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HJ6zmd"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3BfhOzr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HCuSCb"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HCuSCb"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HGc1q2"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@8405"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51tF5xt8T-L._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Home Appliances \n                Water Purifiers & Accessories \nPryce8405 \nDicos58 \n\n❌Regular Price  : ₹  15999  /- 😱"
     }
    ]
   },
   {
    "id": 20570,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683252248",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "371",
    "dicos": "55",
    "catagory":"Beauty",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   NIVEA Waterlily and Oil Shower Gel, 250ml (Pack of 3)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42c1YkL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/411oOdu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/416kpGe"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LWBON4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LX8Yw1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/412cF81"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/412cF81"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/415j6Yc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/415j6Yc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/417y0Np"
     },
     {
      "type": "plain",
      "text": "\n\n @371\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61yFZeZkOOL._SX569_PIbundle-3,TopRight,0,0_AA569SH20_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Beauty"
     },
     {
      "type": "plain",
      "text": "\n\n                Beauty \n                Bath & Body \n                Cleansers \nPryce371 \nDicos55 \n\n❌Regular Price  : ₹ 650   /- 😱"
     }
    ]
   },
   {
    "id": 20571,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683252338",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "168",
    "dicos": "58",
    "catagory":"Health",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Dr Vaidya's Giloy Ras -950 ml✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44xZL4Q"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44xZL4Q"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44xZL4Q"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44xZL4Q"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LWBWfw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LWBWfw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HHBYWp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HIFhfI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ntQdHp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ntQdHp"
     },
     {
      "type": "plain",
      "text": "\n\n @168\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/512ffQrF1vL._SY450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Health"
     },
     {
      "type": "plain",
      "text": " & Personal Care\n\n                Health & Personal Care \n                Diet & Nutrition \n                Vitamins, Minerals & Supplements \nPryce168 \nDicos58 \n\n❌Regular Price  : ₹ 350   /- 😱"
     }
    ]
   },
   {
    "id": 20572,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683252423",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1209",
    "dicos": "70",
    "catagory":"Clothing",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Solefit Men Tracksuit✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p9jLuq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p9jLuq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AUCHQ4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42vw0zP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nrB7SO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nx4O4Q"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NYeWhZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LUY5Lq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NOwjkS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pdgzxN"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1209"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71XPTkdqejL._UY500_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Clothing"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Clothing & Accessories \n                Men \n                Sportswear \nPryce1209 \nDicos70 \n\n❌Regular Price  : ₹ 3499   /- 😱"
     }
    ]
   },
   {
    "id": 20573,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683252516",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "318",
    "dicos": "81",
    "catagory":"Clothing",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   LAWMAN PG3 Men's Slim Polo✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42qLJjr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41YdobL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/427oL1c"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42sP95c"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42lFnlo"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42lFnlo"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42lFnlo"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42lFnlo"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44o3jGC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42thRmr"
     },
     {
      "type": "plain",
      "text": "\n\n @318\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/81FQt9OEwFL._UX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Clothing"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Clothing & Accessories \n                Men \n                T-Shirts & Polos \nPryce318 \nDicos81 \n\n❌Regular Price  : ₹ 1099   /- 😱"
     }
    ]
   },
   {
    "id": 20574,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683252620",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "798",
    "dicos": "68",
    "catagory":"Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   boAt Rockerz 245v2 Bluetooth Wireless in Ear Earphones with Upto 8 Hours Playback, 12mm Drivers, IPX5, Magnetic Eartips, Integrated Controls and Lightweight Design with Mic (Navy Blue)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42tbRtU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44HTMdQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VBiF6L"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VBiF6L"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VBiF6L"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LCq2WO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Ly0CcV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Ly0CcV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HCvY16"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HCvY16"
     },
     {
      "type": "plain",
      "text": "\n\n @798\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/5160RpPgKrS._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Headphones, Earbuds & Accessories \n                Headphones \nPryce798 \nDicos68 \n\n❌Regular Price  : ₹ 1799   /- 😱"
     }
    ]
   },
   {
    "id": 20575,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683252704",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "303",
    "dicos": "42",
    "catagory":"Beauty",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Lakme 9to5 P+M Perfect Cover Foundation✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LX9rhL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pfJNfh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nu0lQp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41mQeuJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41mQeuJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LYacY2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LYae26"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LYae26"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nx5jMg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/416IuN2"
     },
     {
      "type": "plain",
      "text": "\n\n @303\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/41lQ78n0LBL._SY450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Beauty"
     },
     {
      "type": "plain",
      "text": "\n\n                Beauty \n                Make-up \n                Face \nPryce303 \nDicos42 \n\n❌Regular Price  : ₹ 499   /- 😱"
     }
    ]
   },
   {
    "id": 20576,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683252799",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "899",
    "dicos": "85",
    "catagory":"Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   boAt Wave Prime47 Smart Watch with 1.69\" HD Display, 700+ Active Modes, ASAP Charge, Live Cricket Scores, Crest App Health Ecosystem, HR & SpO2 Monitoring(Royal Blue)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/426n445"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/426n445"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/426n445"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LzVSUm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LzVSUm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NFiV2w"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NFiV2w"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NLRLY5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44tUnj3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NF2u6o"
     },
     {
      "type": "plain",
      "text": "\n\n @899\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61c1JhpcwWL._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Electronics \n                Wearable Technology \n                 Smart Watches \nPryce899 \nDicos85 \n\n❌Regular Price  : ₹ 3499   /- 😱"
     }
    ]
   },
   {
    "id": 20577,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683252897",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "7490",
    "dicos": "67",
    "catagory":"Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Inalsa 60 cm Filterless Curved Glass Chimney (Zylo 60BK, Push Button Control, Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VDyb1R"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42pKWiH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VP3fvX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VP3fvX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AX1Byp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AX1Byp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p9wsoU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p9wsoU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p9wsoU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NGnABd"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@7490"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/611MEE-Ex2L._SX466_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Large Appliances \n                 Range Hoods & Chimneys \nPryce7490 \nDicos67 \n\n❌Regular Price  : ₹ 16999   /- 😱"
     }
    ]
   },
   {
    "id": 20578,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683252999",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "799",
    "dicos": "80",
    "catagory":"Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   PTron Bassbuds Tango ENC Bluetooth Truly Wireless in Ear Earbuds with mic, Movie Mode, 40Hrs Playtime, Bluetooth Headphones 5.1, Deep Bass, Touch Control TWS Earbuds & Type-C Fast Charging (White)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NBhrq9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VH6fdx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VH6fdx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LV3cKv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42o8pAU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HIG1S2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LuXZIW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LWTp7P"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LWTp7P"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LWTp7P"
     },
     {
      "type": "plain",
      "text": "\n\n @799\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/41q5tMCpgoL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Headphones, Earbuds & Accessories \n                Headphones \nPryce799 \nDicos80 \n\n❌Regular Price  : ₹ 2699   /- 😱"
     }
    ]
   },
   {
    "id": 20579,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683253090",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "459",
    "dicos": "58",
    "catagory":"Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Fun Homes Floral Print 3 Pieces Plastic Bathroom Stool, Blue✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LzocpN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Ly1luF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/428iu5a"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VxM1CW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VxM1CW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VxM1CW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AUd95s"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AUd95s"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AUXRgP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AUXRgP"
     },
     {
      "type": "plain",
      "text": "\n\n @459\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61kxiXjFwdL._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Home Storage & Organisation \n                Bathroom Accessories & Organization \nPryce459 \nDicos58 \n\n❌Regular Price  : ₹  999  /- 😱"
     }
    ]
   },
   {
    "id": 20580,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683253192",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "4949",
    "dicos": "36",
    "catagory":"Baby",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   R for Rabbit Sugar Pop Baby Stroller | Pram with Auto Fold for Newborn Baby | Kids Pram and Stroller |Baby Stroller for Boy and Girl of 0 to 3 Years (Green Grey)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LXtCMI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NJmuor"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NJmuor"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NJmuor"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41bW6qm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41bW6qm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/426K0Aa"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42JEaor"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p1NC7O"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vz4S0o"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@4949"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71djhhKyAAL._SY741_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Baby"
     },
     {
      "type": "plain",
      "text": "\n\n                Baby \n                Strollers, Buggies & Prams \n                 Strollers & Prams \nPryce4949 \nDicos36 \n\n❌Regular Price  : ₹6899    /- 😱"
     }
    ]
   },
   {
    "id": 20581,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683253281",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "399",
    "dicos": "18",
    "catagory":"Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Hot Muggs Me Graffiti Mug - Makarand Personalised Name Ceramic, 315 ml, 1 Unit✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42mnN1j"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HCwaNx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LzSFEe"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ATmScu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3BfjjO5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AUVieL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AYqvxE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LXafmN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nolFa4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44ye0X4"
     },
     {
      "type": "plain",
      "text": "\n\n @399\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/41njf81LbaL._SX300_SY300_QL70_FMwebp_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Dining \n                Tableware \nPryce399 \nDicos18 \n\n❌Regular Price  : ₹ 480   /- 😱"
     }
    ]
   },
   {
    "id": 20582,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683253374",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "198",
    "dicos": "1",
    "catagory":"Beauty",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Fair and Handsome Instant Radiance Face Wash | Pro-Peptide | Instant Radiance| Washes of Fine Pollutants | Cooling Freshness | 100g✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NArCv8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NArCv8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42JEy6n"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NHEUGa"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LXf9An"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LXf9An"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LUZAcw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/425i67K"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42mobwN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42mobwN"
     },
     {
      "type": "plain",
      "text": "\n\n @198\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61FNI8Jcp0L._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Beauty"
     },
     {
      "type": "plain",
      "text": "\n\n                Beauty \n                Skin Care \n                Face \nPryce198 \nDicos1 \n\n❌Regular Price  : ₹ 180   /- 😱"
     }
    ]
   },
   {
    "id": 20583,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683253475",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "899",
    "dicos": "64",
    "catagory":"Tools",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Lifelong LLBATR09 Stainless Steel Folding Towel Rack for Bathroom/Towel Stand/Hanger/Bathroom Accessories(18 Inch-Chrome)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LV43Ld"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LBPlZb"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HCyFzF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/413HzNn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/413HzNn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44yec8K"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AUqYBd"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AUqYBd"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AUqYBd"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AUqYBd"
     },
     {
      "type": "plain",
      "text": "\n\n @899\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61nL0CGvGCL._SX522_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Tools"
     },
     {
      "type": "plain",
      "text": " & Home Improvement\n\n                Home Improvement \n                Hardware \n                Bathroom Hardware \nPryce899 \nDicos64 \n\n❌Regular Price  : ₹  1999  /- 😱"
     }
    ]
   },
   {
    "id": 20584,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683253575",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1298",
    "dicos": "71",
    "catagory":"Health",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   beatXP Deep Heal Pillow Shiatsu Infrared Heat Therapy Massager with 3 Mode Settings | Deep Tissue Massager for Shoulder, Neck and Back Pain Relief with 1 Year Warranty✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/419xtKO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42tbDDs"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://www.amazon.in/dp/B09NQ7J4ZD?&amp;linkCode=sl1&amp;tag=deals103-21&amp;linkId=bf826bb877ce09bf1f7948b0da8db73e&amp;language=en_IN&amp;ref_=as_li_ss_tl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NHyIhw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44o58mW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VDiwzQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VDiwzQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VDiwzQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VDiwzQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VBRYyA"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1298"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61UUJWLAd9L._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Health"
     },
     {
      "type": "plain",
      "text": " & Personal Care\n\n                Health & Personal Care \n                Health Care \n                Massage & Relaxation \nPryce1298 \nDicos71 \n\n❌Regular Price  : ₹ 3899   /- 😱"
     }
    ]
   },
   {
    "id": 20585,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683253668",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "13199",
    "dicos": "59",
    "catagory":"Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Samsung 24-inch FHD(59.8cm), 144 Hz, 1800R Curved Gaming Monitor, AMD FreeSync, Low Input lag, Eye Saver Mode (LC24RG50FZWXXL, Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ATM1ng"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AVZNWt"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41YfcBz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HJ8yqF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44J52qr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44uU4V4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44zhJnB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44yDtje"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44yDtje"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nr5RDt"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@13199"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/81aTiG1VpzL._SX450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Computers & Accessories \n                2021 \n                 Gaming Monitors \nPryce13199 \nDicos59 \n\n❌Regular Price  : ₹ 26999   /- 😱"
     }
    ]
   },
   {
    "id": 20586,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683253749",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1499",
    "dicos": "80",
    "catagory":"Watches",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Maxima Nitro 1.39\" Ultra HD One Tap Connection Bluetooth Calling Smartwatch, Premium Metallic Design, AI Voice Assistant, 600 Nits Display, HR & SpO2 Monitor,100+ Excercise Modes, Inbuilt Games✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NCVZkD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LyRyEI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LyRyEI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42thRmw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nzLsfe"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NGoTA7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p6rOYJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/422qDYW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pgYbnu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42c4eZh"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1499"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61504tKGRpL._UX466_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Watches"
     },
     {
      "type": "plain",
      "text": "\n\n                Fashion \n                Onam 2021 \n                Watches and fashion jewellery \nPryce1499 \nDicos80 \n\n❌Regular Price  : ₹ 3899   /- 😱"
     }
    ]
   },
   {
    "id": 20587,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683253850",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1002",
    "dicos": "54",
    "catagory":"Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Pigeon Brewster Coffee Maker, 600 Watt, 4 Cups Drip Coffee maker (Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/424YWyx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pesQ4U"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/417zMOz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/417zMOz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LsuIi4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44vhQ3m"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44usk33"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44usk33"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44usk33"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vz9eVg"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1002"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51q9WR-LfHL._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Home Appliances \n                Coffee, Tea & Espresso \nPryce1002 \nDicos54 \n\n❌Regular Price  : ₹ 2100   /- 😱"
     }
    ]
   },
   {
    "id": 20588,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683253942",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "329",
    "dicos": "45",
    "catagory":"Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   KiTech KB-021 Wired Keyboard✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LWE0UO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NHzbjM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NHzbjM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AYrpu2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nrDEwi"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42JFzeH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42JFzeH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42qD1ln"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VBh3tv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VBh3tv"
     },
     {
      "type": "plain",
      "text": "\n\n @329\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51czjOSaOKL._SY450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Computers & Accessories \n                Accessories & Peripherals \n                Keyboards, Mice & Input Devices \nPryce329 \nDicos45 \n\n❌Regular Price  : ₹ 550   /- 😱"
     }
    ]
   },
   {
    "id": 20589,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683254026",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "349",
    "dicos": "78",
    "catagory":"Clothing",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Indigo Women's Cotton Regular Kurta✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pe8o46"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vz65Vu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41YfE2J"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41YfE2J"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VB0HkD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lsl0Ms"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LxDn2N"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/425jeZ2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44o5Pwy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44o5Pwy"
     },
     {
      "type": "plain",
      "text": "\n\n @349\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/9146WwRwzzL._UX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Clothing"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Clothing & Accessories \n                Women \n                Ethnic Wear \nPryce349 \nDicos78 \n\n❌Regular Price  : ₹1450    /- 😱"
     }
    ]
   },
   {
    "id": 20590,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683254115",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "335",
    "dicos": "72",
    "catagory":"Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Amazon Brand - Solimo Plastic Printed Container Set (15-Pieces, Orange) (3-Pcs 2.2 Litres, 3-Pcs 1.2 Litres, 3-Pcs 450ml, 3-Pcs 200ml & 3 Pcs 50ml)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AW0C1x"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NGd4df"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nyCJKq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nyCJKq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nyCJKq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nyCJKq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LDhGhS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LZ8k1o"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LXrioQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LUKDam"
     },
     {
      "type": "plain",
      "text": "\n\n @335\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/81mzOAkUOfL._SX522_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Dining \n                Kitchen Storage & Containers \nPryce335 \nDicos72 \n\n❌Regular Price  : ₹  1099  /- 😱"
     }
    ]
   },
   {
    "id": 20591,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683254213",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "479",
    "dicos": "59",
    "catagory":"Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   PHILIPS 16 Watt LED Bulb, Base B22,Cool Day Light, Pack of 3✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LThKLD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/426Lp9U"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pe8N6C"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NAteoG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NAteoG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://www.amazon.in/dp/B07PNNLCB2?smid=A2AL6IVND0I91F&amp;psc=1&amp;th=1&amp;linkCode=sl1&amp;tag=avi1deals5-21&amp;linkId=cf25cdb87ce4f01a837c2dee78a2b391&amp;language=en_IN&amp;ref_=as_li_ss_tl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/424mML9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LzXVb0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LI73KO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HELARq"
     },
     {
      "type": "plain",
      "text": "\n\n @479\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/31w2qMyVLPL._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Indoor Lighting \n                Light Bulbs \nPryce479 \nDicos59 \n\n❌Regular Price  : ₹  1050  /- 😱"
     }
    ]
   },
   {
    "id": 20592,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683254305",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "637",
    "dicos": "83",
    "catagory":"Shoes",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Koel by Lavie Women's Liz Mini Backpack (P Blue)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HHd7lc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HHd7lc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HHd7lc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44zw8Ae"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NF4jAg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NGxwuB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/4184qY4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/4184wio"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nonrIg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VwLIZa"
     },
     {
      "type": "plain",
      "text": "\n\n @637\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/81INEbcNyBL._UY500_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Shoes"
     },
     {
      "type": "plain",
      "text": " & Handbags\n\n                Bags, Wallets and Luggage \n                Bags & Backpacks \n                Backpacks \nPryce637 \nDicos83 \n\n❌Regular Price  : ₹  1999  /- 😱"
     }
    ]
   },
   {
    "id": 20593,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683254405",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "299",
    "dicos": "80",
    "catagory":"Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   STRIFF Adjustable Laptop Tabletop Stand Patented Riser Ventilated Portable Foldable Compatible with MacBook Notebook Tablet Tray Desk Table Book with Free Phone Stand (Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3palWxE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AZAvqB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VBlFQt"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p9KJlF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pdj1Ev"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LDd9vG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41d0Yvf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3petTlm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LI79lE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LI79lE"
     },
     {
      "type": "plain",
      "text": "\n\n @299\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71Zf9uUp+GL._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Computers & Accessories \n                Accessories & Peripherals \n                Laptop Accessories \nPryce299 \nDicos80 \n\n❌Regular Price  : ₹ 1099   /- 😱"
     }
    ]
   },
   {
    "id": 20594,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683254520",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1125",
    "dicos": "46",
    "catagory":"Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Live Tech PS13 Power Strip with Qualcomm USB 3.0 | Surge Protector Power Strip | 2500W 10A | 2m Extension Cord | 4 Universal Socket 4 USB Type A for iPad iPhone Galaxy Android Tablets & More✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AXhhC4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AXhhC4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nCdEy3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LI7gxA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LuZUNE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HIYv58"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LwvRoV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nAIgQD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ntToyP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ntToyP"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1125"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51gShmrVbdS._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Electronics \n                Power Accessories \n                 Surge Protectors \nPryce1125 \nDicos46 \n\n❌Regular Price  : ₹ 1999   /- 😱"
     }
    ]
   },
   {
    "id": 20595,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683254610",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "589",
    "dicos": "73",
    "catagory":"Clothing",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀  AEROPOSTALE Men Indigo Short Sleeve Printed Polo Shirt ✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/427s48C"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Bfl51H"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LzUlxw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LzUlxw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LzUlxw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nF0xMy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nF0xMy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nF0xMy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Ly3c2B"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HGZcvK"
     },
     {
      "type": "plain",
      "text": "\n\n @589\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71O3VPZWrVL._UX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Clothing"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Clothing & Accessories \n                Men \n                T-Shirts & Polos \nPryce589 \nDicos73 \n\n❌Regular Price  : ₹ 1999   /- 😱"
     }
    ]
   },
   {
    "id": 20596,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683254694",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "229",
    "dicos": "71",
    "catagory":"Clothing",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Fabme Women's Satin Plain Maxi Nighty - Kaftan Night Gown✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NJojSj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NJojSj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42lIj1o"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3npQDP3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LXsatC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LXsatC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NGGxnv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pgZtPm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42q9N63"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pe9kWa"
     },
     {
      "type": "plain",
      "text": "\n\n @229\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/81qshAXDlSL._UY741_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Clothing"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Fashion \n                Apparel Steal Deals \n                Apparel_1 \nPryce229 \nDicos71 \n\n❌Regular Price  : ₹ 680   /- 😱"
     }
    ]
   },
   {
    "id": 20597,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683254783",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "229",
    "dicos": "90",
    "catagory":"Clothing",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   GRECIILOOKS Women's Solid Cotton Silk Manipuri Striped Woven Traditional Saree With Blouse Piece✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/415mkeg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/415mkeg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44yESGw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VDjRGS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44vjamQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AUFWqI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pcUEH7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pcUEH7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VH94v9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pcUMq5"
     },
     {
      "type": "plain",
      "text": "\n\n @229\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/81Q3v6PTCNL._UY741_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Clothing"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Clothing & Accessories \n                Women \n                Ethnic Wear \nPryce229 \nDicos90 \n\n❌Regular Price  : ₹  1499  /- 😱"
     }
    ]
   },
   {
    "id": 20598,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683254874",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "239",
    "dicos": "82",
    "catagory":"Clothing",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   EYEBOGLER Men T-Shirt✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VwMB3W"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nv6mw6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nv6mw6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nv6mw6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LDyMw9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AXi2uU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AZBeIl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AYsL86"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p8jnw6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LwwzCB"
     },
     {
      "type": "plain",
      "text": "\n\n @239\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61jdWXt9-iL._UY500_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Clothing"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Clothing & Accessories \n                Men \n                T-Shirts & Polos \nPryce239 \nDicos82 \n\n❌Regular Price  : ₹ 999   /- 😱"
     }
    ]
   },
   {
    "id": 20599,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683254966",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "139",
    "dicos": "54",
    "catagory":"Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   SignoraWare Kitchen Tools Stainless Steel(Food Grade) Heavy Gauge(1.2 MM) Elite Slotted Turner/Spatula/Cooking Turner No.5 for Dosa/Roti/Omlette/Paranthas/PavBhaji/Breads (Size 34 cm) Set/2✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HHdMTI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LUG7IX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/4195MSC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LDiKlS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/415DiJl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LTiZuh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p8jAiS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/411Pefe"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/415ZK5g"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/415ZK5g"
     },
     {
      "type": "plain",
      "text": "\n\n @139\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51SXZZs8vUL._SX522_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Dining \n                Tableware \nPryce139 \nDicos54"
     }
    ]
   },
   {
    "id": 20600,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683255057",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "660",
    "dicos": "80",
    "catagory":"Clothing",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Hubberholme Women's Winter Wear Cotton Blend Panelled Sleeves Printed Crew Neck Sweatshirt✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3peEnRH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42a4LLs"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HCyLHh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LV8kzc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nr8llh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3npqwYB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NJoYDh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NOzNns"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NOzNns"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NGeHHT"
     },
     {
      "type": "plain",
      "text": "\n\n @660\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61i7M7soB7L._UX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Clothing"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Clothing & Accessories \n                Women \n                Western Wear \nPryce660 \nDicos80 \n\n❌Regular Price  : ₹ 2899   /- 😱"
     }
    ]
   },
   {
    "id": 20601,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683255156",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1758",
    "dicos": "41",
    "catagory":"Car",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Vega Bolt Bunny Dull Black Red Helmet-L✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42obelu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42obelu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42obelu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44y48Ni"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VBbtaK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44zQ1a3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VB2ean"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VB2ean"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VB2ean"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pcV6VP"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1758"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61eOh8zP3VL._SX450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Car"
     },
     {
      "type": "plain",
      "text": " & Motorbike\n\n                Car & Motorbike \n                Motorbike Accessories & Parts \n                Helmets \nPryce1758 \nDicos41 \n\n❌Regular Price  : ₹  2499  /- 😱"
     }
    ]
   },
   {
    "id": 20602,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683255256",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "597",
    "dicos": "80",
    "catagory":"Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   ZEBRONICS Zeb-Yoga 90 Pro Bluetooth Wireless in Ear Earphones with Mic (Blue)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pfd7Tu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AXl3v6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vzbtb8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nzNnAs"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B2UDI9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B2UDI9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ph0aYY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LBRO5T"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LBRO5T"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pfN5z9"
     },
     {
      "type": "plain",
      "text": "\n\n @597\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61ilKaT7miL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Headphones, Earbuds & Accessories \n                Headphones \nPryce597 \nDicos80 \n\n❌Regular Price  : ₹  1899  /- 😱"
     }
    ]
   },
   {
    "id": 20603,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683255344",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "219",
    "dicos": "83",
    "catagory":"Clothing",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Gerua Women's Rayon a-line Kurta✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HDnmak"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/412gYjH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/412gYjH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/412gYjH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/426N2o2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/426qzrf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/411Q0Ja"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LxF8wV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LwxlQ1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LDzyJz"
     },
     {
      "type": "plain",
      "text": "\n\n @219\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/8168xEck50L._UX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Clothing"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Clothing & Accessories \n                Women \n                Ethnic Wear \nPryce219 \nDicos83 \n\n❌Regular Price  : ₹ 850   /- 😱"
     }
    ]
   },
   {
    "id": 20604,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683255435",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "339",
    "dicos": "97",
    "catagory":"Sports",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   AmazonBasics Silicone Universal Mobile Phone Mount for Bicycles✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/428oxGZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HJbfbL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nA8y5B"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HFa0KN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LWXoRG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LWXoRG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NGTRbI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LV7Bya"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NJpr8v"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44zkeGv"
     },
     {
      "type": "plain",
      "text": "\n\n @339\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61j5FdW+5fL._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Sports"
     },
     {
      "type": "plain",
      "text": ", Fitness & Outdoors\n\n                Electronics \n                Mobiles & Accessories \n                Mobile Accessories \nPryce339 \nDicos97 \n\n❌Regular Price  : ₹ 3499   /- 😱"
     }
    ]
   },
   {
    "id": 20605,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683255532",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "999",
    "dicos": "86",
    "catagory":"Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   TAGG Verve Sense Smartwatch with 1.70'' Large Display, Real SPO2, and Real-Time Heart Rate Tracking, 7 Days Battery Backup, IPX67 Waterproof || Silver Yellow,Standard✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41bZb9U"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ph0Hdq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44ygDbo"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44ygDbo"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44ygDbo"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p2ZYfU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42jt49u"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44pOxzj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VBnnRT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44qG0MB"
     },
     {
      "type": "plain",
      "text": "\n\n @999\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51VIljESKCL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Electronics \n                Wearable Technology \n                 Smart Watches \nPryce999 \nDicos86 \n\n❌Regular Price  : ₹  3999  /- 😱"
     }
    ]
   },
   {
    "id": 20606,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683255625",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "7499",
    "dicos": "79",
    "catagory":"Luggage",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   AmazonBasics Polycarbonate Hard 74 cms suitcase(T1916-24_navy blue)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VBHnEb"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VBHnEb"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VBHnEb"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VxQhSW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LyUqRV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AYtOoy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LDjA20"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pfdI7G"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NGzdbr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AU03FB"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@7499"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/91fwzkTZj7L._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Luggage"
     },
     {
      "type": "plain",
      "text": " & Bags\n\n                Bags, Wallets and Luggage \n                Luggage \n                 Luggage Sets \nPryce7499 \nDicos79 \n\n❌Regular Price  : ₹ 19999   /- 😱"
     }
    ]
   },
   {
    "id": 20607,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683255723",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "499",
    "dicos": "81",
    "catagory":"Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   pTron Bassbuds Duo in Ear Earbuds with 32Hrs Total Playtime, Bluetooth 5.1 Wireless Headphones, Stereo Audio, Touch Control TWS, with Mic, Type-C Fast Charging, IPX4 & Voice Assistance (White)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42lJM7U"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/426Noem"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/426Noem"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LWU74V"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/423Pbkg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LyUwsQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Bfn2Lz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Bfn2Lz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Bfn2Lz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AW4orL"
     },
     {
      "type": "plain",
      "text": "\n\n @499\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/414we1a2KDL._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Headphones, Earbuds & Accessories \n                Headphones \nPryce499 \nDicos81 \n\n❌Regular Price  : ₹  1799  /- 😱"
     }
    ]
   },
   {
    "id": 20608,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683255824",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "299",
    "dicos": "80",
    "catagory":"Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀  STRIFF Adjustable Laptop Tabletop Stand Patented Riser Ventilated Portable Foldable Compatible with MacBook Notebook Tablet Tray Desk Table Book with Free Phone Stand (Black) ✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HH4nfg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HH4nfg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HH4nfg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LV3oKQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LV3oKQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NGUEcG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LYXeZW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LYXeZW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NGfWa1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NGfWa1"
     },
     {
      "type": "plain",
      "text": "\n\n @299\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71Zf9uUp+GL._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Computers & Accessories \n                Accessories & Peripherals \n                Laptop Accessories \nPryce299 \nDicos80 \n\n❌Regular Price  : ₹ 1099   /- 😱"
     }
    ]
   },
   {
    "id": 20609,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683255924",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "249",
    "dicos": "75",
    "catagory":"Sports",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   HAANS Hulk Gallon Protein Shaker Bottle 1.5 Liter (Black/Silver)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NFnBp6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VxPTE6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p9MPC3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VBnddl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44HYsQW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44HYsQW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44HYsQW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44HYsQW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AUHReU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3BfnbP7"
     },
     {
      "type": "plain",
      "text": "\n\n @249\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71SvSZsuvZL._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Sports"
     },
     {
      "type": "plain",
      "text": ", Fitness & Outdoors\n\n                Home & Kitchen \n                Kitchen & Dining \n                Bar Accessories \nPryce249 \nDicos75 \n\n❌Regular Price  : ₹ 750   /- 😱"
     }
    ]
   },
   {
    "id": 20610,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683256031",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "329",
    "dicos": "87",
    "catagory":"Clothing",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Red Tape Men's Regular Fit Polo✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VCpmFO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VCpmFO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VCpmFO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HGkZnd"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vw4XSJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HDor1S"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HDor1S"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NHjWHt"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42vsTYJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M7zZxf"
     },
     {
      "type": "plain",
      "text": "\n\n @329\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61pJEXz+LbL._UX522_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Clothing"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Fashion \n                Apparel Steal Deals \n                Apparel_1 \nPryce329 \nDicos87 \n\n❌Regular Price  : ₹  1499  /- 😱"
     }
    ]
   },
   {
    "id": 20611,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683256154",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "4995",
    "dicos": "75",
    "catagory":"Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Instant Pot Air Fryer Vortex 4 Litre Essential 360 Degree EvenCrisp Technology Uses 95 % less Oil Nonstick and Dishwasher Safe Basket Fast Cooking Includes Free App with over 100 Recipes Vortex✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LUNMaa"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LUNMaa"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LUNMaa"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LUNMaa"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LUNMaa"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NJqWUb"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NFojTi"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HHfSmy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HHfSmy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HHfSmy"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@4995"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71IN+z1WhyL._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Home Appliances \n                Small Kitchen Appliances \nPryce4995 \nDicos75 \n\n❌Regular Price  : ₹  14999  /- 😱"
     }
    ]
   },
   {
    "id": 20612,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683256266",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "2949",
    "dicos": "53",
    "catagory":"Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Prestige IRIS Plus 750 watt mixer grinder✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ATqOdg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ATqOdg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ATqOdg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AUZ47V"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AUZ47V"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3paoyeW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B2VJnf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B2VJnf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pale3z"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AYMch2"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@2949"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51HfqyUaHyL._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Home Appliances \n                Small Kitchen Appliances \nPryce2949 \nDicos53 \n\n❌Regular Price  : ₹ 5500   /- 😱"
     }
    ]
   },
   {
    "id": 20613,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683256377",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "12640",
    "dicos": "23",
    "catagory":"Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Livpure Bolt+ Copper with 80% Water Savings (HR Tech), Copper+RO+UF+Mineraliser+Smart TDS Adjuster, 7 L tank, 15 lph Water Purifier for home (Blue)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pcWOXf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44wLqp9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/412iiTH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AUZew3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AUZew3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VBW1Li"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AV2HdZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3BfnRUF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3BfnRUF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VBnYTJ"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@12640"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/41s1NI12u0L._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Home Appliances \n                Water Purifiers & Accessories \nPryce12640 \nDicos23 \n\n❌Regular Price  : ₹  15949  /- 😱"
     }
    ]
   },
   {
    "id": 20614,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683256489",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "319",
    "dicos": "77",
    "catagory":"Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Butterfly Premium Vegetable Chopper 900 Ml, Blue✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HLdrj1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LCCtlp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NBmk2t"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nxalbC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LDBqlz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NGo0b5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LztDFd"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42qRwWd"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nrav4n"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nrav4n"
     },
     {
      "type": "plain",
      "text": "\n\n @319\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61fPH47Hd0L._SX466_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Dining \n                Kitchen Tools \nPryce319 \nDicos77 \n\n❌Regular Price  : ₹ 1099   /- 😱"
     }
    ]
   },
   {
    "id": 20615,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683256582",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "199",
    "dicos": "80",
    "catagory":"Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   BSB HOME® Glace Cotton 160 TC Football Printed Double Bedsheets with 2 King Size Pillow Cover, (90X90 Inches, 7 x 7.5 Feet, Blue and Grey)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/4187Gmg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pdAaxW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pdAaxW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/4161SKi"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/4161SKi"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/4161SKi"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44uYRWy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LAzF8H"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p9XH2D"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/427jTsG"
     },
     {
      "type": "plain",
      "text": "\n\n @199\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51ztm8UK7hL.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Home Furnishing \n                Bedding & Linen \nPryce199 \nDicos80 \n\n❌Regular Price  : ₹  850  /- 😱"
     }
    ]
   },
   {
    "id": 20616,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683256667",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "219",
    "dicos": "78",
    "catagory":"Clothing",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   oxolloxo Women's Regular Fit Shirt✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lzu16D"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pcXJqF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p9OkjF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VGs7py"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ATruzk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AWSwWo"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AXnMok"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NGtFh1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3npTBTH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AU1WCb"
     },
     {
      "type": "plain",
      "text": "\n\n @219\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/715sm-5R0zL._UX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Clothing"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Clothing & Accessories \n                Women \n                Western Wear \nPryce219 \nDicos78 \n\n❌Regular Price  : ₹ 850   /- 😱"
     }
    ]
   },
   {
    "id": 20617,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683256764",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "8999",
    "dicos": "31",
    "catagory":"Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Kodak 80 cm (32 inches) HD Ready Certified Android LED TV 32HDX7XPRO (Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LZLFSB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ntWBOT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VwPxgY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42uvZfx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42uvZfx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42uvZfx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/427BBwf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/425rV5x"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/427k730"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B1xx4E"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@8999"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/81pOKCk2z1L._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Home Theater, TV & Video \n                Televisions \nPryce8999 \nDicos31 \n\n❌Regular Price  : ₹  11999  /- 😱"
     }
    ]
   },
   {
    "id": 20618,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683256855",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "619",
    "dicos": "69",
    "catagory":"Clothing",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   ALCiS Women's Skinny Tights✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/426stbn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44zScKL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44yHJiI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LzYfGG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/413MeyR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NHl4uH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NCEOPZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44zSj99"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NHl8dV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NHl8dV"
     },
     {
      "type": "plain",
      "text": "\n\n @619\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71R8tHbr3NL._UX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Clothing"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Clothing & Accessories \n                Women \n                Sportswear \nPryce619 \nDicos69 \n\n❌Regular Price  : ₹ 1850   /- 😱"
     }
    ]
   },
   {
    "id": 20619,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683256959",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1999",
    "dicos": "76",
    "catagory":"Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Boult Dive+ with 1.85\" HD Display, Bluetooth Calling Smartwatch, 500 Nits Brightness, 7 Days Battery Life, 150+ Watch Faces, 100+ Sport Modes, IP68 Waterproof Smart Watch (ICY Blue)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LXfmmZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p8mjc6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LxHrQB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LxHrQB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/4162iAm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/423QUWM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3peceu2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HCC9Sv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44yiWey"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LV9xGW"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1999"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71ilAZfYIlL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Electronics \n                Wearable Technology \n                 Smart Watches \nPryce1999 \nDicos76 \n\n❌Regular Price  : ₹   4999  /- 😱"
     }
    ]
   },
   {
    "id": 20620,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683257051",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "272",
    "dicos": "9",
    "catagory":"Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Syska 2 Feet 8Watt Straight Linear LED Tube Light  (White)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44qIv1r"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VzapnG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VzapnG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VzapnG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3norqoc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AV3Cer"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VwNz01"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VxSNZo"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AU2qrZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LDwqgH"
     },
     {
      "type": "plain",
      "text": "\n\n @272\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61xQgcGBE3L._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Indoor Lighting \n                 Tube Lights & Battens \nPryce272 \nDicos9 \n\n❌Regular Price  : ₹  299   /- 😱"
     }
    ]
   },
   {
    "id": 20621,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683257144",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "675",
    "dicos": "77",
    "catagory":"Shoes",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Bond Street by Redtape Men's Slip-On Shoes✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AYK4Gm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LXwusT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LXwusT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LXwusT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LXwusT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LX0p4s"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LX0p4s"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/416rO8u"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pecsBo"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VxRSZ4"
     },
     {
      "type": "plain",
      "text": "\n\n @675\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/618xigdcmmL._UY575_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Shoes"
     },
     {
      "type": "plain",
      "text": " & Handbags\n\n                Shoes & Handbags \n                Shoes \n                Men's Shoes \nPryce675 \nDicos77 \n\n❌Regular Price  : ₹  1999  /- 😱"
     }
    ]
   },
   {
    "id": 20622,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683257232",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1573",
    "dicos": "37",
    "catagory":"Clothing",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   AEROPOSTALE Women's Slim Fit Jeans✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/411w49e"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/422vZ6u"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AXoHVO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AYwbHY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/423RdRq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AYO7lK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42dxE9C"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HIviHv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44xTNRy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44xTNRy"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1573"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71Y6mnoRgHL._UX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Clothing"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Clothing & Accessories \n                Women \n                Western Wear \nPryce1573 \nDicos37 \n\n❌Regular Price  : ₹  2499   /- 😱"
     }
    ]
   },
   {
    "id": 20623,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683257315",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "530",
    "dicos": "65",
    "catagory":"Clothing",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀  Karmic Vision Women's Regular Fit TOP✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/419Ctiy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NGsZIM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NF8I6g"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42qdEA3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p6k5tL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HDq6EE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42rI4lv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p0H1dN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M00swE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M00swE"
     },
     {
      "type": "plain",
      "text": "\n\n @530\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71Dgufe1knL._UX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Clothing"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Clothing & Accessories \n                Women \n                Western Wear \nPryce530 \nDicos65 \n\n❌Regular Price  : ₹  1199  /- 😱"
     }
    ]
   },
   {
    "id": 20624,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683257413",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "89",
    "dicos": "55",
    "catagory":"Sports",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   SPIRO PVC (Round/Hex) Dumbbells (1 Kg. X 2 = 2 Kg) Pack of 2 for Home Gym Equipment Fitness Barbell|Gym Exercise|Home Workout | Fitness Kit|Dumbbells Weights for Whole Body Workout✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VP9GiB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LC4v0p"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LC4v0p"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LC4v0p"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LC4v0p"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LyXCgs"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pcupk3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pcupk3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44twvMz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44twvMz"
     },
     {
      "type": "plain",
      "text": "\n\n @89\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61VcW8otphL._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Sports"
     },
     {
      "type": "plain",
      "text": ", Fitness & Outdoors\n\n                Sports, Fitness & Outdoors \n                Exercise & Fitness \n                Strength Training Equipment \nPryce89 \nDicos55 \n\n❌Regular Price  : ₹  199    /- 😱"
     }
    ]
   },
   {
    "id": 20625,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683257507",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "15989",
    "dicos": "33",
    "catagory":"Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Sony HT-S20R Real 5.1ch Dolby Digital Soundbar for TV with subwoofer and Compact Rear Speakers, 5.1ch Home Theatre System (400W,Bluetooth & USB Connectivity, HDMI & Optical connectivity)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VwlQg1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LsArEA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LDikM8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LXgh6V"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LXgh6V"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42p40hg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/417F3FR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LZ8EgD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41728bR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41728bR"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@15989"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71+s6K1eovL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Home Audio \n                Speakers \nPryce15989 \nDicos33 \n\n❌Regular Price  : ₹  21999   /- 😱"
     }
    ]
   },
   {
    "id": 20626,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683257606",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "159",
    "dicos": "60",
    "catagory":"Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Pigeon by Stovekraft Inox Hydra Plus Stainless Steel Drinking Water Bottle 700 ml - Silver✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42a8loS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42vDm6p"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42vDm6p"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pcYZKp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3panwQd"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3panwQd"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NGuQwX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p8n590"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p8n590"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NLZzsR"
     },
     {
      "type": "plain",
      "text": "\n\n @159\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51ZN0mL2y2L._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Dining \n                Kitchen Storage & Containers \nPryce159 \nDicos60 \n\n❌Regular Price  : ₹  395   /- 😱"
     }
    ]
   },
   {
    "id": 20627,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683257691",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "500",
    "dicos": "37",
    "catagory":"Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Syska 2 Feet 8Watt Straight Linear LED Tube Light  (White) (Pack of 2)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42thBnQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LzZ9D4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HGl9er"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pednSm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HISiGi"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42dytze"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42dytze"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/428oLhe"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/424rMPV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/427wsEC"
     },
     {
      "type": "plain",
      "text": "\n\n @500\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61ePaGIX1WL._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Indoor Lighting \n                 Tube Lights & Battens \nPryce500 \nDicos37 \n\n❌Regular Price  : ₹  799   /- 😱"
     }
    ]
   },
   {
    "id": 20628,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683257789",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "999",
    "dicos": "86",
    "catagory":"Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   beatXP Marv Neo with 1.85” HD Display Smartwatch, BT Calling, 24 * 7 Continous Health Monitoring, Fast Charging, 100+ Sports Modes, Cloud Based Watch Faces & IP68 Rating (Electric Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42jwc5e"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42jwc5e"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LABaDR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VBKyf5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VBYCVy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41c2hL4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/411TmvK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41d5gTn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/411TpaU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HGkSrM"
     },
     {
      "type": "plain",
      "text": "\n\n @999\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71vgX-vkOPL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Electronics \n                Wearable Technology \n                 Smart Watches \nPryce999 \nDicos86 \n\n❌Regular Price  : ₹  4999   /- 😱"
     }
    ]
   },
   {
    "id": 20629,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683257880",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "378",
    "dicos": "62",
    "catagory":"Tools",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   ALOXE Cosmetic Organizer Box Drawers Storage Plastic Stationary Box | Make Up Organiser For Women✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nxLEM8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nzRYmc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LTmLUt"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/4172L5d"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vwmryh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LXxc9u"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LXxc9u"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p9rzfI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p9rzfI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p9rzfI"
     },
     {
      "type": "plain",
      "text": "\n\n @378\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61RL5K5yejL._SX466_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Tools"
     },
     {
      "type": "plain",
      "text": " & Home Improvement\n\n                Home & Kitchen \n                Home Storage & Organisation \n                Bathroom Accessories & Organization \nPryce378 \nDicos62 \n\n❌Regular Price  : ₹  999   /- 😱"
     }
    ]
   },
   {
    "id": 20630,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683257974",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "998",
    "dicos": "82",
    "catagory":"Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Boult Audio Z20 True Wireless in Ear Earbuds with Zen ENC Mic, 40H Playtime, Type-C Fast Charging, Made in India, 10mm Rich Bass Drivers, Environmental Noise Cancellation, IPX5 Ear Buds TWS (Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NJUD7A"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NJUD7A"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NHEorQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NHEorQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NFqsyk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NFqsyk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NFqsyk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NCaLbn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HCFu4f"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44txeNN"
     },
     {
      "type": "plain",
      "text": "\n\n @998\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71ZB8xdkpcL._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Headphones, Earbuds & Accessories \n                Headphones \nPryce998 \nDicos82 \n\n❌Regular Price  : ₹  3999   /- 😱"
     }
    ]
   },
   {
    "id": 20631,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683258071",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "639",
    "dicos": "36",
    "catagory":"Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Zebronics Zeb-Companion 107 USB Wireless Keyboard and Mouse Set with Nano Receiver (Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nrJaPw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/428h3nx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/428h3nx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/428h3nx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/428h3nx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42lMXwm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42lMXwm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42lMXwm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42lMXwm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ATTtyK"
     },
     {
      "type": "plain",
      "text": "\n\n @639\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61OQT49xFjS._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Computers & Accessories \n                Accessories & Peripherals \n                Keyboards, Mice & Input Devices \nPryce639 \nDicos36 \n\n❌Regular Price  : ₹  999   /- 😱"
     }
    ]
   },
   {
    "id": 20632,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683258167",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "497",
    "dicos": "50",
    "catagory":"Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Zebronics ZEB-COUNTY 3W Wireless Bluetooth Portable Speaker With Supporting Carry Handle, USB, SD Card, AUX, FM & Call Function. (Green)✅\n"
     },
     {
      "type": "link",
      "text": "https://www.amazon.in/dp/B07YNTJ8ZM?th=1&amp;linkCode=sl1&amp;tag=avinashbmv07-21&amp;linkId=6e1e65840fc28f212104c0c3d2f32196&amp;language=en_IN&amp;ref_=as_li_ss_tl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://www.amazon.in/dp/B07YNTJ8ZM?th=1&amp;linkCode=sl1&amp;tag=avinashbmv07-21&amp;linkId=6e1e65840fc28f212104c0c3d2f32196&amp;language=en_IN&amp;ref_=as_li_ss_tl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AX6E1Z"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HIMGM2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nvaOuO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nviHjQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nviHjQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VCsq4M"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ntYxqD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ntYxqD"
     },
     {
      "type": "plain",
      "text": "\n\n @497\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/81U3kN9bf1L._SY450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Home Audio \n                Speakers \nPryce497 \nDicos50 \n\n❌Regular Price  : ₹  899   /- 😱"
     }
    ]
   },
   {
    "id": 20633,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683258263",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "2087",
    "dicos": "46",
    "catagory":"Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Crucial BX500 500GB 2.5-inch SATA 3D NAND Internal SSD Upto 550 MB/s✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LVcRSe"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41fz4iA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41fz4iA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LXxw8c"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LXxw8c"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LXxw8c"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LXxw8c"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pdoxXJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ntYScT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42a2Xlu"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@2087"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61AivAClY9L._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Computers & Accessories \n                Components \n                 Internal Solid State Drives \nPryce2087 \nDicos46 \n\n❌Regular Price  : ₹  3499  /- 😱"
     }
    ]
   },
   {
    "id": 20634,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683258346",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "43990",
    "dicos": "27",
    "catagory":"Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Sony Bravia 108 cm (43 inches) 4K Ultra HD Smart LED Google TV KD-43X64L (Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VxThii"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VDp2GO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nvb0dw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NM0p8Z"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AZGonH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44tzKnc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nxMCbe"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HDrHu8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AYPtgk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nviYTU"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@43990"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71Is5PjYsnL._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Home Theater, TV & Video \n                Televisions \nPryce43990 \nDicos27 \n\n❌Regular Price  : ₹  54999  /- 😱"
     }
    ]
   },
   {
    "id": 20635,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683258440",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "2699",
    "dicos": "46",
    "catagory":"All",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Certified Refurbished Fire TV Stick 4K with Alexa Voice Remote | Stream in 4K resolution✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42ofDVy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p6yar3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42nxMTu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42nxMTu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42nxMTu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42nxMTu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VCsQbm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VCsQbm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42dzuaw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42dzw28"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@2699"
     },
     {
      "type": "plain",
      "text": "\n \n "
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/41eBfCzVRgL._SY300_SX300_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#All"
     },
     {
      "type": "plain",
      "text": "\n\nPryce2699 \nDicos46 \n\n❌Regular Price  : ₹  4499   /- 😱"
     }
    ]
   },
   {
    "id": 20636,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683258532",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "599",
    "dicos": "62",
    "catagory":"Watches",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Maxima Analog Black Dial Men's Watch - O-45842PPGW✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VBnOLT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AXHAb8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LASqsr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VBhm7Q"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AU4jVB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AXnqy8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VGuTLu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/4197vHB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pdDnxu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3I4ivPV"
     },
     {
      "type": "plain",
      "text": "\n\n @599\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/81oV59rj9aL._UX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Watches"
     },
     {
      "type": "plain",
      "text": "\n\n                Fashion \n                Onam 2021 \n                Watches and fashion jewellery \nPryce599 \nDicos62 \n\n❌Regular Price  : ₹   1299  /- 😱"
     }
    ]
   },
   {
    "id": 20637,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683258613",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "229",
    "dicos": "77",
    "catagory":"Clothing",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   USA Fantasy Women's Regular Fit Top✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pezfgs"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/427hluQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LXxZHu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LY7kKU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/426RfIm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LX2AoE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LC60vz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pfRCBF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LUL0BN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LUL0BN"
     },
     {
      "type": "plain",
      "text": "\n\n @229\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/81FVszSD1uL._UX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Clothing"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Clothing & Accessories \n                Women \n                Western Wear \nPryce229 \nDicos77 \n\n❌Regular Price  : ₹   899 /- 😱"
     }
    ]
   },
   {
    "id": 20638,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683258701",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "74490",
    "dicos": "20",
    "catagory":"Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   ASUS Vivobook 15 OLED (2023), Intel Core i5-13500H 13th Gen, 15.6\" (39.62 cm) FHD OLED, Thin & Light Laptop (16GB/512GB SSD/Intel Iris Xᵉ/Win 11/Office 2021/Backlit KB/Silver/1.7 kg), X1505VA-LK542WS✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AXnzSc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nxdXuc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HHKxAx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NEukzA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NEukzA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NJVR2G"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NJVR2G"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NGkygl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42tkkgG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42tkkgG"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@74490"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71Xq2PQIT1L._SY450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Computers & Accessories \n                Laptops \n                 Traditional Laptops \nPryce74490 \nDicos20 \n\n❌Regular Price  : ₹  89999   /- 😱"
     }
    ]
   },
   {
    "id": 20639,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683258787",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1329",
    "dicos": "78",
    "catagory":"Clothing",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   hangup Men Blazer✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NGZyGC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44Jcsdh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42p5TKS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NGEq2Z"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NAAFMC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3paLH0W"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pdDBVm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44xVYVe"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44xVZsg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44xVZsg"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1329"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/91mwTMc1GLL._UX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Clothing"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Clothing & Accessories \n                Men \n                Suits & Blazers \nPryce1329 \nDicos78 \n\n❌Regular Price  : ₹   4999  /- 😱"
     }
    ]
   },
   {
    "id": 20640,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683258879",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "41490",
    "dicos": "42",
    "catagory":"Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   LG 1.5 Ton 4 Star AI DUAL Inverter Split AC (Copper, Super Convertible 6-in-1 Cooling, HD Filter with Anti-Virus Protection, 2023 Model, RS-Q19ENYE1, White)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/427EvB9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nouJf6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nouJf6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44zCFee"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VHf4UH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VHf4UH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AYMIfg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nyJagy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/428G0Pw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nCjPCf"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@41490"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51JFb7FctDL._SX466_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Heating, Cooling & Air Quality \n                Air Conditioners \nPryce41490 \nDicos42 \n\n❌Regular Price  : ₹  59999   /- 😱"
     }
    ]
   },
   {
    "id": 20641,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683258976",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1228",
    "dicos": "60",
    "catagory":"Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Crucial BX500 240GB 3D NAND SATA 6.35 cm (2.5-inch) SSD (CT240BX500SSD1)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LZOWBn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LyA8Iw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AXhfKo"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LATlsT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LATlsT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LATlsT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HJ6xuO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HGsFFT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LDkuLK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M02NYs"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1228"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51LbXCEndSL._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Computers & Accessories \n                Components \n                 Internal Solid State Drives \nPryce1228 \nDicos60 \n\n❌Regular Price  : ₹  2499   /- 😱"
     }
    ]
   },
   {
    "id": 20642,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683259062",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1309",
    "dicos": "27",
    "catagory":"Clothing",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Puma Unisex-Kid Trackpants✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/4164XKm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41YmBAR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/427EIEr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/4255MEd"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/4192YF9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/415sg72"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/428qSla"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/4287WTB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/416d2OT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/416d2OT"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1309"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/81b8Iuwk3nS._UY741_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Clothing"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Clothing & Accessories \n                Boys \n                Sportswear \nPryce1309 \nDicos27 \n\n❌Regular Price  : ₹  1599   /- 😱"
     }
    ]
   },
   {
    "id": 20643,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683259172",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "339",
    "dicos": "32",
    "catagory":"Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Philips 9-Watts Multipack B22 LED Cool Day White LED Bulb, Pack of 4, (Ace Saver)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AW6OXj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AW6OXj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HGpkqv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HGpkqv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nAOTlZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nAdojh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NHoL3x"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VxVrOO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LWwh9j"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nxv92B"
     },
     {
      "type": "plain",
      "text": "\n\n @339\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/714h9p+w6DL._SX466_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Indoor Lighting \n                Light Bulbs \nPryce339 \nDicos32 \n\n❌Regular Price  : ₹  500   /- 😱"
     }
    ]
   },
   {
    "id": 20644,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683259263",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "99",
    "dicos": "82",
    "catagory":"Clothing",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   612 League Baby Boys' Plain Regular Fit T-Shirt✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42rMU2g"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42oaLQj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NH0gng"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NGx9jB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ND3nMT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42ogVjm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42ogVjm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42ogVjm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pdnBTn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42tr7Hd"
     },
     {
      "type": "plain",
      "text": "\n\n @99\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/A1p1T5KNUsL._UX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Clothing"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Clothing & Accessories \n                Baby \n                Baby Boys \nPryce99 \nDicos82 \n\n❌Regular Price  : ₹  545   /- 😱"
     }
    ]
   },
   {
    "id": 20645,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683259355",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "679",
    "dicos": "83",
    "catagory":"Clothing",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   new balance Women T-Shirt✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3paMsqO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44tBtsG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44tBtsG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44tBtsG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LATNHB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VBizfo"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VBizfo"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LA6ySW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HGpFJN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lssq2g"
     },
     {
      "type": "plain",
      "text": "\n\n @679\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/81nT3MBpxjS._UX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Clothing"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Clothing & Accessories \n                Women \n                Sportswear \nPryce679 \nDicos83 \n\n❌Regular Price  : ₹  2999 /- 😱"
     }
    ]
   },
   {
    "id": 20646,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683259461",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "799",
    "dicos": "62",
    "catagory":"Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Lenovo Legion M200 RGB Gaming Wired USB Mouse, Ambidextrous, 5-Buttons, Upto 2400 DPI with 4 Levels DPI Switch, 7-Colour RGB Backlight (GX30P93886)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B2ZPf7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/416RJwI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/416RJwI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3peAUTe"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LXj93H"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/412n2IZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/412n2IZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/415sN92"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44od3AG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44od3AG"
     },
     {
      "type": "plain",
      "text": "\n\n @799\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61ROx1hBZyL._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Computers & Accessories \n                Accessories & Peripherals \n                PC Gaming Peripherals \nPryce799 \nDicos62 \n\n❌Regular Price  : ₹   1599  /- 😱"
     }
    ]
   },
   {
    "id": 20647,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683259576",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1599",
    "dicos": "30",
    "catagory":"Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   OnePlus Bullets Z2 Bluetooth Wireless in Ear Earphones with Mic, Bombastic Bass - 12.4 Mm Drivers, 10 Mins Charge - 20 Hrs Music, 30 Hrs Battery Life (Acoustic Red)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44yats4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VHfVon"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VHfVon"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VHfVon"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HHHKHh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HHHKHh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HHHKHh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AXph64"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AW8nob"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42poyWF"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1599"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51RP1QMh-mL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Headphones, Earbuds & Accessories \n                Headphones \nPryce1599 \nDicos30 \n\n❌Regular Price  : ₹  1999  /- 😱"
     }
    ]
   },
   {
    "id": 20648,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683259684",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "898",
    "dicos": "53",
    "catagory":"Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Zebronics Jet PRO Premium Wired Gaming On Ear Headphone with LED for Headband + earcups, 40mm Neodymium Drivers, 2 Meter Braided Cable, with mic, Suspension Design, 3.5mm + USB Connector (Black, Blue)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42lH2r2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42lH2r2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VxVSc2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42sYMkm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42sYMkm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LZgMh8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42qgYv1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42qgYv1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42qgYv1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42tlUz8"
     },
     {
      "type": "plain",
      "text": "\n\n @898\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71VR6c3j2bL._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Computers & Accessories \n                Accessories & Peripherals \n                PC Gaming Peripherals \nPryce898 \nDicos53 \n\n❌Regular Price  : ₹   1699  /- 😱"
     }
    ]
   },
   {
    "id": 20649,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683259792",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "399",
    "dicos": "80",
    "catagory":"Health",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀Ant Esports Flora Bud Digital Weighing Scale, Highly Accurate Digital Bathroom Body Scale, Precisely Measures Weight up to 180Kg   ✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44utDis"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44utDis"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LDldws"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p5GEyB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p5GEyB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LCH2fx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44JdFkP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HJ7DXs"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VyjYDg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VyjYDg"
     },
     {
      "type": "plain",
      "text": "\n\n @399\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61qojyZCmSL._SY450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Health"
     },
     {
      "type": "plain",
      "text": " & Personal Care\n\n                Health & Personal Care \n                Home Medical Supplies & Equipment \n                Health Monitors \nPryce399 \nDicos80 \n \n❌Regular Price  : ₹ 999   /- 😱"
     }
    ]
   },
   {
    "id": 20650,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683259865",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "2899",
    "dicos": "61",
    "catagory":"Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀Amazon Basics 1300 W Air Fryer | 3.5 Litre Non Stick Basket with Metallic Interior| Timer Selection And Fully Adjustable Temperature Control   ✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HLi8t9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VDrnBA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nuakVT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nu0H9J"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nu0H9J"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ATW1Nk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nuao85"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AUm07c"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pegpWK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/4166ekC"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@2899"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/81Y+y5ld2jL._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Home Appliances \n                Small Kitchen Appliances \nPryce2899 \nDicos61 \n\n❌Regular Price  : ₹ 6,999   /- 😱"
     }
    ]
   },
   {
    "id": 20651,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683259940",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "399",
    "dicos": "60",
    "catagory":"Clothing",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀\n"
     },
     {
      "type": "link",
      "text": "https://www.amazon.in/dp/B07CQMYL95?th=1&amp;psc=1&amp;linkCode=sl1&amp;tag=avinashbmv07-21&amp;linkId=a1ae36b0bc6408dc12c16c1110302507&amp;language=en_IN&amp;ref_=as_li_ss_tl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44qMXNH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3peBzUI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41fCVMA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/427oTNY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/416dXij"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/411Ai0A"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44tzV1R"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44yBZp6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nvcSTA"
     },
     {
      "type": "plain",
      "text": "\n\n @399\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71X7pa5hBTL._UX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Clothing"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Clothing & Accessories \n                Men \n                T-Shirts & Polos \nPryce399 \nDicos60"
     }
    ]
   },
   {
    "id": 20652,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683260014",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "3620",
    "dicos": "20",
    "catagory":"Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Crompton Ginie Neo Personal Air Cooler - 10L @ ₹2,079🔗 \n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44v40xQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44v40xQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HDtz68"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nxP5m0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AWX9zK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AV41O3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NBMrGw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NGtJxB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AV7D2v"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nrfAJZ"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@3620"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51+085ENXjL._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Heating, Cooling & Air Quality \n                Air Coolers \nPryce3620 \nDicos20"
     }
    ]
   },
   {
    "id": 20653,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683260086",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1599",
    "dicos": "43",
    "catagory":"Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀\n😱\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NHNQvc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42tqqxG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HGNzoo"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NFcE72"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42p7hgy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NGml53"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42qhkSn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p1XyhC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42vGLlH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42ts2HF"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1599"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51Zb-9uH9IL._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Heating, Cooling & Air Quality \n                Fans \nPryce1599 \nDicos43"
     }
    ]
   },
   {
    "id": 20654,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683260156",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1599",
    "dicos": "43",
    "catagory":"Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀\n😱\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VykktA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42dBBuY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lstcwc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44yC8c8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44xgapX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44pUNah"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LC7CW9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nrM1rI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AW8d01"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LyBMda"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1599"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61rGoIkuXlL._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Heating, Cooling & Air Quality \n                Fans \nPryce1599 \nDicos43"
     }
    ]
   },
   {
    "id": 20655,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683260223",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "3999",
    "dicos": "77",
    "catagory":"Clothing",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   kamiliant by American TouristerHarrier Spinner Polypropylene (PP) 2 Pc Set Crimson Red Hard Luggage Small & Medium Sets (55Cm + 68 Cm) ✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VBpRj3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LDqHHK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HGoOZJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HJ89om"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HJ89om"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42oc7un"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nyL150"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HGo1b4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LZQJ9z"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LTqbGN"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@3999"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71hGsT190jL._UX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Clothing"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Bags, Wallets and Luggage \n                Luggage \n                 Suitcases & Trolley Bags \nPryce3999 \nDicos77 \n\n❌Regular Price  : ₹   9999  /- 😱"
     }
    ]
   },
   {
    "id": 20656,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683260297",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1398",
    "dicos": "50",
    "catagory":"Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Sony WI-C100 Wireless Headphones with Customizable Equalizer for Deep Bass & 25 Hrs Battery, DSEE-Upscale, Splash Proof, 360RA, Fast Pair, in-Ear Bluetooth Headset with mic for Phone Calls (Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LXAQQL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41c5jiq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nA4kLm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/4126RM1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/411AsFe"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41c5gDg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/419a5NN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VwUBlu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VwUBlu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41n0xip"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1398"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61dFmUtonHL._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Headphones, Earbuds & Accessories \n                Headphones \nPryce1398 \nDicos50 \n\n❌Regular Price  : ₹  2499  /- 😱"
     }
    ]
   },
   {
    "id": 20657,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683260366",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "401",
    "dicos": "50",
    "catagory":"Beauty",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Fogg Oud Extreme Perfume, Long-Lasting Perfume, Eau De Parfum For Men, 100ml✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41al6hL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/426TA66"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AU6Pev"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/419GGTo"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pbWibW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HGu7Il"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HGu8Mp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44zrVfR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44tM8Ub"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41d8XbH"
     },
     {
      "type": "plain",
      "text": "\n\n @401\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/81h6BivA05L._SY450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Beauty"
     },
     {
      "type": "plain",
      "text": "\n\n                Beauty \n                Fragrance \n                 Perfume \nPryce401 \nDicos50 \n\n❌Regular Price  : ₹   799   /- 😱"
     }
    ]
   },
   {
    "id": 20658,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683260429",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1499",
    "dicos": "80",
    "catagory":"Watches",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Maxima Nitro 1.39\" Ultra HD One Tap Connection Bluetooth Calling Smartwatch, Premium Metallic Design, AI Voice Assistant, 600 Nits Display, HR & SpO2 Monitor,100+ Excercise Modes, Inbuilt Games✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HHdGM4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VxWIFI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NCdGAR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NGmQvX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NACxVE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/427HauD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42JOs83"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42JOs83"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NGmUfb"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42vzemZ"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1499"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61tUKGRBllL._UX466_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Watches"
     },
     {
      "type": "plain",
      "text": "\n\n                Fashion \n                Onam 2021 \n                Watches and fashion jewellery \nPryce1499 \nDicos80 \n\n❌Regular Price  : ₹  4999  /- 😱"
     }
    ]
   },
   {
    "id": 20659,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683260497",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "289",
    "dicos": "59",
    "catagory":"Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Amazon Basics - 15W LED Bulb, Cool White (Base B22, Pack of 2)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AWXLp2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vy4n6I"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42rOP6Y"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HGojPc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/427Heun"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HGO6GU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42ocmFN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HIW3LU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HEUkqI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HEUkqI"
     },
     {
      "type": "plain",
      "text": "\n\n @289\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51JrKkV39sL._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Indoor Lighting \n                Light Bulbs \nPryce289 \nDicos59 \n\n❌Regular Price  : ₹   699  /- 😱"
     }
    ]
   },
   {
    "id": 20660,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683260565",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "548",
    "dicos": "70",
    "catagory":"Health",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Lifelong Beard Trimmer for Men | Quick Charge (2 Hours) | Runtime: 60 mins | 20 Length Settings | Cordless | USB Charging | 1 year warranty (LLPCM07)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LstOlu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/423VS5S"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LXBiyr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pbWCra"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/427HpG3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LYkEPg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/425s8pq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LDrfNO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LDrfNO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AUALar"
     },
     {
      "type": "plain",
      "text": "\n\n @548\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51IOw6+eT7L._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Health"
     },
     {
      "type": "plain",
      "text": " & Personal Care\n\n                Health & Personal Care \n                Personal Care \n                 Shaving, Waxing & Beard Care \nPryce548 \nDicos70 \n\n❌Regular Price  : ₹     /- 😱"
     }
    ]
   },
   {
    "id": 20661,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683260637",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "298",
    "dicos": "70",
    "catagory":"Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   boAt Bass Heads 225 in-Ear Wired Headphones with Mic (Forest Green)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NYrWEj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NHINuS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3I4kXWD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lv8Sui"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nxPZio"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HH7a88"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LVe1fB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HGpjTB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HIAg77"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LX3B05"
     },
     {
      "type": "plain",
      "text": "\n\n @298\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61sGfgrAaCL._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Headphones, Earbuds & Accessories \n                Headphones \nPryce298 \nDicos70 \n\n❌Regular Price  : ₹ 899     /- 😱"
     }
    ]
   },
   {
    "id": 20662,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683260705",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "669",
    "dicos": "83",
    "catagory":"Clothing",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   new balance Women's Relaxed Track Pants✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p0LGfN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LYdCdt"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nubzEx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LXBuxF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LX1kC1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pczpoP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41fEn1u"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/415KN2X"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/415KNzZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3peCFzO"
     },
     {
      "type": "plain",
      "text": "\n\n @669\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/712ttw8YhOS._UX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Clothing"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Clothing & Accessories \n                Women \n                Sportswear \nPryce669 \nDicos83 \n❌Regular Price  : ₹ 2999   /- 😱"
     }
    ]
   },
   {
    "id": 20663,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683260774",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "999",
    "dicos": "NaN",
    "catagory":"Clothing",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Karmic Vision Women TOP✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41bCORU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AX9KD9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/4257vtf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AXtfeQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44xh4Tn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nv5Kqf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AWbzQJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AZJpV3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/427AUTQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/427AUTQ"
     },
     {
      "type": "plain",
      "text": "\n\n @999\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61VJd5A-UiL._UY741_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Clothing"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Clothing & Accessories \n                Women \n                Western Wear \nPryce999 \nDicosNaN"
     }
    ]
   },
   {
    "id": 20664,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683260848",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "5999",
    "dicos": "40",
    "catagory":"Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   PHILIPS Air Fryer - India’s No.1 Air Fryer Brand, with Rapid Air Technology, Uses up to 90% Less Fat, 1425W, 4.1 Liter, (Grey) (HD9216/43)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nvm8XM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VzjmgK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NFdeSg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/415LcT1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/418dxrK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NHIRuC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/418dzzS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/418dzzS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/418dzzS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NFUemw"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@5999"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/31QjuWxofrS._SX300_SY300_QL70_FMwebp_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Home Appliances \n                Small Kitchen Appliances \nPryce5999 \nDicos40 \n\n❌Regular Price  : ₹ 8999    /- 😱"
     }
    ]
   },
   {
    "id": 20665,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683260919",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "449",
    "dicos": "75",
    "catagory":"Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Redgear Cloak Wired RGB Wired Over Ear Gaming Headphones with Mic for PC✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41YoVb3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p6BCC1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42lQlHA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42lQlHA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42uBBGD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42ocSnd"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LxMe4x"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42lIZ6Q"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42vHN13"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pdpHmd"
     },
     {
      "type": "plain",
      "text": "\n\n @449\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61O0rXhhP6L._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Computers & Accessories \n                Accessories & Peripherals \n                PC Gaming Peripherals \nPryce449 \nDicos75 \n\n❌Regular Price  : ₹ 1299   /- 😱"
     }
    ]
   },
   {
    "id": 20666,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683261001",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "5499",
    "dicos": "63",
    "catagory":"Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Blaupunkt Germany's SBW200 160 Watt 2.1 Soundbar with 8 Inch(20cm) Subwoofer, HDMI-ARC, AUX, Bluetooth and Remote Control✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LA41rQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vy4Vti"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pasb4F"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/428l4bB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42dCSlK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pczOYn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HHnd5A"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44wSx0P"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44wSx0P"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vx2zuX"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@5499"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61MDpAvYnUS._SY450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Home Audio \n                Speakers \nPryce5499 \nDicos63 \n\n❌Regular Price  : ₹ 11599   /- 😱"
     }
    ]
   },
   {
    "id": 20667,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683261087",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "754",
    "dicos": "89",
    "catagory":"Clothing",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   PURVAJA Women's Jacquard Semi-Stitched Lehenga choli (Trisha)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LWNrDI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NH2AdY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NH2AdY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3noxzAM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3noxzAM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3noxzAM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42nBixa"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nwVCNU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44vs2ZG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LYaU7O"
     },
     {
      "type": "plain",
      "text": "\n\n @754\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/810JYbl34tL._UY741_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Clothing"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Clothing & Accessories \n                Women \n                Ethnic Wear \nPryce754 \nDicos89 \n\n❌Regular Price  : ₹ 3499   /- 😱"
     }
    ]
   },
   {
    "id": 20668,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683261167",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "12640",
    "dicos": "23",
    "catagory":"Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Livpure Bolt+ Copper with 80% Water Savings (HR Tech), Copper+RO+UF+Mineraliser+Smart TDS Adjuster, 7 L tank, 15 lph Water Purifier for home (Blue)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44vs9EA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AYAXoS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pdH6uY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42a7nZC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42myCQZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42adJbA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nuwjMg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AXuc6U"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AYPNMm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AYPNMm"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@12640"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/41s1NI12u0L._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Home Appliances \n                Water Purifiers & Accessories \nPryce12640 \nDicos23 \n\n❌Regular Price  : ₹  15999  /- 😱"
     }
    ]
   },
   {
    "id": 20669,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683261263",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "272",
    "dicos": "9",
    "catagory":"Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Syska 2 Feet 8Watt Straight Linear LED Tube Light  (White)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HFLF7w"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NOIZrY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NBt6Fr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42trWjm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NGzye7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42JPHEf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42qj7XB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42pqQFf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42icRBg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42srEti"
     },
     {
      "type": "plain",
      "text": "\n\n @272\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61xQgcGBE3L._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Indoor Lighting \n                 Tube Lights & Battens \nPryce272 \nDicos9 \n\n❌Regular Price  : ₹  299  /- 😱"
     }
    ]
   },
   {
    "id": 20670,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683261338",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "300",
    "dicos": "NaN",
    "catagory":"Grocery",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Cadbury Fuse Fit Chocolate Snack Bar with Almonds and Peanuts,40g Pack of 6✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42p1hV1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/428Jrpo"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pd4itj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pa4byv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LA4yKm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VwqOJH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/427BFMG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/424x51N"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44JfQ7Z"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LCINJF"
     },
     {
      "type": "plain",
      "text": "\n\n @300\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/81xq0Dd3T0L._SX679_PIbundle-6,TopRight,0,0_AA679SH20_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Grocery"
     },
     {
      "type": "plain",
      "text": " & Gourmet Foods\n\n                Grocery & Gourmet Foods \n                Snacks & Sweets \n                Snack Foods \nPryce300 \nDicosNaN \n\n❌Regular Price  : ₹  300  /- 😱"
     }
    ]
   },
   {
    "id": 20671,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683261440",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "199",
    "dicos": "60",
    "catagory":"Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Kuber Industries Multiuses Super Tidy Plastic Tray/Basket/Organizer- Pack of 3 (Brown) -46KM0567✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HHcrg2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HHcrg2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LZSe7H"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LZSe7H"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VBP8Kj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nxR3mo"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LZdnin"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HFLYzc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NExC5U"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HGsJph"
     },
     {
      "type": "plain",
      "text": "\n\n @199\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71PT0KLSkrL._SX466_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Home Storage & Organisation \n                Boxes, Baskets & Bins \nPryce199 \nDicos60 \n\n❌Regular Price  : ₹  499  /- 😱"
     }
    ]
   },
   {
    "id": 20672,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683261510",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "379",
    "dicos": "71",
    "catagory":"Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Bajaj 20W Cool Day Light LED Tubelight, Pack of 2, (830412-Pk2)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NJy7vB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LZSvaJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LZSvaJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LX6r56"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LZ64XC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42pXd6L"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LWIuuD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/415LZTZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/416U8aI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3phajow"
     },
     {
      "type": "plain",
      "text": "\n\n @379\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61zxor6WmWS._SX466_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Indoor Lighting \n                 Tube Lights & Battens \nPryce379 \nDicos71 \n\n❌Regular Price  : ₹ 1199   /- 😱"
     }
    ]
   },
   {
    "id": 20673,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683261582",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "179",
    "dicos": "78",
    "catagory":"Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Kadio Analog 20 cm X 20 cm Wall Clock (White, with Glass, Standard)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44xhPvH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42591LT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44tNONt"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/428lSgD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44pWN2h"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3paPIT4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VwdaGx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44ofX8y"
     },
     {
      "type": "plain",
      "text": "\n\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VBlDbo"
     },
     {
      "type": "plain",
      "text": "\n\n @179\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/41V8e2F75OL.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Home & Décor \n                Clocks \nPryce179 \nDicos78 \n\n❌Regular Price  : ₹ 699   /- 😱"
     }
    ]
   },
   {
    "id": 20674,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683261655",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "10999",
    "dicos": "56",
    "catagory":"Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Redmi 80 cm (32 inches) Android 11 Series HD Ready Smart LED TV | L32M6-RA/L32M7-RA (Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44tChxJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NFuzdK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nvnotY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nvnotY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NGPXiR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AXLTTQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HFiul7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NGAdw7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AV66tl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p6CGWx"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@10999"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71L-lTQnJiL._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Home Theater, TV & Video \n                Televisions \nPryce10999 \nDicos56 \n\n❌Regular Price  : ₹  19999  /- 😱"
     }
    ]
   },
   {
    "id": 20675,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683261727",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "999",
    "dicos": "78",
    "catagory":"Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   pTron Force X12S Bluetooth Calling Smartwatch, 1.85\" Full Touch HD Display, Functional Crown, Real Heart Rate Monitor, SpO2, WatchFaces, 5 Days Battery Life, Fitness Trackers & IP68 Waterproof (Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41782tx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42p1LKP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VxYlTQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42p1SpJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/425tjoQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VCxeqQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VBclfl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42ssaYg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/4258LfX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42uDrY3"
     },
     {
      "type": "plain",
      "text": "\n\n @999\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51Hp+NQ-xUL._SY450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Electronics \n                Wearable Technology \n                 Smart Watches \nPryce999 \nDicos78 \n\n❌Regular Price  : ₹ 2999   /- 😱"
     }
    ]
   },
   {
    "id": 20676,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683261800",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "147",
    "dicos": "57",
    "catagory":"Health",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Bombay Shaving Company Turmeric Shaving Foam,266 ml (33% Extra) with Turmeric & Sandalwood✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p8sPj4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LA9zme"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LAXgWD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lz2QJ1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LAX8GD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LIih1U"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44xZHSI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44xZHSI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44tCoJF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LyDP0Q"
     },
     {
      "type": "plain",
      "text": "\n\n @147\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61DGznY9aIL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Health"
     },
     {
      "type": "plain",
      "text": " & Personal Care\n\n                Health & Personal Care \n                Personal Care \n                Shaving, Waxing & Beard Care \nPryce147 \nDicos57 \n\n❌Regular Price  : ₹  345   /- 😱"
     }
    ]
   },
   {
    "id": 20677,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683261869",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1424",
    "dicos": "58",
    "catagory":"Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Lifelong Duos 500 Watt 2 Jar Mixer Grinder with 3 Speed Control and 1100 Watt Dry Non-Stick soleplate Iron Super Combo (Black, 1 Year Warranty)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AXkIso"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p9Vgxd"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HFiHEV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HGqQsP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HGwfjj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HHfzs8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LXCgL2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LWODaa"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LWzGoB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LXGjHm"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1424"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61whl+GwjiL._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Home Appliances \n                Small Kitchen Appliances \nPryce1424 \nDicos58 \n\n❌Regular Price  : ₹   2499 /- 😱"
     }
    ]
   },
   {
    "id": 20678,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683261933",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "299",
    "dicos": "90",
    "catagory":"Shoes",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Bagsy Malone Women's Vegan Leather Stylish Tote Bag Pack of 5 | Ladies Purse Handbag✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LWOLGG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LVhCv4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LXqQaf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LWA2LX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/4184eIx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3npAdWZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/416y1Bk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LUPU1F"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/413Tj2p"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41n2vzj"
     },
     {
      "type": "plain",
      "text": "\n\n @299\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71LyIpj3V+L._UY500_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Shoes"
     },
     {
      "type": "plain",
      "text": " & Handbags\n\n                Shoes & Handbags \n                Handbags, Purses & Clutches \n                Handbags \nPryce299 \nDicos90 \n\n❌Regular Price  : ₹  1999   /- 😱"
     }
    ]
   },
   {
    "id": 20679,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683262006",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "30999",
    "dicos": "64",
    "catagory":"Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   iFFALCON 147 cm (58 inches) 4K Ultra HD Smart LED Google TV iFF58U62 (Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42tt4n6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44wTJ4j"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VxZ3Am"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VwUjuR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pa4Qjt"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3paQb7M"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VwdGnX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VxYDdo"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VPfQ2d"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44u7FvX"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@30999"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/714OTuxxnfL._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Home Theater, TV & Video \n                Televisions \nPryce30999 \nDicos64 \n\n❌Regular Price  : ₹ 54999   /- 😱"
     }
    ]
   },
   {
    "id": 20680,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683262079",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "27900",
    "dicos": "18",
    "catagory":"All",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Apple 2021 10.2-inch (25.91 cm) iPad with A13 Bionic chip (Wi-Fi, 64GB) - Space Grey (9th Generation)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AWZFGc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VzElA8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3npACZv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VDLsaH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VDLsaH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nrideP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nrideP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nrOEtA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NFVWUY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nuxBXC"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@27900"
     },
     {
      "type": "plain",
      "text": "\n \n "
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61PnHlc0HCL._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#All"
     },
     {
      "type": "plain",
      "text": "\n\nPryce27900 \nDicos18 \n\n❌Regular Price  : ₹   31999 /- 😱"
     }
    ]
   },
   {
    "id": 20681,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683262146",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1599",
    "dicos": "30",
    "catagory":"Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   OnePlus Bullets Z2 Bluetooth Wireless in Ear Earphones with Mic, Bombastic Bass - 12.4 Mm Drivers, 10 Mins Charge - 20 Hrs Music, 30 Hrs Battery Life (Acoustic Red)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p5KaZP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VCxPsA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42r013z"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42p2gEH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42p2gEH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/428xwrH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/418bjIQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42JQJA7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/427CEwm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/419fXXk"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1599"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51RP1QMh-mL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Headphones, Earbuds & Accessories \n                Headphones \nPryce1599 \nDicos30 \n\n❌Regular Price  : ₹  2099    /- 😱"
     }
    ]
   },
   {
    "id": 20682,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683262215",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "5499",
    "dicos": "63",
    "catagory":"Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Blaupunkt Germany's SBW200 160 Watt 2.1 Soundbar with 8 Inch(20cm) Subwoofer, HDMI-ARC, AUX, Bluetooth and Remote Control✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/419cSqf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44sQj2t"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44sQj2t"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LDocFa"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ATymg6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3patzEp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44ogHKS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LElWxk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AZM4y1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LAax1Q"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@5499"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61MDpAvYnUS._SY450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Home Audio \n                Speakers \nPryce5499 \nDicos63 \n\n❌Regular Price  : ₹   9999  /- 😱"
     }
    ]
   },
   {
    "id": 20683,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683262302",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "5499",
    "dicos": "58",
    "catagory":"Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Bajaj New Shakti Neo 15L Vertical Storage Water Heater Geyser 4 Star BEE Rated Heater For Water Heating with Titanium Armour Swirl Flow Technology Glasslined Tank White 1 Yr Warranty Wall Mounting✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HIClzX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HIClzX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/4178Uyj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/4178Uyj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/4178Uyj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/411DqJS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pcBukD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pcBukD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NFvNFS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NFvNFS"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@5499"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51-cyGLC3kL._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Heating, Cooling & Air Quality \n                Water Heaters & Geysers \nPryce5499 \nDicos58 \n\n❌Regular Price  : ₹  9999   /- 😱"
     }
    ]
   },
   {
    "id": 20684,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683262405",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "279",
    "dicos": "60",
    "catagory":"Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Kratos Champ Powerful BASS, Wired Ear Phones with Mic, One Button Multi Function Remote, HD Dynamic Sound Compatible with All Devices|Stereo Sound Buds - White✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AYUEgg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44wULgH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44wULgH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44oh4oK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44oh4oK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44uEEQR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44uEEQR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NBuTu9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NBuTu9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AYUNQQ"
     },
     {
      "type": "plain",
      "text": "\n\n @279\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/41uNNPqTXSL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Headphones, Earbuds & Accessories \n                Headphones \nPryce279 \nDicos60 \n\n❌Regular Price  : ₹  699   /- 😱"
     }
    ]
   },
   {
    "id": 20685,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683262490",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "49989",
    "dicos": "31",
    "catagory":"Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   ASUS TUF Gaming A15, 15.6-inch (39.62 cms) FHD 144Hz, AMD Ryzen 5 4600H, 4GB NVIDIA GeForce GTX 1650, Gaming Laptop (8GB/512GB SSD/Windows 11/Black/2.3 Kg), FA506IHRZ-HN111W✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NHRDsq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42qkyVZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VBQmoT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42o7AIm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42oeCwL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nv8cgr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AXMTY6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42uExmD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42lSVgK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/425uuEM"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@49989"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/91zVSkGGZbS._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Computers & Accessories \n                Laptops \n                 Traditional Laptops \nPryce49989 \nDicos31 \n\n❌Regular Price  : ₹ 59999   /- 😱"
     }
    ]
   },
   {
    "id": 20686,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683262568",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "199",
    "dicos": "72",
    "catagory":"Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Redgear A-10 Wired Gaming Mouse with RGB LED, Lightweight and Durable Design, DPI Upto 2400, Compatible with Windows and MAC.✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/427JVft"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vy6SWE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44uEN6R"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LzBMJN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LCDJFc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LEmbbI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nEOXB7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44JhJl5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nCohRt"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LCaJxj"
     },
     {
      "type": "plain",
      "text": "\n\n @199\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51dQYfrzFNL._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Computers & Accessories \n                Accessories & Peripherals \n                PC Gaming Peripherals \nPryce199 \nDicos72 \n\n❌Regular Price  : ₹  699   /- 😱"
     }
    ]
   },
   {
    "id": 20687,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683262649",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "189",
    "dicos": "75",
    "catagory":"Video",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Ant Esports GM40 Wired Optical Gaming Mouse with RGB LED, Lightweight and Ergonomic Design, DPI Upto 2400, Compatible with Windows and Mac✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/427Kfeb"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VHkuPx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HFjyW9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VBxDJT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VBxDJT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VBxDJT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pdsCeF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HJkEQB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LzC4QT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LzC4QT"
     },
     {
      "type": "plain",
      "text": "\n\n @189\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51eG5GY2IXL._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Video"
     },
     {
      "type": "plain",
      "text": " Games\n\n                Computers & Accessories \n                Accessories & Peripherals \n                PC Gaming Peripherals \nPryce189 \nDicos75 \n\n❌Regular Price  : ₹  699   /- 😱"
     }
    ]
   },
   {
    "id": 20688,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683262730",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "279",
    "dicos": "65",
    "catagory":"Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Ant Esports GM50 USB Optical Gaming Mouse✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nudUzj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nudUzj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nudUzj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nu4jbN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nu4jbN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LX6Kgp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/413Uij7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/4185viN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44yPsgI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LX6NJ7"
     },
     {
      "type": "plain",
      "text": "\n\n @279\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/418eDluo7RL._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Computers & Accessories \n                Accessories & Peripherals \n                PC Gaming Peripherals \nPryce279 \nDicos65 \n\n❌Regular Price  : ₹   699 /- 😱"
     }
    ]
   },
   {
    "id": 20689,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683262801",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "724",
    "dicos": "40",
    "catagory":"Shoes",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   LANCER Mens Hummer-610 Platform✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/415NGkj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NGBuDp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VGAykI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VGAzoM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VPgWel"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NINuVi"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/425aKRn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ND7AjF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AYRRUC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42psZAN"
     },
     {
      "type": "plain",
      "text": "\n\n @724\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/81YnGP+o56L._UY625_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Shoes"
     },
     {
      "type": "plain",
      "text": " & Handbags\n\n                Home & Kitchen \n                Indoor Lighting \n                Light Bulbs \nPryce724 \nDicos40 \n\n❌Regular Price  : ₹ 999   /- 😱"
     }
    ]
   },
   {
    "id": 20690,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683262887",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "408",
    "dicos": "81",
    "catagory":"Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   AmazonBasics Hypoallergenic Vinyl-Free Waterproof Mattress Protector, Single (38\" x 75\" - Suitable for Single Bed, White)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AYDyiC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AYDyiC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NK2jXs"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44yFUlO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NFWY3i"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NFWY3i"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NFWY3i"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NYuTVp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vyo4LE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VzFfN2"
     },
     {
      "type": "plain",
      "text": "\n\n @408\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/81UC1rdk7BL._SX522_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Home Furnishing \n                Bedding & Linen \nPryce408 \nDicos81 \n\n❌Regular Price  : ₹  1499    /- 😱"
     }
    ]
   },
   {
    "id": 20691,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683262966",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "3999",
    "dicos": "75",
    "catagory":"Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Samsung Galaxy Buds Live Bluetooth Truly Wireless in Ear Earbuds with Mic, Upto 21 Hours Playtime, Mystic Black✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3npBY6x"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44xjVf3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44ohNGu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42tpSrU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/427nabG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vzmb1k"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vzmb1k"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vzmidg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/416hPQn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VwVJ8F"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@3999"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61G5JoU9tTL._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Headphones, Earbuds & Accessories \n                Headphones \nPryce3999 \nDicos75 \n\n❌Regular Price  : ₹   9999  /- 😱"
     }
    ]
   },
   {
    "id": 20692,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683263050",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "7777",
    "dicos": "54",
    "catagory":"Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀VW 80 cm (32 inches) Frameless Series HD Ready Android Smart LED TV VW32S (Black)   👍*Apply ₹200 Coupon + Bank Offer.😱✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LCbdDD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LwI5xP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LwI78V"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://www.amazon.in/dp/B07MNNH484?th=1&amp;linkCode=sl1&amp;tag=avi1deals-21&amp;linkId=f685aff2739fc18c42c3eeea20f2af6b&amp;language=en_IN&amp;ref_=as_li_ss_tl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://www.amazon.in/dp/B07MNNH484?th=1&amp;linkCode=sl1&amp;tag=avi1deals-21&amp;linkId=f685aff2739fc18c42c3eeea20f2af6b&amp;language=en_IN&amp;ref_=as_li_ss_tl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/426Xw6S"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/424zbPd"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nxkuVK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nxkuVK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nzYxoQ"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@7777"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/81oHGrH1PwL._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Home Theater, TV & Video \n                Televisions \nPryce7777 \nDicos54 \n \n❌Regular Price  : ₹  9,999  /- 😱"
     }
    ]
   },
   {
    "id": 20693,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683263127",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "138",
    "dicos": "70",
    "catagory":"Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Wipro Safelite N10003 B22 0.5-Watt Led Night Lamp (Pack of 6, Yellow)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AVbMDB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/412rtDD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/415Oeqn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3peP1rD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HDxHmE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HDxHmE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HDxHmE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HCMRsr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3I4omon"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nAipbB"
     },
     {
      "type": "plain",
      "text": "\n\n @138\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/7146uaqe0cL._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Indoor Lighting \n                Specialty Lighting \nPryce138 \nDicos70 \n\n❌Regular Price  : ₹   399  /- 😱"
     }
    ]
   },
   {
    "id": 20694,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683263206",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1099",
    "dicos": "54",
    "catagory":"Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Amazon Brand - Solimo 650 Watt Drip Coffee Maker with Borosilicate Carafe✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pdvhVx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LWQRX4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VGAZeQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LTuB0l"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LTuBgR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LTuBgR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HJ0q9M"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HHqima"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VBtrcZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VBtrcZ"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1099"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/81lwV-pZGJL._SY606_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Home Appliances \n                Coffee, Tea & Espresso \nPryce1099 \nDicos54 \n\n❌Regular Price  : ₹   1999  /- 😱"
     }
    ]
   },
   {
    "id": 20695,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683263285",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "275",
    "dicos": "58",
    "catagory":"Beauty",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Paris Elysees DOLCE & SENSE Choco/Menthe Eau De Parfum for Women 60ml✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3I4ounR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NEzsnk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nvpyK6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AXx3Na"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NM872V"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NHSvNI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41YrWYV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ND88WL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/425AwVR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p39YFY"
     },
     {
      "type": "plain",
      "text": "\n\n @275\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71a8Ng6WvYL._SY450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Beauty"
     },
     {
      "type": "plain",
      "text": "\n\n                Beauty \n                Fragrance \n                 Eau de Parfum \nPryce275 \nDicos58 \n\n❌Regular Price  : ₹  650    /- 😱"
     }
    ]
   },
   {
    "id": 20696,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683263364",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "179",
    "dicos": "60",
    "catagory":"Beauty",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   WOW Skin Science Aloe Vera Multipurpose Beauty Gel for Skin and Hair, 250 ml✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/425vsAU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ND8eO7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42ofW2H"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NFxnYk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ND8gFJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42qQ06B"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p6sEVr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p6sEVr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44zvuCL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42uFPOv"
     },
     {
      "type": "plain",
      "text": "\n\n @179\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61sQzHjMk1L._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Beauty"
     },
     {
      "type": "plain",
      "text": "\n\n                Beauty \n                Skin Care \n                Face \nPryce179 \nDicos60 \n\n❌Regular Price  : ₹ 449   /- 😱"
     }
    ]
   },
   {
    "id": 20697,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683263444",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "109",
    "dicos": "56",
    "catagory":"Beauty",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Amazon Brand - Solimo Glycerine Bathing Bar (Pack of 3), 3 x 125g✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VBRl8z"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44yg1Tq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VBesjh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VBesjh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VzmTvw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B359PD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VwW8YJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LCL4Vd"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LCL4Vd"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p6F2ol"
     },
     {
      "type": "plain",
      "text": "\n\n @109\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71CtQihn5QL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Beauty"
     },
     {
      "type": "plain",
      "text": "\n\n                Beauty \n                Bath & Body \n                Cleansers \nPryce109 \nDicos56 \n\n❌Regular Price  : ₹ 249   /- 😱"
     }
    ]
   },
   {
    "id": 20698,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683263526",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "3799",
    "dicos": "36",
    "catagory":"Sports",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Leader Buddy 20T Kids Cycle for 5 to 9 Years Suitable for Boy and Girl Both - Sea Green/Light Pink✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AYSHRg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LVhPNV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3phcW9S"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LWBIVL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/415xkZ6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lz5e2r"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LYhf35"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LYhf35"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HHaHDq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LX7vWN"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@3799"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71Z6WKWWxBL._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Sports"
     },
     {
      "type": "plain",
      "text": ", Fitness & Outdoors\n\n                Sports, Fitness & Outdoors \n                Cycling \n                Kids' Cycles & Accessories \nPryce3799 \nDicos36 \n\n❌Regular Price  : ₹  4999   /- 😱"
     }
    ]
   },
   {
    "id": 20699,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683263605",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "2199",
    "dicos": "63",
    "catagory":"Toys",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Amazon Brand - Solimo Garden Slide✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/417auAf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41c9tXA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AXxlUg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AXxlUg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AXxlUg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/416X9ry"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AVcjW7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HHMRar"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HHMRar"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AVcsc7"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@2199"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71hKDIfuBGL._SX450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Toys"
     },
     {
      "type": "plain",
      "text": " & Games\n\n                Toys & Games \n                Sport & Outdoor \n                 Freestanding Slides \nPryce2199 \nDicos63 \n\n❌Regular Price  : ₹ 5499   /- 😱"
     }
    ]
   },
   {
    "id": 20700,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683263688",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "3599",
    "dicos": "69",
    "catagory":"Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Black + Decker Wdbd15 High Suction Wet & Dry Vacuum Cleaner & Blower With Hepa Filter & Reusable Dustbag Suitable For Household Use, 15-Litre 1400 Watt 16 Kpa, 1 Year Warranty (Red/Grey)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LDvxoo"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HFlfCZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HFlfCZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HGvO8P"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HGvO8P"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LVdJXa"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LVdJXa"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LVdJXa"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vx5GmD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VBost0"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@3599"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71gVZoMA93L._SX522_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Home Appliances \n                Vacuum, Cleaning & Ironing \nPryce3599 \nDicos69 \n\n❌Regular Price  : ₹  9999   /- 😱"
     }
    ]
   },
   {
    "id": 20701,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683263767",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "499",
    "dicos": "45",
    "catagory":"Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Amazon Brand - Solimo 1000-Watt Dry Iron (White and Blue)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41fIWsE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42aazV6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42aazV6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NGKys1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/426YmAy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/425Wubn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/427LwBZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44oixLM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44oixLM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42jE9HC"
     },
     {
      "type": "plain",
      "text": "\n\n @499\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71OUVFk+LdL._SX522_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Home Appliances \n                Vacuum, Cleaning & Ironing \nPryce499 \nDicos45 \n\n❌Regular Price  : ₹  899   /- 😱"
     }
    ]
   },
   {
    "id": 20702,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683263848",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1321",
    "dicos": "34",
    "catagory":"Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Macmerise Bling it On On-Ear Bluetooth Headphone with Upto 10 Hours Playback, FM Radio, SD Card, Soft Padded Ear Cushions and Passive Noise Isolation | Decibel Wireless Headphone✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pdw2On"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44tRPl0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42tr1zk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NHTmOq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42vDzqh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42pcfKe"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p3aZxM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VDwaD4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VDwaD4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VBoGjQ"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1321"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51lVmJcnMzL._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Headphones, Earbuds & Accessories \n                Headphones \nPryce1321 \nDicos34 \n\n❌Regular Price  : ₹  1899   /- 😱"
     }
    ]
   },
   {
    "id": 20703,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683263934",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "149",
    "dicos": "63",
    "catagory":"Beauty",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Body Cupid Citrus Love Body Butter with Pure Orange Essential oil, Cocoa and Shea Butter - 200 ml✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LsKf1m"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LsKf1m"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LsKf1m"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LYhJ9p"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nvhZTM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LXEVV2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LXEVV2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LEo6gq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LDLxa1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/415Paen"
     },
     {
      "type": "plain",
      "text": "\n\n @149\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71WTeysvFEL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Beauty"
     },
     {
      "type": "plain",
      "text": "\n\n                Beauty \n                Bath & Body \n                Body Washes \nPryce149 \nDicos63 \n\n❌Regular Price  : ₹  399   /- 😱"
     }
    ]
   },
   {
    "id": 20704,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683264011",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "39",
    "dicos": "95",
    "catagory":"Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   pTron Solero M241 2.4A Micro USB Data & Charging Cable, Made in India, 480Mbps Data Sync, Durable 1-Meter Long USB Cable for Micro USB Devices (White)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nzZtJS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nvqpui"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HHinFG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nvii0S"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HHNssH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41YsWMF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AXhDIB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LTvshz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AYTstA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AX2pDu"
     },
     {
      "type": "plain",
      "text": "\n\n @39\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/41R8uapbduL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Computers & Accessories \n                Accessories & Peripherals \n                Cables & Accessories \nPryce39 \nDicos95 \n\n❌Regular Price  : ₹  749   /- 😱"
     }
    ]
   },
   {
    "id": 20705,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683264078",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "489",
    "dicos": "51",
    "catagory":"Health",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Amazon Brand - Presto! Total Wash Detergent Powder, Twin (4 Kg + 4Kg) Pack✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HCMaiU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HCLutx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nAVlJJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HCMwGg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/413VO4N"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NFYgLG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LVizTd"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/411FnWI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3noBjlO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NOMHSq"
     },
     {
      "type": "plain",
      "text": "\n\n @489\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61NxstpiLJL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Health"
     },
     {
      "type": "plain",
      "text": " & Personal Care\n\n                Health & Personal Care \n                Household Supplies \n                Laundry \nPryce489 \nDicos51 \n\n❌Regular Price  : ₹  899  /- 😱"
     }
    ]
   },
   {
    "id": 20706,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683264145",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "235",
    "dicos": "31",
    "catagory":"Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Eveready Ultima AAA Alkaline Battery, Pack of 8✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LYpvQu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44v9998"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42o9Jno"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42vDRNT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VBul9n"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/415ygg4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LZhbjF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42r2lYl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42lN4rG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/413W2ZH"
     },
     {
      "type": "plain",
      "text": "\n\n @235\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/81byN3nBRZL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                General Purpose Batteries & Battery Chargers \n                 Disposable Batteries \nPryce235 \nDicos31 \n\n❌Regular Price  : ₹   349  /- 😱"
     }
    ]
   },
  //  {
  //   "id": 20707,
  //   "type": "message",
  //   "date": "2023-05-05",
  //   "date_unixtime": "1683264204",
  //   "from": "All1App daily",
  //   "from_id": "channel1858370886",
  //   "photo": "(File not included. Change data exporting settings to download.)",
  //   "pryce": 569,
  //   "dicos": 569,
    
  //   "text_entities": [
  //    {
  //     "type": "plain",
  //     "text": "🚀🚀   Bombay Shaving Company Sensitive Shaving Foam,266 ml (33% Extra) with Aloe Vera & Oats (Aloe Vera)✅\n"
  //    },
  //    {
  //     "type": "link",
  //     "text": "https://amzn.to/411FvWc"
  //    },
  //    {
  //     "type": "plain",
  //     "text": "\n"
  //    },
  //    {
  //     "type": "link",
  //     "text": "https://amzn.to/42abbtS"
  //    },
  //    {
  //     "type": "plain",
  //     "text": "\n"
  //    },
  //    {
  //     "type": "link",
  //     "text": "https://amzn.to/3AYWRs4"
  //    },
  //    {
  //     "type": "plain",
  //     "text": "\n"
  //    },
  //    {
  //     "type": "link",
  //     "text": "https://amzn.to/3pa7Igf"
  //    },
  //    {
  //     "type": "plain",
  //     "text": "\n"
  //    },
  //    {
  //     "type": "link",
  //     "text": "https://amzn.to/3pa7Igf"
  //    },
  //    {
  //     "type": "plain",
  //     "text": "\n"
  //    },
  //    {
  //     "type": "link",
  //     "text": "https://amzn.to/3pazppc"
  //    },
  //    {
  //     "type": "plain",
  //     "text": "\n"
  //    },
  //    {
  //     "type": "link",
  //     "text": "https://amzn.to/3p9LYRG"
  //    },
  //    {
  //     "type": "plain",
  //     "text": "\n"
  //    },
  //    {
  //     "type": "link",
  //     "text": "https://amzn.to/44sSzGZ"
  //    },
  //    {
  //     "type": "plain",
  //     "text": "\n\n❌Regular Price  : ₹  275   /- 😱"
  //    }
  //   ]
  //  },
   {
    "id": 20708,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683264273",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "599",
    "dicos": "76",
    "catagory":"Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   RAEGR Arc One 15W Type-C PD |Made in India |Qi-Certified Wireless Charger with Fireproof ABS for iPhone 14/13/12/11 Series, Galaxy S23/S22/S21/S20/Note20/10/10+ Series, OnePlus 9/9 Pro-Black RG10450✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nrR5fI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44A1PJp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NAHkGC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VGCeL2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nrlsD1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NHNqoK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NONbYK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VBSo8v"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NCOQR9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42lNaQ4"
     },
     {
      "type": "plain",
      "text": "\n\n @599\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/41lJFlnT1kL._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Mobiles & Accessories \n                Mobile Accessories \nPryce599 \nDicos76 \n\n❌Regular Price  : ₹  1999   /- 😱"
     }
    ]
   },
   {
    "id": 20709,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683264336",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "196",
    "dicos": "67",
    "catagory":"Beauty",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   The Man Company Talc Perfume for Men | Premium Luxury Long lasting Fragrance Spray | Eau De Perfume -30ml✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vx6vfd"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LwJuo5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42t5nLE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nFdXYW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nEQWW5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42uHm7d"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44xm1LX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p6FYsR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VBSrRJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LXFkH2"
     },
     {
      "type": "plain",
      "text": "\n\n @196\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51JCrmD7v+L._SY450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Beauty"
     },
     {
      "type": "plain",
      "text": "\n\n                Beauty \n                Make-up \n                Face \nPryce196 \nDicos67 \n\n❌Regular Price  : ₹  599  /- 😱"
     }
    ]
   },
   {
    "id": 20710,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683264399",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "899",
    "dicos": "82",
    "catagory":"Health",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Beurer HR 5000 Professional Cordless hair clipper for Hair and Beard Cut at home Black with 3 Years Warranty✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42ahRZ8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LEoHia"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LWLZ4f"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VBzLRT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/413W8k1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41bHEP4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AWeDfF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LwJFzL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LDM2kp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/4187K5H"
     },
     {
      "type": "plain",
      "text": "\n\n @899\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51jJRHh+3zL._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Health"
     },
     {
      "type": "plain",
      "text": " & Personal Care\n\n                Beauty \n                Hair Care \n                Hair Cutting Tools \nPryce899 \nDicos82 \n\n❌Regular Price  : ₹  3999   /- 😱"
     }
    ]
   },
   {
    "id": 20711,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683264464",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "34990",
    "dicos": "38",
    "catagory":"Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   ASUS Vivobook 14, Intel Core i3-1115G4 11th Gen, 14\" (35.56 cm) FHD, Thin and Light Laptop (8GB/512GB SSD/Office 2021/Windows 11 Home/Integrated Graphics/Fingerprint/Silver/1.6 kg), X415EA-EB322WS✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/416XUAU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/416B3FI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HEYTRS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/417ObKD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nviKMC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nxBMC1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AUTHFS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nug9Tf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AYFJmi"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AXic5b"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@34990"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71d058GzMXL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Computers & Accessories \n                Laptops \n                 Traditional Laptops \nPryce34990 \nDicos38 \n\n❌Regular Price  : ₹  49999  /- 😱"
     }
    ]
   },
   {
    "id": 20712,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683264528",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1120",
    "dicos": "68",
    "catagory":"Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Longway Creta 600mm High Speed 4 Blade Anti-Dust Ceiling Fan (Smoked Brown, Pack of 1)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HHRRMx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LVjzb4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HIVnpE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nx0brw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LZ9ppC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HFmj9X"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nyQY1Q"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NCOVUX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/425x2Tm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nxbKPn"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1120"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71qyMTz0m4L._SX466_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Heating, Cooling & Air Quality \n                Fans \nPryce1120 \nDicos68 \n\n❌Regular Price  : ₹  2999   /- 😱"
     }
    ]
   },
   {
    "id": 20713,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683264593",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1226",
    "dicos": "63",
    "catagory":"Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   PHILIPS 14 Watts Stellar Bright Curvy LED T-Bulb Base B22,Crystal White (Pack of 6)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NGLoVH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LVf3JC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44yS2TW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44A1Z3t"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42twqGI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pdwP1N"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44qSBzn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44y34ck"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/412cauV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41caVJw"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1226"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/6170JOt1ieL._SX466_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Indoor Lighting \n                Light Bulbs \nPryce1226 \nDicos63 \n\n❌Regular Price  : ₹  2499   /- 😱"
     }
    ]
   },
   {
    "id": 20714,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683264658",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "84990",
    "dicos": "28",
    "catagory":"Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   HP Envy x360 for Creators 12th Gen Intel Core i5-1235U 15.6inch(39.6 cm)FHD OLED 500 Nits Micro-Edge Display (16GB RAM/512GB SSD/B&O/NVIDIA GeForce RTX 2050/Win 11/Fast Charge/Pen/1.7 Kgs/15-ew0021TX)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HGuL91"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/428pEqj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42jF8aM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VzHe3W"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VzoiCi"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3paA8Xs"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LYiGhZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/425ckml"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44tHOnY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VDx8iG"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@84990"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/717hk1x74jL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Computers & Accessories \n                Laptops \n                 Traditional Laptops \nPryce84990 \nDicos28 \n\n❌Regular Price  : ₹ 99999   /- 😱"
     }
    ]
   },
   {
    "id": 20715,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683264721",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "399",
    "dicos": "73",
    "catagory":"Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Lenovo 15.6\" (39.62cm) Slim Everyday Backpack, Made in India, Compact, Water-resistant, Organized storage:Laptop sleeve,tablet pocket,front workstation,2-side pockets,Padded adjustable shoulder straps✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nxC3Vz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42ohTMz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42rUj1y"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44xmptT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42pvmnb"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44Jk3bN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p23JSQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pdLNVC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44tSV06"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/411G7Lu"
     },
     {
      "type": "plain",
      "text": "\n\n @399\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61v0-iGlR1L._SY741_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Computers & Accessories \n                Accessories & Peripherals \n                Laptop Accessories \nPryce399 \nDicos73 \n\n❌Regular Price  : ₹   1199  /- 😱"
     }
    ]
   },
   {
    "id": 20716,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683264786",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1999",
    "dicos": "43",
    "catagory":"Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   BM Ecom Portable Air Conditioner Fan, 500 ml Water Tank USB Personal Cooler, Mini Humidifier Fan with 7 Colors LED Light, 3 Timer, 3 Wind Speeds, 3 Spray Modes for Office, Home (White Pack of 1)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VwYhDL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nvrfqW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VHn7Rp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AXokdW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AVab0D"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LAdVtA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LvemVU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AYG74e"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AUcPE3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AWgZLz"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1999"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61ZwdM0AOtL._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Heating, Cooling & Air Quality \n                Air Conditioners \nPryce1999 \nDicos43 \n\n❌Regular Price  : ₹  3999  /- 😱"
     }
    ]
   },
   {
    "id": 20717,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683264849",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "230",
    "dicos": "88",
    "catagory":"Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   M.G.R.J® Tempered Glass Screen Protector for Lenovo Tab M8 HD / M8 (3rd Gen) / Smart Tab M8 / Tab M8 FHD (2019) (TB-8705F/N, TB-8505F/X)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AVeiK3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HHgVDo"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AUUu9O"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3BfzjzD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HHgJ78"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nyRAVc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AUsNxI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nxCvTL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AYUE02"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NFjhGs"
     },
     {
      "type": "plain",
      "text": "\n\n @230\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/613Iuh6MzgL._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Computers & Accessories \n                Accessories & Peripherals \n                Tablet Accessories \nPryce230 \nDicos88 \n\n❌Regular Price  : ₹ 899   /- 😱"
     }
    ]
   },
   {
    "id": 20718,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683264913",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "699",
    "dicos": "61",
    "catagory":"Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   D-Link DIR-615 Wi-fi Ethernet-N300 Single_band 300Mbps Router, Mobile App Support, Router | AP | Repeater | Client Modes(Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LX6Gx7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/425dyOp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HHSB4h"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NJCdnt"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3paAvRQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42acbhC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/419jBR0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41deOOd"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/419aJLh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HJob1n"
     },
     {
      "type": "plain",
      "text": "\n\n @699\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51B-8XSoCOL._SX466_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Computers & Accessories \n                Networking Devices \n                 Routers \nPryce699 \nDicos61 \n\n❌Regular Price  : ₹  1599   /- 😱"
     }
    ]
   },
   {
    "id": 20719,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683264975",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "629",
    "dicos": "55",
    "catagory":"Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Fun Homes Printed 4 Pieces Plastic Mug, Stool, Dustbin & Tub Set (White) (HS39FUNH023514)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42lO2nO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42r3GhP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42pdyZE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LX6UnX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3paAJIG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LA9fUu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/417cwQT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41YudDr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/427GpBY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/4241JrS"
     },
     {
      "type": "plain",
      "text": "\n\n @629\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61kLqEk+aEL._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Home Storage & Organisation \n                Bathroom Accessories & Organization \nPryce629 \nDicos55 \n\n❌Regular Price  : ₹  1199   /- 😱"
     }
    ]
   },
   {
    "id": 20720,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683265038",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "5999",
    "dicos": "54",
    "catagory":"Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Huami Amazfit GTS Smart Watch(Vermillion Orange)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44Ibpdw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p9Zdlx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HHjV2s"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M0aLRm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pfqgfi"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vy2DKW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44wYquX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nvrTVo"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LZnILc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LWTJ6i"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@5999"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61ST1KA4UzL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Electronics \n                Wearable Technology \n                 Smart Watches \nPryce5999 \nDicos54 \n\n❌Regular Price  : ₹  9999  /- 😱"
     }
    ]
   },
   {
    "id": 20721,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683265101",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "438",
    "dicos": "75",
    "catagory":"Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   archies Clocks Printed Clock Analog Table Clocks Desk TopSilent Non Ticking Quartz Movement Battery Opearted for Bedroom Living Room Office Home Décor Decoration✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42mDR37"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AX3MlC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AX3MlC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AX3MlC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44yucHQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LyI50k"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AWeNUh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NK5Bdg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LEpQ9s"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NFZJSc"
     },
     {
      "type": "plain",
      "text": "\n\n @438\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71BWUIGkGaL._SX466_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Home & Décor \n                Clocks \nPryce438 \nDicos75 \n\n❌Regular Price  : ₹  1199   /- 😱"
     }
    ]
   },
   {
    "id": 20722,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683265167",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "2199",
    "dicos": "51",
    "catagory":"Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Havells Capture 500 Watt Mixer Grinder with 3 Bigger size Stainless Steel Jar (Grey & Green) with 5 year Motor warranty✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3noCx0o"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LXbm64"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42lOiTO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p3co7w"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LXGIcI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p5NPH3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nAaRpm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NK65QC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3noCNwo"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42vN1db"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@2199"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51I6JVp8rjL._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Home Appliances \n                Small Kitchen Appliances \nPryce2199 \nDicos51 \n\n❌Regular Price  : ₹  3999   /- 😱"
     }
    ]
   },
   {
    "id": 20723,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683265208",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "229",
    "dicos": "65",
    "catagory":"Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   SYGA One to Three Data Cable Weaving 2.8A Fast Charge Three-in-One Mobile Phone Data Cable Three-Head Synchronous Charging Cable (Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nx14jQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42qocPF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42vFBGV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NJD0EX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NYxCOD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p0Rtlx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42p6kEX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NYxE9d"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NGAVd5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42aiYrM"
     },
     {
      "type": "plain",
      "text": "\n\n @229\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51zwi3rQkBL._SX466_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Mobiles & Accessories \n                 Mobile Accessories \nPryce229 \nDicos65 \n\n❌Regular Price  : ₹  650   /- 😱"
     }
    ]
   },
   {
    "id": 20724,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683265250",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "2399",
    "dicos": "70",
    "catagory":"Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   DeckUp Lexis Engineered Wood Bedside Table and End Table (Dark Wenge, Set of 2)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42qSCRX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41n6SKJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42vNwnz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/419h7SH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VBza2H"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VzI2pu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/428O9n4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vx7SKT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/425d4I9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AXjihj"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@2399"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/81SzjxulzWL._SX522_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Home & Kitchen \n                Furniture \n                Living Room Furniture \nPryce2399 \nDicos70 \n\n❌Regular Price  : ₹  6999  /- 😱"
     }
    ]
   },
   {
    "id": 20725,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683265314",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "4001",
    "dicos": "56",
    "catagory":"Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Lifelong LLOT27 27 Litres Oven, Toaster & Griller with Illuminated Chamber & Motorized Rotisserie | Crumb Tray | Auto Shut Off, OTG Oven for Baking Cake, Pizza, Grilling and Toasting at Home ✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AXp4Qg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pdvTdX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nA18PC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lz7OFF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HJoIQV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nAkMLx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LsAsbq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lz7WoI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HCPRVJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nAkYdJ"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@4001"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71N3Cs1-liL._SX466_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Home Appliances \n                Small Kitchen Appliances \nPryce4001 \nDicos56 \n\n❌Regular Price  : ₹   7499  /- 😱"
     }
    ]
   },
   {
    "id": 20726,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683265378",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "5999",
    "dicos": "54",
    "catagory":"Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Balzano Tornado Cordless Vacuum Cleaner, Vacuum Cleaner for Home, 2-in-1 Portable Car Vacuum Cleaner, Multi-Purpose & Ultra Lightweight, Powerful Suction to Clean in One-Go, Extra Long Battery (Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LyIwI0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NCjXwp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AWffBX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AWfkFL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nuhklD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/415RemD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AXjDk5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NHx6nR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NHOSrc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/419baVV"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@5999"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61wo9SD-bvL._SX522_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Home Appliances \n                Vacuum, Cleaning & Ironing \nPryce5999 \nDicos54 \n\n❌Regular Price  : ₹  8999   /- 😱"
     }
    ]
   },
   {
    "id": 20727,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683265440",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "489",
    "dicos": "51",
    "catagory":"Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   SanDisk Ultra Luxe USB 3.1 Flash Drive 32GB, Upto 150MB/s, All Metal, Metallic Silver✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HFQKwC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42dIM6o"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/419hlJx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42lWB22"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42dINY0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/427vTdI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42lWIL0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LVg6Jy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p3d4K6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/428zati"
     },
     {
      "type": "plain",
      "text": "\n\n @489\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/31wd4DeiJtL._SX300_SY300_QL70_FMwebp_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Computers & Accessories \n                External Devices & Data Storage \n                 Pen Drives \nPryce489 \nDicos51 \n\n❌Regular Price  : ₹  949   /- 😱"
     }
    ]
   },
   {
    "id": 20728,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683265507",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "279",
    "dicos": "72",
    "catagory":"Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Amazon Basics Hi-Speed 4 Port Ultra Slim USB 2.0 Hub for Laptops and Computers (Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41YuTIZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44wZ4bR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LALM5F"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42dJ11i"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/412uROR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pcFGAT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pdMNJm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3peocDY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LzG0kD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nxVFJe"
     },
     {
      "type": "plain",
      "text": "\n\n @279\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61WW6Ys7ElL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Computers & Accessories \n                Accessories & Peripherals \n                 USB Hubs \nPryce279 \nDicos72 \n\n❌Regular Price  : ₹  899  /- 😱"
     }
    ]
   },
   {
    "id": 20729,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683265572",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1499",
    "dicos": "63",
    "catagory":"Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Blaupunkt Newly Launched BTW300 BASS Buds Truly Wireless Bluetooth in-Ear Earbuds I Bass Demon Tech I ENC CRISPR TECH I 40Hrs Playtime I TurboVolt Charging I BT Version 5.3 I 80ms Low Latency (Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pcFLVd"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AWi2Lv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VBrnSu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NGVCW9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NFzStG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LXbYIU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LXI3jN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NMb9nP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LCHJ8G"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44uIxVX"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1499"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61hMY+LUjpL._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Headphones, Earbuds & Accessories \n                Headphones \nPryce1499 \nDicos63 \n\n❌Regular Price  : ₹  2999   /- 😱"
     }
    ]
   },
   {
    "id": 20730,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683265636",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "10499",
    "dicos": "51",
    "catagory":"Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Havells Fab Alkaline 7 Litre Absoulety safe, RO + UV Purified Water Purifier with 7 Stages and Alkaline Water Technology (White and Sky Blue)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LZot70"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/419bHHp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/419kxF0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42obIYS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p5OLex"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3paBykK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41cceIq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nESNu1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vy3SJY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AXguRt"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@10499"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/41A+iLlIxZL._SY550_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Home Appliances \n                Water Purifiers & Accessories \nPryce10499 \nDicos51 \n\n❌Regular Price  : ₹  16999  /- 😱"
     }
    ]
   },
   {
    "id": 20731,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683265679",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1499",
    "dicos": "55",
    "catagory":"Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Morphy Richards AT 200, 2-Slice Pop-Up Toaster with Dust Cover✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AU43FY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HCO1nx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nA1Lss"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LEqNyy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pdai5j"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HFnQwJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LVlwEq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AWfOvz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HFnJRP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NGFUdt"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1499"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61-F9Iqt5CL._SX466_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Home Appliances \n                Small Kitchen Appliances \nPryce1499 \nDicos55 \n\n❌Regular Price  : ₹  2999  /- 😱"
     }
    ]
   },
   {
    "id": 20732,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683265742",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "41490",
    "dicos": "41",
    "catagory":"Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Sony Bravia 108 cm (43 inches) 4K Ultra HD Smart LED Google TV KD-43X74K (Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AUtXJA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HCORkw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41dfV0l"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3peITQc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/417PNnF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NECKaa"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/412dvSt"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42ttxWi"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HJoTM1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42lXaZI"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@41490"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/81wxS8abrgL._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Home Theater, TV & Video \n                Televisions \nPryce41490 \nDicos41 \n\n❌Regular Price  : ₹  59999   /- 😱"
     }
    ]
   },
   {
    "id": 20733,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683265809",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "549",
    "dicos": "63",
    "catagory":"Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Pigeon Inox Hydra 900 ml Sipper (Pack of 3, Black, Steel/Chrome, Steel)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/417PWrd"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44qUzzL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/427wnAy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LYrWCC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42mELg1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VwxN5n"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p8ybuG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/4189QCB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42jGVN2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/425Zftf"
     },
     {
      "type": "plain",
      "text": "\n\n @549\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/41HGfdj2IJL._SX300_SY300_QL70_FMwebp_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Dining \n                Kitchen Storage & Containers \nPryce549 \nDicos63 \n\n❌Regular Price  : ₹  1199   /- 😱"
     }
    ]
   },
   {
    "id": 20734,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683265873",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "22497",
    "dicos": "32",
    "catagory":"Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Bose Quietcomfort 45 Bluetooth Wireless Over Ear Headphones with Mic Noise Cancelling - Triple Black✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/422Hmvc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44q28Xn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44tK7HE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41YvRoB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44sV38h"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44yj9yE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LyiV1D"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AZR061"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VysguS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VDyKJg"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@22497"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51JbsHSktkL._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Headphones, Earbuds & Accessories \n                Headphones \nPryce22497 \nDicos32 \n\n❌Regular Price  : ₹  29999   /- 😱"
     }
    ]
   },
  //  {
  //   "id": 20735,
  //   "type": "message",
  //   "date": "2023-05-05",
  //   "date_unixtime": "1683265935",
  //   "from": "All1App daily",
  //   "from_id": "channel1858370886",
  //   "photo": "(File not included. Change data exporting settings to download.)",
  //   "pryce": "19999",
  //   "dicos": "NaN",
  //   "catagory":"Electronics",
  //   "text_entities": [
  //    {
  //     "type": "plain",
  //     "text": "🚀🚀   OnePlus Nord CE 3 Lite 5G (Chromatic Gray, 8GB RAM, 128GB Storage)✅\n"
  //    },
  //    {
  //     "type": "link",
  //     "text": "https://amzn.to/3HJpJIJ"
  //    },
  //    {
  //     "type": "plain",
  //     "text": "\n"
  //    },
  //    {
  //     "type": "link",
  //     "text": "https://amzn.to/3pc3Dbu"
  //    },
  //    {
  //     "type": "plain",
  //     "text": "\n"
  //    },
  //    {
  //     "type": "link",
  //     "text": "https://amzn.to/44q2qNX"
  //    },
  //    {
  //     "type": "plain",
  //     "text": "\n"
  //    },
  //    {
  //     "type": "link",
  //     "text": "https://amzn.to/3VzJ1pG"
  //    },
  //    {
  //     "type": "plain",
  //     "text": "\n"
  //    },
  //    {
  //     "type": "link",
  //     "text": "https://amzn.to/3NYyRxh"
  //    },
  //    {
  //     "type": "plain",
  //     "text": "\n"
  //    },
  //    {
  //     "type": "link",
  //     "text": "https://amzn.to/42pwW8B"
  //    },
  //    {
  //     "type": "plain",
  //     "text": "\n"
  //    },
  //    {
  //     "type": "link",
  //     "text": "https://amzn.to/3p3dxfk"
  //    },
  //    {
  //     "type": "plain",
  //     "text": "\n"
  //    },
  //    {
  //     "type": "link",
  //     "text": "https://amzn.to/3NBUpzq"
  //    },
  //    {
  //     "type": "plain",
  //     "text": "\n"
  //    },
  //    {
  //     "type": "link",
  //     "text": "https://amzn.to/3NCRer5"
  //    },
  //    {
  //     "type": "plain",
  //     "text": "\n"
  //    },
  //    {
  //     "type": "link",
  //     "text": "https://amzn.to/3LsBjZG"
  //    },
  //    {
  //     "type": "plain",
  //     "text": "\n\n "
  //    },
  //    {
  //     "type": "mention",
  //     "text": "@19999"
  //    },
  //    {
  //     "type": "plain",
  //     "text": "\n"
  //    },
  //    {
  //     "type": "link",
  //     "text": "https://m.media-amazon.com/images/I/61LB+d0vheL._SX569_.jpg"
  //    },
  //    {
  //     "type": "plain",
  //     "text": " \n\n\n"
  //    },
  //    {
  //     "type": "hashtag",
  //     "text": "#Electronics"
  //    },
  //    {
  //     "type": "plain",
  //     "text": "\n\n                Electronics \n                Mobiles & Accessories \n                Smartphones & Basic Mobiles \nPryce19999 \nDicosNaN"
  //    }
  //   ]
  //  },
   {
    "id": 20736,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683266000",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "30999",
    "dicos": "21",
    "catagory":"Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   OnePlus 10R 5G (Forest Green, 8GB RAM, 128GB Storage, 80W SuperVOOC)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42oqkaE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NGC18H"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/425f6rH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42432ag"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nrnMKf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LVm39o"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VysoKS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://www.amazon.in/dp/B09WRMNJ9G?th=1&amp;linkCode=sl1&amp;tag=avi1deals8-21&amp;linkId=d314b6d0c9aa275ba46e967694241355&amp;language=en_IN&amp;ref_=as_li_ss_tl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NGtx13"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LXw9GH"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@30999"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/716uVx3Wr5L._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Mobiles & Accessories \n                Smartphones & Basic Mobiles \nPryce30999 \nDicos21 \n\n❌Regular Price  : ₹ 36999   /- 😱"
     }
    ]
   },
   {
    "id": 20737,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683266063",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "24999",
    "dicos": "29",
    "catagory":"Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   iQOO Neo 6 5G (Dark Nova, 8GB RAM, 128GB Storage) | Only Snapdragon 870 in The Segment | 50% Charge in Just 12 Mins | 90 FPS Gaming Support*✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NCl3Z3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LzGJlR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LErmsa"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pdxv7v"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LIoRWa"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pdxwIB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/415SdDl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LCIyhM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44tTS8H"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://www.amazon.in/dp/B07WDKLDRX?th=1&amp;linkCode=sl1&amp;tag=avi1deals10-21&amp;linkId=6b374a5d2b13a8cbe992fab3343a1b88&amp;language=en_IN&amp;ref_=as_li_ss_tl"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@24999"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71WS-0ITj7L._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Mobiles & Accessories \n                Smartphones & Basic Mobiles \nPryce24999 \nDicos29 \n\n❌Regular Price  : ₹  32999  /- 😱"
     }
    ]
   },
   {
    "id": 20738,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683266104",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1440",
    "dicos": "36",
    "catagory":"Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Crompton Dyna Ray 12W Round E27 LED Warm White Pack of 8✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HJp61L"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ASWJKV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HJg3y2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HFo8DP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AXxya8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AUeJVd"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HHlkWM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nvl46i"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HJq12h"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AXh7uj"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1440"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/81UmmSWrvNL._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Indoor Lighting \n                Light Bulbs \nPryce1440 \nDicos36 \n \n❌Regular Price  : ₹  1999   /- 😱"
     }
    ]
   },
   {
    "id": 20739,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683266170",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "2699",
    "dicos": "57",
    "catagory":"Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Prestige Iris 750 Watt Mixer Grinder with 3 Stainless Steel Jar + 1 Juicer Jar (White and Blue)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HIHU1j"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3I4rIHZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LTynXz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3I4rCQD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nuiopD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LVngxg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M0ckyI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LXdbjq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3peJpO8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nvlrxI"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@2699"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/81+M1QrVNIL._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Home Appliances \n                Small Kitchen Appliances \nPryce2699 \nDicos57 \n\n❌Regular Price  : ₹  4999    /- 😱"
     }
    ]
   },
   {
    "id": 20740,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683266234",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "3620",
    "dicos": "20",
    "catagory":"Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Crompton Ginie Neo Personal Air Cooler - 10L, White and Light Blue✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B38UEJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41251e0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41fMgnC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p3e0hA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42qTOop"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42r58kh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/412w8FD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44tKqSO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44A4l2j"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44q2RI5"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@3620"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51+085ENXjL._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Heating, Cooling & Air Quality \n                Air Coolers \nPryce3620 \nDicos20 \n\n❌Regular Price  : ₹  4499  /- 😱"
     }
    ]
   },
   {
    "id": 20741,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683266279",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "949",
    "dicos": "78",
    "catagory":"Office",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Amazon Basics Calendar Planner Board, 61 x 91 cm✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41cd482"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/418kHw8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p6wD4l"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/428s0p9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pdaVMd"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/427wZ9k"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42mFygZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42jHrdW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44qV4K9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/427OR3Z"
     },
     {
      "type": "plain",
      "text": "\n\n @949\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71TZrzxZtiS._SY450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Office"
     },
     {
      "type": "plain",
      "text": " Products\n\n                Office Products \n                Calendars, Planners & Personal Organisers \n                 Wall Calendars \nPryce949 \nDicos78 \n\n❌Regular Price  : ₹   2999   /- 😱"
     }
    ]
   },
   {
    "id": 20742,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683266342",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "2899",
    "dicos": "61",
    "catagory":"Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Amazon Basics 1300 W Air Fryer | 3.5 Litre Non Stick Basket with Metallic Interior| Timer Selection And Fully Adjustable Temperature Control✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vx9c0j"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LAgdsG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NK7oz0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pa1ihl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nro2Jd"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NJEIGn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NHyyqj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NAKev0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HCR7rV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NHQ0Ls"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@2899"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/81Y+y5ld2jL._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Home Appliances \n                Small Kitchen Appliances \nPryce2899 \nDicos61 \n\n❌Regular Price  : ₹  5999  /- 😱"
     }
    ]
   },
   {
    "id": 20743,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683266382",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "299",
    "dicos": "90",
    "catagory":"Shoes",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Bagsy Malone Women's Vegan Leather Stylish Tote Bag Pack of 5 | Ladies Purse Handbag✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NFAYFO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nrobwf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42JXbqP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vx0kaV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nCtrgj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3noEav2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HCOPc3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p6Ilff"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LAaS4y"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LDPsDL"
     },
     {
      "type": "plain",
      "text": "\n\n @299\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71yVK8KNr+L._UY500_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Shoes"
     },
     {
      "type": "plain",
      "text": " & Handbags\n\n                Shoes & Handbags \n                Handbags, Purses & Clutches \n                Handbags \nPryce299 \nDicos90 \n\n❌Regular Price  : ₹  1999    /- 😱"
     }
    ]
   },
   {
    "id": 20744,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683266447",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "385",
    "dicos": "36",
    "catagory":"Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Clazkit Plastic Pet Storage Jar and Container, Set of 18 (6 pcs x 350ml Each, 6 pcs x 650ml Each, 6 pcs x 1.20ltrs Each), Red | Air Tight | Kitchen Organiser | BPA Free | Stackable✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p9CLce"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pdy3dz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LXIMS0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LIpcIq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nxpb1O"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nxpb1O"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LX8JBj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ASX6VP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41aso57"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/419PgS6"
     },
     {
      "type": "plain",
      "text": "\n\n @385\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/91XTKDYMsfL._SX522_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Dining \n                Kitchen Storage & Containers \nPryce385 \nDicos36 \n\n❌Regular Price  : ₹  599   /- 😱"
     }
    ]
   },
   {
    "id": 20745,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683266514",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "641",
    "dicos": "46",
    "catagory":"Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Pigeon by Stovekraft Aqua Therminox Stainless Steel Vaccum Insulated Water Bottle with Copper Coating Inside for Better Hot and Cold Retention (1000 ml)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p3eqoa"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3paz5H7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44tVwam"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44tVwam"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/428Dc4Z"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HHVke1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LXwSaT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LVihwI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HGxAH9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HLrMvR"
     },
     {
      "type": "plain",
      "text": "\n\n @641\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61vkGy3ydNL._SX466_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Dining \n                Kitchen Storage & Containers \nPryce641 \nDicos46 \n\n❌Regular Price  : ₹  1099    /- 😱"
     }
    ]
   },
   {
    "id": 20746,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683266554",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "16900",
    "dicos": "15",
    "catagory":"All",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Apple AirPods (3rd Generation) with Lightning Charging Case ​​​​​​​✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vx0u21"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LXdCu4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AVdsgr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NK7J4K"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AUuWJM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/418aEr7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/416fbue"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NGukz3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AUINA7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/415Bq3q"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@16900"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61CVih3UpdL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#All"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Headphones, Earbuds & Accessories \n                Headphones \nPryce16900 \nDicos15 \n\n❌Regular Price  : ₹  19499  /- 😱"
     }
    ]
   },
   {
    "id": 20747,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683266617",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "449",
    "dicos": "75",
    "catagory":"Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Redgear Cloak Wired RGB Wired Over Ear Gaming Headphones with Mic for PC✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nvm6zc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42clpKf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HGwDOW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nAcIKQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/419PCIq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vwk2nj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pg2hMH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LVo5Gm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/422Ixe6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/422Ixe6"
     },
     {
      "type": "plain",
      "text": "\n\n @449\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61O0rXhhP6L._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Computers & Accessories \n                Accessories & Peripherals \n                PC Gaming Peripherals \nPryce449 \nDicos75 \n\n❌Regular Price  : ₹ 1399   /- 😱"
     }
    ]
   },
   {
    "id": 20748,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683266681",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "499",
    "dicos": "81",
    "catagory":"Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   pTron Bassbuds Duo in Ear Earbuds with 32Hrs Total Playtime, Bluetooth 5.1 Wireless Headphones, Stereo Audio, Touch Control TWS, with Mic, Type-C Fast Charging, IPX4 & Voice Assistance (White)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pdbA09"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44zzPWz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44ywM0u"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NGXhLn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pg2u2r"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AWhdlP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NGFHas"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NHa5BE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42ory5K"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NMcMBX"
     },
     {
      "type": "plain",
      "text": "\n\n @499\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/414we1a2KDL._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Headphones, Earbuds & Accessories \n                Headphones \nPryce499 \nDicos81 \n\n❌Regular Price  : ₹   1499  /- 😱"
     }
    ]
   },
   {
    "id": 20749,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683266745",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1599",
    "dicos": "43",
    "catagory":"Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Orient Electric Apex-FX 1200mm BEE Star Rated Ceiling Fan (White)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LEso7w"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vy5vr4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LsOji8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42tvceu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vyu5rI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42pg0zk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LC7V3n"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LVmjnJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LCgvyZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nAYYPR"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1599"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51Zb-9uH9IL._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Heating, Cooling & Air Quality \n                Fans \nPryce1599 \nDicos43 \n\n❌Regular Price  : ₹   2499  /- 😱"
     }
    ]
   },
   {
    "id": 20750,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683266786",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1599",
    "dicos": "43",
    "catagory":"Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Orient Electric Apex-FX 1200mm BEE Star Rated Ceiling Fan (Brown)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nu9c4D"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pdOW7S"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/427y690"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VzmYiN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LVmorx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VPmUvL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/428DLf7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VzrQV8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AXSIEW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LVoeJU"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1599"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61rGoIkuXlL._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Heating, Cooling & Air Quality \n                Fans \nPryce1599 \nDicos43 \n\n❌Regular Price  : ₹  2499    /- 😱"
     }
    ]
   },
   {
    "id": 20751,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683266846",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "189",
    "dicos": "53",
    "catagory":"Grocery",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Nuts About You Quinoa Seeds, 1 kg | kinoa | Raw | Premium | 100% Natural| Diet Food✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/416fDZs"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NHasfw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41715Zm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HHv0jQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NGG8l6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HJ61wO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42qV1vX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3npGQIR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42qqDlh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42qqE8P"
     },
     {
      "type": "plain",
      "text": "\n\n @189\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/717RVBAtuyL._SY606_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Grocery"
     },
     {
      "type": "plain",
      "text": " & Gourmet Foods\n\n                Grocery & Gourmet Foods \n                Cooking Supplies \n                 Rice, Flours & Pulses \nPryce189 \nDicos53 \n\n❌Regular Price  : ₹  499  /- 😱"
     }
    ]
   },
   {
    "id": 20752,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683266911",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1050",
    "dicos": "77",
    "catagory":"Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Mamta Decoration Sheesham Wood Bedside Table with 3 Drawers for Bedroom Living Room End Tables Wooden Nightstand Sofa Side Table with Storage (Light Walnut)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AUvHm6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LYtXPc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HF2FL2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44tVsaD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pac9HV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44yx1sq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44tWdjY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HF2SOk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/416fN30"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NDcVrd"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1050"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/81i5WT6RCqS._SY741_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Home & Kitchen \n                Furniture \n                Bedroom Furniture \nPryce1050 \nDicos77 \n\n❌Regular Price  : ₹  2999   /- 😱"
     }
    ]
   },
   {
    "id": 20753,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683266974",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1734",
    "dicos": "53",
    "catagory":"Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Orient Electric Apex Prime 1200mm Decorative BEE Star Rated Ceiling Fan (Brown, Pack of 1)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pa2i55"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44y6ID2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AU6CYC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HJ6Fue"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nyUKIy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/424F8f1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3I4t2dV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AVhK7t"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LZqXlQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/412xkJ7"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1734"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61QagfOT4dL._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Heating, Cooling & Air Quality \n                Fans \nPryce1734 \nDicos53 \n\n❌Regular Price  : ₹   2999  /- 😱"
     }
    ]
   },
   {
    "id": 20754,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683267037",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "6998",
    "dicos": "59",
    "catagory":"Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Samsung Dolby Digital Bluetooth Soundbar (HW-T42E/XL, Black, 2.1 Channel)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LXKaEf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/427Q0IP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/4126H7i"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3phiW2o"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AUXVxe"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AUJGsr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/418iT6g"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42uL5ld"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NHAbEr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pa2wJt"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@6998"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71YUCeNf93L._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Home Audio \n                Speakers \nPryce6998 \nDicos59 \n\n❌Regular Price  : ₹   14999  /- 😱"
     }
    ]
   },
   {
    "id": 20755,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683267101",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "7999",
    "dicos": "58",
    "catagory":"Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Havells FAB Absoulety Safe RO + UV Water Purifer, GHWRHFB015, Beige & White✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NORhQC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LB50Ib"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42uLmof"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LEt9gS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HF3d3y"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LTzSoF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42mGYbj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vy62tc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LWPPKH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LWPPKH"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@7999"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/518NK6aOSfL._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Home Appliances \n                Water Purifiers & Accessories \nPryce7999 \nDicos58 \n\n❌Regular Price  : ₹ 14999   /- 😱"
     }
    ]
   },
   {
    "id": 20756,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683267167",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "10999",
    "dicos": "45",
    "catagory":"Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Acer 80 cm (32 inches) I Series HD Ready Android Smart LED TV AR32AR2841HDFL (Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LWX8C6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nuF2hu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LAOSqj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LzaGm0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HJs8TL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NHRIMS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42rUQQZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nrprzt"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42pgTYG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nxYLwQ"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@10999"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/712am1RDNiL._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Home Theater, TV & Video \n                Televisions \nPryce10999 \nDicos45 \n\n❌Regular Price  : ₹   16999   /- 😱"
     }
    ]
   },
   {
    "id": 20757,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683267232",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "4499",
    "dicos": "65",
    "catagory":"Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Blaupunkt Germany's SBW100 120W Wired Soundbar with Subwoofer, Bluetooth and HDMI Arc✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42nJmOs"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44tJNsr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/416gJo2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/416gJo2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VydeW0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41ceI9I"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AWkVMl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nrpH1p"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pdcHwR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42pyRtP"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@4499"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61+FFGMLwVL._SY450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Home Audio \n                Speakers \nPryce4499 \nDicos65 \n\n❌Regular Price  : ₹  9999  /- 😱"
     }
    ]
   },
   {
    "id": 20758,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683267273",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1899",
    "dicos": "42",
    "catagory":"Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Bajaj RCX 1.8L DLX Rice Cooker✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AYKgoO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42ph0n4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3paAOMB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44A6sTN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HGxHlU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HCQA99"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3paEeyO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vxb8pB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VCEWkM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/417g2uz"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1899"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/611eY73pegL._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Home Appliances \n                Small Kitchen Appliances \nPryce1899 \nDicos42 \n\n❌Regular Price  : ₹  2999   /- 😱"
     }
    ]
   },
   {
    "id": 20759,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683267335",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "210",
    "dicos": "45",
    "catagory":"Garden",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Odomos Protect Mosquito Repellent Liquid Vaporiser Refill (Pack of 6) | Fits all standard machines | Kills Dengue mosquitoes✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44tMyKi"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pdPVVC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p9Qwre"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VGGES8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NCTPRR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/412yoN7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LB5GgH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LCQCiv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lzbjfh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LsPpKM"
     },
     {
      "type": "plain",
      "text": "\n\n @210\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71iLhB6aq5L._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Garden"
     },
     {
      "type": "plain",
      "text": " & Outdoors\n\n                Health & Personal Care \n                Household Supplies \n                Indoor Insect & Pest Control \nPryce210 \nDicos45 \n\n❌Regular Price  : ₹  382  /- 😱"
     }
    ]
   },
   {
    "id": 20760,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683267400",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "529",
    "dicos": "38",
    "catagory":"Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   SYSKA Iron Press | 2 Year Warranty | Iron Box, Dry Iron, Electric Press, Electric Iron Press for Clothes, SDI-200 1000-Watt (TEAL)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42rZ2QQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M7Ph57"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LVjPa0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/425gryN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VzLtfS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/417ghpt"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pdzYyN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LAPiNp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LC9Bdb"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LAd4sO"
     },
     {
      "type": "plain",
      "text": "\n\n @529\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61ICyg1UxHL._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Home Appliances \n                Vacuum, Cleaning & Ironing \nPryce529 \nDicos38 \n\n❌Regular Price  : ₹  799   /- 😱"
     }
    ]
   },
   {
    "id": 20761,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683267464",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1599",
    "dicos": "52",
    "catagory":"Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Amazon Basics 1600 Watt Induction Cooktop| 8 Stage Power Settings | Made of Crystal Glass Plate with premium Finish| Black✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LVoF7c"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nvvSBk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HCRMcY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HFUlL8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42vJD21"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HJt4Yh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HDFkti"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42nK6De"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HGBi3p"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nuaVqD"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1599"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/81tTfryQEbL._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Home Appliances \n                Small Kitchen Appliances \nPryce1599 \nDicos52 \n\n❌Regular Price  : ₹ 2999    /- 😱"
     }
    ]
   },
   {
    "id": 20762,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683267526",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "499",
    "dicos": "62",
    "catagory":"Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Amazon Basics OTG Card Reader and Hub with Type-C, USB & Micro USB Ports | Portable Memory Card Reader Supports USB Drives, TF, SD, Micro SD, SDHC, SDXC, MMC (Grey)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/418cGrf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AU7Inc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/412yIeN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42lSva4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42lSzqk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pg4l7p"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VBviPc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AX7R9q"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42tx72G"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VBEIKt"
     },
     {
      "type": "plain",
      "text": "\n\n @499\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61E2vM2NQUL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Computers & Accessories \n                External Devices & Data Storage \n                 External Memory Card Readers \nPryce499 \nDicos62 \n\n❌Regular Price  : ₹  1099   /- 😱"
     }
    ]
   },
   {
    "id": 20763,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683267589",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "5999",
    "dicos": "45",
    "catagory":"Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Balzano Tornado Cordless Vacuum Cleaner, Vacuum Cleaner for Home, 2-in-1 Portable Car Vacuum Cleaner, Multi-Purpose & Ultra Lightweight, Powerful Suction to Clean in One-Go, Extra Long Battery (White)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VCFPd6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AYLctk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/412yO69"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M01zwh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44x3LCv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/412gJW5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3BfDStL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44xrWk9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pa3Pbl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nrqAHh"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@5999"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61SQ1o8K2zL._SX522_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Home Appliances \n                Vacuum, Cleaning & Ironing \nPryce5999 \nDicos45 \n\n❌Regular Price  : ₹  8999  /- 😱"
     }
    ]
   },
   {
    "id": 20764,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683267652",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1498",
    "dicos": "70",
    "catagory":"Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   boAt Airdopes 413ANC True Wireless in Ear Earbuds with Active Noise Cancellation, 2 Mics ENx Tech, Signature Sound, Touch Gesture, 20 Hours Playback, ASAP Charge & Ambient Mode(Verve Purple)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3paFeD4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p5SrNn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42uMjgj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42tBvPi"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42m0vbc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42r8Nyx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44Jp9EX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VzpLbO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nx5oj4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3paBPnT"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1498"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61xYH01ElDL._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Headphones, Earbuds & Accessories \n                Headphones \nPryce1498 \nDicos70 \n\n❌Regular Price  : ₹  3999   /- 😱"
     }
    ]
   },
   {
    "id": 20765,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683267701",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "279",
    "dicos": "56",
    "catagory":"Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   GM GLO LED Bulb - B22 100 Lumens Per Watt - 6500K White - Cool Day Light (9 Watts, 4)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/425CFAY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lzc7ko"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VBAtyp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LyMgJy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nubmRN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://www.amazon.in/dp/B09WDSYRS3?th=1&amp;linkCode=sl1&amp;tag=avi1deals6-21&amp;linkId=90829cf7b4550ee18dcd20ea09dcbc34&amp;language=en_IN&amp;ref_=as_li_ss_tl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/416oWZ5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HHmzp4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ATG8GO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nCwiWz"
     },
     {
      "type": "plain",
      "text": "\n\n @279\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/714QEm+6wBL._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Indoor Lighting \n                Light Bulbs \nPryce279 \nDicos56 \n\n❌Regular Price  : ₹  649  /- 😱"
     }
    ]
   },
   {
    "id": 20766,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683267766",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1179",
    "dicos": "65",
    "catagory":"Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Lifelong LLMG23 Power Pro 500-Watt Mixer Grinder with 3 Jars (Liquidizing, Wet Grinding and Chutney Jar), Stainless Steel blades, 1 Year Warranty (Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LVoyaD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HJjfd0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HJtYUF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HCRMt9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42ilOdQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LXvQeR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NGKfNN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NGwS05"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NHcrR0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NHcsV4"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1179"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61zhqJg1bTL._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Home Appliances \n                Small Kitchen Appliances \nPryce1179 \nDicos65 \n\n❌Regular Price  : ₹  2999   /- 😱"
     }
    ]
   },
   {
    "id": 20767,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683267829",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1299",
    "dicos": "83",
    "catagory":"Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   beatXP Marv Raze Advanced Bluetooth Calling Smartwatch with 1.96\" HD Display, 60 Hz Fast Refresh Rate, 24/7 Health Tracking with 100+ Sports Mode, (Upto 7 Days Battery)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/4246uBK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p9FiTM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42qsMgP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VBm4lR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VxceSf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VxceSf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/427RL8T"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VBmdWr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HFrMO1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/427LwlE"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1299"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/619npqOI7aL._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Electronics \n                Wearable Technology \n                 Smart Watches \nPryce1299 \nDicos83 \n\n❌Regular Price  : ₹  4999   /- 😱"
     }
    ]
   },
   {
    "id": 20768,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683267894",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "58990",
    "dicos": "18",
    "catagory":"Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   HP 14s, 12th Gen Intel Core i5 16GB RAM/512GB SSD 14-inch(35.6 cm) Micro-Edge, FHD Laptop/Intel Iris Xe Graphics/Alexa/Dual Speakers/Win 11/Backlit KB/MSO 2021/1.41 Kg, 14s-dy5005TU✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HGAzPR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NFogXG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LXw3yF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/417U2zB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/418oTfm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pg5coD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3phkKZe"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3npJ62N"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/419gicD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/418kVTW"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@58990"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/7193KfRaF+L._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Computers & Accessories \n                Laptops \n                 Traditional Laptops \nPryce58990 \nDicos18 \n\n❌Regular Price  : ₹  69999  /- 😱"
     }
    ]
   },
   {
    "id": 20769,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683267956",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "199",
    "dicos": "60",
    "catagory":"Beauty",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Aromatique Natural Indigo Powder for Hair & Beard Color - 250 grams, Black✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VywEdk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42r9tUB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42qXKFH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42tbAHs"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44wZij5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44tYKdZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/428TydQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LEvcS6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p9SasU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p9FVwC"
     },
     {
      "type": "plain",
      "text": "\n\n @199\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71w4bZpZzCL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Beauty"
     },
     {
      "type": "plain",
      "text": "\n\n                Beauty \n                Hair Care \n                Hair Color \nPryce199 \nDicos60 \n\n❌Regular Price  : ₹   499  /- 😱"
     }
    ]
   },
   {
    "id": 20770,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683268019",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "729",
    "dicos": "57",
    "catagory":"Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   AmazonBasics 250W Hand Blender with ABS Body, White✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VCGWJO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LDxOQg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41cgtDQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LAkah0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nvh1a3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HJsOIJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HGzbwu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LXMxqA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AVgFfZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NFEeB2"
     },
     {
      "type": "plain",
      "text": "\n\n @729\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51U250MzDGL._SY879_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Home Appliances \n                Small Kitchen Appliances \nPryce729 \nDicos57 \n\n❌Regular Price  : ₹  1599   /- 😱"
     }
    ]
   },
   {
    "id": 20771,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683268083",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "87",
    "dicos": "71",
    "catagory":"Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Lexton 20 LED Wine Bottle Cork Lights Copper Wire String Lights, 2M/7.2FT Battery Powered DIY (Warm White, 1 Piece) (Lex-Cork-String-Light)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3BfEUWF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p0WMkX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/416pRbZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/4173QKc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M02FIp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M02FIp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NDfA4b"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/427AWuG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/428TI4W"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44A8qUb"
     },
     {
      "type": "plain",
      "text": "\n\n @87\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51qS-tpHnHL._SX300_SY300_QL70_FMwebp_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Indoor Lighting \n                Specialty Lighting \nPryce87 \nDicos71 \n\n❌Regular Price  : ₹ 299   /- 😱"
     }
    ]
   },
   {
    "id": 20772,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683268148",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "2699",
    "dicos": "36",
    "catagory":"Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Crompton Ameo 750-Watt Mixer Grinder with MaxiGrind and Motor Vent-X Technology (3 Stainless Steel Jars, Black & Green)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AXEZOs"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HJt7Dn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/428TQ4q"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/428woV9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ATHdym"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/424HOJB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HIMH2N"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44x5esv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nvhyJ5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nvhzN9"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@2699"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/41eDxUvLq1L._SX300_SY300_QL70_FMwebp_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Home Appliances \n                Small Kitchen Appliances \nPryce2699 \nDicos36 \n\n❌Regular Price  : ₹  3499  /- 😱"
     }
    ]
   },
   {
    "id": 20773,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683268211",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "8999",
    "dicos": "55",
    "catagory":"Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Blaupunkt Germany's SBW08 220W Dolby Soundbar with 8 Inch(20cm) Subwoofer, 2.1 Channel Surround Sound System with HDMI-ARC, Optical, AUX, Bluetooth and Remote Control, Black✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44tYNGG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pfwawY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p9SBDy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LB7AOn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/426JzG5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NYDzLt"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42peWvh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/4276nFE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p9SKH6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42vSyQZ"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@8999"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/715vlEHoySL._SX450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Home Audio \n                Speakers \nPryce8999 \nDicos55 \n\n❌Regular Price  : ₹  15999   /- 😱"
     }
    ]
   },
   {
    "id": 20774,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683268276",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "999",
    "dicos": "39",
    "catagory":"Tools",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Spotzero by Milton Classic 360 Degree Cleaning Spin Mop with Easy Wheels, 2 Refill and Bucket✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LDMrTR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LB7OVJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44vgHZw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VyxoPE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VyxnLA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VCHBeg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LYxaOK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AXuOti"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LZo8RN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AWnmyt"
     },
     {
      "type": "plain",
      "text": "\n\n @999\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/716oGBfmpEL._SX466_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Tools"
     },
     {
      "type": "plain",
      "text": " & Home Improvement\n\n                Home & Kitchen \n                Home & kitchen | Prime shopping days \n                Prime Units \nPryce999 \nDicos39 \n\n❌Regular Price  : ₹   1499  /- 😱"
     }
    ]
   },
   {
    "id": 20775,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683268338",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "188",
    "dicos": "87",
    "catagory":"Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   SAF Set of 3 Flower UV Coated 6MM MDF Home Decorative Gift Item Painting 12 inch X 27 inch SANFC12190(multi)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3BfFCDj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42tyr62"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/428EGw0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/4264p8B"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/4264p8B"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vzrkqc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AXFFU0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/417iDoj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41ax4rH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42nMp9m"
     },
     {
      "type": "plain",
      "text": "\n\n @188\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61AHWUpPCOL._SX466_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Artwork \n                 Paintings \nPryce188 \nDicos87 \n\n❌Regular Price  : ₹  999   /- 😱"
     }
    ]
   },
   {
    "id": 20776,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683268378",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "529",
    "dicos": "68",
    "catagory":"Health",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Wahl Mini T-Pro Grooming Kit - White & Black✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/4174Qhq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HH0xT6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pfwNGQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AWnKNr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VwE0yd"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44tPjv8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LVmoZG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nunpyt"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NHDmMn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42jLVRO"
     },
     {
      "type": "plain",
      "text": "\n\n @529\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51Z7g8lQH3S._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Health"
     },
     {
      "type": "plain",
      "text": " & Personal Care\n\n                Beauty \n                Hair Care \n                Hair Cutting Tools \nPryce529 \nDicos68 \n\n❌Regular Price  : ₹  1399   /- 😱"
     }
    ]
   },
   {
    "id": 20777,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683268440",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "499",
    "dicos": "50",
    "catagory":"Grocery",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Nature Prime Premium Mix Dry Fruits and Nuts with Almonds| Cashew| Kishmish | Apricot | Black Raisins | kiwi | Dry Fruits, Seeds & Nuts - 900 gm✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LXdr1X"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42tzylQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42vTtAV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nrYs6S"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42tDFhN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LzdAHq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42nMIkw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/425j2c1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/425iV05"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/425kgnz"
     },
     {
      "type": "plain",
      "text": "\n\n @499\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/41I9WoMIs3L.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Grocery"
     },
     {
      "type": "plain",
      "text": " & Gourmet Foods\n\n                Grocery & Gourmet Foods \n                Dried Fruits, Nuts & Seeds \n                Dried Fruits \nPryce499 \nDicos50 \n\n❌Regular Price  : ₹  899   /- 😱"
     }
    ]
   },
   {
    "id": 20778,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683268480",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "299",
    "dicos": "90",
    "catagory":"Shoes",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Bagsy Malone Women's Vegan Leather Stylish Tote Bag Pack of 5 | Ladies Purse Handbag ✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LAfV50"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44zDWlt"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VzNRDm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/426Kuq1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vy9jsm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LCkk7j"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LyOj0c"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LYxZai"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LXBw8P"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LyooFH"
     },
     {
      "type": "plain",
      "text": "\n\n @299\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/617jGsPianL._UY500_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Shoes"
     },
     {
      "type": "plain",
      "text": " & Handbags\n\n                Shoes & Handbags \n                Handbags, Purses & Clutches \n                Handbags \nPryce299 \nDicos90 \n\n❌Regular Price  : ₹   1499  /- 😱"
     }
    ]
   },
   {
    "id": 20779,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683268528",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "370",
    "dicos": "47",
    "catagory":"Sports",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   NIVIA Deflate Round 01 Polyester Gym Bag/Unisex Gym Bags/Adjustable Shoulder Bag for Men/Duffle Gym Bags for Men/Carry Gym Accessories/Fitness Bag/Sports &Travel Bag/Gym kit Bag✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p9HnPA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LDNnYn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HJaKP4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HCTdYz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41axYV7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41chOdQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nA8aE0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LCTImD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HJl6P0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/412aBwY"
     },
     {
      "type": "plain",
      "text": "\n\n @370\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51Gszx6vb5L._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Sports"
     },
     {
      "type": "plain",
      "text": ", Fitness & Outdoors\n\n                Bags, Wallets and Luggage \n                Bags & Backpacks \n                Gym Bags \nPryce370 \nDicos47 \n\n❌Regular Price  : ₹ 695   /- 😱"
     }
    ]
   },
   {
    "id": 20780,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683268591",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "258",
    "dicos": "35",
    "catagory":"Baby",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   LUVLAP CAPS Booties Mittens Set Kitty Penguin Print PO2✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AUaj0q"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HCUD5G"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AT27O9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ATIlC6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/419ob1F"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/419ob1F"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HGCB2r"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HHyL8W"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NKcyuS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/422NGTs"
     },
     {
      "type": "plain",
      "text": "\n\n @258\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/81kTgx0hDsL._SY450_PIbundle-2,TopRight,0,0_AA450SH20_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Baby"
     },
     {
      "type": "plain",
      "text": "\n\n                Clothing & Accessories \n                Baby \n                Baby Boys \nPryce258 \nDicos35 \n\n❌Regular Price  : ₹  398  /- 😱"
     }
    ]
   },
   {
    "id": 20781,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683268724",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "234",
    "dicos": "39",
    "catagory":"Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Larah by Borosil Botanica Opalware Cup Set, 6-Pieces, White✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3paEii9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p8E4bg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AWn0YD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/425FaTS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VC0fTx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AXXOB4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44ypwC4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/425jy9X"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vzsmm4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/411OlDm"
     },
     {
      "type": "plain",
      "text": "\n\n @234\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51GCopAfq8L._SX522_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Dining \n                Tableware \nPryce234 \nDicos39 \n\n❌Regular Price  : ₹  385   /- 😱"
     }
    ]
   },
   {
    "id": 20782,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683268788",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "999",
    "dicos": "75",
    "catagory":"Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Noise ColorFit Pulse Grand Smart Watch with 1.69\"(4.29cm) HD Display, 60 Sports Modes, 150 Watch Faces, Fast Charge, Spo2, Stress, Sleep, Heart Rate Monitoring & IP68 Waterproof (Jet Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p6Ckzf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42qZH4Z"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42rc3Kh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42quPS3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/416kZ70"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VPrwSB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/418g31n"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LAgADw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42qZQFz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/419iXTF"
     },
     {
      "type": "plain",
      "text": "\n\n @999\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61Q0R5cdxWL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Electronics \n                Wearable Technology \n                 Smart Watches \nPryce999 \nDicos75 \n\n❌Regular Price  : ₹  2999  /- 😱"
     }
    ]
   },
   {
    "id": 20783,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683268853",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "999",
    "dicos": "83",
    "catagory":"Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   boAt Wave Prime47 Smart Watch with 1.69\" HD Display, 700+ Active Modes, ASAP Charge, Live Cricket Scores, Crest App Health Ecosystem, HR & SpO2 Monitoring(Matte Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42oq63l"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VCgjUY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VwFit3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nAiNHa"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LDEmP2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LVrB2z"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/419ja9p"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lyp3Hb"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LDEGgI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LzfgAD"
     },
     {
      "type": "plain",
      "text": "\n\n @999\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/41GqnjoVbOL._SX300_SY300_QL70_FMwebp_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Electronics \n                Wearable Technology \n                 Smart Watches \nPryce999 \nDicos83 \n\n❌Regular Price  : ₹  3999   /- 😱"
     }
    ]
   },
   {
    "id": 20784,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683268917",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "599",
    "dicos": "84",
    "catagory":"Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Nirlon 3 Layer Aluminium Non-Stick Coated Chemical Free Healthy Kitchenware Utensil Gift Item Set of 3 Pieces with Bakelitw Handle[3mm Classic_FT10_TP20cm_KD11]✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LIuOCu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M7SPUZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LVsolc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B3f33L"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NCqMOx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LX24qC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NMhJux"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nqcA0p"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nx8zY2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HJmjG2"
     },
     {
      "type": "plain",
      "text": "\n\n @599\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71kRDSWb7RS._SX522_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Dining \n                Cookware \nPryce599 \nDicos84 \n\n❌Regular Price  : ₹  1999  /- 😱"
     }
    ]
   },
   {
    "id": 20785,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683268981",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1399",
    "dicos": "50",
    "catagory":"Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀Cello Prima Induction Base Non-Stick Aluminium Pan Cookware Set, 3-Pieces, Cherry Red   ✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/428pKxV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HGEufp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HCUz5B"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NC0DiM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NFr1Z2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/412kpHn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nqcLZD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42mLExT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AXDY9e"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44IiBq2"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1399"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/7169jlr-0mL._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Dining \n                Cookware \nPryce1399 \nDicos50 \n\n❌Regular Price  : ₹ 1,999   /- 😱"
     }
    ]
   },
   {
    "id": 20786,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683269028",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "395",
    "dicos": "80",
    "catagory":"Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   HP w100 480P 30 FPS Digital Webcam with Built-in Mic, Plug and Play Setup, Wide-Angle View for Video Calling on Skype, Zoom, Microsoft Teams and Other Apps (Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NAPz5w"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NG7RCc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LWMIm1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LXPcAD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NHfOaC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42lWELa"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42s2M4Q"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/412CsNn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42plpq6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41dmQGR"
     },
     {
      "type": "plain",
      "text": "\n\n @395\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61HfJn05shL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Computers & Accessories \n                Accessories & Peripherals \n                Audio & Video Accessories \nPryce395 \nDicos80 \n\n❌Regular Price  : ₹  1299   /- 😱"
     }
    ]
   },
   {
    "id": 20787,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683269090",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "74999",
    "dicos": "17",
    "catagory":"All",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Apple Watch Ultra [GPS + Cellular 49 mm] smart watch w/Rugged Titanium Case & Orange Alpine Loop - Large. Fitness Tracker, Precision GPS, Action Button, Extra-Long BatteryLife, Brighter Retina Display✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42plrhI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42rZyhD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42tA9Eu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pcMkaj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/427xkJm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VDWJb1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HGBvnc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pb1IE6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/428yXGL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nx99VI"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@74999"
     },
     {
      "type": "plain",
      "text": "\n \n "
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/91z5KuonXrL._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#All"
     },
     {
      "type": "plain",
      "text": "\n\nPryce74999 \nDicos17 \n\n❌Regular Price  : ₹  84999  /- 😱"
     }
    ]
   },
   {
    "id": 20788,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683269131",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "74999",
    "dicos": "17",
    "catagory":"All",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Apple Watch Ultra [GPS + Cellular 49 mm] smart watch w/Rugged Titanium Case & Starlight Alpine Loop Medium Fitness Tracker, Precision GPS, Action Button, ExtraLong BatteryLife, Brighter Retina Display✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/422ONCC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AZ5Edy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42dQU6U"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/424K7ML"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44yqhuU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pcMBdl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44zF8Ft"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LZq3pt"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VzP11G"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44uPuX3"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@74999"
     },
     {
      "type": "plain",
      "text": "\n \n "
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51c2yn2Kx6L._SY445_SX342_QL70_FMwebp_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#All"
     },
     {
      "type": "plain",
      "text": "\n\nPryce74999 \nDicos17 \n\n❌Regular Price  : ₹    /- 😱"
     }
    ]
   },
   {
    "id": 20789,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683269195",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "999",
    "dicos": "75",
    "catagory":"Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Amazon Basics 6 Piece Non-Stick Aluminium Cookware Set, Saucepan, Fry Pan (without Induction Base), Black✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pdU3VC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/412kGtT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AXEeoI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42oiFsW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VyhPYg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NBGFER"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VBz6zY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nrZLTk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VBza2G"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42ip350"
     },
     {
      "type": "plain",
      "text": "\n\n @999\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/81vJqi8AwYL._SX466_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Dining \n                Cookware \nPryce999 \nDicos75 \n\n❌Regular Price  : ₹  2999   /- 😱"
     }
    ]
   },
   {
    "id": 20790,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683269259",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "699",
    "dicos": "50",
    "catagory":"Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Maharaja Whiteline HB-129 175 Watt Hand Blender, Stainless Steel Blades, Long Lasting Performance With 175W Motor, Detachable Anti Splash Plastic Foot (Blue And White)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42lXbg8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/424a7Yo"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LVuelU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://www.amazon.in/dp/B08X7DQ6WQ?&amp;linkCode=sl1&amp;tag=avi1deals-21&amp;linkId=45bdc8ce4fa2875d588abdb27c00c707&amp;language=en_IN&amp;ref_=as_li_ss_tl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42crOVN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/425G7eU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p9IKxI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VwFZTb"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nF0b8J"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VBE9QJ"
     },
     {
      "type": "plain",
      "text": "\n\n @699\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/41Jo0NH0vOL._SX466_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Home Appliances \n                Small Kitchen Appliances \nPryce699 \nDicos50 \n\n❌Regular Price  : ₹ 1199    /- 😱"
     }
    ]
   },
   {
    "id": 20791,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683269322",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1459",
    "dicos": "51",
    "catagory":"Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Candes Florence 1200mm/48 inch High Speed Anti-dust Decorative 5 Star Rated Ceiling Fan(100% CNC Winding) 405 RPM (2 Yrs Warranty) (Silver Blue, Pack of 1)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nA9jLO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HLwWrJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AWnfTv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HJcg3I"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AZY42x"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LEyBjQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AXwWRO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B1PT5y"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HH2j6I"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AUbVY2"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1459"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/31eW5cOQeBL._SX300_SY300_QL70_FMwebp_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Heating, Cooling & Air Quality \n                Fans \nPryce1459 \nDicos51 \n\n❌Regular Price  : ₹  2499  /- 😱"
     }
    ]
   },
   {
    "id": 20792,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683269387",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "549",
    "dicos": "50",
    "catagory":"Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Crompton Brio 1000-Watts Dry Iron with Weilburger Coating (Sky Blue and White)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3I4xQQv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HHXA4H"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NDi3LZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42crSF1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LXPsj2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HJ3TF8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HCXhIz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nufz83"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://www.amazon.in/Crompton-BRIO-1000-Years-Warranty/dp/B07YSJ7FF1?&amp;linkCode=sl1&amp;tag=avi1deals9-21&amp;linkId=e4a7a4cec6e475636ad7007daa838f01&amp;language=en_IN&amp;ref_=as_li_ss_tl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/428Hd9u"
     },
     {
      "type": "plain",
      "text": "\n\n @549\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51WmzEXXaeL._SX466_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Home Appliances \n                Vacuum, Cleaning & Ironing \nPryce549 \nDicos50 \n\n❌Regular Price  : ₹  999   /- 😱"
     }
    ]
   },
   {
    "id": 20793,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683269451",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "5900",
    "dicos": "76",
    "catagory":"Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Kuvings Instant Pot 6 Litre Electric Pressure Cooker with Stainless Steel Inner Pot. Pressure Cook, Slow Cook, Saute & More (Kuvings Instant Pot 6L) - Outer Lid✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HHY9LR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NHgwEO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NGUKAN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NGUKAN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ATJSbk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NGJMvl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nvkZzt"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/416mdz8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LXPQ11"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/412D3P7"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@5900"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71im9MlX2sL._SX522_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Dining \n                Cookware \nPryce5900 \nDicos76 \n\n❌Regular Price  : ₹  16999   /- 😱"
     }
    ]
   },
   {
    "id": 20794,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683269512",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "420",
    "dicos": "20",
    "catagory":"Beauty",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Nivea Moisturizing Lotion Body Milk, 400ml And Nivea Moisturization Lotion Body Milk, 120ml ✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3I4yptB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p6Pg8d"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42m4q7U"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42lXNlW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42tfu34"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/428WI1c"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HHYqOT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44uQ1bv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42r0TW1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://www.amazon.in/dp/B07NZX635H?smid=A15APWRK6P7LBV&amp;linkCode=sl1&amp;tag=avi1deals10-21&amp;linkId=4c5db52d4de53ea9afbe3319b07422df&amp;language=en_IN&amp;ref_=as_li_ss_tl"
     },
     {
      "type": "plain",
      "text": "\n\n @420\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51shDMCVn+L._SX569_PIbundle-2,TopRight,0,0_AA569SH20_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Beauty"
     },
     {
      "type": "plain",
      "text": "\n\n                Beauty \n                Bath & Body \n                Body Washes \nPryce420 \nDicos20 \n\n❌Regular Price  : ₹  499   /- 😱"
     }
    ]
   },
   {
    "id": 20795,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683269576",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "599",
    "dicos": "80",
    "catagory":"Clothing",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Intellilens Square Blue Cut Computer Glasses for Eye Protection | Unisex, UV ProtectionZero Power, Anti Glare & Blue Light Filter Glasses✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/427PrPo"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/427PrPo"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pdGIMX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44uQaf3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44AbyPV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vybk82"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44x7YGj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44x81lt"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AWobav"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VBzMW2"
     },
     {
      "type": "plain",
      "text": "\n\n @599\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51b1GOiEAQL._UX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Clothing"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Health & Personal Care \n                Health Care \n                Eye Care \nPryce599 \nDicos80 \n\n❌Regular Price  : ₹  1499   /- 😱"
     }
    ]
   },
   {
    "id": 20796,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683269643",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1399",
    "dicos": "50",
    "catagory":"Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Cello Prima Induction Base Non-Stick Aluminium Pan Cookware Set, 3-Pieces, Cherry Red✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VChC6k"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VBzVZA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44otc9g"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VCKkEw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p9J4fU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vx940M"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NFrNoU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NIYQIU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VPsVZn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NI2jY2"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1399"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/7169jlr-0mL._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Dining \n                Cookware \nPryce1399 \nDicos50 \n\n❌Regular Price  : ₹  2299   /- 😱"
     }
    ]
   },
   {
    "id": 20797,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683269685",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "199",
    "dicos": "85",
    "catagory":"Shoes",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Bagsy Malone Women's Vegan Leather Iconic Sling Bag | Ladies Purse Handbag✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p6PpIN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LsIzEU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LyQnW0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AWon9J"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AXcBMg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AWq8Up"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AXcDnm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42tFETm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3peQLRK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44Ik1AS"
     },
     {
      "type": "plain",
      "text": "\n\n @199\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/81Kr8iMBy2L._UY625_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Shoes"
     },
     {
      "type": "plain",
      "text": " & Handbags\n\n                Fashion \n                Pongal || Fashion accessories \n                Handbags \nPryce199 \nDicos85 \n\n❌Regular Price  : ₹  899  /- 😱"
     }
    ]
   },
   {
    "id": 20798,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683269725",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "198",
    "dicos": "1",
    "catagory":"Beauty",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Fair and Handsome Instant Radiance Face Wash | Pro-Peptide | Instant Radiance| Washes of Fine Pollutants | Cooling Freshness | 100g✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LEyKDU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nA9wyA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41nfe53"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pg9ix1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42vVXPL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LZiZc4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42qaJY3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VybxYK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LYAqJY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42aleiA"
     },
     {
      "type": "plain",
      "text": "\n\n @198\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61FNI8Jcp0L._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Beauty"
     },
     {
      "type": "plain",
      "text": "\n\n                Beauty \n                Skin Care \n                Face \nPryce198 \nDicos1 \n\n❌Regular Price  : ₹  199  /- 😱"
     }
    ]
   },
   {
    "id": 20799,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683269788",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "349",
    "dicos": "76",
    "catagory":"Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Pigeon by Stovekraft Kitchen Tools Value Combo (400ml Chopper & Storage, 3 Knives Set, 1 Kitchen Scissor)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M0k6bS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/428X5ZE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/417XWsf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LIvHLk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LAid46"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/426MApT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LEOG9k"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3paid39"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LzgLif"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nFozqK"
     },
     {
      "type": "plain",
      "text": "\n\n @349\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71psS2GzqCL._SX466_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Dining \n                Kitchen Tools \nPryce349 \nDicos76 \n\n❌Regular Price  : ₹  1299    /- 😱"
     }
    ]
   },
   {
    "id": 20800,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683269850",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "197",
    "dicos": "87",
    "catagory":"Shoes",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   GLOWIC Women's Sling Bag With Lock✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LzOuIv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LEP33I"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NIZ2YE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LDFUbO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NFJaWA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LDXJaN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NELoWa"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NBHtcR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HF8yYE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NFsgHG"
     },
     {
      "type": "plain",
      "text": "\n\n @197\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71dhI-O-bqL._UX625_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Shoes"
     },
     {
      "type": "plain",
      "text": " & Handbags\n\n                Fashion \n                Steal deals - Great Indian Festival \n                Last chance to buy : Min 60% off \nPryce197 \nDicos87 \n\n❌Regular Price  : ₹   999  /- 😱"
     }
    ]
   },
   {
    "id": 20801,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683269893",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "288",
    "dicos": "59",
    "catagory":"Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   HP v150w 32GB USB 2.0 flash Drive (Blue)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HF8ubm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HHr6rA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HI2i2F"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HGEZGr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HF8Hva"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HGK0yt"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/428A49p"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HH3nHK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HJxVIZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nxw8Qs"
     },
     {
      "type": "plain",
      "text": "\n\n @288\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/418bSC5BFWL._SX300_SY300_QL70_FMwebp_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Computers & Accessories \n                External Devices & Data Storage \n                 Pen Drives \nPryce288 \nDicos59 \n\n❌Regular Price  : ₹  649   /- 😱"
     }
    ]
   },
   {
    "id": 20802,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683269958",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "138",
    "dicos": "82",
    "catagory":"Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Fourwalls FOUR WALLS Mini Chrysanthemum Artificial Flora Bunches (40 cm Tall, 6 branches, Yellow)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LV2spN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NELBIW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M0kuXS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LWVTTt"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42tBdIu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NFJkgE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NGKqZN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/416LqcC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41dojNn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p2cIU4"
     },
     {
      "type": "plain",
      "text": "\n\n @138\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/518+Vazk5SL._SX466_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Home & Décor \n                Artificial Flora \nPryce138 \nDicos82 \n\n❌Regular Price  : ₹  699   /- 😱"
     }
    ]
   },
   {
    "id": 20803,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683270020",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "152",
    "dicos": "62",
    "catagory":"Health",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Luvyh Aloe Vera Face Massage Cream & Skin Moisturizer Cream (100g) For Women and Men, Organic Non-Toxic Aloe Vera for Acne, Scars, Glowing & Radiant Skin Treatment for oily, and dry Skin✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pf04RD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/427WoQj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/425mO57"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44t3GzH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p3lEbM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44xxnzz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42dStBO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p6EqiB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42jPwiM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44uQOct"
     },
     {
      "type": "plain",
      "text": "\n\n @152\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/716vqrjm0OL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Health"
     },
     {
      "type": "plain",
      "text": " & Personal Care\n\n                Beauty \n                Skin Care \n                Face \nPryce152 \nDicos62 \n\n❌Regular Price  : ₹  399   /- 😱"
     }
    ]
   },
   {
    "id": 20804,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683270088",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "149",
    "dicos": "62",
    "catagory":"Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Pigeon Stainless Steel Inox Hydra 750 Drinking Water Bottle 700 ml - Silver (Pack of 1)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VybXOW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NYGL9V"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NGP2yL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VC2nuv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LvnFVQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lzg9t2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NOY3G2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nuLu8c"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LBaYZB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LwTRbv"
     },
     {
      "type": "plain",
      "text": "\n\n @149\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51TVF-bHICL._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Dining \n                Kitchen Storage & Containers \nPryce149 \nDicos62 \n\n❌Regular Price  : ₹  395  /- 😱"
     }
    ]
   },
   {
    "id": 20805,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683270129",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "793",
    "dicos": "60",
    "catagory":"Health",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   VGR V-959 Professional Rechargeable cordless Hair & Beard Trimmer with Digital Display Stainless steel Blades, USB Charging cable, 3 Guide Combs for men Runtime: 180 mins 1200 mAh Li-ion Battery, Gold✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NJNKmL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nqebTX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AUcVvg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LWO4ND"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NDj32H"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VzuH0k"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42tgxjw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nxM0SV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44yd5pW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pdVKSY"
     },
     {
      "type": "plain",
      "text": "\n\n @793\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61-yj1Acn1L._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Health"
     },
     {
      "type": "plain",
      "text": " & Personal Care\n\n                Beauty \n                Hair Care \n                Hair Cutting Tools \nPryce793 \nDicos60 \n\n❌Regular Price  : ₹   1599   /- 😱"
     }
    ]
   },
   {
    "id": 20806,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683270191",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "998",
    "dicos": "71",
    "catagory":"Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Oakter Mini UPS for 12V WiFi Router Broadband Modem | Backup Upto 4 Hours | WiFi Router UPS Power Backup During Power Cuts | UPS for 12V Router Broadband Modem | Current Surge & Deep Discharge Protection✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42qbujR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/427zd8U"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AZZuKp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NGVYMp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AUd55Q"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/419tjmq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NGMHUM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NBHZYl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/425mbIR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NJNYdB"
     },
     {
      "type": "plain",
      "text": "\n\n @998\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/31PfpEPlg-L._SX300_SY300_QL70_FMwebp_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Computers & Accessories \n                 Networking Devices \nPryce998 \nDicos71 \n\n❌Regular Price  : ₹   2499   /- 😱"
     }
    ]
   },
   {
    "id": 20807,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683270232",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "299",
    "dicos": "25",
    "catagory":"Beauty",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Park Avenue Voyage Obsession Eau De Parfum For Men, 50 ml✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AZ4Rto"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AUQu9t"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/428rOpF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nxMpVr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AXG3C4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HGFosr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42osiYD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HIQb5n"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HHZm5R"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HHZpyz"
     },
     {
      "type": "plain",
      "text": "\n\n @299\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71Nt9zi-YbL._SX450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Beauty"
     },
     {
      "type": "plain",
      "text": "\n\n                Beauty \n                Fragrance \n                 Perfume \nPryce299 \nDicos25 \n\n❌Regular Price  : ₹ 399  /- 😱"
     }
    ]
   },
   {
    "id": 20808,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683270282",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "249",
    "dicos": "69",
    "catagory":"Sports",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Lifelong Yoga Mat for Women & Men EVA Material 4mm Anti-Slip Mat with Strap for Gym Workout, (LLYM71, Green)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/424c2fy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42uQXuL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p9WTLa"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42ir6Ge"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/428AYmj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lvo22G"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HHrqqi"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LsJIMI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HHmodi"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VzuNVK"
     },
     {
      "type": "plain",
      "text": "\n\n @249\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/41I94oaF5nL._SX300_SY300_QL70_FMwebp_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Sports"
     },
     {
      "type": "plain",
      "text": ", Fitness & Outdoors\n\n                Sports, Fitness & Outdoors \n                Exercise & Fitness \n                Yoga \nPryce249 \nDicos69 \n\n❌Regular Price  : ₹   699  /- 😱"
     }
    ]
   },
   {
    "id": 20809,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683270345",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "669",
    "dicos": "55",
    "catagory":"Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   RPM Euro Games Gaming Keyboard Wired | 87 Keys Space Saving Design | Membrane Keyboard with Mechanical Feel | LED Backlit & Spill Proof Design✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LCn9oV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nxmXiV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VC2Ixh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HFwBH7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VyjHjK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VyjHjK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3noMAm8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HG0neK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/4146NLz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44qaT3H"
     },
     {
      "type": "plain",
      "text": "\n\n @669\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/616fdRSET7L._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Computers & Accessories \n                Accessories & Peripherals \n                PC Gaming Peripherals \nPryce669 \nDicos55 \n\n❌Regular Price  : ₹  1299   /- 😱"
     }
    ]
   },
   {
    "id": 20810,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683270409",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1499",
    "dicos": "50",
    "catagory":"Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Cosmic Byte Vulcan RGB Headphone Stand, Mouse Bungee and 4 Port USB 2.0 HUB with RGB Touch Control Button (Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ny5kzu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42mNLSl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HGLcSt"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/419r6HF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HJeaRU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nuqQ8l"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HLz6rl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HGD7NM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HGL6u5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42lZpw0"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1499"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61Y8u4etjAL._SY741_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Computers & Accessories \n                Accessories & Peripherals \n                PC Gaming Peripherals \nPryce1499 \nDicos50 \n\n❌Regular Price  : ₹  2499  /- 😱"
     }
    ]
   },
   {
    "id": 20811,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683270448",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "379",
    "dicos": "75",
    "catagory":"Car",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   CarBinic Bike Cover - Universal | Water Resistant (Tested) and Dustproof UV Protection for All Two Wheeler (Bikes/Scooty) with Carry Bag & Mirror Pockets | Solid Silver✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M0kI1a"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VBqYiL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NMjU1b"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LXQPOL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vycssa"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M0kVS0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LXR10r"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LXgwz3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LXl4W4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42tC7Vo"
     },
     {
      "type": "plain",
      "text": "\n\n @379\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61qTPNIXiTL._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Car"
     },
     {
      "type": "plain",
      "text": " & Motorbike\n\n                Sports, Fitness & Outdoors \n                Cycling \n                Accessories \nPryce379 \nDicos75 \n\n❌Regular Price  : ₹  1199  /- 😱"
     }
    ]
   },
   {
    "id": 20812,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683270516",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "4399",
    "dicos": "51",
    "catagory":"Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   boAt AAVANTE Bar 1300 60W 2.0 Channel Bluetooth Soundbar Signature Sound, Multiple Connectivity Modes, Entertainment Modes, Premium Finish and Bluetooth V5.0(Premium Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LX4eqe"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LXRfEP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3M06Q73"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42jQnA0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VCLuzS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LWOBiB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/419Xr0K"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/418pq0M"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pgalNt"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/428BbG7"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@4399"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/31C3lHbbdGL._SX300_SY300_QL70_FMwebp_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Home Audio \n                Speakers \nPryce4399 \nDicos51 \n\n❌Regular Price  : ₹   6999  /- 😱"
     }
    ]
   },
   {
    "id": 20813,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683270562",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "268",
    "dicos": "95",
    "catagory":"Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Syriddyaas With Device® Prime Collection 169 TC Satin Striped Fabric Single Sized(90x64) Inch 1 Bedsheet with 1 Pillow Covers -for Home,Hotel & Hospital use Single Bedsheet for Single Bed✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/415IUU4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LEPHOG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44x4wLJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/419XvO2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/425IcHK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44vkIx4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44tQ5bx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NJOrwn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NGPGwb"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pcOtCT"
     },
     {
      "type": "plain",
      "text": "\n\n @268\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71R-B3vUIBL._SX522_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Home Furnishing \n                Bedding & Linen \nPryce268 \nDicos95 \n\n❌Regular Price  : ₹  1999    /- 😱"
     }
    ]
   },
   {
    "id": 20814,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683270625",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "447",
    "dicos": "70",
    "catagory":"Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   ZEBRONICS Zeb Evolve Wireless Bluetooth in Ear Neckband Earphone, Rapid Charge, Dual Pairing, Magnetic earpiece,Voice Assistant with Mic (Orange)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42tGMX1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42s5qaM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p6RnsF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pfAzju"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44IlsPM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pdjwyt"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44yTOV2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44vkZQC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VBG6wx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VBrv4f"
     },
     {
      "type": "plain",
      "text": "\n\n @447\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71Ym1z5Vh7L._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Headphones, Earbuds & Accessories \n                Headphones \nPryce447 \nDicos70 \n\n❌Regular Price  : ₹  1199   /- 😱"
     }
    ]
   },
   {
    "id": 20815,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683270688",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "44999",
    "dicos": "18",
    "catagory":"Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   iQOO 9T 5G (Legend, 8GB RAM, 128GB Storage) | Snapdragon® 8+ Gen 1 | 120W FlashCharge | 120Hz E5 AMOLED Display✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NGCQOx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LCnsQB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lziap1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AYRoBA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LXRAY7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LXh1ZX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AXGxIo"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AUDd0i"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NOZ1Ca"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VBGlaV"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@44999"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/6157kr+aeaL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Mobiles & Accessories \n                Smartphones & Basic Mobiles \nPryce44999 \nDicos18 \n\n❌Regular Price  : ₹  51999   /- 😱"
     }
    ]
   },
   {
    "id": 20816,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683270753",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "10999",
    "dicos": "15",
    "catagory":"Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   realme narzo N55 (Prime Blue, 4GB+64GB) 33W Segment Fastest Charging | Super High-res 64MP Primary AI Camera✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42sHAM8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pajtTV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AY13se"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42qy1Nx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/428LqtT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pf11JH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3noNleY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/419lPQr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41cl0WS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3peSvdK"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@10999"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71Ftzmh3XWL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Mobiles & Accessories \n                Smartphones & Basic Mobiles \nPryce10999 \nDicos15 \n\n❌Regular Price  : ₹  12305  /- 😱"
     }
    ]
   },
   {
    "id": 20817,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683270818",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "103",
    "dicos": "51",
    "catagory":"Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Asian Paints ezyCR8 apcolite Spray Paint, DIY Black Aerosol Gloss Finish 200ml✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AUdPYG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3paHOcl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/416ofiK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42mOaUR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ATMfLg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HCWMOr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LyS59S"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HI017l"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3paLjzo"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ny5xmg"
     },
     {
      "type": "plain",
      "text": "\n\n @103\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51MdUZvYnyL._SX466_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Home & kitchen | Prime shopping days \n                Prime Units \nPryce103 \nDicos51 \n\n❌Regular Price  : ₹ 200   /- 😱"
     }
    ]
   },
   {
    "id": 20818,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683270857",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "347",
    "dicos": "86",
    "catagory":"Shoes",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Centrino Mens 6042-02 Moccasin✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HHmUbe"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HLznun"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HGG8xJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/428sAD5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/425n7wR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AXHjVO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AYRXvc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LEQ0sO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LCnYOx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LsWf2U"
     },
     {
      "type": "plain",
      "text": "\n\n @347\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71fKgCJJT4L._UY500_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Shoes"
     },
     {
      "type": "plain",
      "text": " & Handbags\n\n                Shoes & Handbags \n                Shoes \n                Men's Shoes \nPryce347 \nDicos86 \n\n❌Regular Price  : ₹1499    /- 😱"
     }
    ]
   },
   {
    "id": 20819,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683270921",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "4489",
    "dicos": "50",
    "catagory":"Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Sony WF-C500 Truly Wireless Bluetooth Earbuds with 20Hrs Battery, True Wireless Earbuds with Mic for Phone Calls, Quick Charge, Fast Pair, 360 Reality Audio, Upscale Music - DSEE, App Support - Black✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LzQ6lx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LAW45N"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42vXNjD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HG0NSm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41dpz33"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42otrzp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HJeKPA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HGM2i5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42sI2Kk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42sI2Kk"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@4489"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51sj+JxisFL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Headphones, Earbuds & Accessories \n                Headphones \nPryce4489 \nDicos50 \n\n❌Regular Price  : ₹ 7499   /- 😱"
     }
    ]
   },
   {
    "id": 20820,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683270985",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "90",
    "dicos": "36",
    "catagory":"Office",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Navneet Youva | Wooden Exam Board for Students and Artists | Size - 24 cm x 34.5 cm | Pack of 1✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nxxiLO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LXhivX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pajU0v"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nAloAU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LXUyMk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/416Ndym"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nuhMQT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p9XSek"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LYpP1O"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44yEKGW"
     },
     {
      "type": "plain",
      "text": "\n\n @90\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61uyJKuWntL._SY450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Office"
     },
     {
      "type": "plain",
      "text": " Products\n\n                Office Products \n                Office Supplies \n                Desk Accessories & Storage Products \nPryce90 \nDicos36 \n\n❌Regular Price  : ₹  140  /- 😱"
     }
    ]
   },
   {
    "id": 20821,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683271050",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "3999",
    "dicos": "43",
    "catagory":"Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Havells Power Hunk 800 watt Mixer Grinder with 3 Wider mouth Stainless Steel Jar, Hands Free operation, SS-304 Grade Blade & 7 year motor warranty (Grey)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44t5etv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44uMY32"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41dpYCB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pcdkGU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/425NWkH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vx9T9P"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pdk9rP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VBGKKt"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44qbZMR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42K77k7"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@3999"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51QvcG7iLOL._SX466_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Home Appliances \n                Small Kitchen Appliances \nPryce3999 \nDicos43 \n\n❌Regular Price  : ₹5499    /- 😱"
     }
    ]
   },
   {
    "id": 20822,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683271112",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "2337",
    "dicos": "42",
    "catagory":"Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   USHA 1212 PTC with Adjustable Thermostat Fan Heater (Black/Brown, 1500-Watts).✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/427GjKm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42otDP9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LEQvDc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pcdyxK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44ytcnm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44yexZq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VzS4a8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44yUtWw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44tRaQD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nrxfRN"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@2337"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/81NR4J5CNwL._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Heating, Cooling & Air Quality \n                Room Heaters \nPryce2337 \nDicos42 \n\n❌Regular Price  : ₹  3499  /- 😱"
     }
    ]
   },
   {
    "id": 20823,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683271177",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1798",
    "dicos": "40",
    "catagory":"Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   JBL Go 2, Wireless Portable Bluetooth Speaker with Mic, Signature Sound, Vibrant Color Options with IPX7 Waterproof & AUX (Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NBJd5T"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LTHfMR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NDkkH1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AWq3Qx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42om1w2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NFuiYk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42K7qLN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NGMqkL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42plfPt"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42nRn60"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1798"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71gNS1OH3qL._SY450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Home Audio \n                Speakers \nPryce1798 \nDicos40 \n\n❌Regular Price  : ₹ 2499   /- 😱"
     }
    ]
   },
   {
    "id": 20824,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683271241",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "799",
    "dicos": "60",
    "catagory":"Garden",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   XERGY Solar Light Fairy Outdoor String, 79Ft 240 LED with 1200mAh Inbuilt Rechargeable Battery IP65 Waterproof 8 Modes Copper Wire for Garden Yard Diwali Christmas Tree Home Party Outdoor Decorative✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AT6s3T"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41YG7x7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3peygNg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/419skTh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/424NnaX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NH6tPT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/428t7VB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3BfKKr3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AXqLgv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NHZaYm"
     },
     {
      "type": "plain",
      "text": "\n\n @799\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71ZY7uchoKL._SY606_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Garden"
     },
     {
      "type": "plain",
      "text": " & Outdoors\n\n                Outdoor Living \n                Solar & Wind Power \n                 Solar Lights, Heaters & Gadgets \nPryce799 \nDicos60 \n\n❌Regular Price  : ₹ 1650   /- 😱"
     }
    ]
   },
   {
    "id": 20825,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683271304",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "15989",
    "dicos": "20",
    "catagory":"Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Samsung Galaxy Buds2 Pro, Bluetooth Truly Wireless in Ear Earbuds with Noise Cancellation (Graphite, with Mic)- RS 5000 Instant Discount on All Bank CC/DC✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ns2Ano"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LAkbBw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HLzWEv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nz2dr4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41nhGbL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AVmkTh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LzjkRp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41aC1Rj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HJzZRf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p5ZrtD"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@15989"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61Qqg+T8nsL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Headphones, Earbuds & Accessories \n                Headphones \nPryce15989 \nDicos20 \n\n❌Regular Price  : ₹  18999  /- 😱"
     }
    ]
   },
   {
    "id": 20826,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683271369",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "649",
    "dicos": "74",
    "catagory":"Sports",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   FitBox Sports Intruder 20 Kg Adjustable PVC Dumbbells Weights With Dumbbells Rods For Home Gym & Strength Training, 10 Kg X 2 (Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42qyK1d"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nvw3wy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/422ShFc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p8Iij8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HHD6sK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42mOTW5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NGOedu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B1SRXK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NJPWdZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42dUkGM"
     },
     {
      "type": "plain",
      "text": "\n\n @649\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51aHGGMCgnL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Sports"
     },
     {
      "type": "plain",
      "text": ", Fitness & Outdoors\n\n                Sports, Fitness & Outdoors \n                Exercise & Fitness \n                Strength Training Equipment \nPryce649 \nDicos74 \n\n❌Regular Price  : ₹  1799  /- 😱"
     }
    ]
   },
   {
    "id": 20827,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683271434",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1449",
    "dicos": "42",
    "catagory":"Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Cello Supreme Hand Beater Blender, 1 Piece, Red✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LApGjI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LAWrgH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42it61c"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nB7WN1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LCowUB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/419mO35"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42plzxF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LAq0iq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HJyMt7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41aCaEl"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1449"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/81hVHGt08+L._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Home Appliances \n                Small Kitchen Appliances \nPryce1449 \nDicos42 \n\n❌Regular Price  : ₹ 2099   /- 😱"
     }
    ]
   },
   {
    "id": 20828,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683271498",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "4599",
    "dicos": "69",
    "catagory":"Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Lifelong Mastiquer Cold Press Slow Juicer All-in-1 Fruit & Vegetable Juicer (2 Years Warranty)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41486dr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HHDwPQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HI4uqV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LV9Npk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VxiTvJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LXFNsT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LVxj5s"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VzvY7z"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nAm7C8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VBMy6R"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@4599"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71vlM2Vbi5L._SX522_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Home Appliances \n                Small Kitchen Appliances \nPryce4599 \nDicos69 \n\n❌Regular Price  : ₹ 11499   /- 😱"
     }
    ]
   },
   {
    "id": 20829,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683271538",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "99",
    "dicos": "34",
    "catagory":"Health",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Equinox Digital Thermometer EQ-DT-63, LCD Display, Underarm and Oral Use, Lightweight, 18 Months Warranty✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LVrbu8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44unwut"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lytd1L"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41489G9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LyTnle"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44zWXnU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44yf9hG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41bULjg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VGOWte"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NGcvQE"
     },
     {
      "type": "plain",
      "text": "\n\n @99\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51+E5UkoSnL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Health"
     },
     {
      "type": "plain",
      "text": " & Personal Care\n\n                Health & Personal Care \n                Health Care \n                 Thermometers \nPryce99 \nDicos34"
     }
    ]
   },
   {
    "id": 20830,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683271601",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "7999",
    "dicos": "20",
    "catagory":"Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   realme narzo 50i Prime (Mint Green 4GB RAM+64GB Storage) Octa-core Processor |5000 mAh Battery✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/418qMZq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/418viXW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NOZWm6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NJ1EWs"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NFuF5a"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/416wj2H"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nuiDB5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lzi0y0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LVvX9V"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42jS3cM"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@7999"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/81vkmw6NgxL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Mobiles & Accessories \n                Smartphones & Basic Mobiles \nPryce7999 \nDicos20 \n\n❌Regular Price  : ₹9499    /- 😱"
     }
    ]
   },
   {
    "id": 20831,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683271642",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "3999",
    "dicos": "73",
    "catagory":"Health",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Amazon Basics Corded Electric Massager for Foot with Heat Function, White & Gray✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42sIQyQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3paIQVL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Ly2r9T"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44JwTqv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pce3rC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44vmgXU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pcPSt9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44tRHC7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44vmj62"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44t63T7"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@3999"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71zcB-ZCQtL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Health"
     },
     {
      "type": "plain",
      "text": " & Personal Care\n\n                Health & Personal Care \n                Health Care \n                Massage & Relaxation \nPryce3999 \nDicos73 \n\n❌Regular Price  : ₹  10999  /- 😱"
     }
    ]
   },
   {
    "id": 20832,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683271682",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1149",
    "dicos": "69",
    "catagory":"Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Maxx PX4 Truly Wireless Earbuds with Upto 27 Hrs Playtime, IPX6 Water and Sweat Resistant, Touch Control, Super Fast Charging (White)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LZtTyT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NFLHQA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VC4E91"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LXn80g"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44uOke8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44AeLyX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VCl012"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3noOxyY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42m1bgE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pfCh4o"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1149"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51X5rtwbikL._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Headphones, Earbuds & Accessories \n                Headphones \nPryce1149 \nDicos69 \n\n❌Regular Price  : ₹ 3099   /- 😱"
     }
    ]
   },
   {
    "id": 20833,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683271744",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "66999",
    "dicos": "16",
    "catagory":"All",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Apple iPhone 14 (128 GB) - Starlight✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41fWCUw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AXLe4Q"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/4162orx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AV6nws"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nvDXGa"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ATNoCy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VzxpTy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VzxnuU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VC51jV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VBHMWR"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@66999"
     },
     {
      "type": "plain",
      "text": "\n \n "
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/618Bb+QzCmL._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#All"
     },
     {
      "type": "plain",
      "text": "\n\nPryce66999 \nDicos16 \n\n❌Regular Price  : ₹  75999  /- 😱"
     }
    ]
   },
   {
    "id": 20834,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683271785",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "417",
    "dicos": "83",
    "catagory":"Shoes",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Centrino Mens 6063-01 Moccasin✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AXvbnr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AWth6F"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HJfpk2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VBLpfF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VBLpfF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VBLpfF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HFb1Co"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NYJ9xp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HI4Uxv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LAWVU3"
     },
     {
      "type": "plain",
      "text": "\n\n @417\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71ZlTWRWp8L._UY500_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Shoes"
     },
     {
      "type": "plain",
      "text": " & Handbags\n\n                Shoes & Handbags \n                Shoes \n                Men's Shoes \nPryce417 \nDicos83 \n\n❌Regular Price  : ₹ 1899   /- 😱"
     }
    ]
   },
   {
    "id": 20835,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683271848",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "899",
    "dicos": "80",
    "catagory":"Clothing",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Lavie Sport Chairman Laptop Bag | Premium Leatherette Business Backpack for Men & Women | Durable Office Bag for Notebook/MacBook✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LChmjj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NATHm2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pf2JL7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42m1nwo"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/417awbe"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AVqU3R"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p140p5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/422SEQ6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p6TRY1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HJzkzb"
     },
     {
      "type": "plain",
      "text": "\n\n @899\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/81R8e7K8GlL._UX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Clothing"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Bags, Wallets and Luggage \n                Bags & Backpacks \n                Backpacks \nPryce899 \nDicos80 \n\n❌Regular Price  : ₹ 1999   /- 😱"
     }
    ]
   },
   {
    "id": 20836,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683271911",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "111",
    "dicos": "78",
    "catagory":"Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   FLiX (Beetel Rush 18CC USB Type C to Type C Dash Smartphone Charging Cable, upto 18W 3A super fast charging, Made in India, 1 Meter Cable Red, XCD-RTCC118✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/424eAu8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/417nUw7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/417nUw7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HGHFUv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HHw9bm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3paMOh0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HGHVmr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VxcznW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HCYAXJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LTIuvv"
     },
     {
      "type": "plain",
      "text": "\n\n @111\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/41xe0L+yXLL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Computers & Accessories \n                Accessories & Peripherals \n                Cables & Accessories \nPryce111 \nDicos78 \n\n❌Regular Price  : ₹ 399   /- 😱"
     }
    ]
   },
   {
    "id": 20837,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683271975",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "2299",
    "dicos": "74",
    "catagory":"Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Thermador Toughened ISI Certified 3 Brass Burner Glass Gas Stove (LPG Use Only, Auto Ignition, Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LVwnNx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ND2O5x"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42dVh1O"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LCpKzb"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44qdPgJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NHkLAe"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LziWT2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LziWT2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44uTLtz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42qzLX5"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@2299"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61uQak884oL._SX466_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & dining \n                Steal deals \nPryce2299 \nDicos74 \n\n❌Regular Price  : ₹ 6499   /- 😱"
     }
    ]
   },
   {
    "id": 20838,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683272040",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "4995",
    "dicos": "75",
    "catagory":"Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Instant Pot Air Fryer Vortex 4 Litre Essential 360 Degree EvenCrisp Technology Uses 95 % less Oil Nonstick and Dishwasher Safe Basket Fast Cooking Includes Free App with over 100 Recipes Vortex✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AWroqx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VBLH6f"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HGIbBN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VGPKyg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HI2te3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VxjDRx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VGPMGo"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vwu4ot"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AUoQcD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VGPXBy"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@4995"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71IN+z1WhyL._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Home Appliances \n                Small Kitchen Appliances \nPryce4995 \nDicos75 \n\n❌Regular Price  : ₹  14999  /- 😱"
     }
    ]
   },
   {
    "id": 20839,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683272102",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "299",
    "dicos": "NaN",
    "catagory":"Beauty",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Love Beauty & Planet Moisturising Body Wash 200ml, with Murumuru Butter & Rose, Sulfate Free, Paraben Free- Liquid Shower Gel✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B3jR9j"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nxpdXr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vyfc94"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vzx82V"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HGImNt"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VHAb9p"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HGIo83"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/4181gUf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41cmKPU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/417oj1B"
     },
     {
      "type": "plain",
      "text": "\n\n @299\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/518nBwENR-L._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Beauty"
     },
     {
      "type": "plain",
      "text": "\n\n                Beauty \n                Bath & Body \n                Cleansers \nPryce299 \nDicosNaN \n\n❌Regular Price  : ₹  300  /- 😱"
     }
    ]
   },
   {
    "id": 20840,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683272165",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "7499",
    "dicos": "56",
    "catagory":"Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   VW 80 cm (32 inches) Linux Series Frameless HD Ready Smart LED TV VW32C2 (Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3phrUwA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44JxIzB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AVnAWv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AXJ712"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ND37xd"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AXh3dW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42ovmE7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pcQHlJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HHEkUS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44zK6SD"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@7499"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71jVRzxB1wL._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Home Theater, TV & Video \n                Televisions \nPryce7499 \nDicos56 \n\n❌Regular Price  : ₹ 13599   /- 😱"
     }
    ]
   },
   {
    "id": 20841,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683272229",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "4497",
    "dicos": "48",
    "catagory":"Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   ZEBRONICS ZEB-BT800RUF Tower Speaker with Wired mic for Karaoke, 50W RMS, 5.3 inch subwoofer, Dual 3 inch Drivers, BT v5.0, USB, FM, AUX, Remote Control and LED Display Black✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42pmWfN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HHtQ8m"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HFzaJf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pfDh8E"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/422TtIG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42cw7jV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/428DBob"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/427ZzaH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VBIsLT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/425PIST"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@4497"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51NGmfaxyhL._SY450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Home Audio \n                Speakers \nPryce4497 \nDicos48 \n\n❌Regular Price  : ₹7599  /- 😱"
     }
    ]
   },
   {
    "id": 20842,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683272270",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "149",
    "dicos": "85",
    "catagory":"Clothing",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   TDC Women's Plain Regular fit T-Shirt✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42aoSsM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/428LHwQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/428Ny4R"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LTIVpD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LVxu0O"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LV5HgX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42cwE5p"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42mQyuN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LXlPi1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LAlK2m"
     },
     {
      "type": "plain",
      "text": "\n\n @149\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/512uhvOZBVL._UX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Clothing"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Clothing & Accessories \n                Women \n                Western Wear \nPryce149 \nDicos85 \n\n❌Regular Price  : ₹  499  /- 😱"
     }
    ]
   },
   {
    "id": 20843,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683272311",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "349",
    "dicos": "50",
    "catagory":"Beauty",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Dabur Vatika Hair Fall Control Shampoo with Onion and Saw Palmetto Extract - 640ml | Reduces Hair Fall, Hair Thinning, Promotes Hair Growth & Maintains Scalp Health | For Stronger & Thicker Hair | Parabens & Sulphates Free | Fortified with Vitamin E & Pro-Vitamin B5✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LCqe8t"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NI0SZM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44xcDYP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44yuEGk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44tSGCj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/412gDxC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LzkLPN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nFrwYm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LsYbbG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NDlXED"
     },
     {
      "type": "plain",
      "text": "\n\n @349\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61e2vZOFsCL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Beauty"
     },
     {
      "type": "plain",
      "text": "\n\n                Beauty \n                Hair Care \n                Shampoo & Conditioner \nPryce349 \nDicos50 \n\n❌Regular Price  : ₹  699  /- 😱"
     }
    ]
   },
   {
    "id": 20844,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683272372",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "24900",
    "dicos": "33",
    "catagory":"All",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Apple Watch SE (GPS + Cellular, 44mm) - Space Grey Aluminium Case with Midnight Sport Band - Regular✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VE1PnF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p3p8uS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42ovFPh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LAlZdM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nCDJgr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nujAcD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LsMx0g"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LYDRQS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LWZpx9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lzl795"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@24900"
     },
     {
      "type": "plain",
      "text": "\n \n "
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71ZyBh4LQuL._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#All"
     },
     {
      "type": "plain",
      "text": "\n\nPryce24900 \nDicos33 \n\n❌Regular Price  : ₹ 32599   /- 😱"
     }
    ]
   },
   {
    "id": 20845,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683272439",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1075",
    "dicos": "49",
    "catagory":"Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Usha 3720 700-Watt 2-Slice Pop-up Toaster (Blue)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44xcR27"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/418sjPa"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/412Hbi5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44tVYFE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LZAr0q"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/427TXNQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LCTmfQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LYEcD8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NMmSml"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LCToEu"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1075"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/41VqWCrVulL._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Home Appliances \n                Small Kitchen Appliances \nPryce1075 \nDicos49 \n\n❌Regular Price  : ₹ 1999   /- 😱"
     }
    ]
   },
   {
    "id": 20846,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683272483",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "94",
    "dicos": "33",
    "catagory":"Beauty",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Fogg Scent Tycoon 30 ml✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NDm5UD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NGSyZZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41fYaOk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44upbQJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pf3IuN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44u6oVI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42tJU5d"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41drXH3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42qeIDZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44xB1tf"
     },
     {
      "type": "plain",
      "text": "\n\n @94\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51ITc0M-viL._SY450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Beauty"
     },
     {
      "type": "plain",
      "text": "\n\n                Beauty \n                Bath & Body \n                Deodorants & Antiperspirants \nPryce94 \nDicos33 \n\n❌Regular Price  : ₹  158  /- 😱"
     }
    ]
   },
   {
    "id": 20847,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683272549",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "34990",
    "dicos": "38",
    "catagory":"Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   ASUS Vivobook 14, Intel Core i3-1115G4 11th Gen, 14\" (35.56 cm) FHD, Thin and Light Laptop (8GB/512GB SSD/Office 2021/Windows 11 Home/Integrated Graphics/Fingerprint/Silver/1.6 kg), X415EA-EB322WS✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42nTeYw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AWsfHL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44u6uwy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ATOuya"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44IoCTE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AZ8iAi"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HFc9G8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44uUv1P"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AYUkOC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AXsulZ"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@34990"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71d058GzMXL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Computers & Accessories \n                Laptops \n                 Traditional Laptops \nPryce34990 \nDicos38 \n\n❌Regular Price  : ₹  49999  /- 😱"
     }
    ]
   },
   {
    "id": 20848,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683272615",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "58990",
    "dicos": "18",
    "catagory":"Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   HP 14s, 12th Gen Intel Core i5 16GB RAM/512GB SSD 14-inch(35.6 cm) Micro-Edge, FHD Laptop/Intel Iris Xe Graphics/Alexa/Dual Speakers/Win 11/Backlit KB/MSO 2021/1.41 Kg, 14s-dy5005TU✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HJBz5D"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pdmq6l"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3I4Ddz9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VBudGX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lvr8Um"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/419ubrd"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HCZRht"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LzllwX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AXwuml"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pf3S5n"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@58990"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/7193KfRaF+L._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Computers & Accessories \n                Laptops \n                 Traditional Laptops \nPryce58990 \nDicos18 \n\n❌Regular Price  : ₹  69999  /- 😱"
     }
    ]
   },
   {
    "id": 20849,
    "type": "message",
    "date": "2023-05-05",
    "date_unixtime": "1683272677",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "74490",
    "dicos": "20",
    "catagory":"Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   ASUS Vivobook 15 OLED (2023), Intel Core i5-13500H 13th Gen, 15.6\" (39.62 cm) FHD OLED, Thin & Light Laptop (16GB/512GB SSD/Intel Iris Xᵉ/Win 11/Office 2021/Backlit KB/Silver/1.7 kg), X1505VA-LK542WS✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3pf3T9r"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NEQtxI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AWtu9V"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AWsUZL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NJ3feU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nxQ19X"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42tkqoC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42prgf4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AWvgI9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42r5OpX"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@74490"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71Xq2PQIT1L._SY450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Computers & Accessories \n                Laptops \n                 Traditional Laptops \nPryce74490 \nDicos20 \n\n❌Regular Price  : ₹  89999   /- 😱"
     }
    ]
   },
  {
   "id": 20374,
   "type": "message",
   "date": "2023-05-04",
   "date_unixtime": "1683164439",
   "from": "All1App daily",
   "from_id": "channel1858370886",
   "photo": "(File not included. Change data exporting settings to download.)",
   "pryce": "1089",
   "dicos": "70",
   "catagory": "Home",
   "text_entities": [
    {
     "type": "plain",
     "text": "🚀🚀   ECHT Die Cast Aluminium Non Stick Combo Set of 2 (24 cm Frying Pan and 28cm Dosa Tawa), Granite Finish,Soft Touch Handle, idle for sauté,Frying,dosa and rotis vegies and Omelettes,Green & Grey✅\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3NCEvEL"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LVpQUe"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3VLl5zX"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/42jD2HK"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/42ptbjy"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/44m0sOB"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3p6Plca"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3AVR72t"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LwgP2u"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/44ufnq0"
    },
    {
     "type": "plain",
     "text": "\n\n "
    },
    {
     "type": "mention",
     "text": "@1089"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://m.media-amazon.com/images/I/81M-SbnYz5L._SX425_.jpg"
    },
    {
     "type": "plain",
     "text": " \n\n\n"
    },
    {
     "type": "hashtag",
     "text": "#Home"
    },
    {
     "type": "plain",
     "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Dining \n                Cookware \nPryce1089 \nDicos70 \n\n❌Regular Price  : ₹ 2999    /- 😱"
    }
   ]
  },
  {
   "id": 20375,
   "type": "message",
   "date": "2023-05-04",
   "date_unixtime": "1683164525",
   "from": "All1App daily",
   "from_id": "channel1858370886",
   "photo": "(File not included. Change data exporting settings to download.)",
   "pryce": "1429",
   "dicos": "68",
   "catagory": "Watches",
   "text_entities": [
    {
     "type": "plain",
     "text": "🚀🚀   Daniel Klein Analog Black Dial Men's Watch-DK.1.12611-1✅\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3NDyJmv"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/44raFcm"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/44E80MO"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/44sTQ0D"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3HDiZfE"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3Vu1DY6"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3ASaaKZ"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3AQTBPy"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/44rvpAQ"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3HyLbzV"
    },
    {
     "type": "plain",
     "text": "\n\n "
    },
    {
     "type": "mention",
     "text": "@1429"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://m.media-amazon.com/images/I/61tF0NmNViL._UY741_.jpg"
    },
    {
     "type": "plain",
     "text": " \n\n\n"
    },
    {
     "type": "hashtag",
     "text": "#Watches"
    },
    {
     "type": "plain",
     "text": "\n\n                Fashion \n                Onam 2021 \n                Watches and fashion jewellery \nPryce1429 \nDicos68 \n\n❌Regular Price  : ₹  2999   /- 😱"
    }
   ]
  },
  {
   "id": 20376,
   "type": "message",
   "date": "2023-05-04",
   "date_unixtime": "1683164590",
   "from": "All1App daily",
   "from_id": "channel1858370886",
   "photo": "(File not included. Change data exporting settings to download.)",
   "pryce": "350",
   "dicos": "85",
   "catagory": "Home",
   "text_entities": [
    {
     "type": "plain",
     "text": "🚀🚀   Heart Home Unbreakable Small Multipurpose Storage Baskets with lid|Design-Netted|Material-Plastic|Shape-Oval|Color-Brown,Beige and Grey|Pack of 3✅\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/4159WLl"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3Vu7Cfq"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3oWRWVR"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/42oxTyg"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/411EqxK"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3AVRutT"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3HyR54j"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3VxqVEO"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/4214ytH"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3VvJARm"
    },
    {
     "type": "plain",
     "text": "\n\n @350\n"
    },
    {
     "type": "link",
     "text": "https://m.media-amazon.com/images/I/71yL-hI4U-L._SX425_.jpg"
    },
    {
     "type": "plain",
     "text": " \n\n\n"
    },
    {
     "type": "hashtag",
     "text": "#Home"
    },
    {
     "type": "plain",
     "text": " & Kitchen\n\n                Home & Kitchen \n                Home Storage & Organisation \n                Boxes, Baskets & Bins \nPryce350 \nDicos85 \n\n❌Regular Price  : ₹ 1599   /- 😱"
    }
   ]
  },
  {
   "id": 20377,
   "type": "message",
   "date": "2023-05-04",
   "date_unixtime": "1683164660",
   "from": "All1App daily",
   "from_id": "channel1858370886",
   "photo": "(File not included. Change data exporting settings to download.)",
   "pryce": "9597",
   "dicos": "80",
   "catagory": "Clothing",
   "text_entities": [
    {
     "type": "plain",
     "text": "🚀🚀   Nasher Miles Bruges Hard-Sided Polypropylene Luggage Set of 3 Pastel Purple Trolley Bags (55, 65 & 75 cm)✅\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LTBLSj"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3nBahYa"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3HDnbvK"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/415OHJ0"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/40Yv9pX"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/416oES9"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/42hOlA0"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/42lwWq9"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/413dcqr"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/412HZDM"
    },
    {
     "type": "plain",
     "text": "\n\n "
    },
    {
     "type": "mention",
     "text": "@9597"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://m.media-amazon.com/images/I/51UxXCtVFCL._SY741_.jpg"
    },
    {
     "type": "plain",
     "text": " \n\n\n"
    },
    {
     "type": "hashtag",
     "text": "#Clothing"
    },
    {
     "type": "plain",
     "text": " & Accessories\n\n                Bags, Wallets and Luggage \n                Luggage \n                 Luggage Sets \nPryce9597 \nDicos80 \n\n❌Regular Price  : ₹  24999   /- 😱"
    }
   ]
  },
  {
   "id": 20378,
   "type": "message",
   "date": "2023-05-04",
   "date_unixtime": "1683164724",
   "from": "All1App daily",
   "from_id": "channel1858370886",
   "photo": "(File not included. Change data exporting settings to download.)",
   "pryce": "1912",
   "dicos": "NaN",
   "catagory": "Home",
   "text_entities": [
    {
     "type": "plain",
     "text": "🚀🚀   anchor by panasonic Felicia Star High Speed Ceiling Fan | 1200mm 1 Star Rated Ceiling Fan with 390 RPM (2 Yrs Warranty) (Matt Brown, 1 Piece)✅\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/415g0Dx"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/42FT6Uz"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/42ptVoQ"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/42jDQfK"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3nnp6Of"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3p1MRLX"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/42k9PfM"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/42rLqoJ"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/423MbnZ"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3oZdj90"
    },
    {
     "type": "plain",
     "text": "\n\n "
    },
    {
     "type": "mention",
     "text": "@1912"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://m.media-amazon.com/images/I/41Iw5xpwkOL._SX425_.jpg"
    },
    {
     "type": "plain",
     "text": " \n\n\n"
    },
    {
     "type": "hashtag",
     "text": "#Home"
    },
    {
     "type": "plain",
     "text": " & Kitchen\n\n                Home & Kitchen \n                Heating, Cooling & Air Quality \n                Fans \nPryce1912 \nDicosNaN \n\n❌Regular Price  : ₹   1799  /- 😱"
    }
   ]
  },
  {
   "id": 20379,
   "type": "message",
   "date": "2023-05-04",
   "date_unixtime": "1683164807",
   "from": "All1App daily",
   "from_id": "channel1858370886",
   "photo": "(File not included. Change data exporting settings to download.)",
   "pryce": "99",
   "dicos": "75",
   "catagory": "Computers",
   "text_entities": [
    {
     "type": "plain",
     "text": "🚀🚀   tizum Mouse Pad/Computer Mouse Mat with Anti-Slip Rubber Base | Smooth Mouse Control | Spill-Resistant Surface for Laptop, Notebook, MacBook, Gaming, Laser/Optical Mouse, 9.4”x 7.9”, Green & Blue✅\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3nrcgOL"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3ntBPid"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3nmf5kl"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LzHXh1"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LR1Ql0"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3Lzymqw"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3NFKPeP"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3NG8POW"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3HCH8CJ"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3AQLvqj"
    },
    {
     "type": "plain",
     "text": "\n\n @99\n"
    },
    {
     "type": "link",
     "text": "https://m.media-amazon.com/images/I/81XSX9okfML._SY450_.jpg"
    },
    {
     "type": "plain",
     "text": " \n\n\n"
    },
    {
     "type": "hashtag",
     "text": "#Computers"
    },
    {
     "type": "plain",
     "text": " & Accessories\n\n                Computers & Accessories \n                Accessories & Peripherals \n                Keyboards, Mice & Input Devices \nPryce99 \nDicos75 \n\n❌Regular Price  : ₹ 399   /- 😱"
    }
   ]
  },
  {
   "id": 20380,
   "type": "message",
   "date": "2023-05-04",
   "date_unixtime": "1683164895",
   "from": "All1App daily",
   "from_id": "channel1858370886",
   "photo": "(File not included. Change data exporting settings to download.)",
   "pryce": "179",
   "dicos": "71",
   "catagory": "Home",
   "text_entities": [
    {
     "type": "plain",
     "text": "🚀🚀   Ganesh Plastic Vegetable Chopper Cutter, Pool Green (725 ml)✅\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3Vxafgv"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LWccAg"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/44ugkP6"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/44sZ2BN"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3nuTW7w"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3HDrXZY"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/4132OPm"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3p9wXiP"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3nuVGO6"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3nuW7bc"
    },
    {
     "type": "plain",
     "text": "\n\n @179\n"
    },
    {
     "type": "link",
     "text": "https://m.media-amazon.com/images/I/71LML7H192L._SX466_.jpg"
    },
    {
     "type": "plain",
     "text": " \n\n\n"
    },
    {
     "type": "hashtag",
     "text": "#Home"
    },
    {
     "type": "plain",
     "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Dining \n                Kitchen Tools \nPryce179 \nDicos71 \n\n❌Regular Price  : ₹    599  /- 😱"
    }
   ]
  },
  {
   "id": 20381,
   "type": "message",
   "date": "2023-05-04",
   "date_unixtime": "1683164961",
   "from": "All1App daily",
   "from_id": "channel1858370886",
   "photo": "(File not included. Change data exporting settings to download.)",
   "pryce": "1019",
   "dicos": "49",
   "catagory": "Beauty",
   "text_entities": [
    {
     "type": "plain",
     "text": "🚀🚀   Olay Night Serum: Regenerist Retinol 24 Serum, 30 ml✅\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LWcmrm"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/421DBGr"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3VxwN0B"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/42rFA6R"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/423Mijp"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3p5Zypl"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3p982vT"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3paSwPP"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/44pVgsW"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/44oU9cV"
    },
    {
     "type": "plain",
     "text": "\n\n "
    },
    {
     "type": "mention",
     "text": "@1019"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://m.media-amazon.com/images/I/51mwY-C7PiL._SX569_.jpg"
    },
    {
     "type": "plain",
     "text": " \n\n\n"
    },
    {
     "type": "hashtag",
     "text": "#Beauty"
    },
    {
     "type": "plain",
     "text": "\n\n                Beauty \n                Skin Care \n                Face \nPryce1019 \nDicos49 \n\n❌Regular Price  : ₹   1899  /- 😱"
    }
   ]
  },
  {
   "id": 20382,
   "type": "message",
   "date": "2023-05-04",
   "date_unixtime": "1683165045",
   "from": "All1App daily",
   "from_id": "channel1858370886",
   "photo": "(File not included. Change data exporting settings to download.)",
   "pryce": "349",
   "dicos": "81",
   "catagory": "Health",
   "text_entities": [
    {
     "type": "plain",
     "text": "🚀🚀   Control D Blood Glucose Monitor (Pack of 20 Strips, White)✅\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/41YK3xQ"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/44pJnmS"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/44rwDfq"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3Lukei5"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3VxDdNn"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/424t3q7"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/44FnGiX"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/44sZFeD"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3HB7m8E"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3AQwtk2"
    },
    {
     "type": "plain",
     "text": "\n\n @349\n"
    },
    {
     "type": "link",
     "text": "https://m.media-amazon.com/images/I/61B0FTYWHKL._SX569_.jpg"
    },
    {
     "type": "plain",
     "text": " \n\n\n"
    },
    {
     "type": "hashtag",
     "text": "#Health"
    },
    {
     "type": "plain",
     "text": " & Personal Care\n\n                Health & Personal Care \n                Home Medical Supplies & Equipment \n                Health Monitors \nPryce349 \nDicos81 \n\n❌Regular Price  : ₹  1299   /- 😱"
    }
   ]
  },
  {
   "id": 20383,
   "type": "message",
   "date": "2023-05-04",
   "date_unixtime": "1683165142",
   "from": "All1App daily",
   "from_id": "channel1858370886",
   "photo": "(File not included. Change data exporting settings to download.)",
   "pryce": "59",
   "dicos": "63",
   "catagory": "Grocery",
   "text_entities": [
    {
     "type": "plain",
     "text": "🚀🚀   Keya 100% Durum Wheat Penne Rigate Pasta, 500g | Master of Seasonings✅\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3AV1Uty"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3NAHUE6"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3Lwiq8u"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/42karC6"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/419iksV"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LtSRot"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3nrvljQ"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3NykdMV"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/42px87M"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/420Fmnp"
    },
    {
     "type": "plain",
     "text": "\n\n @59\n"
    },
    {
     "type": "link",
     "text": "https://m.media-amazon.com/images/I/41rbRfT0BSL.jpg"
    },
    {
     "type": "plain",
     "text": " \n\n\n"
    },
    {
     "type": "hashtag",
     "text": "#Grocery"
    },
    {
     "type": "plain",
     "text": " & Gourmet Foods\n\n                Grocery & Gourmet Foods \n                Pasta & Noodles \n                Pasta \nPryce59 \nDicos63 \n\n❌Regular Price  : ₹  160   /- 😱"
    }
   ]
  },
  {
   "id": 20384,
   "type": "message",
   "date": "2023-05-04",
   "date_unixtime": "1683165208",
   "from": "All1App daily",
   "from_id": "channel1858370886",
   "photo": "(File not included. Change data exporting settings to download.)",
   "pryce": "599",
   "dicos": "68",
   "catagory": "Electronics",
   "text_entities": [
    {
     "type": "plain",
     "text": "🚀🚀   Redgear Shadow Helm Gaming Wired Over Ear Headset with Mic with 50Mm Drivers, Superior Fit, Vox Technology and Multi-Purpose Audio Jack (Black)✅\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3Vu8Uag"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3Vxs7YO"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3NCPle1"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LzIVd9"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3HFuuTz"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3HDc7yM"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3nu2sDK"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3HDt1wW"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3HDcrO0"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3HCyRPt"
    },
    {
     "type": "plain",
     "text": "\n\n @599\n"
    },
    {
     "type": "link",
     "text": "https://m.media-amazon.com/images/I/71SOCAheeDL._SY450_.jpg"
    },
    {
     "type": "plain",
     "text": " \n\n\n"
    },
    {
     "type": "hashtag",
     "text": "#Electronics"
    },
    {
     "type": "plain",
     "text": "\n\n                Computers & Accessories \n                Accessories & Peripherals \n                PC Gaming Peripherals \nPryce599 \nDicos68 \n\n❌Regular Price  : ₹  1499   /- 😱"
    }
   ]
  },
  {
   "id": 20385,
   "type": "message",
   "date": "2023-05-04",
   "date_unixtime": "1683165271",
   "from": "All1App daily",
   "from_id": "channel1858370886",
   "photo": "(File not included. Change data exporting settings to download.)",
   "pryce": "55",
   "dicos": "50",
   "catagory": "Grocery",
   "text_entities": [
    {
     "type": "plain",
     "text": "🚀🚀   FRESH & HONEST Awake Filter Coffee Powder 70: 30 Blend, 200g✅\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/44qOenP"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3HDsWJE"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3paTgEB"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/412J9z8"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LTfu76"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3VzScX5"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/42eiH6G"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/42qLgOr"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/42hY7lM"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/416q7YF"
    },
    {
     "type": "plain",
     "text": "\n\n @55\n"
    },
    {
     "type": "link",
     "text": "https://m.media-amazon.com/images/I/61FQFw35aJL._SX569_.jpg"
    },
    {
     "type": "plain",
     "text": " \n\n\n"
    },
    {
     "type": "hashtag",
     "text": "#Grocery"
    },
    {
     "type": "plain",
     "text": " & Gourmet Foods\n\n                Grocery & Gourmet Foods \n                Coffee, Tea & Beverages \n                 Coffee \nPryce55 \nDicos50 \n\n❌Regular Price  : ₹  110   /- 😱"
    }
   ]
  },
  {
   "id": 20386,
   "type": "message",
   "date": "2023-05-04",
   "date_unixtime": "1683165358",
   "from": "All1App daily",
   "from_id": "channel1858370886",
   "photo": "(File not included. Change data exporting settings to download.)",
   "pryce": "3366",
   "dicos": "33",
   "catagory": "Home",
   "text_entities": [
    {
     "type": "plain",
     "text": "🚀🚀   Havells Glaze 1200mm 1 Star Energy Saving Ceiling Fan (Elegant White Gold, Pack of 1)✅\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/42qLkh9"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3p69cbl"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/44rco1k"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3ATEXXS"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3p6E1g2"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/44pK2ES"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/42fI34g"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/42kkPtB"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/423zcma"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/44pKeUC"
    },
    {
     "type": "plain",
     "text": "\n\n "
    },
    {
     "type": "mention",
     "text": "@3366"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://m.media-amazon.com/images/I/410nxidfTEL._SX466_.jpg"
    },
    {
     "type": "plain",
     "text": " \n\n\n"
    },
    {
     "type": "hashtag",
     "text": "#Home"
    },
    {
     "type": "plain",
     "text": " & Kitchen\n\n                Home & Kitchen \n                Heating, Cooling & Air Quality \n                Fans \nPryce3366 \nDicos33 \n\n❌Regular Price  : ₹   3999  /- 😱"
    }
   ]
  },
  {
   "id": 20387,
   "type": "message",
   "date": "2023-05-04",
   "date_unixtime": "1683165651",
   "from": "All1App daily",
   "from_id": "channel1858370886",
   "photo": "(File not included. Change data exporting settings to download.)",
   "pryce": "480",
   "dicos": "59",
   "catagory": "Home",
   "text_entities": [
    {
     "type": "plain",
     "text": "🚀🚀  Pigeon Inox Hydra 700 ml Sipper (Pack of 3, Black, Steel/Chrome, Steel)✅\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3nrqKhE"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LzPPzb"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LwjwRE"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3NCQe6l"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3NDiBSa"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3HFvjM9"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3NyW60n"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3NCMrWQ"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3HyNpPN"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3ASlIhh"
    },
    {
     "type": "plain",
     "text": "\n\n @480\n"
    },
    {
     "type": "link",
     "text": "https://m.media-amazon.com/images/I/71JmRJsGhUL._SX425_.jpg"
    },
    {
     "type": "plain",
     "text": " \n\n\n"
    },
    {
     "type": "hashtag",
     "text": "#Home"
    },
    {
     "type": "plain",
     "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Dining \n                Kitchen Storage & Containers \nPryce480 \nDicos59 \n\n❌Regular Price  : ₹  999   /- 😱"
    }
   ]
  },
  {
   "id": 20388,
   "type": "message",
   "date": "2023-05-04",
   "date_unixtime": "1683165714",
   "from": "All1App daily",
   "from_id": "channel1858370886",
   "photo": "(File not included. Change data exporting settings to download.)",
   "pryce": "98",
   "dicos": "75",
   "catagory": "Electronics",
   "text_entities": [
    {
     "type": "plain",
     "text": "🚀🚀   Portronics Konnect L 1.2M POR-1401 Fast Charging 3A 8 Pin USB Cable with Charge & Sync Function (White)✅\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3HCDBV9"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3p9z3PJ"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LwQ51R"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/413SHK7"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LR3Z02"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/415QZrA"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3HDdDRu"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3HDdDRu"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/423Kqay"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/42klDyD"
    },
    {
     "type": "plain",
     "text": "\n\n @98\n"
    },
    {
     "type": "link",
     "text": "https://m.media-amazon.com/images/I/41R08zLK69L._SX300_SY300_QL70_FMwebp_.jpg"
    },
    {
     "type": "plain",
     "text": " \n\n\n"
    },
    {
     "type": "hashtag",
     "text": "#Electronics"
    },
    {
     "type": "plain",
     "text": "\n\n                Computers & Accessories \n                Accessories & Peripherals \n                Cables & Accessories \nPryce98 \nDicos75 \n\n❌Regular Price  : ₹  349  /- 😱"
    }
   ]
  },
  {
   "id": 20389,
   "type": "message",
   "date": "2023-05-04",
   "date_unixtime": "1683165782",
   "from": "All1App daily",
   "from_id": "channel1858370886",
   "photo": "(File not included. Change data exporting settings to download.)",
   "pryce": "279",
   "dicos": "72",
   "catagory": "Electronics",
   "text_entities": [
    {
     "type": "plain",
     "text": "🚀🚀   Dyazo Water Resistant Laptop Sleeve \n"
    },
    {
     "type": "bot_command",
     "text": "/Case"
    },
    {
     "type": "plain",
     "text": "\n Cover for 15 Inches,15.6 Inch Laptops & Notebook (Black)✅\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/42hR7Fq"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3ATFOb2"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/44uMny8"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/42pvnHJ"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/44pL4AK"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/44kl3CK"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/415R6Dw"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3p6SJDU"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3nt78d8"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3p82zp2"
    },
    {
     "type": "plain",
     "text": "\n\n @279\n"
    },
    {
     "type": "link",
     "text": "https://m.media-amazon.com/images/I/81nrseSZGvL._SX569_.jpg"
    },
    {
     "type": "plain",
     "text": " \n\n\n"
    },
    {
     "type": "hashtag",
     "text": "#Electronics"
    },
    {
     "type": "plain",
     "text": "\n\n                Computers & Accessories \n                Accessories & Peripherals \n                Laptop Accessories \nPryce279 \nDicos72 \n\n❌Regular Price  : ₹ 899   /- 😱"
    }
   ]
  },
  {
   "id": 20390,
   "type": "message",
   "date": "2023-05-04",
   "date_unixtime": "1683165977",
   "from": "All1App daily",
   "from_id": "channel1858370886",
   "photo": "(File not included. Change data exporting settings to download.)",
   "pryce": "1450",
   "dicos": "50",
   "catagory": "Watches",
   "text_entities": [
    {
     "type": "plain",
     "text": "🚀🚀   Titan Analog Black Dial Men's Watch-1864SL08✅\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3NCMX7e"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3paUrUx"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3NGaVyi"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/44ub1iI"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/40YeXVX"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/44w8DYv"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3p6T89o"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/42ek9Ga"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/44vzxjt"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/428oJGf"
    },
    {
     "type": "plain",
     "text": "\n\n "
    },
    {
     "type": "mention",
     "text": "@1450"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://m.media-amazon.com/images/I/61M90it5LHL._UY500_.jpg"
    },
    {
     "type": "plain",
     "text": " \n\n\n"
    },
    {
     "type": "hashtag",
     "text": "#Watches"
    },
    {
     "type": "plain",
     "text": "\n\n                Watches \n                Trends \n                Men \nPryce1450 \nDicos50 \n\n❌Regular Price  : ₹  2499   /- 😱"
    }
   ]
  },
  {
   "id": 20391,
   "type": "message",
   "date": "2023-05-04",
   "date_unixtime": "1683166018",
   "from": "All1App daily",
   "from_id": "channel1858370886",
   "photo": "(File not included. Change data exporting settings to download.)",
   "pryce": "949",
   "dicos": "76",
   "catagory": "Shoes",
   "text_entities": [
    {
     "type": "plain",
     "text": "🚀🚀   Lino Perros Women's Artificial Leather Tote Bag (Red)✅\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/42l89CP"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/42262UN"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3nrrD9Y"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3Vtc0eT"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/42rOEZn"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LSSeGf"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/423OMOL"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3p6aoLR"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/42qMPvN"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/41UzMTn"
    },
    {
     "type": "plain",
     "text": "\n\n @949\n"
    },
    {
     "type": "link",
     "text": "https://m.media-amazon.com/images/I/61nOM5LydnL._UY500_.jpg"
    },
    {
     "type": "plain",
     "text": " \n\n\n"
    },
    {
     "type": "hashtag",
     "text": "#Shoes"
    },
    {
     "type": "plain",
     "text": " & Handbags\n\n                Shoes & Handbags \n                Handbags, Purses & Clutches \n                Handbags \nPryce949 \nDicos76 \n\n❌Regular Price  : ₹ 2999  /- 😱"
    }
   ]
  },
  {
   "id": 20392,
   "type": "message",
   "date": "2023-05-04",
   "date_unixtime": "1683166062",
   "from": "All1App daily",
   "from_id": "channel1858370886",
   "photo": "(File not included. Change data exporting settings to download.)",
   "pryce": "119",
   "dicos": "85",
   "catagory": "Computers",
   "text_entities": [
    {
     "type": "plain",
     "text": "🚀🚀   GIZGA essentials (35cm x 25cm Extended Gaming Mouse Pad, Laptop Desk Mat, Computer Mouse Pad with Smooth Mouse Control, Mercerized Surface, Antifray Stitched Embroidery Edges, Anti-Slip Rubber Base✅\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/42fJ5xa"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LUy8LU"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3nrxkVk"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3AUYAyS"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3ARgaUb"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3AUYQ0O"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3AXOTiQ"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3AV44cE"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3nqFsFC"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3p6aJhB"
    },
    {
     "type": "plain",
     "text": "\n\n @119\n"
    },
    {
     "type": "link",
     "text": "https://m.media-amazon.com/images/I/51s3y5bI-8L._SX300_SY300_QL70_FMwebp_.jpg"
    },
    {
     "type": "plain",
     "text": " \n\n\n"
    },
    {
     "type": "hashtag",
     "text": "#Computers"
    },
    {
     "type": "plain",
     "text": " & Accessories\n\n                Computers & Accessories \n                Accessories & Peripherals \n                Keyboards, Mice & Input Devices \nPryce119 \nDicos85 \n\n❌Regular Price  : ₹  699  /- 😱"
    }
   ]
  },
  {
   "id": 20393,
   "type": "message",
   "date": "2023-05-04",
   "date_unixtime": "1683166106",
   "from": "All1App daily",
   "from_id": "channel1858370886",
   "photo": "(File not included. Change data exporting settings to download.)",
   "pryce": "47999",
   "dicos": "39",
   "catagory": "Electronics",
   "text_entities": [
    {
     "type": "plain",
     "text": "🚀🚀   Acer 164 cm (65 inches) I Series 4K Ultra HD Android Smart LED TV (AR65AR2851UDFL) (Black)✅\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3HDQYEr"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3NCIcKD"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3NxWHQ4"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3NxWHQ4"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3NBlqD2"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3NIgpc5"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3NAK2M6"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3HDqCCE"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LzQRv3"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LAsPzI"
    },
    {
     "type": "plain",
     "text": "\n\n "
    },
    {
     "type": "mention",
     "text": "@47999"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://m.media-amazon.com/images/I/61Q9ZAdoD8L._SX679_.jpg"
    },
    {
     "type": "plain",
     "text": " \n\n\n"
    },
    {
     "type": "hashtag",
     "text": "#Electronics"
    },
    {
     "type": "plain",
     "text": "\n\n                Electronics \n                Home Theater, TV & Video \n                Televisions \nPryce47999 \nDicos39 \n\n❌Regular Price  : ₹  64999  /- 😱"
    }
   ]
  },
  {
   "id": 20394,
   "type": "message",
   "date": "2023-05-04",
   "date_unixtime": "1683166152",
   "from": "All1App daily",
   "from_id": "channel1858370886",
   "photo": "(File not included. Change data exporting settings to download.)",
   "pryce": "2899",
   "dicos": "61",
   "catagory": "Home",
   "text_entities": [
    {
     "type": "plain",
     "text": "🚀🚀   Amazon Basics 1300 W Air Fryer | 3.5 Litre Non Stick Basket with Metallic Interior| Timer Selection And Fully Adjustable Temperature Control✅\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LsPvCd"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LtV82Z"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3NGbgB4"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3HDnwPc"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/412KF4i"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3APHXEG"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3AUNKZs"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3HCyIvg"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3HCJwJH"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/421GhUv"
    },
    {
     "type": "plain",
     "text": "\n\n "
    },
    {
     "type": "mention",
     "text": "@2899"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://m.media-amazon.com/images/I/81Y+y5ld2jL._SX425_.jpg"
    },
    {
     "type": "plain",
     "text": " \n\n\n"
    },
    {
     "type": "hashtag",
     "text": "#Home"
    },
    {
     "type": "plain",
     "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Home Appliances \n                Small Kitchen Appliances \nPryce2899 \nDicos61 \n\n❌Regular Price  : ₹  6499   /- 😱"
    }
   ]
  },
  {
   "id": 20395,
   "type": "message",
   "date": "2023-05-04",
   "date_unixtime": "1683166198",
   "from": "All1App daily",
   "from_id": "channel1858370886",
   "photo": "(File not included. Change data exporting settings to download.)",
   "pryce": "227",
   "dicos": "17",
   "catagory": "Health",
   "text_entities": [
    {
     "type": "plain",
     "text": "🚀🚀   Omron MC 246 Digital Thermometer With Quick Measurement of Oral & Underarm Temperature in Celsius & Fahrenheit, Water Resistant for Easy Cleaning✅\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/413gjP9"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3AQ6RE2"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/4205deY"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3p6DA5v"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/42fJobi"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/423Lr2m"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/44rejmy"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3nqFFIU"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3HEKTrz"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3p6To8m"
    },
    {
     "type": "plain",
     "text": "\n\n @227\n"
    },
    {
     "type": "link",
     "text": "https://m.media-amazon.com/images/I/41f6znHsZ5L._SX569_.jpg"
    },
    {
     "type": "plain",
     "text": " \n\n\n"
    },
    {
     "type": "hashtag",
     "text": "#Health"
    },
    {
     "type": "plain",
     "text": " & Personal Care\n\n                Health & Personal Care \n                Health Care \n                 Thermometers \nPryce227 \nDicos17 \n\n❌Regular Price  : ₹  275   /- 😱"
    }
   ]
  },
  {
   "id": 20396,
   "type": "message",
   "date": "2023-05-04",
   "date_unixtime": "1683166241",
   "from": "All1App daily",
   "from_id": "channel1858370886",
   "photo": "(File not included. Change data exporting settings to download.)",
   "pryce": "399",
   "dicos": "73",
   "catagory": "Computers",
   "text_entities": [
    {
     "type": "plain",
     "text": "🚀🚀   HP v236w USB 2.0 64GB Pen Drive, Metal✅\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/42hZRvk"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/41UAi3L"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/42kmv6n"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3ATlXbP"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3VLptPr"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/42i06qe"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3VDqs3V"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/42rOQI5"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3VvoF0F"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/424QLCq"
    },
    {
     "type": "plain",
     "text": "\n\n @399\n"
    },
    {
     "type": "link",
     "text": "https://m.media-amazon.com/images/I/31febYa30qL._SX300_SY300_QL70_FMwebp_.jpg"
    },
    {
     "type": "plain",
     "text": " \n\n\n"
    },
    {
     "type": "hashtag",
     "text": "#Computers"
    },
    {
     "type": "plain",
     "text": " & Accessories\n\n                Computers & Accessories \n                External Devices & Data Storage \n                 Pen Drives \nPryce399 \nDicos73 \n\n❌Regular Price  : ₹  1199   /- 😱"
    }
   ]
  },
  {
   "id": 20397,
   "type": "message",
   "date": "2023-05-04",
   "date_unixtime": "1683166306",
   "from": "All1App daily",
   "from_id": "channel1858370886",
   "photo": "(File not included. Change data exporting settings to download.)",
   "pryce": "9489",
   "dicos": "41",
   "catagory": "Electronics",
   "text_entities": [
    {
     "type": "plain",
     "text": "🚀🚀   EGate i9 Pro-Max 1080p Native Full HD Projector 4k Support | 4500 L (420 ANSI) | 210\" (533 cm) Large Screen | VGA, AV, HDMI, SD Card, USB, Audio Out | (E03i31) Black✅\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/4226O49"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3p5EkrE"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3VsFZnb"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/44t2bS7"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/44pLUxo"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LSLzMh"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LUneWE"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LSSFAn"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/42qNb5B"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3Lyj0Cp"
    },
    {
     "type": "plain",
     "text": "\n\n "
    },
    {
     "type": "mention",
     "text": "@9489"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://m.media-amazon.com/images/I/71GU85VOpnL._SX450_.jpg"
    },
    {
     "type": "plain",
     "text": " \n\n\n"
    },
    {
     "type": "hashtag",
     "text": "#Electronics"
    },
    {
     "type": "plain",
     "text": "\n\n                Electronics \n                Home Theater, TV & Video \n                 Projectors \nPryce9489 \nDicos41 \n\n❌Regular Price  : ₹  13999   /- 😱"
    }
   ]
  },
  {
   "id": 20398,
   "type": "message",
   "date": "2023-05-04",
   "date_unixtime": "1683166369",
   "from": "All1App daily",
   "from_id": "channel1858370886",
   "photo": "(File not included. Change data exporting settings to download.)",
   "pryce": "179",
   "dicos": "82",
   "catagory": "Clothing",
   "text_entities": [
    {
     "type": "plain",
     "text": "🚀🚀   PURVAJA Men's Regular Fit T-Shirt(Zac-010-014-015)✅\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3p6DZVz"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/42pwjvJ"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/44sXjMH"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LTy8vv"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3VLpIKl"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/44t2o7R"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3NDT3UW"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/44oX99b"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3p62Mcr"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/42leLRI"
    },
    {
     "type": "plain",
     "text": "\n\n @179\n"
    },
    {
     "type": "link",
     "text": "https://m.media-amazon.com/images/I/81vrfEUphwL._UY741_.jpg"
    },
    {
     "type": "plain",
     "text": " \n\n\n"
    },
    {
     "type": "hashtag",
     "text": "#Clothing"
    },
    {
     "type": "plain",
     "text": " & Accessories\n\n                Clothing & Accessories \n                Men \n                T-Shirts & Polos \nPryce179 \nDicos82 \n\n❌Regular Price  : ₹  899    /- 😱"
    }
   ]
  },
  {
   "id": 20399,
   "type": "message",
   "date": "2023-05-04",
   "date_unixtime": "1683166431",
   "from": "All1App daily",
   "from_id": "channel1858370886",
   "photo": "(File not included. Change data exporting settings to download.)",
   "pryce": "160",
   "dicos": "88",
   "catagory": "Office",
   "text_entities": [
    {
     "type": "plain",
     "text": "🚀🚀   DSR A4 100 Colored Sheets Paper for School Assignment Work and for Office Work Different Color Sheet✅\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3VtcVMn"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3oZgy0a"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3ATmhHz"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3nqG7H6"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3HCJWzL"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3HyUMab"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3ATXof0"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/42l95aj"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3NCRK8x"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3NxXjFm"
    },
    {
     "type": "plain",
     "text": "\n\n @160\n"
    },
    {
     "type": "link",
     "text": "https://m.media-amazon.com/images/I/51i4i7DIK6L._SX450_.jpg"
    },
    {
     "type": "plain",
     "text": " \n\n\n"
    },
    {
     "type": "hashtag",
     "text": "#Office"
    },
    {
     "type": "plain",
     "text": " Products\n\n                Office Products \n                Office Paper Products \n                Paper \nPryce160 \nDicos88 \n\n❌Regular Price  : ₹  899  /- 😱"
    }
   ]
  },
  {
   "id": 20400,
   "type": "message",
   "date": "2023-05-04",
   "date_unixtime": "1683166493",
   "from": "All1App daily",
   "from_id": "channel1858370886",
   "photo": "(File not included. Change data exporting settings to download.)",
   "pryce": "160",
   "dicos": "84",
   "catagory": "Office",
   "text_entities": [
    {
     "type": "plain",
     "text": "🚀🚀   Eclet 95 GSM A4 Ruled Thick Colored Project Sheets (60 Sheets, Both Side Ruled)for Project/Assignment/Practical/Homework✅\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3pc31lZ"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/424nLL1"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://www.amazon.in/dp/B089T86G68?smid=A1WYWER0W24N8S&amp;psc=1&amp;th=1&amp;linkCode=sl1&amp;tag=deals103-21&amp;linkId=c364a80d11576e249be0417c89fd9c27&amp;language=en_IN&amp;ref_=as_li_ss_tl"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3VubEEA"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/423w6ig"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/41YNch8"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LWfHqo"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3HzISg8"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/415jsOv"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LwRlSD"
    },
    {
     "type": "plain",
     "text": "\n\n @160\n"
    },
    {
     "type": "link",
     "text": "https://m.media-amazon.com/images/I/7180mwKGniS._SY450_.jpg"
    },
    {
     "type": "plain",
     "text": " \n\n\n"
    },
    {
     "type": "hashtag",
     "text": "#Office"
    },
    {
     "type": "plain",
     "text": " Products\n\n                Office Products \n                Office Paper Products \n                Paper \nPryce160 \nDicos84 \n\n❌Regular Price  : ₹  899   /- 😱"
    }
   ]
  },
  {
   "id": 20401,
   "type": "message",
   "date": "2023-05-04",
   "date_unixtime": "1683166556",
   "from": "All1App daily",
   "from_id": "channel1858370886",
   "photo": "(File not included. Change data exporting settings to download.)",
   "pryce": "1798",
   "dicos": "64",
   "catagory": "Electronics",
   "text_entities": [
    {
     "type": "plain",
     "text": "🚀🚀   boAt Airdopes 393ANC True Wireless in Ear Earbuds with 32dB Hybrid ANC, Quad Mics with ENx™ Tech, 30 Hours Playback, Beast™ Mode,ASAP™ Charge,Ambient Mode,Bluetooth v5.2 IWP™,IPX5(Rogue Black)✅\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/44uNuxO"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/44m5WsF"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/40Yghbn"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3AV4Mqk"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3ntjXnH"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/44FqLj1"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3HFxyPz"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/44tpMC5"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3NBItxu"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3HFuP8R"
    },
    {
     "type": "plain",
     "text": "\n\n "
    },
    {
     "type": "mention",
     "text": "@1798"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://m.media-amazon.com/images/I/51etSlfnpSL._SX679_.jpg"
    },
    {
     "type": "plain",
     "text": " \n\n\n"
    },
    {
     "type": "hashtag",
     "text": "#Electronics"
    },
    {
     "type": "plain",
     "text": "\n\n                Electronics \n                Headphones, Earbuds & Accessories \n                Headphones \nPryce1798 \nDicos64 \n\n❌Regular Price  : ₹  3999   /- 😱"
    }
   ]
  },
  {
   "id": 20402,
   "type": "message",
   "date": "2023-05-04",
   "date_unixtime": "1683166619",
   "from": "All1App daily",
   "from_id": "channel1858370886",
   "photo": "(File not included. Change data exporting settings to download.)",
   "pryce": "199",
   "dicos": "72",
   "catagory": "Computers",
   "text_entities": [
    {
     "type": "plain",
     "text": "🚀🚀   Quantum RJ45 Ethernet Patch/LAN Cable with Gold Plated Connectors Supports Upto 1000Mbps -32Feet (10 Meters), White✅\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LUtei7"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3HBawt2"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3Vy0oHB"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3paLBpS"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/44reY7w"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/424RtzA"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3HFxIGF"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/415erpd"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/44rf27g"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/44qi2kp"
    },
    {
     "type": "plain",
     "text": "\n\n @199\n"
    },
    {
     "type": "link",
     "text": "https://m.media-amazon.com/images/I/71j8m39NSFL._SX569_.jpg"
    },
    {
     "type": "plain",
     "text": " \n\n\n"
    },
    {
     "type": "hashtag",
     "text": "#Computers"
    },
    {
     "type": "plain",
     "text": " & Accessories\n\n                Computers & Accessories \n                Accessories & Peripherals \n                Cables & Accessories \nPryce199 \nDicos72 \n\n❌Regular Price  : ₹  699  /- 😱"
    }
   ]
  },
  {
   "id": 20403,
   "type": "message",
   "date": "2023-05-04",
   "date_unixtime": "1683166682",
   "from": "All1App daily",
   "from_id": "channel1858370886",
   "photo": "(File not included. Change data exporting settings to download.)",
   "pryce": "5499",
   "dicos": "42",
   "catagory": "Home",
   "text_entities": [
    {
     "type": "plain",
     "text": "🚀🚀   KENT 16022 Cold Pressed Juicer Plus | Low Speed Squeezing Technique | Horizontal juicer | Retains More Fibre and Nutrients✅\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3AVVElv"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3p9T8Wc"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/42n4swh"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3AQ7EVw"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/42rIPex"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/42l9o4X"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3ntFwEB"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3AXQ95y"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/44kmxNk"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3NFOiKn"
    },
    {
     "type": "plain",
     "text": "\n\n "
    },
    {
     "type": "mention",
     "text": "@5499"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://m.media-amazon.com/images/I/61hPQYm2aQS._SX522_.jpg"
    },
    {
     "type": "plain",
     "text": " \n\n\n"
    },
    {
     "type": "hashtag",
     "text": "#Home"
    },
    {
     "type": "plain",
     "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Home Appliances \n                Small Kitchen Appliances \nPryce5499 \nDicos42 \n\n❌Regular Price  : ₹  8999   /- 😱"
    }
   ]
  },
  {
   "id": 20404,
   "type": "message",
   "date": "2023-05-04",
   "date_unixtime": "1683166746",
   "from": "All1App daily",
   "from_id": "channel1858370886",
   "photo": "(File not included. Change data exporting settings to download.)",
   "pryce": "179",
   "dicos": "82",
   "catagory": "Computers",
   "text_entities": [
    {
     "type": "plain",
     "text": "🚀🚀   Lapster USB all types smartwatch charger cable for Boat Xtend,boat storm, W26 W26+ W26m W55+, Noise ColorFit Pulse, Colorfit Ultra/Ultra 2, All 2 Pin Watch (Cable Only✅\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3AQPdAf"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/44vASXx"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LwRHbV"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3NxXReo"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3oZhgKS"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/44qJ6jy"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/42FXFOH"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LUnWDi"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3HHxX43"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3nqedLx"
    },
    {
     "type": "plain",
     "text": "\n\n @179\n"
    },
    {
     "type": "link",
     "text": "https://m.media-amazon.com/images/I/51c3oYvNwIL._SX569_.jpg"
    },
    {
     "type": "plain",
     "text": " \n\n\n"
    },
    {
     "type": "hashtag",
     "text": "#Computers"
    },
    {
     "type": "plain",
     "text": " & Accessories\n\n                Electronics \n                Wearable Technology \n                Arm & Wristband Accessories \nPryce179 \nDicos82 \n\n❌Regular Price  : ₹  899   /- 😱"
    }
   ]
  },
  {
   "id": 20405,
   "type": "message",
   "date": "2023-05-04",
   "date_unixtime": "1683166807",
   "from": "All1App daily",
   "from_id": "channel1858370886",
   "photo": "(File not included. Change data exporting settings to download.)",
   "pryce": "1338",
   "dicos": "55",
   "catagory": "Electronics",
   "text_entities": [
    {
     "type": "plain",
     "text": "🚀🚀   Ambrane Dots Hype True Wireless Earbuds with 28 Hours Playtime, BoostedBass™ Low Energy Consumption Bluetooth V5.2, Responsive Touch Controls, Voice Assistance, IPX4 (White)✅\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3HELKIN"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3p84ERS"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/415oDho"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3p84FVW"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LUtqhl"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/44rfr9M"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LRq12M"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/44qiS0x"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/44qJpec"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3NxXWyI"
    },
    {
     "type": "plain",
     "text": "\n\n "
    },
    {
     "type": "mention",
     "text": "@1338"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://m.media-amazon.com/images/I/51mJsWUh5JL._SX679_.jpg"
    },
    {
     "type": "plain",
     "text": " \n\n\n"
    },
    {
     "type": "hashtag",
     "text": "#Electronics"
    },
    {
     "type": "plain",
     "text": "\n\n                Electronics \n                Headphones, Earbuds & Accessories \n                Headphones \nPryce1338 \nDicos55 \n\n❌Regular Price  : ₹  1999  /- 😱"
    }
   ]
  },
  {
   "id": 20406,
   "type": "message",
   "date": "2023-05-04",
   "date_unixtime": "1683166870",
   "from": "All1App daily",
   "from_id": "channel1858370886",
   "photo": "(File not included. Change data exporting settings to download.)",
   "pryce": "2599",
   "dicos": "80",
   "catagory": "Luggage",
   "text_entities": [
    {
     "type": "plain",
     "text": "🚀🚀   AmazonBasics Polycarbonate Hard 66 cms suitcase(T1916-8_navy blue)✅\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LSMPPv"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3VxeJ6P"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/419lInD"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3VxF1pJ"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/411MXR9"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3p63Dd9"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3APJafc"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3APJafc"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3oWWED1"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3ATIaXq"
    },
    {
     "type": "plain",
     "text": "\n\n "
    },
    {
     "type": "mention",
     "text": "@2599"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://m.media-amazon.com/images/I/71ar5kEmCrL._SY879_.jpg"
    },
    {
     "type": "plain",
     "text": " \n\n\n"
    },
    {
     "type": "hashtag",
     "text": "#Luggage"
    },
    {
     "type": "plain",
     "text": " & Bags\n\n                Bags, Wallets and Luggage \n                Luggage \n                 Suitcases & Trolley Bags \nPryce2599 \nDicos80 \n\n❌Regular Price  : ₹  9999   /- 😱"
    }
   ]
  },
  {
   "id": 20407,
   "type": "message",
   "date": "2023-05-04",
   "date_unixtime": "1683166932",
   "from": "All1App daily",
   "from_id": "channel1858370886",
   "photo": "(File not included. Change data exporting settings to download.)",
   "pryce": "7499",
   "dicos": "79",
   "catagory": "Luggage",
   "text_entities": [
    {
     "type": "plain",
     "text": "🚀🚀   AmazonBasics Polycarbonate Hard 74 cms suitcase(T1916-24_navy blue)✅\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3ASfs9j"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3nmixvj"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3nrtnjw"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LTh8pn"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LTOOmH"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LWgsQg"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LTG3sT"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LPILQ5"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/413i8LZ"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3NL0fyi"
    },
    {
     "type": "plain",
     "text": "\n\n "
    },
    {
     "type": "mention",
     "text": "@7499"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://m.media-amazon.com/images/I/91fwzkTZj7L._SX569_.jpg"
    },
    {
     "type": "plain",
     "text": " \n\n\n"
    },
    {
     "type": "hashtag",
     "text": "#Luggage"
    },
    {
     "type": "plain",
     "text": " & Bags\n\n                Bags, Wallets and Luggage \n                Luggage \n                 Luggage Sets \nPryce7499 \nDicos79 \n\n❌Regular Price  : ₹   19999  /- 😱"
    }
   ]
  },
  {
   "id": 20408,
   "type": "message",
   "date": "2023-05-04",
   "date_unixtime": "1683166996",
   "from": "All1App daily",
   "from_id": "channel1858370886",
   "photo": "(File not included. Change data exporting settings to download.)",
   "pryce": "239",
   "dicos": "77",
   "catagory": "Clothing",
   "text_entities": [
    {
     "type": "plain",
     "text": "🚀🚀   Satrani Women's Printed Poly Silk Saree with Unstitched Blouse Piece✅\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LRuQJg"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3HzJNx6"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3HDSMNJ"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3p9c4V3"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3VCMZxW"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3VxHSyR"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3HDwxHE"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3HCBOj1"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3ATIBRy"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3nu5CY8"
    },
    {
     "type": "plain",
     "text": "\n\n @239\n"
    },
    {
     "type": "link",
     "text": "https://m.media-amazon.com/images/I/51n9txRf90L._UY679_.jpg"
    },
    {
     "type": "plain",
     "text": " \n\n\n"
    },
    {
     "type": "hashtag",
     "text": "#Clothing"
    },
    {
     "type": "plain",
     "text": " & Accessories\n\n                Clothing & Accessories \n                Women \n                Ethnic Wear \nPryce239 \nDicos77 \n\n❌Regular Price  : ₹  999   /- 😱"
    }
   ]
  },
  {
   "id": 20409,
   "type": "message",
   "date": "2023-05-04",
   "date_unixtime": "1683167061",
   "from": "All1App daily",
   "from_id": "channel1858370886",
   "photo": "(File not included. Change data exporting settings to download.)",
   "pryce": "1709",
   "dicos": "45",
   "catagory": "Home",
   "text_entities": [
    {
     "type": "plain",
     "text": "🚀🚀   Pigeon by Stovekraft Joy 1.8 Liter Electric Rice Cooker 700 Watt, White✅\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/4219gaR"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LzMqjN"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/44Ed2ca"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3AZf9cF"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3HyYrF2"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/40Y8LgA"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3VxBAPD"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LzyZQI"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3p9U3WE"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LoNNle"
    },
    {
     "type": "plain",
     "text": "\n\n "
    },
    {
     "type": "mention",
     "text": "@1709"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://m.media-amazon.com/images/I/51Xj32RdshL._SX466_.jpg"
    },
    {
     "type": "plain",
     "text": " \n\n\n"
    },
    {
     "type": "hashtag",
     "text": "#Home"
    },
    {
     "type": "plain",
     "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Home Appliances \n                Small Kitchen Appliances \nPryce1709 \nDicos45 \n\n❌Regular Price  : ₹  3999   /- 😱"
    }
   ]
  },
  {
   "id": 20410,
   "type": "message",
   "date": "2023-05-04",
   "date_unixtime": "1683167106",
   "from": "All1App daily",
   "from_id": "channel1858370886",
   "photo": "(File not included. Change data exporting settings to download.)",
   "pryce": "1298",
   "dicos": "48",
   "catagory": "Electronics",
   "text_entities": [
    {
     "type": "plain",
     "text": "🚀🚀   realme Buds Wireless 2S in Ear Earphone with mic, Dual Device Switching & Type C Fast Charge & Up to 24Hrs Playtime, Bluetooth Headset Neckband (Blue)✅\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LvLDQP"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3VxweEe"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/42elTiG"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/42m3Y9F"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LyMUXc"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/42kobwH"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3VCNmsk"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/42rQdqd"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3nlPk3H"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3p85fTC"
    },
    {
     "type": "plain",
     "text": "\n\n "
    },
    {
     "type": "mention",
     "text": "@1298"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://m.media-amazon.com/images/I/61UtY2UWYYS._SX569_.jpg"
    },
    {
     "type": "plain",
     "text": " \n\n\n"
    },
    {
     "type": "hashtag",
     "text": "#Electronics"
    },
    {
     "type": "plain",
     "text": "\n\n                Electronics \n                Headphones, Earbuds & Accessories \n                Headphones \nPryce1298 \nDicos48 \n\n❌Regular Price  : ₹  2499   /- 😱"
    }
   ]
  },
  {
   "id": 20411,
   "type": "message",
   "date": "2023-05-04",
   "date_unixtime": "1683167169",
   "from": "All1App daily",
   "from_id": "channel1858370886",
   "photo": "(File not included. Change data exporting settings to download.)",
   "pryce": "199",
   "dicos": "92",
   "catagory": "Clothing",
   "text_entities": [
    {
     "type": "plain",
     "text": "🚀🚀   Fashion Dream Girls Maxi Length Dresses✅\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/424p0d9"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3NE60Oq"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LwStWn"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LuQe5W"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LykCMt"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3NDDtsj"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LTUrkU"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3HFarEW"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3paWubb"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/414k1I4"
    },
    {
     "type": "plain",
     "text": "\n\n @199\n"
    },
    {
     "type": "link",
     "text": "https://m.media-amazon.com/images/I/71Zf5uabq8L._UY741_.jpg"
    },
    {
     "type": "plain",
     "text": " \n\n\n"
    },
    {
     "type": "hashtag",
     "text": "#Clothing"
    },
    {
     "type": "plain",
     "text": " & Accessories\n\n                Clothing & Accessories \n                Shop by Age \n                Kids' clothing 25-36 months \nPryce199 \nDicos92 \n\n❌Regular Price  : ₹  1499   /- 😱"
    }
   ]
  },
  {
   "id": 20412,
   "type": "message",
   "date": "2023-05-04",
   "date_unixtime": "1683167233",
   "from": "All1App daily",
   "from_id": "channel1858370886",
   "photo": "(File not included. Change data exporting settings to download.)",
   "pryce": "1239",
   "dicos": "45",
   "catagory": "Home",
   "text_entities": [
    {
     "type": "plain",
     "text": "🚀🚀   Pigeon by Stovekraft Joy Rice Cooker with Single pot, 1 litres. A smart Rice Cooker for your own kitchen (White)✅\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/413V93h"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3VvqnPD"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/44ukNkQ"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/42keVsq"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3NFP75V"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3NxYBjG"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LPJdhf"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/44n2fD7"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3VzVNEz"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3Vy1HGv"
    },
    {
     "type": "plain",
     "text": "\n\n "
    },
    {
     "type": "mention",
     "text": "@1239"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://m.media-amazon.com/images/I/31NYiOUvjUL._SX300_SY300_QL70_FMwebp_.jpg"
    },
    {
     "type": "plain",
     "text": " \n\n\n"
    },
    {
     "type": "hashtag",
     "text": "#Home"
    },
    {
     "type": "plain",
     "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Home Appliances \n                Small Kitchen Appliances \nPryce1239 \nDicos45 \n\n❌Regular Price  : ₹   1999  /- 😱"
    }
   ]
  },
  {
   "id": 20413,
   "type": "message",
   "date": "2023-05-04",
   "date_unixtime": "1683167296",
   "from": "All1App daily",
   "from_id": "channel1858370886",
   "photo": "(File not included. Change data exporting settings to download.)",
   "pryce": "908",
   "dicos": "49",
   "catagory": "Home",
   "text_entities": [
    {
     "type": "plain",
     "text": "🚀🚀   Pigeon By Stovekraft Favourite Aluminium Pressure Cooker Mini Combo with Outer Lid 2, 3 Litre Capacity for Healthy Cooking (Silver)✅\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3VCNwQs"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3AToItR"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/414p5w8"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/411KdmY"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3ASgfHj"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LsRXsp"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/413Vweb"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3ATnyyl"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3Lvb2Kj"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/415fIN1"
    },
    {
     "type": "plain",
     "text": "\n\n @908\n"
    },
    {
     "type": "link",
     "text": "https://m.media-amazon.com/images/I/51-mmn9-+sL._SX522_.jpg"
    },
    {
     "type": "plain",
     "text": " \n\n\n"
    },
    {
     "type": "hashtag",
     "text": "#Home"
    },
    {
     "type": "plain",
     "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Dining \n                Cookware \nPryce908 \nDicos49 \n\n❌Regular Price  : ₹  1599   /- 😱"
    }
   ]
  },
  {
   "id": 20414,
   "type": "message",
   "date": "2023-05-04",
   "date_unixtime": "1683167358",
   "from": "All1App daily",
   "from_id": "channel1858370886",
   "photo": "(File not included. Change data exporting settings to download.)",
   "pryce": "4798",
   "dicos": "36",
   "catagory": "Sports",
   "text_entities": [
    {
     "type": "plain",
     "text": "🚀🚀   Lifelong LLBC2601 Escape without Gear Single Speed 26T MTB Cycle(Black) Ideal for: Adults (Above 12 Years)IFrame Size: 18\"| Ideal dicos:5 ft 4 inches+IUnisex Cycle|85% Assembled (Easy Self-Assembly)✅\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3AQBdpQ"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3NL0OYW"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3AVWQoZ"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/42i1Vn4"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/42oE4SY"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/423CYMm"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/424Sl7k"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LVqdhB"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/42iMOdL"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LVgck8"
    },
    {
     "type": "plain",
     "text": "\n\n "
    },
    {
     "type": "mention",
     "text": "@4798"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://m.media-amazon.com/images/I/81cHNPztKcL._SX569_.jpg"
    },
    {
     "type": "plain",
     "text": " \n\n\n"
    },
    {
     "type": "hashtag",
     "text": "#Sports"
    },
    {
     "type": "plain",
     "text": ", Fitness & Outdoors\n\n                Sports, Fitness & Outdoors \n                Cycling \n                Cycles \nPryce4798 \nDicos36 \n\n❌Regular Price  : ₹  6999  /- 😱"
    }
   ]
  },
  {
   "id": 20415,
   "type": "message",
   "date": "2023-05-04",
   "date_unixtime": "1683167423",
   "from": "All1App daily",
   "from_id": "channel1858370886",
   "photo": "(File not included. Change data exporting settings to download.)",
   "pryce": "3498",
   "dicos": "50",
   "catagory": "Electronics",
   "text_entities": [
    {
     "type": "plain",
     "text": "🚀🚀   Jabra Elite 3 in Ear Bluetooth Truly Wireless in Ear Earbuds, Noise Isolating with mic for Clear Calls, Rich Bass, Customizable Sound, Mono Mode - Dark Grey✅\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3VLrtqV"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/418huwG"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3nqfBhd"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LzDvik"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3NL0Ujg"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3nyzJxt"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/415fSE7"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/415fTIb"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/424pno3"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3NE6yUu"
    },
    {
     "type": "plain",
     "text": "\n\n "
    },
    {
     "type": "mention",
     "text": "@3498"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://m.media-amazon.com/images/I/31hfknwFfbL._SX300_SY300_QL70_FMwebp_.jpg"
    },
    {
     "type": "plain",
     "text": " \n\n\n"
    },
    {
     "type": "hashtag",
     "text": "#Electronics"
    },
    {
     "type": "plain",
     "text": "\n\n                Electronics \n                Headphones, Earbuds & Accessories \n                Headphones \nPryce3498 \nDicos50 \n\n❌Regular Price  : ₹  5499   /- 😱"
    }
   ]
  },
  {
   "id": 20416,
   "type": "message",
   "date": "2023-05-04",
   "date_unixtime": "1683167486",
   "from": "All1App daily",
   "from_id": "channel1858370886",
   "photo": "(File not included. Change data exporting settings to download.)",
   "pryce": "129",
   "dicos": "40",
   "catagory": "Home",
   "text_entities": [
    {
     "type": "plain",
     "text": "🚀🚀   Milton Hector 1000 Pet Water Bottle, 1 Piece, 1 Litre, Grey✅\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/423RL9V"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3nrzRii"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3HFb55k"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3ATCX1W"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3nlPZ5b"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/44q0sgq"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/44knzZI"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3ATnWgh"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3p85M84"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3HzKzdu"
    },
    {
     "type": "plain",
     "text": "\n\n @129\n"
    },
    {
     "type": "link",
     "text": "https://m.media-amazon.com/images/I/3126i3EqA1L._SX300_SY300_QL70_FMwebp_.jpg"
    },
    {
     "type": "plain",
     "text": " \n\n\n"
    },
    {
     "type": "hashtag",
     "text": "#Home"
    },
    {
     "type": "plain",
     "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Dining \n                Kitchen Storage & Containers \nPryce129 \nDicos40 \n\n❌Regular Price  : ₹  215   /- 😱"
    }
   ]
  },
  {
   "id": 20417,
   "type": "message",
   "date": "2023-05-04",
   "date_unixtime": "1683167548",
   "from": "All1App daily",
   "from_id": "channel1858370886",
   "photo": "(File not included. Change data exporting settings to download.)",
   "pryce": "2099",
   "dicos": "43",
   "catagory": "Home",
   "text_entities": [
    {
     "type": "plain",
     "text": "🚀🚀   Lifelong 750 Watt Juicer Mixer Grinder, 4 Jars for Grinding, Mixing, and Juice at home, 1.5L Juicer with Fruit Filter (LLMG75, Black)✅\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/415pWNk"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LyNzIa"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LvMFwb"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3nv1wPw"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3p5GAPE"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3VsImX7"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3VtagSQ"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3Nyoccl"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/42laYnp"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3NE6RP8"
    },
    {
     "type": "plain",
     "text": "\n\n "
    },
    {
     "type": "mention",
     "text": "@2099"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://m.media-amazon.com/images/I/71Yq6g2yEyL._SX425_.jpg"
    },
    {
     "type": "plain",
     "text": " \n\n\n"
    },
    {
     "type": "hashtag",
     "text": "#Home"
    },
    {
     "type": "plain",
     "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Home Appliances \n                Small Kitchen Appliances \nPryce2099 \nDicos43 \n\n❌Regular Price  : ₹  2999   /- 😱"
    }
   ]
  },
  {
   "id": 20418,
   "type": "message",
   "date": "2023-05-04",
   "date_unixtime": "1683167588",
   "from": "All1App daily",
   "from_id": "channel1858370886",
   "photo": "(File not included. Change data exporting settings to download.)",
   "pryce": "239",
   "dicos": "52",
   "catagory": "Home",
   "text_entities": [
    {
     "type": "plain",
     "text": "🚀🚀   Fun Homes Plastic Pedal Dustbin/Wastebin With Handle, 5 Liter (Yellow & Black)-47FH0907✅\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/4137THq"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3p4D9Zo"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/44sZvUr"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3p6Gh77"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/44wby3p"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/42kfW3I"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/44rgRkC"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3nweRXW"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/44t545p"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3NxZgSc"
    },
    {
     "type": "plain",
     "text": "\n\n @239\n"
    },
    {
     "type": "link",
     "text": "https://m.media-amazon.com/images/I/61NzVv7gKkL._SX522_.jpg"
    },
    {
     "type": "plain",
     "text": " \n\n\n"
    },
    {
     "type": "hashtag",
     "text": "#Home"
    },
    {
     "type": "plain",
     "text": " & Kitchen\n\n                Home & Kitchen \n                Home Storage & Organisation \n                Waste & Recycling \nPryce239 \nDicos52 \n\n❌Regular Price  : ₹  499   /- 😱"
    }
   ]
  },
  {
   "id": 20419,
   "type": "message",
   "date": "2023-05-04",
   "date_unixtime": "1683167635",
   "from": "All1App daily",
   "from_id": "channel1858370886",
   "photo": "(File not included. Change data exporting settings to download.)",
   "pryce": "399",
   "dicos": "73",
   "catagory": "Computers",
   "text_entities": [
    {
     "type": "plain",
     "text": "🚀🚀   Lenovo 15.6\" (39.62cm) Slim Everyday Backpack, Made in India, Compact, Water-resistant, Organized storage:Laptop sleeve,tablet pocket,front workstation,2-side pockets,Padded adjustable shoulder straps✅\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3VvPsKo"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/4138fhe"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3AXRXeQ"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LW7cvr"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LyY9yP"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3VvPF08"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3VxGxIr"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3NxZsRq"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3VxgAIP"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3nnuqkH"
    },
    {
     "type": "plain",
     "text": "\n\n @399\n"
    },
    {
     "type": "link",
     "text": "https://m.media-amazon.com/images/I/61v0-iGlR1L._SY741_.jpg"
    },
    {
     "type": "plain",
     "text": " \n\n\n"
    },
    {
     "type": "hashtag",
     "text": "#Computers"
    },
    {
     "type": "plain",
     "text": " & Accessories\n\n                Computers & Accessories \n                Accessories & Peripherals \n                Laptop Accessories \nPryce399 \nDicos73 \n\n❌Regular Price  : ₹  1399   /- 😱"
    }
   ]
  },
  {
   "id": 20420,
   "type": "message",
   "date": "2023-05-04",
   "date_unixtime": "1683167676",
   "from": "All1App daily",
   "from_id": "channel1858370886",
   "photo": "(File not included. Change data exporting settings to download.)",
   "pryce": "559",
   "dicos": "60",
   "catagory": "Clothing",
   "text_entities": [
    {
     "type": "plain",
     "text": "🚀🚀   Pepe Womens Round Neck Textured T-Shirt✅\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3HF3iEq"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/42lbim7"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/4138nxe"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/42pyv6O"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LoOPh6"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/418i1ia"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/412qinF"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/412NrXg"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/42qPGov"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/412qd3l"
    },
    {
     "type": "plain",
     "text": "\n\n @559\n"
    },
    {
     "type": "link",
     "text": "https://m.media-amazon.com/images/I/618ZDcscwRL._UX679_.jpg"
    },
    {
     "type": "plain",
     "text": " \n\n\n"
    },
    {
     "type": "hashtag",
     "text": "#Clothing"
    },
    {
     "type": "plain",
     "text": " & Accessories\n\n                Fashion \n                Apparel Steal Deals \n                Apparel_1 \nPryce559 \nDicos60 \n\n❌Regular Price  : ₹   999   /- 😱"
    }
   ]
  },
  {
   "id": 20421,
   "type": "message",
   "date": "2023-05-04",
   "date_unixtime": "1683167738",
   "from": "All1App daily",
   "from_id": "channel1858370886",
   "photo": "(File not included. Change data exporting settings to download.)",
   "pryce": "89",
   "dicos": "55",
   "catagory": "Home",
   "text_entities": [
    {
     "type": "plain",
     "text": "🚀🚀   SPIRO PVC (Round/Hex) Dumbbells (1 Kg. X 2 = 2 Kg) Pack of 2 for Home Gym Equipment Fitness Barbell|Gym Exercise|Home Workout | Fitness Kit|Dumbbells Weights for Whole Body Workout✅\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3ASho1z"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3ATDJvS"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/423xUb2"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/42lD8hT"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/424FMsE"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LwntFY"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3p4DhIm"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/42oERmU"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LTAxGx"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LVwIku"
    },
    {
     "type": "plain",
     "text": "\n\n @89\n"
    },
    {
     "type": "link",
     "text": "https://m.media-amazon.com/images/I/61VcW8otphL._SX425_.jpg"
    },
    {
     "type": "plain",
     "text": " \n\n\n"
    },
    {
     "type": "hashtag",
     "text": "#Home"
    },
    {
     "type": "plain",
     "text": " & Kitchen\n\n                Sports, Fitness & Outdoors \n                Exercise & Fitness \n                Strength Training Equipment \nPryce89 \nDicos55 \n\n❌Regular Price  : ₹  199   /- 😱"
    }
   ]
  },
  {
   "id": 20422,
   "type": "message",
   "date": "2023-05-04",
   "date_unixtime": "1683167801",
   "from": "All1App daily",
   "from_id": "channel1858370886",
   "photo": "(File not included. Change data exporting settings to download.)",
   "pryce": "599",
   "dicos": "83",
   "catagory": "Shoes",
   "text_entities": [
    {
     "type": "plain",
     "text": "🚀🚀   Lavie Women's Betula Medium Tote Bag | Ladies Purse Handbag✅\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/4207SFu"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LVh8VG"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LUvCW7"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3NCMpOL"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3NxZKYw"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/415lPkn"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/423DMAS"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3NBKjOU"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3p2xfrH"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3VxgJfl"
    },
    {
     "type": "plain",
     "text": "\n\n @599\n"
    },
    {
     "type": "link",
     "text": "https://m.media-amazon.com/images/I/61i7e+IUt3L._UY500_.jpg"
    },
    {
     "type": "plain",
     "text": " \n\n\n"
    },
    {
     "type": "hashtag",
     "text": "#Shoes"
    },
    {
     "type": "plain",
     "text": " & Handbags\n\n                Fashion \n                Shoes & handbags steal deals \n                Steal Deals - Women's Footwear & Handbags \nPryce599 \nDicos83 \n\n❌Regular Price  : ₹ 1999   /- 😱"
    }
   ]
  },
  {
   "id": 20423,
   "type": "message",
   "date": "2023-05-04",
   "date_unixtime": "1683167864",
   "from": "All1App daily",
   "from_id": "channel1858370886",
   "photo": "(File not included. Change data exporting settings to download.)",
   "pryce": "202",
   "dicos": "59",
   "catagory": "Baby",
   "text_entities": [
    {
     "type": "plain",
     "text": "🚀🚀   Bumtum Baby Dry Sheet Waterproof Soft Fleece Baby Bed Protector | Anti - Bacterial & Odour Free | Extra Absorbant, Reuseable & Washable (Hot Pink, Medium Size, 100 * 70cm, Pack of 1)✅\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/423OdVk"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/423E22O"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LyYIbV"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3p86imw"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LRqPnd"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3nqIGsI"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LwTz4r"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3ASpFT9"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3AToIKd"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3ATKihS"
    },
    {
     "type": "plain",
     "text": "\n\n @202\n"
    },
    {
     "type": "link",
     "text": "https://m.media-amazon.com/images/I/611m9SD019L._SX425_.jpg"
    },
    {
     "type": "plain",
     "text": " \n\n\n"
    },
    {
     "type": "hashtag",
     "text": "#Baby"
    },
    {
     "type": "plain",
     "text": "\n\n                Baby \n                Bedding, Furniture & Room Décor \n                Bedding \nPryce202 \nDicos59 \n\n❌Regular Price  : ₹   499  /- 😱"
    }
   ]
  },
  {
   "id": 20424,
   "type": "message",
   "date": "2023-05-04",
   "date_unixtime": "1683167904",
   "from": "All1App daily",
   "from_id": "channel1858370886",
   "photo": "(File not included. Change data exporting settings to download.)",
   "pryce": "275",
   "dicos": "51",
   "catagory": "Beauty",
   "text_entities": [
    {
     "type": "plain",
     "text": "🚀🚀   Midazzle Organic Pure Kacchi Neem Wood Comb for men and women | Hair Growth, Hair Straightening, Dandruff Control, Frizz Control | Anti-Bacterial Hair Styling Comb (Pack of 2)✅\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/423E9LM"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3p6J0gK"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3ARm4EL"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/4229yyt"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/4229yyt"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3BbJghv"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LriLJs"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3Vvrwqp"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3HBd2Q0"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LyYMIH"
    },
    {
     "type": "plain",
     "text": "\n\n @275\n"
    },
    {
     "type": "link",
     "text": "https://m.media-amazon.com/images/I/61Eo9H4jsiL._SX569_PIbundle-2,TopRight,0,0_AA569SH20_.jpg"
    },
    {
     "type": "plain",
     "text": " \n\n\n"
    },
    {
     "type": "hashtag",
     "text": "#Beauty"
    },
    {
     "type": "plain",
     "text": "\n\n                Beauty \n                Hair Care \n                Hair Styling Tools \nPryce275 \nDicos51 \n\n❌Regular Price  : ₹  558    /- 😱"
    }
   ]
  },
  {
   "id": 20425,
   "type": "message",
   "date": "2023-05-04",
   "date_unixtime": "1683167967",
   "from": "All1App daily",
   "from_id": "channel1858370886",
   "photo": "(File not included. Change data exporting settings to download.)",
   "pryce": "149",
   "dicos": "72",
   "catagory": "Home",
   "text_entities": [
    {
     "type": "plain",
     "text": "🚀🚀   Cockatoo (10 KG - 40 Kg) Adjustable Hand Grip| Hand Gripper for Men & Women|Hand Grip Strengthener (6 Month Warranty)✅\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3p9D8Dx"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3VxD3FD"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3HFbYea"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3HFbYea"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/42lDu8d"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3Ny07SU"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3p1TPk5"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/414lspW"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/42lbGB5"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/42o04ND"
    },
    {
     "type": "plain",
     "text": "\n\n @149\n"
    },
    {
     "type": "link",
     "text": "https://m.media-amazon.com/images/I/413XL4dW--L._SX300_SY300_QL70_FMwebp_.jpg"
    },
    {
     "type": "plain",
     "text": " \n\n\n"
    },
    {
     "type": "hashtag",
     "text": "#Home"
    },
    {
     "type": "plain",
     "text": " & Kitchen\n\n                Sports, Fitness & Outdoors \n                Exercise & Fitness \n                Strength Training Equipment \nPryce149 \nDicos72 \n\n❌Regular Price  : ₹  499    /- 😱"
    }
   ]
  },
  {
   "id": 20426,
   "type": "message",
   "date": "2023-05-04",
   "date_unixtime": "1683168031",
   "from": "All1App daily",
   "from_id": "channel1858370886",
   "photo": "(File not included. Change data exporting settings to download.)",
   "pryce": "649",
   "dicos": "58",
   "catagory": "Home",
   "text_entities": [
    {
     "type": "plain",
     "text": "🚀🚀   Pigeon 1.5 litre Hot Kettle and Stainless Steel Water Bottle Combo used for boiling Water, Making Tea and Coffee, Instant Noodles, Soup, 1500 Watt with Auto Shut- off Feature - (Silver)✅\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/429Owhu"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/44koTf8"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/42m7mkT"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3p4DUBI"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3p9DMRt"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3p4DWcO"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/42hVpg0"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/42m8Tr9"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/44p0cOF"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/44rhI4O"
    },
    {
     "type": "plain",
     "text": "\n\n @649\n"
    },
    {
     "type": "link",
     "text": "https://m.media-amazon.com/images/I/51VvdjBgB5L._SX425_.jpg"
    },
    {
     "type": "plain",
     "text": " \n\n\n"
    },
    {
     "type": "hashtag",
     "text": "#Home"
    },
    {
     "type": "plain",
     "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Home Appliances \n                Small Kitchen Appliances \nPryce649 \nDicos58 \n\n❌Regular Price  : ₹ 1299   /- 😱"
    }
   ]
  },
  {
   "id": 20427,
   "type": "message",
   "date": "2023-05-04",
   "date_unixtime": "1683168094",
   "from": "All1App daily",
   "from_id": "channel1858370886",
   "photo": "(File not included. Change data exporting settings to download.)",
   "pryce": "339",
   "dicos": "NaN",
   "catagory": "Clothing",
   "text_entities": [
    {
     "type": "plain",
     "text": "🚀🚀   Anubhutee Women's Yellow Ethnic Motifs Printed Tunic✅\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3AV3dce"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3Vt2yYR"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3VDtXr5"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3HzLyKI"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3HzLyKI"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3NL2eTg"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3ND3Tud"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3ND41tH"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3ND41tH"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3NCGSrb"
    },
    {
     "type": "plain",
     "text": "\n\n @339\n"
    },
    {
     "type": "link",
     "text": "https://m.media-amazon.com/images/I/61zCX6sN6uL._UX679_.jpg"
    },
    {
     "type": "plain",
     "text": " \n\n\n"
    },
    {
     "type": "hashtag",
     "text": "#Clothing"
    },
    {
     "type": "plain",
     "text": " & Accessories\n\n                Clothing & Accessories \n                Women \n                Western Wear \nPryce339 \nDicosNaN"
    }
   ]
  },
  {
   "id": 20428,
   "type": "message",
   "date": "2023-05-04",
   "date_unixtime": "1683168158",
   "from": "All1App daily",
   "from_id": "channel1858370886",
   "photo": "(File not included. Change data exporting settings to download.)",
   "pryce": "190",
   "dicos": "5",
   "catagory": "Home",
   "text_entities": [
    {
     "type": "plain",
     "text": "🚀🚀   Tosaa Dry Fruit Cutter, Slicer, Grinder, Chocolate Cutter and Butter Slicer with 3 in 1 Blade – Almonds, Cashews- Color May Vary (Pack of 1, ABS/Unbreakable)✅\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LR8fg2"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LuRTII"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3paOkj6"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/413X4ov"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/40YBu4J"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LwfloS"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LtZ7fX"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3pavrga"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3ASoxPn"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/412Obf0"
    },
    {
     "type": "plain",
     "text": "\n\n @190\n"
    },
    {
     "type": "link",
     "text": "https://m.media-amazon.com/images/I/51bfd5UUidL._SX425_.jpg"
    },
    {
     "type": "plain",
     "text": " \n\n\n"
    },
    {
     "type": "hashtag",
     "text": "#Home"
    },
    {
     "type": "plain",
     "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Dining \n                Kitchen Tools \nPryce190 \nDicos5 \n\n❌Regular Price  : ₹ 199  /- 😱"
    }
   ]
  },
  {
   "id": 20429,
   "type": "message",
   "date": "2023-05-04",
   "date_unixtime": "1683168221",
   "from": "All1App daily",
   "from_id": "channel1858370886",
   "photo": "(File not included. Change data exporting settings to download.)",
   "pryce": "1898",
   "dicos": "41",
   "catagory": "Home",
   "text_entities": [
    {
     "type": "plain",
     "text": "🚀🚀   Bajaj Rex 500W Mixer Grinder with Nutri-Pro Feature, 3 Jars, White✅\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/424HESh"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/424r84D"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LzESxu"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3VxhCVd"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LydSyh"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3Ny0A7C"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LrjjPw"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/412OkPA"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3AQldo3"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3Lye3tr"
    },
    {
     "type": "plain",
     "text": "\n\n "
    },
    {
     "type": "mention",
     "text": "@1898"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://m.media-amazon.com/images/I/51Ck4wmBbSL._SX466_.jpg"
    },
    {
     "type": "plain",
     "text": " \n\n\n"
    },
    {
     "type": "hashtag",
     "text": "#Home"
    },
    {
     "type": "plain",
     "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Home Appliances \n                Small Kitchen Appliances \nPryce1898 \nDicos41 \n\n❌Regular Price  : ₹  2999   /- 😱"
    }
   ]
  },
  {
   "id": 20430,
   "type": "message",
   "date": "2023-05-04",
   "date_unixtime": "1683168283",
   "from": "All1App daily",
   "from_id": "channel1858370886",
   "photo": "(File not included. Change data exporting settings to download.)",
   "pryce": "172",
   "dicos": "83",
   "catagory": "Clothing",
   "text_entities": [
    {
     "type": "plain",
     "text": "🚀🚀   Indigo Women's Regular T-Shirt✅\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/414lXjO"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3HEP0nv"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3NCMx0n"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3NwPorU"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3NFR5Dl"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3NIjZD3"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3NxKM4N"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/42qQxFJ"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/42qQycL"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3NCNVjV"
    },
    {
     "type": "plain",
     "text": "\n\n @172\n"
    },
    {
     "type": "link",
     "text": "https://m.media-amazon.com/images/I/81Jw11ZnrVS._UX569_.jpg"
    },
    {
     "type": "plain",
     "text": " \n\n\n"
    },
    {
     "type": "hashtag",
     "text": "#Clothing"
    },
    {
     "type": "plain",
     "text": " & Accessories\n\n                Clothing & Accessories \n                Women \n                Western Wear \nPryce172 \nDicos83 \n\n❌Regular Price  : ₹  799   /- 😱"
    }
   ]
  },
  {
   "id": 20431,
   "type": "message",
   "date": "2023-05-04",
   "date_unixtime": "1683168343",
   "from": "All1App daily",
   "from_id": "channel1858370886",
   "photo": "(File not included. Change data exporting settings to download.)",
   "pryce": "379",
   "dicos": "87",
   "catagory": "Clothing",
   "text_entities": [
    {
     "type": "plain",
     "text": "🚀🚀   Nigel Designer Girls Baby Frock✅\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/412OHcW"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/44uf5Q0"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/42kgPt4"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/44n4l5X"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3p2NuF7"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3NC8FrK"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3p2Nq8l"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3NxL0c9"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3p87yGg"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/42pDsw2"
    },
    {
     "type": "plain",
     "text": "\n\n @379\n"
    },
    {
     "type": "link",
     "text": "https://m.media-amazon.com/images/I/714pxKiI+xL._UX679_.jpg"
    },
    {
     "type": "plain",
     "text": " \n\n\n"
    },
    {
     "type": "hashtag",
     "text": "#Clothing"
    },
    {
     "type": "plain",
     "text": " & Accessories\n\n                Clothing & Accessories \n                Baby \n                Baby Girls \nPryce379 \nDicos87 \n\n❌Regular Price  : ₹  2499  /- 😱"
    }
   ]
  },
  {
   "id": 20432,
   "type": "message",
   "date": "2023-05-04",
   "date_unixtime": "1683168404",
   "from": "All1App daily",
   "from_id": "channel1858370886",
   "photo": "(File not included. Change data exporting settings to download.)",
   "pryce": "1196",
   "dicos": "52",
   "catagory": "Electronics",
   "text_entities": [
    {
     "type": "plain",
     "text": "🚀🚀   Intex Musique Style Bluetooth in Ear Wireless Neckband with Up to 24H Playtime ASAP Charge, Dual Connectivity, Inbuilt AI Assistant and Magnet Earbuds Lock (Mint Green)✅\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3AURXfI"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/44qlJqh"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/44vDUen"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3ATqSJZ"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3NxL6k1"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3AXTotK"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LwoVrU"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/423TnAv"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3p8KAij"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://www.amazon.in/dp/B0BKWRNP3M?smid=A14CZOWI0VEHLG&amp;psc=1&amp;th=1&amp;linkCode=sl1&amp;tag=avi1deals10-21&amp;linkId=730eaa3de604c2f6850155eaba07a78b&amp;language=en_IN&amp;ref_=as_li_ss_tl"
    },
    {
     "type": "plain",
     "text": "\n\n "
    },
    {
     "type": "mention",
     "text": "@1196"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://m.media-amazon.com/images/I/41xS46U6w3L._SX679_.jpg"
    },
    {
     "type": "plain",
     "text": " \n\n\n"
    },
    {
     "type": "hashtag",
     "text": "#Electronics"
    },
    {
     "type": "plain",
     "text": "\n\n                Electronics \n                Headphones, Earbuds & Accessories \n                Headphones \nPryce1196 \nDicos52 \n\n❌Regular Price  : ₹   1999  /- 😱"
    }
   ]
  },
  {
   "id": 20433,
   "type": "message",
   "date": "2023-05-04",
   "date_unixtime": "1683168468",
   "from": "All1App daily",
   "from_id": "channel1858370886",
   "photo": "(File not included. Change data exporting settings to download.)",
   "pryce": "798",
   "dicos": "84",
   "catagory": "Electronics",
   "text_entities": [
    {
     "type": "plain",
     "text": "🚀🚀   Boult Audio XCharge Wireless in Ear Bluetooth Earphones with ENC Mic, 28H Playtime, Type-C Fast Charging (15Min=15Hrs Playtime), Made in India, Biggest 14.2mm Bass Drivers Neckband (Black) ✅\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/44t6gFV"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/44t1Oqz"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3HFB64l"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LSWyoX"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3HD1Ylu"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3I0BzhZ"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3p9WVmo"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3HDihio"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3HF4Z4K"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3HDryHk"
    },
    {
     "type": "plain",
     "text": "\n\n @798\n"
    },
    {
     "type": "link",
     "text": "https://m.media-amazon.com/images/I/61JQ6aQAheL._SX679_.jpg"
    },
    {
     "type": "plain",
     "text": " \n\n\n"
    },
    {
     "type": "hashtag",
     "text": "#Electronics"
    },
    {
     "type": "plain",
     "text": "\n\n                Electronics \n                Headphones, Earbuds & Accessories \n                Headphones \nPryce798 \nDicos84 \n\n❌Regular Price  : ₹  2999   /- 😱"
    }
   ]
  },
  {
   "id": 20434,
   "type": "message",
   "date": "2023-05-04",
   "date_unixtime": "1683168533",
   "from": "All1App daily",
   "from_id": "channel1858370886",
   "photo": "(File not included. Change data exporting settings to download.)",
   "pryce": "998",
   "dicos": "80",
   "catagory": "Computers",
   "text_entities": [
    {
     "type": "plain",
     "text": "🚀🚀   SENS NUTON 1 with 1.7 IPS Display, Orbiter, 5ATM & 150+ Watch Faces & Free Additional Strap (Matte Black)✅\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3nqs0BL"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LRtluM"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LTS4hV"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LTS4hV"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LTS5Cv"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LTmTDq"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/424rzvN"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3HDiwKk"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3Lu06g9"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/423TrjJ"
    },
    {
     "type": "plain",
     "text": "\n\n @998\n"
    },
    {
     "type": "link",
     "text": "https://m.media-amazon.com/images/I/61amvAo9DtL._SX679_.jpg"
    },
    {
     "type": "plain",
     "text": " \n\n\n"
    },
    {
     "type": "hashtag",
     "text": "#Computers"
    },
    {
     "type": "plain",
     "text": " & Accessories\n\n                Electronics \n                Wearable Technology \n                 Smart Watches \nPryce998 \nDicos80 \n\n❌Regular Price  : ₹  2999  /- 😱"
    }
   ]
  },
  {
   "id": 20435,
   "type": "message",
   "date": "2023-05-04",
   "date_unixtime": "1683168597",
   "from": "All1App daily",
   "from_id": "channel1858370886",
   "photo": "(File not included. Change data exporting settings to download.)",
   "pryce": "329",
   "dicos": "45",
   "catagory": "All",
   "text_entities": [
    {
     "type": "plain",
     "text": "🚀🚀   AmazonBasics 9 Volt Everyday Alkaline Batteries (4-Pack)✅\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3pc7k0D"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/40XKk2M"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3ATACUE"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/44qMk6E"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3APM8Am"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/429PCtC"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LTXyt6"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3nqhzOD"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/44pNtLN"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/420LBaP"
    },
    {
     "type": "plain",
     "text": "\n\n @329\n"
    },
    {
     "type": "link",
     "text": "https://m.media-amazon.com/images/I/71EYDHMLunL._SX569_.jpg"
    },
    {
     "type": "plain",
     "text": " \n\n\n"
    },
    {
     "type": "hashtag",
     "text": "#All"
    },
    {
     "type": "plain",
     "text": "\n\n                Electronics \n                General Purpose Batteries & Battery Chargers \n                 Disposable Batteries \nPryce329 \nDicos45 \n\n❌Regular Price  : ₹  599   /- 😱"
    }
   ]
  },
  {
   "id": 20436,
   "type": "message",
   "date": "2023-05-04",
   "date_unixtime": "1683168659",
   "from": "All1App daily",
   "from_id": "channel1858370886",
   "photo": "(File not included. Change data exporting settings to download.)",
   "pryce": "219",
   "dicos": "60",
   "catagory": "Computers",
   "text_entities": [
    {
     "type": "plain",
     "text": "🚀🚀   Redgear MP35 Speed-Type Gaming Mousepad (Black/Red)✅\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3AR2fO0"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/41bPsk8"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/421KVSr"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3Lwp9PM"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LEsXP4"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/423b2bE"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3NDGGIn"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3VCQ6WE"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3NIkxsB"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3Nyqlop"
    },
    {
     "type": "plain",
     "text": "\n\n @219\n"
    },
    {
     "type": "link",
     "text": "https://m.media-amazon.com/images/I/61G5-hNFMYL._SX679_.jpg"
    },
    {
     "type": "plain",
     "text": " \n\n\n"
    },
    {
     "type": "hashtag",
     "text": "#Computers"
    },
    {
     "type": "plain",
     "text": " & Accessories\n\n                Computers & Accessories \n                Accessories & Peripherals \n                PC Gaming Peripherals \nPryce219 \nDicos60 \n\n❌Regular Price  : ₹  549  /- 😱"
    }
   ]
  },
  {
   "id": 20437,
   "type": "message",
   "date": "2023-05-04",
   "date_unixtime": "1683168724",
   "from": "All1App daily",
   "from_id": "channel1858370886",
   "photo": "(File not included. Change data exporting settings to download.)",
   "pryce": "799",
   "dicos": "68",
   "catagory": "Computers",
   "text_entities": [
    {
     "type": "plain",
     "text": "🚀🚀   Amazon Basics 128GB microSDXC Memory Card with Full Size Adapter, 100MB/s, U3✅\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/415rXZU"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3AP65ax"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/412PkmO"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/415WRkC"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3NwQjZo"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3NBMAcU"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3AZi70N"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3HF5ADw"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/42ldrhF"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3NC9AbG"
    },
    {
     "type": "plain",
     "text": "\n\n @799\n"
    },
    {
     "type": "link",
     "text": "https://m.media-amazon.com/images/I/61DwejyTGkL._SX569_.jpg"
    },
    {
     "type": "plain",
     "text": " \n\n\n"
    },
    {
     "type": "hashtag",
     "text": "#Computers"
    },
    {
     "type": "plain",
     "text": " & Accessories\n\n                Electronics \n                Accessories \n                Memory Cards \nPryce799 \nDicos68 \n\n❌Regular Price  : ₹  1699   /- 😱"
    }
   ]
  },
  {
   "id": 20438,
   "type": "message",
   "date": "2023-05-04",
   "date_unixtime": "1683168770",
   "from": "All1App daily",
   "from_id": "channel1858370886",
   "photo": "(File not included. Change data exporting settings to download.)",
   "pryce": "276",
   "dicos": "16",
   "catagory": "Grocery",
   "text_entities": [
    {
     "type": "plain",
     "text": "🚀🚀   Hershey's Exotic Dark Chocolate Blueberry & Acai, 33.3g (Pack of 6)✅\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3APMFCm"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3I0CdvV"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/40YkcF7"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/42jL8A8"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3NCS33m"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3NCI5id"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/42kr7JJ"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3VxAinY"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/42G1ALp"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/44FuyNh"
    },
    {
     "type": "plain",
     "text": "\n\n @276\n"
    },
    {
     "type": "link",
     "text": "https://m.media-amazon.com/images/I/61DR+NoIOeL._SX679_PIbundle-6,TopRight,0,0_AA679SH20_.jpg"
    },
    {
     "type": "plain",
     "text": " \n\n\n"
    },
    {
     "type": "hashtag",
     "text": "#Grocery"
    },
    {
     "type": "plain",
     "text": " & Gourmet Foods\n\n                Grocery & Gourmet Foods \n                Snacks & Sweets \n                Chocolate \nPryce276 \nDicos16 \n\n❌Regular Price  : ₹  330   /- 😱"
    }
   ]
  },
  {
   "id": 20439,
   "type": "message",
   "date": "2023-05-04",
   "date_unixtime": "1683168834",
   "from": "All1App daily",
   "from_id": "channel1858370886",
   "photo": "(File not included. Change data exporting settings to download.)",
   "pryce": "4999",
   "dicos": "71",
   "catagory": "Electronics",
   "text_entities": [
    {
     "type": "plain",
     "text": "🚀🚀   Skullcandy Riff Wireless 2 on-Ear Headphones Black✅\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/44q3qS6"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LSQwER"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3VsrWOx"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3VvqmLC"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/44t2UTd"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3VxAx2m"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3NE9e4u"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/421paSx"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3VugqSw"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LwpJNs"
    },
    {
     "type": "plain",
     "text": "\n\n "
    },
    {
     "type": "mention",
     "text": "@4999"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://m.media-amazon.com/images/I/71cKqKY3dmL._SX679_.jpg"
    },
    {
     "type": "plain",
     "text": " \n\n\n"
    },
    {
     "type": "hashtag",
     "text": "#Electronics"
    },
    {
     "type": "plain",
     "text": "\n\n                Electronics \n                Headphones, Earbuds & Accessories \n                Headphones \nPryce4999 \nDicos71 \n\n❌Regular Price  : ₹   12999  /- 😱"
    }
   ]
  },
  {
   "id": 20440,
   "type": "message",
   "date": "2023-05-04",
   "date_unixtime": "1683168880",
   "from": "All1App daily",
   "from_id": "channel1858370886",
   "photo": "(File not included. Change data exporting settings to download.)",
   "pryce": "424",
   "dicos": "50",
   "catagory": "Beauty",
   "text_entities": [
    {
     "type": "plain",
     "text": "🚀🚀   Vaseline Total Moisture Body Wash Pump Bottle for Healthy, Fresh Skin, Washes Away Dirt & Impurities, Fights Skin Dryness, Easy to Rinse, Gentle Body Cleanser for Nourished & Smooth Skin, 1L✅\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/412seMX"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3VvtKWN"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3paZIvj"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3VDvRIf"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3VsL1QB"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/417U8qW"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LoRabS"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3VCQAvW"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/414gutz"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LwW4DR"
    },
    {
     "type": "plain",
     "text": "\n\n @424\n"
    },
    {
     "type": "link",
     "text": "https://m.media-amazon.com/images/I/512eNfPubpL._SX569_.jpg"
    },
    {
     "type": "plain",
     "text": " \n\n\n"
    },
    {
     "type": "hashtag",
     "text": "#Beauty"
    },
    {
     "type": "plain",
     "text": "\n\n                Beauty \n                Bath & Body \n                Cleansers \nPryce424 \nDicos50 \n\n❌Regular Price  : ₹   799  /- 😱"
    }
   ]
  },
  {
   "id": 20441,
   "type": "message",
   "date": "2023-05-04",
   "date_unixtime": "1683168943",
   "from": "All1App daily",
   "from_id": "channel1858370886",
   "photo": "(File not included. Change data exporting settings to download.)",
   "pryce": "599",
   "dicos": "74",
   "catagory": "Clothing",
   "text_entities": [
    {
     "type": "plain",
     "text": "🚀🚀   Dennis Lingo Men's Solid Formal Trousers✅\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3M3X2sN"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3nwhHMA"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LAPRXk"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/423UwrN"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3nw8Muk"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LynZ65"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3pdiAtC"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/414gI3T"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3AP6JVv"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/412PM4u"
    },
    {
     "type": "plain",
     "text": "\n\n @599\n"
    },
    {
     "type": "link",
     "text": "https://m.media-amazon.com/images/I/51NRppnoU9L._UX679_.jpg"
    },
    {
     "type": "plain",
     "text": " \n\n\n"
    },
    {
     "type": "hashtag",
     "text": "#Clothing"
    },
    {
     "type": "plain",
     "text": " & Accessories\n\n                Clothing & Accessories \n                Men \n                Trousers \nPryce599 \nDicos74 \n\n❌Regular Price  : ₹  1499   /- 😱"
    }
   ]
  },
  {
   "id": 20442,
   "type": "message",
   "date": "2023-05-04",
   "date_unixtime": "1683169005",
   "from": "All1App daily",
   "from_id": "channel1858370886",
   "photo": "(File not included. Change data exporting settings to download.)",
   "pryce": "1149",
   "dicos": "77",
   "catagory": "Electronics",
   "text_entities": [
    {
     "type": "plain",
     "text": "🚀🚀   PHILIPS Audio Tat1225 Bluetooth Truly Wireless in Ear Earbuds with Mic (TWS) with 18 Hours Playtime (6+12) Ipx4, Voice Assistant, Noise and Echo Cancellation (Blue)✅\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/41bQ4Gs"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/412PVF4"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LVtBZR"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LVtBZR"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/420Msbx"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3VvSgHq"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3NyqYyh"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3oZlbay"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3ATGz40"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/42pAZ4L"
    },
    {
     "type": "plain",
     "text": "\n\n "
    },
    {
     "type": "mention",
     "text": "@1149"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://m.media-amazon.com/images/I/718HvK+RQNL._SX679_.jpg"
    },
    {
     "type": "plain",
     "text": " \n\n\n"
    },
    {
     "type": "hashtag",
     "text": "#Electronics"
    },
    {
     "type": "plain",
     "text": "\n\n                Electronics \n                Headphones, Earbuds & Accessories \n                Headphones \nPryce1149 \nDicos77 \n\n❌Regular Price  : ₹  3999  /- 😱"
    }
   ]
  },
  {
   "id": 20443,
   "type": "message",
   "date": "2023-05-04",
   "date_unixtime": "1683169068",
   "from": "All1App daily",
   "from_id": "channel1858370886",
   "photo": "(File not included. Change data exporting settings to download.)",
   "pryce": "299",
   "dicos": "80",
   "catagory": "Electronics",
   "text_entities": [
    {
     "type": "plain",
     "text": "🚀🚀   STRIFF Adjustable Laptop Tabletop Stand Patented Riser Ventilated Portable Foldable Compatible with MacBook Notebook Tablet Tray Desk Table Book with Free Phone Stand (Black)✅\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3NwQQdQ"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LwWr1d"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/42pi4ai"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LusQVX"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3AVZZVP"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LzQfp9"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3HDvxn6"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3nqtdJj"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3AV5fJo"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3HCDzfY"
    },
    {
     "type": "plain",
     "text": "\n\n @299\n"
    },
    {
     "type": "link",
     "text": "https://m.media-amazon.com/images/I/71Zf9uUp+GL._SX679_.jpg"
    },
    {
     "type": "plain",
     "text": " \n\n\n"
    },
    {
     "type": "hashtag",
     "text": "#Electronics"
    },
    {
     "type": "plain",
     "text": "\n\n                Computers & Accessories \n                Accessories & Peripherals \n                Laptop Accessories \nPryce299 \nDicos80 \n\n❌Regular Price  : ₹  1199   /- 😱"
    }
   ]
  },
  {
   "id": 20444,
   "type": "message",
   "date": "2023-05-04",
   "date_unixtime": "1683169132",
   "from": "All1App daily",
   "from_id": "channel1858370886",
   "photo": "(File not included. Change data exporting settings to download.)",
   "pryce": "3196",
   "dicos": "74",
   "catagory": "Computers",
   "text_entities": [
    {
     "type": "plain",
     "text": "🚀🚀   Razer Opus X - Green - Active Noise Cancellation Gaming Wireless On Ear Headset - RZ04-03760400-R3M1✅\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3nw9qIg"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3ASsinZ"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3Lut0g1"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3NCaxAM"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3NCO7zl"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3p88UAQ"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3HE3Tqd"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3nB0fGx"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3I0D93p"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3HFdXz8"
    },
    {
     "type": "plain",
     "text": "\n\n "
    },
    {
     "type": "mention",
     "text": "@3196"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://m.media-amazon.com/images/I/51OjmN8R8DL._SY450_.jpg"
    },
    {
     "type": "plain",
     "text": " \n\n\n"
    },
    {
     "type": "hashtag",
     "text": "#Computers"
    },
    {
     "type": "plain",
     "text": " & Accessories\n\n                Electronics \n                Headphones, Earbuds & Accessories \n                Headphones \nPryce3196 \nDicos74 \n\n❌Regular Price  : ₹   9999  /- 😱"
    }
   ]
  },
  {
   "id": 20445,
   "type": "message",
   "date": "2023-05-04",
   "date_unixtime": "1683169192",
   "from": "All1App daily",
   "from_id": "channel1858370886",
   "photo": "(File not included. Change data exporting settings to download.)",
   "pryce": "999",
   "dicos": "39",
   "catagory": "Electronics",
   "text_entities": [
    {
     "type": "plain",
     "text": "🚀🚀   CHONCHOW Wired Over Ear Headphone with Volume Control, Omnidirectional Mic, Soft Ear Muffs and Adjustable Headband, Noise Cancelling Design for Gaming, Music, Office (black)✅\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3NzjsTN"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/42epuNI"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/42kif6S"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3NDI8uj"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/42qSylh"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3VxjuNJ"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/42oHogU"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3VzHNuK"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3VxJk4n"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LTDDu9"
    },
    {
     "type": "plain",
     "text": "\n\n @999\n"
    },
    {
     "type": "link",
     "text": "https://m.media-amazon.com/images/I/51S8GGP0nDL._SX679_.jpg"
    },
    {
     "type": "plain",
     "text": " \n\n\n"
    },
    {
     "type": "hashtag",
     "text": "#Electronics"
    },
    {
     "type": "plain",
     "text": "\n\n                Computers & Accessories \n                Accessories & Peripherals \n                PC Gaming Peripherals \nPryce999 \nDicos39 \n\n❌Regular Price  : ₹  1499  /- 😱"
    }
   ]
  },
  {
   "id": 20446,
   "type": "message",
   "date": "2023-05-04",
   "date_unixtime": "1683169256",
   "from": "All1App daily",
   "from_id": "channel1858370886",
   "photo": "(File not included. Change data exporting settings to download.)",
   "pryce": "338",
   "dicos": "62",
   "catagory": "Home",
   "text_entities": [
    {
     "type": "plain",
     "text": "🚀🚀   Crompton 5 Meter Strip Light Pink 300 LEDs (Pack of 1) (Without Driver)✅\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3Vt4Gjj"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/42hXkkI"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LoSdsk"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/421pCjH"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LyoFIF"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3p8LSd9"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/421LWtJ"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/44rk1Vw"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3ATH4Lq"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LwrkTs"
    },
    {
     "type": "plain",
     "text": "\n\n @338\n"
    },
    {
     "type": "link",
     "text": "https://m.media-amazon.com/images/I/618sqYzOgcL._SX466_.jpg"
    },
    {
     "type": "plain",
     "text": " \n\n\n"
    },
    {
     "type": "hashtag",
     "text": "#Home"
    },
    {
     "type": "plain",
     "text": " & Kitchen\n\n                Home & Kitchen \n                Indoor Lighting \n                Specialty Lighting \nPryce338 \nDicos62 \n\n❌Regular Price  : ₹  899   /- 😱"
    }
   ]
  },
  {
   "id": 20447,
   "type": "message",
   "date": "2023-05-04",
   "date_unixtime": "1683169320",
   "from": "All1App daily",
   "from_id": "channel1858370886",
   "photo": "(File not included. Change data exporting settings to download.)",
   "pryce": "1098",
   "dicos": "89",
   "catagory": "Computers",
   "text_entities": [
    {
     "type": "plain",
     "text": "🚀🚀   Fire-Boltt Ninja 3 1.83\" Display Smartwatch Full Touch with 100+ Sports Modes with IP68, Sp02 Tracking, Over 100 Cloud Based Watch Faces (Black)✅\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/44uDi8u"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3VuhgPa"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3VuhllW"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3NxN2Jj"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3Hz2jWA"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3VzI5BQ"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3HCPnyF"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3Hz019R"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3NBrg7q"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3VsLUZr"
    },
    {
     "type": "plain",
     "text": "\n\n "
    },
    {
     "type": "mention",
     "text": "@1098"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://m.media-amazon.com/images/I/61SbNsTVBeL._SX679_.jpg"
    },
    {
     "type": "plain",
     "text": " \n\n\n"
    },
    {
     "type": "hashtag",
     "text": "#Computers"
    },
    {
     "type": "plain",
     "text": " & Accessories\n\n                Electronics \n                Wearable Technology \n                 Smart Watches \nPryce1098 \nDicos89 \n\n❌Regular Price  : ₹  6999  /- 😱"
    }
   ]
  },
  {
   "id": 20448,
   "type": "message",
   "date": "2023-05-04",
   "date_unixtime": "1683169372",
   "from": "All1App daily",
   "from_id": "channel1858370886",
   "photo": "(File not included. Change data exporting settings to download.)",
   "pryce": "788",
   "dicos": "54",
   "catagory": "Computers",
   "text_entities": [
    {
     "type": "plain",
     "text": "🚀🚀   SanDisk ® Ultra Curve USB 3.2 128GB 100MB/s R Navy Blue✅\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3VxC74i"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/42rOiSB"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3nqjaE7"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/412o1cq"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/42kiQFE"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/41jeU7n"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3NGh9ya"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LAQyjo"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LAQyjo"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/44rG66s"
    },
    {
     "type": "plain",
     "text": "\n\n @788\n"
    },
    {
     "type": "link",
     "text": "https://m.media-amazon.com/images/I/513nYKZEccL._SX569_.jpg"
    },
    {
     "type": "plain",
     "text": " \n\n\n"
    },
    {
     "type": "hashtag",
     "text": "#Computers"
    },
    {
     "type": "plain",
     "text": " & Accessories\n\n                Computers & Accessories \n                External Devices & Data Storage \n                 Pen Drives \nPryce788 \nDicos54 \n\n❌Regular Price  : ₹  1499    /- 😱"
    }
   ]
  },
  {
   "id": 20449,
   "type": "message",
   "date": "2023-05-04",
   "date_unixtime": "1683169436",
   "from": "All1App daily",
   "from_id": "channel1858370886",
   "photo": "(File not included. Change data exporting settings to download.)",
   "pryce": "749",
   "dicos": "17",
   "catagory": "Garden",
   "text_entities": [
    {
     "type": "plain",
     "text": "🚀🚀   Kraft Seeds Spectacular Metal Stand for Plants |Plant Stands| Rectangular Metal Plant Stand/Pot Stand/Flower Pots - 24 inches (Set of 4) for Indoor & Outdoor Use in Your Home and Garden✅\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3ARmhrB"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/40Yd6QU"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/415jDJJ"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3VxMQM1"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/40YcUBa"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3oX1xfl"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3p6h90b"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/42hXJ6I"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/420NdRV"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3VstckJ"
    },
    {
     "type": "plain",
     "text": "\n\n @749\n"
    },
    {
     "type": "link",
     "text": "https://m.media-amazon.com/images/I/61gK04YEERL._SX466_.jpg"
    },
    {
     "type": "plain",
     "text": " \n\n\n"
    },
    {
     "type": "hashtag",
     "text": "#Garden"
    },
    {
     "type": "plain",
     "text": " & Outdoors\n\n                Outdoor Living \n                Gardening \n                Plant Containers \nPryce749 \nDicos17 \n\n❌Regular Price  : ₹  899   /- 😱"
    }
   ]
  },
  {
   "id": 20450,
   "type": "message",
   "date": "2023-05-04",
   "date_unixtime": "1683169498",
   "from": "All1App daily",
   "from_id": "channel1858370886",
   "photo": "(File not included. Change data exporting settings to download.)",
   "pryce": "1098",
   "dicos": "54",
   "catagory": "Electronics",
   "text_entities": [
    {
     "type": "plain",
     "text": "🚀🚀   Artis BT18 Wireless Bluetooth Speaker with USB, FM, TF Card, Mobile Phone Holder with Hands Free Calling (Black-Grey) (10W RMS Output)✅\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3Lu1LCp"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3p2ASxP"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3NUJmld"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3NF24x2"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3AW0DTf"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LyRR2e"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3HHDwiX"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3ATHj9i"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LEvdpw"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3nx6ECH"
    },
    {
     "type": "plain",
     "text": "\n\n "
    },
    {
     "type": "mention",
     "text": "@1098"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://m.media-amazon.com/images/I/71ABJ09X0ZL._SY450_.jpg"
    },
    {
     "type": "plain",
     "text": " \n\n\n"
    },
    {
     "type": "hashtag",
     "text": "#Electronics"
    },
    {
     "type": "plain",
     "text": "\n\n                Electronics \n                Home Audio \n                Speakers \nPryce1098 \nDicos54 \n\n❌Regular Price  : ₹  1999   /- 😱"
    }
   ]
  },
  {
   "id": 20451,
   "type": "message",
   "date": "2023-05-04",
   "date_unixtime": "1683169562",
   "from": "All1App daily",
   "from_id": "channel1858370886",
   "photo": "(File not included. Change data exporting settings to download.)",
   "pryce": "2498",
   "dicos": "75",
   "catagory": "Electronics",
   "text_entities": [
    {
     "type": "plain",
     "text": "🚀🚀   boAt Stone Symphony Portable Bluetooth Speaker with 20W RMS Stereo Sound, Party LEDs, TWS Feature, Carry Strap, Multi-Compatibility Modes, FM, IPX5, Built-in Mic(Midnight Black)✅\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3HE4xnD"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LoIDpa"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3NxNEP7"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3nmncNP"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3NwShJg"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LyRYLc"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3HFAL1D"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/44v8h4o"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3HCKGFd"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3payyES"
    },
    {
     "type": "plain",
     "text": "\n\n "
    },
    {
     "type": "mention",
     "text": "@2498"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://m.media-amazon.com/images/I/71gFF575dXL._SY450_.jpg"
    },
    {
     "type": "plain",
     "text": " \n\n\n"
    },
    {
     "type": "hashtag",
     "text": "#Electronics"
    },
    {
     "type": "plain",
     "text": "\n\n                Electronics \n                Home Audio \n                Speakers \nPryce2498 \nDicos75 \n\n❌Regular Price  : ₹  8999   /- 😱"
    }
   ]
  },
  {
   "id": 20452,
   "type": "message",
   "date": "2023-05-04",
   "date_unixtime": "1683169627",
   "from": "All1App daily",
   "from_id": "channel1858370886",
   "photo": "(File not included. Change data exporting settings to download.)",
   "pryce": "1499",
   "dicos": "70",
   "catagory": "Home",
   "text_entities": [
    {
     "type": "plain",
     "text": "🚀🚀   Milton Pro Cook Induction Kitchen Jewel Set of 3 (Fry pan 24 cm/1.6 litres; Kadhai 24 cm/2.5 litres with Glass lid & Tawa 25 cm), Maroon | Dishwasher Safe | Hot Plate | Flame Safe | Non-Stick✅\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3HCGOEj"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3AQFpG6"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/44mbwv7"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3BbMZvv"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/44pPJ5J"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/42kzgxK"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/42mcFRl"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3nrm5w7"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3AVaSHe"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3oZmypI"
    },
    {
     "type": "plain",
     "text": "\n\n "
    },
    {
     "type": "mention",
     "text": "@1499"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://m.media-amazon.com/images/I/71KXvw5EPPL._SX522_.jpg"
    },
    {
     "type": "plain",
     "text": " \n\n\n"
    },
    {
     "type": "hashtag",
     "text": "#Home"
    },
    {
     "type": "plain",
     "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Dining \n                Cookware \nPryce1499 \nDicos70 \n\n❌Regular Price  : ₹   3999  /- 😱"
    }
   ]
  },
  {
   "id": 20453,
   "type": "message",
   "date": "2023-05-04",
   "date_unixtime": "1683169678",
   "from": "All1App daily",
   "from_id": "channel1858370886",
   "photo": "(File not included. Change data exporting settings to download.)",
   "pryce": "369",
   "dicos": "75",
   "catagory": "Clothing",
   "text_entities": [
    {
     "type": "plain",
     "text": "🚀🚀   Red Tape Men Maroon Round Neck T-Shirt✅\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LTUqgL"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/42kjBhY"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3Vuckdc"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3VyjYDe"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3VxGTP3"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3VvTdQ0"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3VxKD3h"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LWb3IV"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3nqjvGT"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3VtjiPN"
    },
    {
     "type": "plain",
     "text": "\n\n @369\n"
    },
    {
     "type": "link",
     "text": "https://m.media-amazon.com/images/I/61C3S5Edl7L._UX679_.jpg"
    },
    {
     "type": "plain",
     "text": " \n\n\n"
    },
    {
     "type": "hashtag",
     "text": "#Clothing"
    },
    {
     "type": "plain",
     "text": " & Accessories\n\n                Fashion \n                Apparel Steal Deals \n                Apparel_1 \nPryce369 \nDicos75 \n\n❌Regular Price  : ₹  1199   /- 😱"
    }
   ]
  },
  {
   "id": 20454,
   "type": "message",
   "date": "2023-05-04",
   "date_unixtime": "1683169720",
   "from": "All1App daily",
   "from_id": "channel1858370886",
   "photo": "(File not included. Change data exporting settings to download.)",
   "pryce": "399",
   "dicos": "73",
   "catagory": "Computers",
   "text_entities": [
    {
     "type": "plain",
     "text": "🚀🚀   HP v236w USB 2.0 64GB Pen Drive, Metal✅\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/424uaWz"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LTKZOr"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/424ubK7"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/44q4Ko2"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/42pEoAE"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/421eKlX"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3Lz1VrZ"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LzXMo1"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/422cTO1"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LAA0Ia"
    },
    {
     "type": "plain",
     "text": "\n\n @399\n"
    },
    {
     "type": "link",
     "text": "https://m.media-amazon.com/images/I/61xNG4wjOuL._SX569_.jpg"
    },
    {
     "type": "plain",
     "text": " \n\n\n"
    },
    {
     "type": "hashtag",
     "text": "#Computers"
    },
    {
     "type": "plain",
     "text": " & Accessories\n\n                Computers & Accessories \n                External Devices & Data Storage \n                 Pen Drives \nPryce399 \nDicos73 \n\n❌Regular Price  : ₹  1099  /- 😱"
    }
   ]
  },
  {
   "id": 20455,
   "type": "message",
   "date": "2023-05-04",
   "date_unixtime": "1683169784",
   "from": "All1App daily",
   "from_id": "channel1858370886",
   "photo": "(File not included. Change data exporting settings to download.)",
   "pryce": "798",
   "dicos": "82",
   "catagory": "Electronics",
   "text_entities": [
    {
     "type": "plain",
     "text": "🚀🚀   boAt Airdopes Atom 81 True Wireless Earbuds with Upto 50H Playtime, Quad Mics ENx™ Tech, 13MM Drivers, Beast™ Mode(50ms Super Low Latency), ASAP™ Charge, BT v5.3(Aero Blue)✅\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/44vGill"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/424Kcje"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3ASrKOV"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LySado"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3HF7vYK"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LPOuW5"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LUFgIa"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/44rljQm"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LTULA3"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LWbe73"
    },
    {
     "type": "plain",
     "text": "\n\n @798\n"
    },
    {
     "type": "link",
     "text": "https://m.media-amazon.com/images/I/51LdqHFZRnL._SX679_.jpg"
    },
    {
     "type": "plain",
     "text": " \n\n\n"
    },
    {
     "type": "hashtag",
     "text": "#Electronics"
    },
    {
     "type": "plain",
     "text": "\n\n                Electronics \n                Headphones, Earbuds & Accessories \n                Headphones \nPryce798 \nDicos82 \n\n❌Regular Price  : ₹  2999    /- 😱"
    }
   ]
  },
  {
   "id": 20456,
   "type": "message",
   "date": "2023-05-04",
   "date_unixtime": "1683169848",
   "from": "All1App daily",
   "from_id": "channel1858370886",
   "photo": "(File not included. Change data exporting settings to download.)",
   "pryce": "1895",
   "dicos": "73",
   "catagory": "Watches",
   "text_entities": [
    {
     "type": "plain",
     "text": "🚀🚀   Fastrack Reflex Vox Smartwatch|Alexa Built-in|Bright HD Display|Upto 10 Days Battery|5 ATM Water Resistance|Multiple Sports Modes|100+ Watchfaces|24x7 HRM|Sp02|Stress Monitor|Camera & Music Control✅\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3HDlfDy"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3VCSug4"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3VuikCE"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3VtjHBN"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LWbAup"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3NDZUxE"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LRbXGu"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LRw9YQ"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LUzoPh"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LTn55H"
    },
    {
     "type": "plain",
     "text": "\n\n "
    },
    {
     "type": "mention",
     "text": "@1895"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://m.media-amazon.com/images/I/51S0UNv1rHL._UX679_.jpg"
    },
    {
     "type": "plain",
     "text": " \n\n\n"
    },
    {
     "type": "hashtag",
     "text": "#Watches"
    },
    {
     "type": "plain",
     "text": "\n\n                Fashion \n                Onam 2021 \n                Watches and fashion jewellery \nPryce1895 \nDicos73 \n\n❌Regular Price  : ₹  4999   /- 😱"
    }
   ]
  },
  {
   "id": 20457,
   "type": "message",
   "date": "2023-05-04",
   "date_unixtime": "1683169895",
   "from": "All1App daily",
   "from_id": "channel1858370886",
   "photo": "(File not included. Change data exporting settings to download.)",
   "pryce": "599",
   "dicos": "62",
   "catagory": "Watches",
   "text_entities": [
    {
     "type": "plain",
     "text": "FVFS\n 🚀🚀   Maxima Analog Black Dial Men's Watch - O-45842PPGW✅\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/417Wje6"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/4107LZf"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3BbNcih"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3AQUMP9"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/40XN548"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/4107UvL"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/411Swz1"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3AXWsGg"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3ARpPdl"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/426qa8i"
    },
    {
     "type": "plain",
     "text": "\n\n @599\n"
    },
    {
     "type": "link",
     "text": "https://m.media-amazon.com/images/I/81oV59rj9aL._UX679_.jpg"
    },
    {
     "type": "plain",
     "text": " \n\n\n"
    },
    {
     "type": "hashtag",
     "text": "#Watches"
    },
    {
     "type": "plain",
     "text": "\n\n                Fashion \n                Onam 2021 \n                Watches and fashion jewellery \nPryce599 \nDicos62 \n\n❌Regular Price  : ₹  1399    /- 😱"
    }
   ]
  },
  {
   "id": 20458,
   "type": "message",
   "date": "2023-05-04",
   "date_unixtime": "1683169958",
   "from": "All1App daily",
   "from_id": "channel1858370886",
   "photo": "(File not included. Change data exporting settings to download.)",
   "pryce": "18499",
   "dicos": "8",
   "catagory": "Electronics",
   "text_entities": [
    {
     "type": "plain",
     "text": "🚀🚀   OnePlus Nord CE 2 Lite 5G (Blue Tide, 6GB RAM, 128GB Storage)✅\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/41UHnBn"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/424Kvuo"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LTVeSP"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/42iS9BP"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/420cag4"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/423IaQm"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3ntfxgG"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/428wJHh"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3AStXtJ"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3ASs0gR"
    },
    {
     "type": "plain",
     "text": "\n\n "
    },
    {
     "type": "mention",
     "text": "@18499"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://m.media-amazon.com/images/I/71AvQd3VzqL._SX569_.jpg"
    },
    {
     "type": "plain",
     "text": " \n\n\n"
    },
    {
     "type": "hashtag",
     "text": "#Electronics"
    },
    {
     "type": "plain",
     "text": "\n\n                Electronics \n                Mobiles & Accessories \n                Smartphones & Basic Mobiles \nPryce18499 \nDicos8 \n\n❌Regular Price  : ₹ 1999    /- 😱"
    }
   ]
  },
  {
   "id": 20459,
   "type": "message",
   "date": "2023-05-04",
   "date_unixtime": "1683170002",
   "from": "All1App daily",
   "from_id": "channel1858370886",
   "photo": "(File not included. Change data exporting settings to download.)",
   "pryce": "1733",
   "dicos": "71",
   "catagory": "Clothing",
   "text_entities": [
    {
     "type": "plain",
     "text": "🚀🚀   Puma Mens Track Suit✅\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3BbNmWV"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3Vxl427"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3AQorIb"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/423IJts"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3Lu2S53"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3Lyq95H"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3nrmDlF"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LsX8sl"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3nBlCYe"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LrmqHc"
    },
    {
     "type": "plain",
     "text": "\n\n "
    },
    {
     "type": "mention",
     "text": "@1733"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://m.media-amazon.com/images/I/619Xz9K--BL._UY500_.jpg"
    },
    {
     "type": "plain",
     "text": " \n\n\n"
    },
    {
     "type": "hashtag",
     "text": "#Clothing"
    },
    {
     "type": "plain",
     "text": " & Accessories\n\n                Clothing & Accessories \n                Men \n                Sportswear \nPryce1733 \nDicos71 \n\n❌Regular Price  : ₹  4999  /- 😱"
    }
   ]
  },
  {
   "id": 20460,
   "type": "message",
   "date": "2023-05-04",
   "date_unixtime": "1683170063",
   "from": "All1App daily",
   "from_id": "channel1858370886",
   "photo": "(File not included. Change data exporting settings to download.)",
   "pryce": "99",
   "dicos": "NaN",
   "catagory": "Home",
   "text_entities": [
    {
     "type": "plain",
     "text": "🚀🚀   Kitchenwell Multi-Purpose Refrigerator Stand,Furniture Base Stand, Washing Machine Stand,Fridge Stand for Single Door and Double Door (Set of 4) (Black Square)✅\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LoJaaE"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3APPkMm"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3NBQ3bq"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3NAR8jI"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3Ny4Gww"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3AQoBzh"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3ATDKQo"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3HCQwGt"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3NUL2ev"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3NUL2ev"
    },
    {
     "type": "plain",
     "text": "\n\n @99\n"
    },
    {
     "type": "link",
     "text": "https://m.media-amazon.com/images/I/61yrPiC9tVL._SX425_.jpg"
    },
    {
     "type": "plain",
     "text": " \n\n\n"
    },
    {
     "type": "hashtag",
     "text": "#Home"
    },
    {
     "type": "plain",
     "text": " & Kitchen\n\n                Home & Kitchen \n                Large Appliances \n                Parts & Accessories \nPryce99 \nDicosNaN \n\n❌Regular Price  : ₹   99 /- 😱"
    }
   ]
  },
  {
   "id": 20461,
   "type": "message",
   "date": "2023-05-04",
   "date_unixtime": "1683170126",
   "from": "All1App daily",
   "from_id": "channel1858370886",
   "photo": "(File not included. Change data exporting settings to download.)",
   "pryce": "1039",
   "dicos": "50",
   "catagory": "Home",
   "text_entities": [
    {
     "type": "plain",
     "text": "🚀🚀   Signoraware Double Wall Square Dinner Set, 27-Pieces, Maroon✅\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3nwzx1V"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3p1XHl7"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LUzLcD"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/42hZ5yk"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LPPcTf"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LPPcTf"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3p9Ie2D"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/429SFSA"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/421rjxz"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/44vW2Vw"
    },
    {
     "type": "plain",
     "text": "\n\n "
    },
    {
     "type": "mention",
     "text": "@1039"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://m.media-amazon.com/images/I/51jVoUpnR2L._SX425_.jpg"
    },
    {
     "type": "plain",
     "text": " \n\n\n"
    },
    {
     "type": "hashtag",
     "text": "#Home"
    },
    {
     "type": "plain",
     "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Dining \n                Tableware \nPryce1039 \nDicos50 \n\n❌Regular Price  : ₹   1699  /- 😱"
    }
   ]
  },
  {
   "id": 20462,
   "type": "message",
   "date": "2023-05-04",
   "date_unixtime": "1683170165",
   "from": "All1App daily",
   "from_id": "channel1858370886",
   "photo": "(File not included. Change data exporting settings to download.)",
   "pryce": "1338",
   "dicos": "55",
   "catagory": "Electronics",
   "text_entities": [
    {
     "type": "plain",
     "text": "🚀🚀   Ambrane Dots Hype True Wireless Earbuds with 28 Hours Playtime, BoostedBass™ Low Energy Consumption Bluetooth V5.2, Responsive Touch Controls, Voice Assistance, IPX4 (White)✅\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3VuaDwu"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3p9hTln"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/44twHv3"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3VykMrK"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3VxDr7g"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3ATiwSw"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3Vudqpk"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/44qp6NX"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3nrFw82"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/44qPaIQ"
    },
    {
     "type": "plain",
     "text": "\n\n "
    },
    {
     "type": "mention",
     "text": "@1338"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://m.media-amazon.com/images/I/51mJsWUh5JL._SX679_.jpg"
    },
    {
     "type": "plain",
     "text": " \n\n\n"
    },
    {
     "type": "hashtag",
     "text": "#Electronics"
    },
    {
     "type": "plain",
     "text": "\n\n                Electronics \n                Headphones, Earbuds & Accessories \n                Headphones \nPryce1338 \nDicos55 \n\n❌Regular Price  : ₹  1999   /- 😱"
    }
   ]
  },
  {
   "id": 20463,
   "type": "message",
   "date": "2023-05-04",
   "date_unixtime": "1683170208",
   "from": "All1App daily",
   "from_id": "channel1858370886",
   "photo": "(File not included. Change data exporting settings to download.)",
   "pryce": "1899",
   "dicos": "73",
   "catagory": "Electronics",
   "text_entities": [
    {
     "type": "plain",
     "text": "🚀🚀   Juarez Lindenwood Acoustic Guitar Kit, 38 Inches Cutaway, 38C With Bag, Strings, Pick And Strap, TBS Transparent (Blue Sunburst)✅\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/44p5lGt"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3p8bcjq"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3APPyTI"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3p8O8kD"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3p6Ljk1"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3HFmfXQ"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3p8bff6"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3nubiRW"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3VLwD6h"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LVB2Ae"
    },
    {
     "type": "plain",
     "text": "\n\n "
    },
    {
     "type": "mention",
     "text": "@1899"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://m.media-amazon.com/images/I/71xp-yTwyGL._SY450_.jpg"
    },
    {
     "type": "plain",
     "text": " \n\n\n"
    },
    {
     "type": "hashtag",
     "text": "#Electronics"
    },
    {
     "type": "plain",
     "text": "\n\n                Musical Instruments \n                Guitars, Basses & Gear \n                Guitars, Basses & Kits \nPryce1899 \nDicos73 \n\n❌Regular Price  : ₹ 4999   /- 😱"
    }
   ]
  },
  {
   "id": 20464,
   "type": "message",
   "date": "2023-05-04",
   "date_unixtime": "1683170256",
   "from": "All1App daily",
   "from_id": "channel1858370886",
   "photo": "(File not included. Change data exporting settings to download.)",
   "pryce": "959",
   "dicos": "66",
   "catagory": "Clothing",
   "text_entities": [
    {
     "type": "plain",
     "text": "🚀🚀   Aristocrat Spark 53cm Polyester Small Red Duffle Bag✅\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3VvwseV"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3HCFYY2"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3Nz4OvB"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3NxOSdb"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LoJGp6"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3Lz3ciL"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LToI3n"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LRwTNC"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LU0CW8"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LzYSA9"
    },
    {
     "type": "plain",
     "text": "\n\n @959\n"
    },
    {
     "type": "link",
     "text": "https://m.media-amazon.com/images/I/61sQW72viwL._UX679_.jpg"
    },
    {
     "type": "plain",
     "text": " \n\n\n"
    },
    {
     "type": "hashtag",
     "text": "#Clothing"
    },
    {
     "type": "plain",
     "text": " & Accessories\n\n                Bags, Wallets and Luggage \n                Luggage \n                 Travel Duffles \nPryce959 \nDicos66 \n\n❌Regular Price  : ₹  1999   /- 😱"
    }
   ]
  },
  {
   "id": 20465,
   "type": "message",
   "date": "2023-05-04",
   "date_unixtime": "1683170320",
   "from": "All1App daily",
   "from_id": "channel1858370886",
   "photo": "(File not included. Change data exporting settings to download.)",
   "pryce": "849",
   "dicos": "35",
   "catagory": "Computers",
   "text_entities": [
    {
     "type": "plain",
     "text": "🚀🚀   Kingston DataTraveler Exodia DTX/128 GB Pen Drive USB 3.2 Gen 1, Black✅\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LrmMxw"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3AP8XnP"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/417XTN4"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/412uPXd"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/412q14q"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/41413kX"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/412pNu6"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/4108Sbn"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/42oJDRm"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/423D6vy"
    },
    {
     "type": "plain",
     "text": "\n\n @849\n"
    },
    {
     "type": "link",
     "text": "https://m.media-amazon.com/images/I/71yVURnYi-L._SX569_.jpg"
    },
    {
     "type": "plain",
     "text": " \n\n\n"
    },
    {
     "type": "hashtag",
     "text": "#Computers"
    },
    {
     "type": "plain",
     "text": " & Accessories\n\n                Computers & Accessories \n                External Devices & Data Storage \n                 Pen Drives \nPryce849 \nDicos35 \n\n❌Regular Price  : ₹   1199  /- 😱"
    }
   ]
  },
  {
   "id": 20466,
   "type": "message",
   "date": "2023-05-04",
   "date_unixtime": "1683170383",
   "from": "All1App daily",
   "from_id": "channel1858370886",
   "photo": "(File not included. Change data exporting settings to download.)",
   "pryce": "17999",
   "dicos": "43",
   "catagory": "Home",
   "text_entities": [
    {
     "type": "plain",
     "text": "🚀🚀   Havells Enticer Alkaline 100% RO & UV Purified Alkaline with 6.5 L Tank Capacity & 7 stages Water Prurifier (Black & Grey)✅\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/424Yn7Y"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/423X0GD"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3p4HZpw"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/420OZ5x"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/420OZ5x"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3AVc2CA"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3Vy83FR"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3p4Ia4a"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/422SLLD"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3NCRBCf"
    },
    {
     "type": "plain",
     "text": "\n\n "
    },
    {
     "type": "mention",
     "text": "@17999"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://m.media-amazon.com/images/I/51w+v4Bq+pL._SX425_.jpg"
    },
    {
     "type": "plain",
     "text": " \n\n\n"
    },
    {
     "type": "hashtag",
     "text": "#Home"
    },
    {
     "type": "plain",
     "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Home Appliances \n                Water Purifiers & Accessories \nPryce17999 \nDicos43 \n\n❌Regular Price  : ₹  27999   /- 😱"
    }
   ]
  },
  {
   "id": 20467,
   "type": "message",
   "date": "2023-05-04",
   "date_unixtime": "1683170445",
   "from": "All1App daily",
   "from_id": "channel1858370886",
   "photo": "(File not included. Change data exporting settings to download.)",
   "pryce": "1299",
   "dicos": "79",
   "catagory": "Home",
   "text_entities": [
    {
     "type": "plain",
     "text": "🚀🚀   Lifelong LLGS10 Glass Top, 2 Burner Manual Glass Gas Stove, Black (ISI Certified)✅\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/424LElL"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3NCcNYM"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3NytlBb"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://www.amazon.in/dp/B08VWZXTWY?th=1&amp;ck=&amp;linkCode=sl1&amp;tag=avi1deals-21&amp;linkId=eca2704373aae07314689a5ff2a4d6b7&amp;language=en_IN&amp;ref_=as_li_ss_tl"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LASIQ2"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3HFmDFM"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LwYN03"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3HFF5Ol"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3HFmKkG"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3nwbXSM"
    },
    {
     "type": "plain",
     "text": "\n\n "
    },
    {
     "type": "mention",
     "text": "@1299"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://m.media-amazon.com/images/I/71Q5pFUtYmL._SX466_.jpg"
    },
    {
     "type": "plain",
     "text": " \n\n\n"
    },
    {
     "type": "hashtag",
     "text": "#Home"
    },
    {
     "type": "plain",
     "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & dining \n                Steal deals \nPryce1299 \nDicos79 \n\n❌Regular Price  : ₹   3999  /- 😱"
    }
   ]
  },
  {
   "id": 20468,
   "type": "message",
   "date": "2023-05-04",
   "date_unixtime": "1683170508",
   "from": "All1App daily",
   "from_id": "channel1858370886",
   "photo": "(File not included. Change data exporting settings to download.)",
   "pryce": "399",
   "dicos": "73",
   "catagory": "Computers",
   "text_entities": [
    {
     "type": "plain",
     "text": "🚀🚀   Lenovo 15.6\" (39.62cm) Slim Everyday Backpack, Made in India, Compact, Water-resistant, Organized storage:Laptop sleeve,tablet pocket,front workstation,2-side pockets,Padded adjustable shoulder straps✅\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3nx8nIb"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/412SdEa"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3nwAirN"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3ASmWsV"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3NCRUNp"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3nqw1Gl"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LWmWyA"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LTqu4o"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LSTol7"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3NDJXaD"
    },
    {
     "type": "plain",
     "text": "\n\n @399\n"
    },
    {
     "type": "link",
     "text": "https://m.media-amazon.com/images/I/61v0-iGlR1L._SY741_.jpg"
    },
    {
     "type": "plain",
     "text": " \n\n\n"
    },
    {
     "type": "hashtag",
     "text": "#Computers"
    },
    {
     "type": "plain",
     "text": " & Accessories\n\n                Computers & Accessories \n                Accessories & Peripherals \n                Laptop Accessories \nPryce399 \nDicos73 \n\n❌Regular Price  : ₹   1299  /- 😱"
    }
   ]
  },
  {
   "id": 20469,
   "type": "message",
   "date": "2023-05-04",
   "date_unixtime": "1683170573",
   "from": "All1App daily",
   "from_id": "channel1858370886",
   "photo": "(File not included. Change data exporting settings to download.)",
   "pryce": "72",
   "dicos": "64",
   "catagory": "Home",
   "text_entities": [
    {
     "type": "plain",
     "text": "🚀🚀   Kuber Industries Cotton Oval Door Mat for Porch/Kitchen/Bathroom/Laundry Room,(Black) 54KM3948✅\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3HD6fFy"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/44EjMXw"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/42nc3Lj"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3HE6Ve5"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/421ObNF"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/44p5ZDT"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/420d788"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/423DMRC"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/421Ok3F"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3p5Xs8M"
    },
    {
     "type": "plain",
     "text": "\n\n @72\n"
    },
    {
     "type": "link",
     "text": "https://m.media-amazon.com/images/I/51OWfXWn34L._SX300_SY300_QL70_FMwebp_.jpg"
    },
    {
     "type": "plain",
     "text": " \n\n\n"
    },
    {
     "type": "hashtag",
     "text": "#Home"
    },
    {
     "type": "plain",
     "text": " & Kitchen\n\n                Home & Kitchen \n                Home Furnishing \n                Carpets & Rugs \nPryce72 \nDicos64 \n\n❌Regular Price  : ₹  199    /- 😱"
    }
   ]
  },
  {
   "id": 20470,
   "type": "message",
   "date": "2023-05-04",
   "date_unixtime": "1683170639",
   "from": "All1App daily",
   "from_id": "channel1858370886",
   "photo": "(File not included. Change data exporting settings to download.)",
   "pryce": "10999",
   "dicos": "56",
   "catagory": "Electronics",
   "text_entities": [
    {
     "type": "plain",
     "text": "🚀🚀   Redmi 80 cm (32 inches) Android 11 Series HD Ready Smart LED TV | L32M6-RA/L32M7-RA (Black)✅\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3Vt7r49"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3VvV5s0"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3VvV5s0"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/44q0bKr"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3nrzOTI"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3Vy8sbl"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/44qpSul"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/44qpSul"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/44FyfCD"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3HETryF"
    },
    {
     "type": "plain",
     "text": "\n\n "
    },
    {
     "type": "mention",
     "text": "@10999"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://m.media-amazon.com/images/I/71L-lTQnJiL._SX679_.jpg"
    },
    {
     "type": "plain",
     "text": " \n\n\n"
    },
    {
     "type": "hashtag",
     "text": "#Electronics"
    },
    {
     "type": "plain",
     "text": "\n\n                Electronics \n                Home Theater, TV & Video \n                Televisions \nPryce10999 \nDicos56 \n\n❌Regular Price  : ₹  19999   /- 😱"
    }
   ]
  },
  {
   "id": 20471,
   "type": "message",
   "date": "2023-05-04",
   "date_unixtime": "1683170702",
   "from": "All1App daily",
   "from_id": "channel1858370886",
   "photo": "(File not included. Change data exporting settings to download.)",
   "pryce": "254",
   "dicos": "85",
   "catagory": "Luggage",
   "text_entities": [
    {
     "type": "plain",
     "text": "🚀🚀   INSTABUYZ Boys and Girls Fabric School Bag✅\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/44qYvAp"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LT0OEX"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LuWH0I"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3VvtXcA"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3pdlIFS"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3HyXpIP"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/412qe7I"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LU1ava"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/411THhV"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3ND8xbD"
    },
    {
     "type": "plain",
     "text": "\n\n @254\n"
    },
    {
     "type": "link",
     "text": "https://m.media-amazon.com/images/I/91tzXTRWbRL._UX679_.jpg"
    },
    {
     "type": "plain",
     "text": " \n\n\n"
    },
    {
     "type": "hashtag",
     "text": "#Luggage"
    },
    {
     "type": "plain",
     "text": " & Bags\n\n                Bags, Wallets and Luggage \n                Bags & Backpacks \n                 School Bags & Sets \nPryce254 \nDicos85 \n\n❌Regular Price  : ₹  999   /- 😱"
    }
   ]
  },
  {
   "id": 20472,
   "type": "message",
   "date": "2023-05-04",
   "date_unixtime": "1683170763",
   "from": "All1App daily",
   "from_id": "channel1858370886",
   "photo": "(File not included. Change data exporting settings to download.)",
   "pryce": "376",
   "dicos": "53",
   "catagory": "Tools",
   "text_entities": [
    {
     "type": "plain",
     "text": "🚀🚀   EcoLink Multiplug Socket 6A || Pack of 4✅\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LWnk00"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LVCfYi"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3Lro77w"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3p1YB0Z"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/42ncu8p"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/42rWDWl"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/412qBiC"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3pdm8vW"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3AQffn0"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3pb2AZ7"
    },
    {
     "type": "plain",
     "text": "\n\n @376\n"
    },
    {
     "type": "link",
     "text": "https://m.media-amazon.com/images/I/61CjkYbJCdL._SX466_.jpg"
    },
    {
     "type": "plain",
     "text": " \n\n\n"
    },
    {
     "type": "hashtag",
     "text": "#Tools"
    },
    {
     "type": "plain",
     "text": " & Home Improvement\n\n                Home Improvement \n                Electrical \n                 Adapters & Multi-Outlets \nPryce376 \nDicos53 \n\n❌Regular Price  : ₹  749   /- 😱"
    }
   ]
  },
  {
   "id": 20473,
   "type": "message",
   "date": "2023-05-04",
   "date_unixtime": "1683170824",
   "from": "All1App daily",
   "from_id": "channel1858370886",
   "photo": "(File not included. Change data exporting settings to download.)",
   "pryce": "898",
   "dicos": "78",
   "catagory": "Electronics",
   "text_entities": [
    {
     "type": "plain",
     "text": "🚀🚀   PTron Newly Launched Bassbuds Nyx in-Ear Wireless Headphone, Immersive Audio, BT5.1, Stereo Calls, 50ms Movie Mode, Touch Control TWS Earbuds, Digital Case, Type-C Fast Charging & IPX4 (White/Black)✅\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3ATPulS"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3oYg3n6"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3p6NDr8"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/42fSbdi"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3NUM2iL"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3M40jIB"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3nrGJw6"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3AVcJMc"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3Nz5CAD"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3NCQTEL"
    },
    {
     "type": "plain",
     "text": "\n\n @898\n"
    },
    {
     "type": "link",
     "text": "https://m.media-amazon.com/images/I/617jGOug+AL._SX679_.jpg"
    },
    {
     "type": "plain",
     "text": " \n\n\n"
    },
    {
     "type": "hashtag",
     "text": "#Electronics"
    },
    {
     "type": "plain",
     "text": "\n\n                Electronics \n                Headphones, Earbuds & Accessories \n                Headphones \nPryce898 \nDicos78 \n\n❌Regular Price  : ₹  2499   /- 😱"
    }
   ]
  },
  {
   "id": 20474,
   "type": "message",
   "date": "2023-05-04",
   "date_unixtime": "1683170889",
   "from": "All1App daily",
   "from_id": "channel1858370886",
   "photo": "(File not included. Change data exporting settings to download.)",
   "pryce": "1599",
   "dicos": "73",
   "catagory": "Musical",
   "text_entities": [
    {
     "type": "plain",
     "text": "🚀🚀   Boya by-MM1 Super-Cardioid Shotgun Microphone with Real Time Monitoring Compatible with iPhone/Android Smartphones, DSLR Cameras Camcorders for Live Streaming Audio Recording✅\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3AUWrmy"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3NCLVI9"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3VuCX1w"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3NUMiOL"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/422eG5H"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LwkMEi"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/421sJIp"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LxdxvJ"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3HE0reX"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3Lwl12a"
    },
    {
     "type": "plain",
     "text": "\n\n "
    },
    {
     "type": "mention",
     "text": "@1599"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://m.media-amazon.com/images/I/51T6FEDoEvL._SX450_.jpg"
    },
    {
     "type": "plain",
     "text": " \n\n\n"
    },
    {
     "type": "hashtag",
     "text": "#Musical"
    },
    {
     "type": "plain",
     "text": " Instruments\n\n                Musical Instruments \n                Microphones \n                 Condenser \nPryce1599 \nDicos73 \n\n❌Regular Price  : ₹  4999   /- 😱"
    }
   ]
  },
  {
   "id": 20475,
   "type": "message",
   "date": "2023-05-04",
   "date_unixtime": "1683170954",
   "from": "All1App daily",
   "from_id": "channel1858370886",
   "photo": "(File not included. Change data exporting settings to download.)",
   "pryce": "805",
   "dicos": "23",
   "catagory": "Home",
   "text_entities": [
    {
     "type": "plain",
     "text": "🚀🚀   Milton Copper Crown 1100 Water Bottle, 1.09 Litre, 1 Piece, Copper✅\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3Lvi9CA"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LToUzz"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LRAb3s"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3p6jkkn"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/412vOGT"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3p6jmsv"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3AQfxu6"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3ATv24p"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/42ncFR7"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/42meXzV"
    },
    {
     "type": "plain",
     "text": "\n\n @805\n"
    },
    {
     "type": "link",
     "text": "https://m.media-amazon.com/images/I/61P8naoHkHS._SX466_.jpg"
    },
    {
     "type": "plain",
     "text": " \n\n\n"
    },
    {
     "type": "hashtag",
     "text": "#Home"
    },
    {
     "type": "plain",
     "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Dining \n                Kitchen Storage & Containers \nPryce805 \nDicos23 \n\n❌Regular Price  : ₹ 999  /- 😱"
    }
   ]
  },
  {
   "id": 20476,
   "type": "message",
   "date": "2023-05-04",
   "date_unixtime": "1683170994",
   "from": "All1App daily",
   "from_id": "channel1858370886",
   "photo": "(File not included. Change data exporting settings to download.)",
   "pryce": "611",
   "dicos": "80",
   "catagory": "Clothing",
   "text_entities": [
    {
     "type": "plain",
     "text": "🚀🚀   Puma Men's Jacket✅\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3pb2XCZ"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3NASECq"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3p6Mccl"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/42rWYbz"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/44kue6a"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/42lmU8K"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3HBit1m"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/44qQsUc"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/44vIpWj"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3HC2fFk"
    },
    {
     "type": "plain",
     "text": "\n\n @611\n"
    },
    {
     "type": "link",
     "text": "https://m.media-amazon.com/images/I/61j8i-p6IlS._UX679_.jpg"
    },
    {
     "type": "plain",
     "text": " \n\n\n"
    },
    {
     "type": "hashtag",
     "text": "#Clothing"
    },
    {
     "type": "plain",
     "text": " & Accessories\n\n                Clothing & Accessories \n                Men \n                Winter Wear \nPryce611 \nDicos80 \n\n❌Regular Price  : ₹   1999  /- 😱"
    }
   ]
  },
  {
   "id": 20477,
   "type": "message",
   "date": "2023-05-04",
   "date_unixtime": "1683171035",
   "from": "All1App daily",
   "from_id": "channel1858370886",
   "photo": "(File not included. Change data exporting settings to download.)",
   "pryce": "1399",
   "dicos": "30",
   "catagory": "Clothing",
   "text_entities": [
    {
     "type": "plain",
     "text": "🚀🚀   Puma Men's Boyfriend Shorts✅\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/42rXk1T"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3p7292e"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/44pTAjc"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/44pTAjc"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3VA2nef"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3VvVtXu"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/422TInb"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3p5N40W"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/42li9vR"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3VsOjmV"
    },
    {
     "type": "plain",
     "text": "\n\n "
    },
    {
     "type": "mention",
     "text": "@1399"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://m.media-amazon.com/images/I/717xPbjZKzL._UX569_.jpg"
    },
    {
     "type": "plain",
     "text": " \n\n\n"
    },
    {
     "type": "hashtag",
     "text": "#Clothing"
    },
    {
     "type": "plain",
     "text": " & Accessories\n\n                Fashion \n                Apparel Steal Deals \n                Apparel_1 \nPryce1399 \nDicos30 \n\n❌Regular Price  : ₹  1599   /- 😱"
    }
   ]
  },
  {
   "id": 20478,
   "type": "message",
   "date": "2023-05-04",
   "date_unixtime": "1683171099",
   "from": "All1App daily",
   "from_id": "channel1858370886",
   "photo": "(File not included. Change data exporting settings to download.)",
   "pryce": "2235",
   "dicos": "30",
   "catagory": "Home",
   "text_entities": [
    {
     "type": "plain",
     "text": "🚀🚀   Bajaj Splendid 1200W Induction Cooktop with Pan Sensor and Voltage Pro Technology, Black✅\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/42n1QP7"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/44Fz0eX"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/41610VI"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/414r3MY"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/414kgmJ"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3AQWO1J"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LVx3Uj"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LT1tWX"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LTNq3x"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3VxPqSd"
    },
    {
     "type": "plain",
     "text": "\n\n "
    },
    {
     "type": "mention",
     "text": "@2235"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://m.media-amazon.com/images/I/71SEfU2UlpL._SX466_.jpg"
    },
    {
     "type": "plain",
     "text": " \n\n\n"
    },
    {
     "type": "hashtag",
     "text": "#Home"
    },
    {
     "type": "plain",
     "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Dining \n                 Gas Stoves \nPryce2235 \nDicos30 \n\n❌Regular Price  : ₹  2765   /- 😱"
    }
   ]
  },
  {
   "id": 20479,
   "type": "message",
   "date": "2023-05-04",
   "date_unixtime": "1683171161",
   "from": "All1App daily",
   "from_id": "channel1858370886",
   "photo": "(File not included. Change data exporting settings to download.)",
   "pryce": "898",
   "dicos": "80",
   "catagory": "Health",
   "text_entities": [
    {
     "type": "plain",
     "text": "🚀🚀   Beurer HR 8000 rotary shaver Precision cutting system with 3 spring-loaded dual-ring shaver heads 2-in-1 beard and sideburn styler as well as pop-up contour trimmer with 3 years warranty✅\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3nwBag3"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3NF4Ims"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3NBukQY"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3NCMn9j"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LWob0I"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/40YfN4Y"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3NASKtM"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3ASvO1F"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3nqmvTF"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LRepNc"
    },
    {
     "type": "plain",
     "text": "\n\n @898\n"
    },
    {
     "type": "link",
     "text": "https://m.media-amazon.com/images/I/51m7cwaKcdL._SX679_.jpg"
    },
    {
     "type": "plain",
     "text": " \n\n\n"
    },
    {
     "type": "hashtag",
     "text": "#Health"
    },
    {
     "type": "plain",
     "text": " & Personal Care\n\n                Health & Personal Care \n                Personal Care \n                 Shaving, Waxing & Beard Care \nPryce898 \nDicos80 \n\n❌Regular Price  : ₹  1999  /- 😱"
    }
   ]
  },
  {
   "id": 20480,
   "type": "message",
   "date": "2023-05-04",
   "date_unixtime": "1683171225",
   "from": "All1App daily",
   "from_id": "channel1858370886",
   "photo": "(File not included. Change data exporting settings to download.)",
   "pryce": "139",
   "dicos": "75",
   "catagory": "Electronics",
   "text_entities": [
    {
     "type": "plain",
     "text": "🚀🚀   Amazon Basics Amazonbasics Aaaa Everyday Alkaline Batteries For Stylus (8-Pack) - Appearance May Vary✅\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/426s7Sa"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3pccgTd"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/40XPuMc"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3APR2gK"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3ASu4p7"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3ARs3cH"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3Hz2YqX"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3HFGmoB"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LWonwY"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LzK4Bu"
    },
    {
     "type": "plain",
     "text": "\n\n @139\n"
    },
    {
     "type": "link",
     "text": "https://m.media-amazon.com/images/I/71seLQCnN3L._SX569_.jpg"
    },
    {
     "type": "plain",
     "text": " \n\n\n"
    },
    {
     "type": "hashtag",
     "text": "#Electronics"
    },
    {
     "type": "plain",
     "text": "\n\n                Electronics \n                General Purpose Batteries & Battery Chargers \n                 Disposable Batteries \nPryce139 \nDicos75 \n\n❌Regular Price  : ₹  550   /- 😱"
    }
   ]
  },
  {
   "id": 20481,
   "type": "message",
   "date": "2023-05-04",
   "date_unixtime": "1683171288",
   "from": "All1App daily",
   "from_id": "channel1858370886",
   "photo": "(File not included. Change data exporting settings to download.)",
   "pryce": "869",
   "dicos": "57",
   "catagory": "Computers",
   "text_entities": [
    {
     "type": "plain",
     "text": "🚀🚀   Tizum ZW78- Full HD 1080p Webcam, Widescreen Viewing Angle, Auto Light Correction, Noise-Reducing Mic, for Skype, FaceTime, Hangouts, Xbox, PC/Mac/Laptop/MacBook/Tablet✅\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3p4JPXs"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/423USii"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LEyEMW"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/423UW1w"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3p6kdtd"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3BbPvSt"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/414lcHL"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3ATkAde"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3ATkAde"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3BbPyh7"
    },
    {
     "type": "plain",
     "text": "\n\n @869\n"
    },
    {
     "type": "link",
     "text": "https://m.media-amazon.com/images/I/61swTVqOoYL._SX679_.jpg"
    },
    {
     "type": "plain",
     "text": " \n\n\n"
    },
    {
     "type": "hashtag",
     "text": "#Computers"
    },
    {
     "type": "plain",
     "text": " & Accessories\n\n                Computers & Accessories \n                Accessories & Peripherals \n                Audio & Video Accessories \nPryce869 \nDicos57 \n\n❌Regular Price  : ₹ 1699    /- 😱"
    }
   ]
  },
  {
   "id": 20482,
   "type": "message",
   "date": "2023-05-04",
   "date_unixtime": "1683171350",
   "from": "All1App daily",
   "from_id": "channel1858370886",
   "photo": "(File not included. Change data exporting settings to download.)",
   "pryce": "539",
   "dicos": "74",
   "catagory": "Sports",
   "text_entities": [
    {
     "type": "plain",
     "text": "🚀🚀   Vector X OJK-127 Unisex Jacket✅\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/41jiA9b"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3nnAOZd"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3HBj3fy"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/41jiKNP"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3VCUCV6"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/42pFdt9"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3HBj5Ec"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3VxJCbf"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/42lKulv"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3oYhj9O"
    },
    {
     "type": "plain",
     "text": "\n\n @539\n"
    },
    {
     "type": "link",
     "text": "https://m.media-amazon.com/images/I/61Cc6BhWd9L._SY450_.jpg"
    },
    {
     "type": "plain",
     "text": " \n\n\n"
    },
    {
     "type": "hashtag",
     "text": "#Sports"
    },
    {
     "type": "plain",
     "text": ", Fitness & Outdoors\n\n                Clothing & Accessories \n                Men \n                Sportswear \nPryce539 \nDicos74 \n\n❌Regular Price  : ₹  1499    /- 😱"
    }
   ]
  },
  {
   "id": 20483,
   "type": "message",
   "date": "2023-05-04",
   "date_unixtime": "1683171412",
   "from": "All1App daily",
   "from_id": "channel1858370886",
   "photo": "(File not included. Change data exporting settings to download.)",
   "pryce": "6598",
   "dicos": "63",
   "catagory": "Electronics",
   "text_entities": [
    {
     "type": "plain",
     "text": "🚀🚀   GOVO GOSURROUND 900 | 200W Soundbar, 2.1 Channel Home Theatre, 6.5” Wired Subwoofer, HDMI, AUX, USB & Bluetooth, 4 Equalizer Modes, Stylish Remote & LED Display, RMS 160W (Platinum Black)✅\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/42rSl1f"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3pbG0jq"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/421tCkd"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/42kmBLg"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/42jQnQk"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3p2DT17"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/423FiTO"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/423L1sy"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3p9k3kZ"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/41UKs4p"
    },
    {
     "type": "plain",
     "text": "\n\n "
    },
    {
     "type": "mention",
     "text": "@6598"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://m.media-amazon.com/images/I/81dajKIAn1L._SY450_.jpg"
    },
    {
     "type": "plain",
     "text": " \n\n\n"
    },
    {
     "type": "hashtag",
     "text": "#Electronics"
    },
    {
     "type": "plain",
     "text": "\n\n                Electronics \n                Home Audio \n                Speakers \nPryce6598 \nDicos63 \n\n❌Regular Price  : ₹  14999   /- 😱"
    }
   ]
  },
  {
   "id": 20484,
   "type": "message",
   "date": "2023-05-04",
   "date_unixtime": "1683171453",
   "from": "All1App daily",
   "from_id": "channel1858370886",
   "photo": "(File not included. Change data exporting settings to download.)",
   "pryce": "444",
   "dicos": "69",
   "catagory": "Tools",
   "text_entities": [
    {
     "type": "plain",
     "text": "🚀🚀   Anchor by Panasonic 66103 Roma Urban Modular 1M 10A V0 Grade Polycarbonate Bell Push Switch (White, Pack of 10)✅\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/420eMKU"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/420QXCX"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3Vtmg6T"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3VDADWb"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3p73oOW"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3HEVd2N"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3AV9sga"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/44tcjdB"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/44vY9Zs"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/44ukDtO"
    },
    {
     "type": "plain",
     "text": "\n\n @444\n"
    },
    {
     "type": "link",
     "text": "https://m.media-amazon.com/images/I/511duCq5YaL._SX425_.jpg"
    },
    {
     "type": "plain",
     "text": " \n\n\n"
    },
    {
     "type": "hashtag",
     "text": "#Tools"
    },
    {
     "type": "plain",
     "text": " & Home Improvement\n\n                Home Improvement \n                Electrical \n                 Switches & Dimmers \nPryce444 \nDicos69 \n\n❌Regular Price  : ₹  1299   /- 😱"
    }
   ]
  },
  {
   "id": 20485,
   "type": "message",
   "date": "2023-05-04",
   "date_unixtime": "1683171515",
   "from": "All1App daily",
   "from_id": "channel1858370886",
   "photo": "(File not included. Change data exporting settings to download.)",
   "pryce": "1873",
   "dicos": "57",
   "catagory": "Home",
   "text_entities": [
    {
     "type": "plain",
     "text": "🚀🚀   Bajaj Grace Neo BBD EE 1200mm Purple Haze Ceiling Fan✅\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/44FzBgH"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/44roPKy"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3p2SLfT"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3oYhG4c"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LVDBCm"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3HC3xQG"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3p2SRUN"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/42o76SE"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3pa2wco"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3nqn3Jd"
    },
    {
     "type": "plain",
     "text": "\n\n "
    },
    {
     "type": "mention",
     "text": "@1873"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://m.media-amazon.com/images/I/61tansbCmkL._SX466_.jpg"
    },
    {
     "type": "plain",
     "text": " \n\n\n"
    },
    {
     "type": "hashtag",
     "text": "#Home"
    },
    {
     "type": "plain",
     "text": " & Kitchen\n\n                Home & Kitchen \n                Heating, Cooling & Air Quality \n                Fans \nPryce1873 \nDicos57 \n\n❌Regular Price  : ₹   3999 /- 😱"
    }
   ]
  },
  {
   "id": 20486,
   "type": "message",
   "date": "2023-05-04",
   "date_unixtime": "1683171577",
   "from": "All1App daily",
   "from_id": "channel1858370886",
   "photo": "(File not included. Change data exporting settings to download.)",
   "pryce": "1338",
   "dicos": "55",
   "catagory": "Electronics",
   "text_entities": [
    {
     "type": "plain",
     "text": "🚀🚀   Ambrane Dots Hype True Wireless Earbuds with 28 Hours Playtime, BoostedBass™ Low Energy Consumption Bluetooth V5.2, Responsive Touch Controls, Voice Assistance, IPX4 (White)✅\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3HFGPal"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LxeOmv"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3nwmAoU"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LRxxJV"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3NL81bs"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LvjEkc"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/4143dRB"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3AR7YmY"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3AR7YmY"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/4162b7A"
    },
    {
     "type": "plain",
     "text": "\n\n "
    },
    {
     "type": "mention",
     "text": "@1338"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://m.media-amazon.com/images/I/51mJsWUh5JL._SX679_.jpg"
    },
    {
     "type": "plain",
     "text": " \n\n\n"
    },
    {
     "type": "hashtag",
     "text": "#Electronics"
    },
    {
     "type": "plain",
     "text": "\n\n                Electronics \n                Headphones, Earbuds & Accessories \n                Headphones \nPryce1338 \nDicos55 \n\n❌Regular Price  : ₹  1999   /- 😱"
    }
   ]
  },
  {
   "id": 20487,
   "type": "message",
   "date": "2023-05-04",
   "date_unixtime": "1683171643",
   "from": "All1App daily",
   "from_id": "channel1858370886",
   "photo": "(File not included. Change data exporting settings to download.)",
   "pryce": "1299",
   "dicos": "79",
   "catagory": "Home",
   "text_entities": [
    {
     "type": "plain",
     "text": "🚀🚀   Lifelong LLGS10 Glass Top, 2 Burner Manual Glass Gas Stove, Black (ISI Certified)✅\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3nthQjQ"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/424NaEo"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3AU6cBA"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3ASuMCN"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3nxagEL"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3HDoE5i"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3nqPKpe"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/421OpV6"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3HDwUCq"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3ARqETx"
    },
    {
     "type": "plain",
     "text": "\n\n "
    },
    {
     "type": "mention",
     "text": "@1299"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://m.media-amazon.com/images/I/71Q5pFUtYmL._SX466_.jpg"
    },
    {
     "type": "plain",
     "text": " \n\n\n"
    },
    {
     "type": "hashtag",
     "text": "#Home"
    },
    {
     "type": "plain",
     "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & dining \n                Steal deals \nPryce1299 \nDicos79 \n\n❌Regular Price  : ₹   3999  /- 😱"
    }
   ]
  },
  {
   "id": 20488,
   "type": "message",
   "date": "2023-05-04",
   "date_unixtime": "1683171706",
   "from": "All1App daily",
   "from_id": "channel1858370886",
   "photo": "(File not included. Change data exporting settings to download.)",
   "pryce": "399",
   "dicos": "73",
   "catagory": "Computers",
   "text_entities": [
    {
     "type": "plain",
     "text": "🚀🚀   Lenovo 15.6\" (39.62cm) Slim Everyday Backpack, Made in India, Compact, Water-resistant, Organized storage:Laptop sleeve,tablet pocket,front workstation,2-side pockets,Padded adjustable shoulder straps✅\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3HDoBq8"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/42loffM"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3ASoWkV"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LUwQRe"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LwviLQ"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3nB4ZMl"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/429VeEc"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/421iq7f"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3NGl3qO"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3NwVV5U"
    },
    {
     "type": "plain",
     "text": "\n\n @399\n"
    },
    {
     "type": "link",
     "text": "https://m.media-amazon.com/images/I/61v0-iGlR1L._SY741_.jpg"
    },
    {
     "type": "plain",
     "text": " \n\n\n"
    },
    {
     "type": "hashtag",
     "text": "#Computers"
    },
    {
     "type": "plain",
     "text": " & Accessories\n\n                Computers & Accessories \n                Accessories & Peripherals \n                Laptop Accessories \nPryce399 \nDicos73 \n\n❌Regular Price  : ₹   1299  /- 😱"
    }
   ]
  },
  {
   "id": 20489,
   "type": "message",
   "date": "2023-05-04",
   "date_unixtime": "1683171770",
   "from": "All1App daily",
   "from_id": "channel1858370886",
   "photo": "(File not included. Change data exporting settings to download.)",
   "pryce": "72",
   "dicos": "64",
   "catagory": "Home",
   "text_entities": [
    {
     "type": "plain",
     "text": "🚀🚀   Kuber Industries Cotton Oval Door Mat for Porch/Kitchen/Bathroom/Laundry Room,(Black) 54KM3948✅\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/426pIqs"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3NCXrDC"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3nweGvu"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/44wjgun"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3nobLVU"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3NDMdyD"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3NCf3PK"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3p8dxec"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3oX5lNF"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3HBjQgw"
    },
    {
     "type": "plain",
     "text": "\n\n @72\n"
    },
    {
     "type": "link",
     "text": "https://m.media-amazon.com/images/I/71wleEDnjNL._SX522_.jpg"
    },
    {
     "type": "plain",
     "text": " \n\n\n"
    },
    {
     "type": "hashtag",
     "text": "#Home"
    },
    {
     "type": "plain",
     "text": " & Kitchen\n\n                Home & Kitchen \n                Home Furnishing \n                Carpets & Rugs \nPryce72 \nDicos64 \n\n❌Regular Price  : ₹  199    /- 😱"
    }
   ]
  },
  {
   "id": 20490,
   "type": "message",
   "date": "2023-05-04",
   "date_unixtime": "1683171831",
   "from": "All1App daily",
   "from_id": "channel1858370886",
   "photo": "(File not included. Change data exporting settings to download.)",
   "pryce": "131",
   "dicos": "53",
   "catagory": "Beauty",
   "text_entities": [
    {
     "type": "plain",
     "text": "🚀🚀   Park Avenue Cool Blue Soap Pack of 125 * 4 (500gm)✅\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3Lrq0B8"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/42pIV62"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3nBp7xQ"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3VulK8s"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3Lz64fx"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3p74avk"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/420RuEX"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/44t7JMj"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/412xEYj"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/419uuSB"
    },
    {
     "type": "plain",
     "text": "\n\n @131\n"
    },
    {
     "type": "link",
     "text": "https://m.media-amazon.com/images/I/81VZi7VFmRL._SX569_PIbundle-4,TopRight,0,0_AA569SH20_.jpg"
    },
    {
     "type": "plain",
     "text": " \n\n\n"
    },
    {
     "type": "hashtag",
     "text": "#Beauty"
    },
    {
     "type": "plain",
     "text": "\n\n                Beauty \n                Bath & Body \n                Cleansers \nPryce131 \nDicos53 \n\n❌Regular Price  : ₹ 280   /- 😱"
    }
   ]
  },
  {
   "id": 20491,
   "type": "message",
   "date": "2023-05-04",
   "date_unixtime": "1683171894",
   "from": "All1App daily",
   "from_id": "channel1858370886",
   "photo": "(File not included. Change data exporting settings to download.)",
   "pryce": "999",
   "dicos": "38",
   "catagory": "Home",
   "text_entities": [
    {
     "type": "plain",
     "text": "🚀🚀   Hindware Zorio Ax Neo 100mm Exhaust Fan with Low Noise, Powerful Air Suction and High Speed, For Kitchen or Bathroom With Overload Protection For Odourless, Clean and Fresh Air (White)✅\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/412xJLB"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/44p8uWN"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/42mgOVp"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/44r0ogt"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3HFpoH8"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3HHHRTh"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/44p8s17"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/44uHRj8"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/44kwwlM"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3nrIqcW"
    },
    {
     "type": "plain",
     "text": "\n\n @999\n"
    },
    {
     "type": "link",
     "text": "https://m.media-amazon.com/images/I/51rOY5YHIXL._SX425_.jpg"
    },
    {
     "type": "plain",
     "text": " \n\n\n"
    },
    {
     "type": "hashtag",
     "text": "#Home"
    },
    {
     "type": "plain",
     "text": " & Kitchen\n\n                Home & Kitchen \n                Heating, Cooling & Air Quality \n                Fans \nPryce999 \nDicos38 \n\n❌Regular Price  : ₹  1499   /- 😱"
    }
   ]
  },
  {
   "id": 20492,
   "type": "message",
   "date": "2023-05-04",
   "date_unixtime": "1683171956",
   "from": "All1App daily",
   "from_id": "channel1858370886",
   "photo": "(File not included. Change data exporting settings to download.)",
   "pryce": "1548",
   "dicos": "61",
   "catagory": "Home",
   "text_entities": [
    {
     "type": "plain",
     "text": "🚀🚀   Hindware Smart Appliances Recio Bianco 1200MM Star Rated Ceiling Fan for home with 425 RPM Energy Efficient Silent Air Delivery Fan 51 Watt copper motor and aerodynamic blades✅\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/42qXXJ5"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3nrC8Kq"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3nqyv7D"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/411S4AY"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/410bcz7"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/415nFBR"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/41jjGBP"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3NwWkVY"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3HHI88L"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LAV8hA"
    },
    {
     "type": "plain",
     "text": "\n\n "
    },
    {
     "type": "mention",
     "text": "@1548"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://m.media-amazon.com/images/I/51pQIrOukaL._SX425_.jpg"
    },
    {
     "type": "plain",
     "text": " \n\n\n"
    },
    {
     "type": "hashtag",
     "text": "#Home"
    },
    {
     "type": "plain",
     "text": " & Kitchen\n\n                Home & Kitchen \n                Heating, Cooling & Air Quality \n                Fans \nPryce1548 \nDicos61 \n\n❌Regular Price  : ₹   2999  /- 😱"
    }
   ]
  },
  {
   "id": 20493,
   "type": "message",
   "date": "2023-05-04",
   "date_unixtime": "1683172018",
   "from": "All1App daily",
   "from_id": "channel1858370886",
   "photo": "(File not included. Change data exporting settings to download.)",
   "pryce": "949",
   "dicos": "60",
   "catagory": "Industrial",
   "text_entities": [
    {
     "type": "plain",
     "text": "🚀🚀   Polycab 3W LED Panel Light Scintillate Edge Slim Square Smart Offers Bright Lumination Long Lifespan No Harmful Radiation (Neutral White, 4000K, 6 PCS, Cut Out: 2.55 inches)✅\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3NyvxbT"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3NyvxbT"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3nrIGsq"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3Hz6YYA"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3pcdMVp"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3AQsrsb"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/423Gjv6"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LTJ0tj"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3nrCtga"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/420fY0Q"
    },
    {
     "type": "plain",
     "text": "\n\n @949\n"
    },
    {
     "type": "link",
     "text": "https://m.media-amazon.com/images/I/41bmfarzhSL._SX679_.jpg"
    },
    {
     "type": "plain",
     "text": " \n\n\n"
    },
    {
     "type": "hashtag",
     "text": "#Industrial"
    },
    {
     "type": "plain",
     "text": " & Scientific\n\n                Home & Kitchen \n                Indoor Lighting \n                Light Bulbs \nPryce949 \nDicos60 \n\n❌Regular Price  : ₹ 1999    /- 😱"
    }
   ]
  },
  {
   "id": 20494,
   "type": "message",
   "date": "2023-05-04",
   "date_unixtime": "1683172081",
   "from": "All1App daily",
   "from_id": "channel1858370886",
   "photo": "(File not included. Change data exporting settings to download.)",
   "pryce": "680",
   "dicos": "51",
   "catagory": "Grocery",
   "text_entities": [
    {
     "type": "plain",
     "text": "🚀🚀   Nature Purify Dry Fruits Combo Gift Pack for 1 Kg (Almond, Mixed Nuts, Raisins, Apricot - 250g Each)✅\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/426tTmi"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/426u1SO"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3Vsyw7H"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/42mh2fd"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3NAUIua"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3VumjPC"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/44vZ3VQ"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3VA4Fdl"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3VA4Fdl"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3NUOfL5"
    },
    {
     "type": "plain",
     "text": "\n\n @680\n"
    },
    {
     "type": "link",
     "text": "https://m.media-amazon.com/images/I/51UD+zN8DML._SX300_SY300_.jpg"
    },
    {
     "type": "plain",
     "text": " \n\n\n"
    },
    {
     "type": "hashtag",
     "text": "#Grocery"
    },
    {
     "type": "plain",
     "text": " & Gourmet Foods\n\n                Grocery & Gourmet Foods \n                Dried Fruits, Nuts & Seeds \n                Dried Fruits \nPryce680 \nDicos51 \n\n❌Regular Price  : ₹  1299   /- 😱"
    }
   ]
  },
  {
   "id": 20495,
   "type": "message",
   "date": "2023-05-04",
   "date_unixtime": "1683172143",
   "from": "All1App daily",
   "from_id": "channel1858370886",
   "photo": "(File not included. Change data exporting settings to download.)",
   "pryce": "1155",
   "dicos": "77",
   "catagory": "Home",
   "text_entities": [
    {
     "type": "plain",
     "text": "🚀🚀   Happer Plastic, Alloy Steel Cloth Drying Stand, Exquisite Designed, Winsome (White & Blue)✅\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LoXkZy"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3HE9M6N"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LvkH3C"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/42i2ugA"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LEA4ae"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3ASpDe1"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3ATLWQI"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3HBBwJ7"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3AZnWLH"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/4144esT"
    },
    {
     "type": "plain",
     "text": "\n\n "
    },
    {
     "type": "mention",
     "text": "@1155"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://m.media-amazon.com/images/I/71qc5keTnpL._SX425_.jpg"
    },
    {
     "type": "plain",
     "text": " \n\n\n"
    },
    {
     "type": "hashtag",
     "text": "#Home"
    },
    {
     "type": "plain",
     "text": " & Kitchen\n\n                Home & Kitchen \n                Home Storage & Organisation \n                Laundry Organization \nPryce1155 \nDicos77 \n\n❌Regular Price  : ₹  3999   /- 😱"
    }
   ]
  },
  {
   "id": 20496,
   "type": "message",
   "date": "2023-05-04",
   "date_unixtime": "1683172204",
   "from": "All1App daily",
   "from_id": "channel1858370886",
   "photo": "(File not included. Change data exporting settings to download.)",
   "pryce": "437",
   "dicos": "60",
   "catagory": "Home",
   "text_entities": [
    {
     "type": "plain",
     "text": "🚀🚀   Butterfly Rapid Frypan 240 mm Induction Base✅\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LzIhMA"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LwwAXc"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LwwHSC"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3HE9RHD"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3NE49cy"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3HE2tf5"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3VyoCBa"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/415o8E7"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/416DEiV"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/42mCxwv"
    },
    {
     "type": "plain",
     "text": "\n\n @437\n"
    },
    {
     "type": "link",
     "text": "https://m.media-amazon.com/images/I/61NilmXF1xL._SX522_.jpg"
    },
    {
     "type": "plain",
     "text": " \n\n\n"
    },
    {
     "type": "hashtag",
     "text": "#Home"
    },
    {
     "type": "plain",
     "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Dining \n                Cookware \nPryce437 \nDicos60 \n\n❌Regular Price  : ₹ 999  /- 😱"
    }
   ]
  },
  {
   "id": 20497,
   "type": "message",
   "date": "2023-05-04",
   "date_unixtime": "1683172266",
   "from": "All1App daily",
   "from_id": "channel1858370886",
   "photo": "(File not included. Change data exporting settings to download.)",
   "pryce": "198",
   "dicos": "77",
   "catagory": "Clothing",
   "text_entities": [
    {
     "type": "plain",
     "text": "🚀🚀   dockstreet Stretchable Light Fabric Cargo Type Styled Leggings✅\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/42G7JXZ"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/44rLCWI"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3p2UMbX"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/44t8ghh"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/42rTWnL"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3Hz4JEz"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/42qYzyn"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/424G2rR"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/42pJHQu"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/421v469"
    },
    {
     "type": "plain",
     "text": "\n\n @198\n"
    },
    {
     "type": "link",
     "text": "https://m.media-amazon.com/images/I/71Skt4kNfwL._UY741_.jpg"
    },
    {
     "type": "plain",
     "text": " \n\n\n"
    },
    {
     "type": "hashtag",
     "text": "#Clothing"
    },
    {
     "type": "plain",
     "text": " & Accessories\n\n                Clothing & Accessories \n                Women \n                Western Wear \nPryce198 \nDicos77 \n\n❌Regular Price  : ₹   799  /- 😱"
    }
   ]
  },
  {
   "id": 20498,
   "type": "message",
   "date": "2023-05-04",
   "date_unixtime": "1683172328",
   "from": "All1App daily",
   "from_id": "channel1858370886",
   "photo": "(File not included. Change data exporting settings to download.)",
   "pryce": "3164",
   "dicos": "49",
   "catagory": "Home",
   "text_entities": [
    {
     "type": "plain",
     "text": "🚀🚀   Havells 1000 Watt Carbon Heater (Black) (GHRGHBWK100)✅\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LTZ3HF"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/44tdrxR"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/44rqy2u"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/410bVjP"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3M42Xy1"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/44vZwY6"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3AQZFaX"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3NBx1ly"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/44rM1sc"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/40YKkPX"
    },
    {
     "type": "plain",
     "text": "\n\n "
    },
    {
     "type": "mention",
     "text": "@3164"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://m.media-amazon.com/images/I/61VTgA5NukL._SX425_.jpg"
    },
    {
     "type": "plain",
     "text": " \n\n\n"
    },
    {
     "type": "hashtag",
     "text": "#Home"
    },
    {
     "type": "plain",
     "text": " & Kitchen\n\n                Home & Kitchen \n                Heating, Cooling & Air Quality \n                Room Heaters \nPryce3164 \nDicos49 \n\n❌Regular Price  : ₹  5999  /- 😱"
    }
   ]
  },
  {
   "id": 20499,
   "type": "message",
   "date": "2023-05-04",
   "date_unixtime": "1683172392",
   "from": "All1App daily",
   "from_id": "channel1858370886",
   "photo": "(File not included. Change data exporting settings to download.)",
   "pryce": "3999",
   "dicos": "80",
   "catagory": "Home",
   "text_entities": [
    {
     "type": "plain",
     "text": "🚀🚀   Wellspire Instant Pot with #304 Stainless Steel Inner Pot, Pressure Cook, Sauté, Steam and more (NOW WITH CUSTOMISED INDIAN RECIPIES) (3 Litres (Black))✅\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3NCUZwX"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LUy7Yw"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3VvzLml"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3VvXUcA"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3VxLOzv"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/424yIfB"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3nocYfU"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/44vKP7l"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3paWBna"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3Ny8s9a"
    },
    {
     "type": "plain",
     "text": "\n\n "
    },
    {
     "type": "mention",
     "text": "@3999"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://m.media-amazon.com/images/I/41iSWKiA9HL._SX300_SY300_QL70_FMwebp_.jpg"
    },
    {
     "type": "plain",
     "text": " \n\n\n"
    },
    {
     "type": "hashtag",
     "text": "#Home"
    },
    {
     "type": "plain",
     "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Dining \n                Cookware \nPryce3999 \nDicos80"
    }
   ]
  },
  {
   "id": 20500,
   "type": "message",
   "date": "2023-05-04",
   "date_unixtime": "1683172454",
   "from": "All1App daily",
   "from_id": "channel1858370886",
   "photo": "(File not included. Change data exporting settings to download.)",
   "pryce": "3068",
   "dicos": "61",
   "catagory": "Home",
   "text_entities": [
    {
     "type": "plain",
     "text": "🚀🚀   Butterfly Cordial 2L, 3L & 5L Stainless Steel Outer Lid Pressure Cooker✅\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3Vun632"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3AUZ6g2"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3AUZ6g2"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/42i37Xu"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3p2V6Yd"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3nBpQPA"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3HD9kp6"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3VypzcI"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/42o8EMl"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3p2V2aV"
    },
    {
     "type": "plain",
     "text": "\n\n "
    },
    {
     "type": "mention",
     "text": "@3068"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://m.media-amazon.com/images/I/71kh3eARwwS._SX425_.jpg"
    },
    {
     "type": "plain",
     "text": " \n\n\n"
    },
    {
     "type": "hashtag",
     "text": "#Home"
    },
    {
     "type": "plain",
     "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Dining \n                Cookware \nPryce3068 \nDicos61 \n\n❌Regular Price  : ₹ 5999   /- 😱"
    }
   ]
  },
  {
   "id": 20501,
   "type": "message",
   "date": "2023-05-04",
   "date_unixtime": "1683172492",
   "from": "All1App daily",
   "from_id": "channel1858370886",
   "photo": "(File not included. Change data exporting settings to download.)",
   "pryce": "172",
   "dicos": "48",
   "catagory": "Beauty",
   "text_entities": [
    {
     "type": "plain",
     "text": "🚀🚀   Santoor Refreshing Skin Body Wash, Tangy Orange Oil & Neroli Extracts, Soap-Free, Paraben-Free, pH Balanced Shower Gel (230 ml) with Loofah✅\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LWqz7G"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3VvAd41"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3HD9qwY"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/42n4RyV"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3HFqS4a"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3VzNNDM"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3NFZfLZ"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/42n50lX"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3VsRevT"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3pbIgqU"
    },
    {
     "type": "plain",
     "text": "\n\n @172\n"
    },
    {
     "type": "link",
     "text": "https://m.media-amazon.com/images/I/716kydGE4DL._SX569_PIbundle-2,TopRight,0,0_AA569SH20_.jpg"
    },
    {
     "type": "plain",
     "text": " \n\n\n"
    },
    {
     "type": "hashtag",
     "text": "#Beauty"
    },
    {
     "type": "plain",
     "text": "\n\n                Beauty \n                Bath & Body \n                Cleansers \nPryce172 \nDicos48 \n\n❌Regular Price  : ₹  330   /- 😱"
    }
   ]
  },
  {
   "id": 20502,
   "type": "message",
   "date": "2023-05-04",
   "date_unixtime": "1683172553",
   "from": "All1App daily",
   "from_id": "channel1858370886",
   "photo": "(File not included. Change data exporting settings to download.)",
   "pryce": "696",
   "dicos": "85",
   "catagory": "Clothing",
   "text_entities": [
    {
     "type": "plain",
     "text": "🚀🚀   RIVER Mens Jeans✅\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3VvyZFY"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3nkSF2Y"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3NIqFkB"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/422WLvD"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3Lu7hVD"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LT48jp"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LA2vWN"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LA2DFL"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LvlD88"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/42oNFJu"
    },
    {
     "type": "plain",
     "text": "\n\n @696\n"
    },
    {
     "type": "link",
     "text": "https://m.media-amazon.com/images/I/7184hoo-f3L._UX569_.jpg"
    },
    {
     "type": "plain",
     "text": " \n\n\n"
    },
    {
     "type": "hashtag",
     "text": "#Clothing"
    },
    {
     "type": "plain",
     "text": " & Accessories\n\n                Fashion \n                Designers \n                River \nPryce696 \nDicos85 \n\n❌Regular Price  : ₹   2999  /- 😱"
    }
   ]
  },
  {
   "id": 20503,
   "type": "message",
   "date": "2023-05-04",
   "date_unixtime": "1683172614",
   "from": "All1App daily",
   "from_id": "channel1858370886",
   "photo": "(File not included. Change data exporting settings to download.)",
   "pryce": "241",
   "dicos": "56",
   "catagory": "Baby",
   "text_entities": [
    {
     "type": "plain",
     "text": "🚀🚀   Mamaganics Nourishing Hair Massage Oil for Baby's Sensitive Soft Skin with 100% Virgin Cold Pressed Coconut Oil Hypoallergenic, Vegan Friendly (Hair Oil, 240ml)✅\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/426rsA0"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LxgIDF"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/42kESYQ"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/419vTZn"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/421kEU9"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/44Enh06"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3p2GJDj"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3nwgiW4"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3HBlkHC"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/421RQLp"
    },
    {
     "type": "plain",
     "text": "\n\n @241\n"
    },
    {
     "type": "link",
     "text": "https://m.media-amazon.com/images/I/61E-fzYLsdL._SX569_.jpg"
    },
    {
     "type": "plain",
     "text": " \n\n\n"
    },
    {
     "type": "hashtag",
     "text": "#Baby"
    },
    {
     "type": "plain",
     "text": "\n\n                Beauty \n                Hair Care \n                 Hair Oils \nPryce241 \nDicos56 \n\n❌Regular Price  : ₹  549   /- 😱"
    }
   ]
  },
  {
   "id": 20504,
   "type": "message",
   "date": "2023-05-04",
   "date_unixtime": "1683172678",
   "from": "All1App daily",
   "from_id": "channel1858370886",
   "photo": "(File not included. Change data exporting settings to download.)",
   "pryce": "933",
   "dicos": "73",
   "catagory": "Watches",
   "text_entities": [
    {
     "type": "plain",
     "text": "🚀🚀   Daniel Klein Analog Silver Dial Women's Watch-DK.1.12492-1✅\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3paDAkK"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3paDAkK"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/4164urg"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/42o8Ueh"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3BbScDz"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3Lxhctt"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3p21KxP"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/416ER9X"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3ATIhCo"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/415zmZc"
    },
    {
     "type": "plain",
     "text": "\n\n @933\n"
    },
    {
     "type": "link",
     "text": "https://m.media-amazon.com/images/I/71AfB9EGIqL._UY741_.jpg"
    },
    {
     "type": "plain",
     "text": " \n\n\n"
    },
    {
     "type": "hashtag",
     "text": "#Watches"
    },
    {
     "type": "plain",
     "text": "\n\n                Fashion \n                Onam 2021 \n                Watches and fashion jewellery \nPryce933 \nDicos73 \n\n❌Regular Price  : ₹ 2499   /- 😱"
    }
   ]
  },
  {
   "id": 20505,
   "type": "message",
   "date": "2023-05-04",
   "date_unixtime": "1683172740",
   "from": "All1App daily",
   "from_id": "channel1858370886",
   "photo": "(File not included. Change data exporting settings to download.)",
   "pryce": "998",
   "dicos": "54",
   "catagory": "Home",
   "text_entities": [
    {
     "type": "plain",
     "text": "🚀🚀   Aparna Apsara 1200 mm (48 inch) High Speed Ceiling Fan (Metallic Brown)✅\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LA3juN"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3HFktWC"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3NDbMQl"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3Nz91iT"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3NF7rfG"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3HCLeee"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/44Enpg6"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3HCVlj3"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3VLAJLH"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/42nfTUJ"
    },
    {
     "type": "plain",
     "text": "\n\n @998\n"
    },
    {
     "type": "link",
     "text": "https://m.media-amazon.com/images/I/41V-lzmnDBL._SX425_.jpg"
    },
    {
     "type": "plain",
     "text": " \n\n\n"
    },
    {
     "type": "hashtag",
     "text": "#Home"
    },
    {
     "type": "plain",
     "text": " & Kitchen\n\n                Home & Kitchen \n                Heating, Cooling & Air Quality \n                Fans \nPryce998 \nDicos54 \n\n❌Regular Price  : ₹ 1299   /- 😱"
    }
   ]
  },
  {
   "id": 20506,
   "type": "message",
   "date": "2023-05-04",
   "date_unixtime": "1683172802",
   "from": "All1App daily",
   "from_id": "channel1858370886",
   "photo": "(File not included. Change data exporting settings to download.)",
   "pryce": "34",
   "dicos": "32",
   "catagory": "Grocery",
   "text_entities": [
    {
     "type": "plain",
     "text": "🚀🚀   Cadbury Fuse Fit Chocolate Snack Bar with Almonds and Peanuts,40g✅\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3HDHOaW"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3HDHOaW"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3HFHmcp"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/420ThtF"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/421wqOh"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LTZYb5"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3VxSEVP"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/424zut1"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/428BGzR"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3NLa7Im"
    },
    {
     "type": "plain",
     "text": "\n\n @34\n"
    },
    {
     "type": "link",
     "text": "https://m.media-amazon.com/images/I/61rVoSLgtJL._SX569_.jpg"
    },
    {
     "type": "plain",
     "text": " \n\n\n"
    },
    {
     "type": "hashtag",
     "text": "#Grocery"
    },
    {
     "type": "plain",
     "text": " & Gourmet Foods\n\n                Grocery & Gourmet Foods \n                Snacks & Sweets \n                Snack Foods \nPryce34 \nDicos32 \n\n❌Regular Price  : ₹  50  /- 😱"
    }
   ]
  },
  {
   "id": 20507,
   "type": "message",
   "date": "2023-05-04",
   "date_unixtime": "1683172865",
   "from": "All1App daily",
   "from_id": "channel1858370886",
   "photo": "(File not included. Change data exporting settings to download.)",
   "pryce": "13999",
   "dicos": "19",
   "catagory": "Home",
   "text_entities": [
    {
     "type": "plain",
     "text": "🚀🚀   Bajaj 50 Litre Digital Oven Toaster Griller (50 litres OTG) with 6 Pre-Set Menu, Oven for Kitchen with Illuminated Chamber, Motorised Rotisserie & Convection, 2 Year Warranty Black & Silver✅\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LTf14K"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3APTKmq"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3p8Sh8b"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3ATyLiz"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3ARafP2"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3p6PVql"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/44r2EEt"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3AQL7rw"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3pcftSL"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LTflR0"
    },
    {
     "type": "plain",
     "text": "\n\n "
    },
    {
     "type": "mention",
     "text": "@13999"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://m.media-amazon.com/images/I/61KJqjWHChL._SX466_.jpg"
    },
    {
     "type": "plain",
     "text": " \n\n\n"
    },
    {
     "type": "hashtag",
     "text": "#Home"
    },
    {
     "type": "plain",
     "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Home Appliances \n                Small Kitchen Appliances \nPryce13999 \nDicos19 \n\n❌Regular Price  : ₹   16999  /- 😱"
    }
   ]
  },
  {
   "id": 20508,
   "type": "message",
   "date": "2023-05-04",
   "date_unixtime": "1683172915",
   "from": "All1App daily",
   "from_id": "channel1858370886",
   "photo": "(File not included. Change data exporting settings to download.)",
   "pryce": "898",
   "dicos": "70",
   "catagory": "Health",
   "text_entities": [
    {
     "type": "plain",
     "text": "🚀🚀   beatXP Stream Full Body Massager Machine for Pain Relief with 4 Attachments - Electric Back Massager for Pain Relief - with up to 18 Months Warranty (Metallic Silver)✅\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3Vycdxt"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/42iX71r"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3HFHHMd"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3HFHRTP"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3HDIgWG"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3p6nqJh"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3HEY1Nn"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3NDvICS"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LSXQ3j"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3ATnlv6"
    },
    {
     "type": "plain",
     "text": "\n\n @898\n"
    },
    {
     "type": "link",
     "text": "https://m.media-amazon.com/images/I/618RaLMlhkL._SX569_.jpg"
    },
    {
     "type": "plain",
     "text": " \n\n\n"
    },
    {
     "type": "hashtag",
     "text": "#Health"
    },
    {
     "type": "plain",
     "text": " & Personal Care\n\n                Health & Personal Care \n                Health Care \n                Massage & Relaxation \nPryce898 \nDicos70 \n\n❌Regular Price  : ₹  2099  /- 😱"
    }
   ]
  },
  {
   "id": 20509,
   "type": "message",
   "date": "2023-05-04",
   "date_unixtime": "1683172976",
   "from": "All1App daily",
   "from_id": "channel1858370886",
   "photo": "(File not included. Change data exporting settings to download.)",
   "pryce": "398",
   "dicos": "84",
   "catagory": "Health",
   "text_entities": [
    {
     "type": "plain",
     "text": "🚀🚀   beatXP Gravity Elite Digital Weighing Scale for Body Weight with 6 mm Thick Tempered Glass | Electronic Weight Machine with High Precision Sensors & Backlit LCD Panel | 18 Months Warranty (White)✅\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3Lvmtli"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LyXRIi"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3AQLulU"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3Lx3neL"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3VCXGk4"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/412X2NM"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3AZpqFL"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/415pvmf"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://www.amazon.in/dp/B09WQKPFD3?&amp;linkCode=sl1&amp;tag=avi1deals9-21&amp;linkId=25ee62c8ee78beed9da708960f79bed6&amp;language=en_IN&amp;ref_=as_li_ss_tl"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3BbSU3H"
    },
    {
     "type": "plain",
     "text": "\n\n @398\n"
    },
    {
     "type": "link",
     "text": "https://m.media-amazon.com/images/I/41NWhKXCxWL._SY450_.jpg"
    },
    {
     "type": "plain",
     "text": " \n\n\n"
    },
    {
     "type": "hashtag",
     "text": "#Health"
    },
    {
     "type": "plain",
     "text": " & Personal Care\n\n                Health & Personal Care \n                Home Medical Supplies & Equipment \n                Health Monitors \nPryce398 \nDicos84 \n\n❌Regular Price  : ₹ 1799   /- 😱"
    }
   ]
  },
  {
   "id": 20510,
   "type": "message",
   "date": "2023-05-04",
   "date_unixtime": "1683173040",
   "from": "All1App daily",
   "from_id": "channel1858370886",
   "photo": "(File not included. Change data exporting settings to download.)",
   "pryce": "1398",
   "dicos": "83",
   "catagory": "Computers",
   "text_entities": [
    {
     "type": "plain",
     "text": "🚀🚀   boAt Wave Leap Call with 1.83\" HD Display, Advanced Bluetooth Calling, Multiple Watch Faces, Multi-Sports Modes, IP68, HR & SpO2, Metallic Design, Weather Forecasts(Deep Blue)✅\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3nrshUT"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/420TLQv"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LvmTIo"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LTttdf"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LAXqxc"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/42kA4mj"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/42pHWDg"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3nlZph3"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3NzqpnR"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/410dwGl"
    },
    {
     "type": "plain",
     "text": "\n\n "
    },
    {
     "type": "mention",
     "text": "@1398"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://m.media-amazon.com/images/I/61cVRt5t0AL._SY450_.jpg"
    },
    {
     "type": "plain",
     "text": " \n\n\n"
    },
    {
     "type": "hashtag",
     "text": "#Computers"
    },
    {
     "type": "plain",
     "text": " & Accessories\n\n                Electronics \n                Wearable Technology \n                 Smart Watches \nPryce1398 \nDicos83 \n\n❌Regular Price  : ₹ 4499   /- 😱"
    }
   ]
  },
  {
   "id": 20511,
   "type": "message",
   "date": "2023-05-04",
   "date_unixtime": "1683173104",
   "from": "All1App daily",
   "from_id": "channel1858370886",
   "photo": "(File not included. Change data exporting settings to download.)",
   "pryce": "2999",
   "dicos": "75",
   "catagory": "Electronics",
   "text_entities": [
    {
     "type": "plain",
     "text": "🚀🚀   Philips Audio TAPN505 Bluetooth Wireless in Ear Earphones with Mic, Active Noise Cancellation, Google Assistant, Hi-Res Audio, 14 Hr Playtime, 12.2 mm Drivers, Line-in Cable (Black)✅\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3NwYswW"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3NDw1h0"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LAXwF4"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3Hz8MAQ"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LoOsmw"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3Lt2BiR"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3HCQi2f"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LoOqeo"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/44q59qz"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3nvby38"
    },
    {
     "type": "plain",
     "text": "\n\n "
    },
    {
     "type": "mention",
     "text": "@2999"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://m.media-amazon.com/images/I/71eGmXuaGgL._SX679_.jpg"
    },
    {
     "type": "plain",
     "text": " \n\n\n"
    },
    {
     "type": "hashtag",
     "text": "#Electronics"
    },
    {
     "type": "plain",
     "text": "\n\n                Electronics \n                Headphones, Earbuds & Accessories \n                Headphones \nPryce2999 \nDicos75 \n\n❌Regular Price  : ₹ 8599   /- 😱"
    }
   ]
  },
  {
   "id": 20512,
   "type": "message",
   "date": "2023-05-04",
   "date_unixtime": "1683173168",
   "from": "All1App daily",
   "from_id": "channel1858370886",
   "photo": "(File not included. Change data exporting settings to download.)",
   "pryce": "920",
   "dicos": "51",
   "catagory": "Home",
   "text_entities": [
    {
     "type": "plain",
     "text": "🚀🚀   Pigeon By Stovekraft Favourite Aluminium Pressure Cooker with Outer Lid Induction and Gas Stove Compatible 5 Litre Capacity for Healthy Cooking (Silver)✅\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3ATyFHz"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3nmtEEx"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3nmtEEx"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3HCMGNR"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3NG0B9x"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3NxTQGR"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3ND5FeN"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3HCMIVZ"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3NE5O1M"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3HDr7g4"
    },
    {
     "type": "plain",
     "text": "\n\n @920\n"
    },
    {
     "type": "link",
     "text": "https://m.media-amazon.com/images/I/51qxl7UZFhL._SX522_.jpg"
    },
    {
     "type": "plain",
     "text": " \n\n\n"
    },
    {
     "type": "hashtag",
     "text": "#Home"
    },
    {
     "type": "plain",
     "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Dining \n                Cookware \nPryce920 \nDicos51 \n\n❌Regular Price  : ₹ 1659   /- 😱"
    }
   ]
  },
  {
   "id": 20513,
   "type": "message",
   "date": "2023-05-04",
   "date_unixtime": "1683173232",
   "from": "All1App daily",
   "from_id": "channel1858370886",
   "photo": "(File not included. Change data exporting settings to download.)",
   "pryce": "344",
   "dicos": "69",
   "catagory": "Toys",
   "text_entities": [
    {
     "type": "plain",
     "text": "🚀🚀   eErlik Plastic Battery Operated Converting Car to Robot, Robot to Car Automatically,Robot Toy, with Light and Sound for Kids Indoor and Outdoor 3 Year, Pack of 1, Multicolor✅\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/44pWi8n"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3oX8n4v"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/44vekWE"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/44FD4Mf"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/42kAsBh"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3Hz99ve"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3HCQZsn"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LRG1BI"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3I0KuzZ"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/421x3r7"
    },
    {
     "type": "plain",
     "text": "\n\n @344\n"
    },
    {
     "type": "link",
     "text": "https://m.media-amazon.com/images/I/51aJbzBc5BL._SY450_.jpg"
    },
    {
     "type": "plain",
     "text": " \n\n\n"
    },
    {
     "type": "hashtag",
     "text": "#Toys"
    },
    {
     "type": "plain",
     "text": " & Games\n\n                Toys & Games \n                Remote & App-Controlled Toys \n                Remote & App Controlled Vehicles \nPryce344 \nDicos69 \n\n❌Regular Price  : ₹ 999   /- 😱"
    }
   ]
  },
  {
   "id": 20514,
   "type": "message",
   "date": "2023-05-04",
   "date_unixtime": "1683173294",
   "from": "All1App daily",
   "from_id": "channel1858370886",
   "photo": "(File not included. Change data exporting settings to download.)",
   "pryce": "351",
   "dicos": "59",
   "catagory": "Sports",
   "text_entities": [
    {
     "type": "plain",
     "text": "🚀🚀   Fox Nano-Speed Fox-50 Badminton Racket Combo Pack with 2 Pieces Nylon Shuttlecock✅\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LREn3c"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/44q5BoL"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3p6o1dZ"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/44unfYE"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/423YnFs"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/44uwojS"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/44ta7mf"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LU5ASM"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3ATnWgk"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3AW84tD"
    },
    {
     "type": "plain",
     "text": "\n\n @351\n"
    },
    {
     "type": "link",
     "text": "https://m.media-amazon.com/images/I/81aj1JrTJnL._SX569_.jpg"
    },
    {
     "type": "plain",
     "text": " \n\n\n"
    },
    {
     "type": "hashtag",
     "text": "#Sports"
    },
    {
     "type": "plain",
     "text": ", Fitness & Outdoors\n\n                Sports, Fitness & Outdoors \n                Badminton \n                 Complete Sets \nPryce351 \nDicos59 \n\n❌Regular Price  : ₹  799   /- 😱"
    }
   ]
  },
  {
   "id": 20515,
   "type": "message",
   "date": "2023-05-04",
   "date_unixtime": "1683173357",
   "from": "All1App daily",
   "from_id": "channel1858370886",
   "photo": "(File not included. Change data exporting settings to download.)",
   "pryce": "854",
   "dicos": "68",
   "catagory": "Health",
   "text_entities": [
    {
     "type": "plain",
     "text": "🚀🚀   Beurer Professional Foldable 1600 Watts Hair Dryer With 2 Ultra Heat & Speed Settings, Pink Limited Edition (3 Years Warranty)✅\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/422Ye57"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3VtpiIj"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3VtpiIj"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3NBV040"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/44taihp"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3NDOuKb"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3VDE8Mj"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3Lv14c8"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3VyqIkw"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/4253ghk"
    },
    {
     "type": "plain",
     "text": "\n\n @854\n"
    },
    {
     "type": "link",
     "text": "https://m.media-amazon.com/images/I/41yw6IvR1IL._SX569_.jpg"
    },
    {
     "type": "plain",
     "text": " \n\n\n"
    },
    {
     "type": "hashtag",
     "text": "#Health"
    },
    {
     "type": "plain",
     "text": " & Personal Care\n\n                Beauty \n                Hair Care \n                Hair Styling Tools \nPryce854 \nDicos68 \n\n❌Regular Price  : ₹  1999   /- 😱"
    }
   ]
  },
  {
   "id": 20516,
   "type": "message",
   "date": "2023-05-04",
   "date_unixtime": "1683173419",
   "from": "All1App daily",
   "from_id": "channel1858370886",
   "photo": "(File not included. Change data exporting settings to download.)",
   "pryce": "3998",
   "dicos": "75",
   "catagory": "Electronics",
   "text_entities": [
    {
     "type": "plain",
     "text": "🚀🚀   Samsung Galaxy Buds Live Bluetooth Truly Wireless in Ear Earbuds with Mic, Upto 21 Hours Playtime, Mystic Black✅\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3VtpqHN"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3Lz8gUj"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LAGLtw"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3ARaZUk"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3ATUiaU"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3nB8eU1"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/40YjW92"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/413iwKk"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/424InTF"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3paYBvG"
    },
    {
     "type": "plain",
     "text": "\n\n "
    },
    {
     "type": "mention",
     "text": "@3998"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://m.media-amazon.com/images/I/61G5JoU9tTL._SX679_.jpg"
    },
    {
     "type": "plain",
     "text": " \n\n\n"
    },
    {
     "type": "hashtag",
     "text": "#Electronics"
    },
    {
     "type": "plain",
     "text": "\n\n                Electronics \n                Headphones, Earbuds & Accessories \n                Headphones \nPryce3998 \nDicos75 \n\n❌Regular Price  : ₹   9999  /- 😱"
    }
   ]
  },
  {
   "id": 20517,
   "type": "message",
   "date": "2023-05-04",
   "date_unixtime": "1683173482",
   "from": "All1App daily",
   "from_id": "channel1858370886",
   "photo": "(File not included. Change data exporting settings to download.)",
   "pryce": "299",
   "dicos": "46",
   "catagory": "Electronics",
   "text_entities": [
    {
     "type": "plain",
     "text": "🚀🚀   Heropantee Pouch for bAot Airdopes 131/138 with LED Light Indicator Protective Soft Silicone Case Cover with Hook (Cover Only) (This Cover is Not Sold Or Created by Boat) (Transparent)✅\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3Lx49bF"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/421ROTS"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3pdrCXy"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/42nhvxL"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/42s1I0R"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3p6gk7N"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3VsAJQx"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/42rVCh3"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3oZtIdA"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/42oOQIU"
    },
    {
     "type": "plain",
     "text": "\n\n @299\n"
    },
    {
     "type": "link",
     "text": "https://m.media-amazon.com/images/I/71iBpRm4FhL._SX569_.jpg"
    },
    {
     "type": "plain",
     "text": " \n\n\n"
    },
    {
     "type": "hashtag",
     "text": "#Electronics"
    },
    {
     "type": "plain",
     "text": "\n\n                Electronics \n                Headphones, Earbuds & Accessories \n                 Cases \nPryce299 \nDicos46 \n\n❌Regular Price  : ₹  549  /- 😱"
    }
   ]
  },
  {
   "id": 20518,
   "type": "message",
   "date": "2023-05-04",
   "date_unixtime": "1683173546",
   "from": "All1App daily",
   "from_id": "channel1858370886",
   "photo": "(File not included. Change data exporting settings to download.)",
   "pryce": "726",
   "dicos": "82",
   "catagory": "Home",
   "text_entities": [
    {
     "type": "plain",
     "text": "🚀🚀   999STORE Printed Wall Decor Mirror for Living Room Mirror for washbasin White Marvel washroom Bathroom Mirror (MDF_14X20 Inches) MirrorSMP100✅\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/42kqnUW"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LRBnTl"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3NG0WJl"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/42r0rqT"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3p2I64V"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/42r0t1Z"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LAY9yq"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LAY9yq"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3nugIMM"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/420UwJl"
    },
    {
     "type": "plain",
     "text": "\n\n @726\n"
    },
    {
     "type": "link",
     "text": "https://m.media-amazon.com/images/I/31+vuyMaIyL._SY300_SX300_.jpg"
    },
    {
     "type": "plain",
     "text": " \n\n\n"
    },
    {
     "type": "hashtag",
     "text": "#Home"
    },
    {
     "type": "plain",
     "text": " & Kitchen\n\n                Home & Kitchen \n                Home & Décor \n                Mirrors \nPryce726 \nDicos82 \n\n❌Regular Price  : ₹ 2999   /- 😱"
    }
   ]
  },
  {
   "id": 20519,
   "type": "message",
   "date": "2023-05-04",
   "date_unixtime": "1683173588",
   "from": "All1App daily",
   "from_id": "channel1858370886",
   "photo": "(File not included. Change data exporting settings to download.)",
   "pryce": "76689",
   "dicos": "15",
   "catagory": "All",
   "text_entities": [
    {
     "type": "plain",
     "text": "🚀🚀   Apple Watch Ultra [GPS + Cellular 49 mm] Smart Watch w/Rugged Titanium Case & Green Alpine Loop - Small. Fitness Tracker, Precision GPS, Action Button, Extra-Long BatteryLife, Brighter Retina Display✅\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3nrtgEz"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3HCWDup"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3AQMiqW"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/44uwPe0"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3HDrE1y"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3AR1SDh"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/44nen77"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3NCVXsL"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3APeLh7"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3NIsDkZ"
    },
    {
     "type": "plain",
     "text": "\n\n "
    },
    {
     "type": "mention",
     "text": "@76689"
    },
    {
     "type": "plain",
     "text": "\n \n "
    },
    {
     "type": "link",
     "text": "https://m.media-amazon.com/images/I/916acEhBkcL._SX679_.jpg"
    },
    {
     "type": "plain",
     "text": " \n\n\n"
    },
    {
     "type": "hashtag",
     "text": "#All"
    },
    {
     "type": "plain",
     "text": "\n\nPryce76689 \nDicos15 \n\n❌Regular Price  : ₹  84999   /- 😱"
    }
   ]
  },
  {
   "id": 20520,
   "type": "message",
   "date": "2023-05-04",
   "date_unixtime": "1683173651",
   "from": "All1App daily",
   "from_id": "channel1858370886",
   "photo": "(File not included. Change data exporting settings to download.)",
   "pryce": "2889",
   "dicos": "33",
   "catagory": "Electronics",
   "text_entities": [
    {
     "type": "plain",
     "text": "🚀🚀   Qubo Outdoor Security Camera (Black) from Hero Group | Made in India | IP65 All-Weather | 2MP 1080p Full HD | CCTV Wi-Fi Camera | Night Vision | Mobile App Connectivity | Cloud & SD Card Recording✅\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3pcgHxl"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3oYldQ0"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/42rW8eZ"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/42rW8eZ"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/44pYaho"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/42kAWY7"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/423ITkM"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LTgmZk"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/42jUsE8"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3nqBfSt"
    },
    {
     "type": "plain",
     "text": "\n\n "
    },
    {
     "type": "mention",
     "text": "@2889"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://m.media-amazon.com/images/I/31RK2ukll1L._SY450_.jpg"
    },
    {
     "type": "plain",
     "text": " \n\n\n"
    },
    {
     "type": "hashtag",
     "text": "#Electronics"
    },
    {
     "type": "plain",
     "text": "\n\n                Electronics \n                Cameras & Photography \n                Security Cameras \nPryce2889 \nDicos33 \n\n❌Regular Price  : ₹  3999   /- 😱"
    }
   ]
  },
  {
   "id": 20521,
   "type": "message",
   "date": "2023-05-04",
   "date_unixtime": "1683173698",
   "from": "All1App daily",
   "from_id": "channel1858370886",
   "photo": "(File not included. Change data exporting settings to download.)",
   "pryce": "137",
   "dicos": "45",
   "catagory": "Grocery",
   "text_entities": [
    {
     "type": "plain",
     "text": "🚀🚀   Pillsbury Cooker Cake Mix, Choco, Eggless, 159 gm x Pack of 2, 318 gm✅\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/44miu3f"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3VsB0Tz"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/426wGfg"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LVHrvg"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LRGXpI"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/420iWCw"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3VCYW6M"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3VsB5Xn"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/44v093O"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LUMhJ0"
    },
    {
     "type": "plain",
     "text": "\n\n @137\n"
    },
    {
     "type": "link",
     "text": "https://m.media-amazon.com/images/I/71mDbIztu+L._SX569_.jpg"
    },
    {
     "type": "plain",
     "text": " \n\n\n"
    },
    {
     "type": "hashtag",
     "text": "#Grocery"
    },
    {
     "type": "plain",
     "text": " & Gourmet Foods\n\n                Grocery & Gourmet Foods \n                Cooking & Baking Supplies \n                Baking Supplies \nPryce137 \nDicos45 \n\n❌Regular Price  : ₹   350  /- 😱"
    }
   ]
  },
  {
   "id": 20522,
   "type": "message",
   "date": "2023-05-04",
   "date_unixtime": "1683173760",
   "from": "All1App daily",
   "from_id": "channel1858370886",
   "photo": "(File not included. Change data exporting settings to download.)",
   "pryce": "229",
   "dicos": "61",
   "catagory": "Health",
   "text_entities": [
    {
     "type": "plain",
     "text": "🚀🚀   VITRO Turmeric Juice | Kacchi Haldi juice | Anti-inflammatory | No added sugar | Helps in Cholesterol Control & Boosts Immunity | Flushes out toxins | Aids in Healthy Digestion | I AM GOLD (500 ML)✅\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3AW8Kzb"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3AU9ZPk"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3nwqC0w"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/41471lR"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3pa6hi0"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3VydG6X"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3VvztMk"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3HDbEfO"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3VvzuQo"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3NxULXP"
    },
    {
     "type": "plain",
     "text": "\n\n @229\n"
    },
    {
     "type": "link",
     "text": "https://m.media-amazon.com/images/I/61ABx0Y42HL._SY450_.jpg"
    },
    {
     "type": "plain",
     "text": " \n\n\n"
    },
    {
     "type": "hashtag",
     "text": "#Health"
    },
    {
     "type": "plain",
     "text": " & Personal Care\n\n                Health & Personal Care \n                Diet & Nutrition \n                Vitamins, Minerals & Supplements \nPryce229 \nDicos61 \n\n❌Regular Price  : ₹   590 /- 😱"
    }
   ]
  },
  {
   "id": 20523,
   "type": "message",
   "date": "2023-05-04",
   "date_unixtime": "1683173825",
   "from": "All1App daily",
   "from_id": "channel1858370886",
   "photo": "(File not included. Change data exporting settings to download.)",
   "pryce": "1251",
   "dicos": "87",
   "catagory": "Electronics",
   "text_entities": [
    {
     "type": "plain",
     "text": "🚀🚀   Amazon Brand - Solimo Denison Engineered Wood Bookcase/Display Stand (Walnut , Set of 5 Shelves)✅\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3nB92s1"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3nwiD3i"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3nwiXPy"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3nBs3KS"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/414w5sQ"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3AUa3i2"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3nrMmdI"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3nwkMvS"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/414794P"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3nwlitO"
    },
    {
     "type": "plain",
     "text": "\n\n "
    },
    {
     "type": "mention",
     "text": "@1251"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://m.media-amazon.com/images/I/71I5qjqmUJL._SX466_.jpg"
    },
    {
     "type": "plain",
     "text": " \n\n\n"
    },
    {
     "type": "hashtag",
     "text": "#Electronics"
    },
    {
     "type": "plain",
     "text": "\n\n                Home & Kitchen \n                Furniture \n                Living Room Furniture \nPryce1251 \nDicos87 \n\n❌Regular Price  : ₹  8999  /- 😱"
    }
   ]
  },
  {
   "id": 20524,
   "type": "message",
   "date": "2023-05-04",
   "date_unixtime": "1683173888",
   "from": "All1App daily",
   "from_id": "channel1858370886",
   "photo": "(File not included. Change data exporting settings to download.)",
   "pryce": "1899",
   "dicos": "81",
   "catagory": "Luggage",
   "text_entities": [
    {
     "type": "plain",
     "text": "🚀🚀   AmazonBasics Polycarbonate Hard 53 cms suitcase(T1916-4_blue)✅\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3AUaclA"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3ntHFAg"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/419xPRD"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/42s2eMl"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/42pKQrm"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LVCcf5"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3p6h6ld"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/40XUr7K"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/41bYNZe"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3oYlPVO"
    },
    {
     "type": "plain",
     "text": "\n\n "
    },
    {
     "type": "mention",
     "text": "@1899"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://m.media-amazon.com/images/I/71wBy6yhT9L._SY879_.jpg"
    },
    {
     "type": "plain",
     "text": " \n\n\n"
    },
    {
     "type": "hashtag",
     "text": "#Luggage"
    },
    {
     "type": "plain",
     "text": " & Bags\n\n                Bags, Wallets and Luggage \n                Luggage \n                 Suitcases & Trolley Bags \nPryce1899 \nDicos81 \n\n❌Regular Price  : ₹  8999   /- 😱"
    }
   ]
  },
  {
   "id": 20525,
   "type": "message",
   "date": "2023-05-04",
   "date_unixtime": "1683173951",
   "from": "All1App daily",
   "from_id": "channel1858370886",
   "photo": "(File not included. Change data exporting settings to download.)",
   "pryce": "899",
   "dicos": "80",
   "catagory": "Clothing",
   "text_entities": [
    {
     "type": "plain",
     "text": "🚀🚀   Lavie Sport Chairman Laptop Bag | Premium Leatherette Business Backpack for Men & Women | Durable Office Bag for Notebook/MacBook✅\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3NCiHJq"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3NwZGbw"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3VvzIHe"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3VvzIHe"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/42oPRkc"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3NDdHEx"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3NLck6C"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3p9JRxr"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3p9JRxr"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3Nzb4DB"
    },
    {
     "type": "plain",
     "text": "\n\n @899\n"
    },
    {
     "type": "link",
     "text": "https://m.media-amazon.com/images/I/81R8e7K8GlL._UX679_.jpg"
    },
    {
     "type": "plain",
     "text": " \n\n\n"
    },
    {
     "type": "hashtag",
     "text": "#Clothing"
    },
    {
     "type": "plain",
     "text": " & Accessories\n\n                Computers & Accessories \n                Accessories & Peripherals \n                Laptop Accessories \nPryce899 \nDicos80 \n\n❌Regular Price  : ₹  2999   /- 😱"
    }
   ]
  },
  {
   "id": 20526,
   "type": "message",
   "date": "2023-05-04",
   "date_unixtime": "1683173991",
   "from": "All1App daily",
   "from_id": "channel1858370886",
   "photo": "(File not included. Change data exporting settings to download.)",
   "pryce": "660",
   "dicos": "70",
   "catagory": "Shoes",
   "text_entities": [
    {
     "type": "plain",
     "text": "🚀🚀   United Colors of Benetton Women's Clutch Bag (Olive)✅\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/42pMtVU"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3p9K97v"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LzYv8D"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3Lz9fnt"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3HFJlNT"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LxiTaj"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3AY35Za"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3HCO8Qj"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3nB9Gpr"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3AR2R6r"
    },
    {
     "type": "plain",
     "text": "\n\n @660\n"
    },
    {
     "type": "link",
     "text": "https://m.media-amazon.com/images/I/61WfPZy-TgS._UY625_.jpg"
    },
    {
     "type": "plain",
     "text": " \n\n\n"
    },
    {
     "type": "hashtag",
     "text": "#Shoes"
    },
    {
     "type": "plain",
     "text": " & Handbags\n\n                Fashion \n                Pongal || Fashion accessories \n                Handbags \nPryce660 \nDicos70 \n\n❌Regular Price  : ₹  1499   /- 😱"
    }
   ]
  },
  {
   "id": 20527,
   "type": "message",
   "date": "2023-05-04",
   "date_unixtime": "1683174054",
   "from": "All1App daily",
   "from_id": "channel1858370886",
   "photo": "(File not included. Change data exporting settings to download.)",
   "pryce": "898",
   "dicos": "70",
   "catagory": "Health",
   "text_entities": [
    {
     "type": "plain",
     "text": "🚀🚀   beatXP Stream Full Body Massager Machine for Pain Relief with 4 Attachments - Electric Back Massager for Pain Relief - with up to 18 Months Warranty (Metallic Silver)✅\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3HFeq4a"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3AV1Biq"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/423kb3Y"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/415ruHd"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3p63nuw"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/4243iGf"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3pb8s4B"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/4166Mqm"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/44uxD2w"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3pdstre"
    },
    {
     "type": "plain",
     "text": "\n\n @898\n"
    },
    {
     "type": "link",
     "text": "https://m.media-amazon.com/images/I/618RaLMlhkL._SX569_.jpg"
    },
    {
     "type": "plain",
     "text": " \n\n\n"
    },
    {
     "type": "hashtag",
     "text": "#Health"
    },
    {
     "type": "plain",
     "text": " & Personal Care\n\n                Health & Personal Care \n                Health Care \n                Massage & Relaxation \nPryce898 \nDicos70 \n\n❌Regular Price  : ₹  1999   /- 😱"
    }
   ]
  },
  {
   "id": 20528,
   "type": "message",
   "date": "2023-05-04",
   "date_unixtime": "1683174119",
   "from": "All1App daily",
   "from_id": "channel1858370886",
   "photo": "(File not included. Change data exporting settings to download.)",
   "pryce": "1598",
   "dicos": "24",
   "catagory": "Home",
   "text_entities": [
    {
     "type": "plain",
     "text": "🚀🚀   AGARO Regal 800 Watts Handheld Vacuum Cleaner, Lightweight & Durable Body, Small/Mini Size ( Black)✅\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/44EpQ2e"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3nty9gt"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/42pJAV5"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/42i5ABe"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/44qcgzg"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/42rWXEB"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3HCNh1U"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3oX9zov"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/42lsjwy"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3Vtm5Ze"
    },
    {
     "type": "plain",
     "text": "\n\n "
    },
    {
     "type": "mention",
     "text": "@1598"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://m.media-amazon.com/images/I/710CHKJ0puL._SX522_.jpg"
    },
    {
     "type": "plain",
     "text": " \n\n\n"
    },
    {
     "type": "hashtag",
     "text": "#Home"
    },
    {
     "type": "plain",
     "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Home Appliances \n                Vacuum, Cleaning & Ironing \nPryce1598 \nDicos24 \n\n❌Regular Price  : ₹  1999   /- 😱"
    }
   ]
  },
  {
   "id": 20529,
   "type": "message",
   "date": "2023-05-04",
   "date_unixtime": "1683174181",
   "from": "All1App daily",
   "from_id": "channel1858370886",
   "photo": "(File not included. Change data exporting settings to download.)",
   "pryce": "1598",
   "dicos": "54",
   "catagory": "Home",
   "text_entities": [
    {
     "type": "plain",
     "text": "🚀🚀   Butterfly Blue Line Stainless Steel Outer Lid Pressure Cooker, 3 Litre✅\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/426xgJY"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3p9oSe5"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LTv82t"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3ATArZf"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/44pd3Ap"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LT6RJF"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LVtNIc"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/424JCSP"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/421yw0B"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LU26zB"
    },
    {
     "type": "plain",
     "text": "\n\n "
    },
    {
     "type": "mention",
     "text": "@1598"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://m.media-amazon.com/images/I/81OVjMSHe8L._SX522_.jpg"
    },
    {
     "type": "plain",
     "text": " \n\n\n"
    },
    {
     "type": "hashtag",
     "text": "#Home"
    },
    {
     "type": "plain",
     "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Dining \n                Cookware \nPryce1598 \nDicos54 \n\n❌Regular Price  : ₹ 2999    /- 😱"
    }
   ]
  },
  {
   "id": 20530,
   "type": "message",
   "date": "2023-05-04",
   "date_unixtime": "1683174243",
   "from": "All1App daily",
   "from_id": "channel1858370886",
   "photo": "(File not included. Change data exporting settings to download.)",
   "pryce": "256",
   "dicos": "14",
   "catagory": "Health",
   "text_entities": [
    {
     "type": "plain",
     "text": "🚀🚀   Spark Clean Fast Action Formula Dishwash Liquid - 500ml Lemon Pack of 2✅\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/44w3ccm"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LVtU6A"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3AR36OT"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/44tDCV3"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3ntyj7z"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3NE72tU"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/44qw8Cl"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LTvupP"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LRjSDI"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3HFmToa"
    },
    {
     "type": "plain",
     "text": "\n\n @256\n"
    },
    {
     "type": "link",
     "text": "https://m.media-amazon.com/images/I/61IyAaUhw3L._SX569_.jpg"
    },
    {
     "type": "plain",
     "text": " \n\n\n"
    },
    {
     "type": "hashtag",
     "text": "#Health"
    },
    {
     "type": "plain",
     "text": " & Personal Care\n\n                Health & Personal Care \n                Household Supplies \n                Dishwashing Supplies \nPryce256 \nDicos14 \n\n❌Regular Price  : ₹  300   /- 😱"
    }
   ]
  },
  {
   "id": 20531,
   "type": "message",
   "date": "2023-05-04",
   "date_unixtime": "1683174305",
   "from": "All1App daily",
   "from_id": "channel1858370886",
   "photo": "(File not included. Change data exporting settings to download.)",
   "pryce": "1959",
   "dicos": "54",
   "catagory": "Health",
   "text_entities": [
    {
     "type": "plain",
     "text": "🚀🚀   Beurer FB 21 foot spa for invigorated, well cared-for feet with 3 functions: vibration massage, bubble massage, water tempering, 3 Years Warranty, Purple, 1 Count (Pack of 1) (FB21)✅\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3NF9MqY"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3NF9MqY"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3p6hOin"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/423ZNzM"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/42mG51N"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3VvBHLE"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3VyeFUH"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/42pJwVI"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3oX9KAb"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3oZuYxk"
    },
    {
     "type": "plain",
     "text": "\n\n "
    },
    {
     "type": "mention",
     "text": "@1959"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://m.media-amazon.com/images/I/71vsD77LZpL._SX569_.jpg"
    },
    {
     "type": "plain",
     "text": " \n\n\n"
    },
    {
     "type": "hashtag",
     "text": "#Health"
    },
    {
     "type": "plain",
     "text": " & Personal Care\n\n                Health & Personal Care \n                Health Care \n                Massage & Relaxation \nPryce1959 \nDicos54 \n\n❌Regular Price  : ₹  2999   /- 😱"
    }
   ]
  },
  {
   "id": 20532,
   "type": "message",
   "date": "2023-05-04",
   "date_unixtime": "1683174368",
   "from": "All1App daily",
   "from_id": "channel1858370886",
   "photo": "(File not included. Change data exporting settings to download.)",
   "pryce": "1798",
   "dicos": "90",
   "catagory": "Computers",
   "text_entities": [
    {
     "type": "plain",
     "text": "🚀🚀   Fire-Boltt Rage Calling Bluetooth Calling Smartwatch, AI Voice Assistant with 1.32” Display 320 * 320 Pixel High Res & 100 Sports Modes with IP67 Rating, Sp02 Tracking✅\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/42preDI"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/412BGjp"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3AUaM2K"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/412wXyu"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3AR3aOD"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/4184LKm"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3nqUA5S"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/414qsLv"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/423PWd0"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3p63XZe"
    },
    {
     "type": "plain",
     "text": "\n\n "
    },
    {
     "type": "mention",
     "text": "@1798"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://m.media-amazon.com/images/I/71RdRgSTVdL._SX569_.jpg"
    },
    {
     "type": "plain",
     "text": " \n\n\n"
    },
    {
     "type": "hashtag",
     "text": "#Computers"
    },
    {
     "type": "plain",
     "text": " & Accessories\n\n                Electronics \n                Wearable Technology \n                 Smart Watches \nPryce1798 \nDicos90 \n\n❌Regular Price  : ₹  9999    /- 😱"
    }
   ]
  },
  {
   "id": 20533,
   "type": "message",
   "date": "2023-05-04",
   "date_unixtime": "1683174431",
   "from": "All1App daily",
   "from_id": "channel1858370886",
   "photo": "(File not included. Change data exporting settings to download.)",
   "pryce": "949",
   "dicos": "46",
   "catagory": "Health",
   "text_entities": [
    {
     "type": "plain",
     "text": "🚀🚀   Wahl Beard Rechargeable Trimmer (Black)✅\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/42fXVDS"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/415s7Az"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3ASzvo1"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3AUaSHE"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3APVVX8"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3oXa2XN"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3APfRcJ"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3AViCZO"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/419yMJH"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3VDGcE3"
    },
    {
     "type": "plain",
     "text": "\n\n @949\n"
    },
    {
     "type": "link",
     "text": "https://m.media-amazon.com/images/I/41kRjsmAUtS._SX679_.jpg"
    },
    {
     "type": "plain",
     "text": " \n\n\n"
    },
    {
     "type": "hashtag",
     "text": "#Health"
    },
    {
     "type": "plain",
     "text": " & Personal Care\n\n                Health & Personal Care \n                Personal Care \n                Shaving, Waxing & Beard Care \nPryce949 \nDicos46 \n\n❌Regular Price  : ₹   1499  /- 😱"
    }
   ]
  },
  {
   "id": 20534,
   "type": "message",
   "date": "2023-05-04",
   "date_unixtime": "1683174494",
   "from": "All1App daily",
   "from_id": "channel1858370886",
   "photo": "(File not included. Change data exporting settings to download.)",
   "pryce": "998",
   "dicos": "50",
   "catagory": "Computers",
   "text_entities": [
    {
     "type": "plain",
     "text": "🚀🚀   Redgear MT02 Keyboard with LED Modes, Windows Key Lock, Floating & Double Injected Keycaps, Control Knob and Ergonomic Design(Black)✅\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3NBWlry"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3Hz8bPx"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3HDFEs4"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3nmvEwx"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3nuigGA"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3p9psZj"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/42oQXMQ"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/42ml7jx"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3nmvLrX"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3nmvLrX"
    },
    {
     "type": "plain",
     "text": "\n\n @998\n"
    },
    {
     "type": "link",
     "text": "https://m.media-amazon.com/images/I/71y-CU36kSL._SY450_.jpg"
    },
    {
     "type": "plain",
     "text": " \n\n\n"
    },
    {
     "type": "hashtag",
     "text": "#Computers"
    },
    {
     "type": "plain",
     "text": " & Accessories\n\n                Computers & Accessories \n                Accessories & Peripherals \n                PC Gaming Peripherals \nPryce998 \nDicos50 \n\n❌Regular Price  : ₹   1699   /- 😱"
    }
   ]
  },
  {
   "id": 20535,
   "type": "message",
   "date": "2023-05-04",
   "date_unixtime": "1683174557",
   "from": "All1App daily",
   "from_id": "channel1858370886",
   "photo": "(File not included. Change data exporting settings to download.)",
   "pryce": "139",
   "dicos": "80",
   "catagory": "Electronics",
   "text_entities": [
    {
     "type": "plain",
     "text": "🚀🚀   Portronics Konnect HD 8 Pin Cable with 3A Fast Charging, Nylon Braided, Fast Data Sync, Tangle Resistance, 1.2 Meter Compatible with iOS Devices(Black)✅\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3AZs3aB"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3nBsXae"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3nrGdye"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3p2Z5UF"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/40Yu3uB"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/414BtME"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3HDcycc"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3Lvnh9J"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3NDeCVv"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3nwnjGo"
    },
    {
     "type": "plain",
     "text": "\n\n @139\n"
    },
    {
     "type": "link",
     "text": "https://m.media-amazon.com/images/I/41Lj-3xMqKL._SX300_SY300_QL70_FMwebp_.jpg"
    },
    {
     "type": "plain",
     "text": " \n\n\n"
    },
    {
     "type": "hashtag",
     "text": "#Electronics"
    },
    {
     "type": "plain",
     "text": "\n\n                Computers & Accessories \n                Accessories & Peripherals \n                Cables & Accessories \nPryce139 \nDicos80 \n\n❌Regular Price  : ₹  699    /- 😱"
    }
   ]
  },
  {
   "id": 20536,
   "type": "message",
   "date": "2023-05-04",
   "date_unixtime": "1683174619",
   "from": "All1App daily",
   "from_id": "channel1858370886",
   "photo": "(File not included. Change data exporting settings to download.)",
   "pryce": "449",
   "dicos": "55",
   "catagory": "Electronics",
   "text_entities": [
    {
     "type": "plain",
     "text": "🚀🚀   boAt Deuce Usb 500 Stress Resistant Tangle-Free Sturdy 2-In-1 Micro Usb + Type-C 6.5A Fast Charging Cable With 480Mbps Data Transmission, 1.5M Long For Personal Computer (Radiant Red)✅\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/42pLUeQ"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3ATW23W"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3NCXyif"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3NBB5lO"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3NBB5lO"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/44wohTJ"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/41Z1hLw"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3nmw3Pz"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3pdthwg"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3HCYC1P"
    },
    {
     "type": "plain",
     "text": "\n\n @449\n"
    },
    {
     "type": "link",
     "text": "https://m.media-amazon.com/images/I/51TyCmqG7ML._SX466_.jpg"
    },
    {
     "type": "plain",
     "text": " \n\n\n"
    },
    {
     "type": "hashtag",
     "text": "#Electronics"
    },
    {
     "type": "plain",
     "text": "\n\n                Computers & Accessories \n                Accessories & Peripherals \n                Cables & Accessories \nPryce449 \nDicos55 \n\n❌Regular Price  : ₹  899   /- 😱"
    }
   ]
  },
  {
   "id": 20537,
   "type": "message",
   "date": "2023-05-04",
   "date_unixtime": "1683174684",
   "from": "All1App daily",
   "from_id": "channel1858370886",
   "photo": "(File not included. Change data exporting settings to download.)",
   "pryce": "2080",
   "dicos": "60",
   "catagory": "Clothing",
   "text_entities": [
    {
     "type": "plain",
     "text": "🚀🚀   Red Chief Lt. Olive Full Sleeve Solid Regular Fit Cotton Poly Casual Sweat Shirt for Men (O8310022 G0044)✅\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/44tDOUh"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3oYnniA"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LVIOdo"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LUNAaS"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3Vulkiu"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://www.amazon.in/dp/B0BMXGW2P1?smid=A24C076CQPFHLF&amp;th=1&amp;psc=1&amp;linkCode=sl1&amp;tag=avi1deals6-21&amp;linkId=391384119777a2885d44c8306b635993&amp;language=en_IN&amp;ref_=as_li_ss_tl"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3HF0Uhb"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LTTrgD"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/426yCEy"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/421TTza"
    },
    {
     "type": "plain",
     "text": "\n\n "
    },
    {
     "type": "mention",
     "text": "@2080"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://m.media-amazon.com/images/I/71cPXiT4tpL._UX679_.jpg"
    },
    {
     "type": "plain",
     "text": " \n\n\n"
    },
    {
     "type": "hashtag",
     "text": "#Clothing"
    },
    {
     "type": "plain",
     "text": " & Accessories\n\n                Clothing & Accessories \n                Men \n                Winter Wear \nPryce2080 \nDicos60 \n\n❌Regular Price  : ₹   2999  /- 😱"
    }
   ]
  },
  {
   "id": 20538,
   "type": "message",
   "date": "2023-05-04",
   "date_unixtime": "1683174749",
   "from": "All1App daily",
   "from_id": "channel1858370886",
   "photo": "(File not included. Change data exporting settings to download.)",
   "pryce": "1728",
   "dicos": "44",
   "catagory": "Home",
   "text_entities": [
    {
     "type": "plain",
     "text": "🚀🚀   Pigeon Ruby 1.8 Litre Single pot (Aluminium) Electric rice cooker✅\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/44ru7FU"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/44uMARU"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/44vgGos"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3ntUbj7"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3NLdgrE"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3nogpmO"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LU2Wwf"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LWueSY"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LTvd6d"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LWjPGR"
    },
    {
     "type": "plain",
     "text": "\n\n "
    },
    {
     "type": "mention",
     "text": "@1728"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://m.media-amazon.com/images/I/31QVVD9P3bL._SX466_.jpg"
    },
    {
     "type": "plain",
     "text": " \n\n\n"
    },
    {
     "type": "hashtag",
     "text": "#Home"
    },
    {
     "type": "plain",
     "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Home Appliances \n                Small Kitchen Appliances \nPryce1728 \nDicos44 \n\n❌Regular Price  : ₹   2599  /- 😱"
    }
   ]
  },
  {
   "id": 20539,
   "type": "message",
   "date": "2023-05-04",
   "date_unixtime": "1683174816",
   "from": "All1App daily",
   "from_id": "channel1858370886",
   "photo": "(File not included. Change data exporting settings to download.)",
   "pryce": "162",
   "dicos": "59",
   "catagory": "Baby",
   "text_entities": [
    {
     "type": "plain",
     "text": "🚀🚀   The Moms Co. Natural Baby Cream for Face With Organic Butters, Oils and Milk Protein|Deep Moisturisation for Soft Baby Skin | Baby Face Cream |Baby Moisturizing Cream -50gm (Old Pack)✅\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3VDGHxV"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/414xoYM"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3VtrsaT"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3nuj8ek"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3VvB30I"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3VxPAsF"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3HDd7Tm"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LzZtBN"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3NBBFA0"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/420kZXe"
    },
    {
     "type": "plain",
     "text": "\n\n @162\n"
    },
    {
     "type": "link",
     "text": "https://m.media-amazon.com/images/I/710GHerB4dL._SX569_.jpg"
    },
    {
     "type": "plain",
     "text": " \n\n\n"
    },
    {
     "type": "hashtag",
     "text": "#Baby"
    },
    {
     "type": "plain",
     "text": "\n\n                Baby \n                Baby Care \n                Skin Care \nPryce162 \nDicos59 \n\n❌Regular Price  : ₹  393  /- 😱"
    }
   ]
  },
  {
   "id": 20540,
   "type": "message",
   "date": "2023-05-04",
   "date_unixtime": "1683174892",
   "from": "All1App daily",
   "from_id": "channel1858370886",
   "photo": "(File not included. Change data exporting settings to download.)",
   "pryce": "28990",
   "dicos": "39",
   "catagory": "Electronics",
   "text_entities": [
    {
     "type": "plain",
     "text": "🚀🚀   Samsung 108 cm (43 inches) Crystal 4K Neo Series Ultra HD Smart LED TV UA43AUE65AKXXL (Black)✅\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LRkR6S"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/419zu9P"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/419zu9P"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3ATqj2I"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/42kIvhq"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/42mlWJ9"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3VA9DXx"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3HDLfym"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3p6qEfR"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3p6qEfR"
    },
    {
     "type": "plain",
     "text": "\n\n "
    },
    {
     "type": "mention",
     "text": "@28990"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://m.media-amazon.com/images/I/71B7sRIydwL._SX679_.jpg"
    },
    {
     "type": "plain",
     "text": " \n\n\n"
    },
    {
     "type": "hashtag",
     "text": "#Electronics"
    },
    {
     "type": "plain",
     "text": "\n\n                Electronics \n                Home Theater, TV & Video \n                Televisions \nPryce28990 \nDicos39 \n\n❌Regular Price  : ₹   39999  /- 😱"
    }
   ]
  },
  {
   "id": 20541,
   "type": "message",
   "date": "2023-05-04",
   "date_unixtime": "1683174964",
   "from": "All1App daily",
   "from_id": "channel1858370886",
   "photo": "(File not included. Change data exporting settings to download.)",
   "pryce": "379",
   "dicos": "78",
   "catagory": "All",
   "text_entities": [
    {
     "type": "plain",
     "text": "🚀🚀   ADOFYS Helmet Chin Mount with Mobile Holder, Motorcycle Helmet Strap Compatible with GoPro Hero 9 8 7 6 5 4 3+ 3 SJCAM and Mobile Phones✅\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LB0klA"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/424SE1W"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LvZYN9"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/42rYhY5"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/411XdZO"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3p2ZRkx"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3HzckDa"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3ntUSsJ"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3p8jjfS"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3ntzrYR"
    },
    {
     "type": "plain",
     "text": "\n\n @379\n"
    },
    {
     "type": "link",
     "text": "https://m.media-amazon.com/images/I/51v1NI15HzL._SX679_.jpg"
    },
    {
     "type": "plain",
     "text": " \n\n\n"
    },
    {
     "type": "hashtag",
     "text": "#All"
    },
    {
     "type": "plain",
     "text": "\n\n                Electronics \n                Cameras & Photography \n                Accessories \nPryce379 \nDicos78 \n\n❌Regular Price  : ₹  1199   /- 😱"
    }
   ]
  },
  {
   "id": 20542,
   "type": "message",
   "date": "2023-05-04",
   "date_unixtime": "1683175033",
   "from": "All1App daily",
   "from_id": "channel1858370886",
   "photo": "(File not included. Change data exporting settings to download.)",
   "pryce": "1226",
   "dicos": "30",
   "catagory": "Watches",
   "text_entities": [
    {
     "type": "plain",
     "text": "🚀🚀   Escort Analog Black Dial Men's Watch-E-1600-1801 SL.3✅\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/44Erdho"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/44FFNoX"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/44FFNoX"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/44uzxQI"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3ntJygk"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/42lpfAv"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/44r6gX3"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3VxQ1Dj"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3VxPVeV"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3M47tMZ"
    },
    {
     "type": "plain",
     "text": "\n\n "
    },
    {
     "type": "mention",
     "text": "@1226"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://m.media-amazon.com/images/I/71YapThIDzL._UX679_.jpg"
    },
    {
     "type": "plain",
     "text": " \n\n\n"
    },
    {
     "type": "hashtag",
     "text": "#Watches"
    },
    {
     "type": "plain",
     "text": "\n\n                Watches \n                Men \n                 Wrist Watches \nPryce1226 \nDicos30 \n\n❌Regular Price  : ₹   1499  /- 😱"
    }
   ]
  },
  {
   "id": 20543,
   "type": "message",
   "date": "2023-05-04",
   "date_unixtime": "1683175105",
   "from": "All1App daily",
   "from_id": "channel1858370886",
   "photo": "(File not included. Change data exporting settings to download.)",
   "pryce": "998",
   "dicos": "71",
   "catagory": "Computers",
   "text_entities": [
    {
     "type": "plain",
     "text": "🚀🚀   Oakter Mini UPS for 12V WiFi Router Broadband Modem | Backup Upto 4 Hours | WiFi Router UPS Power Backup During Power Cuts | UPS for 12V Router Broadband Modem | Current Surge & Deep Discharge Protection✅\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3VteQQZ"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/421A4HX"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/428FYXZ"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3HDLEAS"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3HDLEAS"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3HE7tAn"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LRFb8c"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3HFuJOG"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LUIBqP"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/44vh9He"
    },
    {
     "type": "plain",
     "text": "\n\n @998\n"
    },
    {
     "type": "link",
     "text": "https://m.media-amazon.com/images/I/61Mbl0e228L._SX569_.jpg"
    },
    {
     "type": "plain",
     "text": " \n\n\n"
    },
    {
     "type": "hashtag",
     "text": "#Computers"
    },
    {
     "type": "plain",
     "text": " & Accessories\n\n                Computers & Accessories \n                 Networking Devices \nPryce998 \nDicos71 \n\n❌Regular Price  : ₹  2499    /- 😱"
    }
   ]
  },
  {
   "id": 20544,
   "type": "message",
   "date": "2023-05-04",
   "date_unixtime": "1683175179",
   "from": "All1App daily",
   "from_id": "channel1858370886",
   "photo": "(File not included. Change data exporting settings to download.)",
   "pryce": "10990",
   "dicos": "59",
   "catagory": "Computers",
   "text_entities": [
    {
     "type": "plain",
     "text": "🚀🚀   Samsung Galaxy Watch4 Bluetooth(4.0 cm, Black, Compatible with Android only)✅\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3AV3b3Q"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3ASATXL"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3ASATXL"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3ATCkVP"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3NzcYnJ"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3VD1d1O"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3VtnPSg"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3HDuu6I"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3VxuMl5"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3nxgqVp"
    },
    {
     "type": "plain",
     "text": "\n\n "
    },
    {
     "type": "mention",
     "text": "@10990"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://m.media-amazon.com/images/I/61nBzBREzGL._SX679_.jpg"
    },
    {
     "type": "plain",
     "text": " \n\n\n"
    },
    {
     "type": "hashtag",
     "text": "#Computers"
    },
    {
     "type": "plain",
     "text": " & Accessories\n\n                Electronics \n                Wearable Technology \n                 Smart Watches \nPryce10990 \nDicos59 \n\n❌Regular Price  : ₹ 19999   /- 😱"
    }
   ]
  },
  {
   "id": 20545,
   "type": "message",
   "date": "2023-05-04",
   "date_unixtime": "1683175250",
   "from": "All1App daily",
   "from_id": "channel1858370886",
   "photo": "(File not included. Change data exporting settings to download.)",
   "pryce": "398",
   "dicos": "73",
   "catagory": "Computers",
   "text_entities": [
    {
     "type": "plain",
     "text": "🚀🚀   Tukzer Extended Gaming Mouse Pad| Large Size (795 x 298 x 3.45mm) | Stitched Embroidery Edges| Non-Slip Rubber Base|Keyboard Mouse Pad for Office Home (Periodic Table), Black✅\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LPXAlH"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/415tsHB"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/419A2fT"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LA0jhV"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3NG3Dur"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3nwogys"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3nBceUx"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LzQtN2"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LypwsZ"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LA0fyH"
    },
    {
     "type": "plain",
     "text": "\n\n @398\n"
    },
    {
     "type": "link",
     "text": "https://m.media-amazon.com/images/I/71PeC4vSZfL._SX569_.jpg"
    },
    {
     "type": "plain",
     "text": " \n\n\n"
    },
    {
     "type": "hashtag",
     "text": "#Computers"
    },
    {
     "type": "plain",
     "text": " & Accessories\n\n                Computers & Accessories \n                Accessories & Peripherals \n                Keyboards, Mice & Input Devices \nPryce398 \nDicos73 \n\n❌Regular Price  : ₹  1299   /- 😱"
    }
   ]
  },
  {
   "id": 20546,
   "type": "message",
   "date": "2023-05-04",
   "date_unixtime": "1683175324",
   "from": "All1App daily",
   "from_id": "channel1858370886",
   "photo": "(File not included. Change data exporting settings to download.)",
   "pryce": "898",
   "dicos": "85",
   "catagory": "Computers",
   "text_entities": [
    {
     "type": "plain",
     "text": "🚀🚀   boAt Wave Prime47 Smart Watch with 1.69\" HD Display, 700+ Active Modes, ASAP Charge, Live Cricket Scores, Crest App Health Ecosystem, HR & SpO2 Monitoring(Royal Blue)✅\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LypmBT"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3pcjxCv"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3ARx0lR"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/414yMus"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/415zgkj"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/426w49i"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/42Gdwgb"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3AZtxSd"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3Lzbqr9"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3AR4LnB"
    },
    {
     "type": "plain",
     "text": "\n\n @898\n"
    },
    {
     "type": "link",
     "text": "https://m.media-amazon.com/images/I/61c1JhpcwWL._SX679_.jpg"
    },
    {
     "type": "plain",
     "text": " \n\n\n"
    },
    {
     "type": "hashtag",
     "text": "#Computers"
    },
    {
     "type": "plain",
     "text": " & Accessories\n\n                Electronics \n                Wearable Technology \n                 Smart Watches \nPryce898 \nDicos85 \n\n❌Regular Price  : ₹  3999   /- 😱"
    }
   ]
  },
  {
   "id": 20547,
   "type": "message",
   "date": "2023-05-04",
   "date_unixtime": "1683175395",
   "from": "All1App daily",
   "from_id": "channel1858370886",
   "photo": "(File not included. Change data exporting settings to download.)",
   "pryce": "1298",
   "dicos": "57",
   "catagory": "Electronics",
   "text_entities": [
    {
     "type": "plain",
     "text": "🚀🚀   boAt Aavante Bar 508 Portable Soundbar with 10W RMS Stereo Sound, Dual EQ Modes, Multi-Compatibility, 10HRS Playtime and Master Remote Control(Midnight Black)✅\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/42pL95M"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3nBuxZI"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LRFO1y"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3LRFO1y"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3Vxv1wv"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3NDgb5P"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/42pM31N"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3oZxpzY"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3Lt6LHv"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://amzn.to/3HFvmYy"
    },
    {
     "type": "plain",
     "text": "\n\n "
    },
    {
     "type": "mention",
     "text": "@1298"
    },
    {
     "type": "plain",
     "text": "\n"
    },
    {
     "type": "link",
     "text": "https://m.media-amazon.com/images/I/61CIU4+OWVL._SX679_.jpg"
    },
    {
     "type": "plain",
     "text": " \n\n\n"
    },
    {
     "type": "hashtag",
     "text": "#Electronics"
    },
    {
     "type": "plain",
     "text": "\n\n                Electronics \n                Home Audio \n                Speakers \nPryce1298 \nDicos57 \n\n❌Regular Price  : ₹  2499  /- 😱"
    }
   ]
  },
  {
    "id": 20139,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683073161",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "131",
    "dicos": "53",
    "catagory": "Beauty",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Park Avenue Cool Blue Soap Pack of 125 * 4 (500gm)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p1Nnd4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40Swq1S"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41PvkoV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44p5M3O"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NxN2c9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3no9Xw2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40Y1LjG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VqjKOy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42AKYEP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42gAutL"
     },
     {
      "type": "plain",
      "text": "\n\n @131\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/81VZi7VFmRL._SX569_PIbundle-4,TopRight,0,0_AA569SH20_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Beauty"
     },
     {
      "type": "plain",
      "text": "\n\n                Beauty \n                Bath & Body \n                Cleansers \nPryce131 \nDicos53 \n\n❌Regular Price  : ₹ 280   /- 😱"
     }
    ]
   },
   {
    "id": 20140,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683073264",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "999",
    "dicos": "38",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Hindware Zorio Ax Neo 100mm Exhaust Fan with Low Noise, Powerful Air Suction and High Speed, For Kitchen or Bathroom With Overload Protection For Odourless, Clean and Fresh Air (White)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40YK3fV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VqiRpe"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HypcsS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VxHq3O"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40YecMv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nvSh1n"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LuKR6N"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LjBrus"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42kbhP8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44oezCN"
     },
     {
      "type": "plain",
      "text": "\n\n @999\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/31oaOqIUVRL._SX300_SY300_QL70_FMwebp_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Heating, Cooling & Air Quality \n                Fans \nPryce999 \nDicos38 \n\n❌Regular Price  : ₹  1499   /- 😱"
     }
    ]
   },
   {
    "id": 20141,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683073366",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1549",
    "dicos": "61",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Hindware Smart Appliances Recio Bianco 1200MM Star Rated Ceiling Fan for home with 425 RPM Energy Efficient Silent Air Delivery Fan 51 Watt copper motor and aerodynamic blades✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42jzuFy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LQkYQf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41VBZhx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p0NzsY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hym4xw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HVzZ0P"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NxLaQT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NFRTYK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LR6D65"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VstK9X"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1549"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51pQIrOukaL._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Heating, Cooling & Air Quality \n                Fans \nPryce1549 \nDicos61 \n\n❌Regular Price  : ₹   2999  /- 😱"
     }
    ]
   },
   {
    "id": 20142,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683073473",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "964",
    "dicos": "59",
    "catagory": "Industrial",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Polycab 3W LED Panel Light Scintillate Edge Slim Square Smart Offers Bright Lumination Long Lifespan No Harmful Radiation (Neutral White, 4000K, 6 PCS, Cut Out: 2.55 inches)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VuK75Z"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42iOXWd"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NylUtQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nlEHxy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42kbMbY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42kwNDk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oWHe1z"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nodYk1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hwp2m1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VsSbV5"
     },
     {
      "type": "plain",
      "text": "\n\n @964\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/41bmfarzhSL._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Industrial"
     },
     {
      "type": "plain",
      "text": " & Scientific\n\n                Home & Kitchen \n                Indoor Lighting \n                Light Bulbs \nPryce964 \nDicos59 \n\n❌Regular Price  : ₹ 1999    /- 😱"
     }
    ]
   },
   {
    "id": 20143,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683073602",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "197",
    "dicos": "50",
    "catagory": "Beauty",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   MyGlamm Superfoods Umber Kajal, Brown, Long Lasting, Waterproof, & Smudge-Proof , Matte Finish Eye Pencil, 0.35g✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LMujse"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Nz5CAK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lq9mlh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nwf6C2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44ofpPX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NyP3Fc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40XhpvB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Nx1eSE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44m30vU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44oj1S1"
     },
     {
      "type": "plain",
      "text": "\n\n @197\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/415L6cagk9L._SX300_SY300_QL70_FMwebp_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Beauty"
     },
     {
      "type": "plain",
      "text": "\n\n                Beauty \n                Make-up \n                Eyes \nPryce197 \nDicos50 \n\n❌Regular Price  : ₹  395   /- 😱"
     }
    ]
   },
   {
    "id": 20144,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683073710",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "680",
    "dicos": "51",
    "catagory": "Grocery",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Nature Purify Dry Fruits Combo Gift Pack for 1 Kg (Almond, Mixed Nuts, Raisins, Apricot - 250g Each)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p1A3W5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41W1SNX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NrRbyq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Nwm04Y"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42gAY33"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AL46DY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44hYbUF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42iPjw1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p716z5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AOciTW"
     },
     {
      "type": "plain",
      "text": "\n\n @680\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71hTQ0aRqsL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Grocery"
     },
     {
      "type": "plain",
      "text": " & Gourmet Foods\n\n                Grocery & Gourmet Foods \n                Dried Fruits, Nuts & Seeds \n                Dried Fruits \nPryce680 \nDicos51 \n\n❌Regular Price  : ₹  1299   /- 😱"
     }
    ]
   },
   {
    "id": 20145,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683073819",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1155",
    "dicos": "77",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Happer Plastic, Alloy Steel Cloth Drying Stand, Exquisite Designed, Winsome (White & Blue)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p10DyH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lq9K3d"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p0zSue"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44r7BwT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LtMq4z"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LOOw0n"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Luwq2w"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hw6eTK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nrknej"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ALgp3h"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1155"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71qc5keTnpL._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Home Storage & Organisation \n                Laundry Organization \nPryce1155 \nDicos77 \n\n❌Regular Price  : ₹  3999   /- 😱"
     }
    ]
   },
   {
    "id": 20146,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683073921",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "437",
    "dicos": "60",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Butterfly Rapid Frypan 240 mm Induction Base✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HxWE2M"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LM0SGG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://www.amazon.in/dp/B0B8JKX65J?&amp;linkCode=sl1&amp;tag=deals103-21&amp;linkId=a10ea209c06f44b604d570f8e68e8462&amp;language=en_IN&amp;ref_=as_li_ss_tl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42mKmm5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NxNWW5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41e80jL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44qvC7x"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44kI1tA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3niuW3p"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42kstUI"
     },
     {
      "type": "plain",
      "text": "\n\n @437\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61NilmXF1xL._SX522_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Dining \n                Cookware \nPryce437 \nDicos60 \n\n❌Regular Price  : ₹ 999  /- 😱"
     }
    ]
   },
   {
    "id": 20147,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683074024",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "9595",
    "dicos": "20",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   PHILIPS HL7707/00 750W Mixer Grinder with 10-Year Warranty on product registration, 4 Jars including Blender Jar and Food Processor, Black✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42kclT8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VtD2ms"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41WgMDP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LYMX0d"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LMspry"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NDfvO1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LuFxjJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44r7YYj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Nz6g18"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HtUiCg"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@9595"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61D71DmchIL._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Home Appliances \n                Small Kitchen Appliances \nPryce9595 \nDicos20 \n\n❌Regular Price  : ₹  11499   /- 😱"
     }
    ]
   },
   {
    "id": 20148,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683074124",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "232",
    "dicos": "51",
    "catagory": "Beauty",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   NIVEA Deodorant, Fresh Active for Men, 150ml and Fresh Face Moisturizer Gel for Men, 75ml ✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NDfCcp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44kIpbx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nmfZgv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40ZhaQI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40YLlaL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40ZhDCs"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/416D2tO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HzVow2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/423mZht"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LjCzOI"
     },
     {
      "type": "plain",
      "text": "\n\n @232\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/515km1lUu6L._SX569_PIbundle-2,TopRight,0,0_AA569SH20_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Beauty"
     },
     {
      "type": "plain",
      "text": "\n\n                Beauty \n                Bath & Body \n                Deodorants & Antiperspirants \nPryce232 \nDicos51 \n\n❌Regular Price  : ₹  475    /- 😱"
     }
    ]
   },
   {
    "id": 20149,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683074261",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "412",
    "dicos": "80",
    "catagory": "Car",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   ARNV Car Cover Compatible with Mercedes E-Class | Grey✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41PxnJD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nfDWX2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HwTX1s"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HyYC3b"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HApEad"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hw71UI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LOGepl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/412Kd5Y"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40Xiun9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44nY21N"
     },
     {
      "type": "plain",
      "text": "\n\n @412\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/41EanIvXUOL.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Car"
     },
     {
      "type": "plain",
      "text": " & Motorbike\n\n                Car & Motorbike \n                Car Accessories \n                Exterior Accessories \nPryce412 \nDicos80 \n\n❌Regular Price  : ₹   1599  /- 😱"
     }
    ]
   },
   {
    "id": 20150,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683074401",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "399",
    "dicos": "60",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Huesland by Ahmedabad Cotton 144 TC Cotton Bedsheet for Double Bed with 2 Pillow Covers - White, Grey✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NynVpU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42i3npv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vs4UqL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LPxUWm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LOAc81"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41PxqVP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LtNd5x"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LpLnTy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42aGSDa"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41WAFuM"
     },
     {
      "type": "plain",
      "text": "\n\n @399\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/91z2SePkwxL._SX522_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Home Furnishing \n                Bedding & Linen \nPryce399 \nDicos60 \n\n❌Regular Price  : ₹  899   /- 😱"
     }
    ]
   },
   {
    "id": 20151,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683074516",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "999",
    "dicos": "80",
    "catagory": "Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   ZEBRONICS Zeb-Ultimate Star webcamera with 5P Lens 1920x1080 Full HD Resolution with Built-in mic, auto White Balance, 16 LED Ring Lights with Brightness Control and 1.58m Cable✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HxXi0c"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B6GJFj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ANhDuN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nlFX3K"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ALhqs7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44pcWVG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40Zb7vH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p8d5MM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AMkX9l"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LpLqyI"
     },
     {
      "type": "plain",
      "text": "\n\n @999\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71seeRaDoyL._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Electronics \n                Cameras & Photography \n                Accessories \nPryce999 \nDicos80 \n\n❌Regular Price  : ₹  3999    /- 😱"
     }
    ]
   },
   {
    "id": 20152,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683074653",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "198",
    "dicos": "77",
    "catagory": "Clothing",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   dockstreet Stretchable Light Fabric Cargo Type Styled Leggings✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/421egwq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lt66FT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ASP7ru"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HAfnuO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HtWo51"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ANmdcr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HylVKk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Nun09R"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NxP105"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40UUBwB"
     },
     {
      "type": "plain",
      "text": "\n\n @198\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71Skt4kNfwL._UY741_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Clothing"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Clothing & Accessories \n                Women \n                Western Wear \nPryce198 \nDicos77 \n\n❌Regular Price  : ₹   799  /- 😱"
     }
    ]
   },
   {
    "id": 20153,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683074787",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1489",
    "dicos": "81",
    "catagory": "Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Zebronics ETERNAL Bluetooth Calling Smart watch with 1.85\" Large display, Voice assistant, 100+ Sport modes, IP67 Waterproof, 11 built-in & customizable wallpaper, 8 Menu UI, Crown and Calculator (Grey)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LM0MyP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NyQV0G"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oT8ZrS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p0B6Wm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vtcod2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lrmh6K"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NyRebQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NrSr4C"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NBah58"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40XjpUD"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1489"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61FsVWVCdML._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Electronics \n                Wearable Technology \n                 Smart Watches \nPryce1489 \nDicos81 \n\n❌Regular Price  : ₹  4999   /- 😱"
     }
    ]
   },
   {
    "id": 20154,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683074897",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "249",
    "dicos": "90",
    "catagory": "Shoes",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Bagsy Malone Women's Vegan Leather Iconic Sling Bag | Ladies Purse Handbag✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44qwR6H"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ANidbX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/424LRpi"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ALYdGE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NAOKcP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40ZhmPS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Nx2ZPK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42fd6No"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ANilIt"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40SyVRO"
     },
     {
      "type": "plain",
      "text": "\n\n @249\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/91WkHrO4ozL._UY625_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Shoes"
     },
     {
      "type": "plain",
      "text": " & Handbags\n\n                Fashion \n                Pongal || Fashion accessories \n                Handbags \nPryce249 \nDicos90 \n\n❌Regular Price  : ₹  1499    /- 😱"
     }
    ]
   },
   {
    "id": 20155,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683075008",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1999",
    "dicos": "82",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   beatXP Vega 1.43\" AMOLED 466 * 466px Display One-Tap BT 5.2 Calling AI Voice Assistant Smartwatch (Electric Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VqIK8s"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VssLH0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ntr8ft"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HtWDgr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hybpmo"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LtlD8x"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LqbTfh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LvolKG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LOARX3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HA5n4G"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1999"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71FP8dVTgVL._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Wearable Technology \n                 Smart Watches \nPryce1999 \nDicos82 \n\n❌Regular Price  : ₹  6999   /- 😱"
     }
    ]
   },
   {
    "id": 20156,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683075147",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1999",
    "dicos": "50",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Bajaj Esteem 400 mm Wall Fan (White)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HxYbWA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LQnkyz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40TwPB3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42kuccW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42gYQDJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NBazZM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44olbkB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44nOcNh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LYOqDL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LMtRKm"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1999"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51-rWPXim7L._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Heating, Cooling & Air Quality \n                Fans \nPryce1999 \nDicos50 \n\n❌Regular Price  : ₹  2999   /- 😱"
     }
    ]
   },
   {
    "id": 20157,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683075284",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "29994",
    "dicos": "65",
    "catagory": "Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Acer Nitro 68.58 cm (27 Inch) IPS WQHD 2560 X 1440 Resolution Monitor I 1 MS, 240Hz (Overclock to 270Hz) I 400 Nits I 2 X HDMI 1 X DP 1 X Type C 4 X USB 3.0 HUB I dicos Adjustment and Pivot Function (XV272UX)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vo8EK7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Nz7AB8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NxEX7f"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lq6pkM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p4upTf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41ZwYEo"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/421f2tk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HtW0DG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44nOqUD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40Zc6fn"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@29994"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/815nQiF91PL._SX450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Computers & Accessories \n                2021 \n                 Gaming Monitors \nPryce29994 \nDicos65 \n\n❌Regular Price  : ₹ 49999   /- 😱"
     }
    ]
   },
   {
    "id": 20158,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683075395",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "23727",
    "dicos": "40",
    "catagory": "Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Seagate IronWolf 10TB NAS Internal Hard Drive HDD – CMR 3.5 Inch SATA 6Gb/s 7200 RPM 256MB Cache for RAID Network Attached Storage with 3-Years Data Recovery Services (ST10000VN000)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NFUoKC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oXr2gF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nmtKvI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AL6r1I"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VnB6f7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42i4PYZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LjEtPy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41PyQ2B"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41ZJCTU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VtdiWY"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@23727"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/81+054mzXML._SY450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Computers & Accessories \n                Components \n                 Internal Hard Drives \nPryce23727 \nDicos40 \n\n❌Regular Price  : ₹  34999     /- 😱"
     }
    ]
   },
   {
    "id": 20159,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683075510",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "19997",
    "dicos": "43",
    "catagory": "Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Seagate FireCuda 530 2TB Internal Solid State Drive - M.2 PCIe Gen4 4 NVMe 1.4, Transfer speeds up to 7300 MB/s, 3D TLC NAND, 2550 TBW, 1.8M MTBF (ZP2000GM3A013), Orange, 2 TB✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AOyY6I"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3npV64a"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lq6WTO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LM315c"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hws8Xb"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nrY9ZB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HzXw70"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3npVnnI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NBaVj4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AKJMTk"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@19997"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61kdV+Nig5L._SY450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Computers & Accessories \n                Components \n                 Internal Solid State Drives \nPryce19997 \nDicos43 \n\n❌Regular Price  : ₹  29999   /- 😱"
     }
    ]
   },
   {
    "id": 20160,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683075648",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "869",
    "dicos": "85",
    "catagory": "Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   HP 128GB Class 10 MicroSD Memory Card (U1 TF Card  128GB)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/410PpHs"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42cXeee"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42fgyHR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40YhpM3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NuobGj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42iSbJj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VqjVcF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oUeJBE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/421du2k"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LpMVNm"
     },
     {
      "type": "plain",
      "text": "\n\n @869\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51pA4SwiZRL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Electronics \n                Accessories \n                Memory Cards \nPryce869 \nDicos85 \n\n❌Regular Price  : ₹  3999   /- 😱"
     }
    ]
   },
   {
    "id": 20161,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683075760",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "2950",
    "dicos": "57",
    "catagory": "Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Lapcare LAPDISC NVMe 2280 PCIe Gen 3 SSD 512GB✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LjF7ws"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41Yxzq4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/410beHt"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/410PI54"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HA6d1k"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p32rak"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41ZK2JY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p1Rxlc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AQ7ygw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NzZXdS"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@2950"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61D8J8F63QL._SX450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Computers & Accessories \n                Components \n                 Internal Solid State Drives \nPryce2950 \nDicos57 \n\n❌Regular Price  : ₹  5999   /- 😱"
     }
    ]
   },
   {
    "id": 20162,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683075893",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "149",
    "dicos": "75",
    "catagory": "Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Amazon Basics Wired Mouse, 1000 DPI Optical Sensor Tracking, Standard Control 3-Button & 1 Scroller | USB 2.0 Mice Compatible for PC/Mac/Laptop/Tablet (Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p5Pz2Z"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41W4Thf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HyssV8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41Wk3TD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41TH2Pk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VuGCg6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LOUJcM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3novkxe"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NxRec4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AMirzZ"
     },
     {
      "type": "plain",
      "text": "\n\n @149\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61O9BpppwOL._SY879_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Computers & Accessories \n                Accessories & Peripherals \n                Keyboards, Mice & Input Devices \nPryce149 \nDicos75 \n\n❌Regular Price  : ₹  549   /- 😱"
     }
    ]
   },
   {
    "id": 20163,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683076026",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1099",
    "dicos": "71",
    "catagory": "Sports",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Lifelong LLPVCHGC14 PVC Home Gym Set 20kg Plate with Extension Barbell Rod and Dumbbells Rods with Gym Accessories for Home Workouts (Black, 6 Months Manufacturer's Warranty)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ALZDRu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42gZMrJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44pG9j2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nlmArH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44kLaJV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40ZiJy0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42fh8W3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vp1p4C"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Voa9If"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42cY5LY"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1099"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51hYlA4ucoL._SX300_SY300_QL70_FMwebp_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Sports"
     },
     {
      "type": "plain",
      "text": ", Fitness & Outdoors\n\n                Home & Kitchen \n                Home & kitchen | Prime shopping days \n                Affinity ASINs | 200 \nPryce1099 \nDicos71 \n\n❌Regular Price  : ₹  2999   /- 😱"
     }
    ]
   },
   {
    "id": 20164,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683076161",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "899",
    "dicos": "70",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Milton Ernesto Inner Stainless Steel Jr. Casserole Set of 3 (420 ml, 850 ml, 1.43 Litres), Grey | Easy to Carry | Serving | Stackable✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LOm1jq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LrQQt1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LuBYda"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HyUTCr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NxOqvB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41YCkQe"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44omj7P"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oXs4t3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NxOtrh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/424NL9q"
     },
     {
      "type": "plain",
      "text": "\n\n @899\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71G-hfSm5DL._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Dining \n                Tableware \nPryce899 \nDicos70 \n\n❌Regular Price  : ₹ 1999   /- 😱"
     }
    ]
   },
   {
    "id": 20165,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683076296",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1999",
    "dicos": "50",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Bajaj Esteem 400 mm Wall Fan (White)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AOfsag"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VqnEaa"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VnYitD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VoaxXd"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HxMoHL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LuC3gY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LOm66I"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44gXoTT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LNRhyZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hyt5Ou"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1999"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/31Orq4RfaKL._SX300_SY300_QL70_FMwebp_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Heating, Cooling & Air Quality \n                Fans \nPryce1999 \nDicos50 \n\n❌Regular Price  : ₹ 3799   /- 😱"
     }
    ]
   },
   {
    "id": 20166,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683076436",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "2299",
    "dicos": "67",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Philips Audio TAT2236 TWS Earbuds with IPX4, 6+12 Hours Play time, Quick Charge, Type-C Charging, Black✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3now2dS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LRaEHH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HxIUoP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42i5TMt"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3now8SM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NxRfwt"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42kvMvo"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NA0RqM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oRThx9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42i64r7"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@2299"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51RZh2iDfAL._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Headphones, Earbuds & Accessories \n                Headphones \nPryce2299 \nDicos67 \n\n❌Regular Price  : ₹ 5599   /- 😱"
     }
    ]
   },
   {
    "id": 20167,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683076571",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "29994",
    "dicos": "65",
    "catagory": "Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Acer Nitro 68.58 cm (27 Inch) IPS WQHD 2560 X 1440 Resolution Monitor I 1 MS, 240Hz (Overclock to 270Hz) I 400 Nits I 2 X HDMI 1 X DP 1 X Type C 4 X USB 3.0 HUB I dicos Adjustment and Pivot Function (XV272UX)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40XlNe3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VuHjpI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LOD4BP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41ZuAh9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nwje50"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NxRZSs"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NAQRNN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HzI5vP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HwWR6m"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40XiyDI"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@29994"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/815nQiF91PL._SX450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Computers & Accessories \n                2021 \n                 Gaming Monitors \nPryce29994 \nDicos65 \n\n❌Regular Price  : ₹ 64999   /- 😱"
     }
    ]
   },
   {
    "id": 20168,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683076698",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1399",
    "dicos": "85",
    "catagory": "Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   ZEBRONICS ZEB-MN26 256GB M.2 NVMe Solid State Drive (SSD), with 1900MB/s Read Speed, PCIe Gen 3.0, Next Level Performance, Ultra Low Power Consumption, Thermal Management and Silent Operation.✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p1SqKy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AOJECg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ANkjsl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VteIAM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oXsNKN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AL8Jhk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ALkpRl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LjFCXa"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42eDPtu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/412NfHo"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1399"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51Ymki0Ol5L._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Computers & Accessories \n                Components \n                 Internal Solid State Drives \nPryce1399 \nDicos85 \n\n❌Regular Price  : ₹ 4999   /- 😱"
     }
    ]
   },
   {
    "id": 20169,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683076810",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "899",
    "dicos": "85",
    "catagory": "Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   boAt Wave Prime47 Smart Watch with 1.69\" HD Display, 700+ Active Modes, ASAP Charge, Live Cricket Scores, Crest App Health Ecosystem, HR & SpO2 Monitoring(Royal Blue)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41Zvr1l"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HwXbSC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AQVVpP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3np62Pu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p1EWOV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p1EY9v"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44l0qGC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LPnYfG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LM3sfR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nohWJr"
     },
     {
      "type": "plain",
      "text": "\n\n @899\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61c1JhpcwWL._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Electronics \n                Wearable Technology \n                 Smart Watches \nPryce899 \nDicos85 \n\n❌Regular Price  : ₹ 3499   /- 😱"
     }
    ]
   },
   {
    "id": 20170,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683076947",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "999",
    "dicos": "75",
    "catagory": "Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Noise ColorFit Pulse Grand Smart Watch with 1.69\"(4.29cm) HD Display, 60 Sports Modes, 150 Watch Faces, Fast Charge, Spo2, Stress, Sleep, Heart Rate Monitoring & IP68 Waterproof (Jet Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42h0UeX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42ghbB4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LNTE4P"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40ZjOpy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p3ITTh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VtGHAI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VnoYuF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42kqtvB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VsW1xt"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oRU29t"
     },
     {
      "type": "plain",
      "text": "\n\n @999\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61Q0R5cdxWL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Electronics \n                Wearable Technology \n                 Smart Watches \nPryce999 \nDicos75 \n\n❌Regular Price  : ₹ 2799   /- 😱"
     }
    ]
   },
   {
    "id": 20171,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683077082",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1199",
    "dicos": "66",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   boAt Airdopes 190 True Wireless in Ear Earbuds with Beast Mode(50ms) for Gaming, 40H Playtime, Breathing LEDs, Signature Sound, Quad Mics Enx Tech, ASAP Charge & BT v5.3(Black Sabre)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NFWjPk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3noiiQh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HA7J3w"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HyqnsG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44paYEQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44ArhyL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44i3pQh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nh07Mx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41YQ25J"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p4TuNO"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1199"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/5112PrfGFOL._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Headphones, Earbuds & Accessories \n                Headphones \nPryce1199 \nDicos66 \n\n❌Regular Price  : ₹  2799  /- 😱"
     }
    ]
   },
   {
    "id": 20172,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683077220",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "380",
    "dicos": "87",
    "catagory": "Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   STRIFF Laptop Stand, Multi-Angle Adjustable Laptop Riser with Foldable Legs and Phone Holder, Ventilated Notebook Stand Tray for MacBook, Desktop Computer, Tablet(Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VtH6mI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40ZebId"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Ls5CQj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nrZXBR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AMo3dt"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nrpc7p"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HAiumu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ALlhFB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44pgpna"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44rctCb"
     },
     {
      "type": "plain",
      "text": "\n\n @380\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71caiKgkwVL._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Computers & Accessories \n                Accessories & Peripherals \n                Laptop Accessories \nPryce380 \nDicos87 \n\n❌Regular Price  : ₹ 1799   /- 😱"
     }
    ]
   },
   {
    "id": 20173,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683077342",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "418",
    "dicos": "54",
    "catagory": "Beauty",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Nivea Naturally Good Natural Lavender Body Lotion 200ml, Nivea Women Waterlily & Oil Body Wash 125ml, Nivea Soft Crame 50ml And Nivea Lip Care Fruity Shine Cherry 4.8G With Styling Pouch✅\n"
     },
     {
      "type": "link",
      "text": "https://www.amazon.in/Naturally-Natural-Lavender-Waterlily-Nivea/dp/B0B8NBFZMN?keywords=Yardley&amp;qid=1682967464&amp;refinements=p_85%3A10440599031%2Cp_n_pct-off-with-tax%3A2665401031&amp;rnid=2665398031&amp;rps=1&amp;s=beauty&amp;sr=1-28&amp;linkCode=sl1&amp;tag=avinashbmv07-21&amp;linkId=14e8bc587f4e5080d7f885435232879d&amp;language=en_IN&amp;ref_=as_li_ss_tl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LuD7Bu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AQ9Mwo"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LmeJlA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NvONqe"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LOcybM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HzZSTo"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42fgiIS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oUgAX8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42h1IjZ"
     },
     {
      "type": "plain",
      "text": "\n\n @418\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61j+IigyndL._SX569_PIbundle-5,TopRight,0,0_AA569SH20_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Beauty"
     },
     {
      "type": "plain",
      "text": "\n\n                Beauty \n                Bath & Body \n                Body Washes \nPryce418 \nDicos54 \n\n❌Regular Price  : ₹  850  /- 😱"
     }
    ]
   },
   {
    "id": 20174,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683077484",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1499",
    "dicos": "81",
    "catagory": "Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   boAt Wave Call Smart Watch, Smart Talk with Advanced Dedicated Bluetooth Calling Chip, 1.69 HD Display with 550 NITS & 70% Color Gamut, 150+ Watch Faces, Multi-Sport Modes,HR,SpO2, IP68(Active Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LOE1Kp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/410dfU3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vyuf2r"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VpxoBs"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42kBNrA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Nu35HV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/424P5Jq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oRUB35"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LtHWLk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44pI4Eg"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1499"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/41RulQ1ZXbL._SX300_SY300_QL70_FMwebp_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Electronics \n                Wearable Technology \n                 Smart Watches \nPryce1499 \nDicos81 \n\n❌Regular Price  : ₹ 4599   /- 😱"
     }
    ]
   },
   {
    "id": 20175,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683077624",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "999",
    "dicos": "75",
    "catagory": "Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Noise ColorFit Pulse Grand Smart Watch with 1.69\"(4.29cm) HD Display, 60 Sports Modes, 150 Watch Faces, Fast Charge, Spo2, Stress, Sleep, Heart Rate Monitoring & IP68 Waterproof (Electric Blue)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/410S2ZQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NttDJz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41YQvVx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VsHYb5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NwP2RW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AOF5YM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ALPuEz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42gFDlz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nwkAwC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HAxuR7"
     },
     {
      "type": "plain",
      "text": "\n\n @999\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/412dSHwBHGL._SX300_SY300_QL70_FMwebp_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Electronics \n                Wearable Technology \n                 Smart Watches \nPryce999 \nDicos75 \n\n❌Regular Price  : ₹ 2799   /- 😱"
     }
    ]
   },
   {
    "id": 20176,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683090564",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1699",
    "dicos": "81",
    "catagory": "Clothing",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   AmazonBasics 20-Inch Carry-on, Navy Blue✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hy1Fs5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AQ0y3g"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3njnTaG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ANybCV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Ny27L1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AQ0Jvs"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LOpbUe"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40XAnCj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nosisS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Ny5wte"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1699"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/81D-JCWRgJL._UX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Clothing"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Bags, Wallets and Luggage \n                Luggage \n                 Suitcases & Trolley Bags \nPryce1699 \nDicos81 \n\n❌Regular Price  : ₹ 4999  /- 😱"
     }
    ]
   },
   {
    "id": 20177,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683090703",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "599",
    "dicos": "83",
    "catagory": "Shoes",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Lavie Women's Betula Medium Tote Bag | Ladies Purse Handbag✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LPadgU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Nt0tKx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Ny0ahl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NxjFqk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41ZNqEE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/4115ooW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lqsvn9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lw2JOq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LoagPr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LzBSRQ"
     },
     {
      "type": "plain",
      "text": "\n\n @599\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61i7e+IUt3L._UY500_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Shoes"
     },
     {
      "type": "plain",
      "text": " & Handbags\n\n                Fashion \n                Shoes & handbags steal deals \n                Steal Deals - Women's Footwear & Handbags \nPryce599 \nDicos83 \n\n❌Regular Price  : ₹ 2499   /- 😱"
     }
    ]
   },
   {
    "id": 20178,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683090844",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "599",
    "dicos": "63",
    "catagory": "Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Redgear MS-150 Wired Gamepad with 2 Digital triggers, 2 Analog Sticks, Ergonomic Design, 1.8 m Durable Cable, X Input and Direct Input(Blood Red)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AMBv0X"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LOACLT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NyF1E6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NyUPqf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40ZzPMg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NBqnf4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LrCk4u"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LMjkyW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42gulxY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42ktnk2"
     },
     {
      "type": "plain",
      "text": "\n\n @599\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/312h5+emsiL._SY300_SX300_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Computers & Accessories \n                Accessories & Peripherals \n                PC Gaming Peripherals \nPryce599 \nDicos63 \n\n❌Regular Price  : ₹  1399   /- 😱"
     }
    ]
   },
   {
    "id": 20179,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683090977",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "224",
    "dicos": "50",
    "catagory": "Beauty",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Fiama Shower Gel Patchouli & Macadamia, Body Wash With Skin Conditioners For Smooth Skin, 500ml pump✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42eR1yw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3npklna"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42lYsUL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/421vmu6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42i9dY5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41Z4mev"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44puDEA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/421sCg8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p3itB2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44qNfEf"
     },
     {
      "type": "plain",
      "text": "\n\n @224\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61Mdxm13ceL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Beauty"
     },
     {
      "type": "plain",
      "text": "\n\n                Beauty \n                Bath & Body \n                Cleansers \nPryce224 \nDicos50 \n\n❌Regular Price  : ₹  449   /- 😱"
     }
    ]
   },
   {
    "id": 20180,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683091115",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "376",
    "dicos": "71",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   HB PLUS M3 Colorful Wireless Bluetooth Speakers Mini Electroplating Round Steel Speaker (Random Color) (Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VsUZBt"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VopGrx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p56GlO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VsXhQZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VyHgJl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vv2Myz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B6Yt3n"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VnDBy5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NtlgO4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VsJVnU"
     },
     {
      "type": "plain",
      "text": "\n\n @376\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51uJbi2wpIL._SX466_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Home Audio \n                Speakers \nPryce376 \nDicos71 \n\n❌Regular Price  : ₹   1099  /- 😱"
     }
    ]
   },
   {
    "id": 20181,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683091251",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "799",
    "dicos": "82",
    "catagory": "Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   ZEBRONICS SD13 128GB SSD,Ultra Low Power Consumption, S.M.A.R.T. Thermal Management and Silent Operation.✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lu4yLF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://www.amazon.in/dp/B0B5RXCR7F?th=1&amp;linkCode=sl1&amp;tag=bmvavinash05-21&amp;linkId=4bb6c9a3c9d407bb33d0e2f89e9c4469&amp;language=en_IN&amp;ref_=as_li_ss_tl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p64NFb"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LMi7rp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NyikzH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Nz9odw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LOZkLW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/410rLev"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41ZSQ2x"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Ny551T"
     },
     {
      "type": "plain",
      "text": "\n\n @799\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61oF8gHU9EL._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Computers & Accessories \n                Components \n                 Internal Solid State Drives \nPryce799 \nDicos82 \n\n❌Regular Price  : ₹  1999  /- 😱"
     }
    ]
   },
   {
    "id": 20182,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683091385",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "899",
    "dicos": "74",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Spotzero By Milton Twin Star Spin Mop with Bucket | Cleaning Mop | Dry Compartments | Sturdy Basket✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hxc738"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LvFVOG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LjX0LC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lr1YWX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AQiB9M"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AT6tEE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oS8xtV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42ddbB8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HAmFyC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3noKYIY"
     },
     {
      "type": "plain",
      "text": "\n\n @899\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71Iq+K-KFeL._SX466_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Great Indian Festival Home & Kitchen \n                 Home Improvement \nPryce899 \nDicos74 \n\n❌Regular Price  : ₹  1999   /- 😱"
     }
    ]
   },
   {
    "id": 20183,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683091527",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1899",
    "dicos": "78",
    "catagory": "Luggage",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   AmazonBasics 20-Inch Carry-on, Grey✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44o4vcX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hz9RZ9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HxchYi"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Nw4v4G"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3njoTeW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42gTuZ7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LpCVDz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nfWHcU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42fwDNJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42n03cV"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1899"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/81dDRLFHLXL._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Luggage"
     },
     {
      "type": "plain",
      "text": " & Bags\n\n                Bags, Wallets and Luggage \n                Luggage \n                 Suitcases & Trolley Bags \nPryce1899 \nDicos78 \n\n❌Regular Price  : ₹  6999   /- 😱"
     }
    ]
   },
   {
    "id": 20184,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683091665",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "2575",
    "dicos": "26",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Bajaj Glide Plus Ceiling Fan 1200 mm, Lemon Blue✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42ikUhj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42cYnCs"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41ZJRP1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41XUfXv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41YRepE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p26aoC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44ihQ6V"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41WQCkz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44r42qO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40TOk4d"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@2575"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/41uXb4OlmTL._SX466_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Heating, Cooling & Air Quality \n                Fans \nPryce2575 \nDicos26 \n\n❌Regular Price  : ₹  2999    /- 😱"
     }
    ]
   },
   {
    "id": 20185,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683091805",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "399",
    "dicos": "73",
    "catagory": "Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   HP v236w USB 2.0 64GB Pen Drive, Metal✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44poUPa"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44poYhS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HAeiD0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VuWJdA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VtUp6E"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41YRxAO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LMK0zC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VGBANH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VqB5H7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LtCRCJ"
     },
     {
      "type": "plain",
      "text": "\n\n @399\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61xNG4wjOuL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Computers & Accessories \n                External Devices & Data Storage \n                 Pen Drives \nPryce399 \nDicos73 \n\n❌Regular Price  : ₹  1299   /- 😱"
     }
    ]
   },
   {
    "id": 20186,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683091941",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "309",
    "dicos": "23",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Portronics Konnect L POR-1403 Fast Charging 3A Type-C Cable 1.2 Meter with Charge & Sync Function for All Type-C Devices (White)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VsXWSt"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AQo5B6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p57x60"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p65ApD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HyIQ86"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AOTTXm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AOZEEk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3APbMFk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44qO28b"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LqtB2f"
     },
     {
      "type": "plain",
      "text": "\n\n @309\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/41HYPIrcYQL._SX466_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Computers & Accessories \n                Accessories & Peripherals \n                Cables & Accessories \nPryce309 \nDicos23 \n\n❌Regular Price  : ₹  499    /- 😱"
     }
    ]
   },
   {
    "id": 20187,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683092080",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "599",
    "dicos": "70",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Portronics Harmonics Z3 Wireless Bluetooth 5.3 Neckband in Ear Earphones with mic, 30Hrs Playtime, Magnetic Latch, IPX5 Water Resistant, Type C Charging Port(Green)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LMz8kb"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44lg8l4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44zmeyu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40YmgNa"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NzoAHs"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oXWNGl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p4MbWt"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LtnPwV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42kFTQv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42kFTQv"
     },
     {
      "type": "plain",
      "text": "\n\n @599\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51EpMdCFPuL._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Headphones, Earbuds & Accessories \n                Headphones \nPryce599 \nDicos70 \n\n❌Regular Price  : ₹  1499    /- 😱"
     }
    ]
   },
   {
    "id": 20188,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683092208",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "67",
    "dicos": "55",
    "catagory": "Grocery",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Unibic Chocolate Hazelnut Crispy Wafer Cubes 150g, Waffer cookie with Creme-Filling Delicious and Healthy Snacks✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42fK0gQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HudRu7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41Wl91L"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LPCy6U"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41Xjlpv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oTpYu8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HxQCzg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oX1gZP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41ZBVNt"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41XV02N"
     },
     {
      "type": "plain",
      "text": "\n\n @67\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/81ZkEAEIosL._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Grocery"
     },
     {
      "type": "plain",
      "text": " & Gourmet Foods\n\n                Grocery & Gourmet Foods \n                Snacks & Sweets \n                Snack Foods \nPryce67 \nDicos55 \n\n❌Regular Price  : ₹  150   /- 😱"
     }
    ]
   },
   {
    "id": 20189,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683092338",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "799",
    "dicos": "78",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   PTron Bassbuds Jade Truly Wireless in Ear Earbuds with 40ms Gaming Low Latency, HD Stereo Calls, 40Hrs Playtime, 1-Step Pairing Bluetooth Headphones, Fast TypeC Charging & IPX4 Waterproof (Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42hg43R"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40SQp0o"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44lUYmU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hueeov"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44r5tWe"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44lwf21"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42dX7zL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44l1iLs"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p1X7Uy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p1jw4z"
     },
     {
      "type": "plain",
      "text": "\n\n @799\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51myi+sPEoL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Headphones, Earbuds & Accessories \n                Headphones \nPryce799 \nDicos78 \n\n❌Regular Price  : ₹  2999  /- 😱"
     }
    ]
   },
   {
    "id": 20190,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683092473",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1399",
    "dicos": "32",
    "catagory": "Tools",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Kleeno by Cello Hi Clean Deluxe Spin Mop with Bucket, Violet, Large✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p4sLRv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LQGF2D"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lmtkxo"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vpvi4A"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lto1fD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NwEwdu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LqtVxZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Nw54vk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ALPzb0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ALPzb0"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1399"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51kPCpI1kfL._SX466_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Tools"
     },
     {
      "type": "plain",
      "text": " & Home Improvement\n\n                Home Improvement \n                Cleaning Supplies \n                Mopping Supplies \nPryce1399 \nDicos32 \n\n❌Regular Price  : ₹   1899  /- 😱"
     }
    ]
   },
   {
    "id": 20191,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683092578",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "7499",
    "dicos": "53",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Kodak 80 cm (32 Inches) HD Ready LED TV Kodak 32HDX900S (Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NyWjRl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NBs3oS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Ny6Yfa"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LMkJWe"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40Tr6LP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VyIIeL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LOCUuk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NAgeQ0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HAf3Mm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HAwJHY"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@7499"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/811tmylJsgL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Home Theater, TV & Video \n                Televisions \nPryce7499 \nDicos53 \n\n❌Regular Price  : ₹   12999   /- 😱"
     }
    ]
   },
   {
    "id": 20192,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683092705",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "15990",
    "dicos": "20",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Samsung Galaxy Buds2 Pro, Bluetooth Truly Wireless in Ear Earbuds with Noise Cancellation (Graphite, with Mic)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lto9f7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lqo77K"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p61KNi"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AOw6GM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40YzrxJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41VUS3V"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LOBno9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42cZ3Yw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nlYnkU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LOCtQR"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@15990"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61Qqg+T8nsL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Headphones, Earbuds & Accessories \n                Headphones \nPryce15990 \nDicos20 \n\n❌Regular Price  : ₹   18999  /- 😱"
     }
    ]
   },
   {
    "id": 20193,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683092831",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "299",
    "dicos": "NaN",
    "catagory": "Grocery",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Cadbury Fuse Fit Chocolate Snack Bar with Cranberries and Nuts,41g Pack of 6✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40WZ1TS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Nw5BNQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Ls7WHd"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NQ2dxN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p4MNvf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3niOEMr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41Z5O0r"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LOZHGr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p1XnTw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42glJY7"
     },
     {
      "type": "plain",
      "text": "\n\n @299\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71qn8IqrjtL._SX679_PIbundle-6,TopRight,0,0_AA679SH20_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Grocery"
     },
     {
      "type": "plain",
      "text": " & Gourmet Foods\n\n                Grocery & Gourmet Foods \n                Snacks & Sweets \n                Snack Foods \nPryce299 \nDicosNaN"
     }
    ]
   },
   {
    "id": 20194,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683092957",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1999",
    "dicos": "75",
    "catagory": "Watches",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Vibez by Lifelong Smartwatch for women Metal Strap & 1.28\" HD Display, Bluetooth Calling, Multiple Watch Faces, Health Tracker, Sports Modes & free silicone strap (Emerald, Gold)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42kMRFe"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nsgkOP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HyurJm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42n3PDb"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41Z2bYy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42lZZdt"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44o5YQv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44lVrFG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44lgNmy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3npm0Jq"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1999"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51PIkZh7IhL._SX300_SY300_QL70_FMwebp_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Watches"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Wearable Technology \n                 Smart Watches \nPryce1999 \nDicos75 \n\n❌Regular Price  : ₹  4999   /- 😱"
     }
    ]
   },
   {
    "id": 20195,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683093067",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "9999",
    "dicos": "52",
    "catagory": "Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Honor PAD X8 25.65 cm (10.1 inch) FHD Display, 3GB RAM, 32GB Storage, Mediatek MT8786, Android 12, Tuv Certified Eye Protection, Up to 14 Hours Battery WiFi Tablet, Blue Hour✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44mnk0a"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p3kIEs"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p27GHk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44mNYGk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VpipaW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ANEAhp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3njqgdA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VsZgop"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AMgXWw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40XXdKe"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@9999"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/710G-VKcgtL._SY450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Computers & Accessories \n                2021 \n                 Coupon eligible tablets \nPryce9999 \nDicos52 \n\n❌Regular Price  : ₹ 15999    /- 😱"
     }
    ]
   },
   {
    "id": 20196,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683093194",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "899",
    "dicos": "55",
    "catagory": "Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Artis 65Watt Universal Laptop Adapter with 8 Interchangeable Connector pins (Power Cord Included) Compatible with Dell/HP/Lenovo/ASUS/Acer/Samsung/Compaq/IBM/Toshiba✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nrFtcv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3noyvoB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41VVxSX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3niP9pN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HuWYzm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p66Bhr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Nw68PQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NtnsoM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AQ3hd0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40XyG8e"
     },
     {
      "type": "plain",
      "text": "\n\n @899\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71ZOXYbsLiL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Computers & Accessories \n                Accessories & Peripherals \n                Laptop Accessories \nPryce899 \nDicos55 \n\n❌Regular Price  : ₹  1599    /- 😱"
     }
    ]
   },
   {
    "id": 20197,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683093320",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "264",
    "dicos": "47",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Tosaa Non-Stick 12 Cavity Appam Patra with Stainless Steel Lid- Back✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lmu6dM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LO8Vmd"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/413t2Bk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40XDoT9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HyuV28"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42gw1HM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40XDxWH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LOs33s"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44l36Ef"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HxUBvR"
     },
     {
      "type": "plain",
      "text": "\n\n @264\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61Vb6LWjiJL._SX466_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Dining \n                Cookware \nPryce264 \nDicos47 \n\n❌Regular Price  : ₹   500   /- 😱"
     }
    ]
   },
   {
    "id": 20198,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683093423",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "639",
    "dicos": "68",
    "catagory": "Health",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Lifelong Electric Toothbrush with Free Clove Dental Health Plan for Kids (3+ Years) with Animated sticker for Designing your Toothbrush | 2 Extra Soft Brush Heads|2 min Smart Timer|Rotary Toothbrush Electric Toothbrush(1 year warranty, Pink, LLDC81)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42kHjKP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p1VRAV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41PRTK9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NAh6Eg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lqp1RG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LMNUbM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LOCjsF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41ZD1ZB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42n4u7D"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42m0mVp"
     },
     {
      "type": "plain",
      "text": "\n\n @639\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/41PjhUJ923L._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Health"
     },
     {
      "type": "plain",
      "text": " & Personal Care\n\n                Health & Personal Care \n                Oral Care \n                Toothbrushes & Accessories \nPryce639 \nDicos68 \n\n❌Regular Price  : ₹   1499   /- 😱"
     }
    ]
   },
   {
    "id": 20199,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683093548",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "999",
    "dicos": "82",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Boult Audio Z20 True Wireless in Ear Earbuds with Zen ENC Mic, 40H Playtime, Type-C Fast Charging, Made in India, 10mm Rich Bass Drivers, Environmental Noise Cancellation, IPX5 Ear Buds TWS (Green) ✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42jUgow"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HuXz44"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HyHdHU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44lxA93"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/410vl8n"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/4118hWO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LRCDXO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LODl85"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44znK3E"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LQzpDy"
     },
     {
      "type": "plain",
      "text": "\n\n @999\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71GFeaOKepL._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Headphones, Earbuds & Accessories \n                Headphones \nPryce999 \nDicos82 \n\n❌Regular Price  : ₹ 3999    /- 😱"
     }
    ]
   },
   {
    "id": 20200,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683093672",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "3999",
    "dicos": "73",
    "catagory": "Health",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Amazon Basics Corded Electric Massager for Foot with Heat Function, White & Gray✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44znP7s"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41WUPEY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NBsT50"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VnUXLb"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VpwAws"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3APd3fA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Ny7d9T"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p58XNS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AMhhVe"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AOwViQ"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@3999"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71zcB-ZCQtL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Health"
     },
     {
      "type": "plain",
      "text": " & Personal Care\n\n                Health & Personal Care \n                Health Care \n                Massage & Relaxation \nPryce3999 \nDicos73 \n\n❌Regular Price  : ₹   9999  /- 😱"
     }
    ]
   },
   {
    "id": 20201,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683093777",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "499",
    "dicos": "80",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   PTron Tangentbeat in-Ear Bluetooth 5.0 Wireless Headphones with Mic, Deep Bass, 10mm Drivers, Clear Calls, Snug-Fit, Fast Charging, Magnetic Buds, Voice Assistant & IPX4 Wireless Neckband (Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AM5G8P"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/410FFgC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40SROEc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LODrfX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/4118ucy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41XWxG5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3novDrU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nqdVnQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p8w1ek"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40YAzBt"
     },
     {
      "type": "plain",
      "text": "\n\n @499\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51K8TkvtjgL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Headphones, Earbuds & Accessories \n                Headphones \nPryce499 \nDicos80 \n\n❌Regular Price  : ₹  1699  /- 😱"
     }
    ]
   },
   {
    "id": 20202,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683093905",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "499",
    "dicos": "83",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Furniture Cafe U Shaped Engineered Wood Wall Shelf for Living Room Stylish Wooden Floating for Room Wall Home Storage Racks Organizer and Book Shelves Bookcase for Study Room(Set of 3, Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LqvFY3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p63dDi"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LODBUB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40XYgKa"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LQQDB4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40YAQEv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44l38fk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44lhWum"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Nt3ASJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Ls9np5"
     },
     {
      "type": "plain",
      "text": "\n\n @499\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/81+zItJ5K2L._SX466_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Home & Kitchen \n                Furniture \n                Living Room Furniture \nPryce499 \nDicos83 \n\n❌Regular Price  : ₹   1999  /- 😱"
     }
    ]
   },
   {
    "id": 20203,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683094031",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "249",
    "dicos": "80",
    "catagory": "Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   AmazonBasics iPad Tablet Sleeve Case with Front Pocket, 10 Inch, Grey✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42kN6jU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41XWOc5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HyhU8A"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LQQOfI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3npnkfm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VsMoia"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AMhLuw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44pS7cq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p1osGr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AM67jt"
     },
     {
      "type": "plain",
      "text": "\n\n @249\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/A1mM+KY30eL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Computers & Accessories \n                Accessories & Peripherals \n                Tablet Accessories \nPryce249 \nDicos80 \n\n❌Regular Price  : ₹   999  /- 😱"
     }
    ]
   },
   {
    "id": 20204,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683094138",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "2599",
    "dicos": "46",
    "catagory": "Sports",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   VECTOR 91 Space Fighter 16T Red Single Speed 10.5 Inch Frame - Unisex Kids Cycle✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VsMt5s"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VuZD1Y"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VtdVAb"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/423Hniv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LtYNh4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VsMPci"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vteeej"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p4Oitn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AUwWBL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LtpNgN"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@2599"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51VUbUBIuiL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Sports"
     },
     {
      "type": "plain",
      "text": ", Fitness & Outdoors\n\n                Sports, Fitness & Outdoors \n                Cycling \n                Kids' Cycles & Accessories \nPryce2599 \nDicos46 \n\n❌Regular Price  : ₹   3999   /- 😱"
     }
    ]
   },
   {
    "id": 20205,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683094262",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "2446",
    "dicos": "30",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Duracell Power Bank 20000 mAh, Portable Charger, USB C/Micro USB Input, USB A/USB C Output, Fast Charge Technology, 22.5W Power Delivery for Smartphones, Tablets, Headphones and USB-Powered Devices✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AMi7Bm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3now8Ci"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LRssT3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LObOn1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LP9ITZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LP2G1u"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HAhfDA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p63TZm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LOUxKp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41Z41sq"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@2446"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/41n-7jjv07L._SX300_SY300_QL70_FMwebp_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Mobiles & Accessories \n                Mobile Accessories \nPryce2446 \nDicos30 \n\n❌Regular Price  : ₹  2999    /- 😱"
     }
    ]
   },
   {
    "id": 20206,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683094365",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "299",
    "dicos": "67",
    "catagory": "Office",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Amazon Basics Fine Permanent Markers, Assorted Colours, Pack of 24✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/410uZyD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Nxn6xe"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oXZZlj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LqwlN5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44zp7PQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lw75Fg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42kwDMi"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AMBkCT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HyMrmC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HAOk2h"
     },
     {
      "type": "plain",
      "text": "\n\n @299\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51QOvarcYcL._SY300_SX300_QL70_FMwebp_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Office"
     },
     {
      "type": "plain",
      "text": " Products\n\n                Office Products \n                Office Paper Products \n                Paper \nPryce299 \nDicos67 \n\n❌Regular Price  : ₹   899   /- 😱"
     }
    ]
   },
   {
    "id": 20207,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683094493",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "3499",
    "dicos": "56",
    "catagory": "Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Noise Fit Halo with Leather Strap, Bluetooth Calling Round Dial Smart Watch, 1.43\" AMOLED Display, Premium Metallic Build, Always on Display, Smart Touch Tech, Health Suite-Classic Black✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nowDwa"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AOypcU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Ny7b1Z"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NxYaWp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oXLfD3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/4256iCw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VtxfwQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40ZBRvO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p6PxIw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LtqpmB"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@3499"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71985jPzXIL._SY450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Electronics \n                Wearable Technology \n                 Smart Watches \nPryce3499 \nDicos56 \n\n❌Regular Price  : ₹  6999   /- 😱"
     }
    ]
   },
   {
    "id": 20208,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683094617",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "249",
    "dicos": "81",
    "catagory": "Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Tukzer Fully Foldable Tabletop Desktop Tablet Mobile Stand Holder - Angle & dicos Adjustable for Desk, Cradle, Dock, Compatible with Smartphones & Tablets (White)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/423IeQf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HyMFKu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LPaj8b"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nsikXl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HAqN1A"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nqfnqi"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41euMYN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41euQaZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NujXOL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42fxcqO"
     },
     {
      "type": "plain",
      "text": "\n\n @249\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71EJ-EW2scL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Electronics \n                Mobiles & Accessories \n                Mobile Accessories \nPryce249 \nDicos81 \n\n❌Regular Price  : ₹  999    /- 😱"
     }
    ]
   },
   {
    "id": 20209,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683094720",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "180",
    "dicos": "50",
    "catagory": "Beauty",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   BoroPlus Aloe Vera Gel with Green Tea 100% Organic for Skin & Hair, Rich in Vitamin E and Antiseptic Herbs for Smooth, Nourished and Moisturised Skin, 200ml✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LMNu5a"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42fAzht"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42hiOyb"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NxnI60"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44lj80O"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44psp8g"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44ljkx4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vr38q0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hy2dOP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HAKAxP"
     },
     {
      "type": "plain",
      "text": "\n\n @180\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61QLnX57E1L._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Beauty"
     },
     {
      "type": "plain",
      "text": "\n\n                Beauty \n                Skin Care \n                Face \nPryce180 \nDicos50 \n\n❌Regular Price  : ₹  360   /- 😱"
     }
    ]
   },
   {
    "id": 20210,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683094828",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1099",
    "dicos": "39",
    "catagory": "Health",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   HealthSense Electric Toothbrush Rechargeable Sonic Brush for Adult | 4 Brush Heads | IPX7 Waterproof | 5 Operational Modes | 1 Year Warranty – Clean-Care ET 730✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ARdPZz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3APeSZY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LOF4dz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hu7ZRl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44AJ20T"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LOFaBX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41WCUOp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41WXcYo"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vv0O1o"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VpPJyi"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1099"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71Qmp-rNVgL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Health"
     },
     {
      "type": "plain",
      "text": " & Personal Care\n\n                Health & Personal Care \n                Oral Care \n                Toothbrushes & Accessories \nPryce1099 \nDicos39 \n\n❌Regular Price  : ₹  1399   /- 😱"
     }
    ]
   },
   {
    "id": 20211,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683094952",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "2149",
    "dicos": "43",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Polycab Aery 400 mm Pedestal Fan(Sky Blue)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LpGuJX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ATaQzy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p4QukB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ANHyT5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hu81bV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LryqIO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HzkueN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AOT40E"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NymqYB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LRF4tq"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@2149"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61HJ0zv9OYL._SX466_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Heating, Cooling & Air Quality \n                Fans \nPryce2149 \nDicos43 \n\n❌Regular Price  : ₹  3499    /- 😱"
     }
    ]
   },
   {
    "id": 20212,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683095052",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "699",
    "dicos": "42",
    "catagory": "Sports",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Protoner SP3X4 Weight Lifting Plates 12 kgs, 3kg x 4✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40YCtlB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40Tm1TG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LP2XSb"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NxoeAY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42fBuyr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40VgY53"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44fF0uq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p4Ut0n"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/414Cbtf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44ilZYx"
     },
     {
      "type": "plain",
      "text": "\n\n @699\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51z6tNDFvUL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Sports"
     },
     {
      "type": "plain",
      "text": ", Fitness & Outdoors\n\n                Sports, Fitness & Outdoors \n                Exercise & Fitness \n                Strength Training Equipment \nPryce699 \nDicos42 \n\n❌Regular Price  : ₹  1099  /- 😱"
     }
    ]
   },
   {
    "id": 20213,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683095156",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1699",
    "dicos": "62",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Hindware Smart Appliances Caeli Bianco Star rated ceiling Fan 1200MM 425 RPM Energy Efficient Silent Air Delivery Fan for Home comes with 52 W copper motor and aerodynamic blades✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41Z8UBB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p3nWI4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AOzAsQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B73zwx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AM7VsL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p69xdX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3noBHAB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/410IhLs"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Ny5DVp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41YoZaO"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1699"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51AdmLapwmL._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Heating, Cooling & Air Quality \n                Fans \nPryce1699 \nDicos62 \n\n❌Regular Price  : ₹  3499   /- 😱"
     }
    ]
   },
   {
    "id": 20214,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683095262",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "199",
    "dicos": "89",
    "catagory": "Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   tizum Fully Foldable Tablet Stand Holder with Adjustable Angles & Scratch-Proof, Anti-Slip Rubber Pads, Anodized Aluminium Desktop Stand for All iPhones, Kindle, iPads, Tablets & Smartphones (Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p0XH58"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42e19Ip"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vt0fVJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41VYJ0T"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VpQyXU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lv9Ab7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AQ6w4a"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VoiR9j"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nmDjL5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nwgapz"
     },
     {
      "type": "plain",
      "text": "\n\n @199\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/511FME7c1+L._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Computers & Accessories \n                Accessories & Peripherals \n                Tablet Accessories \nPryce199 \nDicos89 \n\n❌Regular Price  : ₹  999   /- 😱"
     }
    ]
   },
   {
    "id": 20215,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683095388",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1199",
    "dicos": "44",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Larah by Borosil Twilight Silk Series Opalware Dinner Set, 19 Pieces, White✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LOWyWZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Ny5PUD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LOvmaS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LRFDn2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42kQOty"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Ny8UV1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oSdxib"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NDBK6t"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Nxp9kU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44q2flu"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1199"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71g98+4oB5L._SX466_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Dining \n                Tableware \nPryce1199 \nDicos44 \n\n❌Regular Price  : ₹   1899  /- 😱"
     }
    ]
   },
   {
    "id": 20216,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683095510",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "899",
    "dicos": "85",
    "catagory": "Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   boAt Wave Prime47 Smart Watch with 1.69\" HD Display, 700+ Active Modes, ASAP Charge, Live Cricket Scores, Crest App Health Ecosystem, HR & SpO2 Monitoring(Royal Blue)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VpmcFc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44lZ3re"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nm2kpK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AM8SkP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AOTUKQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HyJ1Aw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40ZGaHy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LOX3jP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lv3cR5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LuTFJO"
     },
     {
      "type": "plain",
      "text": "\n\n @899\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61c1JhpcwWL._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Electronics \n                Wearable Technology \n                 Smart Watches \nPryce899 \nDicos85 \n\n❌Regular Price  : ₹  3999   /- 😱"
     }
    ]
   },
   {
    "id": 20217,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683095634",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "2299",
    "dicos": "79",
    "catagory": "Luggage",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   AmazonBasics 24-Inch, Navy Blue✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3np0zIp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VtzMXS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HuiGni"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LtIeSp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LplO4V"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AP4Isk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/411MWwB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AOJHOh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nmE46T"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LPVd2i"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@2299"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/81j53xwDQiL._SY450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Luggage"
     },
     {
      "type": "plain",
      "text": " & Bags\n\n                Bags, Wallets and Luggage \n                Luggage \n                 Suitcases & Trolley Bags \nPryce2299 \nDicos79 \n\n❌Regular Price  : ₹  6999   /- 😱"
     }
    ]
   },
   {
    "id": 20218,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683095736",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "7099",
    "dicos": "53",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   PHILIPS Audio TAB5305 2.1 CH 140W Bluetooth Soundbar with Wireless subwoofer, Multi-Connectivity Option with Supporting USB, AUX, FM & Remote Control (Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LOwhIm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p7p9hl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p8zMAs"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Nzg0Zo"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Nx8rCg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NBc8H9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42kQQBY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40Y1Io6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42jmIH2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42jYv3q"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@7099"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51NYxv-hCFL._SY450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Home Audio \n                Speakers \nPryce7099 \nDicos53 \n\n❌Regular Price  : ₹  13999   /- 😱"
     }
    ]
   },
   {
    "id": 20219,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683095861",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "799",
    "dicos": "68",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   boAt Rockerz 245v2 Bluetooth Wireless in Ear Earphones with Upto 8 Hours Playback, 12mm Drivers, IPX5, Magnetic Eartips, Integrated Controls and Lightweight Design with Mic (Navy Blue)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42kLF4D"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NQ6QYH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p2bSqy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3njvw0O"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NybR80"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ATda9K"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44olgF1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AKoSnn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ATdd5q"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40Y1YU6"
     },
     {
      "type": "plain",
      "text": "\n\n @799\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/5160RpPgKrS._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Headphones, Earbuds & Accessories \n                Headphones \nPryce799 \nDicos68 \n\n❌Regular Price  : ₹  1699   /- 😱"
     }
    ]
   },
   {
    "id": 20220,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683095985",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "899",
    "dicos": "80",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   boAt Airdopes Atom 81 True Wireless Earbuds with Upto 50H Playtime, Quad Mics ENx™ Tech, 13MM Drivers, Beast™ Mode(50ms Super Low Latency), ASAP™ Charge, BT v5.3(Pearl White)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ALuxJI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40ViDYl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HCM4Yn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40YETkb"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3np1it7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oUyJUK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HukIDR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3npqRKE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/429E0Gu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41WFwfb"
     },
     {
      "type": "plain",
      "text": "\n\n @899\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/41M7HyZET4L._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Headphones, Earbuds & Accessories \n                Headphones \nPryce899 \nDicos80 \n\n❌Regular Price  : ₹   3999   /- 😱"
     }
    ]
   },
   {
    "id": 20221,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683096110",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "999",
    "dicos": "78",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   boAt Airdopes 141 Bluetooth Truly Wireless in Ear Earbuds with mic, 42H Playtime, Beast Mode(Low Latency Upto 80ms) for Gaming, ENx Tech, ASAP Charge, IWP, IPX4 Water Resistance (Bold Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LOwziq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ntONwj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nhkLw1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nhkMjz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B75i4Z"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3njvRk6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p4WpWH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lubto7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AMa2wH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AL6o6a"
     },
     {
      "type": "plain",
      "text": "\n\n @999\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51HBom8xz7L._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Headphones, Earbuds & Accessories \n                Headphones \nPryce999 \nDicos78 \n\n❌Regular Price  : ₹  2999  /- 😱"
     }
    ]
   },
   {
    "id": 20222,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683096238",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "399",
    "dicos": "73",
    "catagory": "Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Tukzer Extended Gaming Mouse Pad| Large Size (795 x 298 x 3.45mm) | Stitched Embroidery Edges| Non-Slip Rubber Base|Keyboard Mouse Pad for Office Home (Periodic Table), Black✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p5cXOo"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LOIUTS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nlJI9v"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LPTfiz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lu3l78"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oX7dG9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AMmBYI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40ZHsSU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40SWpGs"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42h0vsV"
     },
     {
      "type": "plain",
      "text": "\n\n @399\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71PeC4vSZfL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Computers & Accessories \n                Accessories & Peripherals \n                Keyboards, Mice & Input Devices \nPryce399 \nDicos73 \n\n❌Regular Price  : ₹  1299    /- 😱"
     }
    ]
   },
   {
    "id": 20223,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683096342",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "6499",
    "dicos": "70",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   boAt Aavante Bar Orion Soundbar with 160W RMS Signature Sound, 2.1 CH, BT v5.3, Multi-Compatibility Modes, Wired Subwoofer, EQ Controls, Dynamic LEDs & Master Remote Control(Premium Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44hihhL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/429EKeK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p7pXmn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p43dUz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vt4FMd"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vo01iF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44oHqa5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Nychv6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41Y1azX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42kMfzl"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@6499"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/41XOA-lcsCL._SX300_SY300_QL70_FMwebp_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Home Audio \n                Speakers \nPryce6499 \nDicos70 \n\n❌Regular Price  : ₹  15999    /- 😱"
     }
    ]
   },
   {
    "id": 20224,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683096447",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "799",
    "dicos": "82",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Boult Audio Probass Curve Bluetooth Wireless in Ear Earphones with Mic with Ipx5 Water Resistant, 12H Battery Life & Extra Bass (Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AKpt8B"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AOKoav"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44obSB9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/4259C0s"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44ki8tL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3noE9qN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NybTfX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HznHLn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HwvCsw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HAueW2"
     },
     {
      "type": "plain",
      "text": "\n\n @799\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61dzS26+TuL._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Headphones, Earbuds & Accessories \n                Headphones \nPryce799 \nDicos82 \n\n❌Regular Price  : ₹  2999    /- 😱"
     }
    ]
   },
   {
    "id": 20225,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683096551",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "999",
    "dicos": "71",
    "catagory": "Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Oakter Mini UPS for 12V WiFi Router Broadband Modem | Backup Upto 4 Hours | WiFi Router UPS Power Backup During Power Cuts | UPS for 12V Router Broadband Modem | Current Surge & Deep Discharge Protection✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40ToOfC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3noRLlW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41WG0C1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LMQtKU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NwccYC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p4Topv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LpJNkl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p10aMW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Nzh2Vg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LuXY7K"
     },
     {
      "type": "plain",
      "text": "\n\n @999\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61Mbl0e228L._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Computers & Accessories \n                 Networking Devices \nPryce999 \nDicos71 \n\n❌Regular Price  : ₹  2999   /- 😱"
     }
    ]
   },
   {
    "id": 20226,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683096656",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "149",
    "dicos": "80",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   PTron Pride Lite HBE (High Bass Earphones) in Ear Wired Earphones with Mic, 10mm Powerful Driver for Stereo Audio, Noise Cancelling Headset with 1.2m Tangle-Free Cable & 3.5mm Aux - (Blue)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p1pUJ5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VtB05q"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vy7yeU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LsrkUj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LohVgF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LMRt1s"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ANKkaV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42eZ2U8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LQUZIq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44zt6Mi"
     },
     {
      "type": "plain",
      "text": "\n\n @149\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51yB+3-eJwL._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Headphones, Earbuds & Accessories \n                Headphones \nPryce149 \nDicos80 \n\n❌Regular Price  : ₹   699  /- 😱"
     }
    ]
   },
   {
    "id": 20227,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683096781",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "379",
    "dicos": "47",
    "catagory": "Tools",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Presto! Spin Mop Rod + Refill (Blue)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44pw1qQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40X5w9e"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vr6WHO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40ZIf6k"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oY4fRP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44hiQrT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Ny29SR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41WrB8Z"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p3r0UA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/423LJWT"
     },
     {
      "type": "plain",
      "text": "\n\n @379\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/31EnPlQ2mfL._SX300_SY300_QL70_FMwebp_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Tools"
     },
     {
      "type": "plain",
      "text": " & Home Improvement\n\n                Home & Kitchen \n                Home & kitchen | Prime shopping days \n                Prime Units \nPryce379 \nDicos47 \n\n❌Regular Price  : ₹    699 /- 😱"
     }
    ]
   },
   {
    "id": 20228,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683096885",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "21499",
    "dicos": "49",
    "catagory": "Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   PHILIPS 2560 x 1440 Pixels 32 inches LCD Monitor with LED Back Lights, with Quad HD Picture Quality 1.07 Billion Colors, Black (325E8/94)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NycP48"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41YrGcq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nwjyAN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42k0e8U"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44qVP5V"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LuYh2o"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44AMrNd"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/429FLU6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Nuo0dV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Nuo1P1"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@21499"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61GW02uh0AS._SY450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Computers & Accessories \n                2021 \n                 Gaming Monitors \nPryce21499 \nDicos49 \n\n❌Regular Price  : ₹   29999  /- 😱"
     }
    ]
   },
   {
    "id": 20229,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683096991",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "783",
    "dicos": "55",
    "catagory": "Watches",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Escort Analog Black Dial Men's Watch-E-1600-1801 SL.3✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hy0skP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/4200NVr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3noF36F"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nrMp9x"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hzhejh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40TywyF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LQEonK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LOZenv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LPJxNa"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/413auRE"
     },
     {
      "type": "plain",
      "text": "\n\n @783\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71YapThIDzL._UX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Watches"
     },
     {
      "type": "plain",
      "text": "\n\n                Watches \n                Men \n                 Wrist Watches \nPryce783 \nDicos55 \n\n❌Regular Price  : ₹   1499   /- 😱"
     }
    ]
   },
   {
    "id": 20230,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683097115",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "699",
    "dicos": "73",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   PTron Newly Launched Basspods P251 Bluetooth V5.1 Wireless Earphones, 13mm Drivers, Stereo Sound & Stereo Calls, 28Hrs Playtime, Touch Control TWS Earbuds, Voice Assist & IPX4 Water Resistant (Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nlDkz5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40YGhTV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vpozb4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LuW8E4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41YVRQM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VsRJGe"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LqvgVC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41WH60B"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HyBYrC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VnLuUd"
     },
     {
      "type": "plain",
      "text": "\n\n @699\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/511rpyyLdyL._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Headphones, Earbuds & Accessories \n                Headphones \nPryce699 \nDicos73 \n\n❌Regular Price  : ₹  1899   /- 😱"
     }
    ]
   },
   {
    "id": 20231,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683097239",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "65990",
    "dicos": "35",
    "catagory": "Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Dell Gaming G15 5525, AMD R5-6600H, 8GB, 512GB SSD, RTX 3050 (4GB GDDR6), 15.6\" (39.62Cms) FHD WVA AG 120Hz 250 nits, Windows 11 + MSO'21, Phantom Grey with speckles, 15 Month Mcafee, 2.51Kgs✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LvOj0C"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AQ9p50"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LPJGjG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LOxQpI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LP650n"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nrFahW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LPj7Lm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HAmF1o"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40Y3w0m"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/410BcdR"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@65990"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61K9AyvhgmL._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Computers & Accessories \n                Laptops \n                 Traditional Laptops \nPryce65990 \nDicos35 \n\n❌Regular Price  : ₹  79999  /- 😱"
     }
    ]
   },
   {
    "id": 20232,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683097366",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "6999",
    "dicos": "65",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   boAt Aavante Bar 1680D Bluetooth Soundbar with Dolby Audio, 120W RMS Signature Sound, 2.1 Channel, 3D Surround Sound, Multi-Compatibility and Master Remote Control(Knight Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/413zoAG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41U5sZ0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oX925X"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nrqoYg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44mui/materialSS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nwkpBv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44ryNvt"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AL7H54"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AQ9VQu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hy71nl"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@6999"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61KKXVKicPL._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Home Audio \n                Speakers \nPryce6999 \nDicos65 \n\n❌Regular Price  : ₹  14999   /- 😱"
     }
    ]
   },
   {
    "id": 20233,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683097489",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "899",
    "dicos": "47",
    "catagory": "Health",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   HealthSense Weight Machine for Kitchen, Kitchen Food Weighing Scale for Health, Fitness, Home Baking & Cooking with Bright LCD, Touch Button, Tare Function & 1 Year Warranty – Chef-Mate KS 63✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/429Gm8i"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Nzik2y"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LmABNI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41WsBKh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41XrgmJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/413b3ee"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hwx9ig"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40Y3Z2C"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/429Gsga"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44px4XO"
     },
     {
      "type": "plain",
      "text": "\n\n @899\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71VQ93ago8L._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Health"
     },
     {
      "type": "plain",
      "text": " & Personal Care\n\n                Home & Kitchen \n                Kitchen & Home Appliances \n                Small Kitchen Appliances \nPryce899 \nDicos47 \n\n❌Regular Price  : ₹  1399    /- 😱"
     }
    ]
   },
   {
    "id": 20234,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683097613",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "3199",
    "dicos": "80",
    "catagory": "Luggage",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Amazon Basics Polycarbonate Hard 30 cms Luggage (N989_Blue)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44l9jjw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vtji2j"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VswqV5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41W2nI7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B77t8F"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3noSXpq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lu5CiG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LqwujG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AMKkI7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AKr7qN"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@3199"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/81-0xQyJVaL._SY879_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Luggage"
     },
     {
      "type": "plain",
      "text": " & Bags\n\n                Bags, Wallets and Luggage \n                Luggage \n                 Suitcases & Trolley Bags \nPryce3199 \nDicos80 \n\n❌Regular Price  : ₹ 9999    /- 😱"
     }
    ]
   },
   {
    "id": 20235,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683097717",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1999",
    "dicos": "82",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   beatXP Vega 1.43\" AMOLED 466 * 466px Display One-Tap BT 5.2 Calling AI Voice Assistant Smartwatch (Electric Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vr8bGY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AMGfDR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p8Cy8Q"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ALXgOq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44lF6Rj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VqK8I6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40X6Mcs"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42dlJYK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LOyyDo"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VvbhJZ"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1999"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71FP8dVTgVL._SY450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Wearable Technology \n                 Smart Watches \nPryce1999 \nDicos82 \n\n❌Regular Price  : ₹  6999   /- 😱"
     }
    ]
   },
   {
    "id": 20236,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683097842",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1299",
    "dicos": "68",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   ZEBRONICS Sound Bomb X1 3-in-1 Wireless Bluetooth v5.0 In Ear Earbuds, Speaker Combo with 30 Hour Backup, Built-in LED Torch, Call Function, Voice Asst, Type C and Splash Proof Portable Design (Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LQWxlI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/429GNPY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41ZSZTN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oZUT8e"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41YWJow"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LpoLT3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LOyFPk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44mVrFm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VppJTY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oX9P6V"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1299"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/613rCuWFCnL._SX450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Home Audio \n                Speakers \nPryce1299 \nDicos68 \n\n❌Regular Price  : ₹  2999   /- 😱"
     }
    ]
   },
   {
    "id": 20237,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683097966",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "6999",
    "dicos": "65",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   boAt Aavante Bar 1680D Bluetooth Soundbar with Dolby Audio, 120W RMS Signature Sound, 2.1 Channel, 3D Surround Sound, Multi-Compatibility and Master Remote Control(Knight Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44qWYdJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Nycfn1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ALIeIL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hv5b6E"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3njya6K"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NzwRv0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Nz4aOP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42kUV8Y"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LmB5n0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lue9SH"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@6999"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61KKXVKicPL._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Home Audio \n                Speakers \nPryce6999 \nDicos65 \n\n❌Regular Price  : ₹  14999    /- 😱"
     }
    ]
   },
   {
    "id": 20238,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683098068",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "466",
    "dicos": "45",
    "catagory": "Beauty",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Dove Renewing Raspberry Body Wash with Lime Pump Bottle | Go Fresh Nourishing Shower Gel | Gentle & Mild Body Cleanser for Nourished & Smooth Skin, 1L✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p1v2N9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41ZdcsH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Nwegjk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41ZKsQv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p5eTXa"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Huczz1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41W38kr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LojCux"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lk7Xgc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LstFyz"
     },
     {
      "type": "plain",
      "text": "\n\n @466\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/510aY3T25DL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Beauty"
     },
     {
      "type": "plain",
      "text": "\n\n                Beauty \n                Bath & Body \n                Cleansers \nPryce466 \nDicos45 \n\n❌Regular Price  : ₹  799  /- 😱"
     }
    ]
   },
   {
    "id": 20239,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683098189",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1799",
    "dicos": "NaN",
    "catagory": "Clothing",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Indigo Nation Men's Slim Shirt✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LrboSj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HAnKGu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LstRhh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40YHxGD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vy9zYw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vt4lgz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nm5Bp2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/410MGxY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/410CuWf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42jh8UZ"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1799"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/81BJb7fe8IL._UX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Clothing"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Clothing & Accessories \n                Men \n                Shirts \nPryce1799 \nDicosNaN \n\n❌Regular Price  : ₹   1399   /- 😱"
     }
    ]
   },
   {
    "id": 20240,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683098312",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "399",
    "dicos": "77",
    "catagory": "All",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   ADOFYS Helmet Chin Mount with Mobile Holder, Motorcycle Helmet Strap Compatible with GoPro Hero 9 8 7 6 5 4 3+ 3 SJCAM and Mobile Phones✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HySYOa"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3noH1nz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/4174Cak"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44loVU6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/410MNcS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41X2JhC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/413AJHI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VqKVJ3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3niWcPh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VpDt0M"
     },
     {
      "type": "plain",
      "text": "\n\n @399\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51v1NI15HzL._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#All"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Cameras & Photography \n                Accessories \nPryce399 \nDicos77 \n\n❌Regular Price  : ₹  1499   /- 😱"
     }
    ]
   },
   {
    "id": 20241,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683098419",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "8770",
    "dicos": "47",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   duroflex LiveIn 2 in 1 Reversible Soft & Firm Sides Roll Pack Foam Mattress Queen Size (75X60X5 inches)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44pYzjE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oY68xT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Nxb1Is"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42itIUp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HAQNd7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NuLO1t"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/429HngC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42d7L9a"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44AO7X1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/411ghqO"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@8770"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61FizTPA4VS._SX522_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Home & Kitchen \n                Furniture \n                Bedroom Furniture \nPryce8770 \nDicos47 \n\n❌Regular Price  : ₹   14999  /- 😱"
     }
    ]
   },
   {
    "id": 20242,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683098541",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "551",
    "dicos": "35",
    "catagory": "Beauty",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Bella Vita Luxury Man Perfume Gift Set 4x20 ML for Men with Dominus, Oud, CEO, Impact Perfume|Woody, Citrusy Long Lasting EDP & EDC Fragrance Scent✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40TreLe"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nhJy2Z"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41PZZT3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/4202UIR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44laBen"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/425cElm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40SZAxS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44pFcaI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VsVNGh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LMJ5Oz"
     },
     {
      "type": "plain",
      "text": "\n\n @551\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61W+8YKi2kL._SY450_PIbundle-4,TopRight,0,0_AA450SH20_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Beauty"
     },
     {
      "type": "plain",
      "text": "\n\n                Beauty \n                Fragrance \n                 Eau de Toilette \nPryce551 \nDicos35 \n\n❌Regular Price  : ₹  849   /- 😱"
     }
    ]
   },
   {
    "id": 20243,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683098644",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "99",
    "dicos": "90",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Sounce Cleaning Soft Brush Keyboard Cleaner 5-in-1 Multi-Function Computer Cleaning Tools Kit Corner Gap Duster Keycap Puller for Bluetooth Earphones Lego Laptop AirPods Pro Camera Lens (White)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/414HDMJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41Q05tT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p25Nu6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VyQYeL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41Q09tD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AONgE9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AMcZxh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nwm1ex"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LmBWEe"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Loklff"
     },
     {
      "type": "plain",
      "text": "\n\n @99\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/316GKIDa42L._SX300_SY300_QL70_FMwebp_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Cameras & Photography \n                Accessories \nPryce99 \nDicos90 \n\n❌Regular Price  : ₹  699  /- 😱"
     }
    ]
   },
   {
    "id": 20244,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683098745",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "799",
    "dicos": "58",
    "catagory": "Video",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Ant Esports MKWM2023 Wireless Gaming Keyboard & Mouse Combo with Stylish 104 Membrane Keys and 3D Button Mouse with 1000 DPI Optical Sensor - Black✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AL98Au"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VvcIrR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42guh19"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AOE4zG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AKso13"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AUDbFH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oXaKUV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LRz189"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LQXyu2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42hoCrt"
     },
     {
      "type": "plain",
      "text": "\n\n @799\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/613EdPfD0NL._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Video"
     },
     {
      "type": "plain",
      "text": " Games\n\n                Computers & Accessories \n                Accessories & Peripherals \n                Keyboards, Mice & Input Devices \nPryce799 \nDicos58 \n\n❌Regular Price  : ₹  1499   /- 😱"
     }
    ]
   },
   {
    "id": 20245,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683098873",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "2949",
    "dicos": "53",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Prestige IRIS Plus 750 watt mixer grinder✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/414HNnj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/413BiRQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VqLjr0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p2g1uC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3np4h4N"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ALYDg2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nlFuyH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42k2oFy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42BeCd5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nqmjnk"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@2949"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51HfqyUaHyL._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Home Appliances \n                Small Kitchen Appliances \nPryce2949 \nDicos53 \n\n❌Regular Price  : ₹  4999  /- 😱"
     }
    ]
   },
   {
    "id": 20246,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683098997",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1739",
    "dicos": "44",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Pigeon Ruby 1.8 Litre Single pot (Aluminium) Electric rice cooker✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HW2zz9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hypikk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LP9MmE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/411RQtv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42kW0O4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42hoTL1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40TYHFb"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/425cWIY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41Zcc86"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/4203uq1"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1739"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/31QVVD9P3bL._SX466_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Home Appliances \n                Small Kitchen Appliances \nPryce1739 \nDicos44 \n\n❌Regular Price  : ₹  2699   /- 😱"
     }
    ]
   },
   {
    "id": 20247,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683099122",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "659",
    "dicos": "57",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀  Pigeon 1.5 litre Hot Kettle and Stainless Steel Water Bottle Combo used for boiling Water, Making Tea and Coffee, Instant Noodles, Soup, 1500 Watt with Auto Shut- off Feature - (Silver) ✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p1w9fN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p5fTuo"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44qYfS3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/423OKqb"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HyUaRE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AL9wiq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vpv2CK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vt89ON"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AMHJOr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ANNnjp"
     },
     {
      "type": "plain",
      "text": "\n\n @659\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51VvdjBgB5L._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Home Appliances \n                Small Kitchen Appliances \nPryce659 \nDicos57 \n\n❌Regular Price  : ₹ 1299    /- 😱"
     }
    ]
   },
   {
    "id": 20248,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683099244",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "242",
    "dicos": "60",
    "catagory": "Shoes",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   DAYZ Unisex Kids Soft Flip-Flops Slipper for Boys & Girls (Red, Numeric_5)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nrOMZZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p2gzka"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41U8bSe"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41Wv1Zn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vt5vbV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vu4ZKU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41ZLVpZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vu529y"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/420bvv4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VsyH2z"
     },
     {
      "type": "plain",
      "text": "\n\n @242\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/A1ixtu6VM8L._UY500_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Shoes"
     },
     {
      "type": "plain",
      "text": " & Handbags\n\n                Shoes & Handbags \n                Shoes \n                Boys' Shoes \nPryce242 \nDicos60 \n\n❌Regular Price  : ₹  599  /- 😱"
     }
    ]
   },
   {
    "id": 20249,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683099365",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "392",
    "dicos": "67",
    "catagory": "Shoes",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   1 WALK Comfortable Footwear For Ladies -Fashion Slippers -MP-G300(C)-\n"
     },
     {
      "type": "cashtag",
      "text": "$P"
     },
     {
      "type": "plain",
      "text": "\n-MP-G300(C)-\n"
     },
     {
      "type": "cashtag",
      "text": "$P"
     },
     {
      "type": "plain",
      "text": "\n✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oY7ngx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LOLjOx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NDGR6F"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44pZVee"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VtEbKo"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NyfTxa"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VsyLiP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p51ApB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42d99so"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3np4WTP"
     },
     {
      "type": "plain",
      "text": "\n\n @392\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/81QmmEfyTsL._UY500_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Shoes"
     },
     {
      "type": "plain",
      "text": " & Handbags\n\n                Shoes & Handbags \n                Shoes \n                Women's Shoes \nPryce392 \nDicos67 \n\n❌Regular Price  : ₹  799   /- 😱"
     }
    ]
   },
   {
    "id": 20250,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683099468",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "399",
    "dicos": "79",
    "catagory": "Sports",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   FitBox Sports Resistance and Pull up Band Cross Training Exercise Band for Home Gym Fitness✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VsUNCe"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NwOagc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HyODe4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LrcMV1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lv1VcA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42gETgy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LpNYwx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HASs2l"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HCRy5p"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lwfl8e"
     },
     {
      "type": "plain",
      "text": "\n\n @399\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61p-ax3w1VL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Sports"
     },
     {
      "type": "plain",
      "text": ", Fitness & Outdoors\n\n                Sports, Fitness & Outdoors \n                Exercise & Fitness \n                Strength Training Equipment \nPryce399 \nDicos79 \n\n❌Regular Price  : ₹   1299   /- 😱"
     }
    ]
   },
   {
    "id": 20251,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683099590",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "245",
    "dicos": "81",
    "catagory": "All",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Yashika Womens Printed Georgette Saree Without Blouse✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nqmXkK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hyq7cU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40Xczyz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/411hQFc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/4176xvy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nwmTQl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LzNNiy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VySjSP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VqMk2i"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41YZc2g"
     },
     {
      "type": "plain",
      "text": "\n\n @245\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/81tTM7LbtTL._UY741_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#All"
     },
     {
      "type": "plain",
      "text": "\n\n                Fashion \n                Apparel Steal Deals \n                Apparel_1 \nPryce245 \nDicos81 \n\n❌Regular Price  : ₹  999  /- 😱"
     }
    ]
   },
   {
    "id": 20252,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683099718",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "564",
    "dicos": "81",
    "catagory": "Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   RIATECH Large Size (900mm x 400mm x 2mm) Hexagonal Print Speed Type Extended Gaming Mouse Pad with Stitched Embroidery Edge, Premium-Textured Mouse Mat, Mousepad for Laptop/Computer- Black✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/423PGuH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HyOYgQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vu5B3a"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LqE9hR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VszpNh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VqNBGE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LqEd15"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vo4uBX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AKu62t"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VpFB8M"
     },
     {
      "type": "plain",
      "text": "\n\n @564\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/91pIt7I-aVL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Computers & Accessories \n                Accessories & Peripherals \n                Keyboards, Mice & Input Devices \nPryce564 \nDicos81 \n\n❌Regular Price  : ₹   1999  /- 😱"
     }
    ]
   },
   {
    "id": 20253,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683099843",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "999",
    "dicos": "55",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   AmazonBasics Bluetooth Speaker 5.0 Soundbar with 16W RMS, 2000mAh Battery, Upto 19 Hrs Playtime Aux/USB Port (Blue)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ALKlwb"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nqni70"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HzrMiF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/429IYTE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44oq5y7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hyqmok"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hupb9B"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44lHZS9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44APTr9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40TZPZr"
     },
     {
      "type": "plain",
      "text": "\n\n @999\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/81vIdmhOjxL._SX450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Home Audio \n                Speakers \nPryce999 \nDicos55 \n\n❌Regular Price  : ₹   1699  /- 😱"
     }
    ]
   },
   {
    "id": 20254,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683099967",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "7599",
    "dicos": "73",
    "catagory": "Sports",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Lifelong Bold MTB 26T Mountain Bikes, Disc Brake 21 Speed Gear Cycles, Frame Size: 18 inches| Free Installation Assistance, Ideal for Adults 14+ Years (LLBC2694, Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/417714Q"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NDHzAR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44mxxtw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41WKloN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44zxtaa"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oXcIEN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nmVfoW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42doRUu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44qZmBd"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AKuk9P"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@7599"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71D935sK2bL._SX466_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Sports"
     },
     {
      "type": "plain",
      "text": ", Fitness & Outdoors\n\n                Sports, Fitness & Outdoors \n                Cycling \n                Cycles \nPryce7599 \nDicos73 \n\n❌Regular Price  : ₹  13999   /- 😱"
     }
    ]
   },
   {
    "id": 20255,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683100069",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "212",
    "dicos": "20",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Treo By Milton Handcrafted Designer Bowl, 1 Piece, 870 ml, Floral✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44oqrVt"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44pAidS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44m5Wc4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44pH8ju"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NyggaR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LtyPud"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HApWxI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LPYmPN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42nd0U5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LsiFBt"
     },
     {
      "type": "plain",
      "text": "\n\n @212\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61kCc7foK5L._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Dining \n                Tableware \nPryce212 \nDicos20 \n\n❌Regular Price  : ₹  290    /- 😱"
     }
    ]
   },
   {
    "id": 20256,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683100173",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "245",
    "dicos": "78",
    "catagory": "Clothing",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Satrani Women's Floral Printed Georgette Saree with Blouse Piece✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nsqrmL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3niYcqL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41VvEm0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HA8No5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Ny5T6R"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44mYiOA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NycDSb"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44q8Z2M"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p8FMsY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lv9Uqf"
     },
     {
      "type": "plain",
      "text": "\n\n @245\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71mWFH1roYS._UY741_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Clothing"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Fashion \n                Apparel Steal Deals \n                Apparel_1 \nPryce245 \nDicos78 \n\n❌Regular Price  : ₹  899   /- 😱"
     }
    ]
   },
   {
    "id": 20257,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683100297",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "249",
    "dicos": "75",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Fashion String 144 TC Microfibre Brown Double Bedsheet with 2 King Size Pillow Covers✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HwT6hn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LpPaA1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LPb94M"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AKuXQJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3npvHYk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HxowEe"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44mfOCF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44ohGKX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LMvcku"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LMtLCD"
     },
     {
      "type": "plain",
      "text": "\n\n @249\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/816BSTUdXfL._SY679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Home Furnishing \n                Bedding & Linen \nPryce249 \nDicos75 \n\n❌Regular Price  : ₹  899   /- 😱\n\\"
     }
    ]
   },
   {
    "id": 20258,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683100424",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "998",
    "dicos": "50",
    "catagory": "Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Redgear MT02 Keyboard with LED Modes, Windows Key Lock, Floating & Double Injected Keycaps, Control Knob and Ergonomic Design(Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/4205m21"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44mfV13"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HAqHXA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VpG9LS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p6cnzG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44oNcsh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44hnYfD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p52Rgn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41ZgjAT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LPbnsE"
     },
     {
      "type": "plain",
      "text": "\n\n @998\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71y-CU36kSL._SY450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Computers & Accessories \n                Accessories & Peripherals \n                PC Gaming Peripherals \nPryce998 \nDicos50 \n\n❌Regular Price  : ₹  1599   /- 😱"
     }
    ]
   },
   {
    "id": 20259,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683100549",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "919",
    "dicos": "49",
    "catagory": "Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   SanDisk Ultra Eco USB Flash Drive USB 3.2 Gen 1 128GB, Upto 100MB/s R, 5Y Warranty✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nmJYoz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LZgE1b"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44ohWcT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NtcTlD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nhLMiR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Ntd1BD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Nyhkvy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NBidDt"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oUF8PM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nwKGj4"
     },
     {
      "type": "plain",
      "text": "\n\n @919\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/31vIaz+-2cL._SY300_SX300_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Computers & Accessories \n                External Devices & Data Storage \n                 Pen Drives \nPryce919 \nDicos49 \n\n❌Regular Price  : ₹  1699    /- 😱"
     }
    ]
   },
   {
    "id": 20260,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683100678",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "2798",
    "dicos": "47",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Havells 1200 mm Efficiencia Prime High Speed, BLDC Motor, Energy Efficient with Remote Control Ceiling Fan (Brown)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3noJkXL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hzm6oz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LqFgy3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/410EgXt"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hy4UA3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42h5Znv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oXdBND"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hyezq1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Nxe1EI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/413Dmcy"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@2798"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61B2UNnAg4L._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Heating, Cooling & Air Quality \n                Fans \nPryce2798 \nDicos47 \n\n❌Regular Price  : ₹  4499   /- 😱"
     }
    ]
   },
   {
    "id": 20261,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683100804",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "8784",
    "dicos": "50",
    "catagory": "Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   TOSHIBA Canvio Flex 4TB Portable External HDD - USB-C USB3.0 for Mac, Windows PC, Laptop and Tablet, 3 Years Warranty, External Hard Drive - Silver (HDTX140ASCCA)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LvaxA7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40Xaym8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LMLpVN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LPjgyj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HyS5pc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41Q2Yed"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LOOiGA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42e8N5z"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LzPFI6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LswFLl"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@8784"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/81JW07Y7YuL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Computers & Accessories \n                External Devices & Data Storage \n                 External Hard Disks \nPryce8784 \nDicos50 \n\n❌Regular Price  : ₹   13999  /- 😱"
     }
    ]
   },
   {
    "id": 20262,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683100929",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "2081",
    "dicos": "52",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Havells 1200mm SS 390 Energy Saving Ceiling Fan (Bianco, Pack of 1)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LqFCET"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ANP0xv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ANP6Fn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p28yeW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44rhyuy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LOOtBK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nqowiC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HyrKaw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hzt3WZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ALctzw"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@2081"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51wvyErZdpL._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Heating, Cooling & Air Quality \n                Fans \nPryce2081 \nDicos52 \n\n❌Regular Price  : ₹   3499  /- 😱"
     }
    ]
   },
   {
    "id": 20263,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683101053",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "3443",
    "dicos": "NaN",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Larah by Borosil - Moon Series, Bluebell 21 Pieces Opalware Dinner Set, White✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40ZEXAd"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Nyi1VG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41UakNM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nrJFJm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42mb2TZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44lsw4y"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NGndGO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3njBA9A"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NznJ9Q"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42fGKSG"
     },
     {
      "type": "plain",
      "text": "\n\n @NaN\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/515i-pXNfOL._SX522_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Dining \n                Tableware \nPryce3443<span class=\"a-price-decimal\">.</span> \nDicosNaN \n\n❌Regular Price  : ₹  2499  /- 😱"
     }
    ]
   },
   {
    "id": 20264,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683101178",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "2096",
    "dicos": "43",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Polycab Vital 1200 MM High Speed Premium Ceiling Fan with dual tone finish and 2 year warranty (Matt Snow White)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VtGoFG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/414KQvL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42mbt0z"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42neJc1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hy5QV5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nrRi2n"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VtaGsh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p49tvx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nhr5Uh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AKwmqt"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@2096"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/511aSiUWQFL._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Heating, Cooling & Air Quality \n                Fans \nPryce2096 \nDicos43 \n\n❌Regular Price  : ₹  2999  /- 😱"
     }
    ]
   },
   {
    "id": 20265,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683101302",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1135",
    "dicos": "40",
    "catagory": "Watches",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Fastrack Analog Women's Watch✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42e9uvH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p16fce"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vrdlmk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/4201H4c"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VnQwjz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ntVvT1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NteLe9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VsBsAX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LMwwUu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ANMaIV"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1135"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61dojkGWi2S._UY500_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Watches"
     },
     {
      "type": "plain",
      "text": "\n\n                Fashion \n                Onam 2021 \n                Watches and fashion jewellery \nPryce1135 \nDicos40 \n\n❌Regular Price  : ₹  1599   /- 😱"
     }
    ]
   },
   {
    "id": 20266,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683101406",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1628",
    "dicos": "47",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Larah by Borosil Fern Opalware Dinner Set, 21-Pieces, White✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LLe12F"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ANQ0BL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LMX2x2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AMPAeP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42dc8RC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LPoZ7m"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LP4uHL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42jltaJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42kYEng"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nrww2I"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1628"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/615+DaqKpOL._SX522_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Dining \n                Tableware \nPryce1628 \nDicos47 \n\n❌Regular Price  : ₹   2499  /- 😱"
     }
    ]
   },
   {
    "id": 20267,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683101529",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "899",
    "dicos": "70",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Noise Buds VS201 V2 in-Ear Truly Wireless Earbuds with Dual Equalizer | with Mic | Total 14-Hour Playtime | Full Touch Control | IPX5 Water Resistance and Bluetooth v5.1 (Snow White)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/4201ZIk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44ltfCO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AQBxVC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HxrsAK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/421KKqm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44zApUe"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VpZM6u"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LODZ5g"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VvgFNd"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ARo3sV"
     },
     {
      "type": "plain",
      "text": "\n\n @899\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51SnB1u-RVL._SY879_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Headphones, Earbuds & Accessories \n                Headphones \nPryce899 \nDicos70 \n\n❌Regular Price  : ₹   1999  /- 😱"
     }
    ]
   },
   {
    "id": 20268,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683101656",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "7999",
    "dicos": "NaN",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Livpure GLO PRO++ RO+UV+UF+Taste Enhancer, Water Purifier for Home - 7 L Storage, Suitable for Borewell, Tanker, Municipal Water (Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ATkAtC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40ZiDGL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lvk36p"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/417a39e"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NDKsBH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NAsRKU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p3xjre"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oYaxkp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p2kn4W"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ANMXcR"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@7999"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61eLWPJtv+L._SY606_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Home Appliances \n                Water Purifiers & Accessories \nPryce7999 \nDicosNaN"
     }
    ]
   },
   {
    "id": 20269,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683101781",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "899",
    "dicos": "61",
    "catagory": "Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   ZEBRONICS Zeb-Astra 20 Wireless BT v5.0 Portable Speaker with 10W RMS Output, TWS, 10H Backup Approx, Built in Rechargeable Battery FM Radio, AUX, mSD, USB, Call Function and Dual 52mm Drivers Multi✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/420fgka"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41W8qwj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VtHG3u"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VvgWjd"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lk94MI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VoBQRa"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p17jNg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Loprbn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nrLGFq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LQL2dG"
     },
     {
      "type": "plain",
      "text": "\n\n @899\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71yCNxkT4GL._SY450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Electronics \n                Home Audio \n                Speakers \nPryce899 \nDicos61 \n\n❌Regular Price  : ₹  1999   /- 😱"
     }
    ]
   },
   {
    "id": 20270,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683101907",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1412",
    "dicos": "50",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Orient Electric Apex-FX 1200mm BEE Star Rated Ceiling Fan (White)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LslJNZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44m8SFC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41Z6iUe"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LvcH2H"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44otlJR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44n18mI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/410RKCu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40XglIf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44hqdzz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p17sjM"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1412"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51Zb-9uH9IL._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Heating, Cooling & Air Quality \n                Fans \nPryce1412 \nDicos50 \n\n❌Regular Price  : ₹  2499   /- 😱"
     }
    ]
   },
   {
    "id": 20271,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683102034",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "4499",
    "dicos": "34",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Havells Carnesia i 1200mm Ceiling Fan (Pearl Brown LT Copper)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44lgwQC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VpzktQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44lgtnV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41ZZk1x"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AOSsI9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42gyYrN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VyfcWE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LMO5CP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LrS0om"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AOSz6x"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@4499"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71GMsepMueL._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Heating, Cooling & Air Quality \n                Fans \nPryce4499 \nDicos34 \n\n❌Regular Price  : ₹  4999   /- 😱"
     }
    ]
   },
   {
    "id": 20272,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683102154",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "395",
    "dicos": "82",
    "catagory": "Shoes",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   United Colors of Benetton Women's Clutch Bag (Olive)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nmb9Qo"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NBF2a6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AQCE7K"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NxzBc8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nj1HNV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AM3AFE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AUI9lP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HAK8Qi"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LZjDXr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Nwk4t8"
     },
     {
      "type": "plain",
      "text": "\n\n @395\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61WfPZy-TgS._UY625_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Shoes"
     },
     {
      "type": "plain",
      "text": " & Handbags\n\n                Fashion \n                Pongal || Fashion accessories \n                Handbags \nPryce395 \nDicos82 \n\n❌Regular Price  : ₹   1499  /- 😱"
     }
    ]
   },
   {
    "id": 20273,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683102279",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "699",
    "dicos": "65",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Mivi Play Bluetooth Speaker with 12 Hours Playtime. Wireless Speaker Made in India with Exceptional Sound Quality, Portable and Built in Mic-Turquoise, One Size✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NGpcee"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HytUXG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40ZPATq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40ZNaUM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42gJf7o"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LMYEqA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/414MYnf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LQ4jvY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p1BmnR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HW7gZN"
     },
     {
      "type": "plain",
      "text": "\n\n @699\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/616KgzKCyOS._SY450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Home Audio \n                Speakers \nPryce699 \nDicos65 \n\n❌Regular Price  : ₹  1499   /- 😱"
     }
    ]
   },
   {
    "id": 20274,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683102407",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "345",
    "dicos": "37",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Cello Double Treat Lunch Box with Jacket, 3 Container, (2 Pcs Round Container - 300 ml, 1 Pc Oval Container), Blue✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42k7pxS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42kIry6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LsmzdB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3njE1Jg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3noZzUM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44q4sgK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NQgCtR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Ny9u4R"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p719eA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LpTbEB"
     },
     {
      "type": "plain",
      "text": "\n\n @345\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/81ZTf8q9j3L._SX466_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Dining \n                Kitchen Storage & Containers \nPryce345 \nDicos37 \n\n❌Regular Price  : ₹  549  /- 😱"
     }
    ]
   },
   {
    "id": 20275,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683102509",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "999",
    "dicos": "74",
    "catagory": "Health",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   ActiveX (Australia) Savvy Smart Bluetooth Body Composition Weighing Scale| Tracks Body Weight, Body Fat, BMI & more | Free ActiveX App | Batteries Included | 1 Year Warranty✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vyftc8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LPqu5u"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Loqqs5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ntXqab"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LwjLMm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LtRoyh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LtDhJr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LMZcg8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AKyQFj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41ZjLvl"
     },
     {
      "type": "plain",
      "text": "\n\n @999\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51VSPEDY-NL._SY450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Health"
     },
     {
      "type": "plain",
      "text": " & Personal Care\n\n                Health & Personal Care \n                Home Medical Supplies & Equipment \n                Health Monitors \nPryce999 \nDicos74 \n\n❌Regular Price  : ₹  2499   /- 😱"
     }
    ]
   },
   {
    "id": 20276,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683102634",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1589",
    "dicos": "72",
    "catagory": "Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   SanDisk Ultra USB 3.0 Flash Drive, CZ48 256GB, USB3.0, Black, Stylish Sleek Design, 5Y Warranty✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lrhwdh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/411mbbs"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41Y9SOF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42dsDx8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44qBY6E"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44likJl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p1o1fi"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VyfBZa"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42bfo0c"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LpTq2t"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1589"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61F-Qe3hJoL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Computers & Accessories \n                External Devices & Data Storage \n                 Pen Drives \nPryce1589 \nDicos72 \n\n❌Regular Price  : ₹  3999    /- 😱"
     }
    ]
   },
   {
    "id": 20277,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683102757",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "255",
    "dicos": "79",
    "catagory": "Clothing",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   SIRIL Women's Animal Print Printed Georgette Saree With Blouse✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p1y2JB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HAKKW6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HCWnvx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LP6z6x"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HAL4Ei"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HyVkNo"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/410Tgoa"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40XhjnR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3np015q"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LOFMau"
     },
     {
      "type": "plain",
      "text": "\n\n @255\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/714-84XYrYL._UY741_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Clothing"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Clothing & Accessories \n                Women \n                Western Wear \nPryce255 \nDicos79 \n\n❌Regular Price  : ₹  899   /- 😱"
     }
    ]
   },
   {
    "id": 20278,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683102879",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "13999",
    "dicos": "53",
    "catagory": "Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Oppo Pad Air 4 GB RAM 64 GB ROM 10.36 inch with Wi-Fi Only Tablet (Grey)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/413GMfo"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42l6kpc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oTFA0I"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42df0Oo"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/429NTUC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LR33ZI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vygcdm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NzDZHM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NuSYmn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p5lHnG"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@13999"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/513FD4w8hGL._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Computers & Accessories \n                2021 \n                 Coupon eligible tablets \nPryce13999 \nDicos53 \n\n❌Regular Price  : ₹  24999   /- 😱"
     }
    ]
   },
   {
    "id": 20279,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683103000",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "781",
    "dicos": "81",
    "catagory": "Clothing",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Amazon Brand - Solimo DSLR and Laptop Backpack - Orange Interior✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LrSHxY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LwkbCq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LpTVtn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3np0e8I"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3noN1wB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LN0FTI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HvcDPa"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HCWHuf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ALF0Vw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oXY9AZ"
     },
     {
      "type": "plain",
      "text": "\n\n @781\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/91cCy1tjvVL._UY741_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Clothing"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Computers & Accessories \n                Accessories & Peripherals \n                Laptop Accessories \nPryce781 \nDicos81 \n\n❌Regular Price  : ₹  2999   /- 😱"
     }
    ]
   },
   {
    "id": 20280,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683103125",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "4499",
    "dicos": "34",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Havells Carnesia i 1200mm Ceiling Fan (Pearl Brown LT Copper)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LPnvdd"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LorcFv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/413HfOG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42l1msW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42l2lck"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42gAavh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/410IYEz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vo93fz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42l1CIq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41Zi2q2"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@4499"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71GMsepMueL._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Heating, Cooling & Air Quality \n                Fans \nPryce4499 \nDicos34 \n\n❌Regular Price  : ₹   4999  /- 😱"
     }
    ]
   },
   {
    "id": 20281,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683103227",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "579",
    "dicos": "81",
    "catagory": "Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Amazon Basics Multipurpose Foldable Laptop Table with Leg Support for Sturdiness | Cup Holder | Mac Holder | Drawer | Foldable and Portable Breakfast Table (Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oXheTL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41ZRm8n"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41UdG3k"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41Xzds5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41YaNP7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44lvmqe"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44krcPj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p5lYXK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44AV9uT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44fSEh8"
     },
     {
      "type": "plain",
      "text": "\n\n @579\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61JZ6hWAhtL._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Computers & Accessories \n                Accessories & Peripherals \n                Laptop Accessories \nPryce579 \nDicos81 \n\n❌Regular Price  : ₹  1999   /- 😱"
     }
    ]
   },
   {
    "id": 20282,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683103351",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1111",
    "dicos": "56",
    "catagory": "Tools",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Treo By Milton All Fresh Premier Glass Tiffin Set of 8 (3 - Containers, 400 ml Each; 1 - Steel Bottle, 750 ml; 2 - Pickle/Chutney Dabba, 30 ml Each; 1 - Salad/Papad Dabba, 200 ml; 1 - Dining Mat)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44AV4r5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HvcRG0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AMjUXh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ANSMHb"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AOTeoH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nn6jSW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LR3nYq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ANTV1t"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HyUBeY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nmd8nO"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1111"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/81ZEMVyXf0L._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Tools"
     },
     {
      "type": "plain",
      "text": " & Home Improvement\n\n                Home & Kitchen \n                Home & kitchen | Prime shopping days \n                Prime Units \nPryce1111 \nDicos56 \n\n❌Regular Price  : ₹  1999  /- 😱"
     }
    ]
   },
   {
    "id": 20283,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683103475",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1170",
    "dicos": "84",
    "catagory": "Sports",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   PowerMax Fitness® BCA-150 Smart Bluetooth Body Fat Scale - Smart BMI Digital Bathroom Wireless Weight Scale & Body Composition Analyser with Smartphone App✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LQ5DPs"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NwTrEw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3njEWcG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nge1hW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p72ty4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40THVWZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LPo0UD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vovn8N"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3np0TqI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41ZRAwf"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1170"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61Z5E72WIQL._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Sports"
     },
     {
      "type": "plain",
      "text": ", Fitness & Outdoors\n\n                Health & Personal Care \n                Home Medical Supplies & Equipment \n                Health Monitors \nPryce1170 \nDicos84 \n\n❌Regular Price  : ₹   4999  /- 😱"
     }
    ]
   },
   {
    "id": 20284,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683103577",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "99",
    "dicos": "67",
    "catagory": "Grocery",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Teddy Roosevelt Smooth Instant Coffee Powder, 50g✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3noIVEO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41YaSlT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NQhNJN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44krllP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44lixMG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LkffR6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LvmUw9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HAD71Q"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40XTsUX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hvd6ko"
     },
     {
      "type": "plain",
      "text": "\n\n @99\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71ritEJblPL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Grocery"
     },
     {
      "type": "plain",
      "text": " & Gourmet Foods\n\n                Grocery & Gourmet Foods \n                Coffee, Tea & Beverages \n                Coffee \nPryce99 \nDicos67 \n\n❌Regular Price  : ₹ 299   /- 😱"
     }
    ]
   },
   {
    "id": 20285,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683103699",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "132",
    "dicos": "55",
    "catagory": "Beauty",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   MyGlamm 2IN1 Nail Paint-Poolside Soiree-2X5ml✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hzxt01"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42kJSN0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Huk1tZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://www.amazon.in/dp/B09CVBVY85?smid=A15APWRK6P7LBV&amp;th=1&amp;linkCode=sl1&amp;tag=avi1deals-21&amp;linkId=4425a1e1e3b149783f54f984b1d7a607&amp;language=en_IN&amp;ref_=as_li_ss_tl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42njpPb"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41eIPO1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40T6gfq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42joYhn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40U5iPX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41WaX9N"
     },
     {
      "type": "plain",
      "text": "\n\n @132\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/31leP-qA7wL._PIbundle-2,TopRight,0,0_AA500SH20_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Beauty"
     },
     {
      "type": "plain",
      "text": "\n\n                Beauty \n                Manicure & Pedicure \n                Nails \nPryce132 \nDicos55 \n\n❌Regular Price  : ₹ 295   /- 😱"
     }
    ]
   },
   {
    "id": 20286,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683103824",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "968",
    "dicos": "60",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Pigeon by Stovekraft 14456 Induction Base Inner Lid Aluminium Pressure Cooker, 3L, Silver✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44r4ANl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44pNcIM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44pFLBq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p1pn9S"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hz0ecS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LqFNA8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LzUxgm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VqS24d"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nmdhaQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p1a558"
     },
     {
      "type": "plain",
      "text": "\n\n @968\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51Xvd6hVBtL._SX522_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Dining \n                Cookware \nPryce968 \nDicos60 \n\n❌Regular Price  : ₹  1999   /- 😱"
     }
    ]
   },
   {
    "id": 20287,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683103947",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "441",
    "dicos": "71",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   AADHYA CREATIONS White with Silver Base Table Lamp with 10 Round Plain Pink Lamp Shade (Size: 25 x 25 x 42 cm)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LMzPem"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HAMbns"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LPrTcg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nrOlPq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nhQsoV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HW98lh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nlMdsr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/414OOVb"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40XU5hh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NyW5tE"
     },
     {
      "type": "plain",
      "text": "\n\n @441\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51D7D8EySYL._SY445_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Indoor Lighting \n                 Table Lamps \nPryce441 \nDicos71 \n\n❌Regular Price  : ₹  1299  /- 😱"
     }
    ]
   },
   {
    "id": 20288,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683104071",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "2399",
    "dicos": "34",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   LUMINOUS Wavia 1200mm 44-Watt High Speed 3Star Ceiling Fan (Silky Silver)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vt1btc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41ZSrNt"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NtXlOB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lv8KuI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42l2hto"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nwP9lQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42nixtP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ALG3EW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AMkMLx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44rHG8j"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@2399"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/41EvVeAQMSL._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Heating, Cooling & Air Quality \n                Fans \nPryce2399 \nDicos34 \n\n❌Regular Price  : ₹ 2999   /- 😱"
     }
    ]
   },
   {
    "id": 20289,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683104195",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "799",
    "dicos": "75",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   PTron Bassbuds Rush Bluetooth True Wireless in Ear Earbuds with Mic, Gaming 40ms Low Latency, 35Hrs Playtime, Deep Bass, ENC Stereo Call, BT5.3 Headphones, 1-Step Pairing, Voice Assist & IPX4 (Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44lwdqW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42l7zom"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p6mlkx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LrUsLA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oYeo0R"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42kWt2H"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42dunXc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LzVc1k"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42kWvYn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/421NzYu"
     },
     {
      "type": "plain",
      "text": "\n\n @799\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51u6kOItA2L._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Headphones, Earbuds & Accessories \n                Headphones \nPryce799 \nDicos75 \n\n❌Regular Price  : ₹  1999  /- 😱"
     }
    ]
   },
   {
    "id": 20290,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683104622",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "212",
    "dicos": "20",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Treo By Milton Handcrafted Designer Bowl, 1 Piece, 870 ml, Floral✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LPgaKL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lkd9R2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44owj0X"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44r5MQP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LmJCX4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42fM1JY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HuuBl2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44pGC56"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42dvwxY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HuldgX"
     },
     {
      "type": "plain",
      "text": "\n\n @212\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61kCc7foK5L._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Dining \n                Tableware \nPryce212 \nDicos20 \n\n❌Regular Price  : ₹  290   /- 😱"
     }
    ]
   },
   {
    "id": 20291,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683104723",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "217",
    "dicos": "69",
    "catagory": "Beauty",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Revolution Pro Supreme Matte Lip Pigment, Sixth Sense, 2ml✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44zFufg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vt1Vyu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p4e1lB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p6ilR6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41XAKyl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vveo4S"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/411ZYKx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nrVFuj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44n4Ht6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VowNQF"
     },
     {
      "type": "plain",
      "text": "\n\n @217\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71mvBhh2IEL._SY450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Beauty"
     },
     {
      "type": "plain",
      "text": "\n\n                Beauty \n                Make-up \n                Lips \nPryce217 \nDicos69 \n\n❌Regular Price  : ₹ 599   /- 😱"
     }
    ]
   },
   {
    "id": 20292,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683104824",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "299",
    "dicos": "73",
    "catagory": "Clothing",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Neostreak Men's Slim Fit T-Shirt✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VoaQ4h"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vtfyxz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AOMqr2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vt45xT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41Q8PjH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nwtF8J"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HykStH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LpVGGZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LvY3Ie"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lva5Sg"
     },
     {
      "type": "plain",
      "text": "\n\n @299\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/81HEguICqXL._UY500_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Clothing"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Clothing & Accessories \n                Men \n                T-Shirts & Polos \nPryce299 \nDicos73 \n\n❌Regular Price  : ₹  899  /- 😱"
     }
    ]
   },
   {
    "id": 20293,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683104925",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "923",
    "dicos": "51",
    "catagory": "Watches",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   helix Analog Black Dial Men's Watch-TW036HG06✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LvY66S"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/410KNBp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nlVq3X"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AOMEyo"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LN1RGE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LtUoL3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ANQKXD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NxjOdq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nn88iK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HzzgSN"
     },
     {
      "type": "plain",
      "text": "\n\n @923\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/81iqIE3A4iL._UY741_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Watches"
     },
     {
      "type": "plain",
      "text": "\n\n                Fashion \n                Onam 2021 \n                Watches and fashion jewellery \nPryce923 \nDicos51 \n\n❌Regular Price  : ₹  1599  /- 😱"
     }
    ]
   },
   {
    "id": 20294,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683105049",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "2819",
    "dicos": "47",
    "catagory": "Tools",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   BUILDSKILL DIY Paint Sprayer - 750 Watt HLVP Painting Machine with a Copper Nozzle - Brand Warranty - Electric Spray Gun for Painting Home Walls and Wood - Polish Spraying✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LPtaQA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HCZ2W3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NynID2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HyaH8L"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hyxz7S"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p6iK64"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ALHB1I"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40TKlox"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41eKtzb"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LufQQ6"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@2819"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71TVWRAVx8L._SX466_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Tools"
     },
     {
      "type": "plain",
      "text": " & Home Improvement\n\n                Home Improvement \n                Painting Supplies, Tools & Wall Treatments \n                Painting Supplies & Tools \nPryce2819 \nDicos47 \n\n❌Regular Price  : ₹  4999   /- 😱"
     }
    ]
   },
   {
    "id": 20295,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683105180",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "9299",
    "dicos": "71",
    "catagory": "Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   SanDisk 1TB Extreme Portable SSD 1050MB/s R, 1000MB/s W,Upto 2 Meter Drop Protection with IP55 Water/dust Resistance, HW Encryption, PC,MAC & TypeC Smartphone Compatible, 5Y Warranty, External SSD✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LPtvTm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AP7rC8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42njN01"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42hbAKx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44zG3Wq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44AX6aH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41Z6N0M"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44hu0wN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p1bEjw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oTIG4Q"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@9299"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71wtYi+0t0L._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Computers & Accessories \n                External Devices & Data Storage \n                 External Solid State Drives \nPryce9299 \nDicos71 \n\n❌Regular Price  : ₹ 19999   /- 😱"
     }
    ]
   },
   {
    "id": 20296,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683105297",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "7499",
    "dicos": "79",
    "catagory": "Luggage",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   AmazonBasics Polycarbonate Hard 74 cms suitcase(T1916-24_navy blue)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42g1zgM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44fVaUC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44ktFt3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VoboqR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3npCiSA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vyj89S"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NAy2dO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41WRDZH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p2eIvA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VsGYDF"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@7499"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/91fwzkTZj7L._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Luggage"
     },
     {
      "type": "plain",
      "text": " & Bags\n\n                Bags, Wallets and Luggage \n                Luggage \n                 Luggage Sets \nPryce7499 \nDicos79 \n\n❌Regular Price  : ₹ 19999    /- 😱"
     }
    ]
   },
   {
    "id": 20297,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683105434",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "10499",
    "dicos": "72",
    "catagory": "Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   ZEBRONICS 24 inch 165Hz Gaming Monitor with FHD 1080p, IPS Panel, 1ms MPRT, HDR10, Free sync Support, HDMI, DP, 250 Nits max, 16.7M Colors, Built-in Speakers and Ultra Slim Bezel Less Design ZEB-S24A✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/420koVs"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p1c0qm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44lyxOG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nsxFXV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LMAegT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LtVnuJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HAO5V8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LvhgKn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nwRJs2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nlW6Gx"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@10499"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/81dGEdml2WL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Computers & Accessories \n                2021 \n                 Gaming Monitors \nPryce10499 \nDicos72 \n\n❌Regular Price  : ₹  24999   /- 😱"
     }
    ]
   },
   {
    "id": 20298,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683105563",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "106",
    "dicos": "90",
    "catagory": "Sports",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Azone Blue 30x10 Feet Anti Bird Nets (Blue)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VqUGXI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ATqJpG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hz3epE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42hxZaD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oUMoLw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NxDYE4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nmghnC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p5asLV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HD0Dv1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NtZt93"
     },
     {
      "type": "plain",
      "text": "\n\n @106\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/810fZOUl2eL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Sports"
     },
     {
      "type": "plain",
      "text": ", Fitness & Outdoors\n\n                Outdoor Living \n                Outdoor Décor \n                 Flags \nPryce106 \nDicos90 \n\n❌Regular Price  : ₹  699   /- 😱"
     }
    ]
   },
   {
    "id": 20299,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683105686",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "149",
    "dicos": "63",
    "catagory": "Beauty",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Soulflower Lavender Essential Oil | Skin, Acne, Hair Fall, Relaxing Sleep, Aromatherapy & Body | Lavandula Angustifolia | Pure, Organic, Natural & Undiluted, Ecocert Certified | 10ml✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NyYkgy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VpB1rk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40Xhr6Y"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Nzg0Zh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44lQ2OR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nu1pUb"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nlWzbL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40Yg4ou"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40T94sY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/4121qMZ"
     },
     {
      "type": "plain",
      "text": "\n\n @149\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/610ETPY7H3L._SY879_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Beauty"
     },
     {
      "type": "plain",
      "text": "\n\n                Beauty \n                Bath & Body \n                Bath Additives \nPryce149 \nDicos63 \n\n❌Regular Price  : ₹ 400   /- 😱"
     }
    ]
   },
   {
    "id": 20300,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683105790",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "799",
    "dicos": "56",
    "catagory": "Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   D-Link DIR-615 Wi-fi Ethernet-N300 Single_band 300Mbps Router, Mobile App Support, Router | AP | Repeater | Client Modes(Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LOs4Ez"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42nlYRj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oXkyyd"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LOUU81"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NyByFn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44iAyeF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p6oQmV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/423YmBh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NQlcIz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42bjiGo"
     },
     {
      "type": "plain",
      "text": "\n\n @799\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51B-8XSoCOL._SX466_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Computers & Accessories \n                Networking Devices \n                 Routers \nPryce799 \nDicos56 \n\n❌Regular Price  : ₹  1499   /- 😱"
     }
    ]
   },
   {
    "id": 20301,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683105892",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "499",
    "dicos": "80",
    "catagory": "Health",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   beatXP Optifit Air Digital Weighing Scale with Backlit LED Panel (Blue), Electronic Weight Machine for Body Weight with 6 mm Thick Tempered Glass✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44r7GAX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NAyNUc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p7Dj1Z"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41XcM6k"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/410LOcL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p2fmJw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41WdEbp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40ZRRhm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44qG8eM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42nllXT"
     },
     {
      "type": "plain",
      "text": "\n\n @499\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61KslYKHj2L._SY450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Health"
     },
     {
      "type": "plain",
      "text": " & Personal Care\n\n                Health & Personal Care \n                Home Medical Supplies & Equipment \n                Health Monitors \nPryce499 \nDicos80 \n\n❌Regular Price  : ₹  1899  /- 😱"
     }
    ]
   },
   {
    "id": 20302,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683105996",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1799",
    "dicos": "40",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Pigeon by Stovekraft Titanium Induction Base Inner Lid Hard Anodised Aluminium Pressure Cooker, 5 litres, Black, Standard (12422)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44rKfHt"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/4121M6h"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41ZbgAm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VsHP7l"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VGWvjH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VtuP1B"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LqJgi8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HwIuPm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oY2293"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44qGli4"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1799"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/411FIPpBwtS._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Dining \n                Cookware \nPryce1799 \nDicos40 \n\n❌Regular Price  : ₹ 2898   /- 😱"
     }
    ]
   },
   {
    "id": 20303,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683106118",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "49990",
    "dicos": "58",
    "catagory": "Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Lenovo ThinkPad C13 Yoga AMD Ryzen 5 3500C 13.3\" (33.78cm) FHD IPS Touch with Pen Thin and Light Chromebook (16GB RAM/256GB SSD/Google Chrome/Backlit Keyboard/Pen/FPR/Abyss Blue/1.49 kg), 20UYS17B01✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/414Smqt"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40ZoNXp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AQD2Dw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VtuV9t"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3noLWoC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hy94b0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44zHPXA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/421NpjG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p4KSqt"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HB2ikN"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@49990"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/41VlJ5DB53L._SY450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Computers & Accessories \n                Laptops \n                 2 in 1 Laptops \nPryce49990 \nDicos58 \n\n❌Regular Price  : ₹ 84999   /- 😱"
     }
    ]
   },
   {
    "id": 20304,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683106243",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1499",
    "dicos": "53",
    "catagory": "Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   ZEBRONICS ZEB-K5000MW Bluetooth Wireless Keyboard with Easy-Switch for Up to 3 Devices for PC, Laptop, Windows, Mac, Chrome OS, Android, iPad OS, 6 Months Backup with Type C Charging (Space Grey)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LN45Ww"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oYgZrD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LkiJmE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LzYhOW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VnWP6J"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44iB9Nr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AKDIdz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LuhktE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AOOsYc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NyYS66"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1499"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71fIf2kTs9L._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Computers & Accessories \n                Accessories & Peripherals \n                Keyboards, Mice & Input Devices \nPryce1499 \nDicos53 \n\n❌Regular Price  : ₹ 2899   /- 😱"
     }
    ]
   },
   {
    "id": 20305,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683106367",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1799",
    "dicos": "55",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Blaupunkt Newly Launched SBA25 Gaming 25W Bluetooth Soundbar with 2000mAh Battery I Gaming RGB Lights I TurboVolt Charging I AUX, Bluetooth, USB & TWS I BT Speaker for TV, Mobile, PC, Laptops(Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vtvi3R"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41eM4oF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LN4JTM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3noMpXU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p7DY3t"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42dxluO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LN4xnG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40YgZFs"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3APiJWY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/413LwSe"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1799"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/613sa6vTj8L._SY450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Home Audio \n                Speakers \nPryce1799 \nDicos55 \n\n❌Regular Price  : ₹ 3199   /- 😱"
     }
    ]
   },
   {
    "id": 20306,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683106493",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "849",
    "dicos": "66",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   ZEBRONICS Zeb-DUKE1 Wireless Bluetooth 5.0 Over Ear Headphone with Voice Assistant, AUX Port, Call Function, 34Hrs* Battery Backup, Dual Pairing, Media & Volume Control with mic (Orange with Grey)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/410MBuf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44kvndX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44lzAy6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HAPon0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/420eqUB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/425nmby"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vvn2QD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HWcvIX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VpFj1M"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40YGQNq"
     },
     {
      "type": "plain",
      "text": "\n\n @849\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/618tzWLGZmL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Headphones, Earbuds & Accessories \n                Headphones \nPryce849 \nDicos66 \n\n❌Regular Price  : ₹1999    /- 😱"
     }
    ]
   },
   {
    "id": 20307,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683106593",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "100",
    "dicos": "67",
    "catagory": "Health",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Fortune Multi Use Descaler 100 gm (2 Units x 100 gm)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44ozk1h"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LrOBpw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44kvztH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44fWRkW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44AZyxV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nlXvNj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p5qcyA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LMDuJ8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HunV67"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41XbqZd"
     },
     {
      "type": "plain",
      "text": "\n\n @100\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/815U6VqekiL._SX450_PIbundle-2,TopRight,0,0_SX450SY404SH20_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Health"
     },
     {
      "type": "plain",
      "text": " & Personal Care\n\n                Health & Personal Care \n                Household Supplies \n                Household Cleaners \nPryce100 \nDicos67 \n\n❌Regular Price  : ₹  499  /- 😱"
     }
    ]
   },
   {
    "id": 20308,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683106722",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "2375",
    "dicos": "60",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Hindware Smart Appliances Alita Amber Gold 1200MM Ceiling Fan for Home Star Rated Metallic Finish Energy Efficient with High Air Delivery and 47W Copper Motor, Alumimium Aerodynamic blade.✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vodas1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42nmbnv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VticmZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ARuwUJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3APa7ja"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AMANAY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ARuGLP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HvhBvi"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AQmkE2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44pK27W"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@2375"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51bht53s-eL._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Heating, Cooling & Air Quality \n                Fans \nPryce2375 \nDicos60 \n\n❌Regular Price  : ₹ 4899   /- 😱"
     }
    ]
   },
   {
    "id": 20309,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683106848",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "4995",
    "dicos": "52",
    "catagory": "Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Logitech Pop Keys Mechanical Wireless Keyboard with Customisable Emoji Keys, Durable Compact Design, Bluetooth Or USB Connectivity, Multi-Device, Os Compatible - Blast✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hyj9on"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LkfVpq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vrl044"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/411s6xc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40XmYKD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AQEq9c"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vrl7N2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LMTQjV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ALKijQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NyqpEE"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@4995"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71ThTAkg0aL._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Computers & Accessories \n                Accessories & Peripherals \n                Keyboards, Mice & Input Devices \nPryce4995 \nDicos52 \n\n❌Regular Price  : ₹ 8599   /- 😱"
     }
    ]
   },
   {
    "id": 20310,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683106971",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "11999",
    "dicos": "67",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Inalsa 75 cm 1350 m³/hr Kitchen Chimney Auto Clean, Motion Sensor, Filter less(Maris 75BKMAC, Touch Control, Made In India, 7 years warranty)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42jC91W"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NxFMwQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p5bW8X"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NxmYxO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40YHmuQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44fXaw6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44hwD1D"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42hAn15"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44pKp2k"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42lbIIW"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@11999"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51qczKeYbqL._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Large Appliances \n                 Range Hoods & Chimneys \nPryce11999 \nDicos67 \n\n❌Regular Price  : ₹  28999  /- 😱"
     }
    ]
   },
   {
    "id": 20311,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683107099",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1299",
    "dicos": "52",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   LONGWAY Creta 1200mm/48 inch High Speed Anti-dust Decorative Ceiling Fan Suitable For Indoor Home/Small Room/Kitchen/Bad Room/Balcony 2 Year Warranty (Ivory, Pack of 1)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/420f5p3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/425oqw4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44loAkj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HxyRQy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44mIv2a"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3noSuDD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3np5h9a"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LPsx9B"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p60QjQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p2hIrQ"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1299"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/517Mr7ykrIL._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Heating, Cooling & Air Quality \n                Fans \nPryce1299 \nDicos52 \n\n❌Regular Price  : ₹ 2100   /- 😱"
     }
    ]
   },
   {
    "id": 20312,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683107199",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1365",
    "dicos": "61",
    "catagory": "Watches",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Daniel Klein Analog Silver Dial Women's Watch-DK.1.12492-7✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LN5In6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LrYHqu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LvasfO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ATsL9i"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AUOqhn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NBrTOj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AOYxED"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AQJfPy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44or01p"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LZq6lb"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1365"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61TYwkQuoNL._UY741_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Watches"
     },
     {
      "type": "plain",
      "text": "\n\n                Fashion \n                Onam 2021 \n                Watches and fashion jewellery \nPryce1365 \nDicos61 \n\n❌Regular Price  : ₹ 2299   /- 😱"
     }
    ]
   },
   {
    "id": 20313,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683107325",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1898",
    "dicos": "60",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   CP PLUS 3 MP Full HD Wi-Fi PT Security Video Camera | 360°View with Motion Detection | Night Vision | Two Way Talk | Supports Alexa & OK Google | 15 Mtr, White - CP-E34A✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/413oN8O"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40XSuIC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AQn9wC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LLmJxT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ALVltt"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AMCfmU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/421Sf0u"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41XEbFf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NyfDxX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HzvuIP"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1898"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/41ld7bu4E3L._SX466_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Cameras & Photography \n                Security Cameras \nPryce1898 \nDicos60 \n\n❌Regular Price  : ₹ 3699   /- 😱"
     }
    ]
   },
   {
    "id": 20314,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683107450",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "202",
    "dicos": "69",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀  SYSKA B22 7-Watt Led Bulb (Pack Of 2, Cool Day Light, Cool Day Light,B22D) ✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HABRvs"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44mJ6kq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Nu2mqp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NBLXA6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44mJgs2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42jD4iU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42hB9v1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VpPHXe"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40YUPD1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/4206vH1"
     },
     {
      "type": "plain",
      "text": "\n\n @202\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51+Ygou7UXL._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Indoor Lighting \n                Light Bulbs \nPryce202 \nDicos69 \n\n❌Regular Price  : ₹ 459  /- 😱"
     }
    ]
   },
   {
    "id": 20315,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683107552",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "839",
    "dicos": "20",
    "catagory": "Clothing",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   2Go Men's Relaxed Shorts✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LqqUOs"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LpZN5T"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p2sq1E"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nqxQD8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44pLpDC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vvox1d"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40XZg0H"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vz3ydZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VtOLRC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/413Nr9o"
     },
     {
      "type": "plain",
      "text": "\n\n @839\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71ImvPs9JXL._UX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Clothing"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Clothing & Accessories \n                Men \n                Sportswear \nPryce839 \nDicos20 \n\n❌Regular Price  : ₹  1049  /- 😱"
     }
    ]
   },
   {
    "id": 20316,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683107675",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "399",
    "dicos": "80",
    "catagory": "Health",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Boldfit Flaxseed Oil Capsules 1000mg with Omega 3 for Men & Women - Supports Skin, Joint, Hair & Immunity Support - Flaxseed Pack of 2-120 Veg Capsules.✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40XT7ls"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LRWiH6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lqreg8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41UjZE2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Nyr5tJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42noPtv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42fcVSg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NAB01W"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LN6Ln4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Nz1DV0"
     },
     {
      "type": "plain",
      "text": "\n\n @399\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/613bvhbBq2L._SY450_PIbundle-2,TopRight,0,0_AA450SH20_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Health"
     },
     {
      "type": "plain",
      "text": " & Personal Care\n\n                Health & Personal Care \n                Diet & Nutrition \n                Vitamins, Minerals & Supplements \nPryce399 \nDicos80 \n\n❌Regular Price  : ₹ 1299   /- 😱"
     }
    ]
   },
   {
    "id": 20317,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683107803",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "2699",
    "dicos": "73",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   AmazonBasics Aluminium Non-Stick Black Cookware Set - 15 Piece✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nhWvK9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42gG2oj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LqPdLV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://www.amazon.in/dp/B07481LPMF?th=1&amp;linkCode=sl1&amp;tag=avi1deals-21&amp;linkId=02e8a8ce5040a9978b28f10b7b8341c1&amp;language=en_IN&amp;ref_=as_li_ss_tl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VpHP8e"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VuhfuU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nqydh0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3APfoY4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LRWIxa"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p2iNQq"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@2699"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61x-NhdKBmL._SX522_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Dining \n                Cookware \nPryce2699 \nDicos73 \n\n❌Regular Price  : ₹ 8599   /- 😱"
     }
    ]
   },
   {
    "id": 20318,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683107927",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1499",
    "dicos": "70",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Philips Audio TAH4205XTBK/00 Bluetooth 5.0, Bass Booster, Quick Charging Upto 29H Playtime, 32mm Driver, Compact Folding Design Bluetooth Wireless On Ear Headphones With Mic (Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AMV8pJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nlZQb3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Nys9Oc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44rqXCm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LPmgL2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NyrEUn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vvj1vM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oTMuTG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VnZDAN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VtkiDn"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1499"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71NbN9Xjz2L._SY879_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Headphones, Earbuds & Accessories \n                Headphones \nPryce1499 \nDicos70 \n\n❌Regular Price  : ₹ 3899   /- 😱"
     }
    ]
   },
   {
    "id": 20319,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683108052",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "947",
    "dicos": "37",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Portronics Power Plate 6 with 4 USB Port + 5 Power Sockets Extension Board, 2500W Power Converter, Cord Length 3Mtr (Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41ZoTzM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HAkpYd"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44rb12X"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LkhzaA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lukj5k"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41UkFt4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42hg5op"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LveIM8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HuA1fS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LMExJ5"
     },
     {
      "type": "plain",
      "text": "\n\n @947\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51GhURawEmL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Power Accessories \n                 Extension Cords \nPryce947 \nDicos37 \n\n❌Regular Price  : ₹ 1350   /- 😱"
     }
    ]
   },
   {
    "id": 20320,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683108176",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1834",
    "dicos": "48",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   AGARO Caspian Cool Mist Ultrasonic Humidifier, 1.7 Litres, For Bedroom, Home, Office, Adjustable Mist Output, Super Quiet, Auto Shut Off (White)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hz3vJA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Nynvzt"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VtycWj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41WhktJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lq0GeJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Nzke35"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/4207J55"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/420bKGs"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oXoAXn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LtZved"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1834"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51Qo++uCJxL._SX466_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Home & kitchen | Prime shopping days \n                Prime Units \nPryce1834 \nDicos48 \n\n❌Regular Price  : ₹  2999  /- 😱"
     }
    ]
   },
   {
    "id": 20321,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683108296",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "749",
    "dicos": "32",
    "catagory": "Shoes",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   KOBURG Men KG-201 Casual Shoes✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ALWLUP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44pTata"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42noE1f"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42gHg2T"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AP1jtn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/420hhwN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ANWKzD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LN7tke"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40ZVvI4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VqZqwu"
     },
     {
      "type": "plain",
      "text": "\n\n @749\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/81n8nkdCxeL._UX625_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Shoes"
     },
     {
      "type": "plain",
      "text": " & Handbags\n\n                Shoes & Handbags \n                Shoes \n                Men's Shoes \nPryce749 \nDicos32 \n\n❌Regular Price  : ₹  1049  /- 😱"
     }
    ]
   },
   {
    "id": 20322,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683108403",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "284",
    "dicos": "83",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Kuber Industries BPA Free Attractive Design Multipurpose Small Trendy Storage Basket With Lid|Material-Plastic|Color-Gray,Beige|Pack of 2✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LOvuan"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44mjbcM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42BsyUr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nlSUe3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Nu3N8h"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p2jM34"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41ZbYOg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42dB1wC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44kybaZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40TPwot"
     },
     {
      "type": "plain",
      "text": "\n\n @284\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61ZDOhUUWTL._SX466_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Home Storage & Organisation \n                Boxes, Baskets & Bins \nPryce284 \nDicos83 \n\n❌Regular Price  : ₹ 1099   /- 😱"
     }
    ]
   },
   {
    "id": 20323,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683108529",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1369",
    "dicos": "52",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   LONGWAY Starlite-1 1200 mm/48 inch High Speed Anti-dust Technology Decorative 5 Star Rated Ceiling Fan 400 RPM with 2 Year Warranty (Silver Blue, Pack of 1)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/414WiHL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42l2pJ1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/421UEs2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44pMLOI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LN7XXA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LoyVDv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41Qf72N"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41XGgRz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41Zt3HJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HyD6LG"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1369"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/310sV0eLgtL._SY300_SX300_QL70_FMwebp_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Heating, Cooling & Air Quality \n                Fans \nPryce1369 \nDicos52 \n\n❌Regular Price  : ₹  2199  /- 😱"
     }
    ]
   },
   {
    "id": 20324,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683108655",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "990",
    "dicos": "60",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Qubo MagZap Z1 from Hero Group Magnetic Magsafe Wireless Charger 15W Fast Charging for iPhone 14, 14 Plus, 14 Pro, 14 Pro Max, iPhone 13, 13 Pro Max, 13 Mini, 12, 12 Pro Max, 12 Mini, Airpods 2, 3, Pro, Pro2, & All Qi-Enabled Devices (White)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44pU2Os"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42Bt0SD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VqZHzw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p1GGb1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p5tnq0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VsMhmz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41ZconO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LvcT1W"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40ZQLT3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42kQI58"
     },
     {
      "type": "plain",
      "text": "\n\n @990\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/41b4unxn1YL._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Mobiles & Accessories \n                Mobile Accessories \nPryce990 \nDicos60 \n\n❌Regular Price  : ₹ 1999   /- 😱"
     }
    ]
   },
   {
    "id": 20325,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683108757",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "799",
    "dicos": "72",
    "catagory": "Clothing",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Safari Quill Laptop Backpack 26 Ltrs Water Resistant Fabric - Blue, L (QUILL19CBBLU)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oSwnFV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41WJkx3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42mnmDJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p5btUp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VvqMSb"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ALnu3Q"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42ixoFP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ANXEfv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p4k8q3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HyqPXz"
     },
     {
      "type": "plain",
      "text": "\n\n @799\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61TWJaaUMtL._UX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Clothing"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Computers & Accessories \n                Accessories & Peripherals \n                Laptop Accessories \nPryce799 \nDicos72 \n\n❌Regular Price  : ₹ 1999   /- 😱"
     }
    ]
   },
   {
    "id": 20326,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683108883",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "37",
    "dicos": "81",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   FLiX (Beetel USB to Micro USB PVC Data Sync & 2A Fast Charging Cable, Made in India, 480Mbps Data Sync, Solid Cable, 1 Meter Long USB Cable for Micro USB Devices (White)(XCD-M11)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ALnQaG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VH1qRH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3APdM0o"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ALnXTE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44nb0Ni"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3APdQ08"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ANXRzj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3APhgA4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VoD5jf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NwtrZQ"
     },
     {
      "type": "plain",
      "text": "\n\n @37\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/41A76fXl4+L._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Computers & Accessories \n                Accessories & Peripherals \n                Cables & Accessories \nPryce37 \nDicos81 \n\n❌Regular Price  : ₹  149  /- 😱"
     }
    ]
   },
   {
    "id": 20327,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683108987",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "3999",
    "dicos": "80",
    "catagory": "Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   ZEBRONICS SD102 2.5\" 1TB SSD, SATA III, 550MB/s Data Speed, Ultra Low Power, Silent Operation, S.M.A.R.T. Technology and Thermal Management✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NBO4Uy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HwNB20"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nqzWD0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/425rp7K"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40Ylfoq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HB6NvH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VsMQwH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HySzvq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LZt35f"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oXq3gl"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@3999"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71ZBp0eH6EL._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Computers & Accessories \n                Components \n                 Internal Solid State Drives \nPryce3999 \nDicos80 \n\n❌Regular Price  : ₹  8599  /- 😱"
     }
    ]
   },
   {
    "id": 20328,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683109090",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "749",
    "dicos": "63",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   D-Link R03 N300 Eagle PRO AI Advance Parental Control Router with Voice Control Assistant (Alexa & Goggle Assistant) - Wi-Fi, Ethernet (Single_Band, 300 megabits_per_Second)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41WJVyN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LutlPJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3APhhE8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Nyt2Gl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nnfbbc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AOTpQM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AOTrrS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HwOaZG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44lVh0Z"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NytNPV"
     },
     {
      "type": "plain",
      "text": "\n\n @749\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/31hup-j6ogL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Computers & Accessories \n                Networking Devices \n                 Routers \nPryce749 \nDicos63 \n\n❌Regular Price  : ₹ 1499   /- 😱"
     }
    ]
   },
   {
    "id": 20329,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683109190",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1147",
    "dicos": "50",
    "catagory": "Watches",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   helix Analog Silver Dial Men's Watch-TW032HG11✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42nqWNX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NtqvgJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VogW4F"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44rP17R"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p5g09d"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40XVB3g"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LpETnz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LutEdl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://www.amazon.in/Helix-Analog-Silver-Dial-Watch-TW032HG11/dp/B087DJ7NBD?crid=D4QBRJF51IH2&amp;keywords=watches&amp;m=A1JFLV2BUSJ5AK&amp;qid=1683018899&amp;refinements=p_n_pct-off-with-tax%3A2665401031%2Cp_6%3AA1JFLV2BUSJ5AK%2Cp_n_feature_fourteen_browse-bin%3A11142592031&amp;rnid=11142591031&amp;s=watches&amp;sprefix=watches%2Caps%2C239&amp;sr=1-75&amp;linkCode=sl1&amp;tag=avi1deals9-21&amp;linkId=b2087d70353db9ff44a09bf11c874c25&amp;language=en_IN&amp;ref_=as_li_ss_tl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AKImbv"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1147"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/41QqQo34ckL.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Watches"
     },
     {
      "type": "plain",
      "text": "\n\n                Fashion \n                Onam 2021 \n                Watches and fashion jewellery \nPryce1147 \nDicos50 \n\n❌Regular Price  : ₹ 2100   /- 😱"
     }
    ]
   },
   {
    "id": 20330,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683109296",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "999",
    "dicos": "83",
    "catagory": "Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   HP USB 3.1 Flash Drive 128GB 796L✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vvlo1E"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/4200l9B"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p3Hnk0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40TfgBe"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AMsAwP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3APhRSk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HAMheK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44hAOKR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HB9eON"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nmXdFN"
     },
     {
      "type": "plain",
      "text": "\n\n @999\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71qFlJxm0iL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Computers & Accessories \n                External Devices & Data Storage \n                 Pen Drives \nPryce999 \nDicos83 \n\n❌Regular Price  : ₹  3499  /- 😱"
     }
    ]
   },
   {
    "id": 20331,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683109424",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "55990",
    "dicos": "28",
    "catagory": "Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   HP Victus Gaming Laptop AMD Ryzen 5 5600H 15.6 inch(39.6 cm) FHD IPS Gaming Laptop (8GB RAM/512GB SSD/AMD Radeon RX 6500M Graphics(4GB GDDR6)/144Hz/9ms Response time/Backlit KB/B&O/Win 11),15-fb0777AX✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LRcsjY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40TRqW9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Nu5jY1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LkjV9q"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LZtVa1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lq319v"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Nz4Dke"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vt9xky"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41eR7W9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44mN4cO"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@55990"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/818vGJO+jeL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Computers & Accessories \n                Laptops \n                 Traditional Laptops \nPryce55990 \nDicos28 \n\n❌Regular Price  : ₹ 69999   /- 😱"
     }
    ]
   },
   {
    "id": 20332,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683109555",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "3880",
    "dicos": "35",
    "catagory": "All",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Green Soul Seoul X Office Chair, Mid Back Mesh Ergonomic Home Office Desk Chair with Comfortable & Spacious Seat, Rocking-tilt Mechanism & Heavy Duty Metal Base (Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41Ykhdb"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LtNOnX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vvsymj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hz9pdk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AP44en"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41eRP5L"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LR4g3d"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ngnbLm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/421Xi0W"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nrXAiA"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@3880"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71ORUhfxYLL._SX522_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#All"
     },
     {
      "type": "plain",
      "text": "\n\n                Home & Kitchen \n                Furniture \n                Study & Home Office Furniture \nPryce3880 \nDicos35 \n\n❌Regular Price  : ₹ 5199   /- 😱"
     }
    ]
   },
   {
    "id": 20333,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683109687",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1799",
    "dicos": "64",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   boAt Airdopes 393ANC True Wireless in Ear Earbuds with 32dB Hybrid ANC, Quad Mics with ENx™ Tech, 30 Hours Playback, Beast™ Mode,ASAP™ Charge,Ambient Mode,Bluetooth v5.2 IWP™,IPX5(Rogue Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LZuWyR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NGClE6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LZvp45"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/410TFqH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44luxxF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LuoI8m"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p1koGm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p1krlw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vr2Uz6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vr3mNO"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1799"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51etSlfnpSL._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Headphones, Earbuds & Accessories \n                Headphones \nPryce1799 \nDicos64 \n\n❌Regular Price  : ₹ 3999    /- 😱"
     }
    ]
   },
   {
    "id": 20334,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683109790",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "4999",
    "dicos": "61",
    "catagory": "Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Amazon Basics 19.5 Inch (49.53 cm) LCD Monitor with Ultra Wide Angle, HDMI and VGA Connectivity (1600 x 900 Pixels), 16.7 M Colors, 5ms Response time, Black✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AP5d5v"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/420l9Ol"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LLrBmF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40TT5uR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40Xp2Cw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42fUTiK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LPqFh2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NyrVq3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44qOl2A"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HAH8Dg"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@4999"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/81tWF2si3SL._SX450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Computers & Accessories \n                2021 \n                 Gaming Monitors \nPryce4999 \nDicos61 \n\n❌Regular Price  : ₹   9999  /- 😱"
     }
    ]
   },
   {
    "id": 20335,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683109919",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1399",
    "dicos": "72",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   boAt Stone 650 10W Bluetooth Speaker with Upto 7 Hours Playback, IPX5 and Integrated Controls (Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HxESg6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NxMCT2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vo4X7f"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41XKqc9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VpNeMy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41001X2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42lcI02"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/421YRfk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oYbaul"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41Uqers"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1399"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71YXa2+AyNL._SY450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Home Audio \n                Speakers \nPryce1399 \nDicos72 \n\n❌Regular Price  : ₹  3999    /- 😱"
     }
    ]
   },
   {
    "id": 20336,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683110021",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "187",
    "dicos": "73",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   SYSKA LED SRL-9W + 7W COMBO✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AO6wBN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AMILtS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AKM9FL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HAJ8LM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oYpjHT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44rTnvJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/411zXea"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LR6wHJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LvqE0z"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ns6lJp"
     },
     {
      "type": "plain",
      "text": "\n\n @187\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51mEioYa0iL._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Indoor Lighting \n                Light Bulbs \nPryce187 \nDicos73 \n\n❌Regular Price  : ₹   599   /- 😱"
     }
    ]
   },
   {
    "id": 20337,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683110147",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1299",
    "dicos": "81",
    "catagory": "Watches",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   French Connection Analog Women's Watch (Dial Colored Strap)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HyvEQF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LOBIHh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LOBSOT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40XqWTG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AN5buT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NsC08a"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41Zhxw8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lqytok"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oSBq9l"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VqfHBM"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1299"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61bOABn2mlL._UY500_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Watches"
     },
     {
      "type": "plain",
      "text": "\n\n                Watches \n                Trends \n                Men \nPryce1299 \nDicos81 \n\n❌Regular Price  : ₹  4999   /- 😱"
     }
    ]
   },
   {
    "id": 20338,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683110275",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "14999",
    "dicos": "62",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   boAt Aavante Bar 3200D Soundbar with Dolby Audio,350W RMS Signature Sound,5.1 Channel,3D Surround Sound,Dual Wireless Rear Speakers&Master Remote(Premium Black)(Premium Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41ZyLJF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42kX8kK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AMx3j5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AREghL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3APmM5K"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AREANv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LP32Fv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LMMm1o"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p2Aa3I"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lvs4Ir"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@14999"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61S2NC2VLEL._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Home Audio \n                Speakers \nPryce14999 \nDicos62 \n\n❌Regular Price  : ₹  24999   /- 😱"
     }
    ]
   },
   {
    "id": 20339,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683110381",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "699",
    "dicos": "75",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   truke Fit 1+ True Wireless Earbuds with Dedicated Gaming Mode | 30hrs of Playtime | IPX4 | Touch Control | Low Latency | Bluetooth 5.1 | 10mm Driver for Deep Bass Music | Hi-Fi Sound with AAC Codec✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nwG1O9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lw9NdM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HuI503"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LusO0e"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HwTlJ6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NsD9N0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HB09Wq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42er5nf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NBUtz4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/4102JMc"
     },
     {
      "type": "plain",
      "text": "\n\n @699\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61jm7L1SrGS._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Headphones, Earbuds & Accessories \n                Headphones \nPryce699 \nDicos75 \n\n❌Regular Price  : ₹  1599   /- 😱"
     }
    ]
   },
   {
    "id": 20340,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683110505",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "387",
    "dicos": "74",
    "catagory": "Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   STRIFF Laptop Stand for Desk, Ergonomic Computer Stand Laptop Riser, Phone and Tablet Stand for Desk, Portable Laptop Elevator Holder Compatible with MacBook, Laptop,Tablet(WhiteSky)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oTU54E"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44qsdFu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44lwtpZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41ZjhWc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44lxqyy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AQSfnO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vtf2jc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AP0xN2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HAtSid"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nnmbVw"
     },
     {
      "type": "plain",
      "text": "\n\n @387\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61J8XU56LwL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Computers & Accessories \n                Accessories & Peripherals \n                Laptop Accessories \nPryce387 \nDicos74 \n\n❌Regular Price  : ₹  899    /- 😱"
     }
    ]
   },
   {
    "id": 20341,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683110629",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1199",
    "dicos": "52",
    "catagory": "Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   boAt Rockerz 103 Pro Bluetooth in Ear Neckband with Beast Mode(40ms Low Latency), ENx Tech, ASAP Charge(Fast Charge), Upto 20HRS Playback, Signature Sound, BT v5.3 & IPX4(Mint Pink)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AOa1bp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Nx8E8A"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oUYcgO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HzFkdJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42bvRBy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42dJf7Y"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LqVDL6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NuKFqv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NxyysM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p7haBe"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1199"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51gnMEfoBCL._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Electronics \n                Headphones, Earbuds & Accessories \n                Headphones \nPryce1199 \nDicos52 \n\n❌Regular Price  : ₹  1999    /- 😱"
     }
    ]
   },
   {
    "id": 20342,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683110756",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1099",
    "dicos": "63",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   boAt Stone Superman Edition 5W Bluetooth Speaker with Upto 4 Hours Playtime, 52mm Drivers, TWS Feature, IPX7 and Dual Mode(Krypton Blue)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lu7aJv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42dJHmG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NyP7EN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40ZZqox"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vyx2sA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p5CeIg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vr8z8k"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42gQ6xB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vtr5gr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3APlEPu"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1099"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71Nj52KXlzL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Home Audio \n                Speakers \nPryce1099 \nDicos63 \n\n❌Regular Price  : ₹  1999   /- 😱"
     }
    ]
   },
   {
    "id": 20343,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683111160",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "265",
    "dicos": "71",
    "catagory": "Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   GLink GW-500C WiFi Dongle 500 Mbps Mini Wireless Network USB Wi-Fi Adapter for PC/Desktop/Laptop (Supports Windows XP/7/8/8.1, Mac OS and Linux, Soft AP Mode, USB 2.0) -Black✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41VRKos"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HAW7gG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hz1IUM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LQ909e"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NQzQ2v"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44oNdwr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3njW3Ls"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/412fVQV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LualAV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LOYe2K"
     },
     {
      "type": "plain",
      "text": "\n\n @265\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/31Tp0da20qL.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Computers & Accessories \n                Networking Devices \n                Network Adapters \nPryce265 \nDicos71 \n\n❌Regular Price  : ₹  799   /- 😱"
     }
    ]
   },
   {
    "id": 20344,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683111283",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "2295",
    "dicos": "NaN",
    "catagory": "Watches",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Fastrack Analog Silver Dial Women's Watch-NM6112SM01 / NL6112SM01/NN6112SM01✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40YTUCs"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44rZWyn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p2uxT0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41YUnGa"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ATG9KA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VutkQM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LQlkX1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HBiF0F"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nnpThU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p5pMrV"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@2295"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71P9I6WANuL._UY741_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Watches"
     },
     {
      "type": "plain",
      "text": "\n\n                Fashion \n                Onam 2021 \n                Watches and fashion jewellery \nPryce2295 \nDicosNaN \n\n❌Regular Price  : ₹ 1999   /- 😱"
     }
    ]
   },
   {
    "id": 20345,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683111385",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "114",
    "dicos": "5",
    "catagory": "Grocery",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Swiss Vanilla Hot Chocolate (Size Variations) (100g Pack)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p6EYot"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40XAYnF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nuhFob"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44hKQvv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HyBvp7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NxUmV6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HypsIy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LNjx4O"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NGLvjY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40Y5E8u"
     },
     {
      "type": "plain",
      "text": "\n\n @114\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/41rzmqwRGnL._SX300_SY300_QL70_FMwebp_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Grocery"
     },
     {
      "type": "plain",
      "text": " & Gourmet Foods\n\n                Grocery & Gourmet Foods \n                Coffee, Tea & Beverages \n                 Hot Chocolate & Malted Drinks \nPryce114 \nDicos5 \n\n❌Regular Price  : ₹ 120   /- 😱"
     }
    ]
   },
   {
    "id": 20346,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683111487",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "182",
    "dicos": "78",
    "catagory": "Clothing",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Slay Day Men T-Shirt✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LuykQu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nhNJff"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42lk2ZA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42gTfxp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41XuLtm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/424f8Af"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p5FXWg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VyAJOY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3niaBLz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AQTL9M"
     },
     {
      "type": "plain",
      "text": "\n\n @182\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/712p1Lq4lFL._UX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Clothing"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Clothing & Accessories \n                Men \n                T-Shirts & Polos \nPryce182 \nDicos78 \n\n❌Regular Price  : ₹  799   /- 😱"
     }
    ]
   },
   {
    "id": 20347,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683111591",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "48",
    "dicos": "82",
    "catagory": "Grocery",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   NRIP Golden Shaheen Zahidi Dates (Khajur) 200 Gm✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42lqQWK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hzguei"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/420oVXW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AO92YN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44s1RD5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LZEiut"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LqFnKg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LwFIuK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lwgnku"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ALzWAC"
     },
     {
      "type": "plain",
      "text": "\n\n @48\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/81X0lhpwmcL._SX466_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Grocery"
     },
     {
      "type": "plain",
      "text": " & Gourmet Foods\n\n                Grocery & Gourmet Foods \n                Dried Fruits, Nuts & Seeds \n                Dried Fruits \nPryce48 \nDicos82 \n\n❌Regular Price  : ₹  270   /- 😱"
     }
    ]
   },
   {
    "id": 20348,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683111698",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "389",
    "dicos": "70",
    "catagory": "Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   HP v222w 64GB USB 2.0 Pen Drive (Silver)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HzKNkL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VHdVN7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44kK3K5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NxDD4k"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NxWtZ2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/4141X0S"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://www.amazon.in/HP-v222w-Flash-Drive-64GB/dp/B01LYLJ99X?th=1&amp;linkCode=sl1&amp;tag=avi1deals7-21&amp;linkId=cc42e6e0f05657eb8e73c9a2e392a744&amp;language=en_IN&amp;ref_=as_li_ss_tl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p4wsGP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p6H8EB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VrcIJo"
     },
     {
      "type": "plain",
      "text": "\n\n @389\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51weXj2hizL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Computers & Accessories \n                External Devices & Data Storage \n                 Pen Drives \nPryce389 \nDicos70 \n\n❌Regular Price  : ₹ 999   /- 😱"
     }
    ]
   },
   {
    "id": 20349,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683111804",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "512",
    "dicos": "21",
    "catagory": "Beauty",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Dove Cream Beauty Bathing Bar 100 g (Combo Pack of 8) With Moisturising Cream for Softer, Glowing Skin & Body - Nourishes Dry Skin more than Bar Soap✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VvxkjY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AMEC9t"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AV4Lmd"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44pZVeA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42h6uya"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3APMbMu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44hN2TL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lvtr9S"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LPaP6d"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LsWISD"
     },
     {
      "type": "plain",
      "text": "\n\n @512\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51jJq4jToUL._SX569_PIbundle-8,TopRight,0,0_AA569SH20_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Beauty"
     },
     {
      "type": "plain",
      "text": "\n\n                Beauty \n                Bath & Body \n                Cleansers \nPryce512 \nDicos21 \n\n❌Regular Price  : ₹  870  /- 😱"
     }
    ]
   },
   {
    "id": 20350,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683111930",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "424",
    "dicos": "50",
    "catagory": "Beauty",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Dove Relaxing Almond Cream Body Wash with Hibiscus Pump Bottle, Soft & Sweet Scent, Moisturizing Shower Gel with Naturally Derived Ingredients, Gentle Body Cleanser for Nourished & Smooth Skin, 1L✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LR8dVe"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44iSpSJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lwhxwm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/4104RDX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42iewXp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LNb40z"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42jLlTT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HzmDH0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vre5b2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vret9r"
     },
     {
      "type": "plain",
      "text": "\n\n @424\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/515ukNBlnfL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Beauty"
     },
     {
      "type": "plain",
      "text": "\n\n                Beauty \n                Bath & Body \n                Cleansers \nPryce424 \nDicos50 \n\n❌Regular Price  : ₹ 799   /- 😱"
     }
    ]
   },
   {
    "id": 20351,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683112060",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "3633",
    "dicos": "41",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Orient Electric I Tome Plus 1200mm 26W BLDC Energy Saving Ceiling Fan with Remote | 5 Star Rated | Decorative Ceiling Fan (Topaz Gold, Pack of 1)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/424i2VF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NC3KXU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NuRUyH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AV6urH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3APAGok"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LMVzXw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/412kD13"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42aaccO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LOL067"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p0oeiS"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@3633"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61pWp957wVL._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Heating, Cooling & Air Quality \n                Fans \nPryce3633 \nDicos41 \n\n❌Regular Price  : ₹  5499    /- 😱"
     }
    ]
   },
   {
    "id": 20352,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683112192",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "139",
    "dicos": "65",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Zebronics Zeb-Calyx Wired in Ear Earphones with Mic (Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nsRT3N"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lr6qov"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p5rf1z"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HWva7t"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42l7d14"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/4115b5t"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p6JenX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42g8wyq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44qAWYg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44gfmFU"
     },
     {
      "type": "plain",
      "text": "\n\n @139\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71AUNLjgcEL._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Headphones, Earbuds & Accessories \n                Headphones \nPryce139 \nDicos65 \n\n❌Regular Price  : ₹ 399  /- 😱"
     }
    ]
   },
   {
    "id": 20353,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683112314",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "7499",
    "dicos": "79",
    "catagory": "Luggage",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   AmazonBasics Polycarbonate Hard 74 cms suitcase(T1916-24_navy blue)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VovzF7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LRahfW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NwHUF8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HWvNhl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/4144QyK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LQtBdo"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42hwd9r"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LPuN0t"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NyxWDb"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44nrnte"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@7499"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/91fwzkTZj7L._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Luggage"
     },
     {
      "type": "plain",
      "text": " & Bags\n\n                Bags, Wallets and Luggage \n                Luggage \n                 Luggage Sets \nPryce7499 \nDicos79 \n\n❌Regular Price  : ₹  19999    /- 😱"
     }
    ]
   },
   {
    "id": 20354,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683112442",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "159",
    "dicos": "80",
    "catagory": "Sports",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   HAANS Shakeit Protein Shaker Bottle, 500ml (Color-Grey, Plastic, Pack of 1)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NzPqzk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/425HtX6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HWwiIf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41XXmid"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hyxh11"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3npcrdv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40Ybg2y"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40Yi6Vt"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LRrTZs"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AOdtCV"
     },
     {
      "type": "plain",
      "text": "\n\n @159\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/615vwtdTRBL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Sports"
     },
     {
      "type": "plain",
      "text": ", Fitness & Outdoors\n\n                Health & Personal Care \n                Diet & Nutrition \n                Sports Supplements \nPryce159 \nDicos80 \n\n❌Regular Price  : ₹  699   /- 😱"
     }
    ]
   },
   {
    "id": 20355,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683112566",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "4999",
    "dicos": "71",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Skullcandy Riff Wireless 2 on-Ear Headphones Black✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44lJqjx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lu3Wpv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/410dtu0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nig1pT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oV8YUi"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42BMJ4D"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AQHNN8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LPeHUN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44Bk6q3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42geC1N"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@4999"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71cKqKY3dmL._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Headphones, Earbuds & Accessories \n                Headphones \nPryce4999 \nDicos71 \n\n❌Regular Price  : ₹  10999  /- 😱"
     }
    ]
   },
   {
    "id": 20356,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683112666",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "499",
    "dicos": "NaN",
    "catagory": "Clothing",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Kashvi Sarees Set of 2 Georgette Sarees with Blouse Piece✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LrI1zh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44qD4ze"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ALESFE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nmDCpe"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LwlgtQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nsUsCX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p0r3jY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42nHPYR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44r32CM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44hRZMl"
     },
     {
      "type": "plain",
      "text": "\n\n @499\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/91IPJ3pI0NL._UX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Clothing"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Clothing & Accessories \n                Women \n                Ethnic Wear \nPryce499 \nDicosNaN"
     }
    ]
   },
   {
    "id": 20357,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683112791",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1944",
    "dicos": "19",
    "catagory": "Pet",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Whiskas Adult Wet Cat Food (1+Years), Chicken in Gravy Flavour, 48 Pouches (48 x 85g)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VrhRBe"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HB3W62"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vq2xou"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3APl2ZX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LvyzuE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41f7IsV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NvjL1V"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40YcPxs"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NsORHs"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42gfnb9"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1944"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/41AJU4KMDdL._PIbundle-48,TopRight,0,0_AA522SH20_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Pet"
     },
     {
      "type": "plain",
      "text": " Supplies\n\n                Pet Supplies \n                Cats \n                Food \nPryce1944 \nDicos19 \n\n❌Regular Price  : ₹  2499  /- 😱"
     }
    ]
   },
   {
    "id": 20358,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683112896",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1819",
    "dicos": "39",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Candes 1 Litre ABS Insta Electric Instant Water Heater (Geyser) Multiple Safety System 3000 Watts (Ivory)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NyLmz7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LvGgkH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LvO9Xr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3APzstm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vrjcbc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42eD2JD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p1OR72"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3APlG9Z"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hx5vle"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44s8sxl"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1819"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51dUnnjvGuL._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Heating, Cooling & Air Quality \n                Water Heaters & Geysers \nPryce1819 \nDicos39 \n\n❌Regular Price  : ₹   2499   /- 😱"
     }
    ]
   },
   {
    "id": 20359,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683113002",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "15790",
    "dicos": "25",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Samsung 7 kg Fully-Automatic Top Loading Washing Machine (WA70A4002GS/TL, Imperial Silver, Diamond drum)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/417EIDm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/424mYtF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/420w7TW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AR4nFm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vp7o9B"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HzrR5A"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40ZfZAX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3APcP88"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3np9mu2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41UF3u6"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@15790"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/510mV2GAtkL._SY445_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Large Appliances \n                Washing Machines & Dryers \nPryce15790 \nDicos25 \n\n❌Regular Price  : ₹ 19999    /- 😱"
     }
    ]
   },
   {
    "id": 20360,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683113126",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "899",
    "dicos": "70",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Mivi Roam 2 Bluetooth 5W Portable Speaker,24 Hours Playtime,Powerful Bass, Wireless Stereo Speaker with Studio Quality Sound,Waterproof, Bluetooth 5.0 and in-Built Mic with Voice Assistance-Black✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LPHsk4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42jXAjq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40XL08j"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nk5V7Y"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LqMv9u"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p6OgAR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42ikqYz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p7u5De"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44qFTAk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44mf4NP"
     },
     {
      "type": "plain",
      "text": "\n\n @899\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/81QP3C+rTbL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Home Audio \n                Speakers \nPryce899 \nDicos70 \n\n❌Regular Price  : ₹  1999    /- 😱"
     }
    ]
   },
   {
    "id": 20361,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683113230",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "699",
    "dicos": "65",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Mivi Play Bluetooth Speaker with 12 Hours Playtime. Wireless Speaker Made in India with Exceptional Sound Quality, Portable and Built in Mic-Black✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44mEYRC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oXKDgB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VrkK50"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AM7MFw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VrkSS4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HzTewr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44mLLuB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LrLUUT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AR2glc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HAYZKi"
     },
     {
      "type": "plain",
      "text": "\n\n @699\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61UJnlIHF9S._SY450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Home Audio \n                Speakers \nPryce699 \nDicos65 \n\n❌Regular Price  : ₹    1499  /- 😱"
     }
    ]
   },
   {
    "id": 20362,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683113341",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1195",
    "dicos": "77",
    "catagory": "Shoes",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Red Tape Men'ss Sneakers✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AMiSuj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/422gdZw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nikaKt"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HyBm5l"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AMNMCT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vtv5xf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40ZhKhx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p42lze"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42loh75"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Nz33P7"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1195"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/5149FttjJuL._UY625_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Shoes"
     },
     {
      "type": "plain",
      "text": " & Handbags\n\n                Shoes & Handbags \n                Shoes \n                Men's Shoes \nPryce1195 \nDicos77 \n\n❌Regular Price  : ₹   3999  /- 😱"
     }
    ]
   },
   {
    "id": 20363,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683113446",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "599",
    "dicos": "70",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Mivi ThunderBeats 2 Upgraded Audio Bluetooth Wireless in Ear Earphones with Superior Sound, Powerful Bass, 14 Hours Playtime, Comfortable Fit, Bluetooth 5.0 with mic (Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42giS1h"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41WE8cP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40Ucmwd"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LsdEZA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AQLJgS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AOmgol"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44nwSYU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://www.amazon.in/dp/B09SBCLGZM?th=1&amp;linkCode=sl1&amp;tag=avi1deals8-21&amp;linkId=c1d8fb633c71b15619bd3884bbc1c9ee&amp;language=en_IN&amp;ref_=as_li_ss_tl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vp7Btm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44lZPo6"
     },
     {
      "type": "plain",
      "text": "\n\n @599\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51eZgOyUfaL._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Headphones, Earbuds & Accessories \n                Headphones \nPryce599 \nDicos70 \n\n❌Regular Price  : ₹  1499   /- 😱"
     }
    ]
   },
   {
    "id": 20364,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683113571",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "999",
    "dicos": "71",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Noise Buds Ace Truly Wireless Half in-Ear Earbuds with 24H Playtime, Instacharge(10 min=120 min), 13mm Driver, Tru Bass and BT v5.3 (Snow White)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LRxijc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NBQ4Mz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LN3MLk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42k0xR2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44rxa15"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44kTXeJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VonUGV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NzIAK3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LwPInM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44rxqx5"
     },
     {
      "type": "plain",
      "text": "\n\n @999\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/41yB2tT+0AL._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Headphones, Earbuds & Accessories \n                Headphones \nPryce999 \nDicos71 \n\n❌Regular Price  : ₹   1999  /- 😱"
     }
    ]
   },
   {
    "id": 20365,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683113675",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "349",
    "dicos": "42",
    "catagory": "Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Portronics Toad 25 Wireless Optical Mouse with 2.4GHz, USB Nano Dongle, 1200 DPI Resolution, Optical Orientation for Laptops, Desktops & Macbooks(Red)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41XGgB2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HuXTzV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vq3a1s"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LPWypS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42guCAQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/410mLWY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42kC0ve"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vt9VPR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p5Rjtm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nxjcKa"
     },
     {
      "type": "plain",
      "text": "\n\n @349\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51t6xeXQEML._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Computers & Accessories \n                Accessories & Peripherals \n                Keyboards, Mice & Input Devices \nPryce349 \nDicos42 \n\n❌Regular Price  : ₹   599  /- 😱"
     }
    ]
   },
   {
    "id": 20366,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683113802",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "2450",
    "dicos": "59",
    "catagory": "Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Portronics Kronos Y1 Bluetooth Calling Smart Watch with Multiple Sports Modes, Full Touch 1.75\" Screen, SpO2 Monitoring, Sleep Tracking, in-Built Music Controller, BP & Heart Rate Monitor(Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nnvsgm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44pmJe9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LN2yQf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Nz4HAh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3npvW5K"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40TCAyM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42bL9Gs"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LrdZeT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LqPHlu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NyPHCp"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@2450"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51dZeOOlwyL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Electronics \n                Wearable Technology \n                 Smart Watches \nPryce2450 \nDicos59 \n\n❌Regular Price  : ₹  3999   /- 😱"
     }
    ]
   },
   {
    "id": 20367,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683113923",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "3600",
    "dicos": "28",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Philips Audio MMS2625B 32W 2.1 Channel Wireless Bluetooth, Wired Multimedia Computer Speaker - Black✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nsqpLJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HzfAhO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NyQoMf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40XOE1Z"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LreGVx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nq8FAA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AOrNv9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42fCsL6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44qB3D8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LNw9ca"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@3600"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/31gkDcLFx9L._SY300_SX300_QL70_FMwebp_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Home Audio \n                Speakers \nPryce3600 \nDicos28 \n\n❌Regular Price  : ₹  4499   /- 😱"
     }
    ]
   },
   {
    "id": 20368,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683114030",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1399",
    "dicos": "77",
    "catagory": "Watches",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Maxima New Samurai Smartwatch 1.85\", 1st Time Call Accept Feature, Hindi Language Support, 600Nits Brightness, Longer Battery, in-App GPS,100+ Watch Faces, Multisport Modes,HR/SpO2 (Gold Peach)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LsUOSb"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41YIfVH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42lgxSC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AOsi8v"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ANodRR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44qBltI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41X97Fn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p6SUPj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41Z9tLQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B7Mhzn"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1399"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61VoXw2cuiL._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Watches"
     },
     {
      "type": "plain",
      "text": "\n\n                Fashion \n                Women's clothing \n                SS23 Trends \nPryce1399 \nDicos77 \n\n❌Regular Price  : ₹    4999 /- 😱"
     }
    ]
   },
   {
    "id": 20369,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683114133",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1799",
    "dicos": "63",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Amazon Basics 500W Juicer Mixer Grinder with 2 Jars, White and Blue✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HBbB4i"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LRATxI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lt7WGL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nkaqiS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ni0kPz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AMmGM7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Nv2d5R"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44qkB68"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/410lZsY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nqajCg"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1799"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71JC0Vk75IL._SX466_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Home Appliances \n                Small Kitchen Appliances \nPryce1799 \nDicos63 \n\n❌Regular Price  : ₹  3499  /- 😱"
     }
    ]
   },
   {
    "id": 20370,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683114239",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "489",
    "dicos": "45",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Pigeon Ivory 1000W Dry Iron✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41XDBar"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AMDlim"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lqt0O5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p2TSfI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p5U264"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LsiwxQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AOr0dD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nnmtvB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3APD8v6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AMnoJh"
     },
     {
      "type": "plain",
      "text": "\n\n @489\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/41psIgdZkCL._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Home Appliances \n                Vacuum, Cleaning & Ironing \nPryce489 \nDicos45 \n\n❌Regular Price  : ₹   799  /- 😱"
     }
    ]
   },
   {
    "id": 20371,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683114341",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "499",
    "dicos": "80",
    "catagory": "All",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Yashika Womens Solid Net Saree With Blouse Piece✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vp3UUt"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3APM9nS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LNyhka"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42iW7tD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LOV6E5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3niprSh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/413R13g"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/420qqW3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LuSlX5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HBlsam"
     },
     {
      "type": "plain",
      "text": "\n\n @499\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/616wDPFsYaL._UX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#All"
     },
     {
      "type": "plain",
      "text": "\n\n                Clothing & Accessories \n                Women \n                Ethnic Wear \nPryce499 \nDicos80 \n\n❌Regular Price  : ₹ 1999   /- 😱"
     }
    ]
   },
   {
    "id": 20372,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683114445",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "599",
    "dicos": "70",
    "catagory": "Clothing",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   FUR JADEN Anti Theft Number Lock Backpack Bag with 15.6 Inch Laptop Compartment, USB Charging Port & Organizer Pocket for Men Women Boys Girls✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VtcYaL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ns7IaQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NvrQUj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vty8po"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VvKsFM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/412wJY1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nnFrlM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p48mvO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42lzBjY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44j5f3n"
     },
     {
      "type": "plain",
      "text": "\n\n @599\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61egMfcDWlL._UX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Clothing"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Computers & Accessories \n                Accessories & Peripherals \n                Laptop Accessories \nPryce599 \nDicos70 \n\n❌Regular Price  : ₹  1499    /- 😱"
     }
    ]
   },
   {
    "id": 20373,
    "type": "message",
    "date": "2023-05-03",
    "date_unixtime": "1683114554",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "359",
    "dicos": "64",
    "catagory": "Tools",
    "text_entities": [
     {
      "type": "plain",
      "text": "🚀🚀   Axmon® Extension Cord [FIRE Proof] [Shock Proof][6 Month Warranty] 10 Amp [1.9 Meter Power Cord] 4 Socket Extension Board for Home Office- Grey✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41fgwyZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41ZV16b"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LQqceM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AVjvl3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LSgdWl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LPfghc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42hks34"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NElJ05"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lri2YD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LPp2QB"
     },
     {
      "type": "plain",
      "text": "\n\n @359\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71mTjclgQvL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Tools"
     },
     {
      "type": "plain",
      "text": " & Home Improvement\n\n                Home Improvement \n                Electrical \n                Adapters & Multi-Outlets \nPryce359 \nDicos64 \n\n❌Regular Price  : ₹  899   /- 😱"
     }
    ]
   },
  {
    "id": 19916,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1682986983",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "265",
    "dicos": "63",
    "catagory": "Baby",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Little Angel Super Soft Cleansing Baby Wipes Lid Pack, 288 Count, Enriched with Aloe vera & Vitamin E, pH balanced, Dermatologically Tested & Alcohol-free, Pack of 4,72 count/pack✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LKFDFf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41RoIq0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41UStpQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LpWHyK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Nu7pqQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41V4T1c"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oVRWWe"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3APbPkg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3APbRso"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LKFQbv"
     },
     {
      "type": "plain",
      "text": "\n\n @265\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51aSqBqm+WL._SY450_PIbundle-4,TopRight,0,0_AA450SH20_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Baby"
     },
     {
      "type": "plain",
      "text": "\n\n                Baby \n                Potty Training & Step Stools \n                 Wet Wipes \nPryce265 \nDicos63 \n\n❌Regular Price  : ₹ 649   /- 😱"
     }
    ]
   },
   {
    "id": 19917,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1682987093",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "2298",
    "dicos": "67",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Philips Audio TWS TAT2206 True Wireless in Ear Earbuds with IPX4 Water Resistance, 18 Hour Playtime (6+12), C-Type Charging and Voice Assistant (Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vr8uBx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41SXuzu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LNyZ13"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LnHSwS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42cnWnp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vln5yq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oWTnns"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nfvuqK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AHTJ3E"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40UxQJh"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@2298"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51TXjsgCahL._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Headphones, Earbuds & Accessories \n                Headphones \nPryce2298 \nDicos67 \n\n❌Regular Price  : ₹ 5999    /- 😱"
     }
    ]
   },
   {
    "id": 19918,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1682987201",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "14300",
    "dicos": "71",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Play MP-1 Smart WIFI 3D 4k Full HD LED Android 8.0 | Genuine Multifunction| Bluetooth 4D keystone | 300-inch Display |1920x1080P | VGA, USB, HDMI | Linux Based Portable Mini Digital Projector LCD Panel Home Theater with Remote Control✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VoNATI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vu7w7O"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40Z5LAq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LpmpDD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lmr9K7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oTMcw9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40UymHd"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44hyHGN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44bD4mG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oZYjrF"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@14300"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/31nT14r8BQL._SY450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Home Theater, TV & Video \n                 Projectors \nPryce14300 \nDicos71 \n\n❌Regular Price  : ₹ 24999   /- 😱"
     }
    ]
   },
   {
    "id": 19919,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1682987329",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "208",
    "dicos": "42",
    "catagory": "Beauty",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Axe Sports Blast 3 In 1 Body, Face & Hair Wash for Men, Long-Lasting Refreshing & Energizing Citrus Fragrance for Up To 12hrs Removes Odor & Bacteria, No Parabens, Dermatologically Tested, 400ml✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42conOz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p17XdC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oWTKhQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LKdy0B"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LL9opl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3niAmLx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Nu7MkX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Nu8qiE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nsijCF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HtY0vw"
     },
     {
      "type": "plain",
      "text": "\n\n @208\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51PymUsGkvL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Beauty"
     },
     {
      "type": "plain",
      "text": "\n\n                Beauty \n                Bath & Body \n                Cleansers \nPryce208 \nDicos42 \n\n❌Regular Price  : ₹ 360   /- 😱"
     }
    ]
   },
   {
    "id": 19920,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1682987445",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "162",
    "dicos": "50",
    "catagory": "Beauty",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Brut Original Deodorant For Men, 200ml✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LKGztf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AKLGmT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LKLMBa"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nhBxuT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VlnGAa"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LIww6T"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44lxsGI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NxzM7o"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oXY14q"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40VCeri"
     },
     {
      "type": "plain",
      "text": "\n\n @162\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/31zlrHhKLkL.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Beauty"
     },
     {
      "type": "plain",
      "text": "\n\n                Beauty \n                Bath & Body \n                Deodorants & Antiperspirants \nPryce162 \nDicos50 \n\n❌Regular Price  : ₹ 325   /- 😱"
     }
    ]
   },
   {
    "id": 19921,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1682987578",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "299",
    "dicos": "85",
    "catagory": "All",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Yashika women's art silk kalamkari bhagalpuri and kashmiri style soft saree with blouse piece (Multi-Color_Free_Size✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41ZJ00D"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42x8ylR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42j4cyx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40ZwZqG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44lTjNY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AKWuBy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p0RUfN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AKWwJG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AKXOEA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44bDUjk"
     },
     {
      "type": "plain",
      "text": "\n\n @299\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/91vQxygQluL._UX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#All"
     },
     {
      "type": "plain",
      "text": "\n\n                Clothing & Accessories \n                Women \n                Ethnic Wear \nPryce299 \nDicos85 \n\n❌Regular Price  : ₹  999   /- 😱"
     }
    ]
   },
   {
    "id": 19922,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1682987707",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "10999",
    "dicos": "71",
    "catagory": "Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   ZEBRONICS  27inch (68.5 cm) LED 920 X 1080 (FHD) Pixels Gaming Monitor, 165Hz, IPS, Ultra Slim, FHD 1920x1080, Display Port, HDMI, 300nits, USB, Built in Speaker, Widescreen - ZEB-A27FHD, Black✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oZo1MU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NveaJ0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LKHnhL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AQAO6N"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hu2qTh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HuQdgO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hu2BOr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VlBJpi"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NpPO3j"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LLZrYK"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@10999"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61uPbYJ3GvL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Computers & Accessories \n                2021 \n                 Gaming Monitors \nPryce10999 \nDicos71 \n\n❌Regular Price  : ₹  24999    /- 😱"
     }
    ]
   },
   {
    "id": 19923,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1682987842",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1799",
    "dicos": "63",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Amazon Basics 500W Juicer Mixer Grinder with 2 Jars, White and Blue✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oZobnu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Nvzv4W"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HqXvCo"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40VCXc0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p26SSA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LpYljU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42g0gyA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42i4NAz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AHFcFp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41av4Qp"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1799"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71JC0Vk75IL._SX466_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Home Appliances \n                Small Kitchen Appliances \nPryce1799 \nDicos63 \n\n❌Regular Price  : ₹ 3499   /- 😱"
     }
    ]
   },
   {
    "id": 19924,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1682987979",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "9999",
    "dicos": "47",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Havells FAB Absoulety Safe RO + UV Water Purifer, GHWRHFB015, Beige & White✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lkf1JP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44hf9SX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nhCnrx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41U2Had"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41Rr2gI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HuQCQm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NtdtQc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AGnX7r"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40TF54i"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AL6hHK"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@9999"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/518NK6aOSfL._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Home Appliances \n                Water Purifiers & Accessories \nPryce9999 \nDicos47 \n\n❌Regular Price  : ₹   16999  /- 😱"
     }
    ]
   },
   {
    "id": 19925,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1682988110",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "26990",
    "dicos": "64",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Samsung Galaxy S20 FE 5G (Cloud Mint, 8GB RAM, 128GB Storage)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oXZ2JM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AIE2t5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p19r7G"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44koUQf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42i55aD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vlp59U"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ALgWlI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NtdIuA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AKFpaI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hu5Cy7"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@26990"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/812yohjGZ2L._SY606_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Mobiles & Accessories \n                Smartphones & Basic Mobiles \nPryce26990 \nDicos64 \n\n❌Regular Price  : ₹  49999  /- 😱"
     }
    ]
   },
   {
    "id": 19926,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1682988242",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "399",
    "dicos": "60",
    "catagory": "Clothing",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Chromozome Men Mens Tshirts✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Ls8nRE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42gOOT6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oWVAPM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vktl9K"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44lUU6q"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LrNy8O"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AL6IBS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44k8yXJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41V7Ajk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44nuQrF"
     },
     {
      "type": "plain",
      "text": "\n\n @399\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71X7pa5hBTL._UX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Clothing"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Clothing & Accessories \n                Men \n                T-Shirts & Polos \nPryce399 \nDicos60 \n\n❌Regular Price  : ₹   899  /- 😱"
     }
    ]
   },
   {
    "id": 19927,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1682988372",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "4264",
    "dicos": "20",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Wonderchef Instant Water Heater (3L)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hu5VJh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Nv1VMr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Nsc8cm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LloHUl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HuJe7O"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40XLyep"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AKZHkE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LLhuOH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nkvztc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44nuX6z"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@4264"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/31GeFORTDzL._SX300_SY300_QL70_FMwebp_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Heating, Cooling & Air Quality \n                Water Heaters & Geysers \nPryce4264 \nDicos20 \n\n❌Regular Price  : ₹  4999   /- 😱"
     }
    ]
   },
   {
    "id": 19928,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1682988503",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "199",
    "dicos": "67",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Kolorr Stitch 7L Plastic Pedal Modern Design Waste Bin (Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vq4tgY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AMvfGw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40T8Hyz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40VDZ7O"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ANeDi5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40VEa2Y"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Np9GDD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40PSI4d"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41VbUin"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44kBizD"
     },
     {
      "type": "plain",
      "text": "\n\n @199\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/816-WyQmYgL._SX466_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Home Storage & Organisation \n                Waste & Recycling \nPryce199 \nDicos67 \n\n❌Regular Price  : ₹  579  /- 😱"
     }
    ]
   },
   {
    "id": 19929,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1682988637",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1099",
    "dicos": "45",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Philips Audio Upbeat Tauh201 Wired On Ear Headphones with Mic (Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44voiaK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3niDcQH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41RsgbO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AMrJw6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HwKiIh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nmilfs"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HuS4SO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NteRSU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NvB1UG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41XBROo"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1099"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61Jpe4jw4rL._SY879_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Headphones, Earbuds & Accessories \n                Headphones \nPryce1099 \nDicos45 \n\n❌Regular Price  : ₹ 1499   /- 😱"
     }
    ]
   },
   {
    "id": 19930,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1682988770",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "3099",
    "dicos": "69",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Skullcandy Jib True 2 Wireless in-Ear Earbuds - Black✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AJJDiT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hu54sb"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42fnrJi"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vq5e9O"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42g21fa"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Nu9Rh5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LnCQjU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41VI39t"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HyN6Vh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41SrueB"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@3099"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61h5ALBhEtL._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Headphones, Earbuds & Accessories \n                Headphones \nPryce3099 \nDicos69 \n\n❌Regular Price  : ₹  8499    /- 😱"
     }
    ]
   },
   {
    "id": 19931,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1682988898",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "299",
    "dicos": "25",
    "catagory": "Health",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Gillette Venus Snap Hair Remover| Women Body Razor| Pack of 1 | Compact case|Soap gel bar with avocado✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HuMnEO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40OR17e"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41VUbY4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oTZFE9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VoR9JA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LlpJjb"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LiC5si"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oOjNYf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42fot88"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p0Rzdb"
     },
     {
      "type": "plain",
      "text": "\n\n @299\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/511dYTkQEBL._SX300_SY300_QL70_FMwebp_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Health"
     },
     {
      "type": "plain",
      "text": " & Personal Care\n\n                Health & Personal Care \n                Personal Care \n                Shaving, Waxing & Beard Care \nPryce299 \nDicos25 \n\n❌Regular Price  : ₹  399   /- 😱"
     }
    ]
   },
   {
    "id": 19932,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1682989027",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "183",
    "dicos": "43",
    "catagory": "Grocery",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Real Activ Coconut Water Bottle, 2 x 1000 ml✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lr07kQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NpuKtG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AL0To8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HsKFnf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NxhkMb"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Nuarvh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LKh5vT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41S2N2b"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nhEQlN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40ORcPW"
     },
     {
      "type": "plain",
      "text": "\n\n @183\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71jdxdvh4lL._SX679_PIbundle-2,TopRight,0,0_AA679SH20_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Grocery"
     },
     {
      "type": "plain",
      "text": " & Gourmet Foods\n\n                Grocery & Gourmet Foods \n                Coffee, Tea & Beverages \n                Juices \nPryce183 \nDicos43 \n\n❌Regular Price  : ₹ 320    /- 😱"
     }
    ]
   },
   {
    "id": 19933,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1682989191",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "239",
    "dicos": "80",
    "catagory": "Clothing",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Cloth Theory Boys & Girls Cotton All Over Print Pajama(CTIN_004_Multi 2_0 Months-3 Months)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41XDMCK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LKF8L8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LKjRRN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42cETy6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3niQigS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AIKDUj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41W51xg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41U5mAJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40Z9PRc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40PmXbG"
     },
     {
      "type": "plain",
      "text": "\n\n @239\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/81gRqqZKpKL._UY741_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Clothing"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Fashion \n                Apparel Steal Deals \n                Apparel_1 \nPryce239 \nDicos80 \n\n❌Regular Price  : ₹  899   /- 😱"
     }
    ]
   },
   {
    "id": 19934,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1682989321",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "322",
    "dicos": "64",
    "catagory": "Sports",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Nivia NEO-8 Women Lower✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42xcIdt"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40WvEB9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lg2Ihy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3npRMWz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LlqCbv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LM2sIy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AJN8G1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NqJWH1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p0VSoH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hu1FJM"
     },
     {
      "type": "plain",
      "text": "\n\n @322\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/618Dk4MSLAL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Sports"
     },
     {
      "type": "plain",
      "text": ", Fitness & Outdoors\n\n                Clothing & Accessories \n                Women \n                Western Wear \nPryce322 \nDicos64 \n\n❌Regular Price  : ₹   799  /- 😱"
     }
    ]
   },
   {
    "id": 19935,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1682989449",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "370",
    "dicos": "74",
    "catagory": "Shoes",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Amazon Brand - Eden & Ivy Women's Travel kit bag (Pack of 3)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LMGTaE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NqKr3R"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AIjWze"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44dof3b"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HwM4ZX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hr1crK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hurj0U"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VpH276"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VuRFpp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Ln5y4n"
     },
     {
      "type": "plain",
      "text": "\n\n @370\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71aLz+9ozIL._UX500_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Shoes"
     },
     {
      "type": "plain",
      "text": " & Handbags\n\n                Fashion \n                Pongal || Fashion accessories \n                Handbags \nPryce370 \nDicos74 \n\n❌Regular Price  : ₹ 1199    /- 😱"
     }
    ]
   },
   {
    "id": 19936,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1682989581",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1823",
    "dicos": "55",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Bajaj Grace BBD Plus Ceiling Fan 1200mm, Timber Golden, (251128)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AQEwNL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41Tufwt"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40PUNNz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42jbx17"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/410Dutb"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42cG1lk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oOlhSj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42fpAVm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41VdUXV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lo7YzR"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1823"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/41gqtUQq-eL._SX466_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Heating, Cooling & Air Quality \n                Fans \nPryce1823 \nDicos55 \n\n❌Regular Price  : ₹  2999   /- 😱"
     }
    ]
   },
   {
    "id": 19937,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1682989713",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "8900",
    "dicos": "19",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   PHILIPS HL7707/00 750W Mixer Grinder with 10-Year Warranty on product registration, 4 Jars including Blender Jar and Food Processor, Black✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44lDhE4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AHIOXZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p0ABeV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LIYdO0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VmN9cs"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40XOkjP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AMafjc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LLcJEM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41Stm77"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hu977J"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@8900"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61D71DmchIL._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Home Appliances \n                Small Kitchen Appliances \nPryce8900 \nDicos19 \n\n❌Regular Price  : ₹  9999   /- 😱"
     }
    ]
   },
   {
    "id": 19938,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1682989821",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "329",
    "dicos": "84",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Tygot Adjustable Aluminium Alloy Tripod Stand Holder for Mobile Phones & Camera, 360 mm -1060 mm (42 Inch), 1/4 inch Screw + Mobile Holder Bracket✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oY39FI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vkmg97"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nsn2nT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VrePgj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LMRfaT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oU1a5f"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42bE3S6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p3mVj1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hu9lM7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hw7SoF"
     },
     {
      "type": "plain",
      "text": "\n\n @329\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51jx8hycZUL._SY450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Cameras & Photography \n                Accessories \nPryce329 \nDicos84 \n\n❌Regular Price  : ₹   999  /- 😱"
     }
    ]
   },
   {
    "id": 19939,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1682989952",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "520",
    "dicos": "NaN",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   WONDER Plastic Slim Premium Fridge Bottle Set,6 Bottles,1000 ml, Red Color✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oTRnfz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NxF3vI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://www.amazon.in/dp/B08WCSYC32?th=1&amp;linkCode=sl1&amp;tag=deals103-21&amp;linkId=c0cdc825ab023f1db60f25aea712d4ad&amp;language=en_IN&amp;ref_=as_li_ss_tl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44idkFn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40VyUwx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3norbcz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LlOJ9T"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44vrdjI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44bJiTA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NokqlI"
     },
     {
      "type": "plain",
      "text": "\n\n @520\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/41gisIKE9gL._SX300_SY300_QL70_FMwebp_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Dining \n                Kitchen Storage & Containers \nPryce520 \nDicosNaN"
     }
    ]
   },
   {
    "id": 19940,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1682990083",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "239",
    "dicos": "40",
    "catagory": "Clothing",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Dixcy Scott Men VEST✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LHcsTn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Nwsktq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VuSyhJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AHaYSW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3niGcMX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41UXBdO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41W6V0S"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hu9Q8X"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VkmLQx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VkBTxp"
     },
     {
      "type": "plain",
      "text": "\n\n @239\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/81sf2y8INgL._UX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Clothing"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Clothing & Accessories \n                Men \n                Innerwear \nPryce239 \nDicos40 \n\n❌Regular Price  : ₹   399  /- 😱"
     }
    ]
   },
   {
    "id": 19941,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1682990212",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "358",
    "dicos": "64",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   wolpin Square Plastic Dinner Plate Set (Set of 10 Pcs) Colorful Snacks/Breakfast Plate with Stand✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VrfzSD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42gJvD5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AKJoUI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40UEM9h"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hu3c2u"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NpxSFW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42j9XfD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42esARH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Nv6MgD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42b0BCw"
     },
     {
      "type": "plain",
      "text": "\n\n @358\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71UEJkYnTvL._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Dining \n                Tableware \nPryce358 \nDicos64 \n\n❌Regular Price  : ₹   899   /- 😱"
     }
    ]
   },
   {
    "id": 19942,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1682990344",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "189",
    "dicos": "87",
    "catagory": "Tools",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Wolpin Kitchen Wall Stickers Wood Wallpaper DIY PVC Shelf Liner, Furniture, Almirah, Table Top, Wardrobe, Kitchen Cupboard Decal, Mahogany Brown✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NpcW1P"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hwu2Hk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nkJPC9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LL0FTX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HuCfMc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nnKu5W"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NugCPL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HuVGUS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hud24h"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VpmLPp"
     },
     {
      "type": "plain",
      "text": "\n\n @189\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51kdwtY1KvL._SX300_SY300_QL70_FMwebp_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Tools"
     },
     {
      "type": "plain",
      "text": " & Home Improvement\n\n                Home Improvement \n                Painting Supplies, Tools & Wall Treatments \n                Wallpaper & Wallpapering Supplies \nPryce189 \nDicos87 \n\n❌Regular Price  : ₹  799   /- 😱"
     }
    ]
   },
   {
    "id": 19943,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1682990475",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "747",
    "dicos": "79",
    "catagory": "Clothing",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Lavie Sport Bristol Medium 55 cms Duffle Bag for Travel | Travel Duffle Bag✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LLko68"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44gs9bn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p1e95m"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VmOuQw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vp8TEH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nhI3Sn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lmx9mc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LM56xY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HwnLLA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HwOqYN"
     },
     {
      "type": "plain",
      "text": "\n\n @747\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/41RkU4rQg+L._SY300_SX300_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Clothing"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Fashion \n                Carry ons to flex \n                Duffles \nPryce747 \nDicos79 \n\n❌Regular Price  : ₹   2999  /- 😱"
     }
    ]
   },
   {
    "id": 19944,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1682990605",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "4499",
    "dicos": "44",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   CROMPTON Immensa Smart 9 Watts Wi-Fi Enabled LED Bulb Compatible with Alexa and Google Assistant (Immensa Music Sync E27, Pack of 8)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HutuS8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41W7W9c"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p1P3TE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42gTyrS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42d1zhr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NqMAfV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p1Pr4y"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42jdMBz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oW07Sq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3npUo6P"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@4499"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/81DbdTmGhmL._SX466_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Indoor Lighting \n                Light Bulbs \nPryce4499 \nDicos44 \n\n❌Regular Price  : ₹  6499   /- 😱"
     }
    ]
   },
   {
    "id": 19945,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1682990740",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "2753",
    "dicos": "68",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   LIVINGBASICS 3 Layer Finest Clothes Stand for Drying/Cloth Drying Stand/Cloth Stand for Drying Clothes Foldable/Cloth Drying Stand for Balcony/Stainless Steel Dryer Rack (Blue + 18 Rod Clips)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NtyFFG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LlQpAd"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LNP1YQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LIZRiu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AImIo8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vlv3HQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NuSjS2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AIekoP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AIeqwH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41VYFhB"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@2753"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71Lfd3jHblL._SX522_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Home Storage & Organisation \n                Laundry Organization \nPryce2753 \nDicos68 \n\n❌Regular Price  : ₹  7999    /- 😱"
     }
    ]
   },
   {
    "id": 19946,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1682990872",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "13723",
    "dicos": "61",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   LG Soundbar S40Q, 300W Dolby Digital Soundbar for TV with Subwoofer, 2.1Ch Home Theatre System, Deep Bass, Bluetooth, HDMI✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VpcmTD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vrh9E3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LLf16Q"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40TeBzJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AMAGFq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/425LTwC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41Tx28V"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40PpQt2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40WQRLc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41aAD1f"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@13723"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/31bGd+ofr4L._SX450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Home Audio \n                Speakers \nPryce13723 \nDicos61 \n\n❌Regular Price  : ₹  24999    /- 😱"
     }
    ]
   },
   {
    "id": 19947,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1682991001",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1149",
    "dicos": "57",
    "catagory": "Clothing",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Real Basics Boy's Regular Tshirt(Pack of 6)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41S6Onh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42gYDke"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41U8Inj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42bH7O6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44nAtGj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Nv84Iv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LKOu9T"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LmyeKM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LnPEqy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AHcX9Q"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1149"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/81klYtDS-jL._UX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Clothing"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Clothing & Accessories \n                Boys \n                T-Shirts & Polos \nPryce1149 \nDicos57 \n\n❌Regular Price  : ₹  1999   /- 😱"
     }
    ]
   },
   {
    "id": 19948,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1682991133",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "28999",
    "dicos": "NaN",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   OnePlus Nord 2T 5G (Jade Fog, 8GB RAM, 128GB Storage)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ANjOi1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AKL9RO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VlINlO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AJNjkJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/421fKXy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40PXUoJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lg6CXK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VrhM0n"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AMdAie"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AIOUqP"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@28999"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61ahn9N38zL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Mobiles & Accessories \n                Smartphones & Basic Mobiles \nPryce28999 \nDicosNaN"
     }
    ]
   },
   {
    "id": 19949,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1682991264",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "69",
    "dicos": "91",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   pTron Solero M241 2.4A Micro USB Data & Charging Cable, Made in India, 480Mbps Data Sync, Durable 1-Meter Long USB Cable for Micro USB Devices - (Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LrjhY5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41SHYDr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ndwzzn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oU4pJX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LnHzCa"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p1033R"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44hbynY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44hlGgr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LMU2Rp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3notSuH"
     },
     {
      "type": "plain",
      "text": "\n\n @69\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51JVvqm1mAL._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Computers & Accessories \n                Accessories & Peripherals \n                Cables & Accessories \nPryce69 \nDicos91 \n\n❌Regular Price  : ₹  499   /- 😱"
     }
    ]
   },
   {
    "id": 19950,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1682991400",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "401",
    "dicos": "71",
    "catagory": "Shoes",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Pro by Khadim's Men's Sneakers✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lr79WX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NwvsFG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3niIzzl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44iBYFI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VmQEQ8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nnPgAj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Huvfic"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vlt4Dw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LKJGRI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LLg8U4"
     },
     {
      "type": "plain",
      "text": "\n\n @401\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/91wtkWoGvcL._UX625_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Shoes"
     },
     {
      "type": "plain",
      "text": " & Handbags\n\n                Shoes & Handbags \n                Shoes \n                Men's Shoes \nPryce401 \nDicos71 \n\n❌Regular Price  : ₹   1199  /- 😱"
     }
    ]
   },
   {
    "id": 19951,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1682991528",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "900",
    "dicos": "70",
    "catagory": "Clothing",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Campus Sutra Men Jacket✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3niIQlR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AL6Am0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42gM08r"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VmT4OG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44kw5b9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vlts4W"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42dP6tZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Llv3TH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42gVMYg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LmzihK"
     },
     {
      "type": "plain",
      "text": "\n\n @900\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/81iREF0AoQL._UX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Clothing"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Clothing & Accessories \n                Men \n                Winter Wear \nPryce900 \nDicos70 \n\n❌Regular Price  : ₹  1999   /- 😱"
     }
    ]
   },
   {
    "id": 19952,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1682991659",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "899",
    "dicos": "55",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Portronics 10000 mAh Lithium Polymer Power Bank Power PRO 10K with Dual Output Emergency Mobile Charger, White✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44lvV3u"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NuxPZr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42gMpHZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LIBTUH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40VCeaZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lmjr2U"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nsqO0x"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44nqv8a"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NtB9Uw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44m9qLu"
     },
     {
      "type": "plain",
      "text": "\n\n @899\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/41RXNpi70bL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Mobiles & Accessories \n                Mobile Accessories \nPryce899 \nDicos55 \n\n❌Regular Price  : ₹  1499   /- 😱"
     }
    ]
   },
   {
    "id": 19953,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1682991789",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1399",
    "dicos": "57",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   AmazonBasics Stainless Steel Knife Set with High-carbon Blades and Pine Wood Block, 14 Pieces✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44kIdsB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VoXLro"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LLognB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LLUHCA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LNRdj2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LKQzCN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LLnQO8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AJSnWd"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HudVKj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ALpetQ"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1399"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71-8YpXMAhL._SY879_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Dining \n                Kitchen Tools \nPryce1399 \nDicos57 \n\n❌Regular Price  : ₹  2499   /- 😱"
     }
    ]
   },
   {
    "id": 19954,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1682991916",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "789",
    "dicos": "21",
    "catagory": "Shoes",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Max mens Su21mfcs1075 Sneakers✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p4GKGE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LL3S5X"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42bIyMu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lr6swE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44exH6t"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42gMVpp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42bIOeq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41Vg6yO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HwcjzP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Nuhl3l"
     },
     {
      "type": "plain",
      "text": "\n\n @789\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/41oNJYHai9L.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Shoes"
     },
     {
      "type": "plain",
      "text": " & Handbags\n\n                Shoes & Handbags \n                Shoes \n                Men's Shoes \nPryce789 \nDicos21 \n\n❌Regular Price  : ₹  999   /- 😱"
     }
    ]
   },
   {
    "id": 19955,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1682992044",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1637",
    "dicos": "34",
    "catagory": "Shoes",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Aks Australia Cricket Studs Rubber White/Blue Size 12 with Wrist Band Cotton 3\" Navy and Padded Cotton Socks Full White/Grey✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NMhsrx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NqsClh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NpZ9YV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NtlNzq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44nrm8S"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LpOpa5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lod6E7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AJQjNZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oW2YL8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42abgOp"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1637"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/417fHQB5G3L.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Shoes"
     },
     {
      "type": "plain",
      "text": " & Handbags\n\n                Shoes & Handbags \n                Shoes \n                Men's Shoes \nPryce1637 \nDicos34 \n\n❌Regular Price  : ₹ 2299    /- 😱"
     }
    ]
   },
   {
    "id": 19956,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1682992172",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "3412",
    "dicos": "20",
    "catagory": "Health",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Wahl WPCT6-2224 Curlito Curling Tong (22mm) (Golden)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44du9RT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LmCda7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AHNvRB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AIhatZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3APmIm8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AHwV4a"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40TbYOw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40WCnuT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HuTlJY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/425OHtE"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@3412"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61WGIICoVGL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Health"
     },
     {
      "type": "plain",
      "text": " & Personal Care\n\n                Beauty \n                Hair Care \n                Hair Styling Tools \nPryce3412 \nDicos20 \n\n❌Regular Price  : ₹  3499    /- 😱"
     }
    ]
   },
   {
    "id": 19957,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1682992301",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "2199",
    "dicos": "27",
    "catagory": "Health",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Lifelong Hair Scalp Rechargeable Electric Head Kneading Massager, 4 Speed Modes, Handheld Portable Head Scratcher Massager for Hair Growth, Full Body Pain Relief, Deep Clean and Stress Relax, Head Massager (LLM603, Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42cL8lw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://www.amazon.in/dp/B0BDMQDX12?th=1&amp;linkCode=sl1&amp;tag=bmvavinash05-21&amp;linkId=b0b9cae89e57ff68b4728b1173b58189&amp;language=en_IN&amp;ref_=as_li_ss_tl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lngfnx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44vvCTM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44dumEF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LVoqJr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NuhYKf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Ntmed2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nf4rvJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41aDEyB"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@2199"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/712bUMnGILL._SY450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Health"
     },
     {
      "type": "plain",
      "text": " & Personal Care\n\n                Health & Personal Care \n                Health Care \n                Massage & Relaxation \nPryce2199 \nDicos27 \n\n❌Regular Price  : ₹  2999     /- 😱"
     }
    ]
   },
   {
    "id": 19958,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1682992431",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "606",
    "dicos": "70",
    "catagory": "Video",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Ant Esports GM400W RGB Wireless Gaming Mouse, Stylish Mouse for Ultimate Gaming, Upto 3200 DPI, 6 Buttons Durable Switches, Dual Color Candy Design - Dull Yellow, Aqua✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NuW8Xo"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p08gFx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44hdSLI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AJT9CB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VlLcgk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VkGcsz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AKNDj6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nssfMt"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vr8UIm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oX4aOu"
     },
     {
      "type": "plain",
      "text": "\n\n @606\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61LyxFQ2NWL._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Video"
     },
     {
      "type": "plain",
      "text": " Games\n\n                Computers & Accessories \n                Accessories & Peripherals \n                PC Gaming Peripherals \nPryce606 \nDicos70 \n\n❌Regular Price  : ₹  1499    /- 😱"
     }
    ]
   },
   {
    "id": 19959,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1682992560",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "459",
    "dicos": "73",
    "catagory": "Baby",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Amazon Brand - Solimo Sherpa Fleece New Born Baby Blankets, Set of 2 (Grey, Brown)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VlLqEc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AMAhTI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40UB8Mw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40TiswH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LJ3ZPG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p1TmOO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HsCmrm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nlCQsE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oXnv1S"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41V4prE"
     },
     {
      "type": "plain",
      "text": "\n\n @459\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/91KP5M5s3PL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Baby"
     },
     {
      "type": "plain",
      "text": "\n\n                Baby \n                Bedding, Furniture & Room Décor \n                Bedding \nPryce459 \nDicos73 \n\n❌Regular Price  : ₹   1299  /- 😱"
     }
    ]
   },
   {
    "id": 19960,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1682992690",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "399",
    "dicos": "73",
    "catagory": "Baby",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Amazon Brand - Solimo Polar Fleece New Born Baby Blankets, Set of 2 (Pink, Sky Blue)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42d5St7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VprDnF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42gOaVB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LpPRt3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/429dUDi"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lr9QYz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42jf13D"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NqQHZp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LMO6aI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oYmoPA"
     },
     {
      "type": "plain",
      "text": "\n\n @399\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71jD2ljs+TL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Baby"
     },
     {
      "type": "plain",
      "text": "\n\n                Baby \n                Bedding, Furniture & Room Décor \n                Bedding \nPryce399 \nDicos73 \n\n❌Regular Price  : ₹  1199   /- 😱"
     }
    ]
   },
   {
    "id": 19961,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1682992820",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "899",
    "dicos": "57",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Philips Cyra Emergency LED Lantern (Grey) | 360 Degree Light and Dimmability Brightness Control Feature | 2200 mAH Battery with 4.5 hrs of Light Backup✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NqtUg7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Nq1dA9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Nsm1H0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oU7B8p"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/429xQpI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AKTKUx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VlzoL8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lrmyql"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HwSiZP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44nEoD1"
     },
     {
      "type": "plain",
      "text": "\n\n @899\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51sTJ6HO-bL._SX466_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Indoor Lighting \n                 Emergency Lights \nPryce899 \nDicos57 \n\n❌Regular Price  : ₹  1799   /- 😱"
     }
    ]
   },
   {
    "id": 19962,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1682992952",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "639",
    "dicos": "68",
    "catagory": "Health",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Lifelong Electric Toothbrush with Free Clove Dental Health Plan for Kids (3+ Years) with Animated sticker for Designing your Toothbrush | 2 Extra Soft Brush Heads|2 min Smart Timer|Rotary Toothbrush Electric Toothbrush(1 year warranty, Pink, LLDC81)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nnSaVJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nmqjVU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ALglAw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AIS5if"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42fwn1i"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41WcCMi"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oW4Kfg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oW4LzQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41T9xNe"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41T6gNR"
     },
     {
      "type": "plain",
      "text": "\n\n @639\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/41PjhUJ923L._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Health"
     },
     {
      "type": "plain",
      "text": " & Personal Care\n\n                Health & Personal Care \n                Oral Care \n                Toothbrushes & Accessories \nPryce639 \nDicos68 \n\n❌Regular Price  : ₹   1699  /- 😱"
     }
    ]
   },
   {
    "id": 19963,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1682993080",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "155",
    "dicos": "50",
    "catagory": "Beauty",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   BoroPlus Soft Antiseptic Cream | Light & Non-sticky | Provides 24 hour moisturisation|Ayurvedic Cream for all seasons |Hand Cream, Body Cream & Face Cream | Vitamin E - 200 ml✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vp3hdn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42h4N3s"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42jfR0h"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lqj7Qn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oYa0iq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Nuk5hq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41UcEV7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Nxr74T"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NzLiiR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44i3vas"
     },
     {
      "type": "plain",
      "text": "\n\n @155\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/614aXmJMdlL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Beauty"
     },
     {
      "type": "plain",
      "text": "\n\n                Beauty \n                Skin Care \n                Face \nPryce155 \nDicos50 \n\n❌Regular Price  : ₹  310   /- 😱"
     }
    ]
   },
   {
    "id": 19964,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1682993212",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1369",
    "dicos": "61",
    "catagory": "Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   HP GK320 Wired Full Size RGB Backlight Mechanical Gaming Keyboard, 4 LED Indicators, Mechanical Switches, Double Injection Key Caps, and Windows Lock Key, 3 Years Warranty✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nnqCzC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vraw4S"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p2jZmJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lrnefn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AKXaqv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lqjx9p"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Huexjd"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vph2ZH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VmQw33"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LL68Kt"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1369"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71hMT1IyswL._SY450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Computers & Accessories \n                Accessories & Peripherals \n                PC Gaming Peripherals \nPryce1369 \nDicos61 \n\n❌Regular Price  : ₹   2999  /- 😱"
     }
    ]
   },
   {
    "id": 19965,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1682993343",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "597",
    "dicos": "50",
    "catagory": "Health",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Dettol No Touch Aloe Vera Handwash Refill, 250ml, Pack of 6✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LL6aC5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/425QQ8G"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HyVUdN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hqksp9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HtquWa"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oQKhIC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oQKlYS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42dsiuj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HwSzfs"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44wVmPJ"
     },
     {
      "type": "plain",
      "text": "\n\n @597\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51foeWB2+BL._SX569_PIbundle-6,TopRight,0,0_AA569SH20_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Health"
     },
     {
      "type": "plain",
      "text": " & Personal Care\n\n                Health & Personal Care \n                Personal Care \n                 Hand Care \nPryce597 \nDicos50 \n\n❌Regular Price  : ₹ 1194    /- 😱"
     }
    ]
   },
   {
    "id": 19966,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1682993473",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "503",
    "dicos": "30",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Larah by Borosil Red Carnation (LH) Cup and Saucer Set, 140ml, 12-Pieces, White, HT12CS14RCA1, HT12CS14RCA1 (Opalware)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44lzSoQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lq8ME6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LLkhaA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NpEowo"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NqSpdh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nf6wb1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NqvxdJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VjVHRz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ndB50N"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VlAKWe"
     },
     {
      "type": "plain",
      "text": "\n\n @503\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51ac6nWGWyL._SX466_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Dining \n                Tableware \nPryce503 \nDicos30 \n\n❌Regular Price  : ₹   649  /- 😱"
     }
    ]
   },
   {
    "id": 19967,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1682993578",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "199",
    "dicos": "90",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   ATEVON Iron Tadka Pan/Fry Pan with Steel Handle for Kitchen 12cMN-01✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LgbbRG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nnrT9S"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AGA8RH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AJVoG1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44vxR9E"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41M7fzj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oX6epI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nlEmek"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VrmI5p"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/413cUA0"
     },
     {
      "type": "plain",
      "text": "\n\n @199\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/311r8rMD4lL._SX466_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Dining \n                Cookware \nPryce199 \nDicos90 \n\n❌Regular Price  : ₹  1299  /- 😱"
     }
    ]
   },
   {
    "id": 19968,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1682993706",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "321",
    "dicos": "35",
    "catagory": "Beauty",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Blue Heaven Lipstick Glam Diva (Matte)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42gbGCs"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ALhPe4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HuzIBu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HwfCad"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/429zrfc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HSfEJN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oQL3W2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vrbv54"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41Tb5a0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VqeGtO"
     },
     {
      "type": "plain",
      "text": "\n\n @321\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51k0c9u6ueL._SY450_PIbundle-3,TopRight,0,0_AA450SH20_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Beauty"
     },
     {
      "type": "plain",
      "text": "\n\n                Beauty \n                Make-up \n                Lips \nPryce321 \nDicos35 \n\n❌Regular Price  : ₹   499  /- 😱"
     }
    ]
   },
   {
    "id": 19969,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1682993834",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "199",
    "dicos": "79",
    "catagory": "Clothing",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Sanisa Women Dress Shirt✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/429zAiK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44lLFn2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NuChr7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41ScjlV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41T7MQ3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44gyIL3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NvKLOK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ndBjoF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LmFwOz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Nqwq63"
     },
     {
      "type": "plain",
      "text": "\n\n @199\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/81MPIVoNR+L._UY741_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Clothing"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Clothing & Accessories \n                Women \n                Ethnic Wear \nPryce199 \nDicos79 \n\n❌Regular Price  : ₹  699   /- 😱"
     }
    ]
   },
   {
    "id": 19970,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1682993962",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "359",
    "dicos": "64",
    "catagory": "Tools",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Axmon® Extension Cord [FIRE Proof] [Shock Proof][6 Month Warranty] 10 Amp [1.9 Meter Power Cord] 4 Socket Extension Board for Home Office- Grey✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40UM7Wn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AItpGM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42gM5Jk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HvDcE5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hx3dmd"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3noyvoz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44hgHwi"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44nH3wv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AItD0A"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nnSaVO"
     },
     {
      "type": "plain",
      "text": "\n\n @359\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71mTjclgQvL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Tools"
     },
     {
      "type": "plain",
      "text": " & Home Improvement\n\n                Home Improvement \n                Electrical \n                Adapters & Multi-Outlets \nPryce359 \nDicos64 \n\n❌Regular Price  : ₹  899    /- 😱"
     }
    ]
   },
   {
    "id": 19971,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1682994088",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "570",
    "dicos": "53",
    "catagory": "Tools",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Anchor by Panasonic 98042 UNO Series 10 Ampere 4 Pole C Curve MCB✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oQLsYy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p2jDfW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HuaL9o"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hx3rtz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nmsDfA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HsV4PP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nnV1Or"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41Wf05I"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41Wf2dQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://www.amazon.in/dp/B086H9YX9J?smid=AH017Z3M1ZJ3T&amp;psc=1&amp;th=1&amp;linkCode=sl1&amp;tag=avi1deals10-21&amp;linkId=2d37f333eae9cd88842d7e4a09aafac2&amp;language=en_IN&amp;ref_=as_li_ss_tl"
     },
     {
      "type": "plain",
      "text": "\n\n @570\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71QPX3sU2vL._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Tools"
     },
     {
      "type": "plain",
      "text": " & Home Improvement\n\n                Home Improvement \n                Electrical \n                 Switches & Dimmers \nPryce570 \nDicos53 \n\n❌Regular Price  : ₹   1099  /- 😱"
     }
    ]
   },
   {
    "id": 19972,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1682994215",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "107",
    "dicos": "59",
    "catagory": "Tools",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Anchor by Panasonic 66244B Roma Urban Plus Modular 2M 10AX V0 Grade Polycarbonate One-Way Switch with LED Indicator (Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VulGpu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42elZH3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LnW6xM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LnMoeK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44n54UH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LM8R6H"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41SCetr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Numj0g"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LKrYxL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NMlobN"
     },
     {
      "type": "plain",
      "text": "\n\n @107\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/41R9-wnLN6L._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Tools"
     },
     {
      "type": "plain",
      "text": " & Home Improvement\n\n                Home Improvement \n                Electrical \n                 Adapters & Multi-Outlets \nPryce107 \nDicos59 \n\n❌Regular Price  : ₹  261   /- 😱"
     }
    ]
   },
   {
    "id": 19973,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1682994342",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "63",
    "dicos": "68",
    "catagory": "Beauty",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Faces Nail Enamel, 9Ml (Love Thy Pink)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NCrKKm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42xlW9B"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42gd3kA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LkvDkF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41Vkmyi"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HwvuJA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hwvvx8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44kNW1z"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44iHh86"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AIu8I0"
     },
     {
      "type": "plain",
      "text": "\n\n @63\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61bEpIxALuL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Beauty"
     },
     {
      "type": "plain",
      "text": "\n\n                Beauty \n                Manicure & Pedicure \n                Nails \nPryce63 \nDicos68 \n\n❌Regular Price  : ₹   199  /- 😱"
     }
    ]
   },
   {
    "id": 19974,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1682994472",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "156",
    "dicos": "59",
    "catagory": "Baby",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Newnik Waterproof Dry Sheet/Reusable MAT/UNDERPAD/Absorbent Sheets/Mattress Protector Plum, Medium✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ALcaVu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VlyNcu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HwgYSl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AJX0Q5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AJTAgh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hv4jPg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hqt3sg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HuB9Qo"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nlGd2M"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VumdI0"
     },
     {
      "type": "plain",
      "text": "\n\n @156\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/41WY9e0yOuL._SX300_SY300_QL70_FMwebp_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Baby"
     },
     {
      "type": "plain",
      "text": "\n\n                Baby \n                Bedding, Furniture & Room Décor \n                Bedding \nPryce156 \nDicos59 \n\n❌Regular Price  : ₹   384  /- 😱"
     }
    ]
   },
   {
    "id": 19975,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1682994601",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "7431",
    "dicos": "45",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Havells OFR 7 Wave Fins with Fan 1900 watt (Beige), (GHROFBYC190)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oUbavj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VumpXK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NvselM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oTlRy9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oTlYK5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LmoQac"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44lBRti"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NxtODx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vlz9jk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VjXUMR"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@7431"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/512nhwTMmdL._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Heating, Cooling & Air Quality \n                Fans \nPryce7431 \nDicos45 \n\n❌Regular Price  : ₹  12999   /- 😱"
     }
    ]
   },
   {
    "id": 19976,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1682994728",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "274",
    "dicos": "45",
    "catagory": "Beauty",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Revolution Relove Compact powder matte banana loose powder | banana powder |yellow banana face powder | banana face loose powder | plus listing✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VkedJv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oPLgc4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ANqU69"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LoB9Td"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nld8EB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44nIeMr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41M9pip"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nkTetr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42duCBx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HwwkGe"
     },
     {
      "type": "plain",
      "text": "\n\n @274\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71JuA7oMQwL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Beauty"
     },
     {
      "type": "plain",
      "text": "\n\n                Beauty \n                Make-up \n                Face \nPryce274 \nDicos45 \n\n❌Regular Price  : ₹ 499    /- 😱"
     }
    ]
   },
   {
    "id": 19977,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1682994859",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "409",
    "dicos": "42",
    "catagory": "Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Kingston DataTraveler Exodia DTX/64 GB Pen Drive USB 3.2 Gen 1 (Multicolor)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42gdNpS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41UDM6m"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AQOQW1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HvEH5b"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Huk4pR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41SDg8N"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LKuVOP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41RFfu4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VkGeAE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Nq4KOV"
     },
     {
      "type": "plain",
      "text": "\n\n @409\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/714gztCQMmL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Computers & Accessories \n                External Devices & Data Storage \n                 Pen Drives \nPryce409 \nDicos42 \n\n❌Regular Price  : ₹  900    /- 😱"
     }
    ]
   },
   {
    "id": 19978,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1682994988",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "64",
    "dicos": "79",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   PAPER PLANE DESIGN PPD Big Size Gym Posters Motivational Gym Posters with Size of (A3 Size 12 in x 18 inch), multi, standard (gympstrA3size63)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NzNYwV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44kCcw7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42gO0O2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44ht6QP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42ahHRz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LNXyuQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41T9LDZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NwCFFK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LlASR3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40PFUuV"
     },
     {
      "type": "plain",
      "text": "\n\n @64\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/517l8oGyZoL._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Artwork \n                 Posters \nPryce64 \nDicos79 \n\n❌Regular Price  : ₹  299   /- 😱"
     }
    ]
   },
   {
    "id": 19979,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1682995119",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1299",
    "dicos": "67",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   boAt Rockerz 255 Pro+ in-Ear Bluetooth Neckband with Upto 40 Hours Playback, ASAP Charge, IPX7, Dual Pairing, BT v5.0, with Mic (Navy Blue)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40P2UtS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nldF9z"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Loj1cj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p2nr0F"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LrcbTa"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oXskbu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nmui/materialBQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42gOcge"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oQNLLc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LJ8b1I"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1299"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/515jdxLlmaL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Headphones, Earbuds & Accessories \n                Headphones \nPryce1299 \nDicos67 \n\n❌Regular Price  : ₹  2999     /- 😱"
     }
    ]
   },
   {
    "id": 19980,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1682995246",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "399",
    "dicos": "67",
    "catagory": "Shoes",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Longwalk Women's Sneaker✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AIRsFt"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HquhDS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HsX7Dv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HsHkV2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/427e7Y0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HquyGU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VCPKPT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VpShfO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LNYeQU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vpw3uL"
     },
     {
      "type": "plain",
      "text": "\n\n @399\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61F98vxt8ZS._UY625_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Shoes"
     },
     {
      "type": "plain",
      "text": " & Handbags\n\n                Shoes & Handbags \n                Shoes \n                Women's Shoes \nPryce399 \nDicos67 \n\n❌Regular Price  : ₹  999   /- 😱"
     }
    ]
   },
   {
    "id": 19981,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1682995352",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "134",
    "dicos": "87",
    "catagory": "Beauty",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Amazon Brand - Solimo Women's Rubber Bands, Pink, Pack of 12✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44i7CDq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44bTw6q"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oZCMPO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/429iTE0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p0dWPR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p0dZLx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44kD6bZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44kD7N5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LICPbE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nceH80"
     },
     {
      "type": "plain",
      "text": "\n\n @134\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/811CsDhfOBL._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Beauty"
     },
     {
      "type": "plain",
      "text": "\n\n                Jewellery \n                Women \n                Hair Accessories \nPryce134 \nDicos87 \n\n❌Regular Price  : ₹  899   /- 😱"
     }
    ]
   },
   {
    "id": 19982,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1682995485",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1799",
    "dicos": "70",
    "catagory": "Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Noise ColorFit Ultra Buzz Bluetooth Calling Smart Watch with 1.75\" HD Display, 320x385 px Resolution, 100 Sports Modes, Stock Market Info Smartwatch for Men & Women (Champagne Grey)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LLv8Bp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LnOQBY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3niQXij"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40VSgRW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AIvSB2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p3xzX1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40PGXLn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40WIQGb"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nmuQro"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LqnYB5"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1799"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61IyCGgiKRL._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Electronics \n                Wearable Technology \n                 Smart Watches \nPryce1799 \nDicos70 \n\n❌Regular Price  : ₹  3999   /- 😱"
     }
    ]
   },
   {
    "id": 19983,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1682995615",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "369",
    "dicos": "63",
    "catagory": "Shoes",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Kraasa Men SocksFit Walking and Running Shoes✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vp4jpY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HvwEFd"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AQQxCR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Huim83"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40PHnRX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LKRanM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AHTYfl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AInLof"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HSjcM7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VpwB3N"
     },
     {
      "type": "plain",
      "text": "\n\n @369\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71jF806zFdL._UY625_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Shoes"
     },
     {
      "type": "plain",
      "text": " & Handbags\n\n                Shoes & Handbags \n                Shoes \n                Men's Shoes \nPryce369 \nDicos63 \n\n❌Regular Price  : ₹  999    /- 😱"
     }
    ]
   },
   {
    "id": 19984,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1682995742",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "424",
    "dicos": "76",
    "catagory": "Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   ADISA Large Laptop Backpack Office Bag College Travel Back Pack with USB (Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41VW6vJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44iJUXw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44mJCyU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42ikdVt"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44knUeP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VmYEAE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VoLLGb"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42dXNED"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LlChXP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NvubP8"
     },
     {
      "type": "plain",
      "text": "\n\n @424\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/81scetteLLL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Computers & Accessories \n                Accessories & Peripherals \n                Laptop Accessories \nPryce424 \nDicos76 \n\n❌Regular Price  : ₹  1399    /- 😱"
     }
    ]
   },
   {
    "id": 19985,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1682995874",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "949",
    "dicos": "41",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Ambrane 10000mAh Slim Power Bank, 20W Fast Charging, Dual Output, Type C PD (Input & Output), Quick Charge, Li-Polymer, Multi-Layer Protection for iPhone, Anrdoid & Other Devices (Stylo 10K, Blue)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NwDJJK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40VT6OE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NtIgwc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NupBRa"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40UPvR5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40VJR17"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40Q6B2l"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42cFvDR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LmHGhe"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nlIlYk"
     },
     {
      "type": "plain",
      "text": "\n\n @949\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61PnVLH4UdL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Mobiles & Accessories \n                Mobile Accessories \nPryce949 \nDicos41 \n\n❌Regular Price  : ₹  1399   /- 😱"
     }
    ]
   },
   {
    "id": 19986,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1682996004",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "2927",
    "dicos": "57",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Maizic Smarthome Maizic Full HD 1080p WiFi Mini DIY Button Camera/Spy Hidden Camera/Video Pinhole Camera/Mini DVR Motion Detection Support Max 32GB✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44j9zPW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VlRXic"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LJf8A2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3noCi5h"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VlF4EW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vp8GBb"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41W8jAA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oXtILe"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AIxkmY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AKZP3j"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@2927"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/41xskXjIxZL._SY450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Cameras & Photography \n                Security Cameras \nPryce2927 \nDicos57 \n\n❌Regular Price  : ₹  5999   /- 😱"
     }
    ]
   },
   {
    "id": 19987,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1682996131",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "3999",
    "dicos": "73",
    "catagory": "Health",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Amazon Basics Corded Electric Massager for Foot with Heat Function, White & Gray✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LLbGVj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LNZBz2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44wZXBt"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LIElKS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42h4aqI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LiPUqG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nq5aKj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LnQ3t0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44nKKlR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LoDMEz"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@3999"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71zcB-ZCQtL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Health"
     },
     {
      "type": "plain",
      "text": " & Personal Care\n\n                Health & Personal Care \n                Health Care \n                Massage & Relaxation \nPryce3999 \nDicos73 \n\n❌Regular Price  : ₹  9999   /- 😱"
     }
    ]
   },
   {
    "id": 19988,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1682996265",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "56990",
    "dicos": "54",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Samsung The Freestyle 100\" Smart and Compact Portable LED Projector with in-Built OTT Apps (SP-LSP3BLAXXL, HDR10, Wi-Fi, 360 Sound, 180 Degree Projection Angle, Auto Focus & Auto Key Stone, White)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NsZE4k"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LolaVp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lggn8k"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40Y0clT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40XnPLc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42fx4aN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AHV9eL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42bQ4qG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41Tc2iv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41VaNiC"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@56990"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61QV0nIv1bL._SX450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Home Theater, TV & Video \n                 Projectors \nPryce56990 \nDicos54 \n\n❌Regular Price  : ₹  74999  /- 😱"
     }
    ]
   },
   {
    "id": 19989,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1682996396",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "699",
    "dicos": "65",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Mivi Play Bluetooth Speaker with 12 Hours Playtime. Wireless Speaker Made in India with Exceptional Sound Quality, Portable and Built in Mic-White✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lr4MU4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LL3WTm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VmasD8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LKvSGV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AKUQja"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AL0im5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NMoWuD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NqB8Rh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LJauSq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HSkyXd"
     },
     {
      "type": "plain",
      "text": "\n\n @699\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61A+XUTMC1S._SY450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Home Audio \n                Speakers \nPryce699 \nDicos65 \n\n❌Regular Price  : ₹  1499  /- 😱"
     }
    ]
   },
   {
    "id": 19990,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1682996526",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "117",
    "dicos": "41",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Signoraware Cute Steel Container, 100ml, Set of 1, Red✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44nyEtg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LVwEkN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42ilFHp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oTp2G5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lw11Ng"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44lFiQI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nlgck5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AKV5L6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Nt0gXG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41XRxkI"
     },
     {
      "type": "plain",
      "text": "\n\n @117\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61gPFhw5MiL._SX522_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Dining \n                Kitchen Storage & Containers \nPryce117 \nDicos41 \n\n❌Regular Price  : ₹  199  /- 😱"
     }
    ]
   },
   {
    "id": 19991,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1682996657",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "493",
    "dicos": "82",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Ntu-126 Off White Cotton Shade Table lamp with Metal Base by tu casa Holder Type e-27 (Bulb not Included)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NttFAY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Nvwba6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NupmWj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p20LO6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42ddn3f"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/410RDGN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HwZyVz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41V9tMU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41RIpOs"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44iLIzM"
     },
     {
      "type": "plain",
      "text": "\n\n @493\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61L9xX3XYsL._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Indoor Lighting \n                 Table Lamps \nPryce493 \nDicos82 \n\n❌Regular Price  : ₹   1999  /- 😱"
     }
    ]
   },
   {
    "id": 19992,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1682996783",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "450",
    "dicos": "25",
    "catagory": "Clothing",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Mom's Love baby-girls co-set✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44vDuVk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LLsiwj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hv8qea"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40TVHsG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LLsnQD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NqC0p1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nhYoq9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/4201Kx1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LKZVOV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AIc40u"
     },
     {
      "type": "plain",
      "text": "\n\n @450\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/41SBtsidN9L._UY741_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Clothing"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Beauty \n                Bath & Body \n                 Bath Sets & Kits \nPryce450 \nDicos25 \n\n❌Regular Price  : ₹   599  /- 😱"
     }
    ]
   },
   {
    "id": 19993,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1682996916",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "999",
    "dicos": "71",
    "catagory": "Clothing",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Lavie Sport Lino Cabin Size 53 cms Wheel Duffel Bag for Travel | Travel Bag with Trolley✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41Ujqu1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oXdkum"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vpag65"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VpyE7Z"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42imq3d"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42cTw4u"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44jbnsc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lmtd56"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LghAfS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LM4c4I"
     },
     {
      "type": "plain",
      "text": "\n\n @999\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61iLq+SNdOL._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Clothing"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Bags, Wallets and Luggage \n                Luggage \n                 Travel Duffles \nPryce999 \nDicos71 \n\n❌Regular Price  : ₹ 2999  /- 😱"
     }
    ]
   },
   {
    "id": 19994,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1682997045",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "3999",
    "dicos": "50",
    "catagory": "Health",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Lifelong LLM279 Foot Spa and Massager with Automatic Rollers, Digital Panel, Bubble Bath & Water Heating Technology for Pedicure, Pain relief & Foot Care (1 Year Warranty)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LLxXT1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lm1Bgd"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LpF5TL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NpoLVL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40Zp38O"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ndGKUB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42ermpH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ALnwbW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42erwNP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41WcoVv"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@3999"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/81u13V6RM0S._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Health"
     },
     {
      "type": "plain",
      "text": " & Personal Care\n\n                Health & Personal Care \n                Health Care \n                Massage & Relaxation \nPryce3999 \nDicos50 \n\n❌Regular Price  : ₹  5999   /- 😱"
     }
    ]
   },
   {
    "id": 19995,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1682997179",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "610",
    "dicos": "30",
    "catagory": "Watches",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Sonata Analog Women's Watch✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lqfaey"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44mMbkw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41QlNxQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LL0mZz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vnojt2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vn0TUA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oXdXEe"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vn2oCa"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40ZpxvE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AHWL8j"
     },
     {
      "type": "plain",
      "text": "\n\n @610\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71JirsfhBdL._UY741_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Watches"
     },
     {
      "type": "plain",
      "text": "\n\n                Watches \n                Trends \n                Round Dial Watches \nPryce610 \nDicos30 \n\n❌Regular Price  : ₹  749   /- 😱"
     }
    ]
   },
   {
    "id": 19996,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1682997308",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "749",
    "dicos": "50",
    "catagory": "Tools",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   GoTrippin Universal Travel Adapter with Dual USB Charger Ports, International Worldwide Charger Plug for Phone, Mobile,Laptop, Camera, Tablet (Black, 5 Year Extended Warranty)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AHp86y"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HuGdEo"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41Uk4Yt"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42alFcV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42fEz1y"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nl9n24"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LO1Mmc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nkN9xc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42bSsh9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oQRzvY"
     },
     {
      "type": "plain",
      "text": "\n\n @749\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71s8Vk7m0dL._SX466_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Tools"
     },
     {
      "type": "plain",
      "text": " & Home Improvement\n\n                Electronics \n                Mobiles & Accessories \n                Mobile Accessories \nPryce749 \nDicos50 \n\n❌Regular Price  : ₹  1299   /- 😱"
     }
    ]
   },
   {
    "id": 19997,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1682997416",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "299",
    "dicos": "28",
    "catagory": "Baby",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Newnik Cozymat Quick Oeko Tex Approved Dry Sheet/Bed Protector for New Born, Toilet Training Toddler, Old Age & New Moms (Small (50cm x 70cm), Salmon Rose + Lemon Green)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oOzb6X"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40UiZP0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nfdZXB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44x25cr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40WOE2n"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oTqqsh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NqZrib"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ndH9q5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Nusxgn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lw2Tpg"
     },
     {
      "type": "plain",
      "text": "\n\n @299\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/515zuw0343L._SY450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Baby"
     },
     {
      "type": "plain",
      "text": "\n\n                Baby \n                Bedding, Furniture & Room Décor \n                Bedding \nPryce299 \nDicos28 \n\n❌Regular Price  : ₹  414   /- 😱"
     }
    ]
   },
   {
    "id": 19998,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1682997545",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "280",
    "dicos": "86",
    "catagory": "Watches",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Acnos Analog Women's Watch (Multicolour Dial Multi Colored Strap)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40ZpYGi"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AMMTde"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AMJ7Ro"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p229Ai"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LNTdYx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41WbEzR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nhUQnR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hv2hPw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nli5xb"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Nxz0av"
     },
     {
      "type": "plain",
      "text": "\n\n @280\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/612OnJc3xWL._UX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Watches"
     },
     {
      "type": "plain",
      "text": "\n\n                Fashion \n                Onam 2021 \n                Watches and fashion jewellery \nPryce280 \nDicos86 \n\n❌Regular Price  : ₹   1099   /- 😱"
     }
    ]
   },
   {
    "id": 19999,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1682997686",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "2842",
    "dicos": "56",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Orient Electric Super Power Juicer Mixer Grinder 750Watt, 4 Jar (Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LJcRVu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ALfKPm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VkBZFb"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3APwh4w"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oYhYYS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HuqNjr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lgi85e"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lmu8T6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42cIpZh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42cIpZh"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@2842"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71rPgwiw-YL._SX466_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Home Appliances \n                Small Kitchen Appliances \nPryce2842 \nDicos56 \n\n❌Regular Price  : ₹   5499  /- 😱"
     }
    ]
   },
   {
    "id": 20000,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1682997819",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1993",
    "dicos": "53",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Polycab Etira 3Ltr 4.5Kw Electric Instant Water Heater (Geyser) with Anti rust tank, powerful heating element, multiple layer safety, ISI certified with 5 years inner tank Warranty (White)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LKzWqD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42esnOx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44hTmKR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40Y2H7L"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44hqRNt"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lw3xDc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LlFB5f"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41VsONR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NurlKf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LIMxe7"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1993"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51MFHy00trL._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Heating, Cooling & Air Quality \n                Water Heaters & Geysers \nPryce1993 \nDicos53 \n\n❌Regular Price  : ₹  3499   /- 😱"
     }
    ]
   },
   {
    "id": 20001,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1682997956",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1189",
    "dicos": "59",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   LONGWAY Starlite-1 1200 mm High Speed 3 Blade Anti-Dust Ceiling Fan Suitable for Kitchen/Veranda/Balcony/with 2 Year Warranty (Silver Blue, Pack of 1)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oXxfsY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oTrpZv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/429ns16"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40TXRIO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AMNCLu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AIWBNN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LrgBcI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hx1NrX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NCynfI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HwZAgi"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1189"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61dEfxAF8mL._SX466_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Heating, Cooling & Air Quality \n                Fans \nPryce1189 \nDicos59 \n\n❌Regular Price  : ₹   2499  /- 😱"
     }
    ]
   },
   {
    "id": 20002,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1682998088",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1199",
    "dicos": "55",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Longway Creta P1 600 mm/24 inch Ultra High Speed 4 Blade Anti-Dust Decorative 5-Star Rated Ceiling Fan (Ivory, Pack of 1)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HqAeAD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VlIDLk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nkYTjb"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AMJR98"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vpc4vT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AIAze8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LMfEgH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ALirAw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HSmJKn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3noGFgH"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1199"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61KoBPi3lYL._SX466_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Heating, Cooling & Air Quality \n                Fans \nPryce1199 \nDicos55 \n\n❌Regular Price  : ₹  1999   /- 😱"
     }
    ]
   },
   {
    "id": 20003,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1682998219",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "2329",
    "dicos": "53",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   LONGWAY Creta P2 1200 mm/48 inch Ultra High Speed 3 Blade Anti-Dust Decorative 5-Star Rated Ceiling Fan (Smoked Brown, Pack of 2)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hun4mf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vn3XQy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41aOm8h"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p2sEWh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oOAEKv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LqgHBk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LqgUEC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LgjbCo"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41WdYqp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NvRRCS"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@2329"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/41x+8fi2ZiL._SY300_SX300_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Heating, Cooling & Air Quality \n                Fans \nPryce2329 \nDicos53 \n\n❌Regular Price  : ₹   5999  /- 😱"
     }
    ]
   },
   {
    "id": 20004,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1682998346",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "309",
    "dicos": "56",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Fun Homes Light Weight, Durable Plastic Bathing Mug for Bathroom Bath Accessory, 1 Ltr, Pack of 6 (Pink)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LnTMqu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40P8ufO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40TTHAn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/410UoYF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LHtd0J"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/413kzhK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NwHYVG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42eIJ9L"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NutF3S"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44mlz32"
     },
     {
      "type": "plain",
      "text": "\n\n @309\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61wRlxUZMKL._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Home Storage & Organisation \n                Bathroom Accessories & Organization \nPryce309 \nDicos56 \n\n❌Regular Price  : ₹  649   /- 😱"
     }
    ]
   },
   {
    "id": 20005,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1682998473",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "849",
    "dicos": "83",
    "catagory": "Clothing",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   SERONA FABRICS Women's Banarasi Cotton Silk Embroidered Work Saree With Blouse Piece✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LmNxD9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VmZxZX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44nOpQD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LN6D79"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vn4xOe"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VkQJ75"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VmZYn3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VlJnA6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40RzFXj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HvBiTF"
     },
     {
      "type": "plain",
      "text": "\n\n @849\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/81f1IzLqGOS._UY741_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Clothing"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Clothing & Accessories \n                Women \n                Ethnic Wear \nPryce849 \nDicos83 \n\n❌Regular Price  : ₹  2999   /- 😱"
     }
    ]
   },
   {
    "id": 20006,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1682998602",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "233",
    "dicos": "69",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   D540827 8W LED Downlight, White✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AMKL5w"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nlbKC0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hx2TE5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3no2CMX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LJjUNY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41Sk5w7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vk4D9z"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LNgiKW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LN6YXt"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42hejDI"
     },
     {
      "type": "plain",
      "text": "\n\n @233\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/510df8lXyFL._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Indoor Lighting \n                Light Bulbs \nPryce233 \nDicos69 \n\n❌Regular Price  : ₹  699   /- 😱"
     }
    ]
   },
   {
    "id": 20007,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1682998730",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "135",
    "dicos": "77",
    "catagory": "Beauty",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Amazon Brand - Solimo Powder Makeup Brush with Wooden Handle, Small✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41RLu0Y"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hv1JsM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HSo0kD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ni1v1j"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LJk5c6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NvlO63"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40UMAru"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nfgxoD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NuLeAJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40PMOQR"
     },
     {
      "type": "plain",
      "text": "\n\n @135\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61updxYXq1L._SY879_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Beauty"
     },
     {
      "type": "plain",
      "text": "\n\n                Beauty \n                Tools & Accessories \n                Make-up Brushes & Tools \nPryce135 \nDicos77 \n\n❌Regular Price  : ₹  499   /- 😱"
     }
    ]
   },
   {
    "id": 20008,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1682998860",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "775",
    "dicos": "61",
    "catagory": "Health",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   beatXP Wave Digital Weighing Scale|LCD Panel|Thick Tempered Glass|Electronic Weight Machine| Weighing Scale (Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42xtIjN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44hzTdh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40RAfUZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lo3r0i"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44lUVHO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3no2QUj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/421tykQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LmOrzx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HvLxrn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nnAnhe"
     },
     {
      "type": "plain",
      "text": "\n\n @775\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61ObbmXBF0L._SX466_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Health"
     },
     {
      "type": "plain",
      "text": " & Personal Care\n\n                Health & Personal Care \n                Home Medical Supplies & Equipment \n                Health Monitors \nPryce775 \nDicos61 \n\n❌Regular Price  : ₹   1599  /- 😱"
     }
    ]
   },
   {
    "id": 20009,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1682998988",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "215",
    "dicos": "64",
    "catagory": "Beauty",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Amazon Brand - Solimo 99% Pure Aloe Vera Gel (Unscented, for Skin & Hair) - 120 ml (Pack of 2)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VlG24a"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ALjHne"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HSohEb"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AIsOoH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AHJr3E"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41QplQG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42bgqJq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41RM2ny"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hqut5J"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42bVguH"
     },
     {
      "type": "plain",
      "text": "\n\n @215\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/81NSXGSwsYL._SY879_PIbundle-2,TopRight,0,0_SX509SY879SH20_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Beauty"
     },
     {
      "type": "plain",
      "text": "\n\n                Beauty \n                Skin Care \n                Face \nPryce215 \nDicos64 \n\n❌Regular Price  : ₹  549   /- 😱"
     }
    ]
   },
   {
    "id": 20010,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1682999118",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "2099",
    "dicos": "75",
    "catagory": "Luggage",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   AmazonBasics Expandable Softside Carry-On Spinner Luggage Suitcase With TSA Lock And Wheels - 21 Inch, Grey✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oXyPeo"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vn5GFw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HujwjM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42bVpyf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LIODuv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nkPZlQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44ievVi"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NuuN7l"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LiVYzs"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NusYrl"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@2099"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/81Nb5iKnBSL._SY450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Luggage"
     },
     {
      "type": "plain",
      "text": " & Bags\n\n                Bags, Wallets and Luggage \n                Luggage \n                 Suitcases & Trolley Bags \nPryce2099 \nDicos75 \n\n❌Regular Price  : ₹  7499    /- 😱"
     }
    ]
   },
   {
    "id": 20011,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1682999248",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "249",
    "dicos": "69",
    "catagory": "Sports",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Lifelong Yoga Mat for Women & Men EVA Material 4mm Anti-Slip Mat with Strap for Gym Workout, (LLYM71, Green)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NtxTIQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NoChsK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40Vq69N"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40PF4yc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42cL84X"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oXOPx9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Ls7fgK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nsF6yd"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nqb91J"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AHJwEu"
     },
     {
      "type": "plain",
      "text": "\n\n @249\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/81Dr6CYCcyL._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Sports"
     },
     {
      "type": "plain",
      "text": ", Fitness & Outdoors\n\n                Sports, Fitness & Outdoors \n                Exercise & Fitness \n                Yoga \nPryce249 \nDicos69 \n\n❌Regular Price  : ₹  799  /- 😱"
     }
    ]
   },
   {
    "id": 20012,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1682999380",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "160",
    "dicos": "84",
    "catagory": "Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Lapster USB all types smartwatch charger cable for boat watch charger & boat xtend watch charger & boat storm watch charger- All 2 Pin Watch (Cable Only)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oXKQ3t"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ALC2jU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HuoUDF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ALigoM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LN03NC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VrlmYC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HuoZXZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HqC7NJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AGKiBP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/429pzly"
     },
     {
      "type": "plain",
      "text": "\n\n @160\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51c3oYvNwIL._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Electronics \n                Wearable Technology \n                 Smart Watches \nPryce160 \nDicos84 \n\n❌Regular Price  : ₹ 699    /- 😱"
     }
    ]
   },
   {
    "id": 20013,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1682999507",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "836",
    "dicos": "58",
    "catagory": "All",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Revlon Orofluido Mask 500ml✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nkQnki"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LKCSDF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p1vcEo"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LssRtw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/429pPB2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42bWnun"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LKC9Ct"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42iqosD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oU8hux"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44mPDvu"
     },
     {
      "type": "plain",
      "text": "\n\n @836\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71GjpEMa8eL._SY679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#All"
     },
     {
      "type": "plain",
      "text": "\n\n                Beauty \n                Skin Care \n                Face \nPryce836 \nDicos58 \n\n❌Regular Price  : ₹  1499   /- 😱"
     }
    ]
   },
   {
    "id": 20014,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1682999639",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "88",
    "dicos": "78",
    "catagory": "Sports",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Simran Sports Hand Grip, Hand Grip for Gym, Hand Grip Strengthener, Hand Gripper, Hand Grip for Gym Workout, Hand Grip Exerciser, Hand Gripper for Men, Women, Boys & Girls (Pack of 2) (PVC Green)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LpJ6aN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nfS0zM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NqGSuj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44mfvrg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NwK73G"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42ev8zn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40VQJLX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VlYp8U"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nll2Oh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40QcE73"
     },
     {
      "type": "plain",
      "text": "\n\n @88\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71Xi2e+ZJoL._SX466_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Sports"
     },
     {
      "type": "plain",
      "text": ", Fitness & Outdoors\n\n                Sports, Fitness & Outdoors \n                Exercise & Fitness \n                Strength Training Equipment \nPryce88 \nDicos78 \n\n❌Regular Price  : ₹  399   /- 😱"
     }
    ]
   },
   {
    "id": 20015,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1682999767",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "849",
    "dicos": "76",
    "catagory": "Clothing",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   SERONA FABRICS Women's Banarasi Cotton Silk Embroidered Work Saree With Blouse Piece✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LmxlSE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hv3eao"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44c0GaO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vqokg0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AL8y5J"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HxcQ4l"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LNiq5o"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LN0JTa"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Husr4L"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LL4gS9"
     },
     {
      "type": "plain",
      "text": "\n\n @849\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/81G-v0fuf8L._UY741_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Clothing"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Clothing & Accessories \n                Women \n                Ethnic Wear \nPryce849 \nDicos76 \n\n❌Regular Price  : ₹ 1299    /- 😱"
     }
    ]
   },
   {
    "id": 20016,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1682999897",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "6489",
    "dicos": "54",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Samsung Galaxy Buds 2 | Wireless in Ear Earbuds Active Noise Cancellation, Auto Switch Feature, Up to 20hrs Battery Life, (Graphite)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AK6dYF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3noJG0v"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41UoH4N"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HqD8W3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HwMlMy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HxdgaV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42bhXza"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lstvaq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VlZmy0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LKDRDR"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@6489"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51X0kXtlBCL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Headphones, Earbuds & Accessories \n                Headphones \nPryce6489 \nDicos54 \n\n❌Regular Price  : ₹   9999  /- 😱"
     }
    ]
   },
   {
    "id": 20017,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1683000026",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "28900",
    "dicos": "3",
    "catagory": "All",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Apple Watch SE (2nd Gen) [GPS 40 mm] Smart Watch w/Midnight Aluminium Case & Midnight Sport Band. Fitness & Sleep Tracker, Crash Detection, Heart Rate Monitor, Retina Display, Water Resistant✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42bi91m"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p1bmct"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44mg18G"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LL4QiN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40X6B0K"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VpcjHw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NoDoIW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Nvob8X"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NuwXDZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44mQrR2"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@28900"
     },
     {
      "type": "plain",
      "text": "\n \n "
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71LfnkRgZ4L._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#All"
     },
     {
      "type": "plain",
      "text": "\n\nPryce28900 \nDicos3"
     }
    ]
   },
   {
    "id": 20018,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1683000135",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "500",
    "dicos": "50",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   EcoLink NeoGlow 0.5-Watt Multicolour LED Bulb B22 Base Night Lamp (Pack of 8)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ALs95O"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AIhDfo"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AMNgVs"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40UnYzc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hwrn0l"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AQYist"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hula4W"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hz7hlZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40X6Wk2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HxdvCR"
     },
     {
      "type": "plain",
      "text": "\n\n @500\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51jbHHeLu-L._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Indoor Lighting \n                Light Bulbs \nPryce500 \nDicos50 \n\n❌Regular Price  : ₹  899   /- 😱"
     }
    ]
   },
   {
    "id": 20019,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1683000265",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "999",
    "dicos": "80",
    "catagory": "Clothing",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   SERONA FABRICS Women's Banarasi Soft Cotton Silk With Colourful Thread Embroidery Work Saree with Blouse✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LJgXgk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AHtVF4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LIUvTt"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HuUpgQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41Wf31m"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oYlR01"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VoSNL5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oQVAR4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VkORLy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VoT4xB"
     },
     {
      "type": "plain",
      "text": "\n\n @999\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/81mekBX6vBL._UY741_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Clothing"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Clothing & Accessories \n                Women \n                Ethnic Wear \nPryce999 \nDicos80 \n\n❌Regular Price  : ₹ 2999    /- 😱"
     }
    ]
   },
   {
    "id": 20020,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1683000393",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "470",
    "dicos": "NaN",
    "catagory": "Health",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Vanish All in One Liquid Detergent Booster - 800 ml | Stain Remover for Clothes | Whitens Whites Brightens Colours| Suitable with all Washing Detergent Powders and Liquids (Pack of 2)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p3h6Cw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nqcBRJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oQVJE6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42cNaSD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nhZnqn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44hrmqO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LiXRw2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oYzlZI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44iS5TI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p2uiqY"
     },
     {
      "type": "plain",
      "text": "\n\n @470\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61YMtRWB5yL._SY450_PIbundle-2,TopRight,0,0_AA450SH20_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Health"
     },
     {
      "type": "plain",
      "text": " & Personal Care\n\n                Health & Personal Care \n                Household Supplies \n                Laundry \nPryce470 \nDicosNaN"
     }
    ]
   },
   {
    "id": 20021,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1683000520",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "2381",
    "dicos": "48",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   EcoLink 22-Watt Surface Square LED Downlighter (Cool White, Pack of 2)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vq0BMA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p1cmgJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42hcvuw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LmyBFm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oYzuwe"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/413o5ss"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nlmyzX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41WpIZY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41WpMsG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oU9Dp7"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@2381"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51zkq9Q9POL._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Indoor Lighting \n                Light Bulbs \nPryce2381 \nDicos48 \n\n❌Regular Price  : ₹   3999  /- 😱"
     }
    ]
   },
   {
    "id": 20022,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1683000649",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "153",
    "dicos": "36",
    "catagory": "Grocery",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Kissan Hazelnut Choco Peanut Spread | Protein Rich | 30% Dark Chocolate Crème | 350g✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41XYlPs"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LMnFSG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LMnIhk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p2wr5X"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41SMBxn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41aS4id"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AL9S8H"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hwsa1j"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44vK0LM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41VgjC4"
     },
     {
      "type": "plain",
      "text": "\n\n @153\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61eArOGE-cL._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Grocery"
     },
     {
      "type": "plain",
      "text": " & Gourmet Foods\n\n                Grocery & Gourmet Foods \n                Jams, Honey & Spreads \n                 Nut Butters \nPryce153 \nDicos36 \n\n❌Regular Price  : ₹  239   /- 😱"
     }
    ]
   },
   {
    "id": 20023,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1683000782",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "139",
    "dicos": "30",
    "catagory": "Beauty",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Colorbar Nail Lacquer, Persian Tan, 12 ml✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41Vieq2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VkPFA4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42gYsFg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lw7NCG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AHMokK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oYmN4x"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44dIRbz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44x7Oir"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LlKyel"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NCDIDM"
     },
     {
      "type": "plain",
      "text": "\n\n @139\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/5173QillW6L._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Beauty"
     },
     {
      "type": "plain",
      "text": "\n\n                Beauty \n                Manicure & Pedicure \n                Nails \nPryce139 \nDicos30 \n\n❌Regular Price  : ₹ 199    /- 😱"
     }
    ]
   },
   {
    "id": 20024,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1683000915",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "34999",
    "dicos": "43",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Samsung 1.5 Ton 3 Star Inverter Split AC (Copper, Convertible 5-in-1 Cooling Mode, Easy Filter Plus (Anti-Bacteria), 2023 Model AR18CYLZABE White)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VmiqMy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40QexAF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Nr50gz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LLEmO3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NuP1hr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nfUaPU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3APC1v6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lm85f3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AIiU6a"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40W17Da"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@34999"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51dPET0mdFL._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Heating, Cooling & Air Quality \n                Air Conditioners \nPryce34999 \nDicos43 \n\n❌Regular Price  : ₹  49999    /- 😱"
     }
    ]
   },
   {
    "id": 20025,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1683001024",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "749",
    "dicos": "NaN",
    "catagory": "Toys",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Funskool FUNDOUGH FIRE Rescue Pretend Play Toy Playset for 3 Years, Shaping,Cutting and Moulding Dough Set, Multi Colour✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41TjslR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42it89p"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AK4umd"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HrmfdK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3no6opB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Huwtdf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HvOCYt"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40UpyRE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HuwD4l"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41SNfuN"
     },
     {
      "type": "plain",
      "text": "\n\n @749\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71vPSvR3WtL._SY450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Toys"
     },
     {
      "type": "plain",
      "text": " & Games\n\n                Toys & Games \n                Arts & Crafts \n                 Clay & Dough \nPryce749 \nDicosNaN \n\n❌Regular Price  : ₹   749  /- 😱"
     }
    ]
   },
   {
    "id": 20026,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1683001139",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "38900",
    "dicos": "15",
    "catagory": "All",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Apple Watch Series 8 [GPS 41 mm] Smart Watch w/Midnight Aluminium Case with Midnight Sport Band. Fitness Tracker, Blood Oxygen & ECG Apps, Always-On Retina Display, Water Resistant✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AI2Q4D"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p3HX0X"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HqxRgX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41TnISs"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p1kpKj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p4WVDS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VnuRI8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vm13eQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/429Lpp8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vm1aag"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@38900"
     },
     {
      "type": "plain",
      "text": "\n \n "
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71ulah9iIwL._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#All"
     },
     {
      "type": "plain",
      "text": "\n\nPryce38900 \nDicos15 \n\n❌Regular Price  : ₹  42999    /- 😱"
     }
    ]
   },
   {
    "id": 20027,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1683001277",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "899",
    "dicos": "NaN",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Kuber Industries Carry Design Single Saree Cover/Organizer With Transparent Top- Pack of 9 (Pink)-HS43KUBMART26141✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LrcFJa"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42jvu81"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oZNkyo"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VrzmRV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42cdITW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Nr5JhN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44hZ3IJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vm1s0Q"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44jiwc0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LJj040"
     },
     {
      "type": "plain",
      "text": "\n\n @899\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71zwozG7UIL._SX466_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Home Storage & Organisation \n                Clothing & Wardrobe Storage \nPryce899 \nDicosNaN \n\n❌Regular Price  : ₹  799    /- \n😱"
     }
    ]
   },
   {
    "id": 20028,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1683001404",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "540",
    "dicos": "23",
    "catagory": "Clothing",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Sugr Active Women Top✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lq5HUx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NwMFyM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LiZlGC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NqJyYT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44eOYfP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LqxTGP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40PIBMY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40VTYD7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AR0y2V"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41WhEIT"
     },
     {
      "type": "plain",
      "text": "\n\n @540\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71N3-+6N3rS._UX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Clothing"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Clothing & Accessories \n                Women \n                Western Wear \nPryce540 \nDicos23 \n\n❌Regular Price  : ₹   699  /- 😱\n\\"
     }
    ]
   },
   {
    "id": 20029,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1683001534",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "265",
    "dicos": "71",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   eCraftIndia 'Soothing Waterfall' Matt Textured UV Art Painting (Synthetic Wood, 51 cm x 36 cm, Multicolour)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oQXQb0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p3iKEc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41Wr7Qe"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hx68eS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AGNhKx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41XZK8G"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40Pdote"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LLxWOU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LL7RQd"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41VhBgo"
     },
     {
      "type": "plain",
      "text": "\n\n @265\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/712hj6tYoeL._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Artwork \n                 Art Prints \nPryce265 \nDicos71 \n\n❌Regular Price  : ₹  799   /- 😱"
     }
    ]
   },
   {
    "id": 20030,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1683001663",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1099",
    "dicos": "58",
    "catagory": "Health",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Panasonic ES-WC20-W Battery Operated Nail Care Basic Care (White)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HuWuta"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p1e3L7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44gKEMR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44ngxDJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44lNznK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LrlJgY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LkGY4d"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44mqMI8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oYnZF3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LVFAql"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1099"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51YgahWSXcL._SY879_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Health"
     },
     {
      "type": "plain",
      "text": " & Personal Care\n\n                Beauty \n                Manicure & Pedicure \n                Nails \nPryce1099 \nDicos58 \n\n❌Regular Price  : ₹  1999   /- 😱"
     }
    ]
   },
   {
    "id": 20031,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1683001791",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "299",
    "dicos": "83",
    "catagory": "Clothing",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Yashika Women's Printed Georgette Saree with Blouse Piece✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LrdYb2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AK5jLP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NoGi0i"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44dKc25"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AMwgOS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p4XBZW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AK7fE3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ALbHm3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42hhonE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nlRwrK"
     },
     {
      "type": "plain",
      "text": "\n\n @299\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71cjTcHJliL._UX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Clothing"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Clothing & Accessories \n                Women \n                Ethnic Wear \nPryce299 \nDicos83 \n\n❌Regular Price  : ₹  1199    /- 😱"
     }
    ]
   },
   {
    "id": 20032,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1683001918",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "249",
    "dicos": "88",
    "catagory": "Shoes",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Stropcarry Faux Leather Green Color Women/Girls Trendy Fashionable Sling Crossbody Bag(SC-SLING-004-GREEN)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41XZk1W"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40RFeVH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40ZXb4u"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41ToOxy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HSsVC7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VqrxMA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42cPvNp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vmki82"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vpt4Cr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42jwBVf"
     },
     {
      "type": "plain",
      "text": "\n\n @249\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71M3XgFwd4L._UY695_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Shoes"
     },
     {
      "type": "plain",
      "text": " & Handbags\n\n                Shoes & Handbags \n                Handbags, Purses & Clutches \n                 Sling & Cross-Body Bags \nPryce249 \nDicos88 \n\n❌Regular Price  : ₹  1399   /- 😱"
     }
    ]
   },
   {
    "id": 20033,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1683002053",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "59990",
    "dicos": "37",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Godrej 564 L Frost Free Side-By-Side Refrigerator (RS EONVELVET 579 RFD GL BK, Glass Black, Multi Air Flow System)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lrm4jK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42h4cif"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44gLt8p"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NuyZUU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44nGxim"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LL8skV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NuxJRJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41UPg9Y"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LN4S9G"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LLEld2"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@59990"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61K-RvtfXSL._SY445_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Large Appliances \n                 Refrigerators \nPryce59990 \nDicos37 \n\n❌Regular Price  : ₹  74999   /- 😱"
     }
    ]
   },
   {
    "id": 20034,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1683002184",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "6589",
    "dicos": "40",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Wonderchef Oven Toaster Griller (OTG) Crimson Edge - 28Litres - With Auto-Shut Off Heat-Resistant Tempered Glass Multi-Stage Heat Selection 2 Yrs Warranty 1600W RedBake Grill RoastEasy Clean✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p3jNE8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44l17zz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nnF62s"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44mrEwo"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44izMOF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AMTFzG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44nGRO6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LrrYBB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AK7MWz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44ikklC"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@6589"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51GkrNamHsL._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Home Appliances \n                Small Kitchen Appliances \nPryce6589 \nDicos40 \n\n❌Regular Price  : ₹  9999   /- 😱"
     }
    ]
   },
   {
    "id": 20035,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1683002315",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "997",
    "dicos": "NaN",
    "catagory": "Health",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Fitness Prime 800mg Keto Pills - Lean Keto Diet Pills - Weight Fat Management Loss - Ultra Fast Prime Keto Supplement for Women and Men - Optimal Max Keto - 120 Capsules (Pack of 2)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/4110qsh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HqHaxF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41UPBcK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VlP9BM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LMm935"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41ViyFu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hv7h6A"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LN5cVW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41SOYjL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/427paQY"
     },
     {
      "type": "plain",
      "text": "\n\n @997\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/81L91142ItS._SY450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Health"
     },
     {
      "type": "plain",
      "text": " & Personal Care\n\n                Health & Personal Care \n                Diet & Nutrition \n                Vitamins, Minerals & Supplements \nPryce997 \nDicosNaN"
     }
    ]
   },
   {
    "id": 20036,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1683002443",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "449",
    "dicos": "10",
    "catagory": "Beauty",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Wet n Wild Lipstick Purple, Metallic Finish✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HwuFAJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AK6vih"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HStDiL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41T14JD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nlhOug"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hv7Hda"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VkSxgk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42hkhV8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p1zDPy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ne7jZL"
     },
     {
      "type": "plain",
      "text": "\n\n @449\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71eaC-FI7gL._SY450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Beauty"
     },
     {
      "type": "plain",
      "text": "\n\n                Beauty \n                Make-up \n                Lips \nPryce449 \nDicos10 \n\n❌Regular Price  : ₹  499  /- 😱"
     }
    ]
   },
   {
    "id": 20037,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1683002568",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "462",
    "dicos": "69",
    "catagory": "Shoes",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Centrino Boys 6037-01 Sneaker✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42jxjln"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44hyH9R"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oWkFu2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41TpOSk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44mUtsE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44iUXjs"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VuzOiw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NvFGWO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44iAGux"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LmCeuY"
     },
     {
      "type": "plain",
      "text": "\n\n @462\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71FtCbQkBCL._UY500_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Shoes"
     },
     {
      "type": "plain",
      "text": " & Handbags\n\n                Shoes & Handbags \n                Shoes \n                Boys' Shoes \nPryce462 \nDicos69 \n\n❌Regular Price  : ₹  1299   /- 😱"
     }
    ]
   },
   {
    "id": 20038,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1683002696",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "181",
    "dicos": "49",
    "catagory": "Sports",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Amazon Brand - Symactive Skipping Rope, Yellow Grip✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lo9zpk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LJkw62"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nfmQZl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LNn978"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lscihc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lo0zjY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LwaZya"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LmVkAT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40TtemM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41Ut4wJ"
     },
     {
      "type": "plain",
      "text": "\n\n @181\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71pd3XJ1g+L._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Sports"
     },
     {
      "type": "plain",
      "text": ", Fitness & Outdoors\n\n                Sports, Fitness & Outdoors \n                Exercise & Fitness \n                Accessories \nPryce181 \nDicos49 \n\n❌Regular Price  : ₹  359   /- 😱"
     }
    ]
   },
   {
    "id": 20039,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1683002828",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "5418",
    "dicos": "34",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   OSMON - 25 Litre Oven Toaster Griller. OTG with full Stainless-Steel body, rotisserie and convection fan (Steel) OS TO25✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AHxISO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42hkYhc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41Qvo7Q"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VqsQuY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40ZYvnY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VlLYtY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VkK8JL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hv7Zkg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41Wj9GM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HSu2lh"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@5418"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71OdkGI8jgL._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Home Appliances \n                Small Kitchen Appliances \nPryce5418 \nDicos34 \n\n❌Regular Price  : ₹  7499   /- 😱"
     }
    ]
   },
   {
    "id": 20040,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1683002957",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "82599",
    "dicos": "8",
    "catagory": "All",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Apple Watch Ultra [GPS + Cellular 49 mm] smart watch w/Rugged Titanium Case & Orange Alpine Loop - Small. Fitness Tracker, Precision GPS, Action Button, Extra-Long BatteryLife, Brighter Retina Display✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LNdxt5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LL9hdr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41VyNCu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41TmJBF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HSukbR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/421zMkI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HtH4VU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NA1i4p"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NtSH2Q"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42d2Jd4"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@82599"
     },
     {
      "type": "plain",
      "text": "\n \n "
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/91z5KuonXrL._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#All"
     },
     {
      "type": "plain",
      "text": "\n\nPryce82599 \nDicos8 \n\n❌Regular Price  : ₹  87999   /- 😱"
     }
    ]
   },
   {
    "id": 20041,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1683003087",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "280",
    "dicos": "13",
    "catagory": "Beauty",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Dettol Intense Cool Bathing Soap Bar With Menthol (Buy 4 Get 1 Free - 125G Each), Combo Offer On Bath Soap✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VmlOae"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lo0OLU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42hjCU2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nt77Wm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LlOJa1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NqiusZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LLAtZq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NxHApJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40PLhKw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41aW6Hn"
     },
     {
      "type": "plain",
      "text": "\n\n @280\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61sThd20O2L._SX569_PIbundle-5,TopRight,0,0_AA569SH20_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Beauty"
     },
     {
      "type": "plain",
      "text": "\n\n                Beauty \n                Bath & Body \n                Cleansers \nPryce280 \nDicos13 \n\n❌Regular Price  : ₹  322  /- 😱"
     }
    ]
   },
   {
    "id": 20042,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1683003220",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "26990",
    "dicos": "59",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Hafele Aqua 12S, 12 Place Settings Stainless Steel Freestanding Dishwasher, Grey✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LoOLxN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42cgZmc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oOIYdd"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oYqFCB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AMyoWS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AImxsO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41WkrBR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41VzlZ4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HsUP7a"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vq4OzS"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@26990"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/41t1eqCdpaL.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Large Appliances \n                 Dishwashers \nPryce26990 \nDicos59 \n\n❌Regular Price  : ₹  49999    /- 😱"
     }
    ]
   },
   {
    "id": 20043,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1683003349",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "20990",
    "dicos": "59",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Hafele Aqua Mini, 8 Place Settings Countertop Dishwasher, Grey✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oXnI5k"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oXnQBQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LIVdkH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AHyGhU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AHz1kG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AMyJc6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AHz9Rc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NuCqe5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HvjQP4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VrCux9"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@20990"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/814-38NwW-L._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Large Appliances \n                 Dishwashers \nPryce20990 \nDicos59 \n\n❌Regular Price  : ₹  39999   /- 😱"
     }
    ]
   },
   {
    "id": 20044,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1683003478",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "2999",
    "dicos": "14",
    "catagory": "Toys",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   KIPA GAMING Photo of Stunt Toy Car Racing Remote Poster (Without Frame) for Travel & Tourism 3 Inch* X 9 Inch✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Huy4Qr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VpyZY5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40YbMgR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vm5w16"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LmTRdW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NuCUAP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p1p135"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42h2FZA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LmDQ80"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LqpvHo"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@2999"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71qQxCthekL._SY450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Toys"
     },
     {
      "type": "plain",
      "text": " & Games\n\n                Toys & Games \n                Remote & App-Controlled Toys \n                Remote & App Controlled Vehicles \nPryce2999 \nDicos14 \n\n❌Regular Price  : ₹  3499   /- 😱"
     }
    ]
   },
   {
    "id": 20045,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1683003610",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "685",
    "dicos": "81",
    "catagory": "Watches",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Amazon Brand - Symbol Analog Unisex Watch✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NxIgvh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p2zrzi"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NvtCVp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42hmcJk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41URsye"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41VBKTp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40W5e24"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AIJlJ6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3APGfTu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44hMaym"
     },
     {
      "type": "plain",
      "text": "\n\n @685\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/81MvFs170NL._UX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Watches"
     },
     {
      "type": "plain",
      "text": "\n\n                Fashion \n                Made for Amazon brands \n                All SPB apparel \nPryce685 \nDicos81 \n\n❌Regular Price  : ₹  2499   /- 😱"
     }
    ]
   },
   {
    "id": 20046,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1683003739",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "28750",
    "dicos": "28",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Samsung 28 L Convection Microwave Oven (MC32B7382QD/TL, Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3njgAzA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HSvcgD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VkLvIp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nnHQgg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LqBp3Z"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nsMgT7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LJmbbM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nivE0k"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ALcdAl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40RIMat"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@28750"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/817T4ogiInL._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Large Appliances \n                 Microwave Ovens \nPryce28750 \nDicos28 \n\n❌Regular Price  : ₹   36999   /- 😱"
     }
    ]
   },
   {
    "id": 20047,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1683003867",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "16990",
    "dicos": "15",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Samsung 32 L Convection Microwave Oven (CE117PC-B3/XTL, Black, SlimFry)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AInmlo"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41WveMa"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p1pIcH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vnbejm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VkLTXn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44kA8nJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40W5AFW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oPZKIY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LnzBsH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42dKt31"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@16990"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71Wq2T27udL._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Large Appliances \n                 Microwave Ovens \nPryce16990 \nDicos15 \n\n❌Regular Price  : ₹  19499   /- 😱"
     }
    ]
   },
   {
    "id": 20048,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1683003996",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "15490",
    "dicos": "NaN",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Samsung 28 L Convection Microwave Oven (MC28A5025VS/TL, Silver, slimfry)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42xBY3h"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NxIVwL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nkXw46"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40UtNg2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40Phn9a"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NuDXRn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LIQXSe"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LO3ROZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LkKVWB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LnzXzx"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@15490"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/516fivvPSOL._SX466_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Large Appliances \n                 Microwave Ovens \nPryce15490 \nDicosNaN"
     }
    ]
   },
   {
    "id": 20049,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1683004123",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "277",
    "dicos": "NaN",
    "catagory": "Beauty",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Skinatura Activated Charcoal Detox Body Wash (200 ml) Refreshing Purifying Deep Cleansing (200g (Pack of 1))✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/429x2kA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44hBm3l"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ALrstm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AMT9C2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HqK7yf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AKcOSV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VnbH56"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VD3Mkx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lo2SDE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VkMjgp"
     },
     {
      "type": "plain",
      "text": "\n\n @277\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/41Cenlf0wnL.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Beauty"
     },
     {
      "type": "plain",
      "text": "\n\n                Beauty \n                Bath & Body \n                Cleansers \nPryce277 \nDicosNaN"
     }
    ]
   },
   {
    "id": 20050,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1683004226",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "199",
    "dicos": "80",
    "catagory": "Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Gizga Essentials Wi-Fi Receiver Wireless Mini Wi-Fi Network Adapter WiFi USB Mini Adapter Supports 150 Mbps Wireless Data, Comes with Advanced Security WPA/WPA2 Encryption Standards, Black✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VmnKj0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AJbwYf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AMWG2Y"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40QjJVb"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AR4qkt"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41UvD1P"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44kAzyn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hzdlef"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41QxSTI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42jynFP"
     },
     {
      "type": "plain",
      "text": "\n\n @199\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61+CcWAREPL._SY450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Computers & Accessories \n                Networking Devices \n                Network Adapters \nPryce199 \nDicos80 \n \n❌Regular Price  : ₹ 899    /- 😱"
     }
    ]
   },
   {
    "id": 20051,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1683004348",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "299",
    "dicos": "25",
    "catagory": "Beauty",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   FACES CANADA Comfy Matte Liquid Lipstick - Note To Self 07, 3 ml | Comfortable 10HR Longstay | Intense Matte Color | Almond Oil & Vitamin E Infused | Super Smooth | No Dryness | No Alcohol✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VkdLuR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44iDeZD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LJ0Nm3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nqiWwv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AR4tg9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44m3PFe"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42h3QrY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NvuN7h"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NtFUxq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p2CkA5"
     },
     {
      "type": "plain",
      "text": "\n\n @299\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71yNhjEM6DL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Beauty"
     },
     {
      "type": "plain",
      "text": "\n\n                Beauty \n                Make-up \n                Lips \nPryce299 \nDicos25 \n\n❌Regular Price  : ₹   699  /- 😱"
     }
    ]
   },
   {
    "id": 20052,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1683004473",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "439",
    "dicos": "84",
    "catagory": "Clothing",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   MIRCHI FASHION Women's Chiffon Forest Designer Printed Saree with Blouse Piece✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NpWcaI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LNqnrg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42gt2PC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Ny5wJA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42gt6Pm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LmFfeM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NwS4G4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41StqEb"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p1qNRN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HxbRkJ"
     },
     {
      "type": "plain",
      "text": "\n\n @439\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/91WbQpHLSEL._UY741_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Clothing"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Clothing & Accessories \n                Women \n                Ethnic Wear \nPryce439 \nDicos84 \n\n❌Regular Price  : ₹   19999  /- 😱"
     }
    ]
   },
   {
    "id": 20053,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1683004574",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1599",
    "dicos": "NaN",
    "catagory": "Car",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Steelbird Hi-Gn SBH-11 Zoom Glossy Full Face Helmet with Smoke Visor, 58CM Matt Black✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44io0Us"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41RUa7y"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41VDvQv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nlW4yk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VmocOe"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ALdFTj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VlSPn4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LKLfPB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VncEuc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HvLgo3"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1599"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/81ZDPb1E14L._SX466_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Car"
     },
     {
      "type": "plain",
      "text": " & Motorbike\n\n                Car & Motorbike \n                Motorbike Accessories & Parts \n                Helmets \nPryce1599 \nDicosNaN"
     }
    ]
   },
   {
    "id": 20054,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1683004697",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "11599",
    "dicos": "19",
    "catagory": "Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   HP Ink Tank 319 All-in-one Colour Printer with upto 15,000 Black and 8,000 Colour pages included in the box - Print, Scan & Copy for Office/Home✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LHCJ3X"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AL7hLS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/4114ed3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VkuTAB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ALdWFP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hurppo"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oXIfGM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hze27l"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vq6YQ0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VpneRj"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@11599"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61bLISKr1EL._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Computers & Accessories \n                Printers, Inks & Accessories \n                Printers \nPryce11599 \nDicos19 \n\n❌Regular Price  : ₹  13999   /- 😱"
     }
    ]
   },
   {
    "id": 20055,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1683004799",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "260",
    "dicos": "91",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   SIMPARTE Air-Tight & Leak Proof Plastic Anti Microbial Oil Pourer - for Vinegar | Sauces | Olive & Refined Oils | Easy Flow | BPA Free (Black) (2, 285ML)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VD55Qt"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AKaCLf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ALzGBC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LmVYOU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p2dcJM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nctMXa"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40UvaLI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42ebpjd"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nsNMoh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NA40qB"
     },
     {
      "type": "plain",
      "text": "\n\n @260\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/518K0fWNH+L._SX522_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Dining \n                Kitchen Tools \nPryce260 \nDicos91 \n\n❌Regular Price  : ₹  1999   /- 😱"
     }
    ]
   },
   {
    "id": 20056,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1683004920",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "400",
    "dicos": "78",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   GoPro Silicone Sleeve + Lanyard White for HERO7 Cameras✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oR3hqq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LMhgag"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nebMeZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41WwAXg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://www.amazon.in/dp/B07DGPTNV6?th=1&amp;linkCode=sl1&amp;tag=avi1deals5-21&amp;linkId=d3b8d89ec726b65f197dec7c2c56bc97&amp;language=en_IN&amp;ref_=as_li_ss_tl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42hjWBW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/421Dmva"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NCKwBk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44jon0Y"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nlWQve"
     },
     {
      "type": "plain",
      "text": "\n\n @400\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/6177iqyDSGL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Sports, Fitness & Outdoors \n                Sports Gadgets \n                 Action Cameras & Accessories \nPryce400 \nDicos78 \n\n❌Regular Price  : ₹ 1499    /- 😱"
     }
    ]
   },
   {
    "id": 20057,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1683005022",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1984",
    "dicos": "31",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Neelam Stainless Steel Premium Dinner Set Combo (Set of 24 Pcs) ✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40QlkKF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LOe3XO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AJ8bbJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41TtseY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hzetyv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HxbBlU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NA51yV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HqLMUv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NuwTUN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oYtHGZ"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1984"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51iVsP9SsqL._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Dining \n                Tableware \nPryce1984 \nDicos31 \n\n❌Regular Price  : ₹  2599   /- 😱"
     }
    ]
   },
   {
    "id": 20058,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1683005121",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "699",
    "dicos": "30",
    "catagory": "Clothing",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   TLF Men T-Shirt✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44hOEwG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44lT4CU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40XejrI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LL77KG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nixGO0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HuCqXD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41VEe4b"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vpo3cR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HveAeG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41WoPk9"
     },
     {
      "type": "plain",
      "text": "\n\n @699\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71dv2+EaQxL._UX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Clothing"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Clothing & Accessories \n                Men \n                T-Shirts & Polos \nPryce699 \nDicos30 \n\n❌Regular Price  : ₹  899  /- 😱"
     }
    ]
   },
   {
    "id": 20059,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1683005222",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "99",
    "dicos": "67",
    "catagory": "Grocery",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Nuts About You CHIA Seeds, 200 g | Raw | Premium | 100% Natural | Diet Food✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41VEkc3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42h56LI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41TqkQb"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41Y5v6i"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VlPPHs"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/420fkjZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42jC6TT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oOLSP9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42hkqrK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ALhmsj"
     },
     {
      "type": "plain",
      "text": "\n\n @99\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71VggWBPI-L._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Grocery"
     },
     {
      "type": "plain",
      "text": " & Gourmet Foods\n\n                Grocery & Gourmet Foods \n                Dried Fruits, Nuts & Seeds \n                 Nuts & Seeds \nPryce99 \nDicos67 \n\n❌Regular Price  : ₹ 299   /- 😱"
     }
    ]
   },
   {
    "id": 20060,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1683005343",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "180",
    "dicos": "50",
    "catagory": "Health",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Beco Wooden Bamboo Toothbrush with Charcoal Activated Ultra-Soft Bristles | Pack of 4 | 100% Environment Friendly, BPA Free, Biodegradable and Compostable✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44mo858"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44kTitN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40XC3f4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/426ajpM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LMr6Jd"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NoLuRQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NoLyky"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LrjL0g"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LguN8s"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Ny6VQm"
     },
     {
      "type": "plain",
      "text": "\n\n @180\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/616Xc+sLg4L._SX569_PIbundle-4,TopRight,0,0_AA569SH20_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Health"
     },
     {
      "type": "plain",
      "text": " & Personal Care\n\n                Health & Personal Care \n                Oral Care \n                Toothbrushes & Accessories \nPryce180 \nDicos50 \n\n❌Regular Price  : ₹  360 /- 😱"
     }
    ]
   },
   {
    "id": 20061,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1683005443",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "147",
    "dicos": "50",
    "catagory": "Beauty",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   MyGlamm Two Of Your Kind Nail Enamel Duo Glitter Collection-High on Drama (Blood red + Gold Glitter)-| Long-Lasting Gel Finish Nail Polish Set |✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hv22Uw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AKcZh5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oYH4aa"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LLMzSn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LJp2l0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ncuR1a"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HuTeOg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VpMnM3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AL8LWs"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44gQw8R"
     },
     {
      "type": "plain",
      "text": "\n\n @147\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71-CHtM3vsL._SX569_PIbundle-2,TopRight,0,0_AA569SH20_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Beauty"
     },
     {
      "type": "plain",
      "text": "\n\n                Beauty \n                Manicure & Pedicure \n                Nails \nPryce147 \nDicos50 \n\n❌Regular Price  : ₹   249  /- 😱"
     }
    ]
   },
   {
    "id": 20062,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1683005666",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "306",
    "dicos": "69",
    "catagory": "Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   GEONIX WIFI DONGLE Mini USB Adapter GX-150 For Desktop And Laptop (IEEE 802.11N ) | GX150 Wireless Network Adapter | USB 2.0/1.1 With WPA2 WIF I Protected Setup| Supported OS Windows XP, vista and windows 7 / 10 / 10 Pro / One year warranty✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LpTpM1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44kCq6j"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nqlue3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42iAvO7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42clbCs"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42xFgUb"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41WxN0K"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/420g74r"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41VCPuC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NqmtWt"
     },
     {
      "type": "plain",
      "text": "\n\n @306\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61BtP1ZJdVL._SY450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Computers & Accessories \n                Networking Devices \n                Network Adapters \nPryce306 \nDicos69 \n\n❌Regular Price  : ₹  899   /- 😱"
     }
    ]
   },
   {
    "id": 20063,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1683005788",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "429",
    "dicos": "83",
    "catagory": "Clothing",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   MIRCHI FASHION Women's Fancy Cotton Blend Ikat Dyed Paisley Printed Saree with Blouse Piece✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p2fbOe"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42ho1X4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42fMlZb"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AMCU7M"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40TxUZS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LKMjmB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44hznfo"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44vSnHc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NrcEHN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NqmNEF"
     },
     {
      "type": "plain",
      "text": "\n\n @429\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71g8sPFhMaL._UY741_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Clothing"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Clothing & Accessories \n                Women \n                Ethnic Wear \nPryce429 \nDicos83 \n\n❌Regular Price  : ₹   1899   /- 😱"
     }
    ]
   },
   {
    "id": 20064,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1683005891",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "135",
    "dicos": "NaN",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Nayasa Lacy 2 Piece Plastic Tall Basket Set, Green✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NoMe9A"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LKMoGV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AL9iYs"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44iraaM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44nN7W6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40Yg9IN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44hPZDI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AIN7SM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HqEVKv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41WqaHH"
     },
     {
      "type": "plain",
      "text": "\n\n @135\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61R+NkDanIL._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Home Storage & Organisation \n                Boxes, Baskets & Bins \nPryce135 \nDicosNaN"
     }
    ]
   },
   {
    "id": 20065,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1683005993",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "139",
    "dicos": "30",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   BeeBaby UltraDry Waterproof Soft Fleece Baby Bed Protector, High Absorbent, Washable & Reusable Quick Dry Sheets for Infants, New Born, Toddlers. (Small, Cobalt)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nlc0Rr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nfs6w3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LshrG2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LNisdx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42eFMpP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VlUYPE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ALuuhe"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AKczr3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oXs8cq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42hlFao"
     },
     {
      "type": "plain",
      "text": "\n\n @139\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61POrcEan3L._SY450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Baby \n                Bedding, Furniture & Room Décor \n                Bedding \nPryce139 \nDicos30 \n\n❌Regular Price  : ₹  199  /- 😱"
     }
    ]
   },
   {
    "id": 20066,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1683006094",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "9495",
    "dicos": "50",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Borosil Volcano 13 Fin Oil Filled Radiator Room Heater, 2900 Watt, Black✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oXsa42"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42h6iPc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LkOtIp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p0vA63"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42cVVMv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44hzLdQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LLNRwH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NwUowM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NrcYpZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Nw4Q7A"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@9495"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51KRdaUpj2L._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Heating, Cooling & Air Quality \n                Room Heaters \nPryce9495 \nDicos50 \n\n❌Regular Price  : ₹  14999   /- 😱"
     }
    ]
   },
   {
    "id": 20067,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1683006196",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "376",
    "dicos": "83",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   wipro 10W B22D LED Cool Day White Bulb, Pack of 4 (Garnet)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NqQnJZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LJqbci"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40UYba0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ALgtQl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LMsDir"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40RMEbv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ALBL0o"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hvgax8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41T78ln"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vl3kXV"
     },
     {
      "type": "plain",
      "text": "\n\n @376\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/81BXPL7FeQL._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Indoor Lighting \n                Light Bulbs \nPryce376 \nDicos83 \n\n❌Regular Price  : ₹   1599   /- 😱"
     }
    ]
   },
   {
    "id": 20068,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1683006301",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1299",
    "dicos": "68",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   USB Microphone, FFUNX Computer Cardioid Condenser PC Gaming Mic with Tripod Stand & Pop Filter for Streaming, Podcasting, Vocal Recording, Compatible with Laptop&PC&Andriod Mobile (Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HxdQ8O"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44gRFxb"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LLsHi9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LHFMsV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VppSXf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ALv6Dy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nlZfpK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hxf5ol"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3noTgjZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41WotKg"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1299"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61qafebM97L._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Computers & Accessories \n                Accessories & Peripherals \n                Audio & Video Accessories \nPryce1299 \nDicos68 \n\n❌Regular Price  : ₹  2999  /- 😱"
     }
    ]
   },
   {
    "id": 20069,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1683006402",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "649",
    "dicos": "57",
    "catagory": "Video",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Ant Value FKBRI03 / Auto-Stand-by, Silent Keys, 8 hot Keys Keyboard and Mouse Set Wireless Desktop Keyboard (Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41VoLRO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vpq7S9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40VBB0X"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/426c6v0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42eesb9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LLf0Qt"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ni9dsj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NwVetq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NCMK3E"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LoU2W7"
     },
     {
      "type": "plain",
      "text": "\n\n @649\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61-0tlLQQkL._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Video"
     },
     {
      "type": "plain",
      "text": " Games\n\n                Computers & Accessories \n                Accessories & Peripherals \n                Keyboards, Mice & Input Devices \nPryce649 \nDicos57 \n\n❌Regular Price  : ₹  1299    /- 😱"
     }
    ]
   },
   {
    "id": 20070,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1683006502",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "466",
    "dicos": "77",
    "catagory": "Video",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Gaming mouse for PC with wire (1.5m) RED, optical sensor adn 6 buttons compatible with Windows XP/Vista/7/8✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40UYCBa"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p1lNwF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LNjpCD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NA7JEB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AIEw2D"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AMEjey"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40ZEuxM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40PQrWU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NvLPSS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AMEre2"
     },
     {
      "type": "plain",
      "text": "\n\n @466\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61u1D9-fATL._SY679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Video"
     },
     {
      "type": "plain",
      "text": " Games\n\n                Computers & Accessories \n                Accessories & Peripherals \n                Keyboards, Mice & Input Devices \nPryce466 \nDicos77 \n\n❌Regular Price  : ₹  1299    /- 😱"
     }
    ]
   },
   {
    "id": 20071,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1683006606",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "3999",
    "dicos": "65",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   GOVO GOSURROUND 410 80 Watt 2.0 Channel with HDMI and Optical, Wireless Bluetooth Soundbar, RMS Output 40 W (Platinum Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44vTnuW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nj9Wt5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nj9Xx9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VD8dMd"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VnDUc4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VlVSf0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LLtjEt"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VpqnAB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LLtCiB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LLOFBJ"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@3999"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/816TspTpEdL._SY450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Home Audio \n                Speakers \nPryce3999 \nDicos65 \n\n❌Regular Price  : ₹  9999  /- 😱"
     }
    ]
   },
   {
    "id": 20072,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1683006709",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1845",
    "dicos": "42",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Pigeon by Stovekraft Classic Lite Mixer Grinder with 550W motor and 2 Stainless Steel Jars (Ivory)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AR8jpz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44vTBSO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nja5N9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AKdIPn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AGVcaJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HSA4Cr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Nqo4eV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HuFcMx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ncx9xi"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HwYjWu"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1845"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/41c3ns1nvFL._SX466_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Home Appliances \n                Small Kitchen Appliances \nPryce1845 \nDicos42 \n\n❌Regular Price  : ₹  2999   /- 😱"
     }
    ]
   },
   {
    "id": 20073,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1683006810",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "595",
    "dicos": "50",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   PHILIPS Ace Saver 10W B22 LED Bulb,900lm, Cool Day Light, Pack of 6✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vqz9Pa"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3niAnPC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VrI1DV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LLtWxP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LVObt7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vq9RjO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44eXkUJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42iC8LJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42hc0k3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44xgEwD"
     },
     {
      "type": "plain",
      "text": "\n\n @595\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/81oi06TLL0L._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Indoor Lighting \n                Light Bulbs \nPryce595 \nDicos50 \n\n❌Regular Price  : ₹  1199   /- 😱"
     }
    ]
   },
   {
    "id": 20074,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1683006914",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1779",
    "dicos": "56",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Amazon Basics 6 Piece Non-Stick Aluminium Cookware Set, Saucepan, Fry Pan (without Induction Base), Black✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VD8P4t"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LgxDu8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ni9QSH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3njlvk2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LLfVAp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NxNqHF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LnEjXp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HrvD0Y"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HuuW78"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LsiSEq"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1779"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/81vJqi8AwYL._SX466_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Dining \n                Cookware \nPryce1779 \nDicos56 \n\n❌Regular Price  : ₹   2999  /- 😱"
     }
    ]
   },
   {
    "id": 20075,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1683007015",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "499",
    "dicos": "38",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Kolorr Stitch Pedal Waste Bin Modern Design Trash Can Plastic Dustbin - 7L (Daiso Grey)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nqnEdF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LKPnPB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41b28Yx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LLubZL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42hq5yi"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3niewYJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NsIwLY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44lVzVO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42c8xn1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NpFyrL"
     },
     {
      "type": "plain",
      "text": "\n\n @499\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/318y4y86VSL._SX300_SY300_QL70_FMwebp_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Home Storage & Organisation \n                Waste & Recycling \nPryce499 \nDicos38 \n\n❌Regular Price  : ₹   699  /- 😱"
     }
    ]
   },
   {
    "id": 20076,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1683007118",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "229",
    "dicos": "62",
    "catagory": "Tools",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   GLUN Magic Silicone Dish Washing Gloves, Silicon Cleaning Gloves, Silicon Hand Gloves for Kitchen Dishwashing and Pet Grooming, Great for Washing Dish, Car, Bathroom (1 Pair,Assorted)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nieJet"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HuBkLN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VkyhLP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44hNj99"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3niadwz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ALbhfm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VlWZeG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AR96H3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40V83AH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HwCZjX"
     },
     {
      "type": "plain",
      "text": "\n\n @229\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/41zgcZKX6QL.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Tools"
     },
     {
      "type": "plain",
      "text": " & Home Improvement\n\n                Home & Kitchen \n                Home & kitchen | Prime shopping days \n                Prime Units \nPryce229 \nDicos62 \n\n❌Regular Price  : ₹   599   /- 😱"
     }
    ]
   },
   {
    "id": 20077,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1683007220",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "159",
    "dicos": "71",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   GANESH Wonder Kitchen Dori Handy Vegetable and Fruit Manual Onion Dry Fruit Salad Maker Vegetable Quick String Chopper Machine, Cutter – 3 Stainless Steel Blades (470 ml, Grey)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HqGWq3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nfurHl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oR79aW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3njmiS2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ALuNbO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nlpCw4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ALuQEw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41b2AWJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40X57DJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42c7XWm"
     },
     {
      "type": "plain",
      "text": "\n\n @159\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/817ti0wOyVL._SX466_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Dining \n                Kitchen Tools \nPryce159 \nDicos71 \n\n❌Regular Price  : ₹   499  /- 😱"
     }
    ]
   },
   {
    "id": 20078,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1683007322",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "9990",
    "dicos": "33",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Sony WH-CH720N, Wireless Over-Ear Active Noise Cancellation Headphones with Mic, up to 50 Hours Playtime, Multi-Point Connection, App Support, AUX & Voice Assistant Support for Mobile Phones (Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44mrgxU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LpVyHz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LgxTsU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LMyTGO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ndWVRP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NuFOWz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LmKX0c"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lgy1bS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Nvl550"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41b2RZL"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@9990"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51rpbVmi9XL._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Headphones, Earbuds & Accessories \n                Headphones \nPryce9990 \nDicos33 \n\n❌Regular Price  : ₹   13999  /- 😱"
     }
    ]
   },
   {
    "id": 20079,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1683007425",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "3999",
    "dicos": "43",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   JBL Tune 130NC True Wireless in Ear Earbuds Active Noise Cancellation (Upto 40dB) Adjust EQ for Extra Bass Massive 40Hrs Playtime Legendary Sound 4Mics for Clear Calls BT 5.2 (Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40Yinb7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40PRAOc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AGWyCl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3noUZpt"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oTGQRr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/426dUUO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hvirsa"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44hSCp4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vqb2je"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oYyBUp"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@3999"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/510B7v93YKL._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Headphones, Earbuds & Accessories \n                Headphones \nPryce3999 \nDicos43 \n\n❌Regular Price  : ₹   5999  /- 😱"
     }
    ]
   },
   {
    "id": 20080,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1683007525",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "245",
    "dicos": "81",
    "catagory": "All",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Yashika Womens Printed Georgette Saree Without Blouse✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44mB236"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nifF2t"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LJso7A"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nfvjf5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LMlRJy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NxOs6v"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NvzYnJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AN2fhS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3APMWoA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vkj2Tb"
     },
     {
      "type": "plain",
      "text": "\n\n @245\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/81xnPqnbO7L._UY741_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#All"
     },
     {
      "type": "plain",
      "text": "\n\n                Fashion \n                Apparel Steal Deals \n                Apparel_1 \nPryce245 \nDicos81 \n\n❌Regular Price  : ₹  1199   /- 😱"
     }
    ]
   },
   {
    "id": 20081,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1683007627",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "299",
    "dicos": "70",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Fashion String 144 TC Microfibre Orange Double Bedsheet with 2 King Size Pillow Covers✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oYz1tX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44laeQN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42c8HL8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lmj5Jn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LmLzmw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nqoPK7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LHIpuN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p1o3E9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42aBEYt"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NtZIkc"
     },
     {
      "type": "plain",
      "text": "\n\n @299\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71oykWrlNiL._SY679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Home Furnishing \n                Bedding & Linen \nPryce299 \nDicos70 \n\n❌Regular Price  : ₹   899  /- 😱"
     }
    ]
   },
   {
    "id": 20082,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1683007728",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "2075",
    "dicos": "48",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   \n by Nilkamal Double Flannel Blanket Sea Green✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hv6rXy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LKQM8P"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42jEZE9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3njvDJw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nig70F"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3njc1Fp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44lawal"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hx06Lc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NsK4Wi"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oXuS9I"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@2075"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71EN7JSEtxL._SY606_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Home Furnishing \n                Bedding & Linen \nPryce2075 \nDicos48 \n\n❌Regular Price  : ₹  3499   /- 😱"
     }
    ]
   },
   {
    "id": 20083,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1683007831",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "3999",
    "dicos": "60",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Boult Audio Anchor Bluetooth Wireless Over Ear Headphones with Mic, Active Noise Cancellation, 30H Playtime, AUX Option, Balanced Audio 40mm Drivers, Voice Assistant Sweatproof Gaming ANC Headphones✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44hHJUj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3niCaEk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ALm3Cr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p3Sw47"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VrAoxw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LHJ0N3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LLRv9R"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HvRBQn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41VtUsS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42hpb4A"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@3999"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/31Sd4H9cJlL._SX300_SY300_QL70_FMwebp_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Headphones, Earbuds & Accessories \n                Headphones \nPryce3999 \nDicos60 \n\n❌Regular Price  : ₹  8999  /- 😱"
     }
    ]
   },
   {
    "id": 20084,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1683007934",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "3899",
    "dicos": "22",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   JBL Wave Beam in-Ear Earbuds (TWS) with Mic, App for Customized Extra Bass EQ, 32 Hours Battery and Quick Charge, IP54 Water & Dust Resistance, Ambient Aware & Talk-Thru, Google FastPair (Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42aC15j"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40X4cmP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40UdVKC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40YjHuB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LohXFk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/429DHLC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LlX6Ct"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LLKHsT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VvpiaD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AGY73b"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@3899"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61p1-gm4RwL._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Headphones, Earbuds & Accessories \n                Headphones \nPryce3899 \nDicos22 \n\n❌Regular Price  : ₹  4999   /- 😱"
     }
    ]
   },
   {
    "id": 20085,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1683008034",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "199",
    "dicos": "50",
    "catagory": "Grocery",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Bevzilla Instant Coffee Sachets & Shaker Pack With 25 Flavoured Coffee Sachets (2 Grams Each) And BPA Free Shaker 500 Ml For Office, School, Gym, College, Makes Cold Coffee, Frappe, Cafe Shakes At Home | English Butterscotch, Colombian Gold, French Vanilla & Turkish Hazelnut Flavour | Protein Shaker| Cold Coffee Shaker| Leak Proof | Gym Shaker Bottle✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lrx80g"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NuKXxx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oXOhXW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40Pocri"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oTIcvv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/426fyFY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AHGLmK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hw0vh3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oYLVrU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44cfgz4"
     },
     {
      "type": "plain",
      "text": "\n\n @199\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/81mlbFBToVL._SX679_PIbundle-26,TopRight,0,0_AA679SH20_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Grocery"
     },
     {
      "type": "plain",
      "text": " & Gourmet Foods\n\n                Health & Personal Care \n                Diet & Nutrition \n                Sports Supplements \nPryce199 \nDicos50 \n\n❌Regular Price  : ₹  399   /- 😱"
     }
    ]
   },
   {
    "id": 20086,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1683008136",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "553",
    "dicos": "77",
    "catagory": "Clothing",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Lavie Sport Westport 24 Ltrs Latest Backpack | College bags for girls & boys (Dark Olive)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p1xwLx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AN156i"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44hUZIu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hw0Wbb"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VqcEti"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Nw8COi"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HqPWMc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42bvkiQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LjaKWW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ALGejG"
     },
     {
      "type": "plain",
      "text": "\n\n @553\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/813IV8M92hS._UX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Clothing"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Bags, Wallets and Luggage \n                Bags & Backpacks \n                Backpacks \nPryce553 \nDicos77 \n\n❌Regular Price  : ₹   1899  /- 😱"
     }
    ]
   },
   {
    "id": 20087,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1683008235",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "216",
    "dicos": "67",
    "catagory": "Beauty",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   LA COLOR Setting Spray, Clear, 30 ml✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41WtD9l"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41VuTt4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42ehSuv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VqCJZC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nnPyXK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VkBf2V"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44gVX7L"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p1prXn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LrxNig"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LoYc0b"
     },
     {
      "type": "plain",
      "text": "\n\n @216\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51yf53l+v8L._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Beauty"
     },
     {
      "type": "plain",
      "text": "\n\n                Beauty \n                Hair Care \n                Styling \nPryce216 \nDicos67 \n\n❌Regular Price  : ₹  660   /- 😱"
     }
    ]
   },
   {
    "id": 20088,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1683008359",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1450",
    "dicos": "71",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Shredded Bamboo Pillow I Luxury Pillow I Big Size Pillow I Ultra Soft Pillow✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41VvhYy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LgAdA8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ANNVpz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/4108ABk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HrzBGU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41YbtEe"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HwUbWi"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Ht4g6A"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NMIYoP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3njdV91"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1450"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61qCDHtk5jL._SX522_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Home Furnishing \n                Bedding & Linen \nPryce1450 \nDicos71 \n\n❌Regular Price  : ₹  3999  /- 😱"
     }
    ]
   },
   {
    "id": 20089,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1683008481",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1587",
    "dicos": "40",
    "catagory": "Watches",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Timex Analog Black Dial Men's Watch-TW000R438✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LHKpDj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ALGSO8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HuGuHx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HwUrVg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41WsFtu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HuZJk8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p2Kf0h"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40XkUT0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LJv9Wu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42ccsQL"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1587"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51vSiDswk6L._UX466_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Watches"
     },
     {
      "type": "plain",
      "text": "\n\n                Watches \n                Trends \n                Men \nPryce1587 \nDicos40 \n\n❌Regular Price  : ₹   2499  /- 😱"
     }
    ]
   },
   {
    "id": 20090,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1683008582",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "253",
    "dicos": "52",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Dynore Set of 4 Measuring Cup with Wire Handle (Stainless Steel)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LsIGjU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42dcckC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42jI7zX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41UDqN7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oUmOq5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42iGhPN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44hFvEq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LgBAPu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44xkeXB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44mul0W"
     },
     {
      "type": "plain",
      "text": "\n\n @253\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/517xUVjJUeL.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Dining \n                Kitchen Tools \nPryce253 \nDicos52 \n\n❌Regular Price  : ₹ 525   /- 😱"
     }
    ]
   },
   {
    "id": 20091,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1683008704",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1749",
    "dicos": "71",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   CrossBeats Slide Bluetooth Truly Wireless in Earbuds 40ms Low Latency, Clearcomm™ Quad mic ENC, Best Music by EchoBlast™, 30Hrs Playtime, Snap Charge™, 10 mm Drivers Earphone-Green✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VqDFNC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LsmypI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Nu2rdq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p1q9E1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p1qHK5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41Wt0MM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41U1EXT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NAcDBv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Ntly7y"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40QsfDD"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1749"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51Vd+RCvsHL._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Headphones, Earbuds & Accessories \n                Headphones \nPryce1749 \nDicos71 \n\n❌Regular Price  : ₹   4999  /- 😱"
     }
    ]
   },
   {
    "id": 20092,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1683008806",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "649",
    "dicos": "74",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Portronics Harmonics Z5 Wireless Bluetooth Stereo Headset with 33Hrs Playtime, Double EQ Mode, 14.2 mm Dynamic Drivers, Click Action Buttons(White)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nejMwx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40TKhoD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41Wthzi"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HwUFvA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HvtL7g"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41SBMf1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42dyxOJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42dcCrc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vm1fLc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vvr7Ev"
     },
     {
      "type": "plain",
      "text": "\n\n @649\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/41U8gTtnDwL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Headphones, Earbuds & Accessories \n                Headphones \nPryce649 \nDicos74 \n\n❌Regular Price  : ₹  1599   /- 😱"
     }
    ]
   },
   {
    "id": 20093,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1683008929",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1199",
    "dicos": "60",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Portronics Pixel 8W Portable Bluetooth Speaker with 32 LED Displays Animation, TWS Feature, USB & SD Card Slot(Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44hWGWm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44xkwxF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44hWTJ8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40PV1o4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HqSFoP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HvTWuD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41Ycxbc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3noZ0dx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VnlSXk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LoZjwT"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1199"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51NbIJ1wWlL._SY450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Home Audio \n                Speakers \nPryce1199 \nDicos60 \n\n❌Regular Price  : ₹ 2499   /- 😱"
     }
    ]
   },
   {
    "id": 20094,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1683009050",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "2449",
    "dicos": "76",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   CrossBeats Newly launched Revolt Hybrid Active Noise Cancelling Wireless Bluetooth Earbuds, 48hrs Playtime TWS, 10 mm Ti-Alloy Driver, EchoBlast™, 40ms Low Latency Earphone-Black✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LmlLqp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42fSswB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AIxSJo"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LgCr2E"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p0C2Kj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oWvUTg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40Q4hsp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41VMkK7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p18Oel"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LgCyeA"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@2449"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61J9BzebDnL._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Headphones, Earbuds & Accessories \n                Headphones \nPryce2449 \nDicos76 \n\n❌Regular Price  : ₹  8999   /- 😱"
     }
    ]
   },
   {
    "id": 20095,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1683009151",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "904",
    "dicos": "10",
    "catagory": "Grocery",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Christopher Cocoa Hazelnut Mocha Hot Drinking Chocolate Powder with Dark Chocolate Buttons 1Kg, Brown✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LLLqu0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ALoxRh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LokWxw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40UgmNg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AIK511"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nfyun1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41WiSDJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HvUsZB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oOTjpx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44icLvb"
     },
     {
      "type": "plain",
      "text": "\n\n @904\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71EGC3H6h6L._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Grocery"
     },
     {
      "type": "plain",
      "text": " & Gourmet Foods\n\n                Grocery & Gourmet Foods \n                Coffee, Tea & Beverages \n                Powdered Drink Mixes \nPryce904 \nDicos10 \n\n❌Regular Price  : ₹   999  /- 😱"
     }
    ]
   },
   {
    "id": 20096,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1683009252",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "728",
    "dicos": "88",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   tu casa Ntu-107 Transparent Glass Shade Table Lantern Holder Type b-22 (Bulb not Included )✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oYCF77"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Ht5T4c"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hvmy7A"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41VMAZB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LLR8fy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B3Hqzb"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VqF3ji"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42xMyHx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LJwHzM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ncCbtG"
     },
     {
      "type": "plain",
      "text": "\n\n @728\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71T802PBe1L._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Indoor Lighting \n                 Table Lamps \nPryce728 \nDicos88 \n\n❌Regular Price  : ₹  3999   /- 😱"
     }
    ]
   },
   {
    "id": 20097,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1683009375",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "4213",
    "dicos": "50",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Lifelong Stormix Bagless Vacuum Cleaner for Home with Power Suction, Low Sound, High Energy Efficiency | 1200 W✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42fTg4B"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42f2v4Z"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AKnhhb"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44ldQ5j"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41U37gR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41S3cBu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VnmQmq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40Uh0ua"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VpGuyj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VpwswV"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@4213"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71rrqGY-TJL._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Home Appliances \n                Vacuum, Cleaning & Ironing \nPryce4213 \nDicos50 \n\n❌Regular Price  : ₹   7499  /- 😱"
     }
    ]
   },
   {
    "id": 20098,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1683009473",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "189",
    "dicos": "61",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Kuber Industries Code-Net11 Stainless Steel Multipurpose Net Lid, 8-inch, Silver✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42iHNkX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ncCmoQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41Mytpv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42hv7uG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NuLQ9C"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41Tea9L"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nsWi6J"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LrAxMA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oXRpDa"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40Q57p3"
     },
     {
      "type": "plain",
      "text": "\n\n @189\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51dLbW4fyTL._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Home Storage & Organisation \n                Clothing & Wardrobe Storage \nPryce189 \nDicos61 \n\n❌Regular Price  : ₹ 488   /- 😱"
     }
    ]
   },
   {
    "id": 20099,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1683009573",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "69",
    "dicos": "91",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   PTron Solero T241 2.4A Type-C Data & Charging USB Cable, Made in India, 480Mbps Data Sync, Durable 1-Meter Long USB Cable for Type-C USB Devices for Charging Adapter (Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lm0Io1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ALga8c"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VuMplO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3np02WX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nifrZd"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41QHyxw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HqLY5V"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LMEgFY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40XJFhE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NuOjRf"
     },
     {
      "type": "plain",
      "text": "\n\n @69\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51eaN2pOkNL._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Computers & Accessories \n                Accessories & Peripherals \n                Cables & Accessories \nPryce69 \nDicos91 \n\n❌Regular Price  : ₹   499  /- 😱"
     }
    ]
   },
   {
    "id": 20100,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1683009694",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1807",
    "dicos": "48",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Crystal Eco Plus 8-pc Non-Stick Cookware Set, PFOA Free, Tawa, Kadhai with SS Lid, Fry Pan, Vagariya, Mini Grill Pan, 2 Spatulas, Blue✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LLNZwf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3np0iFp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LLzR61"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ne1PhH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42heizI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LNq0Np"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p2Kg4o"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vpxrx7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40PrQS0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40TMlNp"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1807"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71479EeTv0L._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Dining \n                Cookware \nPryce1807 \nDicos48 \n\n❌Regular Price  : ₹  2999   /- 😱"
     }
    ]
   },
   {
    "id": 20101,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1683009793",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "140",
    "dicos": "50",
    "catagory": "Beauty",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Park Avenue Pure Luxury Soap Pack of 4 500gm✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40TMbFN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42aG5CB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40X8oTB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NAeMx3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LodoL8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NvSc8K"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nlkc49"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40Q6fJj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41TeSUt"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://www.amazon.in/dp/B0B6ZXHMX7?smid=A15APWRK6P7LBV&amp;th=1&amp;linkCode=sl1&amp;tag=avi1deals10-21&amp;linkId=83d5a40c78eb94649d92b9cf387693e7&amp;language=en_IN&amp;ref_=as_li_ss_tl"
     },
     {
      "type": "plain",
      "text": "\n\n @140\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/81X1sriXnAL._SX569_PIbundle-4,TopRight,0,0_AA569SH20_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Beauty"
     },
     {
      "type": "plain",
      "text": "\n\n                Beauty \n                Bath & Body \n                Cleansers \nPryce140 \nDicos50 \n\n❌Regular Price  : ₹  280   /- 😱"
     }
    ]
   },
   {
    "id": 20102,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1683009913",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "44",
    "dicos": "96",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Suzec Lemon Zester & Cheese Grater - Cheese, Lemon, Ginger, Garlic, Vegetables, Fruits - Stainless Steel Protective Cover, Dishwasher Safe✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Ln8E8n"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41VNRzR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41UGkS1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HuMyj7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41b8vLr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nl9jzo"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NuLorZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LJ6q4L"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p1aDYJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vp9PJ1"
     },
     {
      "type": "plain",
      "text": "\n\n @44\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51fyRCxhlpL._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Dining \n                Kitchen Tools \nPryce44 \nDicos96 \n\n❌Regular Price  : ₹   899  /- 😱"
     }
    ]
   },
   {
    "id": 20103,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1683010035",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "649",
    "dicos": "68",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Portronics Harmonics Twins S3 Smart TWS Bluetooth 5.2 Earbuds with 20 Hrs Playtime, 8 MM Drivers, Lightweight Earbuds(Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44f3iVD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hvw1vg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oUCA4d"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42bzhUI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41MzGx3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nm7emI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VnnRuM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42dWipV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LLNpys"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3B3IWBn"
     },
     {
      "type": "plain",
      "text": "\n\n @649\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/5108gi-TZUL._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Headphones, Earbuds & Accessories \n                Headphones \nPryce649 \nDicos68 \n\n❌Regular Price  : ₹   1499   /- 😱"
     }
    ]
   },
   {
    "id": 20104,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1683010158",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1299",
    "dicos": "58",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Honeywell Suono P100, Portable Speaker, Premium Stereo Sound with Wireless Bluetooth 5.0 Connectivity, TWS Feature and Up to 12 Hours Playtime (Blue)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oY7xET"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VmyIFc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ALqxZN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42xOIH9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ALqH3l"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41VwKyg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40Psv5W"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LHNt2h"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VnLFic"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44mHs2c"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1299"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/615LAwnXD0L._SY450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Home Audio \n                Speakers \nPryce1299 \nDicos58 \n\n❌Regular Price  : ₹   2499  /- 😱"
     }
    ]
   },
   {
    "id": 20105,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1683010261",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "599",
    "dicos": "70",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Portronics Harmonics Z2 Wireless Bluetooth 5.2 Headset with Mic, ENC Noise Cancelling, Upto 30Hrs Playtime, Fast Charging Type C Neckband, Voice Assistant, & in-line Controls (Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40W9JKh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44jykvm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41b8V4t"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41TE1i8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41SEqRZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Nu5l1O"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NtR45i"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NxUDYf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Loesi6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41TfM3j"
     },
     {
      "type": "plain",
      "text": "\n\n @599\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/41v21i5fuZL._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Headphones, Earbuds & Accessories \n                Headphones \nPryce599 \nDicos70 \n\n❌Regular Price  : ₹   1499  /- 😱"
     }
    ]
   },
   {
    "id": 20106,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1683010384",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1099",
    "dicos": "56",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Portronics SoundDrum 1 10W TWS Portable Bluetooth 5.0 Speaker with Powerful Bass, Inbuilt-FM & Type C Charging Cable Included(Green)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LoJvKL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AIj79J"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40UiJQa"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40UiPHw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41QIXUO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/427DTLK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HuC1EK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oUD76d"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VkXwgZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NuPU9B"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1099"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/515hY2JM7fL._SY450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Home Audio \n                Speakers \nPryce1099 \nDicos56 \n\n❌Regular Price  : ₹  1999   /- 😱"
     }
    ]
   },
   {
    "id": 20107,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1683010485",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "6599",
    "dicos": "63",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   GOVO GOSURROUND 900 | 200W Soundbar, 2.1 Channel Home Theatre, 6.5” Wired Subwoofer, HDMI, AUX, USB & Bluetooth, 4 Equalizer Modes, Stylish Remote & LED Display, RMS 160W (Platinum Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NuGJ9b"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NqZhHp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40XLDyy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40Qw23P"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40Qw8bH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AIMxEL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HvwKwu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41YfI2C"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vm09PI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VDgHD3"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@6599"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/31XhRxMvTOL._SX300_SY300_QL70_FMwebp_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Home Audio \n                Speakers \nPryce6599 \nDicos63 \n\n❌Regular Price  : ₹   14999  /- 😱"
     }
    ]
   },
   {
    "id": 20108,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1683010586",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "114",
    "dicos": "57",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Eveready 0.5-Watt LED Deco Bulb (White and Pack of 3)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Ht8Ly0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/421O19a"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44i03wu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/426lcb8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42iJSxh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42hkacb"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3APT7sM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AI49Aq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AIMZmr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oUDIVv"
     },
     {
      "type": "plain",
      "text": "\n\n @114\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51YzFHQmHAL._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Indoor Lighting \n                Light Bulbs \nPryce114 \nDicos57 \n\n❌Regular Price  : ₹  270  /- 😱"
     }
    ]
   },
   {
    "id": 20109,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1683010707",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "139",
    "dicos": "72",
    "catagory": "Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   GIZGA Essentials Portable Tabletop Tablet Stand Mobile Holder, Desktop Stand, Cradle, Dock for iPad, Smartphone, Kindle, E-Reader, Fully Foldable, Adjustable Angle, Anti-Slip Pads, Black✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44f4Sa1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hvx2DA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44hJNvw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ntmpKK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LMG69Q"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LLBIrv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41UHP2B"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41WAbVj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NwdmDA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41T4wUF"
     },
     {
      "type": "plain",
      "text": "\n\n @139\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61zODtv7sfL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Computers & Accessories \n                Accessories & Peripherals \n                Tablet Accessories \nPryce139 \nDicos72 \n\n❌Regular Price  : ₹  499   /- 😱"
     }
    ]
   },
   {
    "id": 20110,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1683010811",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "219",
    "dicos": "87",
    "catagory": "Clothing",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Naughty Ninos Unisex Cotton Hooded Neck Sweatshirt✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HxwixT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Nu6fve"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VpuFYW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ALBQkM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nolewo"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LOoPxe"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nlEr1z"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oY5nF5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LkXXU1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HuO2Kd"
     },
     {
      "type": "plain",
      "text": "\n\n @219\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71MttCH1csL._UY741_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Clothing"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Clothing & Accessories \n                Girls \n                Winter Wear \nPryce219 \nDicos87 \n\n❌Regular Price  : ₹   1399  /- 😱"
     }
    ]
   },
   {
    "id": 20111,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1683010936",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "487",
    "dicos": "46",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Cutting EDGE Turkish Basket (1 x Medium, 2 x Small for Kitchen), Vegetables, Toys, Books, Office, Stationery, Utility, Cosmetics, Accessories, Closet, Wardrobe, (Set of 3, Brown)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oY8Cwr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HxwJbv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40VKiZ9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p3YQbR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vm0KAW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vm5Soy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41SFtkT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lwpcv0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VmAiH8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VrQVRR"
     },
     {
      "type": "plain",
      "text": "\n\n @487\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71ky-mocCYL._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Home Storage & Organisation \n                Boxes, Baskets & Bins \nPryce487 \nDicos46 \n\n❌Regular Price  : ₹   849   /- 😱"
     }
    ]
   },
   {
    "id": 20112,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1683011057",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "2100",
    "dicos": "27",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Inalsa Cookware Platinum Triply Sauce Pan with Lid-16cm, 1.75L| Induction Friendly, (Silver), Small✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44xoxCf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41bagIx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LooqjA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44mIQBW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42a2u2e"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44mz82q"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vldh7J"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LMsBHm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41WySpx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42gGu64"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@2100"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51e3A4uvjPL._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Dining \n                Cookware \nPryce2100 \nDicos27 \n\n❌Regular Price  : ₹  2699  /- 😱"
     }
    ]
   },
   {
    "id": 20113,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1683011158",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "499",
    "dicos": "80",
    "catagory": "Clothing",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Yashika Womens Georgette Printed Saree With Blouse Piece✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hv47Q8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HwLJGN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nsZL5f"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LMCEMz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40Q8BI9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LLo87H"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40WkfB2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oXUBPa"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NuR1Gp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nlmZu9"
     },
     {
      "type": "plain",
      "text": "\n\n @499\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/81dfxZfhY+L._UX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Clothing"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Clothing & Accessories \n                Women \n                Ethnic Wear \nPryce499 \nDicos80 \n\n❌Regular Price  : ₹ 1499   /- 😱"
     }
    ]
   },
   {
    "id": 20114,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1683011257",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "499",
    "dicos": "75",
    "catagory": "Clothing",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Yashika Women's Combo Art Silk Printed Saree With Blouse Piece Pack Of 2✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42hB0Yq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HvYaT1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41UJcON"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Ht9Plu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44xp0Ev"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oYGQjj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LLQRcv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44mzt5c"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AKqMEl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/411hkHf"
     },
     {
      "type": "plain",
      "text": "\n\n @499\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/812r9A7ds6L._UX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Clothing"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Clothing & Accessories \n                Women \n                Ethnic Wear \nPryce499 \nDicos75 \n\n❌Regular Price  : ₹  1299  /- 😱"
     }
    ]
   },
   {
    "id": 20115,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1683011379",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "265",
    "dicos": "78",
    "catagory": "Clothing",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Yashika Women's Georgette Printed Saree With Blouse Piece(RACHNA_Free Size)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40RXjTz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HvydTw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HrGl7G"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NMOisj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ne5gVD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LrDLQc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LHPLOV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oTOAmt"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42chAnZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Nv74nH"
     },
     {
      "type": "plain",
      "text": "\n\n @265\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71YYlde-ZoL._UY741_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Clothing"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Fashion \n                Apparel Steal Deals \n                Apparel_1 \nPryce265 \nDicos78 \n\n❌Regular Price  : ₹  899  /- 😱"
     }
    ]
   },
   {
    "id": 20116,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1683011500",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "499",
    "dicos": "80",
    "catagory": "All",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Yashika Womens Solid Net Saree With Blouse Piece✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NvV59A"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40UhcJB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44m5lY0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oYF4P4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p5eGmw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lrwq3i"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p1dHUJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VkZvBX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44mzRAG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Nvt4z0"
     },
     {
      "type": "plain",
      "text": "\n\n @499\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/710-S7WQA8L._UX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#All"
     },
     {
      "type": "plain",
      "text": "\n\n                Clothing & Accessories \n                Women \n                Ethnic Wear \nPryce499 \nDicos80 \n\n❌Regular Price  : ₹  1399   /- 😱"
     }
    ]
   },
   {
    "id": 20117,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1683011602",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "169",
    "dicos": "81",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   SAF Set of 3 Buddha UV Textured Home Decorative Gift Item Painting , 18 Inch X 12 Inch - SANFJM31046, Figures, Religious✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44nXRDU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NAhSkF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nqxa0n"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41WBCDb"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41WnjhR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ALrHEt"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NxWTyH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42g4N40"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40PvcEA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Htr0DH"
     },
     {
      "type": "plain",
      "text": "\n\n @169\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61wu5AOuAfS._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Artwork \n                 Paintings \nPryce169 \nDicos81 \n\n❌Regular Price  : ₹  899   /- 😱"
     }
    ]
   },
   {
    "id": 20118,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1683011703",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "4045",
    "dicos": "49",
    "catagory": "Baby",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Mee Mee Ultra Sleek Baby High Chair with 7 dicos Adjustment Levels 3 Compact Folding Chair with Feeding Tray (Blue) (Blue Tents)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HvodKc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LHPXxD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40Wcqvn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41VOk5g"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42dZdin"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42dEknr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oXVHdK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41WAcbZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VkrXE9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44m5DOA"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@4045"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/6149kHwBrpL._SY450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Baby"
     },
     {
      "type": "plain",
      "text": "\n\n                Baby \n                Bedding, Furniture & Room Décor \n                Furniture \nPryce4045 \nDicos49 \n\n❌Regular Price  : ₹  6999  /- 😱"
     }
    ]
   },
   {
    "id": 20119,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1683011806",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "749",
    "dicos": "75",
    "catagory": "Clothing",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Intellilens Square Blue Cut Computer Glasses for Eye Protection | Unisex, UV ProtectionZero Power, Anti Glare & Blue Light Filter Glasses✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44hW96P"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44mJZte"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LncfTV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LgHNeg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HxqVyN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hvrx8i"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LrEu3S"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NoXoLw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nfDR5F"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42xR7l9"
     },
     {
      "type": "plain",
      "text": "\n\n @749\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51b1GOiEAQL._UX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Clothing"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Health & Personal Care \n                Health Care \n                Eye Care \nPryce749 \nDicos75 \n\n❌Regular Price  : ₹  1999   /- 😱"
     }
    ]
   },
   {
    "id": 20120,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1683011909",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "299",
    "dicos": "70",
    "catagory": "Car",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Generic Waterproof Two Wheeler All Bike Cover Bullet Black✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AKpysL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p5fkjW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3njDLtw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40Q9Max"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42a3NhE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40TQbWP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40WcT0B"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oRh5kK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42cxYVu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42bCiV2"
     },
     {
      "type": "plain",
      "text": "\n\n @299\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/31bRXNKKCrL._SX450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Car"
     },
     {
      "type": "plain",
      "text": " & Motorbike\n\n                Car & Motorbike \n                Motorbike Accessories & Parts \n                Accessories \nPryce299 \nDicos70 \n\n❌Regular Price  : ₹  899   /- 😱"
     }
    ]
   },
   {
    "id": 20121,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1683012008",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "99",
    "dicos": "50",
    "catagory": "Sports",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   VENOM Wooden Hand Gripper, Hand Gripper, Hand Grip Strenthener, Wooden Hand Gripper, Hand Grip Exerciser, Hand Gripper for Men & Women (Model : 1022) (Pack of 2) (Brown)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nnWT9K"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NwfJpY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LjibNQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3neoWbT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AJlzfZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ARjr5P"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NtrzBa"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HzrDvp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HvrHMW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40XeVxx"
     },
     {
      "type": "plain",
      "text": "\n\n @99\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51dMZg+z95L._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Sports"
     },
     {
      "type": "plain",
      "text": ", Fitness & Outdoors\n\n                Sports, Fitness & Outdoors \n                Exercise & Fitness \n                Strength Training Equipment \nPryce99 \nDicos50 \n\n❌Regular Price  : ₹  199  /- 😱"
     }
    ]
   },
   {
    "id": 20122,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1683012129",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "2499",
    "dicos": "50",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Havells Glaze 1200mm 1 Star Energy Saving Ceiling Fan (Elegant White Gold, Pack of 1)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p2QS2F"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41U8EE9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42fYdKJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hvsbme"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nioBVz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44w3Nuy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oYIb9P"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44e3bJT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44iiH7r"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3njko3N"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@2499"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/410nxidfTEL._SX466_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Heating, Cooling & Air Quality \n                Fans \nPryce2499 \nDicos50 \n\n❌Regular Price  : ₹   3999  /- 😱"
     }
    ]
   },
   {
    "id": 20123,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1683012249",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "2499",
    "dicos": "50",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Havells Glaze 1200mm 1 Star Energy Saving Ceiling Fan (Sapphire Blue Chrome, Pack of 1)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41bc8B3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LLWvLK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nijO6z"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Ll0nSB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41TDIUr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LJfJAD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42a4ebM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VnsrsW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VrT6ov"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42g6kag"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@2499"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/41seaZtJAFL._SX466_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Heating, Cooling & Air Quality \n                Fans \nPryce2499 \nDicos50 \n\n❌Regular Price  : ₹  3999  /- 😱"
     }
    ]
   },
   {
    "id": 20124,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1683012371",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "360",
    "dicos": "36",
    "catagory": "Grocery",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Hershey's Cookies n Crème Chocolate Bar, 100g (Pack of 4)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AKsKod"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LstN0S"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p0Ihhd"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44l5FpJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/429LHfC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42hy3Hq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NtV064"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42dFU8R"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LrK9qP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lnden5"
     },
     {
      "type": "plain",
      "text": "\n\n @360\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/511kP+3cyML._SX569_PIbundle-4,TopRight,0,0_AA569SH20_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Grocery"
     },
     {
      "type": "plain",
      "text": " & Gourmet Foods\n\n                Grocery & Gourmet Foods \n                Snacks & Sweets \n                Chocolate \nPryce360 \nDicos36 \n\n❌Regular Price  : ₹  560   /- 😱"
     }
    ]
   },
   {
    "id": 20125,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1683012472",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "2099",
    "dicos": "43",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Hindware Snowcrest Brisa Metallic 1200mm Designer 3 Blade Ceiling Fan with Premium Metallic Finish, Dust Resistant Aerodynamic Blades For Even Air Distribution (White)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nqyvUX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ngfuVs"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ALlIzy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AIQCZB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hw8j2l"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ALlZm4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ALujlV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HvzRVc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41TjOIZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42hjcg6"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@2099"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/410nTL2wppL._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Heating, Cooling & Air Quality \n                Fans \nPryce2099 \nDicos43 \n\n❌Regular Price  : ₹  2999  /- 😱"
     }
    ]
   },
   {
    "id": 20126,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1683012572",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "149",
    "dicos": "10",
    "catagory": "Beauty",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Blue Heaven Velvet Creme Lipstick, Dead Red, 3.5gm✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42e0GoT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HtZrdk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Nx6Exi"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NuQaFV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/410gF98"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p2sIW2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p0IDo3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HvsWvA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hxsm09"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41U9TDj"
     },
     {
      "type": "plain",
      "text": "\n\n @149\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/41w1lSDYctL._SY450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Beauty"
     },
     {
      "type": "plain",
      "text": "\n\n                Beauty \n                Make-up \n                Lips \nPryce149 \nDicos10 \n\n❌Regular Price  : ₹   165  /- 😱"
     }
    ]
   },
   {
    "id": 20127,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1683012674",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "5499",
    "dicos": "42",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   KENT 16022 Cold Pressed Juicer Plus | Low Speed Squeezing Technique | Horizontal juicer | Retains More Fibre and Nutrients✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hw8FWJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HuRbtv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oUuCrT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42hAtWO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VnQdoM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NvXEse"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44w53xM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42xSrV9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41Tkdet"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NuRskc"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@5499"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61hPQYm2aQS._SX522_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Home Appliances \n                Small Kitchen Appliances \nPryce5499 \nDicos42 \n\n❌Regular Price  : ₹  8999  /- 😱"
     }
    ]
   },
   {
    "id": 20128,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1683012775",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1733",
    "dicos": "53",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Hindware Snowcrest Brisa Metallic 1200mm Designer 3 Blade Ceiling Fan with Premium Metallic Finish, Dust Resistant Aerodynamic Blades For Even Air Distribution (Dark Coffee)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LmVybu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LsuAim"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oUuMQ1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42czjvu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NvKoDR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VnspBm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AMQOqu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40YtaC9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AHPQMm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3njFtLs"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1733"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/21Re8RjgmfL._SX300_SY300_QL70_FMwebp_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Heating, Cooling & Air Quality \n                Fans \nPryce1733 \nDicos53 \n\n❌Regular Price  : ₹   2999  /- 😱"
     }
    ]
   },
   {
    "id": 20129,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1683012881",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "165",
    "dicos": "34",
    "catagory": "Beauty",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Yardley English Lavender 200 ml Pack✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LOshba"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42hjXFY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41S9npa"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41VQqSG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3neqmTL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LLFUYh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HuRxAl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44l6XRB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44i4reW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40YtsZL"
     },
     {
      "type": "plain",
      "text": "\n\n @165\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51w0hSolGnL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Beauty"
     },
     {
      "type": "plain",
      "text": "\n\n                Beauty \n                Bath & Body \n                Deodorants & Antiperspirants \nPryce165 \nDicos34 \n\n❌Regular Price  : ₹  299   /- 😱"
     }
    ]
   },
   {
    "id": 20130,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1683012982",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "98",
    "dicos": "30",
    "catagory": "Grocery",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Tata Sampann Fibre Rich Dosa Mix | Ready to Cook Breakfast Mix | Ready in 15 Minutes | Golden, Crispy & Tasty Dosas | Authentic South Indian Taste | Instant Dosa Batter| 500 g✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p0JlSf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HtcFa8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41UaGEh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AI8hAq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hv7Ay8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LgK7lu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LW09D3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VnQMio"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oXF7Lc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NpQJkd"
     },
     {
      "type": "plain",
      "text": "\n\n @98\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61RPzSbYcML._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Grocery"
     },
     {
      "type": "plain",
      "text": " & Gourmet Foods\n\n                Grocery & Gourmet Foods \n                Ready To Eat & Cook \n                Instant Snacks & Breakfast Mixes \nPryce98 \nDicos30 \n\n❌Regular Price  : ₹  140   /- 😱"
     }
    ]
   },
   {
    "id": 20131,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1683013102",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "87",
    "dicos": "50",
    "catagory": "Grocery",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Earthmade Organic Penne Pasta- 100% Organic Durum Wheat Semolina✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VpytJI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41SJ0j9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41V9kci"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hw1fT5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/429NfWY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lq7hG5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LLwBHW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LnQtQ3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vqm3RC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LMFJMD"
     },
     {
      "type": "plain",
      "text": "\n\n @87\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61VHtA23aHL._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Grocery"
     },
     {
      "type": "plain",
      "text": " & Gourmet Foods\n\n                Grocery & Gourmet Foods \n                Pasta & Noodles \n                Pasta \nPryce87 \nDicos50 \n\n❌Regular Price  : ₹  174    /- 😱"
     }
    ]
   },
   {
    "id": 20132,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1683013209",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "999",
    "dicos": "80",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Wings Phantom 500 in Ear Earphones Wireless Earbuds with LED Battery Indicator, 40ms Low Latency, Bluetooth 5.1, 30 Hours Playtime, ENC, 10mm Speaker Size, with Mic (Black TWS)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NvYyVE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/429NlxO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oXY0NW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LqqFCJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p2RdT2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LmtPaF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NuaRl2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oTS5t7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42g04iF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HrJDYA"
     },
     {
      "type": "plain",
      "text": "\n\n @999\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/513lv00mN1L._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Headphones, Earbuds & Accessories \n                Headphones \nPryce999 \nDicos80 \n\n❌Regular Price  : ₹  2999   /- 😱"
     }
    ]
   },
   {
    "id": 20133,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1683013309",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "199",
    "dicos": "60",
    "catagory": "Beauty",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Body cupid Olive Oil Hair Mask - 200 ml✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ALIXd2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40ZS3NI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40XQy2u"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40VOND1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AJsg1x"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Nrvnmz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42bEBYc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3neqZwB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Np0j6W"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nmd4o8"
     },
     {
      "type": "plain",
      "text": "\n\n @199\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61zq-H3yyAS._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Beauty"
     },
     {
      "type": "plain",
      "text": "\n\n                Beauty \n                Hair Care \n                 Hair Masks & Packs \nPryce199 \nDicos60 \n\n❌Regular Price  : ₹   499  /- 😱"
     }
    ]
   },
   {
    "id": 20134,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1683013410",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "349",
    "dicos": "85",
    "catagory": "Clothing",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   GRECIILOOKS Men’s Night Suit| Casual Wear| Lounge-wear Nightdress Pajama Set | Summer Night Wear | Printed Casual Sleepwear| Pajama Shorts Night Suits Set| Beach Wear✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Htu7LT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hvh50s"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ALuGNb"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44cp3oO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ANbrDc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AHRqxM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41VF5BQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hzu94P"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41SJGoH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VmEwyu"
     },
     {
      "type": "plain",
      "text": "\n\n @349\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61k-h8Q2C1L._UX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Clothing"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Clothing & Accessories \n                Men \n                Sleep & Lounge Wear \nPryce349 \nDicos85 \n\n❌Regular Price  : ₹  1699   /- 😱"
     }
    ]
   },
   {
    "id": 20135,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1683013509",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "144",
    "dicos": "45",
    "catagory": "Grocery",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Haldiram's Dry Agra Petha, 350g/400g (Weight May Vary), Indian Sweets Diwali Gift Pack✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VDlSTv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vl3BKl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VqmHi0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44w6so2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42e2ka3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42dIgod"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p5i3da"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LqTKxR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44mDAhE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p2us1w"
     },
     {
      "type": "plain",
      "text": "\n\n @144\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61Ar9CNOZ-L._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Grocery"
     },
     {
      "type": "plain",
      "text": " & Gourmet Foods\n\n                Grocery & Gourmet Foods \n                Hampers & Gourmet Gifts \n                 Sweets Gifts \nPryce144 \nDicos45 \n\n❌Regular Price  : ₹  260   /- 😱"
     }
    ]
   },
   {
    "id": 20136,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1683013610",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "5999",
    "dicos": "60",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   boAt Aavante Bar 1200D Soundbar with Dolby Audio, 100W RMS Signature Sound, 2.1 Channel with Wired Subwoofer, Entertainment EQs, Multi-Connectivity & Master Remote Control(Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VmaE5s"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Nr4ZJl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41betvP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LosQXI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NtuBFy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40YuuF7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NqAHGR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42hFheq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HwPT1n"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AIGCzc"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@5999"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/31GdYLVT06L._SX300_SY300_QL70_FMwebp_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Home Audio \n                Speakers \nPryce5999 \nDicos60 \n\n❌Regular Price  : ₹  13999  /- 😱"
     }
    ]
   },
   {
    "id": 20137,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1683013711",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "329",
    "dicos": "45",
    "catagory": "Grocery",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   House Of Farms (HOF) Authentic Kashmiri Mongra Kesar (Saffron) - 1 Gram, For Biryani, Pulao & Sweet Dish Like Kheer & Phirni, Good Health, Tilak, Beauty & Skin, A+++ Grade Quality✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hr26of"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nn15GW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AJplpF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LLxyju"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LMLIAW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LM3uUT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VpzAZU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LJJp1E"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Nrw7rR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3APYkkk"
     },
     {
      "type": "plain",
      "text": "\n\n @329\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61BDkkNQ7PL._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Grocery"
     },
     {
      "type": "plain",
      "text": " & Gourmet Foods\n\n                Grocery & Gourmet Foods \n                Cooking & Baking Supplies \n                Spices & Masalas \nPryce329 \nDicos45 \n\n❌Regular Price  : ₹   599  /- 😱"
     }
    ]
   },
   {
    "id": 20138,
    "type": "message",
    "date": "2023-05-02",
    "date_unixtime": "1683029553",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "679",
    "dicos": "66",
    "catagory": "Toys",
    "text_entities": [
     {
      "type": "plain",
      "text": "Deal of the day: Wembley Toys Talking Cactus Baby Toys for Kids Dancing Cactus Toys Can Sing Wriggle & Singing Recording Repeat What You Say Funny Education Toys for Children Playing Home Decor Items for Kids \n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oQO1tG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LNidPs"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AKYSIn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/410pG1U"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LNeUYH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hryib5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HrxjYw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/428hdLm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/428hhL6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42ehhJb"
     },
     {
      "type": "plain",
      "text": "\n\n @679\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71ML2TRKAUL._SY450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Toys"
     },
     {
      "type": "plain",
      "text": " & Games\n\n                Toys & Games \n                Electronic Toys \n                 Electronic Pets \nPryce679 \nDicos66"
     }
    ]
   },
  {
    "id": 19842,
    "type": "message",
    "date": "2023-05-01",
    "date_unixtime": "1682898904",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1900",
    "dicos": "60",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀Amazon Basics 500W Juicer Mixer Grinder with 2 Jars, White and Blue   ✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lcuzis"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41SziNs"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vz9sfn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41ItbeF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ALOAaM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AHyvmM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ADHN38"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40WZOnv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40S7xDm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44eucgc"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1900"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71JC0Vk75IL._SX466_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Home Appliances \n                Small Kitchen Appliances \nPryce1900 \nDicos60 \n\n❌Regular Price  : ₹ 2,999   /- 😱"
     }
    ]
   },
   {
    "id": 19843,
    "type": "message",
    "date": "2023-05-01",
    "date_unixtime": "1682899005",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "2999",
    "dicos": "40",
    "catagory": "Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀ZEBRONICS Zeb-V16HD LED Monitor with15.4 with Supporting HDMI, has VGA Input, HD 1280 x 800, Glossy Panel, Slim Feature and Wall mountable.   ✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LHJRNG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40PFAMz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HqSNo8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nmnNif"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nhqNg0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AIYuu6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oZT9f1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nftq1I"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40Ti55o"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nkWdC7"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@2999"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/6103DnamOXL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Computers & Accessories \n                2021 \n                 Gaming Monitors \nPryce2999 \nDicos40 \n\n❌Regular Price  : ₹  4,999  /- 😱"
     }
    ]
   },
   {
    "id": 19844,
    "type": "message",
    "date": "2023-05-01",
    "date_unixtime": "1682899114",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "26990",
    "dicos": "64",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "Samsung Galaxy S20 FE 5G 8GB RAM, 128GB @ ₹24,990🔗 \n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nkkmJ5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vl8AdR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44dGqWr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41QQwee"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HmDA7t"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/429Q8XR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hp16Ri"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VlPdSf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HrlgKE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LoiiaK"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@26990"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/81vDZyJQ-4L._SY606_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Mobiles & Accessories \n                Smartphones & Basic Mobiles \nPryce26990 \nDicos64 \n❌ Regular price @ ₹42,999😱💡 \nFlat ₹2,000 Off With HDFC"
     }
    ]
   },
   {
    "id": 19845,
    "type": "message",
    "date": "2023-05-01",
    "date_unixtime": "1682899214",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "7693",
    "dicos": "29",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀 Kenstar Eplast 15L Water Heater  ✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LFvFoq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41Itru9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hrlnpy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NqN1GV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NqKoVt"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NqLtwP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NnhBRV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NqN99n"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NueKXg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oPDIWJ"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@7693"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/615bS-IgC6S._SY679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Heating, Cooling & Air Quality \n                Water Heaters & Geysers \nPryce7693 \nDicos29 \n\n❌Regular Price  : ₹ 8,999  /- 😱"
     }
    ]
   },
   {
    "id": 19846,
    "type": "message",
    "date": "2023-05-01",
    "date_unixtime": "1682899315",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "480",
    "dicos": "70",
    "catagory": "Clothing",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀Gleamrush Men's Stylish Regular Fit Lycra Jogger Lower Track Pants for Gym, Running, Athletic, Casual Wear for Men Pack of 2 (GLTP_Track Pant_Combo_All)   ✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/427tPSM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40MmMO9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44iyxPi"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LiEGCI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lcv2kI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41U3lEK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LlH8rX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vjer3n"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LkzTkb"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nf7jbJ"
     },
     {
      "type": "plain",
      "text": "\n\n @480\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71O6K5Tj0rL._UY741_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Clothing"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Clothing & Accessories \n                Men \n                Sportswear \nPryce480 \nDicos70 \n \n❌Regular Price  : ₹ 999   /- 😱"
     }
    ]
   },
   {
    "id": 19847,
    "type": "message",
    "date": "2023-05-01",
    "date_unixtime": "1682899419",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1350",
    "dicos": "60",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀KENT Electric Chopper-R for Kitchen 250 Watt | Chop, Mince, Whisk, 400 ml Capacity   ✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44tfYbB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LmdR09"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40X0FVf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41MBwxU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HrOxon"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/429cmJM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42bQoFT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VrnWxB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oZt6oA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HqDITF"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1350"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/511USbGsIvL._SX466_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Home Appliances \n                Small Kitchen Appliances \nPryce1350 \nDicos60 \n ❌Regular Price  : ₹  1,999  /- 😱"
     }
    ]
   },
   {
    "id": 19848,
    "type": "message",
    "date": "2023-05-01",
    "date_unixtime": "1682899541",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "9999",
    "dicos": "50",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Blaupunkt Germany's SBW08 220W Dolby Soundbar with 8 Inch(20cm) Subwoofer, 2.1 Channel Surround Sound System with HDMI-ARC, Optical, AUX, Bluetooth and Remote Control, Black✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HrlFg8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nhAo6p"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LFBySK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AGis8F"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NpPe5q"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NrDorB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44jPJUW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Nr3o6p"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NnieuL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42dsUA2"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@9999"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/715vlEHoySL._SX450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Home Audio \n                Speakers \nPryce9999 \nDicos50 \n❌Regular Price  : ₹ 14999   /- 😱"
     }
    ]
   },
   {
    "id": 19849,
    "type": "message",
    "date": "2023-05-01",
    "date_unixtime": "1682899664",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1299",
    "dicos": "32",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀  CASADECOR Wooden Viridis Tripod Side Table Balcony Indoor Outdoor Balcony Plant ✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LICOEy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41MBRka"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44gZS4v"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lshli2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oWAvF5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44eGvJr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/449R28N"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44fu5AY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44dPkTM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vh0Zx9"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1299"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61C1Bf5PZmL._SX466_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Outdoor Living \n                Gardening \n                Plant Containers \nPryce1299 \nDicos32 \n\n❌Regular Price  : \n₹1999"
     }
    ]
   },
   {
    "id": 19850,
    "type": "message",
    "date": "2023-05-01",
    "date_unixtime": "1682899785",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "480",
    "dicos": "63",
    "catagory": "Shoes",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀 Carlton London Sports Women's Cl-f-w-02_Beige Slipper ✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LHlik5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Nnik5B"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nc8Gbo"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hr7MP6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hti80W"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40ZD9Y4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40RD74b"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LRSIg9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LshQIW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40R66oT"
     },
     {
      "type": "plain",
      "text": "\n\n @480\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71aQKbnglHS._UY675_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Shoes"
     },
     {
      "type": "plain",
      "text": " & Handbags\n\n                Shoes & Handbags \n                Shoes \n                Women's Shoes \nPryce480 \nDicos63 \n\n❌Regular Price  : \n₹799"
     }
    ]
   },
   {
    "id": 19851,
    "type": "message",
    "date": "2023-05-01",
    "date_unixtime": "1682899909",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "359",
    "dicos": "85",
    "catagory": "Watches",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀 LOUIS DEVIN WT005 Stainless Steel Chain Analog Wrist  ✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44f4vvQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LkkJvg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hs1DSR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42dmm4w"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VlowNb"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AJQYix"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41Pzxck"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NqOmNX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41Ptpkf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HsQhxE"
     },
     {
      "type": "plain",
      "text": "\n\n @359\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/81u5T0xRdML._UX466_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Watches"
     },
     {
      "type": "plain",
      "text": "\n\n                Fashion \n                Onam 2021 \n                Watches and fashion jewellery \nPryce359 \nDicos85 \n\n❌Regular Price  : \n₹699"
     }
    ]
   },
   {
    "id": 19852,
    "type": "message",
    "date": "2023-05-01",
    "date_unixtime": "1682900035",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "649",
    "dicos": "50",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   USHA EI 1602 1000 W Lightweight Dry Iron with Non-Stick Soleplate (Multi-colour)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44iwUkC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oNaY0O"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41RpJhW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LkATVt"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44aUxff"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44dELAa"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lfa6K2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lnmzvm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VlFCut"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AHHDru"
     },
     {
      "type": "plain",
      "text": "\n\n @649\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61S+GSuRHkL._SX522_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Home Appliances \n                Vacuum, Cleaning & Ironing \nPryce649 \nDicos50 \n\n❌Regular Price  : ₹  1099  /- 😱"
     }
    ]
   },
   {
    "id": 19853,
    "type": "message",
    "date": "2023-05-01",
    "date_unixtime": "1682900160",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1032",
    "dicos": "70",
    "catagory": "Watches",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   FCUK Analog Black Dial Men's Watch-FK00014C✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nhsz0E"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nc9fC2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AHrtOP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40PyvvC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oYm434"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LFyNkk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LIDHNo"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44gJ79t"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44fuYti"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40Tk5KW"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1032"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/618Dig9hkWL._UY741_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Watches"
     },
     {
      "type": "plain",
      "text": "\n\n                Fashion \n                Onam 2021 \n                Watches and fashion jewellery \nPryce1032 \nDicos70 \n\n❌Regular Price  : ₹ 2499   /- 😱"
     }
    ]
   },
   {
    "id": 19854,
    "type": "message",
    "date": "2023-05-01",
    "date_unixtime": "1682900282",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "2355",
    "dicos": "NaN",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Larah by Borosil Red Lily Opalware Dinner Set, 18 Pieces, White✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LmLFu3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LIDXMm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42dukum"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44dQw9I"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44ibaWk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VlRmgL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oUAD81"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NnjOgb"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oNbOdY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oXrDyX"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@2355"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/41XXq1U3KyL._SX300_SY300_QL70_FMwebp_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Dining \n                Tableware \nPryce2355 \nDicosNaN \n\n❌Regular Price  : ₹  1999    /- 😱"
     }
    ]
   },
   {
    "id": 19855,
    "type": "message",
    "date": "2023-05-01",
    "date_unixtime": "1682900401",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "259",
    "dicos": "74",
    "catagory": "Clothing",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Campus Sutra Men's Round Neck Tshirt ✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41SseAt"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42dpm14"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://www.amazon.in/Campus-Sutra-Round-Tshirt-AZ17PRM_RNFSCOT_M_PLN_BLWH_AZ_S/dp/B074CZJTH8?crid=14XHD0J162HDG&amp;keywords=men+clothing&amp;m=A1WYWER0W24N8S&amp;qid=1682832054&amp;refinements=p_85%3A10440599031%2Cp_n_pct-off-with-tax%3A27060457031%2Cp_6%3AA1WYWER0W24N8S&amp;rnid=1318474031&amp;rps=1&amp;s=apparel&amp;sprefix=men+clothin%2Caps%2C297&amp;sr=1-1162&amp;linkCode=sl1&amp;tag=deals103-21&amp;linkId=4823fcd7fdfbe4fc9efdcd97532a17c7&amp;language=en_IN&amp;ref_=as_li_ss_tl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oXHBsY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LGSyb3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40Un5Xn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44j4UNS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40W9rmy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41MDFts"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NqNXv9"
     },
     {
      "type": "plain",
      "text": "\n\n @259\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71ytAI+P3bL._UX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Clothing"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Clothing & Accessories \n                Men \n                T-Shirts & Polos \nPryce259 \nDicos74 \n\n❌Regular Price  : ₹   899   /- 😱"
     }
    ]
   },
   {
    "id": 19856,
    "type": "message",
    "date": "2023-05-01",
    "date_unixtime": "1682900523",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1459",
    "dicos": "51",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Candes Florence 1200mm/48 inch High Speed Anti-dust Decorative 5 Star Rated Ceiling Fan(100% CNC Winding) 405 RPM (2 Yrs Warranty) (Silver Blue, Pack of 1)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Htqkhv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40X2CB3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Npm7PK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44ewCLO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3njO3K8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LnAEZJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HpRKVw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oUxuVE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AFkZQj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VjFmMA"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1459"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51EXKeCrdKL._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Heating, Cooling & Air Quality \n                Fans \nPryce1459 \nDicos51 \n\n❌Regular Price  : ₹  2499    /- 😱"
     }
    ]
   },
   {
    "id": 19857,
    "type": "message",
    "date": "2023-05-01",
    "date_unixtime": "1682900644",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "156",
    "dicos": "74",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   wipro Safelite N10004 B22 0.5-Watt Led Night Lamp (Pack Of 6, Blue)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VlS91d"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NuhwMa"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LGYZLd"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LHSuYD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LHSyYs"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42dvwxQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oYmW7Q"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vjl4mk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41Wmd5R"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44k5omT"
     },
     {
      "type": "plain",
      "text": "\n\n @156\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71Vu7GH+V4L._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Indoor Lighting \n                Specialty Lighting \nPryce156 \nDicos74 \n\n❌Regular Price  : ₹  549   /- 😱"
     }
    ]
   },
   {
    "id": 19858,
    "type": "message",
    "date": "2023-05-01",
    "date_unixtime": "1682900770",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "8999",
    "dicos": "36",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Redmi 12C (Royal Blue, 4GB RAM, 64GB Storage) | High Performance Mediatek Helio G85 | Big 17cm(6.71) HD+ Display with 5000mAh(typ) Battery with 10W Charger in-Box✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vh2TxN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LGTfkF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LIqJiQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44e9zRf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LsjCK6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oU29T1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HmPbDN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40VGffo"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40LYiot"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LHN5Ri"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@8999"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/81itQPVn-GL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Mobiles & Accessories \n                Smartphones & Basic Mobiles \nPryce8999 \nDicos36 \n\n❌Regular Price  : ₹  12999   /- 😱"
     }
    ]
   },
   {
    "id": 19859,
    "type": "message",
    "date": "2023-05-01",
    "date_unixtime": "1682900869",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "438",
    "dicos": "78",
    "catagory": "Beauty",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   XX Revolution XX CompleXXion Palette Intrinsic✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/4174S9j"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LcyCvm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AHJkVS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44k5MBR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NkUZS2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nelVbv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LHTeNu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40S42wV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LhUsO5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nkZZvh"
     },
     {
      "type": "plain",
      "text": "\n\n @438\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/41RXLf7bY+L._SY300_SX300_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Beauty"
     },
     {
      "type": "plain",
      "text": "\n\n                Beauty \n                Make-up \n                 Make-up Palettes \nPryce438 \nDicos78 \n\n❌Regular Price  : ₹  1299  /- 😱"
     }
    ]
   },
   {
    "id": 19860,
    "type": "message",
    "date": "2023-05-01",
    "date_unixtime": "1682900967",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "665",
    "dicos": "58",
    "catagory": "Clothing",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Indigo Women's Acrylic Regular Kurta✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42aHB7b"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/426FFx3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3n8EXjC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HqGvw7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42tFXxN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VrqKe7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NqQyFb"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42d9StE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LHS4BB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LHpG2B"
     },
     {
      "type": "plain",
      "text": "\n\n @665\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/91ObIuVh9OL._UX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Clothing"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Clothing & Accessories \n                Women \n                Ethnic Wear \nPryce665 \nDicos58 \n\n❌Regular Price  : ₹   1199  /- 😱"
     }
    ]
   },
   {
    "id": 19861,
    "type": "message",
    "date": "2023-05-01",
    "date_unixtime": "1682901065",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "199",
    "dicos": "75",
    "catagory": "Clothing",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   ONLY Women's Regular fit T-Shirt✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NrUVQy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VlBomH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VgF8pz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40T60x5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VlBACr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40T3I11"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/429TuKr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LHsXyM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44dK0Qn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/425XhbI"
     },
     {
      "type": "plain",
      "text": "\n\n @199\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/81N6+2AqS0L._UX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Clothing"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Clothing & Accessories \n                Women \n                Western Wear \nPryce199 \nDicos75 \n\n❌Regular Price  : ₹  699  /- 😱"
     }
    ]
   },
   {
    "id": 19862,
    "type": "message",
    "date": "2023-05-01",
    "date_unixtime": "1682901186",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "2711",
    "dicos": "41",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Pigeon by Stovekraft Ruby Woo Mixer Grinder with 3 jars and 1 Juicer Jar, 750 Watts 4 Jar, Shiny Red✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41SCRmO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44i10Fe"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LHTFr6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hrv4nI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nhv17m"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hp5tvG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HroTQM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NwfwDn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41Rsyzy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HrlEsK"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@2711"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/512U1tMF6FL._SX466_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Home Appliances \n                Small Kitchen Appliances \nPryce2711 \nDicos41 \n\n❌Regular Price  : ₹  3999   /- 😱"
     }
    ]
   },
   {
    "id": 19863,
    "type": "message",
    "date": "2023-05-01",
    "date_unixtime": "1682901308",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "999",
    "dicos": "60",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   KENT 16105 Crisp Pop Up Toaster 700Watts | 2 Slice Automatic Pop up electric Toaster | 6 Heating Modes | Auto Shut off | Removable bread crumbs tray | White✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44dKyFV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oXJ9mM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AHgxRa"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vjmsp2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oXsekl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40R4paU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oKPtOb"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3negdq7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HqHkVJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HmHv4b"
     },
     {
      "type": "plain",
      "text": "\n\n @999\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/317sL7bpeRL._SX300_SY300_QL70_FMwebp_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Home Appliances \n                Small Kitchen Appliances \nPryce999 \nDicos60 \n\n❌Regular Price  : ₹   1999    /- 😱"
     }
    ]
   },
   {
    "id": 19864,
    "type": "message",
    "date": "2023-05-01",
    "date_unixtime": "1682901428",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "266",
    "dicos": "51",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Signoraware Modular Steel Container 2.5ltr, Set of 1, Blue✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LIrNDm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oQt8Pj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40Lr2gY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40PB0y0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://www.amazon.in/dp/B09YNTWLRG?smid=AXOGFIT0PZZ7G&amp;th=1&amp;linkCode=sl1&amp;tag=avi1deals5-21&amp;linkId=92abfa286ee1fa9a5ee9591c29dd1069&amp;language=en_IN&amp;ref_=as_li_ss_tl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p19G2g"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LczVdK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44dSYNs"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44dT3AK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Nn37kP"
     },
     {
      "type": "plain",
      "text": "\n\n @266\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51Klc1EBBKL._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Dining \n                Kitchen Storage & Containers \nPryce266 \nDicos51 \n\n❌Regular Price  : ₹  549   /- 😱"
     }
    ]
   },
   {
    "id": 19865,
    "type": "message",
    "date": "2023-05-01",
    "date_unixtime": "1682901550",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1359",
    "dicos": "32",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Pigeon Modern Cucina Hand Mixer with Chrome Beater and Dough Hook Stainless Steel Attachments 7 Speed Setting, Beater for Cake Egg Bakery (300 Watts)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oYoBKC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40QDHzg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LiJHLy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LHp1y5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HqKjNL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hrw5My"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LHUiAT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ALU4Co"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nkmK2A"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HsEcc1"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1359"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51J1tofUKFL._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Home Appliances \n                Small Kitchen Appliances \nPryce1359 \nDicos32 \n\n❌Regular Price  : ₹  1799  /- 😱"
     }
    ]
   },
   {
    "id": 19866,
    "type": "message",
    "date": "2023-05-01",
    "date_unixtime": "1682901671",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1399",
    "dicos": "72",
    "catagory": "Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Hammer Pulse Ace Pro Bluetooth Calling Smartwatch with SpO2 & 1.81” Aluminium Alloy Body with Blood Oxygen Monitoring, Heart Rate, Full Touch & Multiple Watch Faces with Customization (Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NkWwrg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41O3Hwo"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oQnP2p"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VhZVsK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HqCLeb"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ni3zGw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41RO6Mj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vi05jQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44aXRHf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HqI5Oz"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1399"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/7120P0d1zgL._SY450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Electronics \n                Wearable Technology \n                 Smart Watches \nPryce1399 \nDicos72 \n\n❌Regular Price  : ₹   2999  /- 😱"
     }
    ]
   },
   {
    "id": 19867,
    "type": "message",
    "date": "2023-05-01",
    "date_unixtime": "1682901791",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "199",
    "dicos": "80",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Cotton Villas 3D Printed Microfiber Pink Mickey Bedsheet for Double Bed with 2 Pillow Cover Microfiber and Cotton Mix Color Pink (88 X 88 inch )✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AIJNXI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Nn42Sj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oXt7JH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LHUQ9V"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3neHCIk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LFaDW3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oXunwf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3neojit"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AHE2ty"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AFnpOT"
     },
     {
      "type": "plain",
      "text": "\n\n @199\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71mE3zXahgL._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Home Furnishing \n                Bedding & Linen \nPryce199 \nDicos80 \n\n❌Regular Price  : ₹ 899   /- 😱"
     }
    ]
   },
   {
    "id": 19868,
    "type": "message",
    "date": "2023-05-01",
    "date_unixtime": "1682901912",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "136",
    "dicos": "73",
    "catagory": "Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   GIZGA Essentials Portable Tabletop Tablet Stand Mobile Holder, Desktop Stand, Cradle, Dock for iPad, Smartphone, Kindle, E-Reader, Fully Foldable, Adjustable Angle, Anti-Slip Pads, Black✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LIti4s"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HqXtKI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NkX54m"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oZYkLZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/425K4j2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oKR5rd"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NqPG3y"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41UdJMA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oQoD7r"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HqXMVS"
     },
     {
      "type": "plain",
      "text": "\n\n @136\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61zODtv7sfL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Computers & Accessories \n                Accessories & Peripherals \n                Tablet Accessories \nPryce136 \nDicos73 \n\n❌Regular Price  : ₹  499   /- 😱"
     }
    ]
   },
   {
    "id": 19869,
    "type": "message",
    "date": "2023-05-01",
    "date_unixtime": "1682902012",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "259",
    "dicos": "74",
    "catagory": "Clothing",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   TLF Men's Printed Premium Cotton Regular Shirt Ananya-L_708 Blue M✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AHvtPl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42aKogF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VgjYYJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VjnUru"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VlK2l3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oUQXpk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44rSIdY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VgH7u1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HpUJgG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44rSOSS"
     },
     {
      "type": "plain",
      "text": "\n\n @259\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/81FVarZp21L._UX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Clothing"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Clothing & Accessories \n                Men \n                Shirts \nPryce259 \nDicos74 \n\n❌Regular Price  : ₹   899  /- 😱"
     }
    ]
   },
   {
    "id": 19870,
    "type": "message",
    "date": "2023-05-01",
    "date_unixtime": "1682902134",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "137",
    "dicos": "64",
    "catagory": "Baby",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Newnik Waterproof Dry Sheet/Reusable MAT/UNDERPAD/Absorbent Sheets/Mattress Protector Firoza, Medium✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3neoxGl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VlKakx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40NTHlv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42dyfr4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HrxsLc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LkdBPs"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/426HZnL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oKRytt"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oYpQtg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42eDDKT"
     },
     {
      "type": "plain",
      "text": "\n\n @137\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/81eodkZtQYL._SY450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Baby"
     },
     {
      "type": "plain",
      "text": "\n\n                Baby \n                Bedding, Furniture & Room Décor \n                Bedding \nPryce137 \nDicos64 \n\n❌Regular Price  : ₹  384   /- 😱"
     }
    ]
   },
   {
    "id": 19871,
    "type": "message",
    "date": "2023-05-01",
    "date_unixtime": "1682902230",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1247",
    "dicos": "50",
    "catagory": "Clothing",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   SERA Women's Cotton Kurta✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LHrPLH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44cZhAN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oTR0BG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LiKWuc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44gNK3l"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40Wd4Jc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oW2ZyI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LkdSls"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40LsqjG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LIEw95"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1247"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/81f+VDhw99L._UX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Clothing"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Fashion \n                Apparel Steal Deals \n                Apparel_1 \nPryce1247 \nDicos50 \n\n❌Regular Price  : ₹  1999   /- 😱"
     }
    ]
   },
   {
    "id": 19872,
    "type": "message",
    "date": "2023-05-01",
    "date_unixtime": "1682902332",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "419",
    "dicos": "73",
    "catagory": "Clothing",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   612 League Girl's Regular Jeans✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AHpauZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LF5wpU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40VJi7k"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42fJlMx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40TKhF0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/426Io9L"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oU5GRh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oQpbdv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VzfuN3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NyUCmK"
     },
     {
      "type": "plain",
      "text": "\n\n @419\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/91PgvDxAr8L._UY741_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Clothing"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Clothing & Accessories \n                Girls \n                 Jeans \nPryce419 \nDicos73 \n\n❌Regular Price  : ₹  1199   /- 😱"
     }
    ]
   },
   {
    "id": 19873,
    "type": "message",
    "date": "2023-05-01",
    "date_unixtime": "1682902452",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1895",
    "dicos": "NaN",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Larah by Borosil Green Herbs Opalware Dinner Set, 13 Pieces, White✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VigoNu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LHDrhv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VjJSuw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NqQwNK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NsfwUK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nhi3q9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42cK3du"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LiLmkg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/429zurj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42dz7Mm"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1895"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51RViYlNRcL._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Dining \n                Tableware \nPryce1895 \nDicosNaN \n\n❌Regular Price  : ₹  1699   /- 😱"
     }
    ]
   },
   {
    "id": 19874,
    "type": "message",
    "date": "2023-05-01",
    "date_unixtime": "1682902573",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "4152",
    "dicos": "58",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Lifelong LLHFD421 HealthyFry Pro 4.2L Digital Air Fryer with Curated 7-Preset Menu, Touch Control & Digital Display| Variable Temperature and Timer Control (1 Year Warranty, Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41Wq3Mj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LnEzFV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LjHMpV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nplScK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LjHPSD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LF60MK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40MsH5P"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AGqu1f"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lj6Mxu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AFoLJt"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@4152"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51ognUm9klL._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Industrial & Scientific \n                Food Service Equipment & Supplies \n                 Cooking Equipment \nPryce4152 \nDicos58 \n\n❌Regular Price  : ₹  8999   /- 😱"
     }
    ]
   },
   {
    "id": 19875,
    "type": "message",
    "date": "2023-05-01",
    "date_unixtime": "1682902676",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "239",
    "dicos": "66",
    "catagory": "Video",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Ant Value Fkbri01 Wired Usb Multi-Device Keyboardâ  (Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hvs8qj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AJ6n2o"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/428erWf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/429zVSt"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40Qk5LM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/4293kMP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oU6bL9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/423D4Uc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41RyGHQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41O6lCk"
     },
     {
      "type": "plain",
      "text": "\n\n @239\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61Q-zPvwzEL._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Video"
     },
     {
      "type": "plain",
      "text": " Games\n\n                Computers & Accessories \n                Accessories & Peripherals \n                Keyboards, Mice & Input Devices \nPryce239 \nDicos66 \n\n❌Regular Price  : ₹  699   /- 😱"
     }
    ]
   },
   {
    "id": 19876,
    "type": "message",
    "date": "2023-05-01",
    "date_unixtime": "1682902800",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1102",
    "dicos": "72",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   boAt Rockerz 450 Wonder Woman Edition Bluetooth On Ear Headphones with Mic, Upto 15 Hours Playback, 40MM Drivers, Padded Ear Cushions, Integrated Controls and Dual Modes(Amazonian Red)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VjksNv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AFoXsb"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NqTth7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AJ5EhY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AEksOP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Htmc13"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HrebtC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nhzIhu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VgWgLP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LHsEEh"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1102"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61mD5YyWSrL._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Headphones, Earbuds & Accessories \n                Headphones \nPryce1102 \nDicos72 \n\n❌Regular Price  : ₹  1999   /- 😱"
     }
    ]
   },
   {
    "id": 19877,
    "type": "message",
    "date": "2023-05-01",
    "date_unixtime": "1682902923",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "229",
    "dicos": "43",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Amazon Brand - Solimo Set of 6 Plastic Glasses, 250 ml Each, Transparent✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hrymay"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40NUYch"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oPJJCN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42cKDIc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42ddgVo"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LFbyH5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nepVJ3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3njS2q4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44j8Y0A"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41XOg56"
     },
     {
      "type": "plain",
      "text": "\n\n @229\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71Fcwgom1kS._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Dining \n                Tableware \nPryce229 \nDicos43 \n\n❌Regular Price  : ₹   399  /- 😱"
     }
    ]
   },
   {
    "id": 19878,
    "type": "message",
    "date": "2023-05-01",
    "date_unixtime": "1682903045",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "210",
    "dicos": "50",
    "catagory": "Grocery",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Hershey's Kisses Milk Choclates Almond, 100g (Pack of 3)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41PFuGc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ADPark"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/429X9YH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41Pbgmp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40TLqfM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41XOwkA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HqKp8f"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NrofXs"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oUGeLz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nkpi0E"
     },
     {
      "type": "plain",
      "text": "\n\n @210\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51wyVsgC9jL._SX569_PIbundle-3,TopRight,0,0_AA569SH20_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Grocery"
     },
     {
      "type": "plain",
      "text": " & Gourmet Foods\n\n                Grocery & Gourmet Foods \n                Snacks & Sweets \n                Chocolate \nPryce210 \nDicos50 \n\n❌Regular Price  : ₹ 420   /- 😱"
     }
    ]
   },
   {
    "id": 19879,
    "type": "message",
    "date": "2023-05-01",
    "date_unixtime": "1682903146",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "455",
    "dicos": "70",
    "catagory": "Clothing",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Amazon Brand - Symbol Women Shirt✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LHtgK5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oU5Qs3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p1cT1O"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40X7y93"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44dL5ro"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HqFK6n"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40Set3i"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42dj415"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LnrKvi"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LsoMFY"
     },
     {
      "type": "plain",
      "text": "\n\n @455\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/81N3zswbRDL._UX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Clothing"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Clothing & Accessories \n                Women \n                Western Wear \nPryce455 \nDicos70 \n\n❌Regular Price  : ₹   1299   /- 😱"
     }
    ]
   },
   {
    "id": 19880,
    "type": "message",
    "date": "2023-05-01",
    "date_unixtime": "1682903244",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1815",
    "dicos": "27",
    "catagory": "Shoes",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Footin Womens Alfa Running Ballet Flat✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LKjneJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42aMVYd"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oMd9li"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/428aC3k"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42ahQ6T"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41RvFaI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LHVLHt"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Nr9rrD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NwjMTn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LJn9EQ"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1815"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71lnaiaQkhS._UX500_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Shoes"
     },
     {
      "type": "plain",
      "text": " & Handbags\n\n                Shoes & Handbags \n                Shoes \n                Women's Shoes \nPryce1815 \nDicos27 \n\n❌Regular Price  : ₹  2499  /- 😱"
     }
    ]
   },
   {
    "id": 19881,
    "type": "message",
    "date": "2023-05-01",
    "date_unixtime": "1682903365",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "469",
    "dicos": "82",
    "catagory": "Clothing",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   ANNI DESIGNER Women's Cotton Blend Printed Straight Kurta with Pant (KIDYA)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oXwPCX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40R7BTW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44dLha6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NISpph"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44b0FEh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42dAniy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44iheOA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42bXGcJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AFkq9d"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oYNztm"
     },
     {
      "type": "plain",
      "text": "\n\n @469\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61ScAWcHFrL._UY741_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Clothing"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Clothing & Accessories \n                Women \n                Ethnic Wear \nPryce469 \nDicos82 \n\n❌Regular Price  : ₹ 1599    /- 😱"
     }
    ]
   },
   {
    "id": 19882,
    "type": "message",
    "date": "2023-05-01",
    "date_unixtime": "1682903487",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "4499",
    "dicos": "79",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Fire-Boltt Invincible Plus 1.43\" AMOLED Display Smartwatch with Bluetooth Calling, TWS Connection, 300+ Sports Modes, 110 in-Built Watch Faces, 4GB Storage & AI Voice Assistant (Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/429Bjo9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LH3JAv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ANhyqN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VlvaD7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HpqI0B"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40T7Wpp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oZBcxu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42tKro7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HpqW7X"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41Sy6K1"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@4499"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71dxFO2yAwL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Wearable Technology \n                 Smart Watches \nPryce4499 \nDicos79 \n\n❌Regular Price  : ₹  12499  /- 😱"
     }
    ]
   },
   {
    "id": 19883,
    "type": "message",
    "date": "2023-05-01",
    "date_unixtime": "1682903590",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "999",
    "dicos": "67",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   boAt Rockerz 400 Bluetooth On Ear Headphones with Mic with Upto 8 Hours Playback & Soft Padded Ear Cushions(Black/Red)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HrsX3u"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LFEQW4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HmTWx9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44ef8iB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VlgwMb"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VlgHXR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NISDwD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lipm8V"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HqLj4D"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HnKidy"
     },
     {
      "type": "plain",
      "text": "\n\n @999\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61US9yCTRJL._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Headphones, Earbuds & Accessories \n                Headphones \nPryce999 \nDicos67 \n\n❌Regular Price  : ₹   1999  /- 😱"
     }
    ]
   },
   {
    "id": 19884,
    "type": "message",
    "date": "2023-05-01",
    "date_unixtime": "1682903690",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "136",
    "dicos": "66",
    "catagory": "Beauty",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   PureSense Pink Guava Face Wash with Pomegranate & Thanka for Gentle Cleansing & Imparts Glow | Suitable for Men & Women | 100g✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40Mu6t7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LFE23k"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42djG6T"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/428slrm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LHnIiu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LFJ3cm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NrpqpQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44iGXGo"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/428fUvJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44ihwFa"
     },
     {
      "type": "plain",
      "text": "\n\n @136\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51Ju0tGqZDL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Beauty"
     },
     {
      "type": "plain",
      "text": "\n\n                Beauty \n                Skin Care \n                Face \nPryce136 \nDicos66 \n\n❌Regular Price  : ₹  400  /- 😱"
     }
    ]
   },
   {
    "id": 19885,
    "type": "message",
    "date": "2023-05-01",
    "date_unixtime": "1682903789",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "746",
    "dicos": "25",
    "catagory": "Shoes",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Kidsville Generic Multicolor Shoes for Boys✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41PGMB2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NqKhJz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41Q591v"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p01fUX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/429Yuib"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oTTrEk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41QGrhr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oKVgmT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oUTL5Q"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lks8uy"
     },
     {
      "type": "plain",
      "text": "\n\n @746\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/81olymgjorL._UY500_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Shoes"
     },
     {
      "type": "plain",
      "text": " & Handbags\n\n                Shoes & Handbags \n                Shoes \n                Boys' Shoes \nPryce746 \nDicos25 \n\n❌Regular Price  : ₹  899   /- 😱"
     }
    ]
   },
   {
    "id": 19886,
    "type": "message",
    "date": "2023-05-01",
    "date_unixtime": "1682903888",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "221",
    "dicos": "63",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Wipro Safelite N10006 B22 0.5-Watt Led Night Lamp (Pack of 6, Blue)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oSzr50"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lj8Vcr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41UbJ7c"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LgXdzf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nmwzwH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44h7fsH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AHXWEG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AFls55"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LHWEzN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Ht743Q"
     },
     {
      "type": "plain",
      "text": "\n\n @221\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61zBuZbroHL._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Indoor Lighting \n                Specialty Lighting \nPryce221 \nDicos63 \n\n❌Regular Price  : ₹  600    /- 😱"
     }
    ]
   },
   {
    "id": 19887,
    "type": "message",
    "date": "2023-05-01",
    "date_unixtime": "1682903990",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "899",
    "dicos": "70",
    "catagory": "Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Amazon Basics 2-tier Wooden Monitor Riser Stand with Storage Organizer (Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oYOhqw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/428bViK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41RRF59"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vjp06y"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AE2g7Q"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ALYeu0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41UbU2m"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41PBFkf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vlu6PQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oYtaoe"
     },
     {
      "type": "plain",
      "text": "\n\n @899\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61LCY1MHS-L._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Computers & Accessories \n                2021 \n                 Gaming Monitors \nPryce899 \nDicos70 \n\n❌Regular Price  : ₹   1999  /- 😱"
     }
    ]
   },
   {
    "id": 19888,
    "type": "message",
    "date": "2023-05-01",
    "date_unixtime": "1682904090",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "368",
    "dicos": "51",
    "catagory": "Toys",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Handycrafts - Fantastic Jewel Designer ,Custom Jewellery Making Kit , Make your own designer jewellery , 5 years + , DIY jewellery✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40TqJkm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44dLZ7g"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oUHgHr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44i6kZe"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nad76B"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/448hqjc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LcEFzS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LoOf2O"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VlYQjR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lj94wA"
     },
     {
      "type": "plain",
      "text": "\n\n @368\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61RKEYY4LEL._SY741_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Toys"
     },
     {
      "type": "plain",
      "text": " & Games\n\n                Toys & Games \n                Dress Up & Pretend Play \n                 Makeup Bags & Cases \nPryce368 \nDicos51 \n\n❌Regular Price  : ₹ 749    /- 😱"
     }
    ]
   },
   {
    "id": 19889,
    "type": "message",
    "date": "2023-05-01",
    "date_unixtime": "1682904191",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1179",
    "dicos": "76",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Philips Audio Tws Tat1215 Bluetooth Truly Wireless In Ear Earbuds With Mic With 18 Hr Playtime - 6+12, Ipx4, Voice Assistant (Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42dfpAq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nhBYFu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41SzhsV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41OJaIj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p1eG72"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NlU83v"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vjr7r2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nftGxM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AD6GMp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40LV1p6"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1179"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/41JjWA1nbPL._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Headphones, Earbuds & Accessories \n                Headphones \nPryce1179 \nDicos76 \n\n❌Regular Price  : ₹  3999    /- 😱"
     }
    ]
   },
   {
    "id": 19890,
    "type": "message",
    "date": "2023-05-01",
    "date_unixtime": "1682904299",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "4190",
    "dicos": "58",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Faber Glass Top 4 Burner Gas Stove with Powder Coated Pan Support (Hob Cooktop Daisy 4BB BK),2 Medium & 2 Small,Manual Ignition, Black✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oQy7Q1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LcEViw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LfhNQq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nj1jii"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Ht7GGG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Ht7Iyi"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ANjdwx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40PFXXC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oYtCCW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40Qi4yT"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@4190"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/411AKf1u1jL._SX300_SY300_QL70_FMwebp_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & dining \n                Steal deals \nPryce4190 \nDicos58 \n\n❌Regular Price  : ₹  8999    /- 😱"
     }
    ]
   },
   {
    "id": 19891,
    "type": "message",
    "date": "2023-05-01",
    "date_unixtime": "1682904399",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "60",
    "dicos": "39",
    "catagory": "Beauty",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   NIVEA Body Lotion, Nourishing Body Milk, For Very Dry Skin, 120ml✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VziFnX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41Ucj4S"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VlhFmX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vh5lEs"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VqNmeU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42c1kn2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/429Zraf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hr2Cm0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AHIfNS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oU8Zb9"
     },
     {
      "type": "plain",
      "text": "\n\n @60\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/41YYjKKbruL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Beauty"
     },
     {
      "type": "plain",
      "text": "\n\n                Beauty \n                Bath & Body \n                Body Washes \nPryce60 \nDicos39 \n\n❌Regular Price  : ₹  99   /- 😱"
     }
    ]
   },
   {
    "id": 19892,
    "type": "message",
    "date": "2023-05-01",
    "date_unixtime": "1682904501",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "623",
    "dicos": "27",
    "catagory": "Beauty",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Himalaya Purifying Neem Face Wash 400ML (pack of 2)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LiqJ7z"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NrMGUv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NpYFlm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Nn8e4v"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NpZ4UU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hpb1Gw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NIU6D9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hr2OBK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44hfy7L"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LH5cqv"
     },
     {
      "type": "plain",
      "text": "\n\n @623\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61OtI2NDQ7L._SX569_PIbundle-2,TopRight,0,0_AA569SH20_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Beauty"
     },
     {
      "type": "plain",
      "text": "\n\n                Beauty \n                Skin Care \n                Face \nPryce623 \nDicos27 \n\n❌Regular Price  : ₹ 799   /- 😱"
     }
    ]
   },
   {
    "id": 19893,
    "type": "message",
    "date": "2023-05-01",
    "date_unixtime": "1682904609",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "807",
    "dicos": "27",
    "catagory": "Toys",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Portal Games Neuroshima Hex 3.0 Iron Gang✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LKtfFm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vzjj4R"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42c1wmg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42tMp7Z"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40LVFTy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41PCABd"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LgYczr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41PCGZB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vi5l74"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oW6fKs"
     },
     {
      "type": "plain",
      "text": "\n\n @807\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61F3LX9BP7L._SY450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Toys"
     },
     {
      "type": "plain",
      "text": " & Games\n\n                Toys & Games \n                Games \n                 Board Games \nPryce807 \nDicos27 \n\n❌Regular Price  : ₹ 899   /- 😱"
     }
    ]
   },
   {
    "id": 19894,
    "type": "message",
    "date": "2023-05-01",
    "date_unixtime": "1682904709",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "788",
    "dicos": "61",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Symphony Solaris Quartz 800W Room Heater for Home with ISI Certified 2 Quartz Heating Tubes and Energy Efficient Heating ( Grey & Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nelVIz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3neMjBW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AJZApn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AHsWEF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44fCswo"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HqMTU7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44jbzHS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lsr9sm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LKtqR2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LmTpfB"
     },
     {
      "type": "plain",
      "text": "\n\n @788\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61TdtREyj8L._SX466_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Heating, Cooling & Air Quality \n                Room Heaters \nPryce788 \nDicos61 \n\n❌Regular Price  : ₹ 1599    /- 😱"
     }
    ]
   },
   {
    "id": 19895,
    "type": "message",
    "date": "2023-05-01",
    "date_unixtime": "1682904811",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "15999",
    "dicos": "41",
    "catagory": "Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Samsung Galaxy Watch4 Bluetooth(4.0 cm, Black, Compatible with Android only)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AHZmz0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3neMFZi"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40Qnv14"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oXybOd"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41RBNj0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Li0TRf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oQtpSp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VgLuFr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LIMor0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/4263PHk"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@15999"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/31NyK8A74VL._SY300_SX300_QL70_FMwebp_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Electronics \n                Wearable Technology \n                 Smart Watches \nPryce15999 \nDicos41 \n\n❌Regular Price  : ₹  22999    /- 😱"
     }
    ]
   },
   {
    "id": 19896,
    "type": "message",
    "date": "2023-05-01",
    "date_unixtime": "1682904917",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "304",
    "dicos": "1",
    "catagory": "Health",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Dabur Meswak Complete Oral Care Toothpaste 500gm (2 * 200gm + 1 * 100gm) | Tooth Decay Prevention, Stain Removal, Cavity Protection, Tartar Control, Gum Protection, Plaque Removal, Anti-Bacterial✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41UiVA4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41UiYfe"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oSB4j8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Ht8N9k"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NwmjNn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/4263VyG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oUVd8i"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LHZzsg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41SAfp3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LFeX8P"
     },
     {
      "type": "plain",
      "text": "\n\n @304\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71SOgUzkeeL._SY450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Health"
     },
     {
      "type": "plain",
      "text": " & Personal Care\n\n                Health & Personal Care \n                Oral Care \n                 Toothpastes \nPryce304 \nDicos1 \n\n❌Regular Price  : ₹  308   /- 😱"
     }
    ]
   },
   {
    "id": 19897,
    "type": "message",
    "date": "2023-05-01",
    "date_unixtime": "1682905019",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "337",
    "dicos": "50",
    "catagory": "Beauty",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Nivea Dark Spot Reduction Face Wash, 100ml (Pack of 3)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41RNSoC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/4264oRs"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LiPcda"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40TcLir"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44dYO1k"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LHTUCo"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AGrG4L"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AD80il"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ni9v2g"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oSBi9Y"
     },
     {
      "type": "plain",
      "text": "\n\n @337\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/6144327llvL._SX569_PIbundle-3,TopRight,0,0_AA569SH20_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Beauty"
     },
     {
      "type": "plain",
      "text": "\n\n                Beauty \n                Skin Care \n                Face \nPryce337 \nDicos50 \n\n❌Regular Price  : ₹  675  /- 😱"
     }
    ]
   },
   {
    "id": 19898,
    "type": "message",
    "date": "2023-05-01",
    "date_unixtime": "1682905119",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "3663",
    "dicos": "36",
    "catagory": "Industrial",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Polycab 12W LED Panel Light Scintillate Edge Slim Square Smart Offers Bright Lumination Long Lifespan No Harmful Radiation (Warm White, 3000K, 6 PCS, Cut Out: 5.9 inches)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41R0TPc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AJ8KT8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40Xarqp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40VNEvc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44dRbYP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LIy6H2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oUIX7L"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nhhH2S"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40WhJuG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AZN31j"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@3663"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/41zikURhqoL._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Industrial"
     },
     {
      "type": "plain",
      "text": " & Scientific\n\n                Home & Kitchen \n                Indoor Lighting \n                Light Bulbs \nPryce3663 \nDicos36 \n\n❌Regular Price  : ₹ 4999    /- 😱"
     }
    ]
   },
   {
    "id": 19899,
    "type": "message",
    "date": "2023-05-01",
    "date_unixtime": "1682905219",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "2529",
    "dicos": "53",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Crompton 5 Meter Strip Light Green 300 LEDs (Pack of 6) (Without Driver)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LFg5rZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oUa1E3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LHZXHe"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LFHrPO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42dgWXc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42a0pmT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NqXxxT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nl75Qr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oXfV7z"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HrZ2YP"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@2529"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71IXuBJPWlL._SX466_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Indoor Lighting \n                Specialty Lighting \nPryce2529 \nDicos53 \n\n❌Regular Price  : ₹ 4999    /- 😱"
     }
    ]
   },
   {
    "id": 19900,
    "type": "message",
    "date": "2023-05-01",
    "date_unixtime": "1682905319",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "178",
    "dicos": "74",
    "catagory": "Shoes",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Barbie by toothless Girls Kids Moulds Clog✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41R1i4a"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41XSdqs"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hn2fZW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://www.amazon.in/dp/B09DCYBXDT?th=1&amp;psc=1&amp;linkCode=sl1&amp;tag=avi1deals-21&amp;linkId=2283b69f30e9eebbe272bbe77588c26d&amp;language=en_IN&amp;ref_=as_li_ss_tl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vh0jYx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LFG8jI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VgMtp7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VmM1Ww"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VzkqBz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nfFqQO"
     },
     {
      "type": "plain",
      "text": "\n\n @178\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/81rusWCCLYL._UY575_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Shoes"
     },
     {
      "type": "plain",
      "text": " & Handbags\n\n                Shoes & Handbags \n                Shoes \n                Girls' Shoes \nPryce178 \nDicos74 \n\n❌Regular Price  : ₹550    /- 😱"
     }
    ]
   },
   {
    "id": 19901,
    "type": "message",
    "date": "2023-05-01",
    "date_unixtime": "1682905420",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "299",
    "dicos": "70",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   BSB HOME® All Season Miltipurpose Polar Fleece Blanket Combo| Assorted Multicolour Lightweight Blanket | Set of 2, 60x90 inches, Coffee✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p1gf4U"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40Qjs4z"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44i8Iza"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LosFvc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44dOvue"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Ljb7Rd"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41RCAAu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41Q8OfL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NlVA5X"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41P7h9v"
     },
     {
      "type": "plain",
      "text": "\n\n @299\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/81-6fzeS-cL._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Home Furnishing \n                Bedding & Linen \nPryce299 \nDicos70 \n\n❌Regular Price  : ₹850    /- 😱"
     }
    ]
   },
   {
    "id": 19902,
    "type": "message",
    "date": "2023-05-01",
    "date_unixtime": "1682905520",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1350",
    "dicos": "NaN",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Prestige Thermo-Pot Stainless Steel Coffee & Tea Flask (PSCF 1L)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oWKomb"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44i8Rmc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44dVGCD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40PQH8f"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44dVDqr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LHAhKM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nmyQrJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LmJt5Q"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nhLatn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AE4iou"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1350"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/41anOQyGS4L._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Dining \n                Kitchen Storage & Containers \nPryce1350 \nDicosNaN"
     }
    ]
   },
   {
    "id": 19903,
    "type": "message",
    "date": "2023-05-01",
    "date_unixtime": "1682905641",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "373",
    "dicos": "53",
    "catagory": "Tools",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   AmazonBasics 17-Piece Electronics Repair Screwdriver Set, multicolor✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AFnNgn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42fPOal"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HqO3ir"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/4265nB8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oUaAOb"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42dxZbY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/427F0ec"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VljC2L"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41SyesU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VljEHV"
     },
     {
      "type": "plain",
      "text": "\n\n @373\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/7118n9Fd2CL._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Tools"
     },
     {
      "type": "plain",
      "text": " & Home Improvement\n\n                Home Improvement \n                Power & Hand Tools \n                Hand Tools \nPryce373 \nDicos53 \n\n❌Regular Price  : ₹  750  /- 😱"
     }
    ]
   },
   {
    "id": 19904,
    "type": "message",
    "date": "2023-05-01",
    "date_unixtime": "1682905740",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "791",
    "dicos": "1",
    "catagory": "Sports",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Nivia 5236 Prism 1 Large Bag, Others (Navy-Orange)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41SAz7p"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VgMNnP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VlwW7s"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oQuSYV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41Q9sdb"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44ikg5q"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NuqoRY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vm1nKT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LHYZL5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44ff6a6"
     },
     {
      "type": "plain",
      "text": "\n\n @791\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/81Q8XYlfSTL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Sports"
     },
     {
      "type": "plain",
      "text": ", Fitness & Outdoors\n\n                Bags, Wallets and Luggage \n                Bags & Backpacks \n                Gym Bags \nPryce791 \nDicos1 \n\n❌Regular Price  : ₹   799 /- 😱"
     }
    ]
   },
   {
    "id": 19905,
    "type": "message",
    "date": "2023-05-01",
    "date_unixtime": "1682905863",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "329",
    "dicos": "45",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Clazkit Plastic Pet Storage Jar and Container, Set of 18 (6 pcs x 350ml Each, 6 pcs x 650ml Each, 6 pcs x 1.20ltrs Each), Black | Air Tight | Kitchen Organiser | BPA Free | Stackable✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AZNWqF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42aRl1f"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42c1wm9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40ZNkfe"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40RNGnR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42dwGti"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LIzf1i"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3netJKl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oXA0dR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LJz7i7"
     },
     {
      "type": "plain",
      "text": "\n\n @329\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/91H6z0ZDR5L._SX522_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Dining \n                Kitchen Storage & Containers \nPryce329 \nDicos45 \n\n❌Regular Price  : ₹  599   /- 😱"
     }
    ]
   },
   {
    "id": 19906,
    "type": "message",
    "date": "2023-05-01",
    "date_unixtime": "1682905982",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "499",
    "dicos": "50",
    "catagory": "Clothing",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Murano Gym/Duffle Round Bag Self Embossed for Men and Women Best Selling Sports Bag- Biege✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nenIxh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40LxDbe"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44hhvB7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LFMeAO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nktkGk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hpuhnv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HqKitk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AEKHV4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NmFxVz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3neOpli"
     },
     {
      "type": "plain",
      "text": "\n\n @499\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/81zeW1S9njL._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Clothing"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Bags, Wallets and Luggage \n                Bags & Backpacks \n                Gym Bags \nPryce499 \nDicos50 \n\n❌Regular Price  : ₹ 899  /- 😱"
     }
    ]
   },
   {
    "id": 19907,
    "type": "message",
    "date": "2023-05-01",
    "date_unixtime": "1682906103",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "112",
    "dicos": "50",
    "catagory": "Beauty",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Nivea Soft Berry Blossom, Light Moisturizer For Face, Hand & Body, Instant Hydration Non-Greasy Cream With Vitamin E & Jojoba Oil, 100ml✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AGwuHj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/427FtwY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41SAVuL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oSCBWq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/427FxwI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/423IbUo"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41RUx1V"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VmN0Gc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VjpPMF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HrtAu2"
     },
     {
      "type": "plain",
      "text": "\n\n @112\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51vp7-iymeL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Beauty"
     },
     {
      "type": "plain",
      "text": "\n\n                Beauty \n                Skin Care \n                Face \nPryce112 \nDicos50 \n\n❌Regular Price  : ₹ 225   /- 😱"
     }
    ]
   },
   {
    "id": 19908,
    "type": "message",
    "date": "2023-05-01",
    "date_unixtime": "1682906223",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "60",
    "dicos": "39",
    "catagory": "Beauty",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   NIVEA Body Lotion, Nourishing Body Milk, For Very Dry Skin, 120ml✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VhbC31"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41XTOfW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VgqA9v"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44rZd0m"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3neOzZW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AGwDKR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LIO9EC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ViFhZo"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HpdErS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nbG6a7"
     },
     {
      "type": "plain",
      "text": "\n\n @60\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/41YYjKKbruL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Beauty"
     },
     {
      "type": "plain",
      "text": "\n\n                Beauty \n                Bath & Body \n                Body Washes \nPryce60 \nDicos39 \n\n❌Regular Price  : ₹  99   /- 😱"
     }
    ]
   },
   {
    "id": 19909,
    "type": "message",
    "date": "2023-05-01",
    "date_unixtime": "1682906326",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "12390",
    "dicos": "27",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Samsung 28 L Convection Microwave Oven (CE1041DSB3/TL, Black, SlimFry)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HqOWrh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oNmut4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Ll5GkP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nprNyu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44eRHFX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oNmYzo"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LHBfGU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3njWV2o"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42dz0Rk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lh0yON"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@12390"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/81jv5L8wWAL._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Large Appliances \n                 Microwave Ovens \nPryce12390 \nDicos27 \n\n❌Regular Price  : ₹   14999   /- 😱"
     }
    ]
   },
   {
    "id": 19910,
    "type": "message",
    "date": "2023-05-01",
    "date_unixtime": "1682906448",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "463",
    "dicos": "34",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Pasabahce Sylvana Glass Whisky/Water/Juice Tumbler 315 ml 6 Pcs Set, Transparent✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oXB3dX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44eS0k5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p05ccf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nfH5pw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LIAqhe"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LFgWtN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AFuO0D"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LI2wZN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42c2G0Z"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oKZpar"
     },
     {
      "type": "plain",
      "text": "\n\n @463\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/519cOdcEIbL._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Dining \n                Tableware \nPryce463 \nDicos34 \n\n❌Regular Price  : ₹  700    /- 😱"
     }
    ]
   },
   {
    "id": 19911,
    "type": "message",
    "date": "2023-05-01",
    "date_unixtime": "1682906550",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "337",
    "dicos": "25",
    "catagory": "Car",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   CARMATE Car Steering Cover Finger Grip for Maruti Celerio (Medium Beige)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nibN1m"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oUHgXQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42do71x"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VmnfFC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vm2sCr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HpuYx7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41XUmT2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oXRLd6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VzmuJP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Nml6Ig"
     },
     {
      "type": "plain",
      "text": "\n\n @337\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51m-d68DDfL._SY450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Car"
     },
     {
      "type": "plain",
      "text": " & Motorbike\n\n                Car & Motorbike \n                Car Parts \n                Interior Fittings \nPryce337 \nDicos25 \n\n❌Regular Price  : ₹   449  /- 😱"
     }
    ]
   },
   {
    "id": 19912,
    "type": "message",
    "date": "2023-05-01",
    "date_unixtime": "1682906650",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "249",
    "dicos": "75",
    "catagory": "Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Sounce HDMI Cable 4K High-Speed HDMI Cord 18Gbps with Ethernet Support 4K 60Hz Compatible with UHD TV, Monitor, Computer, Xbox 360, PS5 PS4, Blu-ray, and More 1.5 Meter (5FT)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HvxEcv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3naxIaR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nahcrr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oYQAK9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44trdRl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LKoHid"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41UlTEI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LflyW2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41SCfxJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41QL6zX"
     },
     {
      "type": "plain",
      "text": "\n\n @249\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61KUaE1R97L._SX466_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Electronics \n                Home Theater, TV & Video \n                Accessories \nPryce249 \nDicos75 \n\n❌Regular Price  : ₹  899   /- 😱"
     }
    ]
   },
   {
    "id": 19913,
    "type": "message",
    "date": "2023-05-01",
    "date_unixtime": "1682906770",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "2999",
    "dicos": "40",
    "catagory": "Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   ZEBRONICS Zeb-V16HD LED Monitor with15.4 with Supporting HDMI, has VGA Input, HD 1280 x 800, Glossy Panel, Slim Feature and Wall mountable.✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ncl8Ia"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41OcK0k"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NqX8LN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NrQO6X"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oXBtkn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44iHZ5c"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/428xrnu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42b8FDb"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42eLqID"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AHCjEv"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@2999"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/6103DnamOXL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Computers & Accessories \n                2021 \n                 Gaming Monitors \nPryce2999 \nDicos40 \n\n❌Regular Price  : ₹  4499   /- 😱"
     }
    ]
   },
   {
    "id": 19914,
    "type": "message",
    "date": "2023-05-01",
    "date_unixtime": "1682906870",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "288",
    "dicos": "41",
    "catagory": "Beauty",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Navratna Ayurvedic Cool Oil |Power of 9 Ayurvedic Herbs |Relieves Headache, Fatigue, Sleeplessness and Tension, 600ml✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LFcyuO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nfHV5E"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VhcNPZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40QquGO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40LzcpC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HmYvrh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hn4ix6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HqKU2f"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VgOLVf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LnJ7fi"
     },
     {
      "type": "plain",
      "text": "\n\n @288\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/7181IEiOyLL._SY450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Beauty"
     },
     {
      "type": "plain",
      "text": "\n\n                Health & Personal Care \n                Health Care \n                Alternative Medicine \nPryce288 \nDicos41"
     }
    ]
   },
   {
    "id": 19915,
    "type": "message",
    "date": "2023-05-01",
    "date_unixtime": "1682910069",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "9990",
    "dicos": "10",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "LG 43 L 4 Star Direct Cool Single Door Mini Refrigerator (GL-M051RSWE, Super White, Fast Ice Making) \n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vh7sbj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40Sim8F"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oZLpdi"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3p0aMvd"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40XhFL3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/417jIg1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/425VzHe"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44eXxHn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oPUoNP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HmVVRR"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@9990"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/518Z-SgMbcL._SX466_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Large Appliances \n                 Refrigerators \nPryce9990 \nDicos10"
     }
    ]
   },
  {
    "id": 19681,
    "type": "message",
    "date": "2023-04-30",
    "date_unixtime": "1682814128",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "2299",
    "dicos": "62",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   EDYELL Bluetooth Earbuds Wireless Earbuds with 40ms Ultra-Low Latency, Gaming Earbuds with ENC 4-Mic Stereo Call, Dual Modes, 10mm Driver, 48H Play Time, IPX7 Waterproof, Breathing LED✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/426CLI0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Np8j7P"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44q9Cts"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44gRTEq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40RCIim"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/449btTn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LG7QNl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LHTWKO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NpmGcl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Ll4hur"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@2299"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61m0aNaj+KL._SX522_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Headphones, Earbuds & Accessories \n                Headphones \nPryce2299 \nDicos62 \n\n❌Regular Price  : ₹  3999    /- 😱"
     }
    ]
   },
   {
    "id": 19682,
    "type": "message",
    "date": "2023-04-30",
    "date_unixtime": "1682814236",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1999",
    "dicos": "67",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   EDYELL C5 True Wireless Earbuds, 120Hours Playtime Bluetooth Headphones with 3500Mah Charging Case, IPX7 Waterproof Earbuds, Bluetooth WIreless with Mic, Touch Control, Mirco USB Fast Charging, HD Calling✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NkQ3wx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ADCkJp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nfUSwl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42bvMgP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AXXm61"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LDjFE2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AEG4dL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VgvOlm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41PIKkQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LDQCA6"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1999"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71I468hI3eL._SX522_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Headphones, Earbuds & Accessories \n                Headphones \nPryce1999 \nDicos67 \n\n❌Regular Price  : ₹ 4999    /- 😱"
     }
    ]
   },
   {
    "id": 19683,
    "type": "message",
    "date": "2023-04-30",
    "date_unixtime": "1682814366",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "6218",
    "dicos": "82",
    "catagory": "Sports",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Spanco 8 in 1 Double Support Multipurpose Weight Lifting Bench(Incline/Decline/Flat/Leg Exerciser/Lats Excerciser/Dips Stand/Push Up Stand)- 85 kg Holding Capacity for Full Body Workout✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oVZY1q"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VhAMhL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41Npvbn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41KVIQK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Nk5aWZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VfgRQI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ADCyAf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AFUsCI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AXXtOZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3niDK9q"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@6218"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51M8FmpklML._SX450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Sports"
     },
     {
      "type": "plain",
      "text": ", Fitness & Outdoors\n\n                Sports, Fitness & Outdoors \n                Exercise & Fitness \n                Strength Training Equipment \nPryce6218 \nDicos82 \n\n❌Regular Price  : ₹  18999   /- 😱"
     }
    ]
   },
   {
    "id": 19684,
    "type": "message",
    "date": "2023-04-30",
    "date_unixtime": "1682814497",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "803",
    "dicos": "43",
    "catagory": "Shoes",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀  U.S. POLO ASSN. mens Zedo FLIP FLOP ✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VfhfyE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hl7CJ1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nhe7FU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40NTZJi"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hl0IDl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3niFeQX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lkvg9v"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lh1fYs"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LG6fHe"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oKqZog"
     },
     {
      "type": "plain",
      "text": "\n\n @803\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71hybv0v0KL._UX395_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Shoes"
     },
     {
      "type": "plain",
      "text": " & Handbags\n\n                Shoes & Handbags \n                Shoes \n                Men's Shoes \nPryce803 \nDicos43 \n\n❌Regular Price  : ₹  1299  /- 😱"
     }
    ]
   },
   {
    "id": 19685,
    "type": "message",
    "date": "2023-04-30",
    "date_unixtime": "1682814625",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "2985",
    "dicos": "57",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Cello Opalware x Amitabh Bachchan Solitaire Series Blu Dinner Set of 33, White✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LGcmLO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42bOmW6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LkTNLE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41UGdpt"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LQbgxj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Npn6zr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44ips9d"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44c51Lh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/448cN8Z"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3n9QBe3"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@2985"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/81mcLxn++pL._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Dining \n                Tableware \nPryce2985 \nDicos57 \n\n❌Regular Price  : ₹  4999   /- 😱"
     }
    ]
   },
   {
    "id": 19686,
    "type": "message",
    "date": "2023-04-30",
    "date_unixtime": "1682814730",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1498",
    "dicos": "85",
    "catagory": "Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Fire-Boltt Ring 3 Smart Watch 1.8 Biggest Display with Advanced Bluetooth Calling Chip, Voice Assistance,118 Sports Modes, in Built Calculator & Games, SpO2, Heart Rate Monitoring (Rose-Gold)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Livpui"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LDrAAb"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AHmnC0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/427whc2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oOMJiR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AKdoQs"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42bHCYo"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42bOBjY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41PJoig"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hrizc2"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1498"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61Yi8xC7q6L._SX522_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Electronics \n                Wearable Technology \n                 Smart Watches \nPryce1498 \nDicos85 \n\n❌Regular Price  : ₹  5999  /- 😱"
     }
    ]
   },
   {
    "id": 19687,
    "type": "message",
    "date": "2023-04-30",
    "date_unixtime": "1682814833",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "158",
    "dicos": "55",
    "catagory": "Health",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   DR. VAIDYA'S new age ayurveda My Prash Chyawanprash - 500 g | for Daily Health, Immunity Booster,Stamina & Energy, Ayurvedic, Natural Herbs, Rich in Antioxidants, Anti-aging - Pack of 1✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VgerkS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/428YHCp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LGVzZ9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LFbWoR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40QofmN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ACzRPF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40Rmd5Z"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ndFu3A"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3n9QGOT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41PYCUu"
     },
     {
      "type": "plain",
      "text": "\n\n @158\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61ZRMH192eL._SY450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Health"
     },
     {
      "type": "plain",
      "text": " & Personal Care\n\n                Health & Personal Care \n                Health Care \n                Alternative Medicine \nPryce158 \nDicos55 \n\n❌Regular Price  : ₹  349   /- 😱"
     }
    ]
   },
   {
    "id": 19688,
    "type": "message",
    "date": "2023-04-30",
    "date_unixtime": "1682814955",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "999",
    "dicos": "62",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   F Funx Gaming Bluetooth Truly Wireless In Ear Earbuds With Mic Tws With Charging Case, 45Ms Ultra Low-Latency,Dedicated Gaming Mode,Enc Noise Cancellation,Ipx5 Waterproof 50H Playtime✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HoOWYX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44gYizu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LG8Il5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40KfOtf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oVt8hb"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VxsFOx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/448d8Zj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44gsUBq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oVKV7R"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HnG1XD"
     },
     {
      "type": "plain",
      "text": "\n\n @999\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71gdg9F0yCL._SX522_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Computers & Accessories \n                Accessories & Peripherals \n                PC Gaming Peripherals \nPryce999 \nDicos62 \n\n❌Regular Price  : ₹ 19999    /- 😱"
     }
    ]
   },
   {
    "id": 19689,
    "type": "message",
    "date": "2023-04-30",
    "date_unixtime": "1682815076",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1299",
    "dicos": "40",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   CHONCHOW 7.1 Gaming Wired Gaming On Ear Headphone with Mic with Virtual Surround Sound,50Mm Driver, RGB LEDs & Remotrol (Blue/Black) (B-601-1-1-3)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HoTPkC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42bIcW4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41PYMey"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oOD7Vn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NoaUPm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oSNRC6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NktGHu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Nq1hQj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44gSNki"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NH8P1p"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1299"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51SAa4anf0L._SX522_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Computers & Accessories \n                Accessories & Peripherals \n                PC Gaming Peripherals \nPryce1299 \nDicos40 \n\n❌Regular Price  : ₹  1699   /- 😱"
     }
    ]
   },
   {
    "id": 19690,
    "type": "message",
    "date": "2023-04-30",
    "date_unixtime": "1682815198",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "649",
    "dicos": "44",
    "catagory": "Clothing",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   MINITATU Baby-Boy's Regular Fit T-Shirt✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40XYHEs"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41W3VBW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nd0RC4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3niFVd1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AFMCcb"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HoYSS7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AGd3OW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AFMGbV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VfcIfz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42b0jvy"
     },
     {
      "type": "plain",
      "text": "\n\n @649\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/91y6WRXk+eL._UX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Clothing"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Fashion \n                Apparel Steal Deals \n                Apparel_1 \nPryce649 \nDicos44 \n\n❌Regular Price  : ₹  999   /- 😱"
     }
    ]
   },
   {
    "id": 19691,
    "type": "message",
    "date": "2023-04-30",
    "date_unixtime": "1682815299",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "699",
    "dicos": "66",
    "catagory": "Clothing",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   MINITATU Girl's Cotton Heart Printed Pajama Bottom✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LDRDrU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HoYVxh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AFVvCE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ndFXCS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40RmC8v"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nfvRkZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HlcFt2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HqW5Id"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40Oupnd"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Nob7lC"
     },
     {
      "type": "plain",
      "text": "\n\n @699\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/91-n7UFJxeL._UY679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Clothing"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Clothing & Accessories \n                Baby \n                Baby Boys \nPryce699 \nDicos66 \n\n❌Regular Price  : ₹  1599   /- 😱"
     }
    ]
   },
   {
    "id": 19692,
    "type": "message",
    "date": "2023-04-30",
    "date_unixtime": "1682815420",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "649",
    "dicos": "35",
    "catagory": "Shoes",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Sparx mens Sd9039g Sneakers✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44bZ8h8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40SHLit"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41KWSvA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40XZ024"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44ibzIq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LG9gYb"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VkWsda"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vgxbk0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Liw7aW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LgDkYV"
     },
     {
      "type": "plain",
      "text": "\n\n @649\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51e48JV2GfL._UY500_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Shoes"
     },
     {
      "type": "plain",
      "text": " & Handbags\n\n                Shoes & Handbags \n                Shoes \n                Men's Shoes \nPryce649 \nDicos35 \n\n❌Regular Price  : ₹  999   /- 😱"
     }
    ]
   },
   {
    "id": 19693,
    "type": "message",
    "date": "2023-04-30",
    "date_unixtime": "1682815541",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1589",
    "dicos": "27",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Cosbary HD LED Wired Gaming Headset | Noise Cancelling Over-Ear Headphones with Mic for PC / PS4 / Xbox One/Nintendo Switch/Mac (Black) B-601-1-1✅\n"
     },
     {
      "type": "link",
      "text": "https://www.amazon.in/dp/B0BZSDG1VR?&amp;linkCode=sl1&amp;tag=avinashbmv07-21&amp;linkId=b9359ec44a611c10c0cbfa3dbce44618&amp;language=en_IN&amp;ref_=as_li_ss_tl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42e0LZX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LHNZNZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LFFGlD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42bxgHV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/420B66S"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Np80dp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/425QGhy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44fjZ31"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44f52xN"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1589"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51GVBYdW83L._SX522_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Computers & Accessories \n                Accessories & Peripherals \n                PC Gaming Peripherals \nPryce1589 \nDicos27 \n\n❌Regular Price  : ₹  1699   /- 😱"
     }
    ]
   },
   {
    "id": 19694,
    "type": "message",
    "date": "2023-04-30",
    "date_unixtime": "1682815641",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "327",
    "dicos": "68",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Rooh Dream Catcher Red Valentine with Pretty Lights Handmade Hangings for Positivity (Can be Used as Home Décor Accents, Wall Hangings, Meditation Room, Yoga Temple, Windchime)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40PYsej"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44fk86z"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Ll5RfR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41OVuIt"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44bgGtD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ADzIeD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oRQT9U"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Ln1JvY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vgxrzu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LiwvpU"
     },
     {
      "type": "plain",
      "text": "\n\n @327\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61z1x2-mbFL._SY355_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Home & Décor \n                 Decorative Accessories \nPryce327 \nDicos68 \n\n❌Regular Price  : ₹ 899    /- 😱"
     }
    ]
   },
   {
    "id": 19695,
    "type": "message",
    "date": "2023-04-30",
    "date_unixtime": "1682815740",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "805",
    "dicos": "77",
    "catagory": "Shoes",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Caprese Taro Women's Wallet✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VxthUl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AEGPDH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AFGznP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nglFIY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://www.amazon.in/dp/B0BBQ9QKZC?th=1&amp;psc=1&amp;linkCode=sl1&amp;tag=avi1deals5-21&amp;linkId=25cf1dfe468e18ebfe4c209db77506fe&amp;language=en_IN&amp;ref_=as_li_ss_tl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VgxFXm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hld0fi"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oO26Ih"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42b1dYY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LHDWIo"
     },
     {
      "type": "plain",
      "text": "\n\n @805\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/91F+sis6G8L._UX500_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Shoes"
     },
     {
      "type": "plain",
      "text": " & Handbags\n\n                Bags, Wallets and Luggage \n                Wallets, Card Cases & Money Organisers \n                Women's \nPryce805 \nDicos77 \n\n❌Regular Price  : ₹ 1999    /- 😱"
     }
    ]
   },
   {
    "id": 19696,
    "type": "message",
    "date": "2023-04-30",
    "date_unixtime": "1682815863",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "583",
    "dicos": "31",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   WONDER Plastic Prime Magna Fridge Bottle Set Red Prime Parent (6)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HpRUMu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41Q7oSf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oS8luM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Nqe1Xg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oSWPiR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Np73S3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44d3cxD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44hqdPS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LdxZ4G"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LCbTKx"
     },
     {
      "type": "plain",
      "text": "\n\n @583\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/7186oH6az1L._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Dining \n                Kitchen Storage & Containers \nPryce583 \nDicos31 \n\n❌Regular Price  : ₹  880    /- 😱"
     }
    ]
   },
   {
    "id": 19697,
    "type": "message",
    "date": "2023-04-30",
    "date_unixtime": "1682815985",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "699",
    "dicos": "72",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   PTron Bassbuds Plus in Ear True Wireless Stereo Earbuds with Mic, Deep Bass Bluetooth Headphones, Voice Assistance, IPX4 Sweat & Water Resistant TWS, 12Hrs Battery & Fast Charge (Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nkKS4T"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VhZbE0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LFkCeT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40Ov4oH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LhUnKf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HlY40g"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Np8znx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nkL447"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42b1hba"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/4241uws"
     },
     {
      "type": "plain",
      "text": "\n\n @699\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61PRAZMNyUL._SX522_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Headphones, Earbuds & Accessories \n                Headphones \nPryce699 \nDicos72 \n\n❌Regular Price  : ₹ 1999   /- 😱"
     }
    ]
   },
   {
    "id": 19698,
    "type": "message",
    "date": "2023-04-30",
    "date_unixtime": "1682816088",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "8998",
    "dicos": "45",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Livpure Glo Star RO+In-Tank UV+UF+Mineraliser with Carbon Filter - 7 L Storage, 15 LPH Water Purifier for Home, Suitable for Borewell, Tanker, Municipal Water (Blue)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/424ijrc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LIKqak"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42e1p9P"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LIKsis"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oOO6hv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42e1wCh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42b1xHa"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/4290k2Z"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/427LycB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44qbWRc"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@8998"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/6192jC2Lz2L._SY355_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Home Appliances \n                Water Purifiers & Accessories \nPryce8998 \nDicos45 \n\n❌Regular Price  : ₹  14999   /- 😱"
     }
    ]
   },
   {
    "id": 19699,
    "type": "message",
    "date": "2023-04-30",
    "date_unixtime": "1682816210",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1499",
    "dicos": "42",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   CROMPTON SUREBREEZE SEA Sapphira 1200 mm (48 inch) Ceiling Fan (Opal White) Star Rated Energy efficient Fans✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41UI6m3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44cQlLS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oRRwAi"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VhGKPK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44csmfL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oVuloJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LmEoue"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AC61L8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AD6Ymv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AHnrpM"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1499"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/31udR2mZM7L._SY355_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Heating, Cooling & Air Quality \n                Fans \nPryce1499 \nDicos42 \n\n❌Regular Price  : ₹   1999   /- 😱"
     }
    ]
   },
   {
    "id": 19700,
    "type": "message",
    "date": "2023-04-30",
    "date_unixtime": "1682816334",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "8199",
    "dicos": "50",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Livpure Glo Star RO+UV+UF+Mineraliser - 7 L Storage, 15 LPH Water Purifier for Home, Suitable for Borewell, Tanker, Municipal Water (Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AChUk6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ADaSvu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HoeBRk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VfnKl5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oSobpp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/427jkyT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/426xVKT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VhCNdP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vxu7jX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oJ8OPN"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@8199"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/41vt+pz11WL._SY550_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Home Appliances \n                Water Purifiers & Accessories \nPryce8199 \nDicos50 \n\n❌Regular Price  : ₹  14999     /- 😱"
     }
    ]
   },
   {
    "id": 19701,
    "type": "message",
    "date": "2023-04-30",
    "date_unixtime": "1682816457",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "499",
    "dicos": "74",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   PTron Tangent Duo Bluetooth 5.2 Wireless in Ear Headphones, 13mm Driver, Deep Bass, HD Calls, Fast Charging Type-C Neckband, Dual Pairing, Voice Assistant & IPX4 Water Resistant (Black/Blue)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44bhkY5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44gUb6u"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40VncSl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Nk7sW5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41Q0cFU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41Q7Xvl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LDtIYH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44druaC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3niFT4Y"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LIxbGB"
     },
     {
      "type": "plain",
      "text": "\n\n @499\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51niwp02VOL._SX466_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Headphones, Earbuds & Accessories \n                Headphones \nPryce499 \nDicos74 \n\n❌Regular Price  : ₹  1299    /- 😱"
     }
    ]
   },
   {
    "id": 19702,
    "type": "message",
    "date": "2023-04-30",
    "date_unixtime": "1682816574",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "749",
    "dicos": "47",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Pigeon Inox Hydra 700 ml Bottle (Pack of 4, Black, Steel/Chrome, Steel)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LH1Xzs"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VoZQ6M"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40RqNB9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LiXYrB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40RnVEr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NlHul5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3n9SozN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oJ8Qan"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oLz8J4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42byi6L"
     },
     {
      "type": "plain",
      "text": "\n\n @749\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/713Y0jtA07L._SY355_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Dining \n                Kitchen Storage & Containers \nPryce749 \nDicos47 \n\n❌Regular Price  : ₹   1399   /- 😱"
     }
    ]
   },
   {
    "id": 19703,
    "type": "message",
    "date": "2023-04-30",
    "date_unixtime": "1682816696",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "4298",
    "dicos": "39",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Hindware JUVO 1200mm High Speed Designer Ceiling Fan with Electroplated Finish, Copper Motor and Double Ball Bearing For Smoother & Silent Operation (Antique Brass)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LFDdrc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3n6YIIk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/4242b92"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/428zhVp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41QXZtE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vk1XbP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40Y0xoQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40Ro3np"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VjJmwp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AHoAgM"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@4298"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51ieK-zpHaL._SY355_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Heating, Cooling & Air Quality \n                Fans \nPryce4298 \nDicos39 \n\n❌Regular Price  : ₹  5499   /- 😱"
     }
    ]
   },
   {
    "id": 19704,
    "type": "message",
    "date": "2023-04-30",
    "date_unixtime": "1682816826",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "999",
    "dicos": "78",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Boult Audio FXCharge Wireless in Ear Bluetooth Neckband with ENC Mic, 32H Playtime, Type-C Fast Charging (5Mins=7.5Hrs Playtime), Dual Pairing, Made in India, Biggest 14.2mm Drivers Ear Phones (Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LdyICW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LDtWiv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nnFCNU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oVIe6p"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ni9KKA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ACBOLZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nfx9MR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AKfZtG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hpgg9e"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://www.amazon.in/dp/B0B1F6GQPS?th=1&amp;linkCode=sl1&amp;tag=avi1deals10-21&amp;linkId=dccce15ca4ad16a1d3d258e9736a89e9&amp;language=en_IN&amp;ref_=as_li_ss_tl"
     },
     {
      "type": "plain",
      "text": "\n\n @999\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/6161gqzzYlL._SX522_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Headphones, Earbuds & Accessories \n                Headphones \nPryce999 \nDicos78 \n\n❌Regular Price  : ₹  2999    /- 😱"
     }
    ]
   },
   {
    "id": 19705,
    "type": "message",
    "date": "2023-04-30",
    "date_unixtime": "1682816957",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "499",
    "dicos": "50",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   JBL C50HI, Wired in Ear Headphones with Mic, One Button Multi-Function Remote, Lightweight & Comfortable fit (Red)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ngnfKU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40QvhYs"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/429jPZ7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40Ow3oT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LG8GcQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LG8Hxq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LGeEuo"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LGaNgT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LDTjlc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HrlFNc"
     },
     {
      "type": "plain",
      "text": "\n\n @499\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/614O-yU3euL._SX522_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Headphones, Earbuds & Accessories \n                Headphones \nPryce499 \nDicos50 \n\n❌Regular Price  : ₹  899    /- 😱"
     }
    ]
   },
   {
    "id": 19706,
    "type": "message",
    "date": "2023-04-30",
    "date_unixtime": "1682817078",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "899",
    "dicos": "70",
    "catagory": "Clothing",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Ap'pulse Men's 1/4 Zipper Hi Neck Thumbopen (Pack of 2)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HtJSSX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Nk7ZY5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44bi6Et"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LIxwcl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Np39bT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44h0w1O"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44dSIOs"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LDTrkG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vp0fpO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oSoWif"
     },
     {
      "type": "plain",
      "text": "\n\n @899\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61b32wKRpZL._UX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Clothing"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Clothing & Accessories \n                Men \n                T-Shirts & Polos \nPryce899 \nDicos70 \n\n❌Regular Price  : ₹  1999   /- 😱"
     }
    ]
   },
   {
    "id": 19707,
    "type": "message",
    "date": "2023-04-30",
    "date_unixtime": "1682817198",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "488",
    "dicos": "2",
    "catagory": "Toys",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Birday Happy Birthday Balloon Set, (BLN-COMBO-4)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LFP5JS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VhHy7e"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LiYDcz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lm2Vjr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HoR8j9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/415shrH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41GSzBv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oO3VVD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Nor3UU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oO3Ykh"
     },
     {
      "type": "plain",
      "text": "\n\n @488\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71ZCXl+KkmL._SY355_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Toys"
     },
     {
      "type": "plain",
      "text": " & Games\n\n                Toys & Games \n                Party Supplies \n                Decorations \nPryce488 \nDicos2 \n\n❌Regular Price  : ₹  499  /- 😱"
     }
    ]
   },
   {
    "id": 19708,
    "type": "message",
    "date": "2023-04-30",
    "date_unixtime": "1682817298",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "449",
    "dicos": "36",
    "catagory": "Toys",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Gooyo Educational Learning Laptop Toy with LED Display and Music Effect | Play & Learn with Fun - Alphabets/Counting/Words and More Functions - 2018 | Pink Color, Power Source: 3xAA Battery (Not Included)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oSnTyD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/429kauR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vgza7W"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oSpbtF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VkYeuQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Npq07j"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42e2Ipf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nazzMS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41NsPTT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40Q09Zd"
     },
     {
      "type": "plain",
      "text": "\n\n @449\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61ZGqi2KqrL._SY355_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Toys"
     },
     {
      "type": "plain",
      "text": " & Games\n\n                Toys & Games \n                Learning & Education \n                Electronics \nPryce449 \nDicos36 \n\n❌Regular Price  : ₹ 699    /- 😱"
     }
    ]
   },
   {
    "id": 19709,
    "type": "message",
    "date": "2023-04-30",
    "date_unixtime": "1682817419",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "395",
    "dicos": "NaN",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Cosmos (Set of 6) 1000 Ml-Blue✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40OXlvw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HnIvVX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LDuvsD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nnaxdf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LiYOof"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AFWX88"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LaTeE4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40Qv1Zy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ABmVJT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nfYOx7"
     },
     {
      "type": "plain",
      "text": "\n\n @395\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/714Jr1ZnZzL._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Dining \n                Tableware \nPryce395 \nDicosNaN"
     }
    ]
   },
   {
    "id": 19710,
    "type": "message",
    "date": "2023-04-30",
    "date_unixtime": "1682817541",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "4050",
    "dicos": "46",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   CROCKERY WALA AND COMPANY Stainless Steel Dinner Set - 63 Pieces✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40QvSta"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oLzWh4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/427zxnM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oUWRqv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LQewZz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42cWjuv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oSpnsT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40Pta7t"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LaTjHS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LiZb27"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@4050"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/81CfhIrrV4L._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Dining \n                Tableware \nPryce4050 \nDicos46 \n\n❌Regular Price  : ₹   6499  /- 😱"
     }
    ]
   },
   {
    "id": 19711,
    "type": "message",
    "date": "2023-04-30",
    "date_unixtime": "1682817640",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "349",
    "dicos": "83",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   eCraftIndia Wrought Iron Lord Ganesha Playing Flute Wall Hanging Sculpture Decorative Religious Showpiece for Home Wall Decor, Pooja Room, Temple & House Warming Gift✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oSQrrM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41PfkDo"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HpEMY6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oOFFmp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Nxbome"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Npq7jf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VgTGoW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vk30bL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VhE18V"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VhI1q0"
     },
     {
      "type": "plain",
      "text": "\n\n @349\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/813VATVPxGL._SX522_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Artwork \n                 Wall Sculptures \nPryce349 \nDicos83 \n\n❌Regular Price  : ₹   1399  /- 😱"
     }
    ]
   },
   {
    "id": 19712,
    "type": "message",
    "date": "2023-04-30",
    "date_unixtime": "1682817763",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "849",
    "dicos": "58",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Portronics Power Plate 7 with 6 USB Port + 8 Power Sockets Smart Electric Universal Extension Board Multi Plug with 2500W, 3Mtr Cord Length, 2.1A USB Output(Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VgTPJ0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42e2SNn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/426GBko"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LdzAHI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41SwjVm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LggSPL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41PMGC8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40QvomU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41KZ9XE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nfQ2zi"
     },
     {
      "type": "plain",
      "text": "\n\n @849\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/518grrfPw8L._SX522_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Power Accessories \n                 Extension Cords \nPryce849 \nDicos58 \n\n❌Regular Price  : ₹  1599    /- 😱"
     }
    ]
   },
   {
    "id": 19713,
    "type": "message",
    "date": "2023-04-30",
    "date_unixtime": "1682817862",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "234",
    "dicos": "35",
    "catagory": "Beauty",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Margo Original Neem Soap - 125gm Pack of 8✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LGLRGc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3n6ZRzC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ALxEBf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AD8JQD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oOFYO5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/415sP0J"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44gwbAI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3njlYCP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LgFAzn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oUlZ0w"
     },
     {
      "type": "plain",
      "text": "\n\n @234\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61JI6P5LBUL._SY450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Beauty"
     },
     {
      "type": "plain",
      "text": "\n\n                Beauty \n                Bath & Body \n                Cleansers \nPryce234 \nDicos35 \n\n❌Regular Price  : ₹  360   /- 😱"
     }
    ]
   },
   {
    "id": 19714,
    "type": "message",
    "date": "2023-04-30",
    "date_unixtime": "1682817983",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "449",
    "dicos": "55",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀  BSB HOME® 100% Cotton 160 TC Zigzag Printed Double Bedsheets with 2 King Size Pillow Cover, (90X90 Inches, 7 x 7.5 Feet, Grey & Yellow) ✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oX2hkx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AEK3qJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lkyrhr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AEGZef"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AIffFH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40MdB0d"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AKh1G4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44dTBqg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LFHjQl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AKh70o"
     },
     {
      "type": "plain",
      "text": "\n\n @449\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/91+dDMcilIL._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Home Furnishing \n                Bedding & Linen \nPryce449 \nDicos55 \n\n❌Regular Price  : ₹  599    /- 😱"
     }
    ]
   },
   {
    "id": 19715,
    "type": "message",
    "date": "2023-04-30",
    "date_unixtime": "1682818103",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1169",
    "dicos": "35",
    "catagory": "Clothing",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Reebok Men's Polyester Crew Neck Sweatshirt✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AEJyNr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LmGuKC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VjLzIq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nnbIJH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LGZ8hZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oSoyjB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40SKIzz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HlfCtC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nhhLQ6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3naAIEa"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1169"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71oslhUVDwL._UX466_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Clothing"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Clothing & Accessories \n                Men \n                Winter Wear \nPryce1169 \nDicos35 \n\n❌Regular Price  : ₹  1499   /- 😱"
     }
    ]
   },
   {
    "id": 19716,
    "type": "message",
    "date": "2023-04-30",
    "date_unixtime": "1682818229",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1499",
    "dicos": "83",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Amazon Basics Cast Iron Heavy Duty Pre-Seasoned Wok Pan (14inch)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41PPjUi"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44hSAO7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NkxlVK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44dtbVw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vgm8HE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/427NOR7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42bLFny"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ncCEMn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LFEZZo"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40NXU8Y"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1499"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/81LCsIE0PTL._SX355_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Dining \n                Cookware \nPryce1499 \nDicos83 \n\n❌Regular Price  : ₹  5999   /- 😱"
     }
    ]
   },
   {
    "id": 19717,
    "type": "message",
    "date": "2023-04-30",
    "date_unixtime": "1682818350",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "217",
    "dicos": "89",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   SAF Motivational Set of 4 UV Textured Painting (19 x 19 Inches, SAF_SET4_21)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41Sruvo"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40VoVah"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Ns9kfp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VkduIn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vp20TW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NqxOFU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VhJk8m"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41Q201E"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Np9jsM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/4292XBT"
     },
     {
      "type": "plain",
      "text": "\n\n @217\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51FneEsh1vL.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Artwork \n                 Paintings \nPryce217 \nDicos89 \n\n❌Regular Price  : ₹  1299    /- 😱"
     }
    ]
   },
   {
    "id": 19718,
    "type": "message",
    "date": "2023-04-30",
    "date_unixtime": "1682818448",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "450",
    "dicos": "NaN",
    "catagory": "Beauty",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Menjewell Floral Eau de Apparel Perfume For Women (50ML)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AFPt4T"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42bLWa4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41PPCOW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NuCvi9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44caUYP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44cT1co"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44cu1Sx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44hsevq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40Kj5sx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LlWgpa"
     },
     {
      "type": "plain",
      "text": "\n\n @450\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61qQhfoj5UL._SY550_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Beauty"
     },
     {
      "type": "plain",
      "text": "\n\n                Beauty \n                Fragrance \n                 Perfume \nPryce450 \nDicosNaN"
     }
    ]
   },
   {
    "id": 19719,
    "type": "message",
    "date": "2023-04-30",
    "date_unixtime": "1682818551",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "649",
    "dicos": "57",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Amazon Brand - Solimo Stainless Steel Insulated 24 Hours Hot or Cold Bottle Flask, 1000 ml, Silver✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LghrZT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LaWSOs"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ndIFZ4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40Q1vDh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nibuU8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44cudRL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AEK6Tv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AHqQog"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ACk0jY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ADGEID"
     },
     {
      "type": "plain",
      "text": "\n\n @649\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71VJeH76FCL._SY879_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Dining \n                Kitchen Storage & Containers \nPryce649 \nDicos57 \n\n❌Regular Price  : ₹  1399    /- 😱"
     }
    ]
   },
   {
    "id": 19720,
    "type": "message",
    "date": "2023-04-30",
    "date_unixtime": "1682818651",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "148",
    "dicos": "73",
    "catagory": "Beauty",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   PureSense Macadamia 3-in-1 Revitalising Face Mask | Scrub and Cleanser | Cleanses Pores | Sulphate & Paraben Free | All Skin Types | 140 ml✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AHqUEw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VgAess"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VhF74x"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LE36YG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VhIOas"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LDUUYe"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vfg4iF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/427Og1L"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ncD05F"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41QZBUe"
     },
     {
      "type": "plain",
      "text": "\n\n @148\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61k8y4x2I1L._SY450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Beauty"
     },
     {
      "type": "plain",
      "text": "\n\n                Beauty \n                Skin Care \n                Face \nPryce148 \nDicos73 \n\n❌Regular Price  : ₹ 550    /- 😱"
     }
    ]
   },
   {
    "id": 19721,
    "type": "message",
    "date": "2023-04-30",
    "date_unixtime": "1682818773",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "2211",
    "dicos": "50",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Larah by Borosil - Tiara Series, Rose Gold, 36 Pcs, Opalware Dinner Set, White✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/421WQPW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41Sxrs4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NndtBc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oOGDix"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NqypaC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41NmBDz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42e3WRn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oO5DGx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VhJ7lC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oXSF9y"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@2211"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/519EgYgoCPL._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Dining \n                Tableware \nPryce2211 \nDicos50 \n\n❌Regular Price  : ₹  3999  /- 😱"
     }
    ]
   },
   {
    "id": 19722,
    "type": "message",
    "date": "2023-04-30",
    "date_unixtime": "1682818876",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "174",
    "dicos": "50",
    "catagory": "Beauty",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   MyGlamm POPxo H2O Booster Sunscreen Gel SPF 30-30gm✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VgUHxg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vf2TON"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vp2pWs"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nncnuF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HrndXw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40RHEUq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lht16T"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LaUnvm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vk4EtX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VeGxNr"
     },
     {
      "type": "plain",
      "text": "\n\n @174\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/41gEkCxV3zL._SY450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Beauty"
     },
     {
      "type": "plain",
      "text": "\n\n                Beauty \n                Skin Care \n                Face \nPryce174 \nDicos50 \n\n❌Regular Price  : ₹  349   /- 😱"
     }
    ]
   },
   {
    "id": 19723,
    "type": "message",
    "date": "2023-04-30",
    "date_unixtime": "1682818976",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "259",
    "dicos": "80",
    "catagory": "Musical",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   JUAREZ Professional Metal Capo JRZ300 Black for Guitars, Ukuleles✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/4293x2x"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ncILQN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LDVeGq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oO5VgB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nfRhhW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oKvQ8Y"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/429lzS9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nfzsQ1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ngpqy4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41NTEYj"
     },
     {
      "type": "plain",
      "text": "\n\n @259\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/614boVRqv7L._SX450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Musical"
     },
     {
      "type": "plain",
      "text": " Instruments\n\n                Musical Instruments \n                Guitars, Basses & Gear \n                Accessories \nPryce259 \nDicos80 \n\n❌Regular Price  : ₹  999   /- 😱"
     }
    ]
   },
   {
    "id": 19724,
    "type": "message",
    "date": "2023-04-30",
    "date_unixtime": "1682819074",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "97",
    "dicos": "68",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Itel 50GI 12W high Power Handset Charger_White✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44dtXlo"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nhiQHE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NnJ3Pm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44gXbQi"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40Q1Ohp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44h2rn2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oWLiPk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://www.amazon.in/dp/B09TQWQHQV?&amp;linkCode=sl1&amp;tag=avi1deals8-21&amp;linkId=2e76b27f8dce504e9b88c57c6e37723a&amp;language=en_IN&amp;ref_=as_li_ss_tl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LiHBLE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LlWTz2"
     },
     {
      "type": "plain",
      "text": "\n\n @97\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51ZUYhaG-SL._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Mobiles & Accessories \n                Mobile Accessories \nPryce97 \nDicos68 \n\n❌Regular Price  : ₹  299   /- 😱"
     }
    ]
   },
   {
    "id": 19725,
    "type": "message",
    "date": "2023-04-30",
    "date_unixtime": "1682819196",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "149",
    "dicos": "75",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   eCraftIndia handicrafted Decorative Wall/Door/Window Hanging Bells Wind Chimes Showpiece for Home Decor, Wall Decor, Pooja Room Temple, Diwali Gift, Corporate Gift✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ADGYaj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nic9F6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lm4DRT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LgHGiJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LkYVPU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ACkA18"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HqZUNz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LFXJbb"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HpGed0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VgVpdU"
     },
     {
      "type": "plain",
      "text": "\n\n @149\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61uzBgdClDL._SY355_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Home & Décor \n                Decorative Accessories \nPryce149 \nDicos75 \n\n❌Regular Price  : ₹  599   /- 😱"
     }
    ]
   },
   {
    "id": 19726,
    "type": "message",
    "date": "2023-04-30",
    "date_unixtime": "1682819300",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "805",
    "dicos": "60",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Symphony Solaris Quartz 800W Room Heater for Home with ISI Certified 2 Quartz Heating Tubes and Energy Efficient Heating ( Grey & Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VhJWLc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oWLtKu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40OBTH8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Np4XBH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Nxd9Qm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41SsfVg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41PQqDs"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hr01IZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/4293UKt"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oJbCwj"
     },
     {
      "type": "plain",
      "text": "\n\n @805\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61TdtREyj8L._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Heating, Cooling & Air Quality \n                Room Heaters \nPryce805 \nDicos60 \n\n❌Regular Price  : ₹  1599    /- 😱"
     }
    ]
   },
   {
    "id": 19727,
    "type": "message",
    "date": "2023-04-30",
    "date_unixtime": "1682819422",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "4570",
    "dicos": "71",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Hindware Atlantic 2500 Watt Ofr Room Heater With Ptc Fan - Velda 11 Fin, Black✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41QRR4F"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41W88FK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44fny9p"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41QS5Zz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LFIL5f"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VgBcF6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/421XGw4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VgBnAg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40PnWIM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LgiFUZ"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@4570"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/31PBdqrgXBL._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Heating, Cooling & Air Quality \n                Room Heaters \nPryce4570 \nDicos71 \n\n❌Regular Price  : ₹  9999   /- 😱"
     }
    ]
   },
   {
    "id": 19728,
    "type": "message",
    "date": "2023-04-30",
    "date_unixtime": "1682819543",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "4818",
    "dicos": "63",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Wonderchef Vista Storage Water Heater (25L)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oVKvOZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/427BzEq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41Qb6LF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oT075H"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42s3uPF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40Vq95n"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44ifQvs"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40VqaGt"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oTb4Em"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44bkk6N"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@4818"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51bweEHFdpL._SY355_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Heating, Cooling & Air Quality \n                Water Heaters & Geysers \nPryce4818 \nDicos63 \n\n❌Regular Price  : ₹  9999    /- 😱"
     }
    ]
   },
   {
    "id": 19729,
    "type": "message",
    "date": "2023-04-30",
    "date_unixtime": "1682819664",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "2227",
    "dicos": "63",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Hindware Smart Appliances Ventus Brown Gold 1200MM ceiling Fan Star Rated with metallic finish Energy Efficient High-Speed Air Delivery Fan comes with 49W copper motor and aerodynamic aluminium blades✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/446xNwG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lm55Q5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LgIdRL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ADaq0r"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NsE7J0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Ln61n4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LhtTIH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40Y3i9G"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40MfuKl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ALzMZL"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@2227"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51+ky33+G0L._SY355_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Heating, Cooling & Air Quality \n                Fans \nPryce2227 \nDicos63 \n\n❌Regular Price  : ₹  4999   /- 😱"
     }
    ]
   },
   {
    "id": 19730,
    "type": "message",
    "date": "2023-04-30",
    "date_unixtime": "1682819789",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "2816",
    "dicos": "47",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Havells 1200 mm Efficiencia Prime High Speed, BLDC Motor, Energy Efficient with Remote Control Ceiling Fan (Brown)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AC9Gsm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LIAbCR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vfr4wz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LH0TvB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NqzEqi"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vfmyhy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/421XWLy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44c9UnB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VhKgcU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HrgbSq"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@2816"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61B2UNnAg4L._SY355_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Heating, Cooling & Air Quality \n                Fans \nPryce2816 \nDicos47 \n\n❌Regular Price  : ₹  3999   /- 😱"
     }
    ]
   },
   {
    "id": 19731,
    "type": "message",
    "date": "2023-04-30",
    "date_unixtime": "1682819911",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "284",
    "dicos": "74",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   AGARO Rejoice SH120 Rich Bass in-Ear Wired Earphone with Mic and Passive Noise Isolation (Green)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/426BJMb"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/420FVx0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40OCHMa"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40RrsCH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41Q3tFc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NnK75O"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41QDUnd"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41QboSL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41MsroM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LFJCCZ"
     },
     {
      "type": "plain",
      "text": "\n\n @284\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51fDNJc4hBL._SY450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Headphones, Earbuds & Accessories \n                Headphones \nPryce284 \nDicos74 \n\n❌Regular Price  : ₹ 899  /- 😱"
     }
    ]
   },
   {
    "id": 19732,
    "type": "message",
    "date": "2023-04-30",
    "date_unixtime": "1682820033",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "749",
    "dicos": "79",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Zebronics Zeb-Sound Bomb 3 TWS In Ear Earbuds with Bluetooth v5.2, up to 12H Backup, Flash Connect, Splash Proof, Voice Assistant, stem Touch Control, 13mm Driver, Built in Microphone and Type C(Blue)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44goYAI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LFh8Jn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vk1ypV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vp3DB2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HqhAIX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ADEbhp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LiAl2i"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AG0mnm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LlXWis"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oJch0L"
     },
     {
      "type": "plain",
      "text": "\n\n @749\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61BT6aCutvL._SX522_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Headphones, Earbuds & Accessories \n                Headphones \nPryce749 \nDicos79 \n\n❌Regular Price  : ₹  2499    /- 😱"
     }
    ]
   },
   {
    "id": 19733,
    "type": "message",
    "date": "2023-04-30",
    "date_unixtime": "1682820154",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "449",
    "dicos": "85",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Amazon Brand - Solimo Truly Wireless in Ear Earbuds with 21-Hour Playtime and Touch Control | IPX4 Water Resistance and Bluetooth v5.0 (Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Nq5HXp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Nxe7fs"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42bOpl8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41L1Ok6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41QRR4P"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/4250LvL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/427PM3X"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42bubYq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3n9VTpV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NsbziP"
     },
     {
      "type": "plain",
      "text": "\n\n @449\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/418P07xg2yL._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Headphones, Earbuds & Accessories \n                Headphones \nPryce449 \nDicos85 \n\n❌Regular Price  : ₹   1999  /- 😱"
     }
    ]
   },
   {
    "id": 19734,
    "type": "message",
    "date": "2023-04-30",
    "date_unixtime": "1682820277",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "199",
    "dicos": "72",
    "catagory": "Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Quantum RJ45 Ethernet Patch/LAN Cable with Gold Plated Connectors Supports Upto 1000Mbps -32Feet (10 Meters), White✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40O7j07"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LlYgxG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LqHLke"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LkZPMi"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LhoLV2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3niK1Sw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AH6c7M"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AFRder"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ndWx5w"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ndKLbo"
     },
     {
      "type": "plain",
      "text": "\n\n @199\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/31AQqe9fbJL._SX300_SY300_QL70_FMwebp_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Computers & Accessories \n                Accessories & Peripherals \n                Cables & Accessories \nPryce199 \nDicos72 \n\n❌Regular Price  : ₹  699  /- 😱"
     }
    ]
   },
   {
    "id": 19735,
    "type": "message",
    "date": "2023-04-30",
    "date_unixtime": "1682820377",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "198",
    "dicos": "1",
    "catagory": "Beauty",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Fair and Handsome Instant Radiance Face Wash | Pro-Peptide | Instant Radiance| Washes of Fine Pollutants | Cooling Freshness | 100g✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ABpDPz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LGccUA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LE4TwS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LDWr0q"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hp8yff"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HntUtq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/429nA0F"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42b60tq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40OzTyj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oKxo2M"
     },
     {
      "type": "plain",
      "text": "\n\n @198\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61FNI8Jcp0L._SY355_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Beauty"
     },
     {
      "type": "plain",
      "text": "\n\n                Beauty \n                Skin Care \n                Face \nPryce198 \nDicos1 \n\n❌Regular Price  : ₹  199   /- 😱"
     }
    ]
   },
   {
    "id": 19736,
    "type": "message",
    "date": "2023-04-30",
    "date_unixtime": "1682820496",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "216",
    "dicos": "78",
    "catagory": "Tools",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀  Amazon Brand - Presto! Microfibre Cleaning Cloth, 40 x 30 cm, 220 GSM, Set of 8, Yellow ✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40KOfQh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40S4Wt4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41UMr8P"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3n8JTF7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/4250Z65"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41P0vkh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NqAnrw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41Q49dI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3n9W8Bl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VfrQcX"
     },
     {
      "type": "plain",
      "text": "\n\n @216\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/711uXVRGtRL._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Tools"
     },
     {
      "type": "plain",
      "text": " & Home Improvement\n\n                Home & Kitchen \n                Great Indian Festival Home & Kitchen \n                 Home Improvement \nPryce216 \nDicos78 \n\n❌Regular Price  : ₹  899    /- 😱"
     }
    ]
   },
   {
    "id": 19737,
    "type": "message",
    "date": "2023-04-30",
    "date_unixtime": "1682820594",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1658",
    "dicos": "50",
    "catagory": "Tools",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Havells Life Line Plus S3 0.75 sq mm PVC HRFR Cable (Blue)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44cd9vd"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VkfnVt"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LCgo7T"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oW6vJu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VpRnQD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VhLrJk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vm3PRA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vfs9o7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40Qukj7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VgX438"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1658"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/91kdN5nCWHL._SX355_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Tools"
     },
     {
      "type": "plain",
      "text": " & Home Improvement\n\n                Home Improvement \n                 Electrical \nPryce1658 \nDicos50 \n\n❌Regular Price  : ₹ 2499    /- 😱"
     }
    ]
   },
   {
    "id": 19738,
    "type": "message",
    "date": "2023-04-30",
    "date_unixtime": "1682820714",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "460",
    "dicos": "NaN",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Havells LHEBHEP6IZ1W003 Octane Square 3-Watt LED Panel Light (White), 75 mm x 75 mm✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ADIJ7p"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HpX0IC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HoZffs"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/420GvLc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LFhCiF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LGikfG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41PPP4U"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/421YW2g"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oSrvAH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41W9twg"
     },
     {
      "type": "plain",
      "text": "\n\n @460\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/41wSqOBSMJL._SX355_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Indoor Lighting \n                Light Bulbs \nPryce460 \nDicosNaN \n\n❌Regular Price  : ₹  460  /- 😱"
     }
    ]
   },
   {
    "id": 19739,
    "type": "message",
    "date": "2023-04-30",
    "date_unixtime": "1682820813",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "243",
    "dicos": "51",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   SYSKA SQ2001-20W-SLM 20Watt Slim T5 Tubelight (Pack of 1, White)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41PPTSc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44fp8rR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44blJKB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NptTcp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NnfCge"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40S5f7c"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40Rv8UX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lk6OoR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nkPYOz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ng1URL"
     },
     {
      "type": "plain",
      "text": "\n\n @243\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/619Y9o5ORUL._SY355_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Indoor Lighting \n                 Tube Lights & Battens \nPryce243 \nDicos51 \n\n❌Regular Price  : ₹  499   /- 😱"
     }
    ]
   },
   {
    "id": 19740,
    "type": "message",
    "date": "2023-04-30",
    "date_unixtime": "1682820915",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "4899",
    "dicos": "39",
    "catagory": "Clothing",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Amazon Brand – Solimo Murray Hardside Trolley, Blue, Set of 2 (55cm + 66cm)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AFLnJT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nidxYk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LiBc30"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AIi6yp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LGcElK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AClZ7U"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ng2crP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nfBz6p"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HpOqtC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oVyYz7"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@4899"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/91LH0ttzSsL._UY606_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Clothing"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Bags, Wallets and Luggage \n                Luggage \n                 Suitcases & Trolley Bags \nPryce4899 \nDicos39 \n\n❌Regular Price  : ₹  6999   /- 😱"
     }
    ]
   },
   {
    "id": 19741,
    "type": "message",
    "date": "2023-04-30",
    "date_unixtime": "1682821036",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "418",
    "dicos": "53",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Prestige SS Water Bottle PSWBC-09 350 ML (Stainless Steel, Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HrLDQK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/426vBDE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hr1Fu9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HrRLIx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40ODJYy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oSe3wI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oQSw7E"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40UyeYa"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NpHDUK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41P15hX"
     },
     {
      "type": "plain",
      "text": "\n\n @418\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61bXjBCZcxL._SY606_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Dining \n                Kitchen Storage & Containers \nPryce418 \nDicos53 \n\n❌Regular Price  : ₹ 799    /- 😱"
     }
    ]
   },
   {
    "id": 19742,
    "type": "message",
    "date": "2023-04-30",
    "date_unixtime": "1682821142",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "564",
    "dicos": "81",
    "catagory": "Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   RIATECH Large Size (900mm x 400mm x 2mm) Hexagonal Print Speed Type Extended Gaming Mouse Pad with Stitched Embroidery Edge, Premium-Textured Mouse Mat, Mousepad for Laptop/Computer- Black✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41NwKA5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LDYCkM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44cVWSo"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oXUWS8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VfnZws"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LIPbkc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LFZW6t"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AFS52H"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oYkQov"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40QzyLu"
     },
     {
      "type": "plain",
      "text": "\n\n @564\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/91pIt7I-aVL._SY450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Computers & Accessories \n                Accessories & Peripherals \n                Keyboards, Mice & Input Devices \nPryce564 \nDicos81 \n\n❌Regular Price  : ₹  1999   /- 😱"
     }
    ]
   },
   {
    "id": 19743,
    "type": "message",
    "date": "2023-04-30",
    "date_unixtime": "1682821264",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1014",
    "dicos": "52",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Cello Opalware Dazzle Secret Garden Dinner Set, 18Pcs, White✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40KlG5L"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HnMhP7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HN2JZB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LhpsxC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HlePJ5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41QSNpR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40O0Ylu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40Pq4QM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44rIkTB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41Wa4y0"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1014"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61+5HsQYSBS._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Dining \n                Tableware \nPryce1014 \nDicos52 \n\n❌Regular Price  : ₹  1699    /- 😱"
     }
    ]
   },
   {
    "id": 19744,
    "type": "message",
    "date": "2023-04-30",
    "date_unixtime": "1682821387",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1005",
    "dicos": "54",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Pigeon Brewster Coffee Maker, 600 Watt, 4 Cups Drip Coffee maker (Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oJdu8j"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Nq6Zld"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44c5pcG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Llbo67"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LlZ9q0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oX66WV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Ln7Zns"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40Vs1ep"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44bmovz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LDqoOi"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1005"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51q9WR-LfHL._SY355_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Home Appliances \n                Coffee, Tea & Espresso \nPryce1005 \nDicos54 \n\n❌Regular Price  : ₹  1799   /- 😱"
     }
    ]
   },
   {
    "id": 19745,
    "type": "message",
    "date": "2023-04-30",
    "date_unixtime": "1682821508",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "7999",
    "dicos": "49",
    "catagory": "Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Acer HA220Q 21.5 Inch (54.61 Cm) Full HD IPS Ultra Slim (6.6Mm Thick) LCD Monitor with LED Back Light Technology I Frameless Design I AMD Free Sync I Eye Care Features I Stereo Speakers (White)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lm07Tl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ndCS5J"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Npe3yD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lk7URv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3n8KHd7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HpXxdA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NqjL3g"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vf5S9X"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HnuPtS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AEKJwj"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@7999"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51LsW7JmZNL._SY300_SX300_QL70_FMwebp_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Computers & Accessories \n                2021 \n                 Gaming Monitors \nPryce7999 \nDicos49 \n\n❌Regular Price  : ₹ 9999   /- 😱"
     }
    ]
   },
   {
    "id": 19746,
    "type": "message",
    "date": "2023-04-30",
    "date_unixtime": "1682821631",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "39500",
    "dicos": "37",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Hitachi 1.5 Ton 3 Star ice Clean Xpandable Plus Inverter Split AC (100% Copper, Dust Filter, 2023 Model, iZen 3400FXL, R32-RAS.G318PCAISF, Dual Gold)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AY4peZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hr25Rf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HpC9W8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LFivaZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/4251QUl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41PSI5w"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oQSZXs"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41P1DV3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oW7p8Q"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41NWfl1"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@39500"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51qAUryQ5DL._SX522_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Heating, Cooling & Air Quality \n                Air Conditioners \nPryce39500 \nDicos37 \n\n❌Regular Price  : ₹  54999  /- 😱"
     }
    ]
   },
   {
    "id": 19747,
    "type": "message",
    "date": "2023-04-30",
    "date_unixtime": "1682821753",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "999",
    "dicos": "52",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Signoraware Double Wall Square Dinner Set, 27-Pieces, Maroon✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oSUfcy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AD14S2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LH74Qa"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AY4vDn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ADG4uv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vf6dcJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40KPuip"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hr2fbj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40OEv7U"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hp05c6"
     },
     {
      "type": "plain",
      "text": "\n\n @999\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51jVoUpnR2L._SX355_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Dining \n                Tableware \nPryce999 \nDicos52 \n\n❌Regular Price  : ₹  1899   /- 😱"
     }
    ]
   },
   {
    "id": 19748,
    "type": "message",
    "date": "2023-04-30",
    "date_unixtime": "1682821851",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "272",
    "dicos": "1",
    "catagory": "Grocery",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Pantai Red Curry Paste Cup, 114g✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vk7izT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/427RyC9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42bDozV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40Q4yeH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/426wxIa"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HlfwCb"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3niLrMQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oT2oOh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44ciy5s"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NkAODK"
     },
     {
      "type": "plain",
      "text": "\n\n @272\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71-Aq0ydWRL._SY445_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Grocery"
     },
     {
      "type": "plain",
      "text": " & Gourmet Foods\n\n                Grocery & Gourmet Foods \n                Cooking & Baking Supplies \n                Cooking Pastes & Sauces \nPryce272 \nDicos1 \n\n❌Regular Price  : ₹  275  /- 😱"
     }
    ]
   },
   {
    "id": 19749,
    "type": "message",
    "date": "2023-04-30",
    "date_unixtime": "1682821950",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "249",
    "dicos": "84",
    "catagory": "Toys",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Amitasha Plastic Archery Bow and Arrow Toy Set with Target Board, Multicolour✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41UNMMT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NrtEOc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NpcQqL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3n9XpZ9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oJenxF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/449jGqF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42bVkue"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oVN6sd"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42e4Ygh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LDyB55"
     },
     {
      "type": "plain",
      "text": "\n\n @249\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/417eK2Qnc0L._SX355_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Toys"
     },
     {
      "type": "plain",
      "text": " & Games\n\n                Toys & Games \n                Sport & Outdoor \n                 Target Games & Darts \nPryce249 \nDicos84 \n\n❌Regular Price  : ₹  999   /- 😱"
     }
    ]
   },
   {
    "id": 19750,
    "type": "message",
    "date": "2023-04-30",
    "date_unixtime": "1682822074",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1499",
    "dicos": "70",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Philips Audio TAH4205XTBK/00 Bluetooth 5.0, Bass Booster, Quick Charging Upto 29H Playtime, 32mm Driver, Compact Folding Design Bluetooth Wireless On Ear Headphones With Mic (Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LFU7Gg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ACHx4r"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NrtLcA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ACmNcW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LlcaQz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AENIVz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hr2rax"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nfCC6l"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42bVphw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VgYbQm"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1499"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71NbN9Xjz2L._SY679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Headphones, Earbuds & Accessories \n                Headphones \nPryce1499 \nDicos70 \n\n❌Regular Price  : ₹  2999  /- 😱"
     }
    ]
   },
   {
    "id": 19751,
    "type": "message",
    "date": "2023-04-30",
    "date_unixtime": "1682822193",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "2801",
    "dicos": "NaN",
    "catagory": "Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   HP Z4Y86AA 16GB 2400MHz DDR4 RAM✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42s63Bh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nd8456"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AH7QGu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hnvw6s"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hr2CCJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LFIEGC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LChMr7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LE6JxM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VjOzUZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41L3Fp4"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@2801"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/81Wva+k+jiL._SX355_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Computers & Accessories \n                Components \n                 Memory \nPryce2801 \nDicosNaN \n\n❌Regular Price  : ₹  8999    /- 😱"
     }
    ]
   },
   {
    "id": 19752,
    "type": "message",
    "date": "2023-04-30",
    "date_unixtime": "1682822295",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1079",
    "dicos": "57",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Intex Musique Style Bluetooth in Ear Wireless Neckband with Up to 24H Playtime ASAP Charge, Dual Connectivity, Inbuilt AI Assistant and Magnet Earbuds Lock (Stealth Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LGjEiE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oX9HnW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oOJXdv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3n8LydP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40Y5z4I"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AIjLUF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LFIOhc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oZzY4Y"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ncM8Hr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oT2O7j"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1079"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/41C8eGzIXEL._SX522_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Headphones, Earbuds & Accessories \n                Headphones \nPryce1079 \nDicos57 \n\n❌Regular Price  : ₹  1599   /- 😱"
     }
    ]
   },
   {
    "id": 19753,
    "type": "message",
    "date": "2023-04-30",
    "date_unixtime": "1682822419",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "499",
    "dicos": "69",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Townsville Sleeko Laptop Table (Chalet)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44gr9nS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40Vt91D"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40Qwhfr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VxAvYt"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vl39Mk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oX7dWB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LkCwSN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44dXOdy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lm0U6L"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lb0Jeo"
     },
     {
      "type": "plain",
      "text": "\n\n @499\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71WItQNJ53L._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Home & Kitchen \n                Furniture \n                Study & Home Office Furniture \nPryce499 \nDicos69 \n\n❌Regular Price  : ₹ 1299   /- 😱"
     }
    ]
   },
   {
    "id": 19754,
    "type": "message",
    "date": "2023-04-30",
    "date_unixtime": "1682822542",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "32490",
    "dicos": "31",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Godrej 1.5 Ton 3 Star, 5-In-1 Convertible, Inverter Split AC (Copper, I-Sense Technology, 2023 Model, AC 1.5T EI 18TINV3R32-GWA \n"
     },
     {
      "type": "bot_command",
      "text": "/AC"
     },
     {
      "type": "plain",
      "text": "\n 1.5T EI 18TINV3R32-GWB, White)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HrIr7z"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nnKW3Q"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LIQf7G"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NngQbk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NqBLue"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3naFgdI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oV2Mfd"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LDYBNA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44qhT0u"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NpINQ6"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@32490"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51z-jTCpF6L._SX342_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Heating, Cooling & Air Quality \n                Air Conditioners \nPryce32490 \nDicos31 \n\n❌Regular Price  : ₹  39999   /- 😱"
     }
    ]
   },
   {
    "id": 19755,
    "type": "message",
    "date": "2023-04-30",
    "date_unixtime": "1682822642",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1871",
    "dicos": "47",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   AGARO Caspian Cool Mist Ultrasonic Humidifier, 1.7 Litres, For Bedroom, Home, Office, Adjustable Mist Output, Super Quiet, Auto Shut Off (White)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oT33iJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42alRIx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3n9Yikr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LDzh9x"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lj44Z1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lm1aCL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Ll2fKS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42e5xGV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40OFh4O"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40JOWJK"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1871"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51Qo++uCJxL._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Home & kitchen | Prime shopping days \n                Prime Units \nPryce1871 \nDicos47 \n\n❌Regular Price  : ₹  2999   /- 😱"
     }
    ]
   },
   {
    "id": 19756,
    "type": "message",
    "date": "2023-04-30",
    "date_unixtime": "1682822765",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1885",
    "dicos": "73",
    "catagory": "Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   ZEBRONICS DC Joker Edition DRIP Smartwatch with Bluetooth 5.1 Calling, 1.69\" Display Size, Voice Assistance, 11 Built-in + Customizable Watch Faces, 8 Menu UI, IP67 and 100+ Sports Tracker✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44dxRe2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ACnram"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VpTnID"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AG1XcU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nfD30t"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HrNjd0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41QU4NF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VjCy1R"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vf6X1v"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AIk5Tn"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1885"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/41cCJ6MJgRL._SX300_SY300_QL70_FMwebp_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Electronics \n                Wearable Technology \n                 Smart Watches \nPryce1885 \nDicos73 \n\n❌Regular Price  : ₹  4999   /- 😱"
     }
    ]
   },
   {
    "id": 19757,
    "type": "message",
    "date": "2023-04-30",
    "date_unixtime": "1682822885",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1649",
    "dicos": "70",
    "catagory": "Shoes",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Caprese Women's Tote Bag (Red)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ngtmyQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ncMu0J"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AG3dg4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LGedjC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NnMNAo"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LFUNLO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ALD537"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41QR6Zi"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oX7rgp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LFjHet"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1649"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61tsEswS0iL._UY395_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Shoes"
     },
     {
      "type": "plain",
      "text": " & Handbags\n\n                Shoes & Handbags \n                Handbags, Purses & Clutches \n                Handbags \nPryce1649 \nDicos70 \n\n❌Regular Price  : ₹  2999   /- 😱"
     }
    ]
   },
   {
    "id": 19758,
    "type": "message",
    "date": "2023-04-30",
    "date_unixtime": "1682822992",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "2999",
    "dicos": "43",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Orient Electric Wendy 1200mm BEE Star Rated Ceiling Fan (Topaz Gold, Pack of 1)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LID7zn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hr3ibf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40O2hko"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LI27Xw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/415xKPf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40JPq2w"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vi5EPi"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oYmIh1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40QwSOd"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40JPvDm"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@2999"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/21J1FPLJLyL._SX300_SY300_QL70_FMwebp_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Heating, Cooling & Air Quality \n                Fans \nPryce2999 \nDicos43 \n\n❌Regular Price  : ₹  3999   /- 😱"
     }
    ]
   },
   {
    "id": 19759,
    "type": "message",
    "date": "2023-04-30",
    "date_unixtime": "1682823091",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1050",
    "dicos": "30",
    "catagory": "Watches",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Sonata Epic Gents Analog Watch - EP10001SM02✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44dybtg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lm1mBZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41Mvq0s"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lk9gM5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LHVbtt"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3niMA76"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NqlnKm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44cfqqf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Np8K1T"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42bRotQ"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1050"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71SQkQz6G-L._UX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Watches"
     },
     {
      "type": "plain",
      "text": "\n\n                Fashion \n                Onam 2021 \n                Watches and fashion jewellery \nPryce1050 \nDicos30 \n\n❌Regular Price  : ₹  1399   /- 😱"
     }
    ]
   },
   {
    "id": 19760,
    "type": "message",
    "date": "2023-04-30",
    "date_unixtime": "1682823196",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "112",
    "dicos": "55",
    "catagory": "Beauty",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   MyGlamm POPxo Makeup Collection, Vibin' Mini Nail Kit Exclusively, Paraben Free, Long Lasting Formula, 5 in 1 Combo, 3 ml, Pack of 5✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42bQrkY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lm1264"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/420IBL4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AG2dbS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40UAkY2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AELUMf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44dyswi"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42bWkP0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ACIQ3l"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AIkntr"
     },
     {
      "type": "plain",
      "text": "\n\n @112\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71WH4goIpQL._SY450_PIbundle-5,TopRight,0,0_AA450SH20_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Beauty"
     },
     {
      "type": "plain",
      "text": "\n\n                Beauty \n                Manicure & Pedicure \n                Nails \nPryce112 \nDicos55 \n\n❌Regular Price  : ₹  249   /- 😱"
     }
    ]
   },
   {
    "id": 19761,
    "type": "message",
    "date": "2023-04-30",
    "date_unixtime": "1682823317",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "112",
    "dicos": "55",
    "catagory": "Beauty",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   MyGlamm POPxo Makeup Collection, Chillin' Mini Nail Kit, Paraben Free, Long Lasting Formula, 5 in 1 Combo✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44dadhX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VjD9k7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LiDgbg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HrJ0OJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3njrTI3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vki8WP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hr3w25"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ng4sPP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VhNVra"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3niNQaf"
     },
     {
      "type": "plain",
      "text": "\n\n @112\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61P2ispzZ7L._SY450_PIbundle-5,TopRight,0,0_AA450SH20_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Beauty"
     },
     {
      "type": "plain",
      "text": "\n\n                Beauty \n                Manicure & Pedicure \n                Nails \nPryce112 \nDicos55 \n\n❌Regular Price  : ₹   249   /- 😱"
     }
    ]
   },
   {
    "id": 19762,
    "type": "message",
    "date": "2023-04-30",
    "date_unixtime": "1682823418",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1090",
    "dicos": "71",
    "catagory": "Video",
    "text_entities": [
     {
      "type": "plain",
      "text": "II\nOffer 🚀🚀   Ant Esports Professional Gaming Combo, Gaming RGB Mouse + Gaming RGB Headset, USB✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/429q3It"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NjlYgO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42aldLe"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AHujn4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LH8z0K"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NlsNhJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/4221Q7a"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HN4JB5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oSgr6E"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41L4U7I"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1090"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61ra0UZnUnL._SX522_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Video"
     },
     {
      "type": "plain",
      "text": " Games\n\n                Computers & Accessories \n                Accessories & Peripherals \n                PC Gaming Peripherals \nPryce1090 \nDicos71 \n\n❌Regular Price  : ₹ 2999    /- 😱"
     }
    ]
   },
   {
    "id": 19763,
    "type": "message",
    "date": "2023-04-30",
    "date_unixtime": "1682823519",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "699",
    "dicos": "50",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   ZEBRONICS Zeb-Bang Wireless Bluetooth Over The Ear Headphone with Mic and and Playback time 16 hrs (Blue)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LGhfV9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LHVsfZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40Y6DFK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40PyN5B"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44c72XQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40Qxpjb"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41NrkVP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41QHkX5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44h6Ar6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vgn5jk"
     },
     {
      "type": "plain",
      "text": "\n\n @699\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71JmAlJ8ggL._SX522_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Headphones, Earbuds & Accessories \n                Headphones \nPryce699 \nDicos50 \n\n❌Regular Price  : ₹  1299   /- 😱"
     }
    ]
   },
   {
    "id": 19764,
    "type": "message",
    "date": "2023-04-30",
    "date_unixtime": "1682823642",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "899",
    "dicos": "64",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   ZEBRONICS Zeb-DUKE1 Wireless Bluetooth 5.0 Over Ear Headphone with Voice Assistant, AUX Port, Call Function, 34Hrs* Battery Backup, Dual Pairing, Media & Volume Control with mic (Green)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vi6jQM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lj5bb9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VhOaCA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LhxwhN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oOL6Sl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LiMog6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lldl2r"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HqupmR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oSgHTa"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LE8dYS"
     },
     {
      "type": "plain",
      "text": "\n\n @899\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61UmV5OukDL._SY450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Headphones, Earbuds & Accessories \n                Headphones \nPryce899 \nDicos64 \n\n❌Regular Price  : ₹  1999   /- 😱"
     }
    ]
   },
   {
    "id": 19765,
    "type": "message",
    "date": "2023-04-30",
    "date_unixtime": "1682823742",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "599",
    "dicos": "54",
    "catagory": "Tools",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Cheston Hand Tool Kit I 2 Screwdrivers, Cutter, Pliers, Tester, Measuring & Electric Tape, Hammer I Household & Professional Hand Tools✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NlOXk7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44fs4Vp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44hY23x"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NuHJKN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NnNutu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42bS826"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NHhfpx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42bx6jQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oKAfc0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40OGqt8"
     },
     {
      "type": "plain",
      "text": "\n\n @599\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61oWe-BRbwL._SY355_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Tools"
     },
     {
      "type": "plain",
      "text": " & Home Improvement\n\n                Home Improvement \n                Power & Hand Tools \n                Hand Tools \nPryce599 \nDicos54 \n\n❌Regular Price  : ₹   1199  /- 😱"
     }
    ]
   },
   {
    "id": 19766,
    "type": "message",
    "date": "2023-04-30",
    "date_unixtime": "1682823863",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1499",
    "dicos": "59",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   pTron Newly Launched Fusion Maxx 20W Bluetooth Wireless Party Speaker with Wired Karaoke Mic, 20Hrs Playtime, Immersive Sound, BT V5.2, 3.5mm AUX, USB, Micro SD Card Slot & Integrated Controls (Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LqLnmi"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40Ob4mf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ndZoLM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AG42Wc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AHusqC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vf7SPv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41L5jae"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LldItR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vf829z"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oRZ1XY"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1499"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61y572aqzVL._SY355_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Home Audio \n                Speakers \nPryce1499 \nDicos59 \n\n❌Regular Price  : ₹  2999   /- 😱"
     }
    ]
   },
   {
    "id": 19767,
    "type": "message",
    "date": "2023-04-30",
    "date_unixtime": "1682823984",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "639",
    "dicos": "42",
    "catagory": "Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Acer UP300 USB 3.2 Gen 1 Pen Drive-Green (32GB)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LlS9cU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HqkVYv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AEPAO5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nd9Q6g"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42d36Ev"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://www.amazon.in/dp/B0B24199NS?smid=A3L8PMPSACIVGM&amp;th=1&amp;linkCode=sl1&amp;tag=avi1deals6-21&amp;linkId=c333b9d9517daf19d7a3e883c1cbcfb8&amp;language=en_IN&amp;ref_=as_li_ss_tl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LDAjDx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hnx1S8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HpRqWU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hp1YFI"
     },
     {
      "type": "plain",
      "text": "\n\n @639\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61rPJ89Z23L._SY450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Computers & Accessories \n                External Devices & Data Storage \n                 Pen Drives \nPryce639 \nDicos42 \n\n❌Regular Price  : ₹  999    /- 😱"
     }
    ]
   },
   {
    "id": 19768,
    "type": "message",
    "date": "2023-04-30",
    "date_unixtime": "1682824081",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1235",
    "dicos": "79",
    "catagory": "Video",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Ant Esports Premium Gaming Combo, Gaming RGB Mouse + Gaming RGB Headset + Gaming Mouse pad – Black✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3n75qhu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LG2odb"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41O3v0k"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40Y79Uc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ncNAtn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40U63Zk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oX8InH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40UAYVs"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44hYtuH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40ObbOH"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1235"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/81D2Sj32ijL._SX522_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Video"
     },
     {
      "type": "plain",
      "text": " Games\n\n                Computers & Accessories \n                Accessories & Peripherals \n                PC Gaming Peripherals \nPryce1235 \nDicos79 \n\n❌Regular Price  : ₹  3999   /- 😱"
     }
    ]
   },
   {
    "id": 19769,
    "type": "message",
    "date": "2023-04-30",
    "date_unixtime": "1682824204",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1069",
    "dicos": "78",
    "catagory": "Health",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Beurer 2200 Watts Salon Hair Dryer - Professional Blow Dryer - Lightweight Travel Hairdryer for Normal & Curly Hair Includes with 2 attachments & practical cable retractor Black (3 Years Warranty)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44cdTR5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/449lCiV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40PsFdu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44cdQET"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41Ouadr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VgZU8i"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/449lS1n"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LDADRF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nnhgnv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oSvXj5"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1069"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/41FVZSfjgbL._SX355_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Health"
     },
     {
      "type": "plain",
      "text": " & Personal Care\n\n                Beauty \n                Hair Care \n                Hair Styling Tools \nPryce1069 \nDicos78 \n\n❌Regular Price  : ₹  2999  /- 😱"
     }
    ]
   },
   {
    "id": 19770,
    "type": "message",
    "date": "2023-04-30",
    "date_unixtime": "1682824307",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "359",
    "dicos": "79",
    "catagory": "Clothing",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   LAWMAN PG3 Men's Slim Polo✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/425ZDHG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lna6aS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oVOTgV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LhakR2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LFKG9I"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41NrU5X"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41QI4LR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lnafeq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41NzZaJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HpRw0I"
     },
     {
      "type": "plain",
      "text": "\n\n @359\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/81E+8kVwgxL._UX522_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Clothing"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Clothing & Accessories \n                Men \n                T-Shirts & Polos \nPryce359 \nDicos79 \n\n❌Regular Price  : ₹   999  /- 😱"
     }
    ]
   },
   {
    "id": 19771,
    "type": "message",
    "date": "2023-04-30",
    "date_unixtime": "1682824407",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "255",
    "dicos": "9",
    "catagory": "Beauty",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Park Avenue Pure Luxury Soap Pack of 4 500gm✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LHMdvW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hq0iLY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Nl0aS1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44cgc6D"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LFlcJD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Nrw1R6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42e7bbz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Nrw5QQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oT5vFX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42bSKEW"
     },
     {
      "type": "plain",
      "text": "\n\n @255\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/41JHAGwWuHL._SX300_SY300_QL70_FMwebp_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Beauty"
     },
     {
      "type": "plain",
      "text": "\n\n                Beauty \n                Bath & Body \n                Cleansers \nPryce255 \nDicos9 \n\n❌Regular Price  : ₹  280   /- 😱"
     }
    ]
   },
   {
    "id": 19772,
    "type": "message",
    "date": "2023-04-30",
    "date_unixtime": "1682824507",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "3499",
    "dicos": "73",
    "catagory": "Clothing",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Aristocrat Airpro 76cm Polypropylene Hardsided Large Size 8 Wheels Blue Trolley✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3n8NDGF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/415yUKB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40QD08U"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41SxAMi"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42amd1W"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41N5fHd"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42bFDmP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AFIj0o"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AIlHMV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44cYZKk"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@3499"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71u9uL8nzML._UX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Clothing"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Bags, Wallets and Luggage \n                Luggage \n                 Suitcases & Trolley Bags \nPryce3499 \nDicos73 \n\n❌Regular Price  : ₹  8999    /- 😱"
     }
    ]
   },
   {
    "id": 19773,
    "type": "message",
    "date": "2023-04-30",
    "date_unixtime": "1682824607",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "799",
    "dicos": "72",
    "catagory": "Clothing",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Safari Quill Laptop Backpack 26 Ltrs Water Resistant Fabric - Blue, L (QUILL19CBBLU)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oVCf1n"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VfrbrW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AGcu7O"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LiNfxk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LkavLf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LqLSwG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nih0WQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LgnMEF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hpc2hP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AGcCUQ"
     },
     {
      "type": "plain",
      "text": "\n\n @799\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61TWJaaUMtL._UX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Clothing"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Computers & Accessories \n                Accessories & Peripherals \n                Laptop Accessories \nPryce799 \nDicos72 \n\n❌Regular Price  : ₹  1999   /- 😱"
     }
    ]
   },
   {
    "id": 19774,
    "type": "message",
    "date": "2023-04-30",
    "date_unixtime": "1682824707",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "149",
    "dicos": "85",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   PTron Solero TB301 3A Type-C Data and Fast Charging Cable, Made in India, 480Mbps Data Sync, Strong and Durable 1.5-Meter Nylon Braided USB Cable for Type-C Devices for Charging Adapter (Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Honevc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nho6Lo"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HnxxQ4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/427U3Ex"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41R5hxw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NjnbES"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41QSATm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LFL7km"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44h7Dr2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LFWFnO"
     },
     {
      "type": "plain",
      "text": "\n\n @149\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/31wOPjcSxlL._SX300_SY300_QL70_FMwebp_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Computers & Accessories \n                Accessories & Peripherals \n                Cables & Accessories \nPryce149 \nDicos85 \n\n❌Regular Price  : ₹  699   /- 😱"
     }
    ]
   },
   {
    "id": 19775,
    "type": "message",
    "date": "2023-04-30",
    "date_unixtime": "1682824829",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "849",
    "dicos": "65",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Amazon Basics Polyester Microfiber Duvet Cover Set With Pillow Cover - Single, 2-Piece (66x90-inch, Burgundy)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44cAhtv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44ikJEO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40Pt7Ze"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40Kphkh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41NZ31x"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vm7RcG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Li2TsH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40RO3yW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vi7csA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41NsCA9"
     },
     {
      "type": "plain",
      "text": "\n\n @849\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71wW1ZjxPeL._SY355_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Home Furnishing \n                Bedding & Linen \nPryce849 \nDicos65 \n\n❌Regular Price  : ₹ 1999   /- 😱"
     }
    ]
   },
   {
    "id": 19776,
    "type": "message",
    "date": "2023-04-30",
    "date_unixtime": "1682824930",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "398",
    "dicos": "44",
    "catagory": "Clothing",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Chromozome Men's Hybrid Shorts✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VeM06V"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ncJmlx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VjSzFc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42bya7k"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NpxWW9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41SxW5A"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vf8PHz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3n8NWBj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41Mx16s"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oOMaWl"
     },
     {
      "type": "plain",
      "text": "\n\n @398\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71XgqvHixwL._UX522_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Clothing"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Clothing & Accessories \n                Casual wear \n                Casual trousers & shorts \nPryce398 \nDicos44 \n\n❌Regular Price  : ₹  680  /- 😱"
     }
    ]
   },
   {
    "id": 19777,
    "type": "message",
    "date": "2023-04-30",
    "date_unixtime": "1682825030",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "3096",
    "dicos": "31",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Polycab Purocoat Premium 1200 Ceiling Fan (Revere Grape, 1200 MM)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HnPtdz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oSX7WS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LQlZYB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oLGHzs"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/449mt37"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LGgbk0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NqDGyW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NphnK7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3n76dis"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/448mXq9"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@3096"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/41g-wYimaKL._SY355_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Heating, Cooling & Air Quality \n                Fans \nPryce3096 \nDicos31 \n\n❌Regular Price  : ₹ 4099   /- 😱"
     }
    ]
   },
   {
    "id": 19778,
    "type": "message",
    "date": "2023-04-30",
    "date_unixtime": "1682825129",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "919",
    "dicos": "79",
    "catagory": "Clothing",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Stylee Lifestyle Black Rayon Printed Kurti✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NkfYo1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LmMAuu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40Rz9Zx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LhsRMU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LdFMzs"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40KprYV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oS0fT4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42bSCoE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AG5cRy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44ceHW7"
     },
     {
      "type": "plain",
      "text": "\n\n @919\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71C-iTuC4KL._UY606_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Clothing"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Clothing & Accessories \n                Women \n                Ethnic Wear \nPryce919 \nDicos79 \n\n❌Regular Price  : ₹ 1799   /- 😱"
     }
    ]
   },
   {
    "id": 19779,
    "type": "message",
    "date": "2023-04-30",
    "date_unixtime": "1682825250",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "189",
    "dicos": "NaN",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Adroitz Car Seat Black Neck Rest Cushion Pillow for Maruti Suzuki Alto,Maruti Suzuki Wagon-R, Tata Tiago, Tata Punch, Hyundai Santro, Hyundai Grand I-1O with Pack of 2✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44rLTZZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oUsznK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oWaIwM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VjSPnE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VjSQbc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HonDOe"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lgoh1v"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3njtNbF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hqml5h"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40OHygm"
     },
     {
      "type": "plain",
      "text": "\n\n @189\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51gOt4t2VNL._SX300_SY300_QL70_FMwebp_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Fashion \n                Luggage & Watches \n                 Mens Wallets \nPryce189 \nDicosNaN"
     }
    ]
   },
   {
    "id": 19780,
    "type": "message",
    "date": "2023-04-30",
    "date_unixtime": "1682825372",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "399",
    "dicos": "80",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Tygot Lightweight & Portable Portable 7 Feet (84 Inch) Long Tripod Stand with Adjustable Mobile Clip Holder for All Mobiles & Cameras (Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/428lU7B"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3njtX2L"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44cePoz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/424aaD2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41PmiZ4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44ftwqP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NoyqM4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41PmlnI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lht2b2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41O4AoU"
     },
     {
      "type": "plain",
      "text": "\n\n @399\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/31yLwzxjv7S._SX300_SY300_QL70_FMwebp_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Cameras & Photography \n                Accessories \nPryce399 \nDicos80 \n\n❌Regular Price  : ₹1399    /- 😱"
     }
    ]
   },
   {
    "id": 19781,
    "type": "message",
    "date": "2023-04-30",
    "date_unixtime": "1682825494",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "967",
    "dicos": "55",
    "catagory": "Beauty",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Livon Super Styler Serum For Women & Men For Hair Straightening |Straighter Hair Up To 12 Hours & 5X Less Breakage | With Heat Activated Proteins | 100 ml (Pack of 2) With Syska Hair Straightener✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LGTsEI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nfXBGc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40U7eYK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oQWpcK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oYoG0T"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LH62Ur"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40Ml5jP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40QDsEc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vf97OF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NuJpE5"
     },
     {
      "type": "plain",
      "text": "\n\n @967\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/617A4zEzjlL._SY450_PIbundle-3,TopRight,0,0_AA450SH20_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Beauty"
     },
     {
      "type": "plain",
      "text": "\n\n                Beauty \n                Hair Care \n                Styling \nPryce967 \nDicos55 \n\n❌Regular Price  : ₹ 1999   /- 😱"
     }
    ]
   },
   {
    "id": 19782,
    "type": "message",
    "date": "2023-04-30",
    "date_unixtime": "1682825616",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "784",
    "dicos": "74",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   ZEBRONICS Newly Launched Yoga 9 with 45 Hours Backup, Bluetooth v5.2 Wireless Neckband, ENC Calling, Gaming Mode (Upto 50ms), Voice Assistant, Dual Pairing, Splash Proof and Type C (Sunrise)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NpgiBJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VmhhVl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42d4tD9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Ll4Owu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lhz0Zp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3niiekU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41QJrtZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://www.amazon.in/dp/B0BLK914PD?th=1&amp;linkCode=sl1&amp;tag=avi1deals8-21&amp;linkId=b14c607a8d5117762fa4704e44f36f53&amp;language=en_IN&amp;ref_=as_li_ss_tl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41QgKxl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oQWVaG"
     },
     {
      "type": "plain",
      "text": "\n\n @784\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/6120CczQPiL._SY450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Headphones, Earbuds & Accessories \n                Headphones \nPryce784 \nDicos74 \n\n❌Regular Price  : ₹ 2299   /- 😱"
     }
    ]
   },
   {
    "id": 19783,
    "type": "message",
    "date": "2023-04-30",
    "date_unixtime": "1682825739",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "559",
    "dicos": "44",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Halonix Prime Bluetooth Base B22D 10 Watt smart led bulb (16 Million Colors + Warm White/Neutral White/White)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LG3SnL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VmhrMr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44fu0x9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Nqbf4b"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VfwM1t"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AY7ZFX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42bTR7A"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AHb3Wy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AHxtqE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40OEvV9"
     },
     {
      "type": "plain",
      "text": "\n\n @559\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71w0z6oK2EL._SX522_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Indoor Lighting \n                Light Bulbs \nPryce559 \nDicos44 \n\n❌Regular Price  : ₹899    /- 😱"
     }
    ]
   },
   {
    "id": 19784,
    "type": "message",
    "date": "2023-04-30",
    "date_unixtime": "1682825838",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "149",
    "dicos": "88",
    "catagory": "Clothing",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀  Aarika Women Shirt✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oOWFc9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lb140s"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HlblWL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40OEzUT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44hZV07"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44hZZwT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44i08Ar"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oWRqai"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oYp53p"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AIn0eN"
     },
     {
      "type": "plain",
      "text": "\n\n @149\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51RfF7FHkWL._UY606_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Clothing"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Clothing & Accessories \n                Women \n                Western Wear \nPryce149 \nDicos88 \n\n❌Regular Price  : ₹  750  /- 😱"
     }
    ]
   },
   {
    "id": 19785,
    "type": "message",
    "date": "2023-04-30",
    "date_unixtime": "1682825960",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "404",
    "dicos": "16",
    "catagory": "Grocery",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Snickers Peanut Filled Chocolates - 150g (Pack of 4)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40RzXh1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VjSiSt"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oV6vtd"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AFWbYD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/449nPed"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ACLciH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LDChmj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AKnIrG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AER8HT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42bU5LY"
     },
     {
      "type": "plain",
      "text": "\n\n @404\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61bhGZOLdfL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Grocery"
     },
     {
      "type": "plain",
      "text": " & Gourmet Foods\n\n                Grocery & Gourmet Foods \n                Snacks & Sweets \n                Chocolate \nPryce404 \nDicos16 \n\n❌Regular Price  : ₹ 470   /- 😱"
     }
    ]
   },
   {
    "id": 19786,
    "type": "message",
    "date": "2023-04-30",
    "date_unixtime": "1682826061",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "499",
    "dicos": "38",
    "catagory": "Clothing",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Amazon Brand - Myx Women's Cotton Regular Kurta✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oLHsIO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LGmq7t"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LGTVqs"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Npk5z3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LI4CsS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41N6fLt"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LGjnfB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40RA0td"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LHaTou"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NqoAJU"
     },
     {
      "type": "plain",
      "text": "\n\n @499\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/81F8Q5fVdiL._UX466_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Clothing"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Clothing & Accessories \n                Women \n                Ethnic Wear \nPryce499 \nDicos38 \n\n❌Regular Price  : ₹ 750   /- 😱"
     }
    ]
   },
   {
    "id": 19787,
    "type": "message",
    "date": "2023-04-30",
    "date_unixtime": "1682826182",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "3495",
    "dicos": "NaN",
    "catagory": "Health",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Lifelong LLM540 Manual Jade Roller & Gua Sha Set|Reduces Puffiness, Improves Blood Circulation, Relaxes Muscles|Facial Massage Roller|Facial Tool (1 Year Warranty, Green)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40UCEhI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/427sf3l"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/426AlsW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41PUQdK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/4261kow"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40KjjQk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41PUTpW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oSwsth"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41PX24K"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oWRKWy"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@3495"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71WvvUK4azL._SY450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Health"
     },
     {
      "type": "plain",
      "text": " & Personal Care\n\n                Health & Personal Care \n                Health Care \n                Massage & Relaxation \nPryce3495 \nDicosNaN \n\n❌Regular Price  : ₹1699   /- 😱"
     }
    ]
   },
   {
    "id": 19788,
    "type": "message",
    "date": "2023-04-30",
    "date_unixtime": "1682826283",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "244",
    "dicos": "83",
    "catagory": "Clothing",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Indigo Women's Bermuda Shorts✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oONCrL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vp9G8I"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42bzqr4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nniG1j"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oSxSnN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VpWPD5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LgoVfr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41R6G7g"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LqNc2C"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3niQdd9"
     },
     {
      "type": "plain",
      "text": "\n\n @244\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/81iJKOxxJIL._UX342_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Clothing"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Clothing & Accessories \n                Women \n                Western Wear \nPryce244 \nDicos83 \n\n❌Regular Price  : ₹    999/- 😱"
     }
    ]
   },
   {
    "id": 19789,
    "type": "message",
    "date": "2023-04-30",
    "date_unixtime": "1682826404",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "6520",
    "dicos": "53",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Samsung Galaxy Buds 2 | Wireless in Ear Earbuds Active Noise Cancellation, Auto Switch Feature, Up to 20hrs Battery Life, (Graphite)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ADJZHJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nfGxA5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44imq58"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HrQilI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44c9AoS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hm8sFi"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hqx9AF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/429acd3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LDCwxJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40O5now"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@6520"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51X0kXtlBCL._SX466_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Headphones, Earbuds & Accessories \n                Headphones \nPryce6520 \nDicos53 \n\n❌Regular Price  : ₹ 12550  /- 😱"
     }
    ]
   },
   {
    "id": 19790,
    "type": "message",
    "date": "2023-04-30",
    "date_unixtime": "1682826504",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "699",
    "dicos": "71",
    "catagory": "Clothing",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀 Veirdo Men T-Shirt✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/426Hd9J"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40UCWFk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44qkXd0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40Safsu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44fuH9J"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44h934Q"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44fuO59"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44iADyH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AFKpxi"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ng6M9v"
     },
     {
      "type": "plain",
      "text": "\n\n @699\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61B2CRzImRL._UX522_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Clothing"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Clothing & Accessories \n                Men \n                T-Shirts & Polos \nPryce699 \nDicos71 \n\n❌Regular Price  : ₹ 1799   /- 😱"
     }
    ]
   },
   {
    "id": 19791,
    "type": "message",
    "date": "2023-04-30",
    "date_unixtime": "1682826626",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "210",
    "dicos": "NaN",
    "catagory": "Beauty",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Pears Naturale Brightening Pomegranate Bodywash With Glycerine, Paraben Free, Soap Free, Eco Friendly, Dermatologically Tested, 250 ml✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AGn1Qm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VhMlpb"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VkHX8Q"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LlUswA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lj8cIv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VxEdRT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vpa07q"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LFmPHf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ACLK8f"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/420LT0S"
     },
     {
      "type": "plain",
      "text": "\n\n @210\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/41h9ydwiNWL._SX300_SY300_QL70_FMwebp_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Beauty"
     },
     {
      "type": "plain",
      "text": "\n\n                Beauty \n                Bath & Body \n                Cleansers \nPryce210 \nDicosNaN"
     }
    ]
   },
   {
    "id": 19792,
    "type": "message",
    "date": "2023-04-30",
    "date_unixtime": "1682826747",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1149",
    "dicos": "69",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Maxx PX3 Truly Wireless Earbuds with Upto 40 Hrs Playtime, IPX6 Water and Sweat Resistant, Touch Control, Super Fast Charging (Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LFuKnV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41PVlEE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41QYFzf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44gEBrO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41Owst3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41MyCcs"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44iANGj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40OJ422"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41Szywa"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40QF5Sg"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1149"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51OnFbNSyAL._SX522_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Headphones, Earbuds & Accessories \n                Headphones \nPryce1149 \nDicos69 \n\n❌Regular Price  : ₹ 2799   /- 😱"
     }
    ]
   },
   {
    "id": 19793,
    "type": "message",
    "date": "2023-04-30",
    "date_unixtime": "1682826868",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1179",
    "dicos": "76",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   PHILIPS Audio Tat1225 Bluetooth Truly Wireless in Ear Earbuds with Mic (TWS) with 18 Hours Playtime (6+12) Ipx4, Voice Assistant, Noise and Echo Cancellation (Blue)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VgtSJI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VhMDfL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AG6HiE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oWcq1a"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42bzV4q"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hm8OM8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HpdMaR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hp9KPV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nfHloB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hop3bw"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1179"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/718HvK+RQNL._SX522_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Headphones, Earbuds & Accessories \n                Headphones \nPryce1179 \nDicos76 \n\n❌Regular Price  : ₹ 3499   /- 😱"
     }
    ]
   },
   {
    "id": 19794,
    "type": "message",
    "date": "2023-04-30",
    "date_unixtime": "1682826966",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "339",
    "dicos": "66",
    "catagory": "Clothing",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   AEROPOSTALE Men T-Shirt✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oVRveJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oXedCU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NpMzJg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Nnkbam"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LH7pm3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44rNBKT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LHOjvO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NphBAD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44h4zLw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lj8lvx"
     },
     {
      "type": "plain",
      "text": "\n\n @339\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/81IW7NhrOxL._UX522_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Clothing"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Clothing & Accessories \n                Men \n                T-Shirts & Polos \nPryce339 \nDicos66 \n\n❌Regular Price  : ₹  850  /- 😱"
     }
    ]
   },
   {
    "id": 19795,
    "type": "message",
    "date": "2023-04-30",
    "date_unixtime": "1682827066",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "2999",
    "dicos": "40",
    "catagory": "Watches",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Helix Timex Metalfit SPO2 smartwatch with Full Metal Body and Touch to Wake Feature, HRM, Sleep & Activity Tracker✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42bAwDc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nnjjbb"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44i1bAn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44cCmpj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LE2Dpc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LGoe0k"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LH7CWn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LE46vS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ndQCNU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AGeDAo"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@2999"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71kBTK2unUL._UX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Watches"
     },
     {
      "type": "plain",
      "text": "\n\n                Fashion \n                Watches \n                Smartwatches & Activity tracker \nPryce2999 \nDicos40 \n\n❌Regular Price  : ₹  4199  /- 😱"
     }
    ]
   },
   {
    "id": 19796,
    "type": "message",
    "date": "2023-04-30",
    "date_unixtime": "1682827192",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "740",
    "dicos": "48",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Bajaj 10W B22D Led Cool Day Light Linear Bulb, Pack of 3✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AEPfef"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ndQMou"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3n8Dv0F"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HrXMF7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vk8rHN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AFQTMB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42aq5jn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HpqdDC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HpU1jC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HtT6yz"
     },
     {
      "type": "plain",
      "text": "\n\n @740\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51YfC1qYC5L._SY355_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Indoor Lighting \n                Light Bulbs \nPryce740 \nDicos48 \n\n❌Regular Price  : ₹1250    /- 😱"
     }
    ]
   },
   {
    "id": 19797,
    "type": "message",
    "date": "2023-04-30",
    "date_unixtime": "1682827322",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "423",
    "dicos": "74",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   ExclusiveLane 'Namaste Handpainted Cow' Wall Door Hanging for Home Decor Items for Living Room & Kitchen Wall Decor in Iron✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LCm6GR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41QXTlZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40KkbEA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NryGdy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oYqkQ7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vpayu0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LI5Sw6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vfyj7J"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LG5ii5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LGnF6D"
     },
     {
      "type": "plain",
      "text": "\n\n @423\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71801XkyOOL._SY355_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Artwork \n                 Wall Sculptures \nPryce423 \nDicos74 \n\n❌Regular Price  : ₹ 1250   /- 😱"
     }
    ]
   },
   {
    "id": 19798,
    "type": "message",
    "date": "2023-04-30",
    "date_unixtime": "1682827449",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "626",
    "dicos": "74",
    "catagory": "Health",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   VGR V-935 Professional Hair Trimmer Runtime: 100 min Trimmer for Men (BLUE)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AES9jb"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vl7fUI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40KUROB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HpUdPS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40PvgEg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VjUIke"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hpercl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HlkoqX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HrMjFD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Li5keP"
     },
     {
      "type": "plain",
      "text": "\n\n @626\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51Ou8l0W5WL._SY450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Health"
     },
     {
      "type": "plain",
      "text": " & Personal Care\n\n                Health & Personal Care \n                Personal Care \n                 Shaving, Waxing & Beard Care \nPryce626 \nDicos74 \n\n❌Regular Price  : ₹ 1199   /- 😱"
     }
    ]
   },
   {
    "id": 19799,
    "type": "message",
    "date": "2023-04-30",
    "date_unixtime": "1682827580",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "792",
    "dicos": "50",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Bajaj Acrylonitrile Butadiene Styrene Softlite Led Rc Table Lamp 2W (Pack of 1, White)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/429u2op"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oOOFId"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40Q9Nen"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41USMRF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LFYTDG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44i1r2j"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/4225xd2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/429u4N3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42aoA4Q"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/424cgmo"
     },
     {
      "type": "plain",
      "text": "\n\n @792\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/41KVkWEn6ML._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Indoor Lighting \n                Light Bulbs \nPryce792 \nDicos50 \n\n❌Regular Price  : ₹  1450  /- 😱"
     }
    ]
   },
   {
    "id": 19800,
    "type": "message",
    "date": "2023-04-30",
    "date_unixtime": "1682827709",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "200",
    "dicos": "33",
    "catagory": "Beauty",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Clean & Clear Foaming Facewash for Oily Skin, Brown, 240ml✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LiPX5Y"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lhvr5y"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LkdraJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LHceM2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NqGjRk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oYqLKf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40YageQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44qmsYG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LGipQo"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nnjXFD"
     },
     {
      "type": "plain",
      "text": "\n\n @200\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51cJ-IDx5PL._SY450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Beauty"
     },
     {
      "type": "plain",
      "text": "\n\n                Beauty \n                Skin Care \n                Face \nPryce200 \nDicos33 \n\n❌Regular Price  : ₹ 290   /- 😱"
     }
    ]
   },
   {
    "id": 19801,
    "type": "message",
    "date": "2023-04-30",
    "date_unixtime": "1682827839",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "177",
    "dicos": "75",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   SYSKA B22D LED Bulb SSK-SRL - 9W, Pack of 2 (Cool Day Light)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NsicBJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NqGDQ2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AHy0sW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ACrROu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ACrOlM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LdHW22"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ng80S9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/4262Gj6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AHdjNw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vh3u2e"
     },
     {
      "type": "plain",
      "text": "\n\n @177\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/716F-aiWrpL._SY355_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Indoor Lighting \n                Light Bulbs \nPryce177 \nDicos75 \n\n❌Regular Price  : ₹480    /- 😱"
     }
    ]
   },
   {
    "id": 19802,
    "type": "message",
    "date": "2023-04-30",
    "date_unixtime": "1682827972",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "250",
    "dicos": "75",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Philips Ace Saver 9 Watt LED Bulb, Base B22 (Cool Day Light), Pack of 2✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AIoYvH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LFo0Xb"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41NvGMF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hr7hEJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41USWbJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://www.amazon.in/dp/B09RQK81B9?l=&amp;linkCode=sl1&amp;tag=avi1deals6-21&amp;linkId=bec8701caa293272f707fe3895efdf0b&amp;language=en_IN&amp;ref_=as_li_ss_tl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/427u0NZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oKEyEc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VgJ3CA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nfIcpj"
     },
     {
      "type": "plain",
      "text": "\n\n @250\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61KP7EtFyQL._SY355_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Indoor Lighting \n                Light Bulbs \nPryce250 \nDicos75 \n\n❌Regular Price  : ₹  850  /- 😱"
     }
    ]
   },
   {
    "id": 19803,
    "type": "message",
    "date": "2023-04-30",
    "date_unixtime": "1682828103",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1049",
    "dicos": "75",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Nirlon Aluminium Cookware Set, 3-Pieces, Red/Black, 2.6mm_FT13_FP10_AC✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40PvH1m"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41PYUKO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AFRGx3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AHyyiu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ACss2G"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HN8I0v"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VfumzS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HpHmgE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VeP5E1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HpeVPH"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1049"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/81bKZeJsrVL._SX355_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Dining \n                Cookware \nPryce1049 \nDicos75 \n\n❌Regular Price  : ₹ 3499   /- 😱"
     }
    ]
   },
   {
    "id": 19804,
    "type": "message",
    "date": "2023-04-30",
    "date_unixtime": "1682828233",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "649",
    "dicos": "35",
    "catagory": "Shoes",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Sparx mens Sd9039g Sneakers✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44cjrej"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vpbpec"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hq3ibc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HlpMdK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nhraam"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VhSxgW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NpiF7B"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NomSbK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LqOLO2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3n8EoGx"
     },
     {
      "type": "plain",
      "text": "\n\n @649\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51e48JV2GfL._UY500_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Shoes"
     },
     {
      "type": "plain",
      "text": " & Handbags\n\n                Shoes & Handbags \n                Shoes \n                Men's Shoes \nPryce649 \nDicos35 \n\n❌Regular Price  : ₹ 950   /- 😱"
     }
    ]
   },
   {
    "id": 19805,
    "type": "message",
    "date": "2023-04-30",
    "date_unixtime": "1682828363",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "159",
    "dicos": "60",
    "catagory": "Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Tizum Mouse Pad/ Computer Mouse Mat with Anti-Slip Rubber Base | Smooth Mouse Control | Spill-Resistant Surface for Laptop, Notebook, MacBook Pro, Gaming, Laser/ Optical Mouse, 9.4”x 7.9”, Black-Green✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NplVQt"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41QYOmr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LdI8yi"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/426Ra72"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44qmUWS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LE54s0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LE3K8m"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3niQVao"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44inSEC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LGVXH6"
     },
     {
      "type": "plain",
      "text": "\n\n @159\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71T4xJbYomL._SY450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Computers & Accessories \n                Accessories & Peripherals \n                Keyboards, Mice & Input Devices \nPryce159 \nDicos60 \n\n❌Regular Price  : ₹  350  /- 😱"
     }
    ]
   },
   {
    "id": 19806,
    "type": "message",
    "date": "2023-04-30",
    "date_unixtime": "1682828494",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "415",
    "dicos": "41",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   SYSKA 9W B22 6500K Cool Day Light Inverter Rechargeable Emergency Led Bulb Pack of 1✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Nxl5B8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Npit8E"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3naKCpk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/426C0yG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40Pwlfi"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AD7ctw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ACgYwe"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ADPuGj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ADm1MQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41PZh8a"
     },
     {
      "type": "plain",
      "text": "\n\n @415\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/717Yp2t-R9L._SX522_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Home & kitchen | Prime shopping days \n                Prime Units \nPryce415 \nDicos41 \n\n❌Regular Price  : ₹  650  /- 😱"
     }
    ]
   },
   {
    "id": 19807,
    "type": "message",
    "date": "2023-04-30",
    "date_unixtime": "1682828602",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "679",
    "dicos": "9",
    "catagory": "Clothing",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Max Boys Printed T-Shirt - Set of 3✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AGoCpk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LGlVKH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/4226fXK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Viayfa"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41QLUEL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HpOLwy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HtU79R"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VmjXlR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40RC30n"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VhSEZU"
     },
     {
      "type": "plain",
      "text": "\n\n @679\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51iSTO4QDrL._UX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Clothing"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Clothing & Accessories \n                Baby \n                Baby Boys \nPryce679 \nDicos9 \n\n❌Regular Price  : ₹ 720   /- 😱"
     }
    ]
   },
   {
    "id": 19808,
    "type": "message",
    "date": "2023-04-30",
    "date_unixtime": "1682828715",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1149",
    "dicos": "71",
    "catagory": "Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Ant Esports H1000 Pro Wired Over Ear Headphones with Mic (Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HN92ML"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oLJrge"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42bWPsK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HqpnXd"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HrnN7s"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oLJDvY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ACNmPl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AD7zEq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3n8EWfz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VpYSqL"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1149"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51iQBu-mARL._SY355_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Computers & Accessories \n                Accessories & Peripherals \n                PC Gaming Peripherals \nPryce1149 \nDicos71 \n\n❌Regular Price  : ₹ 3499   /- 😱"
     }
    ]
   },
   {
    "id": 19809,
    "type": "message",
    "date": "2023-04-30",
    "date_unixtime": "1682828822",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "26499",
    "dicos": "82",
    "catagory": "Health",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Lifelong LLM567 Fully Body Foldable Chair Massager with Recliner and Powerful 3D Back, Leg and Foot Rollers for Massage for Home|Massage Chair for Full Body Relaxation at Home (1 Year Warranty)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41MAuC0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VgvBia"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NqHznw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HrZkip"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oYrsTR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/415CeW5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LDEBe7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HnSTNr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40OKZDM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40OH8pZ"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@26499"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61CzYUuzwyL._SY450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Health"
     },
     {
      "type": "plain",
      "text": " & Personal Care\n\n                Health & Personal Care \n                Health Care \n                Massage & Relaxation \nPryce26499 \nDicos82 \n\n❌Regular Price  : ₹ 75999   /- 😱"
     }
    ]
   },
   {
    "id": 19810,
    "type": "message",
    "date": "2023-04-30",
    "date_unixtime": "1682828943",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "129",
    "dicos": "39",
    "catagory": "Tools",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Gala Steel Scrubber Combo Set (Pack of 6)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44rPgjB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44fgYzB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44hCjsg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oY2og4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NqHFLU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LlhN19"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/424d2Qk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LgqZEd"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LkInYh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nnQsDA"
     },
     {
      "type": "plain",
      "text": "\n\n @129\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/81kPDUxtx3S._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Tools"
     },
     {
      "type": "plain",
      "text": " & Home Improvement\n\n                Home & Kitchen \n                Home & kitchen | Prime shopping days \n                Prime Units \nPryce129 \nDicos39 \n\n❌Regular Price  : ₹ 200   /- 😱"
     }
    ]
   },
   {
    "id": 19811,
    "type": "message",
    "date": "2023-04-30",
    "date_unixtime": "1682829071",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "365",
    "dicos": "39",
    "catagory": "Beauty",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   WOW Skin Science Retinol Face Serum | Vitamin B3 & E | Skin Plumping, Boost Collagen, Anti Acne, Anti Aging, Restoration | Face Serum For Women & Men | Paraben Free | 30 ml✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lfnj5H"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/420NXpE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44hCoMA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41QZfgz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41R8I7o"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41UTP43"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3n8Fhil"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44ioHNI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3naLhHk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LdIODQ"
     },
     {
      "type": "plain",
      "text": "\n\n @365\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/813nNmMEfgL._SY450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Beauty"
     },
     {
      "type": "plain",
      "text": "\n\n                Beauty \n                Skin Care \n                Face \nPryce365 \nDicos39 \n\n❌Regular Price  : ₹ 550   /- 😱"
     }
    ]
   },
   {
    "id": 19812,
    "type": "message",
    "date": "2023-04-30",
    "date_unixtime": "1682829181",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1769",
    "dicos": "41",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Polycab Zoomer DLX High Speed 1200 mm 1 star rating Ceiling Fan (Luster Brown)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AEQTfV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AG8BQk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oOeA2B"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Ll7PNk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oZG8SE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lb3VXe"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40QHigw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://www.amazon.in/dp/B0BTHCYPQG?smid=AXOGFIT0PZZ7G&amp;th=1&amp;linkCode=sl1&amp;tag=avi1deals8-21&amp;linkId=f84421cfe5f84b1a922fdb7ca0b09da9&amp;language=en_IN&amp;ref_=as_li_ss_tl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ng0SoY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AGp9Ym"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1769"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61BlD+ott6L._SY355_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Heating, Cooling & Air Quality \n                Fans \nPryce1769 \nDicos41 \n\n❌Regular Price  : ₹  2499  /- 😱"
     }
    ]
   },
   {
    "id": 19813,
    "type": "message",
    "date": "2023-04-30",
    "date_unixtime": "1682829290",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "461",
    "dicos": "58",
    "catagory": "Clothing",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Miss Olive Women T-Shirt✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LGqcxK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LGqeWo"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AERdLF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40RzHi7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oXcXj7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40KWk7z"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HrSB8m"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ndSG8C"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40UFMds"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40RCCav"
     },
     {
      "type": "plain",
      "text": "\n\n @461\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71rbIHtkUnL._UY606_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Clothing"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Clothing & Accessories \n                Women \n                Western Wear \nPryce461 \nDicos58 \n\n❌Regular Price  : ₹ 899   /- 😱"
     }
    ]
   },
   {
    "id": 19814,
    "type": "message",
    "date": "2023-04-30",
    "date_unixtime": "1682829419",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "529",
    "dicos": "62",
    "catagory": "Clothing",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Miss Olive Women's Regular Shirt✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41PpvI6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41R8YDo"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HlqpnC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hr8tbb"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VkmP2T"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VkabRl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oXda5T"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41UU8Mf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VfcWDv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40KWHit"
     },
     {
      "type": "plain",
      "text": "\n\n @529\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/81IIWap7W8L._UY606_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Clothing"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Clothing & Accessories \n                Women \n                Western Wear \nPryce529 \nDicos62 \n\n❌Regular Price  : ₹  999  /- 😱"
     }
    ]
   },
   {
    "id": 19815,
    "type": "message",
    "date": "2023-04-30",
    "date_unixtime": "1682829524",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1560",
    "dicos": "74",
    "catagory": "Watches",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   French Connection Full Touch Smartwatch with 1.69'' Large Display, Heart Rate Monitor, Multiple Watch Faces, Unisex Smart Watch✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44hCPXe"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oUwaSM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nfHLeK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44fhnlB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44fx7Fl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HNab71"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LjsdOX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44ip62G"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LgrEpb"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40MocIx"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1560"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71OBiYrBIFL._UX342_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Watches"
     },
     {
      "type": "plain",
      "text": "\n\n                Fashion \n                Onam 2021 \n                Watches and fashion jewellery \nPryce1560 \nDicos74 \n\n❌Regular Price  : ₹  3799  /- 😱"
     }
    ]
   },
   {
    "id": 19816,
    "type": "message",
    "date": "2023-04-30",
    "date_unixtime": "1682829634",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1889",
    "dicos": "66",
    "catagory": "Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   TP-Link AC750 Wifi Range Extender | Up to 750Mbps | Dual Band WiFi Extender, Repeater, Wifi Signal Booster, Access Point| Easy Set-Up | Extends Wifi to Smart Home & Alexa Devices (RE200)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VxGHzH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ng18Es"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LI0rgH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LFRO6d"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40PEctp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42ec705"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/427Ykb3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42ec9oJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44qo58K"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lhf2yc"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1889"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/41eyLfRsPIL._SY450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Computers & Accessories \n                Networking Devices \n                 Repeaters & Extenders \nPryce1889 \nDicos66 \n\n❌Regular Price  : ₹ 4199   /- 😱"
     }
    ]
   },
   {
    "id": 19817,
    "type": "message",
    "date": "2023-04-30",
    "date_unixtime": "1682829744",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "69",
    "dicos": "83",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   MISTRONICS Spiral Charger Cable Protector Data Cable Saver Charging Cord Protective Cable Cover Set of 1 (4 Pieces)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3n8FQZv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42ed34x"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44cEvRT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LjamYD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/429vJCh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LQqm5X"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41N9Ucb"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lhfdtm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41Q0lca"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44hbCE0"
     },
     {
      "type": "plain",
      "text": "\n\n @69\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61eqW9cfk+L._SY450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Home Improvement \n                Electrical \n                Cord Management \nPryce69 \nDicos83 \n\n❌Regular Price  : ₹  250  /- 😱"
     }
    ]
   },
   {
    "id": 19818,
    "type": "message",
    "date": "2023-04-30",
    "date_unixtime": "1682829874",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "59",
    "dicos": "70",
    "catagory": "Sports",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   SPIRO Jumping Skipping Rope with Comfortable PVC, Pencil Shape Handle for Weight REDUCING/Warm-UP/Gym/Sports & Gym Fitness.✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41MBru4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AEUQ4d"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40RA5NB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LjsykH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ncNB0r"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40ScV9w"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ndSUwu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LGqGE4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AFNmxS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AHzQtQ"
     },
     {
      "type": "plain",
      "text": "\n\n @59\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61p7ucQpvHL._SY606_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Sports"
     },
     {
      "type": "plain",
      "text": ", Fitness & Outdoors\n\n                Sports, Fitness & Outdoors \n                Exercise & Fitness \n                Accessories \nPryce59 \nDicos70 \n\n❌Regular Price  : ₹ 140   /- 😱"
     }
    ]
   },
   {
    "id": 19819,
    "type": "message",
    "date": "2023-04-30",
    "date_unixtime": "1682829992",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "799",
    "dicos": "69",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   pTron Bassbuds Duo in Ear Earbuds with 32Hrs Total Playtime, Bluetooth 5.1 Wireless Headphones, Stereo Audio, Touch Control TWS, with Mic, Type-C Fast Charging, IPX4 & Voice Assistance (Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40RCY0P"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HpPJZI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hp2RhF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hq4BqC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41QMXVd"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vfw4kM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VhPDJ3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42edqvX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HqAcsB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3niTtoT"
     },
     {
      "type": "plain",
      "text": "\n\n @799\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51ZT3aMrJIL._SX522_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Headphones, Earbuds & Accessories \n                Headphones \nPryce799 \nDicos69 \n\n❌Regular Price  : ₹  1999  /- 😱"
     }
    ]
   },
   {
    "id": 19820,
    "type": "message",
    "date": "2023-04-30",
    "date_unixtime": "1682830102",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "498",
    "dicos": "67",
    "catagory": "Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   MATTSPY Bluetooth Smart Watch for Men Women, Touch Screen for Android iOS Phones Wrist Phone Watch, Daily Activity Tracker, Heart Rate Sensor, BP Monitor, Sports Watch - Black✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NplHZR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lm7CcX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oSA1j9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hq4SKa"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NsNjNw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NkjE9j"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LQqEd3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LIJVNr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oV9YYL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Nlyj3V"
     },
     {
      "type": "plain",
      "text": "\n\n @498\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61icsCcbdKL._SY355_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Electronics \n                Wearable Technology \n                 Smart Watches \nPryce498 \nDicos67 \n\n❌Regular Price  : ₹ 1199   /- 😱"
     }
    ]
   },
   {
    "id": 19821,
    "type": "message",
    "date": "2023-04-30",
    "date_unixtime": "1682830212",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "39",
    "dicos": "NaN",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   E-COSMOS 5V 1.2W Portable Flexible USB LED Light (Colours May Vary, Small, EC-POF1, Plastic)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VknAcf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NxmHea"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3n8GjuJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NkjQFz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/426Dw3Q"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/424uu7o"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oYtdQX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/428M4Hp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42bKSTx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42aqKS0"
     },
     {
      "type": "plain",
      "text": "\n\n @39\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51IEbBI7qBL._SY450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Computers & Accessories \n                Accessories & Peripherals \n                USB Gadgets \nPryce39 \nDicosNaN"
     }
    ]
   },
   {
    "id": 19822,
    "type": "message",
    "date": "2023-04-30",
    "date_unixtime": "1682830319",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "599",
    "dicos": "78",
    "catagory": "All",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   VIMAL JONNEY Men Regular Fit Trackpants Pack of 3 Multicolor✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LljgEH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/427Lbiu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lm7v0Q"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LmQX8S"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41H49MZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41PYGna"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oR1FNw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LGqhkX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ADnFOw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3naM4YO"
     },
     {
      "type": "plain",
      "text": "\n\n @599\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61MXQ+vHwjL._UX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#All"
     },
     {
      "type": "plain",
      "text": "\n\n                Clothing & Accessories \n                Men \n                Sportswear \nPryce599 \nDicos78 \n\n❌Regular Price  : ₹ 1799   /- 😱"
     }
    ]
   },
   {
    "id": 19823,
    "type": "message",
    "date": "2023-04-30",
    "date_unixtime": "1682830435",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "13499",
    "dicos": "46",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Onida 80 cm (32 inches) HD Ready Smart LED Fire TV 32HIF3 (Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AYbQCV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ADNqy7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44iq7rw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AKr91y"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LGnFDJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40RAzDp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LFUa4Z"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LDG6cf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LHeLG2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3niTCIX"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@13499"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71K6W3QsL2L._SX450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Home Theater, TV & Video \n                Televisions \nPryce13499 \nDicos46 \n\n❌Regular Price  : ₹ 20999   /- 😱"
     }
    ]
   },
   {
    "id": 19824,
    "type": "message",
    "date": "2023-04-30",
    "date_unixtime": "1682830546",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1999",
    "dicos": "71",
    "catagory": "Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   boAt Blaze Smart Watch with 1.75\" HD Display, Fast Charge, Apollo 3 Blue Plus Processor, 24x7 Heart Rate & SpO2 Monitor, Multiple Watch Faces, Multiple Sports Modes & 7 Days Battery Life(Active Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hq54Jo"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40QI9hi"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ACODpB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Ljb8ov"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40KmXtu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41P9HVP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41QXKyI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AGaCMo"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44iDDuX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44rQF9R"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1999"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61mh6YHB5fL._SX522_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Electronics \n                Wearable Technology \n                 Smart Watches \nPryce1999 \nDicos71 \n\n❌Regular Price  : ₹ 4799   /- 😱"
     }
    ]
   },
   {
    "id": 19825,
    "type": "message",
    "date": "2023-04-30",
    "date_unixtime": "1682830658",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "342",
    "dicos": "62",
    "catagory": "Clothing",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Gear Superior 16L Water Resistant School Bag//Backpack/College Bag for Men/Women - Orange Black✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hrp8eu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vh5R58"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VxHxwl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VgLo0k"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LqQSRY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VfdQ2R"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LE7fMc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LDyOoS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nfLo4h"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HpQulw"
     },
     {
      "type": "plain",
      "text": "\n\n @342\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/91F36KkXG3L._UY606_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Clothing"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Bags, Wallets and Luggage \n                Bags & Backpacks \n                Backpacks \nPryce342 \nDicos62 \n\n❌Regular Price  : ₹ 850   /- 😱"
     }
    ]
   },
   {
    "id": 19826,
    "type": "message",
    "date": "2023-04-30",
    "date_unixtime": "1682830791",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "333",
    "dicos": "72",
    "catagory": "Clothing",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Cazibe Women's Regular Fit Men's T-Shirt (CZ11105_Red/S.Green M)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hln8Vh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oORE3n"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NskReH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41SIWQg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41QcZrU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Npkv8M"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44fD05j"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/424eHW4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LIWJU4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42ee8JD"
     },
     {
      "type": "plain",
      "text": "\n\n @333\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/81n0Qp78i8L._UX522_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Clothing"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Clothing & Accessories \n                Men \n                T-Shirts & Polos \nPryce333 \nDicos72 \n\n❌Regular Price  : ₹  1099  /- 😱"
     }
    ]
   },
   {
    "id": 19827,
    "type": "message",
    "date": "2023-04-30",
    "date_unixtime": "1682830927",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "427",
    "dicos": "22",
    "catagory": "Beauty",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   POND'S Triple Vitamin Moisturising Body Lotion 600 ml✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44e4V5K"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44bup3D"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vgxvzk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40MpqU9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vh6cEW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vh6eg2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VgxBaa"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lm7Twk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ninrJu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vmdk3c"
     },
     {
      "type": "plain",
      "text": "\n\n @427\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/41z9LG4aZnL._SY450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Beauty"
     },
     {
      "type": "plain",
      "text": "\n\n                Beauty \n                Bath & Body \n                Body Washes \nPryce427 \nDicos22 \n\n❌Regular Price  : ₹  530  /- 😱"
     }
    ]
   },
   {
    "id": 19828,
    "type": "message",
    "date": "2023-04-30",
    "date_unixtime": "1682831062",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1299",
    "dicos": "64",
    "catagory": "Clothing",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Skybags Komet 45 cms Royal Blue Laptop Backpack✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/427LXfo"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ndU23c"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ng2L52"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HlrRX6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hq5O18"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HrpMsq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NpkVf5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HnV3g1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HpXxKQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40KuJnf"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1299"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/91TB4AOdP6L._UX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Clothing"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Computers & Accessories \n                Accessories & Peripherals \n                Laptop Accessories \nPryce1299 \nDicos64 \n\n❌Regular Price  : ₹ 3100   /- 😱"
     }
    ]
   },
   {
    "id": 19829,
    "type": "message",
    "date": "2023-04-30",
    "date_unixtime": "1682831193",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "749",
    "dicos": "46",
    "catagory": "Health",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   VGR V-596 Professional Electric Hair Curling Wand | Stylish Hair Styling Professional Hair Curler with 200° C Max Heat Setting✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ncUqix"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40MpMdr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40OJ8P1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40SemEW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AByXD3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AGi0r2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LiSL30"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ADO8eL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AGi4qM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41QYljW"
     },
     {
      "type": "plain",
      "text": "\n\n @749\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61Cn8JSpGzL._SX522_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Health"
     },
     {
      "type": "plain",
      "text": " & Personal Care\n\n                Beauty \n                Hair Care \n                Hair Styling Tools \nPryce749 \nDicos46 \n\n❌Regular Price  : ₹  1250  /- 😱"
     }
    ]
   },
   {
    "id": 19830,
    "type": "message",
    "date": "2023-04-30",
    "date_unixtime": "1682831323",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "999",
    "dicos": "71",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Noise Buds Smart Truly Wireless in Ear Earbuds with 18H Playtime,10mm Driver, Full Touch Control, Hyper Sync, and BT v5.0 (Jet Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41OA3r3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VeREWF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LFTEUF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vlatri"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LG8NoJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VhUWZ0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LFTI6R"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/426EpJI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VjXSo6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oSBVR1"
     },
     {
      "type": "plain",
      "text": "\n\n @999\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51IemHi5XdL._SX522_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Headphones, Earbuds & Accessories \n                Headphones \nPryce999 \nDicos71 \n\n❌Regular Price  : ₹ 2396   /- 😱"
     }
    ]
   },
   {
    "id": 19831,
    "type": "message",
    "date": "2023-04-30",
    "date_unixtime": "1682831455",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "799",
    "dicos": "60",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Mivi Play Bluetooth Speaker with 12 Hours Playtime. Wireless Speaker Made in India with Exceptional Sound Quality, Portable and Built in Mic-Blue, One Size✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nhtb6q"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3njz4jt"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NposKt"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HnVdE9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HqBvYx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Nlz3WL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/426TESU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41PrpZg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41MCL00"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41SJCFi"
     },
     {
      "type": "plain",
      "text": "\n\n @799\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61Dw2jJTurS._SY355_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Home Audio \n                Speakers \nPryce799 \nDicos60 \n\n❌Regular Price  : ₹ 1550  /- 😱"
     }
    ]
   },
   {
    "id": 19832,
    "type": "message",
    "date": "2023-04-30",
    "date_unixtime": "1682831567",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "275",
    "dicos": "58",
    "catagory": "Sports",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   FitBox Sports Exclusive 5kg Diamond Dumbbells Set (2.5kg x 2) Exercise and Fitness Training Kit✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41Lbpr8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42sdOan"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oSn6xG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/4228RF2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/426EQ6O"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/446IzTE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42c3Nxw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/4280h7n"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oLM55E"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VmmFrx"
     },
     {
      "type": "plain",
      "text": "\n\n @275\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51Wexq2UEtL._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Sports"
     },
     {
      "type": "plain",
      "text": ", Fitness & Outdoors\n\n                Sports, Fitness & Outdoors \n                Exercise & Fitness \n                Strength Training Equipment \nPryce275 \nDicos58 \n\n❌Regular Price  : ₹  550  /- 😱"
     }
    ]
   },
   {
    "id": 19833,
    "type": "message",
    "date": "2023-04-30",
    "date_unixtime": "1682831675",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "230",
    "dicos": "79",
    "catagory": "Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Travel Blue 968 3-in-1 USB 2.0 Sync and Charge Cable (White)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vfr8fH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VfezRD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44cG0Q1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ALKejX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ndgObq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ndUuOW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AIrGRT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://www.amazon.in/dp/B00S161YNM?&amp;linkCode=sl1&amp;tag=avi1deals8-21&amp;linkId=4a552caedcede6129bc22769105aead4&amp;language=en_IN&amp;ref_=as_li_ss_tl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42d9A6j"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hrqjuq"
     },
     {
      "type": "plain",
      "text": "\n\n @230\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/41f+gnjS+FL._SX522_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Computers & Accessories \n                Accessories & Peripherals \n                Cables & Accessories \nPryce230 \nDicos79 \n\n❌Regular Price  : ₹ 899   /- 😱"
     }
    ]
   },
   {
    "id": 19834,
    "type": "message",
    "date": "2023-04-30",
    "date_unixtime": "1682831808",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "4999",
    "dicos": "71",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Skullcandy Riff Wireless 2 on-Ear Headphones Black✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lb8BfW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3niTxVK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oOSrBn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40RBAvd"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AETq9V"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41Nz7D3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40Pai8C"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44fjdmv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44hF1xW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LILgUt"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@4999"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71cKqKY3dmL._SX522_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Headphones, Earbuds & Accessories \n                Headphones \nPryce4999 \nDicos71 \n\n❌Regular Price  : ₹ 12596   /- 😱"
     }
    ]
   },
   {
    "id": 19835,
    "type": "message",
    "date": "2023-04-30",
    "date_unixtime": "1682831940",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "289",
    "dicos": "40",
    "catagory": "Beauty",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Park Avenue Men's Deo, Alter Ego Signature, 100g (Pack of 2)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40PFYe3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LqRRl8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40QJGnu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lm9hiH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LhE2oL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ADoTcA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LgTYYp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LEeSlQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AGbwZi"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42295Mo"
     },
     {
      "type": "plain",
      "text": "\n\n @289\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/81P8eOv3EGL._SY450_PIbundle-2,TopRight,0,0_AA450SH20_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Beauty"
     },
     {
      "type": "plain",
      "text": "\n\n                Beauty \n                Bath & Body \n                Deodorants & Antiperspirants \nPryce289 \nDicos40 \n\n❌Regular Price  : ₹ 450   /- 😱"
     }
    ]
   },
   {
    "id": 19836,
    "type": "message",
    "date": "2023-04-30",
    "date_unixtime": "1682832046",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "166",
    "dicos": "17",
    "catagory": "Beauty",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Charmis Deep Radiance Face Wash 150ml with Vitamin C, Hyaluronic Acid and Salicylic Acid, Removes 99.9% Acne causing bacteria and gives Hydrated Skin✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AGbFfi"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NqgIYL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VhVpdI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Npg6T3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NkKxtK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/424fpTe"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42d9Imj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42bENGK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ngC2oU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41QmbfJ"
     },
     {
      "type": "plain",
      "text": "\n\n @166\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61PQnUkg8LL._SY450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Beauty"
     },
     {
      "type": "plain",
      "text": "\n\n                Beauty \n                Skin Care \n                Face \nPryce166 \nDicos17 \n\n❌Regular Price  : ₹ 190   /- 😱"
     }
    ]
   },
   {
    "id": 19837,
    "type": "message",
    "date": "2023-04-30",
    "date_unixtime": "1682832175",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "39",
    "dicos": "NaN",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   E-COSMOS 5V 1.2W Portable Flexible USB LED Light (Colours May Vary, Small, EC-POF1, Plastic)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HlgVZd"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oSBExh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44fzyYv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41Qmozx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VjKBvB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NkKPkk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44fEzAd"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vie3lO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LDAcYC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VlbjUY"
     },
     {
      "type": "plain",
      "text": "\n\n @39\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51IEbBI7qBL._SY450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Computers & Accessories \n                Accessories & Peripherals \n                USB Gadgets \nPryce39 \nDicosNaN"
     }
    ]
   },
   {
    "id": 19838,
    "type": "message",
    "date": "2023-04-30",
    "date_unixtime": "1682832308",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "5699",
    "dicos": "68",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Coirfit Folding Sofa Cum Bed - Perfect for Guests - Jute Fabric Washable Cover - Grey | 3' X 6' Feet✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40RBVht"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HpeJjB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LnhqTU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42efaoZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LqSkDU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LqSwmC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LGm8xm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44gztUE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42c4E1c"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42c4E1c"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@5699"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/41fSyPAU5OL._SY300_SX300_QL70_FMwebp_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Home & Kitchen \n                Furniture \n                Living Room Furniture \nPryce5699 \nDicos68 \n\n❌Regular Price  : ₹ 16596   /- 😱"
     }
    ]
   },
   {
    "id": 19839,
    "type": "message",
    "date": "2023-04-30",
    "date_unixtime": "1682832438",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "799",
    "dicos": "73",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀 truke Air Buds Lite True Wireless in Ear Earbuds with 48 Hours Playtime, Gaming Mode, Enviornomental Noise Cancellation (ENC), Auto Play/Pause, AAC Codec, Bluetooth 5.1, IPX4 (Yellow)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LI9Gxo"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41Ncdfl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AFPGF6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ndLV6H"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oTmHv0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44cs34C"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lmgxv3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LgUlSN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nnoacp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LmSziW"
     },
     {
      "type": "plain",
      "text": "\n\n @799\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61FA5YUYFjL._SX466_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Headphones, Earbuds & Accessories \n                Headphones \nPryce799 \nDicos73 \n\n❌Regular Price  : ₹ 1899   /- 😱\n,l"
     }
    ]
   },
  {
    "id": 19532,
    "type": "message",
    "date": "2023-04-29",
    "date_unixtime": "1682745018",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "65",
    "dicos": "67",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀Kuber Industries Cotton Oval Door Mat for Porch/Kitchen/Bathroom/Laundry Room,(Green) 54KM3952   ✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Nk6d9p"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NqwDWX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VffKQO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3No0UG6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3n5q8OM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3n6MNKB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NixxVv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NoopyD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Nm4pg0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44eKRAo"
     },
     {
      "type": "plain",
      "text": "\n\n @65\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71+jAGj0sQL._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Home Furnishing \n                Carpets & Rugs \nPryce65 \nDicos67 \n \n❌Regular Price  : ₹ 179   /- 😱"
     }
    ]
   },
   {
    "id": 19533,
    "type": "message",
    "date": "2023-04-29",
    "date_unixtime": "1682745125",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "99",
    "dicos": "90",
    "catagory": "Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   STRIFF Mpad Mouse Mat 230X190X3mm Gaming Mouse Pad, Non-Slip Rubber Base, Waterproof Surface, Premium-Textured, Compatible with Laser and Optical Mice(Universe Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/427IWeR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40KyCbD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AzMz1D"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/447FpiJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40SRRzQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AFLohc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ABzXXG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AFP4iI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oUbw5d"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VdC7Gt"
     },
     {
      "type": "plain",
      "text": "\n\n @99\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61zroMjd9FL._SY450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Computers & Accessories \n                Accessories & Peripherals \n                Keyboards, Mice & Input Devices \nPryce99 \nDicos90 \n\n❌Regular Price  : ₹ 499   /- 😱"
     }
    ]
   },
   {
    "id": 19534,
    "type": "message",
    "date": "2023-04-29",
    "date_unixtime": "1682745232",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "349",
    "dicos": "73",
    "catagory": "Health",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Shatras Pink Lily Liquid Detergent 5 Liter, Laundry Liquid for Fabric Care, Suitable for Top-Load and Front Load Machine and Hand Wash, Concentrated Laundry Detergent (5L)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oTjcET"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oUqMz6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44dKRAr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oRvu0u"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/449GiHj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nbrQOo"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AEjmTi"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44dQ0Zb"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42cmvp7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LkekAb"
     },
     {
      "type": "plain",
      "text": "\n\n @349\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71PwZE8u2YL._SY450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Health"
     },
     {
      "type": "plain",
      "text": " & Personal Care\n\n                Health & Personal Care \n                Household Supplies \n                Laundry \nPryce349 \nDicos73 \n\n❌Regular Price  : ₹ 1099   /- 😱"
     }
    ]
   },
   {
    "id": 19535,
    "type": "message",
    "date": "2023-04-29",
    "date_unixtime": "1682745341",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "397",
    "dicos": "75",
    "catagory": "Clothing",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Fusefit Women Yoga Pants✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VdIny0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VknpO6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oTTrEt"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nb6AIJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LEAApJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Ljt2an"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LElUqt"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HnGjO4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41OYDrx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/429OjKu"
     },
     {
      "type": "plain",
      "text": "\n\n @397\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71ZShWikv7L._UX522_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Clothing"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Clothing & Accessories \n                Women \n                Sportswear \nPryce397 \nDicos75 \n\n❌Regular Price  : ₹ 999   /- 😱"
     }
    ]
   },
   {
    "id": 19536,
    "type": "message",
    "date": "2023-04-29",
    "date_unixtime": "1682745469",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1031",
    "dicos": "40",
    "catagory": "Tools",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   SYSKA PVC WFGN511003 FR-1 sq mm Cables (90 m, green)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Ljt6H9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/424dzlu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oU7vhj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NnxpEg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41T6O6j"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40OSXwk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NiWbW0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LBSb0v"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41JnZXU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41Pa6HC"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1031"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/81vLPsqUkJL._SY355_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Tools"
     },
     {
      "type": "plain",
      "text": " & Home Improvement\n\n                Home Improvement \n                Electrical \n                 Electrical Wire \nPryce1031 \nDicos40 \n\n❌Regular Price  : ₹ 1499   /- 😱"
     }
    ]
   },
   {
    "id": 19537,
    "type": "message",
    "date": "2023-04-29",
    "date_unixtime": "1682745598",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "191",
    "dicos": "45",
    "catagory": "Beauty",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Nivea Soft Berry Blossom, Light Moisturizer For Face, Hand & Body, Instant Hydration Non-Greasy Cream With Vitamin E & Jojoba Oil, 200 Ml✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41OaTc0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41LR98F"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41OsOPQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AEH15U"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LdCxYV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AzMTgR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oU7UAl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AzMV8t"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LAC6sV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LjtpBN"
     },
     {
      "type": "plain",
      "text": "\n\n @191\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51vp7-iymeL._SY450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Beauty"
     },
     {
      "type": "plain",
      "text": "\n\n                Beauty \n                Skin Care \n                Face \nPryce191 \nDicos45 \n\n❌Regular Price  : ₹ 310   /- 😱"
     }
    ]
   },
   {
    "id": 19538,
    "type": "message",
    "date": "2023-04-29",
    "date_unixtime": "1682745707",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "425",
    "dicos": "24",
    "catagory": "Beauty",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Nivea Frangipani And Oil Shower Gel, 250ml And Nivea Naturally Good, Natural Avocado Body Lotion, 200ml✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LgYkPo"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Ho1WOy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oV9cL4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nfIooy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nb0Wq3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HnGHMw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44ayJQL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nb0OH5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oXTheY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44dvuYD"
     },
     {
      "type": "plain",
      "text": "\n\n @425\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61t+XfdgzZL._SY450_PIbundle-2,TopRight,0,0_AA450SH20_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Beauty"
     },
     {
      "type": "plain",
      "text": "\n\n                Beauty \n                Bath & Body \n                Cleansers \nPryce425 \nDicos24 \n\n❌Regular Price  : ₹  499  /- 😱"
     }
    ]
   },
   {
    "id": 19539,
    "type": "message",
    "date": "2023-04-29",
    "date_unixtime": "1682745841",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1249",
    "dicos": "69",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   BSB HOME 300 GSM Cotton Floral Printed AC/All Season/Summer Comforter Quilt Set King Size Double Bed with 1 Flat bedsheet-90x100 inch and Two Pillow Covers II 4 Pcs Comforter Set -Pastle Green✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Nnv4Jd"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3n5qO6M"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40R7OGN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42qsjLR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VeIPMm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ViZIp2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oNfKvj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AEH9Cq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/446EYVP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AEn2o0"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1249"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51qwtpFeggL._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Home Furnishing \n                Bedding & Linen \nPryce1249 \nDicos69 \n\n❌Regular Price  : ₹  3100  /- 😱"
     }
    ]
   },
   {
    "id": 19540,
    "type": "message",
    "date": "2023-04-29",
    "date_unixtime": "1682745975",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "349",
    "dicos": "82",
    "catagory": "Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   ZEBRONICS Zeb-Jaguar Wireless Mouse, 2.4GHz with USB Nano Receiver, High Precision Optical Tracking, 4 Buttons, Plug & Play, Ambidextrous, for PC/Mac/Laptop (Black+Grey)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/446F0gp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AFpuKK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AAGEcu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AFPHJ6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AAGH8a"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3n8ikMb"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NlDwZw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oUc7DZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3n72KAp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3n5qVze"
     },
     {
      "type": "plain",
      "text": "\n\n @349\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/41nclnxOSLL._SX466_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Computers & Accessories \n                Accessories & Peripherals \n                Keyboards, Mice & Input Devices \nPryce349 \nDicos82 \n\n❌Regular Price  : ₹ 999   /- 😱"
     }
    ]
   },
   {
    "id": 19541,
    "type": "message",
    "date": "2023-04-29",
    "date_unixtime": "1682746083",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1871",
    "dicos": "11",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   LED 30W PK 3✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LCjOrc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/429Zl2j"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LBNuou"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LGphgW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LCrdqG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LeFTLb"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44q59Hh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/4268WHr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/428Fxww"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VkxMkL"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1871"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/41i3ROb0SVL._SX355_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Indoor Lighting \n                Light Bulbs \nPryce1871 \nDicos11 \n\n❌Regular Price  : ₹ 2000   /- 😱"
     }
    ]
   },
   {
    "id": 19542,
    "type": "message",
    "date": "2023-04-29",
    "date_unixtime": "1682746215",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "399",
    "dicos": "56",
    "catagory": "Clothing",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Chromozome Men Mens Tshirts✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LkrsW5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HlNG8E"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VdJ0aQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HlNHJK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oN2TcB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LhE5Rx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oPcy2m"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LklzYI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HnGXuY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oPcKyC"
     },
     {
      "type": "plain",
      "text": "\n\n @399\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71X7pa5hBTL._UX522_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Clothing"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Clothing & Accessories \n                Men \n                T-Shirts & Polos \nPryce399 \nDicos56 \n\n❌Regular Price  : ₹ 799   /- 😱"
     }
    ]
   },
   {
    "id": 19543,
    "type": "message",
    "date": "2023-04-29",
    "date_unixtime": "1682746347",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "399",
    "dicos": "60",
    "catagory": "Clothing",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Chromozome Men Mens Tshirts✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LgYCFY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nh7B1V"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41JoCke"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nb1pZl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HsaaVF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HpL4XA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LE3RRg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3No1YtA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40I9iTG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nb7DbD"
     },
     {
      "type": "plain",
      "text": "\n\n @399\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/81Cf+MBByrL._UX522_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Clothing"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Clothing & Accessories \n                Men \n                T-Shirts & Polos \nPryce399 \nDicos60 \n\n❌Regular Price  : ₹ 799   /- 😱"
     }
    ]
   },
   {
    "id": 19544,
    "type": "message",
    "date": "2023-04-29",
    "date_unixtime": "1682746478",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "199",
    "dicos": "80",
    "catagory": "Grocery",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Organic Box 100% Natural Premium 5 in 1 Mix Seeds + Raisins for Eating (Pumkin Chia Sunflower Flax Seed + Raisin) | Mix Seeds | Diet Snacks - 400gm✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NnvAH9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LEmQet"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LOCioN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40Qor5g"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oXTXkw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40OqRBt"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40NmXZI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40JbWZf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44azoBJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40OUWkk"
     },
     {
      "type": "plain",
      "text": "\n\n @199\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71nrHIY9M1L._SX466_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Grocery"
     },
     {
      "type": "plain",
      "text": " & Gourmet Foods\n\n                Grocery & Gourmet Foods \n                Dried Fruits, Nuts & Seeds \n                Nuts & Seeds \nPryce199 \nDicos80 \n\n❌Regular Price  : ₹ 499   /- 😱"
     }
    ]
   },
   {
    "id": 19545,
    "type": "message",
    "date": "2023-04-29",
    "date_unixtime": "1682746606",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1239",
    "dicos": "23",
    "catagory": "Tools",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Visko Tools Brass 2008 Royal Swan Neck Sink Tap - Faucet (Silver)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VipKsF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oTk3p5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vibmke"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NnuwTH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/444UGR8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VipNVt"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VnqwVu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vg2Uln"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/422HCKe"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42bijWD"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1239"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71NHzOIQJSL._SY606_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Tools"
     },
     {
      "type": "plain",
      "text": " & Home Improvement\n\n                Home Improvement \n                Kitchen & Bath Fixtures \n                Bathroom Fixtures \nPryce1239 \nDicos23 \n\n❌Regular Price  : ₹ 1550   /- 😱"
     }
    ]
   },
   {
    "id": 19546,
    "type": "message",
    "date": "2023-04-29",
    "date_unixtime": "1682746714",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1550",
    "dicos": "NaN",
    "catagory": "Sports",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   NIVIA NEO-4 Female Jacket, Polyester, Black✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ADZqQc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ABnHGu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/428FOzy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ADaVr7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AAH6Ye"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nc9ay2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ABBcpO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44flzlk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oUcwGv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LEmWTn"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1550"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61TrHxLZfjL._SY450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Sports"
     },
     {
      "type": "plain",
      "text": ", Fitness & Outdoors\n\n                Fashion \n                Sportsmania \n                Women \nPryce1550 \nDicosNaN"
     }
    ]
   },
   {
    "id": 19547,
    "type": "message",
    "date": "2023-04-29",
    "date_unixtime": "1682746827",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "791",
    "dicos": "60",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Portronics Power Plate 7 with 6 USB Port + 8 Power Sockets Smart Electric Universal Extension Board Multi Plug with 2500W, 3Mtr Cord Length, 2.1A USB Output(Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nc9xIW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NmD2SY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vd3wZf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3n73fKN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ndXACG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/446FGlX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oRqlWd"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oQfzzA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LiXxxb"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/446FJOF"
     },
     {
      "type": "plain",
      "text": "\n\n @791\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/41gg-4gu93L._SX300_SY300_QL70_FMwebp_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Power Accessories \n                 Extension Cords \nPryce791 \nDicos60 \n\n❌Regular Price  : ₹ 1699   /- 😱"
     }
    ]
   },
   {
    "id": 19548,
    "type": "message",
    "date": "2023-04-29",
    "date_unixtime": "1682746935",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1411",
    "dicos": "44",
    "catagory": "Shoes",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Footin Womens Alfa Running Ballet Flat✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42ahzke"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44gDMPQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vd3FMh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Viq1fb"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oRewzc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41JoKAe"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41QPTS2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41Otq86"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HpLayq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HpLayq"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1411"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71lnaiaQkhS._UX395_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Shoes"
     },
     {
      "type": "plain",
      "text": " & Handbags\n\n                Shoes & Handbags \n                Shoes \n                Women's Shoes \nPryce1411 \nDicos44 \n\n❌Regular Price  : ₹2199    /- 😱"
     }
    ]
   },
   {
    "id": 19549,
    "type": "message",
    "date": "2023-04-29",
    "date_unixtime": "1682747046",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "3190",
    "dicos": "74",
    "catagory": "Musical",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Nektar Impact GX61 Midi Keyboard✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nh81W3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41Mnb4I"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vg7kJ2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nh844b"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nm3AsS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lh78F0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Ljmfxs"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Nt3t9T"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LfOvkz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LlrSeC"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@3190"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/411NEdOcr3S._SY355_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Musical"
     },
     {
      "type": "plain",
      "text": " Instruments\n\n                Musical Instruments \n                Piano & Keyboard \n                 MIDI Keyboards & Equipments \nPryce3190 \nDicos74 \n\n❌Regular Price  : ₹ 7999   /- 😱"
     }
    ]
   },
   {
    "id": 19550,
    "type": "message",
    "date": "2023-04-29",
    "date_unixtime": "1682747175",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1049",
    "dicos": "NaN",
    "catagory": "Shoes",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   EEKEN Mens Casual Lightweight Mesh Sneakers by Paragon✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NmDcK4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LjmGrA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40NIRMk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HsaCTR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hnn3Am"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40R8I69"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41Pg7nL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41T8jBt"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HlOpXq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41ML641"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1049"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71c7iiqst8L._UX500_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Shoes"
     },
     {
      "type": "plain",
      "text": " & Handbags\n\n                Shoes & Handbags \n                Shoes \n                Men's Shoes \nPryce1049 \nDicosNaN"
     }
    ]
   },
   {
    "id": 19551,
    "type": "message",
    "date": "2023-04-29",
    "date_unixtime": "1682747303",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "3667",
    "dicos": "68",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Maharaja Whiteline Plastic Frostair 20 DLX Co-127 Air Cooler with Remote Control, White, 20 Liters✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LBO0Ts"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LEHxqQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hq7jN8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NvzGwW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LFnE31"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HpDBYm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HlOAC4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LEBLFF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NnMnKl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nhL3xZ"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@3667"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71cEHMjNiKL._SY741_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Heating, Cooling & Air Quality \n                Air Coolers \nPryce3667 \nDicos68 \n\n❌Regular Price  : ₹ 8999   /- 😱"
     }
    ]
   },
   {
    "id": 19552,
    "type": "message",
    "date": "2023-04-29",
    "date_unixtime": "1682747417",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "2899",
    "dicos": "71",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   AmazonBasics Aluminium Non-Stick Black Cookware Set - 15 Piece✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40NSo6b"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40NSpqL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ndY8bI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40Mn47W"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LOCMv7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LBOnNQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LCjRn2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40NSuL5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40NSvP9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oVsLD2"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@2899"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61x-NhdKBmL._SX355_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Dining \n                Cookware \nPryce2899 \nDicos71 \n\n❌Regular Price  : ₹ 8999   /- 😱"
     }
    ]
   },
   {
    "id": 19553,
    "type": "message",
    "date": "2023-04-29",
    "date_unixtime": "1682747552",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "10995",
    "dicos": "15",
    "catagory": "Musical",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Blue Yeti USB Microphone for Recording, Streaming, Gaming, Podcasting on PC and Mac, Condenser Mic for Laptop or Computer with Blue VO!CE Effects, Adjustable Stand, Plug and Play - Slate✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AEwBTS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nc0veX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/426DzfV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44buBA1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AEavAJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LHbEOA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LEnN6x"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AzOe7n"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ABy4KL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ABy4KL"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@10995"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61SijxwsG6L._SY355_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Musical"
     },
     {
      "type": "plain",
      "text": " Instruments\n\n                Musical Instruments \n                Microphones \n                 Condenser \nPryce10995 \nDicos15 \n\n❌Regular Price  : ₹12499    /- 😱"
     }
    ]
   },
   {
    "id": 19554,
    "type": "message",
    "date": "2023-04-29",
    "date_unixtime": "1682747684",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "4999",
    "dicos": "71",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Skullcandy Riff Wireless 2 on-Ear Headphones Black✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LG5uOk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/429PGJ8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Nk8amf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VkyUF1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44bPjzA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VdrFyN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/429PUjs"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oIT1k0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41YZPIK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oPdCTU"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@4999"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71cKqKY3dmL._SX522_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Headphones, Earbuds & Accessories \n                Headphones \nPryce4999 \nDicos71 \n\n❌Regular Price  : ₹ 11999   /- 😱"
     }
    ]
   },
   {
    "id": 19555,
    "type": "message",
    "date": "2023-04-29",
    "date_unixtime": "1682747814",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "8970",
    "dicos": "73",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   KORG Noise Cancelling Headphone -Black NC-Q1 BK✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VhVttV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oHA15p"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HpDFr4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VdrVxL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LiYaH3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lkn9tC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Nizxgt"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LkngFy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LdE8xT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LdE8xT"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@8970"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/81JqdeE1KwL._SY450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Musical Instruments \n                DJ & VJ Equipment \n                 DJ Headphones \nPryce8970 \nDicos73 \n\n❌Regular Price  : ₹ 21999   /- 😱"
     }
    ]
   },
   {
    "id": 19556,
    "type": "message",
    "date": "2023-04-29",
    "date_unixtime": "1682747949",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "3323",
    "dicos": "72",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Klaxon Tetra Coffee Table - Black✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Ll6iH0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41MLD61"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41T93GL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nh8OX1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ndWA1j"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oPdVy2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41QUt2w"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LE7Opf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41T99OD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Nk8kKn"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@3323"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71PF6QMhnxL._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Home & Kitchen \n                Furniture \n                Living Room Furniture \nPryce3323 \nDicos72 \n\n❌Regular Price  : ₹  8999   /- 😱"
     }
    ]
   },
   {
    "id": 19557,
    "type": "message",
    "date": "2023-04-29",
    "date_unixtime": "1682748069",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "509",
    "dicos": "83",
    "catagory": "Clothing",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Intellilens Square Blue Cut Computer Glasses for Eye Protection | Unisex, UV ProtectionZero Power, Anti Glare & Blue Light Filter Glasses✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AEl07q"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nb8usR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NFCqIz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/413U5N7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40KAAc1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40OrIlF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40PO86j"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40MwWyn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LFsxsK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LFsxsK"
     },
     {
      "type": "plain",
      "text": "\n\n @509\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51b1GOiEAQL._UX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Clothing"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Health & Personal Care \n                Health Care \n                Eye Care \nPryce509 \nDicos83 \n\n❌Regular Price  : ₹  1999  /- 😱"
     }
    ]
   },
   {
    "id": 19558,
    "type": "message",
    "date": "2023-04-29",
    "date_unixtime": "1682748205",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1053",
    "dicos": "64",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Kitchen Kit Prado Series 550w juicer mixer grinder with 3 stainless steel jar✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LBV6r5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40OrPO7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NnzfVG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44fmIcC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://www.amazon.in/dp/B097N7TYL1?ascsub=&amp;linkCode=sl1&amp;tag=avi1deals5-21&amp;linkId=029d1179215d27e94b5a1b30429b0e1c&amp;language=en_IN&amp;ref_=as_li_ss_tl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3n8k5sL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AzOvHr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42aaErm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42aim4G"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ncaqkK"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1053"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61ZJlO-W7AL._SY355_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Home Appliances \n                Small Kitchen Appliances \nPryce1053 \nDicos64 \n\n❌Regular Price  : ₹ 1999    /- 😱"
     }
    ]
   },
   {
    "id": 19559,
    "type": "message",
    "date": "2023-04-29",
    "date_unixtime": "1682748341",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "799",
    "dicos": "43",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀  Sony MDR-ZX110A Wired On Ear Headphone without Mic (White)  ✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AEliew"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/428HbhG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41NGn1U"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LhtciP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HnipCz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VoeU4r"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3No3nAm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lh0630"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nhLMPJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3n8kz23"
     },
     {
      "type": "plain",
      "text": "\n\n @799\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/41BoLKMYjnL._SY879_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Electronics \n                Headphones \nPryce799 \nDicos43 \n\n❌Regular Price  : ₹  1199   /- 😱"
     }
    ]
   },
   {
    "id": 19560,
    "type": "message",
    "date": "2023-04-29",
    "date_unixtime": "1682748457",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "457",
    "dicos": "75",
    "catagory": "Clothing",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Fusefit Women's Slim Leggings✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ncaKjs"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40ID62A"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LEgZG8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40OVAOI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44fovyo"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/444W8D4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40Ib2fG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44aAVHZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AFO26C"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AD7VuT"
     },
     {
      "type": "plain",
      "text": "\n\n @457\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71FxiOBR+9L._UX466_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Clothing"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Clothing & Accessories \n                Women \n                Western Wear \nPryce457 \nDicos75 \n\n❌Regular Price  : ₹   1299   /- 😱"
     }
    ]
   },
   {
    "id": 19561,
    "type": "message",
    "date": "2023-04-29",
    "date_unixtime": "1682748592",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "7999",
    "dicos": "59",
    "catagory": "Health",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   JSB HF74 Back Massager for Chair Wireless Rechargeable with Kneading & Heat (Red Blue)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nc1Cv9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NoJMzY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AEbBMR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/429QUEe"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44aBl11"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44gFqku"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oPfxrA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HjENwE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lh0D50"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LivGxf"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@7999"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61X6fxCfnPL._SY355_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Health"
     },
     {
      "type": "plain",
      "text": " & Personal Care\n\n                Health & Personal Care \n                Health Care \n                Massage & Relaxation \nPryce7999 \nDicos59 \n\n❌Regular Price  : ₹  14999   /- 😱"
     }
    ]
   },
   {
    "id": 19562,
    "type": "message",
    "date": "2023-04-29",
    "date_unixtime": "1682748732",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "16690",
    "dicos": "21",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀  LG 185 L 4 Star Inverter Direct-Cool Single Door Refrigerator (GL-D199OSEY, Scarlet Euphoria, Base stand with drawer) ✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HLoYz5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41TaapV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LFp8u7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LFbOG8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HoF6pJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44dSWoF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44bjLtO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LFpn8v"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/428HMzW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/428HPf6"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@16690"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61g7eNjP0DL._SX342_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Large Appliances \n                 Refrigerators \nPryce16690 \nDicos21 \n\n❌Regular Price  : ₹  19999   /- 😱"
     }
    ]
   },
   {
    "id": 19563,
    "type": "message",
    "date": "2023-04-29",
    "date_unixtime": "1682748863",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1800",
    "dicos": "71",
    "catagory": "Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   XPG ADATA GAMMIX D30 DDR4 8GB (1x8GB) 3200MHz U-DIMM Desktop Memory - AX4U32008G16A-SR30✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oJZICm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oJZXxg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41JqGZw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41MpdBS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44dyDHV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HmGJUW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VidDMi"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ngCGmc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LkoRew"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HoFhRV"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1800"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/811elTxHkaL._SY450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Computers & Accessories \n                Components \n                 Memory \nPryce1800 \nDicos71 \n\n❌Regular Price  : ₹  4999   /- 😱"
     }
    ]
   },
   {
    "id": 19564,
    "type": "message",
    "date": "2023-04-29",
    "date_unixtime": "1682748999",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "129",
    "dicos": "74",
    "catagory": "Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Portronics Toad 101 Wired Optical Mouse with 1200 DPI, Plug & Play, Hi-Optical Tracking, 1.25M Cable Length, 30 Million Click Life(Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40MVTJV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41QWvQc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/423nDf7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44fSBSs"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HomplY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AEi6zp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40OXPSc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VidSqG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NjlcQZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oMx6bD"
     },
     {
      "type": "plain",
      "text": "\n\n @129\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51czxrySrVL._SY450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Computers & Accessories \n                Accessories & Peripherals \n                Keyboards, Mice & Input Devices \nPryce129 \nDicos74 \n\n❌Regular Price  : ₹ 499    /- 😱"
     }
    ]
   },
   {
    "id": 19565,
    "type": "message",
    "date": "2023-04-29",
    "date_unixtime": "1682749135",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "7999",
    "dicos": "78",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   coocaa 80 cm (32 inches) Frameless Series HD Ready Smart IPS LED TV 32S3U Pro (Black) ✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AC9AAL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NmVY3W"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AAJXAq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42cqpyh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41Fjb5T"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LkuU2Z"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3L9olzS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VkBoDl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Nm8DnS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41Pfx9J"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@7999"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71bnSMzWhxL._SX522_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Home Theater, TV & Video \n                Televisions \nPryce7999 \nDicos78 \n\n❌Regular Price  : ₹  19999  /- 😱"
     }
    ]
   },
   {
    "id": 19566,
    "type": "message",
    "date": "2023-04-29",
    "date_unixtime": "1682749265",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "699",
    "dicos": "80",
    "catagory": "Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Ant Esports GM320 Pro Optical Wireless Gaming Mouse with RGB LED Backlit Lighting, 3200 DPI Optical Sensor, Type- C Charging Port - Black✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40PQ1zV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41PfGdh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AFtj2y"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LBXpuf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42qvHGz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AFtnPQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LEa7Zr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nb5mNF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LEGvLp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/426Gx43"
     },
     {
      "type": "plain",
      "text": "\n\n @699\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61CQ9ExSjkL._SY450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Computers & Accessories \n                Accessories & Peripherals \n                PC Gaming Peripherals \nPryce699 \nDicos80 \n\n❌Regular Price  : ₹   1999   /- 😱"
     }
    ]
   },
   {
    "id": 19567,
    "type": "message",
    "date": "2023-04-29",
    "date_unixtime": "1682749396",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1089",
    "dicos": "27",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Luminous Vento Deluxe 150 mm Exhaust Fan for Kitchen, Bathroom with Strong Air Suction, Rust Proof Body and Dust Protection Shutters (2-Year Warranty, White)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AWs1AF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44aEa26"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41P331B"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AFTo1q"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AFTqX6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ADaE7D"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VhYfzl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44gl0Ij"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oUbN8p"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nm6DBk"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1089"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/41wbi-Kl5uL._SY355_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Heating, Cooling & Air Quality \n                Fans \nPryce1089 \nDicos27 \n\n❌Regular Price  : ₹ 1499    /- 😱"
     }
    ]
   },
   {
    "id": 19568,
    "type": "message",
    "date": "2023-04-29",
    "date_unixtime": "1682749523",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "699",
    "dicos": "65",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Pigeon by Stovekraft Non Induction Base Aluminium Inner Lid Pressure Cooker, 14459 (3 L, Silver)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HkvgoU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HmHTjg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LE7BCi"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nbaWzz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Notirt"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LBWYPx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40MzDQv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oVtVyl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/423oMTX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44blCig"
     },
     {
      "type": "plain",
      "text": "\n\n @699\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51n2CVREDKL._SY355_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Dining \n                Cookware \nPryce699 \nDicos65 \n\n❌Regular Price  : ₹  1499   /- 😱"
     }
    ]
   },
   {
    "id": 19569,
    "type": "message",
    "date": "2023-04-29",
    "date_unixtime": "1682749628",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "399",
    "dicos": "73",
    "catagory": "Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   STRIFF Laptop Stand for Desk, Ergonomic Computer Stand Laptop Riser, Phone and Tablet Stand for Desk, Portable Laptop Elevator Holder Compatible with MacBook, Laptop,Tablet(WhiteSky)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40PUWAP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LAGUOZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LFv06u"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/444Y6TY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oQTsJ9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40MzRXR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44gHuJg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/447K1W5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40QsK0q"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44aD46v"
     },
     {
      "type": "plain",
      "text": "\n\n @399\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61J8XU56LwL._SX450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Computers & Accessories \n                Accessories & Peripherals \n                Laptop Accessories \nPryce399 \nDicos73 \n\n❌Regular Price  : ₹ 1199    /- 😱"
     }
    ]
   },
   {
    "id": 19570,
    "type": "message",
    "date": "2023-04-29",
    "date_unixtime": "1682749728",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "399",
    "dicos": "81",
    "catagory": "Sports",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀 Kore PVC DM 4-40 Kg (Black/Black-Red/3 IN 1 Convertible) Dumbbells Set and Fitness Kit for Men and Women Whole Body Workout  ✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40RcjRH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40Jg1N3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ACapcP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AE3aRK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AC4Zyz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41OfsmE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40OuCH5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40IdCCo"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nbb8yN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oVe9Ua"
     },
     {
      "type": "plain",
      "text": "\n\n @399\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/4115u7yARML._SY300_SX300_QL70_FMwebp_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Sports"
     },
     {
      "type": "plain",
      "text": ", Fitness & Outdoors\n\n                Sports, Fitness & Outdoors \n                Exercise & Fitness \n                Strength Training Equipment \nPryce399 \nDicos81 \n\n❌Regular Price  : ₹  1599   /- 😱"
     }
    ]
   },
   {
    "id": 19571,
    "type": "message",
    "date": "2023-04-29",
    "date_unixtime": "1682749832",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "979",
    "dicos": "39",
    "catagory": "Sports",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   YONEX ZR 100 Light Aluminium Badminton Racquet with Full Cover (Black/Red) - Set of 2✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hnr0oG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HnCiJr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41OhL94"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LE8evE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/420nI2M"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oRijMW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LE9ajT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41Ofw5S"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44aENbY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42bmotV"
     },
     {
      "type": "plain",
      "text": "\n\n @979\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71iIuBTV7RL._SY450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Sports"
     },
     {
      "type": "plain",
      "text": ", Fitness & Outdoors\n\n                Sports, Fitness & Outdoors \n                Badminton \n                 Racquets \nPryce979 \nDicos39 \n\n❌Regular Price  : ₹  1599     /- 😱"
     }
    ]
   },
   {
    "id": 19572,
    "type": "message",
    "date": "2023-04-29",
    "date_unixtime": "1682749934",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "649",
    "dicos": "69",
    "catagory": "Clothing",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Amazon Brand - Symbol Men's Regular Fit T-Shirt✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VgtUBm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Nr5sv8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42aedOg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41Pfiv6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/422wKfi"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AEo3wo"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NjT007"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44byzZr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AE3y2E"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3njhdJl"
     },
     {
      "type": "plain",
      "text": "\n\n @649\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71eWZpgtFiL._UX522_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Clothing"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Fashion \n                Made for Amazon brands \n                All SPB apparel \nPryce649 \nDicos69 \n\n❌Regular Price  : ₹  1599    /- 😱"
     }
    ]
   },
   {
    "id": 19573,
    "type": "message",
    "date": "2023-04-29",
    "date_unixtime": "1682750059",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "299",
    "dicos": "70",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Dekorly Artificial Potted Plants, 6 Pack Artificial Plastic Eucalyptus Plants Small Indoor Potted Houseplants, Small Faux Plants for Home Decor Bathroom Office Farmhouse (Set 0F 6)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LBXqgH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oRiCr4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Viu4Iv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Llw65Y"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Ll9NNE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41OD36z"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VdvJz3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HnLCgs"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lh3P0u"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40PRfLx"
     },
     {
      "type": "plain",
      "text": "\n\n @299\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71IyuwAbIPL._SX355_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Home & Décor \n                Artificial Flora \nPryce299 \nDicos70 \n\n❌Regular Price  : ₹  899    /- 😱"
     }
    ]
   },
   {
    "id": 19574,
    "type": "message",
    "date": "2023-04-29",
    "date_unixtime": "1682750184",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1799",
    "dicos": "85",
    "catagory": "Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Fire-Boltt Phoenix Pro 1.39\" Bluetooth Calling Smartwatch, AI Voice Assistant, Metal Body with 120+ Sports Modes, SpO2, Heart Rate Monitoring (Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oUcEG9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40MAhNV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40KEaCZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40IFLt6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HpHkoO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lla5UK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HnCM2d"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44auN2u"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ne0gjD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44dAtIP"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1799"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61ArSKA048L._SX522_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Electronics \n                Wearable Technology \n                 Smart Watches \nPryce1799 \nDicos85 \n\n❌Regular Price  : ₹  4999   /- 😱"
     }
    ]
   },
   {
    "id": 19575,
    "type": "message",
    "date": "2023-04-29",
    "date_unixtime": "1682750307",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1005",
    "dicos": "54",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Pigeon Brewster Coffee Maker, 600 Watt, 4 Cups Drip Coffee maker (Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oUgor1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ncnF4S"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40PRvdt"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3neiyRS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ABBZHt"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LEzjyQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nes33n"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oQU6Gz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AJZY73"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41LLz6h"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1005"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51q9WR-LfHL._SY355_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Home Appliances \n                Coffee, Tea & Espresso \nPryce1005 \nDicos54 \n\n❌Regular Price  : ₹   1899  /- 😱"
     }
    ]
   },
   {
    "id": 19576,
    "type": "message",
    "date": "2023-04-29",
    "date_unixtime": "1682750409",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "2399",
    "dicos": "48",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Hindware Atlantic Compacto 3 Litre Instant Water Heater With Stainless Steel Tank, Robust Construction, Pressure Relief Valve And I-Thermostat Feature (White And Grey), Wall Mounting✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oQU95d"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41Mlcxd"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oVuYON"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41JsXE2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40SuTJ4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oXYCTy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HjHyy0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AAMlqS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40SXKNs"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nhbpAk"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@2399"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/4157skMWZ2L._SY355_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Heating, Cooling & Air Quality \n                Water Heaters & Geysers \nPryce2399 \nDicos48 \n\n❌Regular Price  : ₹  3999   /- 😱"
     }
    ]
   },
   {
    "id": 19577,
    "type": "message",
    "date": "2023-04-29",
    "date_unixtime": "1682750530",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "13103",
    "dicos": "83",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Casacomfort Lifestylia 3 Seater Fabric Sofa Set for Living Room (Light Grey-Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HjHBtG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LEbszr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/449LLOl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41OxHbE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44oEE4W"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41LLHTj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VgbwbK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oUwRLW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40Iec32"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LHflDW"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@13103"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61KBrMNaOvL._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Home & Kitchen \n                Furniture \n                Living Room Furniture \nPryce13103 \nDicos83 \n\n❌Regular Price  : ₹  39999   /- 😱"
     }
    ]
   },
   {
    "id": 19578,
    "type": "message",
    "date": "2023-04-29",
    "date_unixtime": "1682750638",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "88",
    "dicos": "91",
    "catagory": "Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   POSH Male to Male VGA Cable 1.5 Meter, Support PC/Monitor/LCD/LED, Plasma, Projector, TFT. VGA to VGA Converter Adapter Cable✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40MAFMn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NjmF9X"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oUwX6g"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VnveTa"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42a4dED"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/428IwoB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VoixHB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oTYJjj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NiD8et"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/427ODJL"
     },
     {
      "type": "plain",
      "text": "\n\n @88\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/41GWPiBQXmL.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Computers & Accessories \n                Accessories & Peripherals \n                Cables & Accessories \nPryce88 \nDicos91 \n\n❌Regular Price  : ₹ 250   /- 😱"
     }
    ]
   },
   {
    "id": 19579,
    "type": "message",
    "date": "2023-04-29",
    "date_unixtime": "1682750757",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1099",
    "dicos": "27",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Amazon Brand - Solimo 100% Cotton 6 Piece Towel Set, 500 GSM (Sepia Brown and Baby Pink)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oK2nMm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NnCYCE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44q9YQT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LhxkiP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44aDFoL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ADbSzL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44bTX0u"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oQkyAi"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VgbOPS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vd8EMZ"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1099"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/91dlaDQlHbL._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Home Furnishing \n                Bathroom Linen \nPryce1099 \nDicos27 \n\n❌Regular Price  : ₹ 1450   /- 😱"
     }
    ]
   },
   {
    "id": 19580,
    "type": "message",
    "date": "2023-04-29",
    "date_unixtime": "1682750860",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "669",
    "dicos": "26",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Amazon Brand - Solimo Polyester Solid Crushed Texture Curtain, Door, 7 feet (2.13 m), Cream, Pack of 2, Eyelet✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AzSnrX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NoMXaS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NnzFuZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/4271gEX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://www.amazon.in/dp/B08WK6RVBS?th=1&amp;linkCode=sl1&amp;tag=avi1deals5-21&amp;linkId=0a3f8edf3afafa2e2094fdfdde98da71&amp;language=en_IN&amp;ref_=as_li_ss_tl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NlIZzw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41Fl9Dj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oXYOSM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41Mrvks"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/420oJb6"
     },
     {
      "type": "plain",
      "text": "\n\n @669\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/417EEmWjFHS._SX300_SY300_QL70_FMwebp_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Home Furnishing \n                Curtains & Accessories \nPryce669 \nDicos26 \n\n❌Regular Price  : ₹  950  /- 😱"
     }
    ]
   },
   {
    "id": 19581,
    "type": "message",
    "date": "2023-04-29",
    "date_unixtime": "1682750963",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1169",
    "dicos": "58",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   AmazonBasics Microfiber 3 Pieces Queen Duvet Cover Set with Grey Striped With 2 Pillow Covers✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41Flea5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vd8Lbn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3L9rrEc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vj5bw4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44eZW4U"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LfOf5a"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44fq13w"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ViuJJZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ViuKxx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LfbMTL"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1169"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/91bKNaDCs0L._SX355_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Home Furnishing \n                Bedding & Linen \nPryce1169 \nDicos58 \n\n❌Regular Price  : ₹ 2499   /- 😱"
     }
    ]
   },
   {
    "id": 19582,
    "type": "message",
    "date": "2023-04-29",
    "date_unixtime": "1682751069",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "9990",
    "dicos": "19",
    "catagory": "Sports",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Cockatoo Premium Kids Edition CKC Series Kids Bicycle with Clipper Break(DIY Installation & 2 Year Warranty) (Red, 12T(2-4 Years))✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44gVlyX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LfOjSs"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nm8Exo"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AB88yP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NoZTNQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LEG2ZJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3n77So9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NmaJ7e"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nej56k"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nb6W23"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@9990"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/41KczuPAl6L._SY300_SX300_QL70_FMwebp_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Sports"
     },
     {
      "type": "plain",
      "text": ", Fitness & Outdoors\n\n                Sports, Fitness & Outdoors \n                Cycling \n                Kids' Cycles & Accessories \nPryce9990 \nDicos19 \n\n❌Regular Price  : ₹ 11999   /- 😱"
     }
    ]
   },
   {
    "id": 19583,
    "type": "message",
    "date": "2023-04-29",
    "date_unixtime": "1682751173",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "429",
    "dicos": "39",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Amazon Brand - Solimo PVC Water Resistant Top Load Semi Automatic Washing Machine Cover, Grey✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LEa9k5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40WuZ2E"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nhbNyM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41PkpLT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41UC4C8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/422xwcc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41LWJrD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LG9wGs"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40NX6AT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ADca9N"
     },
     {
      "type": "plain",
      "text": "\n\n @429\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61xbl4vdmpL._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Large Appliances \n                Parts & Accessories \nPryce429 \nDicos39 \n\n❌Regular Price  : ₹  699  /- 😱"
     }
    ]
   },
   {
    "id": 19584,
    "type": "message",
    "date": "2023-04-29",
    "date_unixtime": "1682751274",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "129",
    "dicos": "82",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Portronics Konnect L POR-1403 Fast Charging 3A Type-C Cable 1.2 Meter with Charge & Sync Function for All Type-C Devices (White)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40PScU7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AABayy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41PtWCC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41MlKTN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hmcmhv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ABCAZJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AFRxdg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/420p79y"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LH1VYx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LEkOuY"
     },
     {
      "type": "plain",
      "text": "\n\n @129\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/41HYPIrcYQL._SX342_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Computers & Accessories \n                Accessories & Peripherals \n                Cables & Accessories \nPryce129 \nDicos82 \n\n❌Regular Price  : ₹ 450   /- 😱"
     }
    ]
   },
   {
    "id": 19585,
    "type": "message",
    "date": "2023-04-29",
    "date_unixtime": "1682751376",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "129",
    "dicos": "74",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Sounce Tablet Stand Phone Holder Foldable & Adjustable, [2021 Updated] Compact Desktop iPad Tablet Stands Holder Cradle Dock Fits for iPad Pro 11, 12.9, 10.2, Mini Air 2 3 4 Samsung Tab, Kindle, Monitor, Phone✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VkDrY3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44dVLWN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vjs9DG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44dVNxT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nhc1G8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LEkXP2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oQkUH8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LEMLTu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40Wv9Hi"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40INyqV"
     },
     {
      "type": "plain",
      "text": "\n\n @129\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51J+TJYKFWL._SX300_SY300_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Mobiles & Accessories \n                Mobile Accessories \nPryce129 \nDicos74 \n\n❌Regular Price  : ₹ 389   /- 😱"
     }
    ]
   },
   {
    "id": 19586,
    "type": "message",
    "date": "2023-04-29",
    "date_unixtime": "1682751484",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "308",
    "dicos": "74",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Amazon brand solimo Reinforced polypropelene Plastic Multipurpose Foldable Tray - Turquoiseblue✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oVffiK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LBYhhp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NmaVDu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NhINl0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HjyIQv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3n8nUy7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HmJqG2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ncepxI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nby54M"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42afu80"
     },
     {
      "type": "plain",
      "text": "\n\n @308\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71VlKhNPi0L._SX355_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Furniture \n                Study & Home Office Furniture \nPryce308 \nDicos74 \n\n❌Regular Price  : ₹ 999   /- 😱"
     }
    ]
   },
   {
    "id": 19587,
    "type": "message",
    "date": "2023-04-29",
    "date_unixtime": "1682751586",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "209",
    "dicos": "78",
    "catagory": "Clothing",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   CHEROKEE KIDS Girl's Cherokee Full Sleeve Regular FIT Coordinates Pajama Set (CCJGCOT20026ASO_Assorted_2-3 Years)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/422MRcS"
     },
     {
      "type": "plain",
      "text": "\n\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NmId5m"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NouE5x"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/425PHxV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/4271I67"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/429tRtu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/424ZsML"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42an2rg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NmbfCc"
     },
     {
      "type": "plain",
      "text": "\n\n @209\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/712Lq6DAtuS._UY679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Clothing"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Fashion \n                Apparel Steal Deals \n                Apparel_1 \nPryce209 \nDicos78 \n\n❌Regular Price  : ₹ 650   /- 😱"
     }
    ]
   },
   {
    "id": 19588,
    "type": "message",
    "date": "2023-04-29",
    "date_unixtime": "1682751688",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "65",
    "dicos": "67",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Kuber Industries Cotton Oval Door Mat for Porch/Kitchen/Bathroom/Laundry Room,(Green) 54KM3952✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NnDDnC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Nt8Tlf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NlJt8O"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40NXoHZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AGJe0J"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40MrNXe"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40MYSlB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oRvFJb"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VdwSGR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/422y5mk"
     },
     {
      "type": "plain",
      "text": "\n\n @65\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51ody-KlWKL._SX300_SY300_QL70_FMwebp_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Home Furnishing \n                Carpets & Rugs \nPryce65 \nDicos67 \n\n❌Regular Price  : ₹150    /- 😱"
     }
    ]
   },
   {
    "id": 19589,
    "type": "message",
    "date": "2023-04-29",
    "date_unixtime": "1682751790",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "549",
    "dicos": "78",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   PTron Tangentbeat in-Ear Bluetooth 5.0 Wireless Headphones with Mic, Deep Bass, 10mm Drivers, Clear Calls, Snug-Fit, Fast Charging, Magnetic Buds, Voice Assistant & IPX4 Wireless Neckband (Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44f0xUc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vivzq1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VifSit"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vi0e6L"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LlxgOS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LeLUHL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NjUnMj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40JhFyd"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41Ju0DY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vj5Nlm"
     },
     {
      "type": "plain",
      "text": "\n\n @549\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51K8TkvtjgL._SX466_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Headphones, Earbuds & Accessories \n                Headphones \nPryce549 \nDicos78 \n\n❌Regular Price  : ₹ 1499   /- 😱"
     }
    ]
   },
   {
    "id": 19590,
    "type": "message",
    "date": "2023-04-29",
    "date_unixtime": "1682751895",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1078",
    "dicos": "28",
    "catagory": "Sports",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Tights WMN SG WTSST025 Purple SAGE XL✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41LMzr3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oWMHp5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nhQ2Pd"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oMAyTD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nfOuVY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/428L7Pu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AB94TR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lhyaft"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LfcNv3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ADd2ez"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1078"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/31j8t2wrnES.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Sports"
     },
     {
      "type": "plain",
      "text": ", Fitness & Outdoors\n\n                Clothing & Accessories \n                Women \n                Sportswear \nPryce1078 \nDicos28 \n\n❌Regular Price  : ₹  1450  /- 😱"
     }
    ]
   },
   {
    "id": 19591,
    "type": "message",
    "date": "2023-04-29",
    "date_unixtime": "1682751998",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "399",
    "dicos": "73",
    "catagory": "Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   STRIFF Laptop Stand for Desk, Ergonomic Computer Stand Laptop Riser, Phone and Tablet Stand for Desk, Portable Laptop Elevator Holder Compatible with MacBook, Laptop,Tablet(WhiteSky)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lhydb9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AzTeZH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LEaZxf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AEtlYI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LGl50H"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HnDZqh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VgvGT2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VgvHX6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44gVW3F"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Nov6kf"
     },
     {
      "type": "plain",
      "text": "\n\n @399\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/41V9w0FlZVL._SY300_SX300_QL70_FMwebp_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Computers & Accessories \n                Accessories & Peripherals \n                Laptop Accessories \nPryce399 \nDicos73 \n\n❌Regular Price  : ₹  1099  /- 😱"
     }
    ]
   },
   {
    "id": 19592,
    "type": "message",
    "date": "2023-04-29",
    "date_unixtime": "1682752101",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "594",
    "dicos": "46",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   itel IEB-33 Neck Earphones (Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LGllgb"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LOIbSV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NnBat5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Viw9UJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NnSVZr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NnBisz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NoO2iW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40WvUA8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40PXvCX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40PXwH1"
     },
     {
      "type": "plain",
      "text": "\n\n @594\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/510-KFjwA0L._SX522_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Headphones, Earbuds & Accessories \n                Headphones \nPryce594 \nDicos46 \n\n❌Regular Price  : ₹  1499  /- 😱"
     }
    ]
   },
   {
    "id": 19593,
    "type": "message",
    "date": "2023-04-29",
    "date_unixtime": "1682752202",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "279",
    "dicos": "65",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Surya 2W LED Prime SPOT Light, Ceiling SPOT Light (Green) (Pack of 4)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oXZYO8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40SZ6I2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AEfaCJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/429urYc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/428LjOI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ncf02q"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44dBPmT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AFSm5Q"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/424lmje"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44bV7sS"
     },
     {
      "type": "plain",
      "text": "\n\n @279\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61zhxCdSuLL._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Indoor Lighting \n                Fixtures \nPryce279 \nDicos65 \n\n❌Regular Price  : ₹ 750   /- 😱"
     }
    ]
   },
   {
    "id": 19594,
    "type": "message",
    "date": "2023-04-29",
    "date_unixtime": "1682752301",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "342",
    "dicos": "83",
    "catagory": "Car",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   ARNV Car Cover Compatible with Mahindra Thar | Grey✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44dS8Af"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44eRUJm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AWuMC1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44aEX39"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ncpr62"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44aH8Ui"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AWuQBL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vj6j2M"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ViFoV9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/4261VGS"
     },
     {
      "type": "plain",
      "text": "\n\n @342\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/31CIWBZMsWL.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Car"
     },
     {
      "type": "plain",
      "text": " & Motorbike\n\n                Car & Motorbike \n                Car Accessories \n                Exterior Accessories \nPryce342 \nDicos83 \n\n❌Regular Price  : ₹ 1499   /- 😱"
     }
    ]
   },
   {
    "id": 19595,
    "type": "message",
    "date": "2023-04-29",
    "date_unixtime": "1682752425",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "399",
    "dicos": "90",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀  SYVO Flex Bluetooth 5.2 Wireless in Ear Headphones, 13mm Driver, Deep Bass, HD Calls, Fast Charging Neckband, Dual Pairing, Voice Assistant & IPX4 Water Resistant (Black) ✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LEd1xj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vfn1A6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3L9scNm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/425QyyD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/426225g"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LBZ4Pp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LfPAsI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LjsCkm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/428LDNq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3neUJt6"
     },
     {
      "type": "plain",
      "text": "\n\n @399\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51CWdYLo21L._SX522_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Headphones, Earbuds & Accessories \n                Headphones \nPryce399 \nDicos90 \n\n❌Regular Price  : ₹  1799  /- 😱"
     }
    ]
   },
   {
    "id": 19596,
    "type": "message",
    "date": "2023-04-29",
    "date_unixtime": "1682752549",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1799",
    "dicos": "67",
    "catagory": "Watches",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   SYSKA SW200 Smart Watch 100+ Cloud & Customizable Watch Faces | Smart Notifications for Calls, SMS, Whatsapp|Battery Runtime-Upto 10 Days (Spectra Blue)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oWN0jJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40OwWOj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hmdsd7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HkxTqM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HLsMAn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41FmI49"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oUyKbu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LENHas"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NnT7YF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Nj2PeU"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1799"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51XNwDk9lDL._SX522_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Watches"
     },
     {
      "type": "plain",
      "text": "\n\n                Watches \n                Men \n                 Fashion Smartwatches \nPryce1799 \nDicos67 \n\n❌Regular Price  : ₹  3899  /- 😱"
     }
    ]
   },
   {
    "id": 19597,
    "type": "message",
    "date": "2023-04-29",
    "date_unixtime": "1682752670",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "97",
    "dicos": "43",
    "catagory": "Tools",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Goldmedal Curve Plus 202042 Plastic Spice 3-Pin 240V Universal Travel Adaptor (White)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lc60SW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LOIqgN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oPkAs2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HoUMcR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HnwmjN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41TeoOj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ne20tb"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40OUOBz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LEHgUP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oRkOii"
     },
     {
      "type": "plain",
      "text": "\n\n @97\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/41lVG7lmieL._SX450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Tools"
     },
     {
      "type": "plain",
      "text": " & Home Improvement\n\n                Electronics \n                Mobiles & Accessories \n                Mobile Accessories \nPryce97 \nDicos43 \n\n❌Regular Price  : ₹ 160   /- 😱"
     }
    ]
   },
   {
    "id": 19598,
    "type": "message",
    "date": "2023-04-29",
    "date_unixtime": "1682752794",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "445",
    "dicos": "72",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Home Elite 210 TC Pure Cotton King Size Bedsheet with 2 Pillow Covers (Black, 100x108 inch)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NnTgvb"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44oGkLM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ACcFkj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NmZ5ZG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40N0bB1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AC7fWz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3n8pmAz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NiFaLD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LEalj4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40Qv8nU"
     },
     {
      "type": "plain",
      "text": "\n\n @445\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71JBPQAmlVL._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Home Furnishing \n                Bedding & Linen \nPryce445 \nDicos72 \n\n❌Regular Price  : ₹ 1199   /- 😱"
     }
    ]
   },
   {
    "id": 19599,
    "type": "message",
    "date": "2023-04-29",
    "date_unixtime": "1682752917",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "849",
    "dicos": "47",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Hindware Zorio Ax Neo 100mm Exhaust Fan with Low Noise, Powerful Air Suction and High Speed, For Kitchen or Bathroom With Overload Protection For Odourless, Clean and Fresh Air (Brown)✅\n"
     },
     {
      "type": "link",
      "text": "https://www.amazon.in/Hindware-Powerful-Bathroom-Protection-Odourless/dp/B09YRR84FM?th=1&amp;linkCode=sl1&amp;tag=avinashbmv07-21&amp;linkId=acd5a38c8efea15873a609d16664af43&amp;language=en_IN&amp;ref_=as_li_ss_tl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oST5hi"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/426K56n"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40ION9z"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40Oxoft"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AE5TdW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40Q8agW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40NYJ1t"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nc6f8v"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oHF2uL"
     },
     {
      "type": "plain",
      "text": "\n\n @849\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61Yhy1pit1L._SY355_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Heating, Cooling & Air Quality \n                Fans \nPryce849 \nDicos47 \n\n❌Regular Price  : ₹ 1450   /- 😱"
     }
    ]
   },
   {
    "id": 19600,
    "type": "message",
    "date": "2023-04-29",
    "date_unixtime": "1682753040",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "2059",
    "dicos": "44",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Polycab Vital 1200 MM High Speed Premium Ceiling Fan with dual tone finish and 2 year warranty (Matt Snow White)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42agV6o"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Nj3a1a"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3n79Gxr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/428KsNT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LAJVih"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44aFmmb"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3n8pIXV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44gnTc7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42aobz4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/447MFet"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@2059"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/511aSiUWQFL._SY355_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Heating, Cooling & Air Quality \n                Fans \nPryce2059 \nDicos44 \n\n❌Regular Price  : ₹ 3199   /- 😱"
     }
    ]
   },
   {
    "id": 19601,
    "type": "message",
    "date": "2023-04-29",
    "date_unixtime": "1682753165",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1525",
    "dicos": "34",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Russell Hobbs RFH20VH 2000 Watt Fan Heater/Room Heater with Adjustable Thermostat (White)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/4258Ekc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/424mbsk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/422zEAI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/428LY2E"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vg9DM9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VihE3k"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41OFK8b"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41PvqNc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LjzVIL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41Pi1ok"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1525"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/81GboNuYy1L._SX355_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Heating, Cooling & Air Quality \n                Room Heaters \nPryce1525 \nDicos34 \n\n❌Regular Price  : ₹ 1899  /- 😱"
     }
    ]
   },
   {
    "id": 19602,
    "type": "message",
    "date": "2023-04-29",
    "date_unixtime": "1682753266",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1834",
    "dicos": "54",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Echt RC 5s Mixer Grinder 550 Watt, 3 Stainless Steel Jars,Wet Jar (1500 ml), Multipurpose Jar (1000 ml), Chutney Jar (300 ml); with 3 Speed Control, with 2 years warranty, White✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LeMSDT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VgecWQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ViGgJp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LktmWs"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VihKbc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lhdp3w"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lh64AW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ViGkZF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40OVaIp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oUeNlb"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1834"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61JBozxwbSL._SY355_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Home Appliances \n                Small Kitchen Appliances \nPryce1834 \nDicos54 \n\n❌Regular Price  : ₹ 3899   /- 😱"
     }
    ]
   },
   {
    "id": 19603,
    "type": "message",
    "date": "2023-04-29",
    "date_unixtime": "1682753365",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "933",
    "dicos": "48",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Baltra Fire BTH135 800-Watt Halogen Heater With ISI Mark (White)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LkmYP9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40MCzfZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3L9tlok"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HnwBvd"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HlUrHy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hme16J"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HmL2j4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3n8pJLt"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hqn1Yp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HjAfpJ"
     },
     {
      "type": "plain",
      "text": "\n\n @933\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61kZ0bPpU5L._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Heating, Cooling & Air Quality \n                Room Heaters \nPryce933 \nDicos48 \n\n❌Regular Price  : ₹ 1199   /- 😱"
     }
    ]
   },
   {
    "id": 19604,
    "type": "message",
    "date": "2023-04-29",
    "date_unixtime": "1682753467",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "149",
    "dicos": "83",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   PTron Pride Lite HBE (High Bass Earphones) in Ear Wired Earphones with Mic, 10mm Powerful Driver for Stereo Audio, Noise Cancelling Headset with 1.2m Tangle-Free Cable & 3.5mm Aux (Grey)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NnF1Xm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41LNGHf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NFHDjB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LEtnpv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NlKNsi"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oNaAQ1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41OzOMC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oQHUpi"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40NZ44f"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44aFFNR"
     },
     {
      "type": "plain",
      "text": "\n\n @149\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51RyH+nQGRL._SX522_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Headphones, Earbuds & Accessories \n                Headphones \nPryce149 \nDicos83 \n\n❌Regular Price  : ₹650    /- 😱"
     }
    ]
   },
   {
    "id": 19605,
    "type": "message",
    "date": "2023-04-29",
    "date_unixtime": "1682753572",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "169",
    "dicos": "77",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   PTron Pride Lite HBE (High Bass Earphones) in Ear Wired Earphones with Mic, 10mm Powerful Driver for Stereo Audio, Noise Cancelling Headset with 1.2m Tangle-Free Cable & 3.5mm Aux - (Blue)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LHhxva"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nbe4eN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Njp1pj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AEOsdk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40N13ph"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40MD0a7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42cuSB3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LGmAMn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NFI0L1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/4262Nv8"
     },
     {
      "type": "plain",
      "text": "\n\n @169\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51yB+3-eJwL._SX522_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Headphones, Earbuds & Accessories \n                Headphones \nPryce169 \nDicos77 \n\n❌Regular Price  : ₹ 650   /- 😱"
     }
    ]
   },
   {
    "id": 19606,
    "type": "message",
    "date": "2023-04-29",
    "date_unixtime": "1682753683",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "49400",
    "dicos": "21",
    "catagory": "Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   HP 14s, Ryzen 5-5500U 16GB RAM/512GB SSD 14-inches (35.6 cm) FHD, IPS, Micro-Edge Display/AMD Radeon Graphics/Alexa/Win 11/Backlit KB/Dual Speakers/MSO 2021/1.46 Kg, 14s- fy1003AU, Windows 11 Home✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ncghGK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44fXwmo"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NvG734"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/4273RyH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44eT9Z2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42bpVZd"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://www.amazon.in/HP-14-inches-Micro-Edge-14s-fy1003AU/dp/B0B58VN76J?th=1&amp;linkCode=sl1&amp;tag=avi1deals7-21&amp;linkId=c796b99b3cb27a0c220b4057af614e44&amp;language=en_IN&amp;ref_=as_li_ss_tl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oQWVr9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/424XMCW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/4451n5I"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@49400"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71bzSFt9qKL._SY450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Computers & Accessories \n                Laptops \n                 Traditional Laptops \nPryce49400 \nDicos21 \n\n❌Regular Price  : ₹58999    /- 😱"
     }
    ]
   },
   {
    "id": 19607,
    "type": "message",
    "date": "2023-04-29",
    "date_unixtime": "1682753786",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "899",
    "dicos": "87",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   BMS lifestyle 3 Layer Non-Stick Coated Aluminium Rust Free Kitchen Cookware Combo Set Offer 3 Piece✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oQWz3Z"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41PmAPz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VeQHxo"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://www.amazon.in/BMS-Lifestyle-Non-Stick-Breakfast-Cookware/dp/B07H1KXK5R?&amp;linkCode=sl1&amp;tag=avi1deals-21&amp;linkId=bf8b08e322072da7de19a5c790ca5ef6&amp;language=en_IN&amp;ref_=as_li_ss_tl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VkFNpR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41UEj8w"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oUzw8o"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VgxjjC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VkFVFR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LhdHYa"
     },
     {
      "type": "plain",
      "text": "\n\n @899\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71k9h9EPpiL._SX355_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Dining \n                Cookware \nPryce899 \nDicos87 \n\n❌Regular Price  : ₹ 2799   /- 😱"
     }
    ]
   },
   {
    "id": 19608,
    "type": "message",
    "date": "2023-04-29",
    "date_unixtime": "1682753916",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "479",
    "dicos": "63",
    "catagory": "Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Strontium 32gb Pollex USB 3.1, Black✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LgqSbJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LlcsqC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LeNhGp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HnnAlZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40P2rru"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LfAh3q"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HoqSoK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ne2Gid"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HmeK7X"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40Jjhbf"
     },
     {
      "type": "plain",
      "text": "\n\n @479\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71MR2ZvDLVL._SX450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Computers & Accessories \n                External Devices & Data Storage \n                 Pen Drives \nPryce479 \nDicos63 \n\n❌Regular Price  : ₹ 1099   /- 😱"
     }
    ]
   },
   {
    "id": 19609,
    "type": "message",
    "date": "2023-04-29",
    "date_unixtime": "1682754017",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "700",
    "dicos": "72",
    "catagory": "Shoes",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Lotto Mens Vertigo Running Shoes✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HlVuas"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HLtLR5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HnFwN3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nhdIU0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Nn05go"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41OGmL1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NnIV2h"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/420rIjO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NnCITV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NnCNXJ"
     },
     {
      "type": "plain",
      "text": "\n\n @700\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/91MhDgdsY+L._UX500_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Shoes"
     },
     {
      "type": "plain",
      "text": " & Handbags\n\n                Shoes & Handbags \n                Shoes \n                Men's Shoes \nPryce700 \nDicos72 \n\n❌Regular Price  : ₹ 1899   /- 😱"
     }
    ]
   },
   {
    "id": 19610,
    "type": "message",
    "date": "2023-04-29",
    "date_unixtime": "1682754120",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "4599",
    "dicos": "77",
    "catagory": "Luggage",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   AmazonBasics 2-Piece Set (20\", 28\"), Green✅\n"
     },
     {
      "type": "link",
      "text": "https://www.amazon.in/dp/B07PC561CR?&amp;linkCode=sl1&amp;tag=avinashbmv07-21&amp;linkId=95135569743daed3a18ed7cab7dcfb6b&amp;language=en_IN&amp;ref_=as_li_ss_tl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oVxFjn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HnFIMh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://www.amazon.in/dp/B07PC561CR?&amp;linkCode=sl1&amp;tag=avi1deals-21&amp;linkId=87e808e0d5fa2590493f01d6dd3b3df1&amp;language=en_IN&amp;ref_=as_li_ss_tl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40T0DxM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/4140rMt"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40IgUFK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40IgVti"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40T0MRQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AADA06"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@4599"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/91uWQ4GYgSL._SY355_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Luggage"
     },
     {
      "type": "plain",
      "text": " & Bags\n\n                Bags, Wallets and Luggage \n                Luggage \n                 Luggage Sets \nPryce4599 \nDicos77 \n\n❌Regular Price  : ₹ 16599   /- 😱"
     }
    ]
   },
   {
    "id": 19611,
    "type": "message",
    "date": "2023-04-29",
    "date_unixtime": "1682754246",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "499",
    "dicos": "74",
    "catagory": "Sports",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   FitBox Sports Dumbbells Combo Weigth Plates & Hand Grips 8kg, Black✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AK34Id"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/446N1C1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LFgQCw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LGmSmr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oWoPSQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LH45Y9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LOJHEB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AEOUbw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vdz6WJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3n8qDYn"
     },
     {
      "type": "plain",
      "text": "\n\n @499\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/618TJMTB9NL._SY450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Sports"
     },
     {
      "type": "plain",
      "text": ", Fitness & Outdoors\n\n                Sports, Fitness & Outdoors \n                Exercise & Fitness \n                Strength Training Equipment \nPryce499 \nDicos74 \n\n❌Regular Price  : ₹ 1499   /- 😱"
     }
    ]
   },
   {
    "id": 19612,
    "type": "message",
    "date": "2023-04-29",
    "date_unixtime": "1682754349",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "4799",
    "dicos": "71",
    "catagory": "Health",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Lifelong LLM360 Corded Electric Foot Massager for Foot Pain, Perfect for Home Use & Pain Relief at Home with 4 Automatic Programs and 3 Custom Massage Modes (1 Year Warranty, Matte Grey)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LEKDuT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40TVDsB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ncr6bM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LFujdx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ABaNZl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40TVGVj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ACdUQv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/426LrOv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ABaYnt"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NketWX"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@4799"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/5160XJYLkDL._SY450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Health"
     },
     {
      "type": "plain",
      "text": " & Personal Care\n\n                Health & Personal Care \n                Health Care \n                Massage & Relaxation \nPryce4799 \nDicos71 \n\n❌Regular Price  : ₹  11899  /- 😱"
     }
    ]
   },
   {
    "id": 19613,
    "type": "message",
    "date": "2023-04-29",
    "date_unixtime": "1682754451",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "379",
    "dicos": "78",
    "catagory": "Clothing",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Campus Sutra Women Printed Hoodie✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Nox5oH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/427AFaV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/446NbJD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42cvIOd"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41LZkBT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44gYI95"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44gLx8q"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oTs8tV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44ayIwe"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vj8rHO"
     },
     {
      "type": "plain",
      "text": "\n\n @379\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/815ZbrHODGL._UX522_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Clothing"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Clothing & Accessories \n                Women \n                Western Wear \nPryce379 \nDicos78 \n\n❌Regular Price  : ₹ 1299   /- 😱"
     }
    ]
   },
   {
    "id": 19614,
    "type": "message",
    "date": "2023-04-29",
    "date_unixtime": "1682754575",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "3323",
    "dicos": "72",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Klaxon Tetra Coffee Table - Black✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VgxwDq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oQXf9x"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oQX9yH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/423sOM5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LhMhkL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nmbBOu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LhMx3d"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Ljuc5M"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LdKWvr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LheqbQ"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@3323"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51SIMpFcSIL._SX300_SY300_QL70_FMwebp_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Home & Kitchen \n                Furniture \n                Living Room Furniture \nPryce3323 \nDicos72 \n\n❌Regular Price  : ₹  8599  /- 😱"
     }
    ]
   },
   {
    "id": 19615,
    "type": "message",
    "date": "2023-04-29",
    "date_unixtime": "1682754679",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "969",
    "dicos": "52",
    "catagory": "Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Zebronics Zeb-War Gaming Keyboard and Mouse Combo (Gold Plated USB, Braided Cable)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3L9uDzG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HjB6GX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HshNvh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HjIx15"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nhfZhV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HkzCwg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NkeFp9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NvGLO2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Noxsj5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oRmcl0"
     },
     {
      "type": "plain",
      "text": "\n\n @969\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/813SshIMhfL._SY450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Computers & Accessories \n                Accessories & Peripherals \n                Keyboards, Mice & Input Devices \nPryce969 \nDicos52 \n\n❌Regular Price  : ₹ 1759   /- 😱"
     }
    ]
   },
   {
    "id": 19616,
    "type": "message",
    "date": "2023-04-29",
    "date_unixtime": "1682754788",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1155",
    "dicos": "37",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀 Crompton Blendserve Hand Blender with Plastic Stem | 300 Watts Power | Detachable stem | Silent Operation  ✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40T1deW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41R201i"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40O04Fx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ne3Ie5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HjKRFq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40OX0ZP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HpyMhX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AC8N2P"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ne3NhT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44bCipT"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1155"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/31svA2t9fML._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Home Appliances \n                Small Kitchen Appliances \nPryce1155 \nDicos37 \n\n❌Regular Price  : ₹ 1699   /- 😱"
     }
    ]
   },
   {
    "id": 19617,
    "type": "message",
    "date": "2023-04-29",
    "date_unixtime": "1682754928",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "14990",
    "dicos": "17",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Samsung Galaxy M14 5G (ICY Silver, 4GB, 128GB Storage) | 50MP Triple Cam | 6000 mAh Battery | 5nm Octa-Core Processor | Android 13 | Without Charger✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LOK3ep"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44bq1li"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oQXqBJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VjvpyU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ncgXfg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vw2h7H"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VeOwKm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AE7vV2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ACefTh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LEv3iN"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@14990"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/817WWpaFo1L._SX466_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Mobiles & Accessories \n                Smartphones & Basic Mobiles \nPryce14990 \nDicos17 \n\n❌Regular Price  : ₹ 17496   /- 😱"
     }
    ]
   },
   {
    "id": 19618,
    "type": "message",
    "date": "2023-04-29",
    "date_unixtime": "1682755073",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "899",
    "dicos": "74",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Spotzero By Milton Twin Star Spin Mop with Bucket | Cleaning Mop | Dry Compartments | Sturdy Basket✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LEJfZh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/426i5jf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oMCXh7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/425Sver"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/426i1jv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AE7JLS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AAER7o"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ADfp0Z"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NoxEPl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NFJeWD"
     },
     {
      "type": "plain",
      "text": "\n\n @899\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71Iq+K-KFeL._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Great Indian Festival Home & Kitchen \n                 Home Improvement \nPryce899 \nDicos74 \n\n❌Regular Price  : ₹ 2499   /- 😱"
     }
    ]
   },
   {
    "id": 19619,
    "type": "message",
    "date": "2023-04-29",
    "date_unixtime": "1682755210",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "2199",
    "dicos": "33",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Bergner Ribera Non Stick Cookware Set, 4Pc-Kadhai with Glass Lid 2.6 L, Dosa Tawa 28cm, Frypan 24cm 1.8 L with Induction Compatible, Soft Touch Bakelite Handles, PFOA Free, Red✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41Mvnlu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oRywln"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44bqeVC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44fvg3e"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oRyLgh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42qB0Wv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oUAEJa"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VkGVK7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ViHHHN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41KXEbW"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@2199"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61JlO9Rpo2L._SY355_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Dining \n                Cookware \nPryce2199 \nDicos33 \n\n❌Regular Price  : ₹ 3099   /- 😱"
     }
    ]
   },
   {
    "id": 19620,
    "type": "message",
    "date": "2023-04-29",
    "date_unixtime": "1682755346",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "75",
    "dicos": "NaN",
    "catagory": "Tools",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Scotch-Brite Scrub 5 Pad (Green)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oQXP7d"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lffm09"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LfRNo0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nmc7Mq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LeOnlv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ngIvA4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LjuSYS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hofx8o"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ne3S5b"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Ho3ckE"
     },
     {
      "type": "plain",
      "text": "\n\n @75\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/81ulmTzK3oL._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Tools"
     },
     {
      "type": "plain",
      "text": " & Home Improvement\n\n                Home & Kitchen \n                Home & kitchen | Prime shopping days \n                Prime Units \nPryce75 \nDicosNaN"
     }
    ]
   },
   {
    "id": 19621,
    "type": "message",
    "date": "2023-04-29",
    "date_unixtime": "1682755484",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "575",
    "dicos": "4",
    "catagory": "Office",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Sakura Gelly Roll Moonlight Roller-Ball Pens Water Based Gel Ink Acid Free, Water, Fade And Chemical Proof (Set Of 10)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HoWR8F"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Nkfuyf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oQJfwk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NnVDhz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LDLIDb"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NnKrBv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40Ii3gw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LFA1Mm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ne5wUu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nfRl18"
     },
     {
      "type": "plain",
      "text": "\n\n @575\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61YsbwlDZsL._SY355_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Office"
     },
     {
      "type": "plain",
      "text": " Products\n\n                Office Products \n                Office Paper Products \n                Paper \nPryce575 \nDicos4 \n\n❌Regular Price  : ₹ 640   /- 😱"
     }
    ]
   },
   {
    "id": 19622,
    "type": "message",
    "date": "2023-04-29",
    "date_unixtime": "1682755619",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "398",
    "dicos": "NaN",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Zebronics Zeb-Corolla In Ear Wired Earphone with Mic, 3.5mm Jack, 1.2 Meter Cable, Multi Function Button✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nfRlOG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40N2DaH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40IK1J6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AE7X5G"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AWx7gh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LGcZos"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LALJI5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LELwnd"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AGM7i5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LCA4bU"
     },
     {
      "type": "plain",
      "text": "\n\n @398\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/713g7tCij5L._SX522_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Headphones, Earbuds & Accessories \n                Headphones \nPryce398 \nDicosNaN \n\n❌Regular Price  : ₹ 550   /- 😱"
     }
    ]
   },
   {
    "id": 19623,
    "type": "message",
    "date": "2023-04-29",
    "date_unixtime": "1682755749",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "260",
    "dicos": "53",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Crompton Dyna Ray 12W Round E27 LED Cool Day Light Pack of 2✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LEvDwZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NmLPnW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/4252Uab"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42brgPJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/426iAd7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AWxmbb"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nbBIHW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42cxUFn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AE8x3m"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NnGRre"
     },
     {
      "type": "plain",
      "text": "\n\n @260\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71OMiOZupuL._SX355_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Indoor Lighting \n                Light Bulbs \nPryce260 \nDicos53 \n\n❌Regular Price  : ₹ 480   /- 😱"
     }
    ]
   },
   {
    "id": 19624,
    "type": "message",
    "date": "2023-04-29",
    "date_unixtime": "1682755857",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "178",
    "dicos": "40",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   ODIO - THE KNOTTY SERIES - OCM06 Premium Nylon Braided Micro USB Cable 1Mtr 2.4A (White & Black Braided)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nbBQao"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41Z8FGq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44qdASX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/447PkVv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ViytLx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VgfhOm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lgsqm3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vw33S9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VgfsJw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41Pk0sM"
     },
     {
      "type": "plain",
      "text": "\n\n @178\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/81E20GwizvL._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Computers & Accessories \n                Accessories & Peripherals \n                Cables & Accessories \nPryce178 \nDicos40 \n\n❌Regular Price  : ₹   180 /- 😱"
     }
    ]
   },
   {
    "id": 19625,
    "type": "message",
    "date": "2023-04-29",
    "date_unixtime": "1682755987",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "564",
    "dicos": "37",
    "catagory": "Grocery",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   GreenFinity Healthy & Tasty Jumbo Pumpkin, Jumbo Sunflower - 250g, Flax Seeds, Chia Seeds - 300g Jar | Seeds for Eating | Immunity Booster Diet | Protein and Rich in Fibre✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3L9ve4o"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LC2nWP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LlAi5I"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LC2Acz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3njloVx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LgsMJp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LePbXz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HLvBRZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Ho3W9q"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NmLXDW"
     },
     {
      "type": "plain",
      "text": "\n\n @564\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/81MuACxAsIL._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Grocery"
     },
     {
      "type": "plain",
      "text": " & Gourmet Foods\n\n                Grocery & Gourmet Foods \n                Dried Fruits, Nuts & Seeds \n                Nuts & Seeds \nPryce564 \nDicos37 \n\n❌Regular Price  : ₹ 799   /- 😱"
     }
    ]
   },
   {
    "id": 19626,
    "type": "message",
    "date": "2023-04-29",
    "date_unixtime": "1682756119",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "222",
    "dicos": "56",
    "catagory": "Beauty",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   glowious Combo Pack of 5 (Orange Peel, Hibiscus, Ubtan, Brahmi & Aloe Vera Powder) 250gms(50gX5)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HoXfUF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HmNw0S"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HpM3qy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40MvtIw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LCt9iK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LEfQOV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41M0L3f"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40IL1gk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41LQ68N"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41MpyEz"
     },
     {
      "type": "plain",
      "text": "\n\n @222\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/81UaJ7ezmJL._SY355_PIbundle-5,TopRight,0,0_AA355SH20_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Beauty"
     },
     {
      "type": "plain",
      "text": "\n\n                Beauty \n                Skin Care \n                Face \nPryce222 \nDicos56 \n\n❌Regular Price  : ₹ 450   /- 😱"
     }
    ]
   },
   {
    "id": 19627,
    "type": "message",
    "date": "2023-04-29",
    "date_unixtime": "1682756255",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "799",
    "dicos": "60",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Attro Pre-Seasoned Cast Iron Double Handle Dosa Tawa Perfect for Cooking on Gas, Induction 12 inch - Black, Standard (Attro_Cstlr_Dostwa_12)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/4453maa"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40NwuzY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40Q0NGj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ABGrpF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AWxHKZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AIPViA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AEouqn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oUlCTF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LGdL4Q"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3neng20"
     },
     {
      "type": "plain",
      "text": "\n\n @799\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61fQxTdkXML._SY355_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Dining \n                Cookware \nPryce799 \nDicos60 \n\n❌Regular Price  : ₹ 1659   /- 😱"
     }
    ]
   },
   {
    "id": 19628,
    "type": "message",
    "date": "2023-04-29",
    "date_unixtime": "1682756395",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "324",
    "dicos": "68",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Attro Non Stick Heavy Duty 7 Cavity Single Handle Uttapam Tawa, Spatter Finish, Black✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VomJaj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40ILe36"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42qBDPR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3n9age6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40WzhHi"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AIQeKg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40Qy1Fg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42qCaBl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/427Tcnn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/429xEXK"
     },
     {
      "type": "plain",
      "text": "\n\n @324\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/31LEFFEZ-eS._SX300_SY300_QL70_FMwebp_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Dining \n                Cookware \nPryce324 \nDicos68 \n\n❌Regular Price  : ₹899    /- 😱"
     }
    ]
   },
   {
    "id": 19629,
    "type": "message",
    "date": "2023-04-29",
    "date_unixtime": "1682756539",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1073",
    "dicos": "53",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Attro Amulya Elegant Designer Celebrations Copper Water Bottle & 2 Glass Set Leak Proof for Yoga, Gym, Home, Office - 1 Liter✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41Z97V8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NvI67w"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NlNfPw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VfqLl8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44b2H7b"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AE99WI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/426jKoZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44eVJ1a"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lkp0if"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LiCU4l"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1073"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71ssW1m33ZL._SY355_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Dining \n                Kitchen Storage & Containers \nPryce1073 \nDicos53 \n\n❌Regular Price  : ₹ 2099   /- 😱"
     }
    ]
   },
   {
    "id": 19630,
    "type": "message",
    "date": "2023-04-29",
    "date_unixtime": "1682756675",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "4278",
    "dicos": "38",
    "catagory": "Industrial",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Polycab 15W LED Panel Light Scintillate Edge Slim Square Smart Offers Bright Lumination Long Lifespan No Harmful Radiation (Neutral White, 4000K, 6 PCS, Cut Out: 5.9 inches)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nmcNkW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41Z9wa6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HpzICZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41MwEcg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41MULrj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oU3Obn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VdWXFN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LdMAgB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LfgnFv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LkCjzj"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@4278"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/41GwyQ3ih6L._SX522_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Industrial"
     },
     {
      "type": "plain",
      "text": " & Scientific\n\n                Home & Kitchen \n                Indoor Lighting \n                Light Bulbs \nPryce4278 \nDicos38 \n\n❌Regular Price  : ₹  5500  /- 😱"
     }
    ]
   },
   {
    "id": 19631,
    "type": "message",
    "date": "2023-04-29",
    "date_unixtime": "1682756811",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "8377",
    "dicos": "33",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Kenstar Eplast 25L Water Heater✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lh8UWC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nhUgX5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lh9hjY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NmfDkE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nhUkpN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HnwpMu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hnq97B"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40T2D9g"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nhUy07"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oVzA7z"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@8377"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/615bS-IgC6S._SY606_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Heating, Cooling & Air Quality \n                Water Heaters & Geysers \nPryce8377 \nDicos33 \n\n❌Regular Price  : ₹11999    /- 😱"
     }
    ]
   },
   {
    "id": 19632,
    "type": "message",
    "date": "2023-04-29",
    "date_unixtime": "1682756955",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1099",
    "dicos": "82",
    "catagory": "Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   TAGG Verve Max II 1.83” Biggest Display Smartwatch with Metal Body | Smart QR Codes to Receive Payments || 120+ Sports Mode | 7 Days Long Battery || Water Resistant || in Build Calculator and Games✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NmfH3S"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41R3jNK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LEQXmc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/446Pi01"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44dFyRp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41LQBQd"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AzWIvf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41Ti2rt"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AFW5Am"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40Ri8yx"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1099"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61QJxzuQLwL._SY355_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Electronics \n                Wearable Technology \n                 Smart Watches \nPryce1099 \nDicos82 \n\n❌Regular Price  : ₹  3499  /- 😱"
     }
    ]
   },
   {
    "id": 19633,
    "type": "message",
    "date": "2023-04-29",
    "date_unixtime": "1682757096",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "174",
    "dicos": "37",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Cello Crystal PET Bottle Set, 1 Litre, Set of 6, Black✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ABwIiO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40N3KXV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40N9aT4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LGyPbM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44aIluT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ne6X5k"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40P55xq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NjrNed"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ViztiL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ACaDkf"
     },
     {
      "type": "plain",
      "text": "\n\n @174\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71+I6P2z5-L._SY355_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Dining \n                Kitchen Storage & Containers \nPryce174 \nDicos37 \n\n❌Regular Price  : ₹  240  /- 😱"
     }
    ]
   },
   {
    "id": 19634,
    "type": "message",
    "date": "2023-04-29",
    "date_unixtime": "1682757228",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "999",
    "dicos": "69",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   pTron Bassbuds Eon Truly Wireless in Ear Earbuds with Mic,ENC, 13mm Driver, Stereo Sound, BT 5.3 Headphone, Quick Pairing, Touch Control, Fast Charging & 30Hrs Playtime, IPX4 & Voice Asst (Grey/Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ACaJbB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oRAwKp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44bZx2W"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VkIOGH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VkyzlY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HoYeEl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VjxhHW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HoNsxB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oNpDcp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42ak71S"
     },
     {
      "type": "plain",
      "text": "\n\n @999\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61ae1vRurNL._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Headphones, Earbuds & Accessories \n                Headphones \nPryce999 \nDicos69 \n\n❌Regular Price  : ₹ 2799   /- 😱"
     }
    ]
   },
   {
    "id": 19635,
    "type": "message",
    "date": "2023-04-29",
    "date_unixtime": "1682757361",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "265",
    "dicos": "50",
    "catagory": "Beauty",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   BOROPLUS Aloe Vera Gel Green Tea, 200ml Jar + Antiseptic Cream, 120ml✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VjaR9m"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41UGZ64"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41PkZJu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lfh7uh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3L9wB32"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HLwOsv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HlYbZC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oWQNxt"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LpehmO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://www.amazon.in/gp/product/B0BB3CX2F9?smid=A15APWRK6P7LBV&amp;th=1&amp;linkCode=sl1&amp;tag=avi1deals10-21&amp;linkId=2e9f98bfd476d7c7a13468035d526246&amp;language=en_IN&amp;ref_=as_li_ss_tl"
     },
     {
      "type": "plain",
      "text": "\n\n @265\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71KZMRn-dlL._SY450_PIbundle-2,TopRight,0,0_AA450SH20_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Beauty"
     },
     {
      "type": "plain",
      "text": "\n\n                Beauty \n                Skin Care \n                Face \nPryce265 \nDicos50 \n\n❌Regular Price  : ₹ 320   /- 😱"
     }
    ]
   },
   {
    "id": 19636,
    "type": "message",
    "date": "2023-04-29",
    "date_unixtime": "1682757493",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "5628",
    "dicos": "38",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Bajaj PX 97 Torque New PMH 36L Personal Air Cooler for home with DuraMarine Pump (2-Yr Warranty by Bajaj), TurboFan Technology, Powerful Air Throw & 3-Speed Control, Portable AC, White cooler for room✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NnFeJR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oSWEnG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44bEu0B"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HqpWQR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/4454Cdo"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/420uiGw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LEfpUR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44h0Uxl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LEx4LT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41Tip5l"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@5628"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61nlH93oiJL._SY355_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Heating, Cooling & Air Quality \n                Air Coolers \nPryce5628 \nDicos38 \n\n❌Regular Price  : ₹  8599  /- 😱"
     }
    ]
   },
   {
    "id": 19637,
    "type": "message",
    "date": "2023-04-29",
    "date_unixtime": "1682757602",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "10999",
    "dicos": "71",
    "catagory": "Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   ZEBRONICS 24 inch 165Hz Gaming Monitor with FHD 1080p, IPS Panel, 1ms MPRT, HDR10, Free sync Support, HDMI, DP, 250 Nits max, 16.7M Colors, Built-in Speakers and Ultra Slim Bezel Less Design ZEB-S24A✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AERsGC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LGz9Hw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Ho59xu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AE9R6k"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HoNQfx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nctIGC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LEFP8M"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nhif8T"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LERIvy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AExThK"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@10999"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/81dGEdml2WL._SY355_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Computers & Accessories \n                2021 \n                 Gaming Monitors \nPryce10999 \nDicos71 \n\n❌Regular Price  : ₹ 24999   /- 😱"
     }
    ]
   },
   {
    "id": 19638,
    "type": "message",
    "date": "2023-04-29",
    "date_unixtime": "1682757712",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "913",
    "dicos": "13",
    "catagory": "Shoes",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   eeken Mens Mike Polyester Sneakers by Paragon✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LDNJPL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oNpFRz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ABdo5x"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44h0VRV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LExmSZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LExnq1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44aLmv8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44aISwT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Nq1p2o"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3n6ZLYN"
     },
     {
      "type": "plain",
      "text": "\n\n @913\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/81q1urE1BZL._UX500_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Shoes"
     },
     {
      "type": "plain",
      "text": " & Handbags\n\n                Shoes & Handbags \n                Shoes \n                Men's Shoes \nPryce913 \nDicos13 \n\n❌Regular Price  : ₹ 1035   /- 😱"
     }
    ]
   },
   {
    "id": 19639,
    "type": "message",
    "date": "2023-04-29",
    "date_unixtime": "1682757822",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "2659",
    "dicos": "37",
    "catagory": "Tools",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Wipro Smart Camera 1080P | Full HD Picture | AI Powered Motion Detection | Infrared Night Vision | 360° Panorama | Talk Back Feature (2-Way Audio)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41NvYDr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nbhBd3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40TYbqF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VkyZc2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NhOxv4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/429yAve"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VghKs8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VdXKXh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40NTBKE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VdXMOT"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@2659"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/41lhclnKThL._SY450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Tools"
     },
     {
      "type": "plain",
      "text": " & Home Improvement\n\n                Electronics \n                Cameras & Photography \n                Security Cameras \nPryce2659 \nDicos37 \n\n❌Regular Price  : ₹ 3999   /- 😱"
     }
    ]
   },
   {
    "id": 19640,
    "type": "message",
    "date": "2023-04-29",
    "date_unixtime": "1682757956",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "16199",
    "dicos": "71",
    "catagory": "Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   SanDisk 2TB Extreme Portable SSD 1050MB/s R, 1000MB/s W,Upto 2 Meter Drop Protection with IP55 Water/dust Resistance, HW Encryption, PC,MAC & TypeC Smartphone Compatible, 5Y Warranty, External SSD✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oWrzQ8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AIRCfW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LC3Jkn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3L9wJ2w"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lgup9Z"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LlBO7U"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AERSwG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NjZdcr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NmNOIU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NvJBTc"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@16199"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71V5mS+FNnL._SY450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Computers & Accessories \n                External Devices & Data Storage \n                 External Solid State Drives \nPryce16199 \nDicos71 \n\n❌Regular Price  : ₹ 35999   /- 😱"
     }
    ]
   },
   {
    "id": 19641,
    "type": "message",
    "date": "2023-04-29",
    "date_unixtime": "1682758090",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "24999",
    "dicos": "31",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   MI 108 cm (43 inches) 5A Series Full HD Smart Android LED TV L43M7-EAIN (Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/446QbFT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hmi4Qt"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Nmgu4Q"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oNqcTz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/429zbNu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NqJuZd"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ViKTDh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ViAUO5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/426kXg1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/424pQ9y"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@24999"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/811UAL1+6DL._SY450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Home Theater, TV & Video \n                Televisions \nPryce24999 \nDicos31 \n\n❌Regular Price  : ₹32999    /- 😱"
     }
    ]
   },
   {
    "id": 19642,
    "type": "message",
    "date": "2023-04-29",
    "date_unixtime": "1682758221",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "399",
    "dicos": "50",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Portronics Power Plate 11 1500W Power Converter with 4 Power Sockets, 3M Cord Length, Short Circuit Protection, Imported Intelligent Chips (White)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Llfmvw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oVCJ7q"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oR0xcH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LfU8PO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nmekaG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40T3AhQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LhCY4v"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LhP2T9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LkxGoE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44fvLdA"
     },
     {
      "type": "plain",
      "text": "\n\n @399\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/41NmaKdBz4L._SY355_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Home Audio \n                Accessories \nPryce399 \nDicos50 \n\n❌Regular Price  : ₹ 650   /- 😱"
     }
    ]
   },
   {
    "id": 19643,
    "type": "message",
    "date": "2023-04-29",
    "date_unixtime": "1682758356",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "529",
    "dicos": "52",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Tosaa Hot & Cold Double Wall Vacuum Insulated Flask Water Bottle Stainless Steel 1000 ML✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LkqvNp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40SBjb8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nlNTC1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40IMFi0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NnMtS9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41MxZ2M"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AzXWGR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ACbu4r"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AASD9Y"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HqbOHn"
     },
     {
      "type": "plain",
      "text": "\n\n @529\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61taBeBzNcL._SY679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Dining \n                Kitchen Storage & Containers \nPryce529 \nDicos52 \n\n❌Regular Price  : ₹ 1099   /- 😱"
     }
    ]
   },
   {
    "id": 19644,
    "type": "message",
    "date": "2023-04-29",
    "date_unixtime": "1682758486",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "2149",
    "dicos": "43",
    "catagory": "Musical",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Koss UR23IK Headphones (Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HjDTjp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41Pap5d"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HskKfl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41NwmBT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41UHWLG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LEeVOi"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HskNrx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/424pS1a"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42514pM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HnrMSL"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@2149"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61Efbi5FFVL._SY355_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Musical"
     },
     {
      "type": "plain",
      "text": " Instruments\n\n                Electronics \n                Headphones, Earbuds & Accessories \n                Headphones \nPryce2149 \nDicos43 \n\n❌Regular Price  : ₹1899   /- 😱"
     }
    ]
   },
   {
    "id": 19645,
    "type": "message",
    "date": "2023-04-29",
    "date_unixtime": "1682758616",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "42130",
    "dicos": "83",
    "catagory": "Musical",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   QSC TouchMix-30 Pro Professional Digital Mixer✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HLxLkz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ABxOLs"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40TYH85"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44qfO4L"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LEqOnu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LFy56H"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41MynhK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NoTmTs"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41PaJAX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41UIb9y"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@42130"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/91WvJIBib2L._SX450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Musical"
     },
     {
      "type": "plain",
      "text": " Instruments\n\n                Musical Instruments \n                DJ & VJ Equipment \n                 DJ Mixer \nPryce42130 \nDicos83 \n\n❌Regular Price  : ₹ 120999   /- 😱"
     }
    ]
   },
   {
    "id": 19646,
    "type": "message",
    "date": "2023-04-29",
    "date_unixtime": "1682758747",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "2349",
    "dicos": "51",
    "catagory": "Clothing",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Arrow Sports Men AS_High Neck Jacket_4799✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oUnhsn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VdYgVd"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oY4Rqs"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/4255XPF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/4266XmK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3L9xjNK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vgis8M"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40INaso"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lknblq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nmeHlA"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@2349"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71mzgYq02IL._UX466_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Clothing"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Clothing & Accessories \n                Men \n                Winter Wear \nPryce2349 \nDicos51 \n\n❌Regular Price  : ₹  4099  /- 😱"
     }
    ]
   },
   {
    "id": 19647,
    "type": "message",
    "date": "2023-04-29",
    "date_unixtime": "1682758877",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1924",
    "dicos": "45",
    "catagory": "Clothing",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   U.S. POLO ASSN. Men Sweatshirt✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42a06Zg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AChnyv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AEvbZG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AEaUTO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Ho6692"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44bFim9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44gOtBY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44e1pYZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VgAUOE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Nod162"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1924"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/81sCiNuo26L._UX466_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Clothing"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Clothing & Accessories \n                Men \n                Winter Wear \nPryce1924 \nDicos45 \n\n❌Regular Price  : ₹ 3199   /- 😱"
     }
    ]
   },
   {
    "id": 19648,
    "type": "message",
    "date": "2023-04-29",
    "date_unixtime": "1682759024",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1119",
    "dicos": "60",
    "catagory": "Clothing",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Jack & Jones Stripes Cotton Regular Fit Mens Casual Shirt (S22JJ106872201-P)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3n5Cq9S"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VeRkHo"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40Mxtk0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41Lwy4H"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oRpWmy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oRpXH8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LcbiOi"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/4455LBI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LfUIwY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LkqYPF"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1119"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61qUIC4LU1L._UX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Clothing"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Clothing & Accessories \n                Men \n                Shirts \nPryce1119 \nDicos60 \n\n❌Regular Price  : ₹ 2299   /- 😱"
     }
    ]
   },
   {
    "id": 19649,
    "type": "message",
    "date": "2023-04-29",
    "date_unixtime": "1682759159",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "81900",
    "dicos": "18",
    "catagory": "All",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Apple 2020 MacBook Air Laptop M1 chip, 13.3-inch/33.74 cm Retina Display, 8GB RAM, 256GB SSD Storage, Backlit Keyboard, FaceTime HD Camera, Touch ID. Works with iPhone/iPad; Space Grey✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44fzeZG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40O3Qib"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oWShrx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40NywA6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AEyOic"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40O3V5t"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ADij5T"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NnGtbZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HLynGT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LAO45P"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@81900"
     },
     {
      "type": "plain",
      "text": "\n \n "
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71jG+e7roXL._SX522_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#All"
     },
     {
      "type": "plain",
      "text": "\n\nPryce81900 \nDicos18 \n\n❌Regular Price  : ₹  94999  /- 😱"
     }
    ]
   },
   {
    "id": 19650,
    "type": "message",
    "date": "2023-04-29",
    "date_unixtime": "1682759296",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "649",
    "dicos": "57",
    "catagory": "Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Lenovo 15.6\" (39.62cm) Slim Everyday Backpack, Made in India, Compact, Water-resistant, Organized storage:Laptop sleeve,tablet pocket,front workstation,2-side pockets,Padded adjustable shoulder straps✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41OoW10"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LCvl9Y"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oJ3EmU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ABMvhK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/427E1L3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40RjPMp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oK9B2W"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40Q33xh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oY5vnS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40NyFUa"
     },
     {
      "type": "plain",
      "text": "\n\n @649\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61v0-iGlR1L._SY606_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Computers & Accessories \n                Accessories & Peripherals \n                Laptop Accessories \nPryce649 \nDicos57 \n\n❌Regular Price  : ₹1300    /- 😱"
     }
    ]
   },
   {
    "id": 19651,
    "type": "message",
    "date": "2023-04-29",
    "date_unixtime": "1682759431",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "369",
    "dicos": "66",
    "catagory": "Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   SanDisk Cruzer Blade 64GB USB 2.0 Flash Drive✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LGAqOO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Np66JE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41Pbji7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nhiiBG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VeUL0C"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VnBLxa"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Nq2kjm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oWSH15"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VnBPgo"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VfaTiA"
     },
     {
      "type": "plain",
      "text": "\n\n @369\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61lfthIflfL._SX466_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Computers & Accessories \n                External Devices & Data Storage \n                 Pen Drives \nPryce369 \nDicos66 \n\n❌Regular Price  : ₹ 999   /- 😱"
     }
    ]
   },
   {
    "id": 19652,
    "type": "message",
    "date": "2023-04-29",
    "date_unixtime": "1682759571",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "4428",
    "dicos": "53",
    "catagory": "Sports",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Beetle Vintage 20T Kids’ Bike, 12 Inch Frame, Maroon, Single Speed Steel Frame Bike with Retro Looks, Ideal for 6-10 Years Unisex, dicos 3.5-4.5 feet✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LeRB8B"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LiFpnf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nckt9s"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42abeVZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HnBdS3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AATs2y"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HoioOE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oSY95i"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NkiMBB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AFY6wq"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@4428"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/611zv0ZvpzL._SX450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Sports"
     },
     {
      "type": "plain",
      "text": ", Fitness & Outdoors\n\n                Sports, Fitness & Outdoors \n                Cycling \n                Cycles \nPryce4428 \nDicos53 \n\n❌Regular Price  : ₹ 8599   /- 😱"
     }
    ]
   },
   {
    "id": 19653,
    "type": "message",
    "date": "2023-04-29",
    "date_unixtime": "1682759705",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "448",
    "dicos": "40",
    "catagory": "Beauty",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   The Man Company Privilege Deodorants for Men - Legend, Bold & Intensey, Premium Fragrance with Long-Lasting Smell, Everyday Use Deo Combo Pack (150ml* 3)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44g2cZu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44aK7fx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Nodoxs"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vw5GU1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VeVezU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vgjf9K"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42abog3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VimzBk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41NRrfs"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3L9xmZK"
     },
     {
      "type": "plain",
      "text": "\n\n @448\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51U0dk6sgDL._SX450_PIbundle-3,TopRight,0,0_SX450SY409SH20_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Beauty"
     },
     {
      "type": "plain",
      "text": "\n\n                Beauty \n                Bath & Body \n                Deodorants & Antiperspirants \nPryce448 \nDicos40 \n\n❌Regular Price  : ₹ 699   /- 😱"
     }
    ]
   },
   {
    "id": 19654,
    "type": "message",
    "date": "2023-04-29",
    "date_unixtime": "1682759838",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "7200",
    "dicos": "42",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Maharaja Whiteline Warmist Neo Storage Water Heater, 10 Litre, Glasslined Coating, Rust & Shock Proof Abs Body, Blue & White Color, Free Installation, Free Connection Pipes - Warmist Neo/Wh-164✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LkyHgs"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HpOwkO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hm02h2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LkobpG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LkrVHJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NmhMgc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NiKC13"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41R1lNw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LEipR3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NrcYWS"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@7200"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/515MMlQqJSL._SY355_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Heating, Cooling & Air Quality \n                Water Heaters & Geysers \nPryce7200 \nDicos42 \n\n❌Regular Price  : ₹10799    /- 😱"
     }
    ]
   },
   {
    "id": 19655,
    "type": "message",
    "date": "2023-04-29",
    "date_unixtime": "1682759975",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1694",
    "dicos": "72",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Infinity (JBL) Swing 320 by Harman 19 Hours of Playtime, Dual Equalizers, Dual Connect, Bluetooth 5.0, Type C & Voice Assistant Support Bluetooth Truly Wireless in Ear Earbuds with Mic - Black✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/423xkKx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42am2n6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41Msr8n"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/426PwCj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/428Pmuh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/426PyKr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41PrSdT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40OCokf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40SCIOW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40SCIOW"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1694"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51UwXG8jNXL._SY450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Headphones, Earbuds & Accessories \n                Headphones \nPryce1694 \nDicos72 \n\n❌Regular Price  : ₹ 3999   /- 😱"
     }
    ]
   },
   {
    "id": 19656,
    "type": "message",
    "date": "2023-04-29",
    "date_unixtime": "1682760111",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "698",
    "dicos": "86",
    "catagory": "Clothing",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   VIMAL JONNEY Dryfit Lycra Solid Multicolor T-Shirt for Men (Pack of 5)-Dryfit_T_BLK_BLU_Gry_L.Gry_OLV_05-P✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LFDhHA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NmPgek"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44gsQBJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LCvRoA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44h2uPN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LkEXFf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LjFOFR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/4144YOZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oQsuS6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oQsuS6"
     },
     {
      "type": "plain",
      "text": "\n\n @698\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71SqXTNwUBL._UX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Clothing"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Clothing & Accessories \n                Men \n                T-Shirts & Polos \nPryce698 \nDicos86 \n\n❌Regular Price  : ₹ 1999   /- 😱"
     }
    ]
   },
   {
    "id": 19657,
    "type": "message",
    "date": "2023-04-29",
    "date_unixtime": "1682760252",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "23990",
    "dicos": "29",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Samsung 236 L 2 Star Digital Inverter Frost Free Double Door Refrigerator (RT28C3452BX/HL, Luxe Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VkBhYG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nbFcKw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NnH7pV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/426mKSh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AEwEPG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42buMJV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NvLFKW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44e2AYp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40IUyEd"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44aKs1N"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@23990"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/416U1wKnBLL._SY445_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Large Appliances \n                 Refrigerators \nPryce23990 \nDicos29 \n\n❌Regular Price  : ₹ 30999   /- 😱"
     }
    ]
   },
   {
    "id": 19658,
    "type": "message",
    "date": "2023-04-29",
    "date_unixtime": "1682760390",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "99",
    "dicos": "67",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Attro Iceberg Plastic Unbreakable Fridge Water Bottle for Office, Sports, School, Travelling, Gym, Yoga-BPA And Leak Free, Assorted 1000 ml - Set of 2✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44dIdun"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40MyCbi"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/4456JxQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/4252Y9U"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42buSRN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oUEOAM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/449TFHv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44fAlbO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AFC9NM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ABK2nF"
     },
     {
      "type": "plain",
      "text": "\n\n @99\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/91TQk371oGL._SY355_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Dining \n                Kitchen Storage & Containers \nPryce99 \nDicos67 \n\n❌Regular Price  : ₹ 250   /- 😱"
     }
    ]
   },
   {
    "id": 19659,
    "type": "message",
    "date": "2023-04-29",
    "date_unixtime": "1682760523",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "512",
    "dicos": "72",
    "catagory": "Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Bennett Laptop Bag for Menं & Women 15.6 inch, 25 L Water Resistant Bag Laptop Apple/Dell/Lenovo/Asus/Hp/Samsung/Mi/MacBook/Ultrabook/Thinkpad/Ideapad/Surfacepro (Blue)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41Pnc7K"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40NbsBE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nbe7Y3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HnBYKT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HjFfdZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hsm7dZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40NVyGY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hqdd0B"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HnC59h"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hqs5Mp"
     },
     {
      "type": "plain",
      "text": "\n\n @512\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/91bzxvU2ZuL._SY679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Computers & Accessories \n                Accessories & Peripherals \n                Laptop Accessories \nPryce512 \nDicos72 \n\n❌Regular Price  : ₹ 1299   /- 😱"
     }
    ]
   },
   {
    "id": 19660,
    "type": "message",
    "date": "2023-04-29",
    "date_unixtime": "1682760657",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "6399",
    "dicos": "36",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Crompton Marvel Neo Inverter Compatible Portable Personal Air Cooler (40L, White).✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LC08ns"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/423xPEp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41ZbZRU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hqs9M9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LGrKYL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LCDKdI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41JAS4e"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oPr36i"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oWtu7i"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44gPOsu"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@6399"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/41V0bcp5nsL._SY355_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Heating, Cooling & Air Quality \n                Air Coolers \nPryce6399 \nDicos36 \n\n❌Regular Price  : ₹ 9499   /- 😱"
     }
    ]
   },
   {
    "id": 19661,
    "type": "message",
    "date": "2023-04-29",
    "date_unixtime": "1682760769",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "494",
    "dicos": "45",
    "catagory": "Health",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Nova NHT 1076 Cordless: 30 Minutes Runtime Trimmer for Men (Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44e2PTj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NtfQCR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vw6NTH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VgfCR7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40MIe5J"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ADjvWV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HjPvTS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AEIQzO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41JB4jY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NhQM1s"
     },
     {
      "type": "plain",
      "text": "\n\n @494\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51WJQh6Zb3S._SX522_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Health"
     },
     {
      "type": "plain",
      "text": " & Personal Care\n\n                Health & Personal Care \n                Personal Care \n                 Shaving, Waxing & Beard Care \nPryce494 \nDicos45 \n\n❌Regular Price  : ₹ 799   /- 😱"
     }
    ]
   },
   {
    "id": 19662,
    "type": "message",
    "date": "2023-04-29",
    "date_unixtime": "1682760886",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "99",
    "dicos": "NaN",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Kitchenette Baking and Cooking Parchment Paper - 5 Meters|| Food Grade Non Stick Fat Free Cooking|| Coreless Roll Butter Paper|| Cake, Sandwich, Pizza, Burger Baking || Microwave Safe|| Eco-Friendly ||✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AExbkE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Np7lZk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HqdOzn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44e2W1b"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3n7f1Vv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3n9dFto"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3n5DQBe"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nf1sTS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oUp3d1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44dIZHN"
     },
     {
      "type": "plain",
      "text": "\n\n @99\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71vKB-4IEXL._SX355_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Dining \n                Kitchen Tools \nPryce99 \nDicosNaN \n\n❌Regular Price  : ₹  99   /- 😱"
     }
    ]
   },
   {
    "id": 19663,
    "type": "message",
    "date": "2023-04-29",
    "date_unixtime": "1682761020",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "999",
    "dicos": "77",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   ZEBRONICS Sound Bomb 7 Bluetooth TWS in Ear Earbuds with 40H Playtime, ENC Mic, Rapid Charge, Upto 50ms Gaming Mode, Flash Connect, Voice Assistant, Smooth Touch Control, BT v5.2, Type C (White)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NrdQL8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LCwUES"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://www.amazon.in/dp/B09Z838S32?th=1&amp;linkCode=sl1&amp;tag=deals103-21&amp;linkId=788c699270cba9e96c9953820577d34d&amp;language=en_IN&amp;ref_=as_li_ss_tl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ADjLFn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vgj8uO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VkLzI3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vi7kbp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LeSyxH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VinHF4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41NSBaO"
     },
     {
      "type": "plain",
      "text": "\n\n @999\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51HGnDMQmcL._SX522_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Headphones, Earbuds & Accessories \n                Headphones \nPryce999 \nDicos77 \n\n❌Regular Price  : ₹  2499    /- 😱"
     }
    ]
   },
   {
    "id": 19664,
    "type": "message",
    "date": "2023-04-29",
    "date_unixtime": "1682761129",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "4999",
    "dicos": "67",
    "catagory": "Sports",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Leader Lady Star Breeze 26T Bicycle for Girls/Women with Front Basket and Integrated Carrier | Ideal for 12 + Years (Frame: 18 Inches) (26, Aqua Blue)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nbFXmQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41OGcDA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3L9yLQ0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AAUV94"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AExsUI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AITZiQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40SDLym"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LGBRNc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LFmfti"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3L9ze4U"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@4999"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/41TOn1RVC9L._SX300_SY300_QL70_FMwebp_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Sports"
     },
     {
      "type": "plain",
      "text": ", Fitness & Outdoors\n\n                Sports, Fitness & Outdoors \n                Cycling \n                Cycles \nPryce4999 \nDicos67 \n\n❌Regular Price  : ₹   9999  /- 😱"
     }
    ]
   },
   {
    "id": 19665,
    "type": "message",
    "date": "2023-04-29",
    "date_unixtime": "1682761265",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "899",
    "dicos": "67",
    "catagory": "Beauty",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Wahl 09865-124 Pure Confidence Rechargable Grooming Kit, Purple✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LDQAYZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/426Qsqj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oR2Uw7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NmQd6o"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HjG06P"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Np7NH0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41OLEGn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41LyVo7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/423yyp7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41OLIpB"
     },
     {
      "type": "plain",
      "text": "\n\n @899\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71ubzj2T8cS._SY450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Beauty"
     },
     {
      "type": "plain",
      "text": "\n\n                Health & Personal Care \n                Personal Care \n                 Shaving, Waxing & Beard Care \nPryce899 \nDicos67 \n\n❌Regular Price  : ₹  1999   /- 😱"
     }
    ]
   },
   {
    "id": 19666,
    "type": "message",
    "date": "2023-04-29",
    "date_unixtime": "1682761395",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "499",
    "dicos": "75",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   boAt WCDV 20W Wall Charger with Fast Charging for PD Devices(iPhone Type-c Adapter), Smart IC Protection, Auto Detection and Corrosion Resistant Pins Compatible with All PD Supported Devices(White)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oQNNTq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ABO7bi"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AIU1Hu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nbf7vh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oY7dFO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NhRpbk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3n7fG9r"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44h3t2r"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NmQkyQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oRK206"
     },
     {
      "type": "plain",
      "text": "\n\n @499\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/41JnZRv9qQL._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Mobiles & Accessories \n                Mobile Accessories \nPryce499 \nDicos75 \n\n❌Regular Price  : ₹   499  /- 😱"
     }
    ]
   },
   {
    "id": 19667,
    "type": "message",
    "date": "2023-04-29",
    "date_unixtime": "1682761528",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "899",
    "dicos": "64",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   ZEBRONICS Zeb-DUKE1 Wireless Bluetooth 5.0 Over Ear Headphone with Voice Assistant, AUX Port, Call Function, 34Hrs* Battery Backup, Dual Pairing, Media & Volume Control with mic (Green)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nhjQM0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/427WBm9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LDXZaH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vi7RKr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HkEm52"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41ZcYS6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/427WGpX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40P2Lqp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40MzBbu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3n8wGfx"
     },
     {
      "type": "plain",
      "text": "\n\n @899\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61UmV5OukDL._SY450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Headphones, Earbuds & Accessories \n                Headphones \nPryce899 \nDicos64 \n\n❌Regular Price  : ₹  1699  /- 😱"
     }
    ]
   },
   {
    "id": 19668,
    "type": "message",
    "date": "2023-04-29",
    "date_unixtime": "1682761659",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "337",
    "dicos": "44",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Hitage Active fir Sport Earphone Neckband Wired with mic - NBH-725 (Green)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Nmjcr2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/422FORm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oRDyyh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44aMfUv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44qim2P"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NqMjth"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44gtXBp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44cuhRu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VgDnIU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/426nW8d"
     },
     {
      "type": "plain",
      "text": "\n\n @337\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/5172y2jR5bL._SX522_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Headphones, Earbuds & Accessories \n                Headphones \nPryce337 \nDicos44 \n\n❌Regular Price  : ₹  599    /- 😱"
     }
    ]
   },
   {
    "id": 19669,
    "type": "message",
    "date": "2023-04-29",
    "date_unixtime": "1682761769",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "232",
    "dicos": "77",
    "catagory": "Shoes",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Fusefit mens Gogo Slipper✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oMIHaF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VgkRQQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oUpELN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42adixd"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VjAzeg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LfjIV3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oUG07e"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ABgQNx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LfjLjH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oUlbZH"
     },
     {
      "type": "plain",
      "text": "\n\n @232\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61DEmCfBRfL._UY535_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Shoes"
     },
     {
      "type": "plain",
      "text": " & Handbags\n\n                Shoes & Handbags \n                Shoes \n                Men's Shoes \nPryce232 \nDicos77 \n\n❌Regular Price  : ₹ 899   /- 😱"
     }
    ]
   },
   {
    "id": 19670,
    "type": "message",
    "date": "2023-04-29",
    "date_unixtime": "1682761879",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1099",
    "dicos": "66",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Lifelong PVC Home Gym Set 10Kg Plate 3Feet Curl Rod and Dumbbells Rods with Gym Accessories, Black✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LgxXcj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41OpJiI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41L2HJq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nmhdZ4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40P88pi"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40N7RDl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40P9Ery"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hp2x2t"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41TlMcv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41OM2oj"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1099"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/81f76RWGiYL._SY450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Sports, Fitness & Outdoors \n                Exercise & Fitness \n                Strength Training Equipment \nPryce1099 \nDicos66 \n\n❌Regular Price  : ₹  2499   /- 😱"
     }
    ]
   },
   {
    "id": 19671,
    "type": "message",
    "date": "2023-04-29",
    "date_unixtime": "1682762016",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1099",
    "dicos": "82",
    "catagory": "Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   boAt Wave Prime47 Smart Watch with 1.69\" HD Display, 700+ Active Modes, ASAP Charge, Live Cricket Scores, Crest App Health Ecosystem, HR & SpO2 Monitoring(Royal Blue)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nhlCg3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41PtHrf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41Nz4r3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Nk2dFJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oQOhsI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3n72Rfn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41NTvUK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41MAXEs"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LC7NCf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LOPD0l"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1099"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61c1JhpcwWL._SY450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Electronics \n                Wearable Technology \n                 Smart Watches \nPryce1099 \nDicos82 \n\n❌Regular Price  : ₹  3999  /- 😱"
     }
    ]
   },
   {
    "id": 19672,
    "type": "message",
    "date": "2023-04-29",
    "date_unixtime": "1682762147",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1249",
    "dicos": "41",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   La Opala, Novo Collection, Opal Glass Dinner Set 18 pcs, Lush Greens, White✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40ODZXh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40Qf5GW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LkpKE4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LFn2dK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nbGG7y"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NnLFgg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40P8kow"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3njpXiD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44oNAr0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44aM0J9"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1249"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71AHLXNT00L._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Dining \n                Tableware \nPryce1249 \nDicos41 \n\n❌Regular Price  : ₹   1899   /- 😱"
     }
    ]
   },
   {
    "id": 19673,
    "type": "message",
    "date": "2023-04-29",
    "date_unixtime": "1682762252",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "34925",
    "dicos": "30",
    "catagory": "Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Lenovo IdeaPad Slim 3 2021 11th Gen Intel Core i3 15.6 FHD Thin & Light Laptop (8GB/256GB SSD/Windows 10/MS Office/2 Year Warranty/Arctic Grey/1.65Kg), 82H801CUIN✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AEBr3y"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NFPbTt"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LOPNEZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LEkoEZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/422UNLc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/446Tqx3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Np8DDE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/4258Wrl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/424tuQM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oUG9Yk"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@34925"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61q6x-ll5FL._SX522_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Computers & Accessories \n                Laptops \n                 Traditional Laptops \nPryce34925 \nDicos30 \n\n❌Regular Price  : ₹  44999   /- 😱"
     }
    ]
   },
   {
    "id": 19674,
    "type": "message",
    "date": "2023-04-29",
    "date_unixtime": "1682762354",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "899",
    "dicos": "55",
    "catagory": "Health",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Bombay Shaving Co Trimmer Men, 2X Fast Charging, 2 Yr Warranty, 80Min runtime, Hair Trimmer, Shaving Machine, Cordless Beard, 38 length Settings, Flash USB Cable fast Charging (With Men’s Fragrance worth Rs. 795/- FREE)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LFBvX5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AG3CPm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3n8xDo7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VgDQLa"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40P8pbO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VkMmZx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40O6t3x"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AK9BTf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40KNXJf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40U20Mx"
     },
     {
      "type": "plain",
      "text": "\n\n @899\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61SYIr8QUzL._SY355_PIbundle-2,TopRight,0,0_AA355SH20_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Health"
     },
     {
      "type": "plain",
      "text": " & Personal Care\n\n                Health & Personal Care \n                Personal Care \n                Shaving, Waxing & Beard Care \nPryce899 \nDicos55 \n\n❌Regular Price  : ₹  1699    /- 😱"
     }
    ]
   },
   {
    "id": 19675,
    "type": "message",
    "date": "2023-04-29",
    "date_unixtime": "1682762457",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "6399",
    "dicos": "36",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Crompton Marvel Neo Inverter Compatible Portable Personal Air Cooler (40L, White).✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40Q5Nuz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oUpPXt"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/428SwOM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AEyikk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AA1DfH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44b6JMR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44b6LEt"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Nmk1jC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/4458hYG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44h4tDJ"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@6399"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/41V0bcp5nsL._SY355_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Heating, Cooling & Air Quality \n                Air Coolers \nPryce6399 \nDicos36 \n\n❌Regular Price  : ₹  9499   /- 😱"
     }
    ]
   },
   {
    "id": 19676,
    "type": "message",
    "date": "2023-04-29",
    "date_unixtime": "1682762583",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "8948",
    "dicos": "38",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   HUL Pureit Copper UV Tankless Water Purifier (White) with Dual Dispensing feature (Not Suitable for tanker or borewell water)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44aNgvN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AEKdys"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AEtjjt"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LC1T42"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42aw5se"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/427GxRv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LHorR6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VdXure"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LEkV9X"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3L9zII4"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@8948"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/41h8EifUQPL._SY355_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Home Appliances \n                Water Purifiers & Accessories \nPryce8948 \nDicos38 \n\n❌Regular Price  : ₹  12999    /- 😱"
     }
    ]
   },
   {
    "id": 19677,
    "type": "message",
    "date": "2023-04-29",
    "date_unixtime": "1682762708",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "349",
    "dicos": "12",
    "catagory": "Beauty",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Park Avenue Good Morning Combo Perfume For Men Fresh Long Lasting Fragrance Super Saver Pack, 300ml (Pack Of 2)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41LzEpl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40T783E"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LkGXgJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40NBnsO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3L9AeWI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41ULDkw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41NzyNT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41PqMPt"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HmlHG5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oQOJqU"
     },
     {
      "type": "plain",
      "text": "\n\n @349\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71jMXBI46hL._SY450_PIbundle-2,TopRight,0,0_AA450SH20_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Beauty"
     },
     {
      "type": "plain",
      "text": "\n\n                Beauty \n                Bath & Body \n                Deodorants & Antiperspirants \nPryce349 \nDicos12 \n\n❌Regular Price  : ₹ 390   /- 😱"
     }
    ]
   },
   {
    "id": 19678,
    "type": "message",
    "date": "2023-04-29",
    "date_unixtime": "1682762811",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1099",
    "dicos": "27",
    "catagory": "Toys",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Dash Monkey Ride On for Kids, Baby Car, Ride On for Kids 2 Years+, Push Car for Baby with Backrest and Under Seat Storage Utility Box for Toys (Capacity 20kg | Green)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41MBhDa"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hoh3aK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hqff0J"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VdRzlX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oUGESc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hql4eK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NnQ96p"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LFBG4H"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3njqqkT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LkB6ru"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1099"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71UeaIzhB2L._SY355_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Toys"
     },
     {
      "type": "plain",
      "text": " & Games\n\n                Toys & Games \n                Bikes, Trikes & Ride-Ons \n                 Ride-Ons \nPryce1099 \nDicos27 \n\n❌Regular Price  : ₹ 1299   /- 😱"
     }
    ]
   },
   {
    "id": 19679,
    "type": "message",
    "date": "2023-04-29",
    "date_unixtime": "1682762915",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "499",
    "dicos": "30",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Larah by Borosil Red Carnation (LH) Cup and Saucer Set, 140ml, 12-Pieces, White, HT12CS14RCA1, HT12CS14RCA1 (Opalware)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LDYNML"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41LVqZP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LDS05L"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LGtAZF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40SETSC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AIVtJW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NqMXqH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40NXE9O"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/4458qeG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NjvZdX"
     },
     {
      "type": "plain",
      "text": "\n\n @499\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51ac6nWGWyL._SX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Dining \n                Tableware \nPryce499 \nDicos30 \n\n❌Regular Price  : ₹699    /- 😱"
     }
    ]
   },
   {
    "id": 19680,
    "type": "message",
    "date": "2023-04-29",
    "date_unixtime": "1682763039",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "571",
    "dicos": "20",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Larah by Borosil Opalware Classic Cup and Saucer Set, 145ml, 12-Pieces, White, HT12CS14CSC1, HT12CS14CSC1✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NlRHOe"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/425hcYk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NiNb3b"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42cCyDn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LGj5VS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oNunPf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40U2zpD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VeYs6u"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VeYtHA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AKatqZ"
     },
     {
      "type": "plain",
      "text": "\n\n @571\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/41hsQ7ZidcL._SX300_SY300_QL70_FMwebp_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Dining \n                Tableware \nPryce571 \nDicos20 \n\n❌Regular Price  : ₹  699  /- 😱"
     }
    ]
   },
  {
    "id": 19357,
    "type": "message",
    "date": "2023-04-28",
    "date_unixtime": "1682667020",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1799",
    "dicos": "64",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   boAt Airdopes 458 TWS Wireless Earbuds with Spatial Bionic Sound by THX,in Ear, Enx Tech, 30H Playtime, Beast Mode, Signature Sound, ASAP Charge,IPX5,Touch Control(Active Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40OKysZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40Kb0nx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40KBzc7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40Lqpny"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/423Pvit"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44mghF0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AyFpL1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/445m0ip"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/423qOTx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ACasWg"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1799"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61BxCybXWtL._SX522_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Headphones, Earbuds & Accessories \n                Headphones \nPryce1799 \nDicos64 \n\n❌Regular Price  : ₹ 3699   /- 😱"
     }
    ]
   },
   {
    "id": 19358,
    "type": "message",
    "date": "2023-04-28",
    "date_unixtime": "1682667143",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "299",
    "dicos": "84",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   PTron Boom Ultima 4D Dual Driver, in-Ear Gaming Wired Headphones with in-line Mic, Volume Control & Passive Noise Cancelling Boom 3 Earphones - (Dark Blue)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oRBPJn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AynzaS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/420sEEy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HnFa9j"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AAMz1d"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ABZkZy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hjxc0M"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/429300D"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VaJxKJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NjKnCQ"
     },
     {
      "type": "plain",
      "text": "\n\n @299\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61PlUpGmAYL._SX466_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Headphones, Earbuds & Accessories \n                Headphones \nPryce299 \nDicos84 \n\n❌Regular Price  : ₹ 1099   /- 😱"
     }
    ]
   },
   {
    "id": 19359,
    "type": "message",
    "date": "2023-04-28",
    "date_unixtime": "1682667247",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "949",
    "dicos": "21",
    "catagory": "Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Cosmic Byte CB-GK-05 Titan Wired Gaming Keyboard with Aluminum Body, Rainbow Backlit Keycaps, Braided Cable (Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41J0pe3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44bjQh1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LCj7yb"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LCdLms"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NgeoDD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41MERNl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41N0Vrc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NthjbY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40Mtcgp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NlatFp"
     },
     {
      "type": "plain",
      "text": "\n\n @949\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/81XCtAPvyOL._SX522_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Computers & Accessories \n                Accessories & Peripherals \n                PC Gaming Peripherals \nPryce949 \nDicos21 \n\n❌Regular Price  : ₹  1099  /- 😱"
     }
    ]
   },
   {
    "id": 19360,
    "type": "message",
    "date": "2023-04-28",
    "date_unixtime": "1682667350",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "599",
    "dicos": "70",
    "catagory": "Clothing",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   SQUAREFASHIONS Men's Regular Fit Tshirt (Pack of 4)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NgZjSl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NfjXSO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hi9KBa"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HjOb3f"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40NrOKz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oVwBfm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HhjcoA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NgGkY0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NgeNWF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40Mz8FW"
     },
     {
      "type": "plain",
      "text": "\n\n @599\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51sIeDlhwCL.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Clothing"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Clothing & Accessories \n                Men \n                T-Shirts & Polos \nPryce599 \nDicos70 \n\n❌Regular Price  : ₹ 1499   /- 😱"
     }
    ]
   },
   {
    "id": 19361,
    "type": "message",
    "date": "2023-04-28",
    "date_unixtime": "1682667475",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "619",
    "dicos": "59",
    "catagory": "Grocery",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Nature Prime Special Jumbo Size Phool Makhana/Lotus Seeds (Handpicked)- 1kg (Plain, 1 Kg)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AALMNP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44d394G"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44bkcUT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44821j0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44cCLYY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HjOpHD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/424inan"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Ve95GI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ngZGBx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LcQIxb"
     },
     {
      "type": "plain",
      "text": "\n\n @619\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/41Wqf8h8HSL.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Grocery"
     },
     {
      "type": "plain",
      "text": " & Gourmet Foods\n\n                Grocery & Gourmet Foods \n                Snacks & Sweets \n                Snack Foods \nPryce619 \nDicos59 \n\n❌Regular Price  : ₹ 1299   /- 😱"
     }
    ]
   },
   {
    "id": 19362,
    "type": "message",
    "date": "2023-04-28",
    "date_unixtime": "1682667578",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "178",
    "dicos": "64",
    "catagory": "Luggage",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Traders Best School Bags for Kids with Cute Cartoon and Animal Faces. School Picnic Carry Travelling Bag Multipurpose Backpack for Baby Kids Children & Toddler for Kid Girl/boy. (Mickey)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/421VDYI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ACpETg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oRQm7N"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LmLfEm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AASOlD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oQmIji"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HnM5iW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://www.amazon.in/Traders-Travelling-Multipurpose-Backpack-Children/dp/B09XBFNH6H?th=1&amp;linkCode=sl1&amp;tag=avi1deals8-21&amp;linkId=ed5fdb16dcea57bab60fd7cce392bc10&amp;language=en_IN&amp;ref_=as_li_ss_tl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ACbiCo"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AxtwVH"
     },
     {
      "type": "plain",
      "text": "\n\n @178\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/414aAL8hYtS.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Luggage"
     },
     {
      "type": "plain",
      "text": " & Bags\n\n                Bags, Wallets and Luggage \n                Bags & Backpacks \n                 School Bags & Sets \nPryce178 \nDicos64 \n\n❌Regular Price  : ₹ 450   /- 😱"
     }
    ]
   },
   {
    "id": 19363,
    "type": "message",
    "date": "2023-04-28",
    "date_unixtime": "1682667680",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "99",
    "dicos": "80",
    "catagory": "Health",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Boldfit Wrist Sleeves for Men & Women, Wrist Band/Wrap for Gym. Wrist Wrap/Straps Gym Accessories for Men & Women Hand Grip & Wrist Support. While Workout - Blue - Medium, (WristWrapBlueM)-1Pcs✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hkmjfg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hml679"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/448eOls"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nbVnaQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AzNZJd"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VcGkdm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LC28Mh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HnUd2J"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41IwrXm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3n4AvSZ"
     },
     {
      "type": "plain",
      "text": "\n\n @99\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51pq0j-zG-L._SX300_SY300_QL70_FMwebp_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Health"
     },
     {
      "type": "plain",
      "text": " & Personal Care\n\n                Health & Personal Care \n                Home Medical Supplies & Equipment \n                Braces, Splints & Supports \nPryce99 \nDicos80 \n\n❌Regular Price  : ₹ 250   /- 😱"
     }
    ]
   },
   {
    "id": 19364,
    "type": "message",
    "date": "2023-04-28",
    "date_unixtime": "1682667787",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "5048",
    "dicos": "75",
    "catagory": "Industrial",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Proven® Copper + Mineral RO+UV+UF 10 to 12 Liter RO + UV + TDS ADJUSTER Water Purifier with Copper Charge Technology black & copper Best For Home and Office (Made In India)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NohBB1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LzYy5y"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nbFNMq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LEG1VP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LA6SlQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42a4MyB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oHCrB0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oGzkJC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/423QKy9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/426pH5y"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@5048"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/41EzVyKoA0L._SY445_SX342_QL70_FMwebp_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Industrial"
     },
     {
      "type": "plain",
      "text": " & Scientific\n\n                Home & Kitchen \n                Kitchen & Home Appliances \n                Water Purifiers & Accessories \nPryce5048 \nDicos75 \n\n❌Regular Price  : ₹  8999  /- 😱"
     }
    ]
   },
   {
    "id": 19365,
    "type": "message",
    "date": "2023-04-28",
    "date_unixtime": "1682667892",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "8999",
    "dicos": "36",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Redmi 12C (Matte Black, 4GB RAM, 64GB Storage) | High Performance Mediatek Helio G85 | Big 17cm(6.71) HD+ Display with 5000mAh(typ) Battery with 10W Charger in-Box✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NjLx1a"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40NGdGy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NllsPf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40IfriF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40K2JA8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Nhyld5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NohTb5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NgGO0g"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lfc7pJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NllE0V"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@8999"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/81guvfQzeVL._SX466_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Mobiles & Accessories \n                Smartphones & Basic Mobiles \nPryce8999 \nDicos36 \n\n❌Regular Price  : ₹ 13499   /- 😱"
     }
    ]
   },
   {
    "id": 19366,
    "type": "message",
    "date": "2023-04-28",
    "date_unixtime": "1682667992",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "695",
    "dicos": "1",
    "catagory": "Shoes",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Mayor Men's Mars Mesh Running Shoes✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Ldv6kf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40U3s1O"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40LvKvd"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nfwevR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oKXwKV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/420tAZA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vb8xS1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VdLbLo"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44bsdJn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LgUhlY"
     },
     {
      "type": "plain",
      "text": "\n\n @695\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/81KBYMVmAAL._UY500_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Shoes"
     },
     {
      "type": "plain",
      "text": " & Handbags\n\n                Shoes & Handbags \n                Shoes \n                Men's Shoes \nPryce695 \nDicos1 \n\n❌Regular Price  : ₹ 699   /- 😱"
     }
    ]
   },
   {
    "id": 19367,
    "type": "message",
    "date": "2023-04-28",
    "date_unixtime": "1682668093",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "254",
    "dicos": "88",
    "catagory": "Clothing",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Miss Olive Womens Dress✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LzDuuT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oOiicE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VdPCG4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vbpiwo"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lfkgu7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44blhfp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oPdrbg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AzFFt1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VaKNNX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Ve9SYc"
     },
     {
      "type": "plain",
      "text": "\n\n @254\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71QryTthHWL._UY606_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Clothing"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Clothing & Accessories \n                Women \n                Western Wear \nPryce254 \nDicos88 \n\n❌Regular Price  : ₹ 1899   /- 😱"
     }
    ]
   },
   {
    "id": 19368,
    "type": "message",
    "date": "2023-04-28",
    "date_unixtime": "1682668195",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1074",
    "dicos": "20",
    "catagory": "Sports",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   CHARGED unisex-adult T SHIRT✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lgasjz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HjPnUh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AzgtD2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VbprQs"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LzylnF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AANXRt"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HnGEQV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LDMK1W"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42a8PuJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NDjhqH"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1074"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/81bI0aRRWFL._UX342_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Sports"
     },
     {
      "type": "plain",
      "text": ", Fitness & Outdoors\n\n                Clothing & Accessories \n                Women \n                Sportswear \nPryce1074 \nDicos20 \n\n❌Regular Price  : ₹ 999   /- 😱"
     }
    ]
   },
   {
    "id": 19369,
    "type": "message",
    "date": "2023-04-28",
    "date_unixtime": "1682668301",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "531",
    "dicos": "41",
    "catagory": "Car",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Kingsway Bone Shape Neck Rest Car Cushion for Ford Figo, Model Year : 2014-2021, Material: Polyfill, Color : Black, Set of 2 Piece✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3n60w4j"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40L44Xk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41MdqmT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44nNccn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LBt7rl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41Mdtiz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41LWv3M"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LCOvwE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Nm995l"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AzG9iP"
     },
     {
      "type": "plain",
      "text": "\n\n @531\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61lBc-5gBgL._SY355_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Car"
     },
     {
      "type": "plain",
      "text": " & Motorbike\n\n                Car & Motorbike \n                Car Accessories \n                 Interior Accessories \nPryce531 \nDicos41 \n\n❌Regular Price  : ₹ 1199   /- 😱"
     }
    ]
   },
   {
    "id": 19370,
    "type": "message",
    "date": "2023-04-28",
    "date_unixtime": "1682668405",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "273",
    "dicos": "83",
    "catagory": "Clothing",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Solefit Men's Track Pants✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AC0xQA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vb95r3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Nfl9Wi"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40NGSI2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nds0oy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40Nyecz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40QxfrY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nds3Rg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oFhnuX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/423Rrrf"
     },
     {
      "type": "plain",
      "text": "\n\n @273\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71zyoP3k-xL._UX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Clothing"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Clothing & Accessories \n                Men \n                Sportswear \nPryce273 \nDicos83 \n\n❌Regular Price  : ₹  999  /- 😱"
     }
    ]
   },
   {
    "id": 19371,
    "type": "message",
    "date": "2023-04-28",
    "date_unixtime": "1682668506",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "3824",
    "dicos": "66",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Maharaja Whiteline Plastic Frostair 20 DLX Co-127 Air Cooler with Remote Control, White, 20 Liters✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/426glXn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/422yNjF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44dZ1Sj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/4210ZUF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LdugUI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VtCw81"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Veahda"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VbpI5W"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VaLkzr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44ehhuS"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@3824"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71cEHMjNiKL._SY879_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Heating, Cooling & Air Quality \n                Air Coolers \nPryce3824 \nDicos66 \n\n❌Regular Price  : ₹ 9999   /- 😱"
     }
    ]
   },
   {
    "id": 19372,
    "type": "message",
    "date": "2023-04-28",
    "date_unixtime": "1682668632",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "399",
    "dicos": "11",
    "catagory": "Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Kingston DataTraveler Exodia DTX/32 GB Pen Drive USB 3.2 Gen 1 (Multicolor)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AATOWV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vgfgu3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nh14UL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vg7q3t"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AzGAtt"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3n8NLWB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VcHpBW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LCfeJu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nePNoc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3na0tnQ"
     },
     {
      "type": "plain",
      "text": "\n\n @399\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/21e4IoLXBFL._SY300_SX300_QL70_FMwebp_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Computers & Accessories \n                External Devices & Data Storage \n                 Pen Drives \nPryce399 \nDicos11 \n\n❌Regular Price  : ₹  450  /- 😱"
     }
    ]
   },
   {
    "id": 19373,
    "type": "message",
    "date": "2023-04-28",
    "date_unixtime": "1682668734",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "449",
    "dicos": "44",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Tosaa Plastic Pet Storage Jar and Container, Set of 24 (6pcs x 250ml Each, 6 pcs x 350ml Each, 6 pcs x 650ml Each, 6 pcs x 1.20ltrs Each), Black|Air Tight|Kitchen Organiser|BPA Free|Stackable✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HhfHyn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HnkfmG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3n612PN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42a5L1L"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41LlYKS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41OA0ve"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41MGlan"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42a9qwt"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/424GaqL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40Lsfos"
     },
     {
      "type": "plain",
      "text": "\n\n @449\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71AFv8l+y-L._SX355_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Dining \n                Kitchen Storage & Containers \nPryce449 \nDicos44 \n\n❌Regular Price  : ₹ 750   /- 😱"
     }
    ]
   },
  //  {
  //   "id": 19374,
  //   "type": "message",
  //   "date": "2023-04-28",
  //   "date_unixtime": "1682668838",
  //   "from": "All1App daily",
  //   "from_id": "channel1858370886",
  //   "photo": "(File not included. Change data exporting settings to download.)",
  //   "pryce": "8188",
  //   "dicos": "NaN",
  //   "catagory": "Home",
  //   "text_entities": [
  //    {
  //     "type": "plain",
  //     "text": "Offer 🚀🚀   Livpure GLO PRO++ RO+UV+UF+Taste Enhancer, Water Purifier for Home - 7 L Storage, Suitable for Borewell, Tanker, Municipal Water (Black)✅\n"
  //    },
  //    {
  //     "type": "link",
  //     "text": "https://amzn.to/3oVy8C8"
  //    },
  //    {
  //     "type": "plain",
  //     "text": "\n"
  //    },
  //    {
  //     "type": "link",
  //     "text": "https://amzn.to/41L2s17"
  //    },
  //    {
  //     "type": "plain",
  //     "text": "\n"
  //    },
  //    {
  //     "type": "link",
  //     "text": "https://amzn.to/3LDNSCI"
  //    },
  //    {
  //     "type": "plain",
  //     "text": "\n"
  //    },
  //    {
  //     "type": "link",
  //     "text": "https://amzn.to/3LeHgtk"
  //    },
  //    {
  //     "type": "plain",
  //     "text": "\n"
  //    },
  //    {
  //     "type": "link",
  //     "text": "https://amzn.to/3n8K8zZ"
  //    },
  //    {
  //     "type": "plain",
  //     "text": "\n"
  //    },
  //    {
  //     "type": "link",
  //     "text": "https://amzn.to/3LhYDcL"
  //    },
  //    {
  //     "type": "plain",
  //     "text": "\n"
  //    },
  //    {
  //     "type": "link",
  //     "text": "https://amzn.to/3L71Oni"
  //    },
  //    {
  //     "type": "plain",
  //     "text": "\n"
  //    },
  //    {
  //     "type": "link",
  //     "text": "https://amzn.to/3n8Kerl"
  //    },
  //    {
  //     "type": "plain",
  //     "text": "\n"
  //    },
  //    {
  //     "type": "link",
  //     "text": "https://amzn.to/3oT5vWd"
  //    },
  //    {
  //     "type": "plain",
  //     "text": "\n"
  //    },
  //    {
  //     "type": "link",
  //     "text": "https://amzn.to/3nbFnpf"
  //    },
  //    {
  //     "type": "plain",
  //     "text": "\n\n "
  //    },
  //    {
  //     "type": "mention",
  //     "text": "@8188"
  //    },
  //    {
  //     "type": "plain",
  //     "text": "\n"
  //    },
  //    {
  //     "type": "link",
  //     "text": "https://m.media-amazon.com/images/I/61eLWPJtv+L._SY550_.jpg"
  //    },
  //    {
  //     "type": "plain",
  //     "text": " \n\n\n"
  //    },
  //    {
  //     "type": "hashtag",
  //     "text": "#Home"
  //    },
  //    {
  //     "type": "plain",
  //     "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Home Appliances \n                Water Purifiers & Accessories \nPryce8188 \nDicosNaN"
  //    }
  //   ]
  //  },
   {
    "id": 19375,
    "type": "message",
    "date": "2023-04-28",
    "date_unixtime": "1682668938",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "198",
    "dicos": "40",
    "catagory": "Grocery",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Saffola Honey Active, Made with Sundarban Forest Honey, 100% Pure Honey, No sugar adulteration, Natural Immunity booster, 2 x 350g✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HknU4K"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40LwWPd"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oHDFMC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/4259GNb"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42813kK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vb9Ct3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ABRWgx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oO05vY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NjNtGY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/4211BcV"
     },
     {
      "type": "plain",
      "text": "\n\n @198\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71CUDoeTVAL._SX466_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Grocery"
     },
     {
      "type": "plain",
      "text": " & Gourmet Foods\n\n                Grocery & Gourmet Foods \n                Cooking & Baking Supplies \n                Baking Syrups, Sugars & Sweeteners \nPryce198 \nDicos40 \n\n❌Regular Price  : ₹ 310   /- 😱"
     }
    ]
   },
   {
    "id": 19376,
    "type": "message",
    "date": "2023-04-28",
    "date_unixtime": "1682669041",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1499",
    "dicos": "75",
    "catagory": "Watches",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Maxima New Samurai Smartwatch 1.85\", 1st Time Call Accept Feature, Hindi Language Support, 600Nits Brightness, Longer Battery, in-App GPS,100+ Watch Faces, Multisport Modes,HR/SpO2 (Blue)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LdaOrm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vcnxiy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3L728Cw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VaLZAV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/448ihjZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/427Ranw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AANYox"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LCP2Pa"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44duLXu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LDcuM2"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1499"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61me-IcSRyL._SX522_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Watches"
     },
     {
      "type": "plain",
      "text": "\n\n                Fashion \n                Women's clothing \n                SS23 Trends \nPryce1499 \nDicos75 \n\n❌Regular Price  : ₹ 4599   /- 😱"
     }
    ]
   },
   {
    "id": 19377,
    "type": "message",
    "date": "2023-04-28",
    "date_unixtime": "1682669147",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "711",
    "dicos": "60",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Athom Living Light Weight Cotton Premium Waffle Border Bath Towel 75x150 cm Pack of 6✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/4483TIy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LzzRWT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Libb3X"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3L72zNa"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40GVqsJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HjzbCg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AAOanL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HlQnqY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3n61JZp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44843j8"
     },
     {
      "type": "plain",
      "text": "\n\n @711\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/615+EYFvOuL._SX522_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Home Furnishing \n                Bathroom Linen \nPryce711 \nDicos60 \n\n❌Regular Price  : ₹ 1699   /- 😱"
     }
    ]
   },
   {
    "id": 19378,
    "type": "message",
    "date": "2023-04-28",
    "date_unixtime": "1682669247",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "5439",
    "dicos": "70",
    "catagory": "Clothing",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   AmazonBasics Polycarbonate Soft 73 cms Suitcase (N802_Blue)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/424kxH1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/4484mdM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LBW0DS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oR5ORF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41MVU23"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41N39qy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41L372B"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oP13b8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41OBfKU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41LXwJ8"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@5439"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/915zpRm7a1L._SX522_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Clothing"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Toys & Games \n                Prime Day \n                 Games and puzzles: 25%-50% off \nPryce5439 \nDicos70 \n\n❌Regular Price  : ₹12999   /- 😱"
     }
    ]
   },
   {
    "id": 19379,
    "type": "message",
    "date": "2023-04-28",
    "date_unixtime": "1682669347",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "13999",
    "dicos": "36",
    "catagory": "Tools",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Amazon Basics Steel Security Safe with Programmable Biometric Fingerprint Lock - Secure Cash, Jewelry, ID Documents - 1.8 Cubic Feet, 13.8 x 13 x 19.7 inches✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3n3cXhi"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LBOpFr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LBuEh5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HlHzkY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HnVKG1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NkltTy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oUof7N"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NlOqPg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3n4CEy1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NmacSP"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@13999"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61DZU910DYL._SY355_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Tools"
     },
     {
      "type": "plain",
      "text": " & Home Improvement\n\n                Home Improvement \n                Safety & Security \n                Safes & Safe Accessories \nPryce13999 \nDicos36 \n\n❌Regular Price  : ₹   17999 /- 😱"
     }
    ]
   },
   {
    "id": 19380,
    "type": "message",
    "date": "2023-04-28",
    "date_unixtime": "1682669454",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "599",
    "dicos": "70",
    "catagory": "Health",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   beatXP NeoChef Multipurpose Digital Weight Machine for Home Kitchen | Chefs & Food Diet | Portable Food Weighing Scale with 4 Measurement Units & LCD Display | 18 Months Warranty✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LzF28b"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Njmo6G"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LeI9lE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/448DaeR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44bmOlF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Ldxbwz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40Rscr7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40NzOv1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LcT0wh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AAOqTL"
     },
     {
      "type": "plain",
      "text": "\n\n @599\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51nLxWlx+yL._SY450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Health"
     },
     {
      "type": "plain",
      "text": " & Personal Care\n\n                Home & Kitchen \n                Kitchen & Home Appliances \n                Small Kitchen Appliances \nPryce599 \nDicos70 \n\n❌Regular Price  : ₹  1199  /- 😱"
     }
    ]
   },
   {
    "id": 19381,
    "type": "message",
    "date": "2023-04-28",
    "date_unixtime": "1682669565",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "2089",
    "dicos": "58",
    "catagory": "Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   AmazonBasics AC1200 Dual-Band Wireless SOHO Router, Wi-Fi Speed Up to 867 Mbps/5 GHz and 300 Mbps/2.4 GHz, MediaTek Chipset, Guest Network Function, with 0.75M Ethernet Cable (White)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oKZzyB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41LUOUc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AyqC2O"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/445pi5f"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vbmf7n"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ABGrpq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AAVmAd"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/427Uuic"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AzI6Mb"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AU6F6Z"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@2089"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/41PHaHfyWvL._SY450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Computers & Accessories \n                Networking Devices \n                 Routers \nPryce2089 \nDicos58 \n\n❌Regular Price  : ₹  4099  /- 😱"
     }
    ]
   },
   {
    "id": 19382,
    "type": "message",
    "date": "2023-04-28",
    "date_unixtime": "1682669666",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1999",
    "dicos": "47",
    "catagory": "Car",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Oshotto/Recaro 100% Dust Proof, Water Resistant Grey Car Body Cover with Mirror Pocket Compatible with Volkswagen Passat✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LcT4w1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/426s1ti"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NjmKKy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3L9Jb2e"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oRSufX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AyqQHc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NfmLiO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nbI5eu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LzAJL9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AzIdY7"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1999"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61CsUdkoXIL._SX522_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Car"
     },
     {
      "type": "plain",
      "text": " & Motorbike\n\n                Car & Motorbike \n                Car Accessories \n                Exterior Accessories \nPryce1999 \nDicos47 \n\n❌Regular Price  : ₹ 3099   /- 😱"
     }
    ]
   },
   {
    "id": 19383,
    "type": "message",
    "date": "2023-04-28",
    "date_unixtime": "1682669769",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "4499",
    "dicos": "68",
    "catagory": "Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   AmazonBasics Portable SSD 512GB, External SSD, USB 3.2 Gen 2x2, USB-C, Read Speed up t o 2000MB/s✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AAPTtd"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HjRqHX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NlgiCN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AC2n3W"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44buvYZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VbaHkB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VcIJoo"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oKeOI1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VcuUq6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41JzEpv"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@4499"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51o3o9GVEqL._SY450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Computers & Accessories \n                External Devices & Data Storage \n                 External Solid State Drives \nPryce4499 \nDicos68 \n\n❌Regular Price  : ₹ 9999   /- 😱"
     }
    ]
   },
   {
    "id": 19384,
    "type": "message",
    "date": "2023-04-28",
    "date_unixtime": "1682669871",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1861",
    "dicos": "38",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Amazon Basics 500W Juicer Mixer Grinder with 2 Jars, White and Blue✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LBP7T7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LMkXgd"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LCkI75"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oU28yE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3n8PmM5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40LxS6b"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LDdxeW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3n62MIP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NhAUvJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LCpH7S"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1861"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71JC0Vk75IL._SX355_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Home Appliances \n                Small Kitchen Appliances \nPryce1861 \nDicos38 \n\n❌Regular Price  : ₹ 2099   /- 😱"
     }
    ]
   },
   {
    "id": 19385,
    "type": "message",
    "date": "2023-04-28",
    "date_unixtime": "1682669971",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "842",
    "dicos": "63",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   EcoLink 24-Watt Polycarbonate Batten (Cool White,Pack of 4)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/448jqYP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40OOhqt"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44d6i4u"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NlPeUi"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NkmjzG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oRThxr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HjRBmB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HjA4uA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Ljaib8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LgWUUS"
     },
     {
      "type": "plain",
      "text": "\n\n @842\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61pGGFfFeWL._SY450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Indoor Lighting \n                 Tube Lights & Battens \nPryce842 \nDicos63 \n\n❌Regular Price  : ₹  1999  /- 😱"
     }
    ]
   },
   {
    "id": 19386,
    "type": "message",
    "date": "2023-04-28",
    "date_unixtime": "1682670074",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "999",
    "dicos": "33",
    "catagory": "Car",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Oshotto Car Backseat Storage Organizer Phone Holder,Multi-Pocket for Bottles, Tissue Boxes,Kids Toy Storage and Great Travel Accessory Compatible with Hyundai Creta (2015-2019) (Tan)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AAVO1n"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AC2z3a"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/427yW5g"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oGChtG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HmnCKD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ncB64U"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hl1Xmj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HlRln6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VfXiaW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/427z0lw"
     },
     {
      "type": "plain",
      "text": "\n\n @999\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/7152PHwpaFL._SY355_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Car"
     },
     {
      "type": "plain",
      "text": " & Motorbike\n\n                Car & Motorbike \n                Car Accessories \n                Interior Accessories \nPryce999 \nDicos33 \n\n❌Regular Price  : ₹  1459  /- 😱"
     }
    ]
   },
   {
    "id": 19387,
    "type": "message",
    "date": "2023-04-28",
    "date_unixtime": "1682670176",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "4399",
    "dicos": "85",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "A\nOffer 🚀🚀   Amazon Brand - Solimo Ohrid Acacia Solid Wood Wall Mounted Study Desk (New Linen)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LA19wk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3n8LydN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LBO5q2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oKOj5n"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VihR6v"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lcpua5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Nlqw6h"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41JzRch"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LcTVNf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44mk3ya"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@4399"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71qvNO9+A2L._SY450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Home & Kitchen \n                Furniture \n                Study & Home Office Furniture \nPryce4399 \nDicos85 \n\n❌Regular Price  : ₹  8999  /- 😱"
     }
    ]
   },
   {
    "id": 19388,
    "type": "message",
    "date": "2023-04-28",
    "date_unixtime": "1682670279",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "759",
    "dicos": "62",
    "catagory": "Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Ant Esports Knight Cobra 20, Large 38L Stylish unisex backpack with Earphone/Headphone Port, with rain protection cover and reflective strip, fits upto 17\" Laptop - Gun Black✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/427SxTc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oGCuNu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44863Ia"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VghaLd"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oL0DCB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VdShzy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VfUPNv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LE5DSK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VbbphL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LA2HGu"
     },
     {
      "type": "plain",
      "text": "\n\n @759\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/81jxpm+FsbL._SY355_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Computers & Accessories \n                Accessories & Peripherals \n                Laptop Accessories \nPryce759 \nDicos62 \n\n❌Regular Price  : ₹  1199    /- 😱"
     }
    ]
   },
   {
    "id": 19389,
    "type": "message",
    "date": "2023-04-28",
    "date_unixtime": "1682670381",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "299",
    "dicos": "70",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Aavjo Insulated Lunch Bag for Men Women Kids Boy Girl, Smiley Design Tote Lunch Bag with Aluminum Foil for Work, School, Picnic, Outdoor (Bear, Gray)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oOlqoU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/448ihR0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40GhVhC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3n3e6W8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44ex1Ob"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NoMCVs"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NlotPz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LBPCg1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/445qq8Z"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40KfbzJ"
     },
     {
      "type": "plain",
      "text": "\n\n @299\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71RetLTKhLL._SY355_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Dining \n                Kitchen Storage & Containers \nPryce299 \nDicos70 \n\n❌Regular Price  : ₹  799  /- 😱"
     }
    ]
   },
   {
    "id": 19390,
    "type": "message",
    "date": "2023-04-28",
    "date_unixtime": "1682670483",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "849",
    "dicos": "72",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Onlineshoppee Wooden Decorative Floating Wall Shelf,White, Set of 3✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/444oaid"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3neSia4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Nled9V"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LffxZB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nh2Vcb"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NjnFuu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3n6Krvc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NleidJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3neLyJd"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40Nvwnt"
     },
     {
      "type": "plain",
      "text": "\n\n @849\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/41kCqh305AL._SY450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Home & Kitchen \n                Furniture \n                Living Room Furniture \nPryce849 \nDicos72 \n\n❌Regular Price  : ₹   1999   /- 😱"
     }
    ]
   },
   {
    "id": 19391,
    "type": "message",
    "date": "2023-04-28",
    "date_unixtime": "1682670584",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "278",
    "dicos": "76",
    "catagory": "Sports",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀  Lista Combo of Rechargeable Head Cycle Light and Cycle Tail Light Cycle Light led for Bicycle ✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AD7jFw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40O7sAy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40NAW1J"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hmx6Wx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40FVKYQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HmxeW1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HnPf6i"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40KFHZY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hhi4Bh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/422LjzC"
     },
     {
      "type": "plain",
      "text": "\n\n @278\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51s7Xs7Dk3L._SY450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Sports"
     },
     {
      "type": "plain",
      "text": ", Fitness & Outdoors\n\n                Sports, Fitness & Outdoors \n                Cycling \n                Accessories \nPryce278 \nDicos76 \n\n❌Regular Price  : ₹ 949   /- 😱"
     }
    ]
   },
   {
    "id": 19392,
    "type": "message",
    "date": "2023-04-28",
    "date_unixtime": "1682670687",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "443",
    "dicos": "77",
    "catagory": "Shoes",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Bourge Mens Loire-z-175 Running Shoe✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/423U6kJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LA1Q8U"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NoMTHY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NgilrX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vg9p7P"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41LoeSm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oO2g2C"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44dwFY8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41Sh5jm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oScJKo"
     },
     {
      "type": "plain",
      "text": "\n\n @443\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/716Mew8TbnL._UY500_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Shoes"
     },
     {
      "type": "plain",
      "text": " & Handbags\n\n                Shoes & Handbags \n                Shoes \n                Men's Shoes \nPryce443 \nDicos77 \n\n❌Regular Price  : ₹  1299   /- 😱"
     }
    ]
   },
   {
    "id": 19393,
    "type": "message",
    "date": "2023-04-28",
    "date_unixtime": "1682670788",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "469",
    "dicos": "77",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   SENS Alvis 1 Bluetooth Headset with Feather-Light, Dual Pairing, Magnetic Earbuds and up to 13hrs Playback  (Sapphire Blue)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vd42Gm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40Ru9Ut"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40OPqhL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41MJ0Rp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NlQ6Z4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oUpV15"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/423vF7d"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40Mxww9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41K0qOL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41MaCGy"
     },
     {
      "type": "plain",
      "text": "\n\n @469\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61dXSu889YL._SY450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Headphones, Earbuds & Accessories \n                Headphones \nPryce469 \nDicos77 \n\n❌Regular Price  : ₹ 1299   /- 😱"
     }
    ]
   },
  //  {
  //   "id": 19394,
  //   "type": "message",
  //   "date": "2023-04-28",
  //   "date_unixtime": "1682670888",
  //   "from": "All1App daily",
  //   "from_id": "channel1858370886",
  //   "photo": "(File not included. Change data exporting settings to download.)",
  //   "pryce": "799",
  //   "dicos": "NaN",
  //   "catagory": "Health",
  //   "text_entities": [
  //    {
  //     "type": "plain",
  //     "text": "Offer 🚀🚀   NatAura - 3D Facial Massager for Relaxation and Skin Tightening I Removes Wrinkles and Fine Lines I Face Lift and Blood Circulation I✅\n"
  //    },
  //    {
  //     "type": "link",
  //     "text": "https://amzn.to/44mkCrM"
  //    },
  //    {
  //     "type": "plain",
  //     "text": "\n"
  //    },
  //    {
  //     "type": "link",
  //     "text": "https://amzn.to/3ABXwj9"
  //    },
  //    {
  //     "type": "plain",
  //     "text": "\n"
  //    },
  //    {
  //     "type": "link",
  //     "text": "https://amzn.to/3ACfodK"
  //    },
  //    {
  //     "type": "plain",
  //     "text": "\n"
  //    },
  //    {
  //     "type": "link",
  //     "text": "https://amzn.to/3AzRPC7"
  //    },
  //    {
  //     "type": "plain",
  //     "text": "\n"
  //    },
  //    {
  //     "type": "link",
  //     "text": "https://amzn.to/40MEDo8"
  //    },
  //    {
  //     "type": "plain",
  //     "text": "\n"
  //    },
  //    {
  //     "type": "link",
  //     "text": "https://amzn.to/447rsB7"
  //    },
  //    {
  //     "type": "plain",
  //     "text": "\n"
  //    },
  //    {
  //     "type": "link",
  //     "text": "https://amzn.to/3LCRbdG"
  //    },
  //    {
  //     "type": "plain",
  //     "text": "\n"
  //    },
  //    {
  //     "type": "link",
  //     "text": "https://amzn.to/4486G4u"
  //    },
  //    {
  //     "type": "plain",
  //     "text": "\n"
  //    },
  //    {
  //     "type": "link",
  //     "text": "https://amzn.to/3AydVVQ"
  //    },
  //    {
  //     "type": "plain",
  //     "text": "\n"
  //    },
  //    {
  //     "type": "link",
  //     "text": "https://amzn.to/3Ayshp4"
  //    },
  //    {
  //     "type": "plain",
  //     "text": "\n\n @799\n"
  //    },
  //    {
  //     "type": "link",
  //     "text": "https://m.media-amazon.com/images/I/31qGG1rgHoL._SY450_.jpg"
  //    },
  //    {
  //     "type": "plain",
  //     "text": " \n\n\n"
  //    },
  //    {
  //     "type": "hashtag",
  //     "text": "#Health"
  //    },
  //    {
  //     "type": "plain",
  //     "text": " & Personal Care\n\n                Health & Personal Care \n                Health Care \n                Massage & Relaxation \nPryce799 \nDicosNaN"
  //    }
  //   ]
  //  },
   {
    "id": 19395,
    "type": "message",
    "date": "2023-04-28",
    "date_unixtime": "1682670991",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "993",
    "dicos": "50",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   V-Guard VSG80 2 Slice Grill Sandwich Maker | Fixed Non-Stick Grill Plates with Powerful 800 Watt Heating Rods |Easy to Clean Non-Stick Greblon Coating | 1 Year Warranty - Black✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LBwAWT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3n8QBed"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hhih7x"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3n4N7tf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AARpeT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NDn1Zh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AAWW57"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Nmcph5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NlhVjT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ABXQOT"
     },
     {
      "type": "plain",
      "text": "\n\n @993\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/41jm-u8webL._SY355_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Home Appliances \n                Small Kitchen Appliances \nPryce993 \nDicos50 \n\n❌Regular Price  : ₹  1699   /- 😱"
     }
    ]
   },
   {
    "id": 19396,
    "type": "message",
    "date": "2023-04-28",
    "date_unixtime": "1682671095",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "2544",
    "dicos": "40",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   V-Guard Esfera STS Plus (Red Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/422C6r5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/422C7v9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/449YGjm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HnXSO1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HlaRA7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/448Fa6R"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HnKz05"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40MaN39"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40NwfFd"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/424mXW7"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@2544"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71djiQbfoQL._SY450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Heating, Cooling & Air Quality \n                Fans \nPryce2544 \nDicos40 \n\n❌Regular Price  : ₹  3999    /- 😱"
     }
    ]
   },
   {
    "id": 19397,
    "type": "message",
    "date": "2023-04-28",
    "date_unixtime": "1682671196",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "813",
    "dicos": "59",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀  Symphony Solaris Quartz 800W Room Heater for Home with ISI Certified 2 Quartz Heating Tubes and Energy Efficient Heating ( Grey & Black) ✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40RuyWZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40K6O7q"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LBQpO1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NmFJEa"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NlkFxW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NgiNXb"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41N2pBP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41MV3hC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NjQ97u"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44cxW1S"
     },
     {
      "type": "plain",
      "text": "\n\n @813\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61TdtREyj8L._SY450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Heating, Cooling & Air Quality \n                Room Heaters \nPryce813 \nDicos59 \n\n❌Regular Price  : ₹   1599  /- 😱"
     }
    ]
   },
   {
    "id": 19398,
    "type": "message",
    "date": "2023-04-28",
    "date_unixtime": "1682671298",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "626",
    "dicos": "84",
    "catagory": "Shoes",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Caprese LINDSAY women's Sling Bag (AQUA)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oQrMnO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oQrMUQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44e2jVF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oPaw2h"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3L9KXAq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41JrptD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VgabBL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41MYepN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VbtkVy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AD8bKi"
     },
     {
      "type": "plain",
      "text": "\n\n @626\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71VtCdccZfL._UY500_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Shoes"
     },
     {
      "type": "plain",
      "text": " & Handbags\n\n                Shoes & Handbags \n                Handbags, Purses & Clutches \n                 Sling & Cross-Body Bags \nPryce626 \nDicos84 \n\n❌Regular Price  : ₹  1999   /- 😱"
     }
    ]
   },
   {
    "id": 19399,
    "type": "message",
    "date": "2023-04-28",
    "date_unixtime": "1682671401",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "4188",
    "dicos": "36",
    "catagory": "Industrial",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Polycab Intenso 20W LXS LED Batten in Square Shape, Energy-efficient Light with Cool White Color (220-240V, 1130mm, 6500K, 10 Pcs)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ADsOpY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VedM3i"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HhnYm0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LA2Rhe"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HlsM9K"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40KGvOb"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HnYhjv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40O8gp4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40KGEBw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LDQNLG"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@4188"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51zxvFEaFqL._SX342_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Industrial"
     },
     {
      "type": "plain",
      "text": " & Scientific\n\n                Home & Kitchen \n                Indoor Lighting \n                 Tube Lights & Battens \nPryce4188 \nDicos36 \n\n❌Regular Price  : ₹  4999    /- 😱"
     }
    ]
   },
   {
    "id": 19400,
    "type": "message",
    "date": "2023-04-28",
    "date_unixtime": "1682671523",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "717",
    "dicos": "76",
    "catagory": "Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Hynix 4GB PC3-12800 DDR3-1600MHz desktop 240-Pin DIMM RAM Memory Module Mfr P/N HMT451U6AFR8C-PB✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40Myp7X"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41N5Fgu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oRV19N"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/4499iix"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nh40Rh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/448FNNL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nbJRMG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oRV3i5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3njq3H3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3n4NB2x"
     },
     {
      "type": "plain",
      "text": "\n\n @717\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51IjfkOmvFL._SX355_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Computers & Accessories \n                Components \n                 Memory \nPryce717 \nDicos76 \n\n❌Regular Price  : ₹ 1999    /- 😱"
     }
    ]
   },
   {
    "id": 19401,
    "type": "message",
    "date": "2023-04-28",
    "date_unixtime": "1682671627",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1150",
    "dicos": "23",
    "catagory": "Car",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Auto Pearl 12 LED Bar Light Auto Pearl- Universal Bike Car Fog Light - Hi Power - SMD LED Bar Light for Off Roading Bikes Cars SUV ATV - Compatible With- Yamaha MT 15 Set of 2 (48W)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3n4EGy9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NoNMQO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Azhf35"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3na3YL0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AHFJa5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3n8N3Zt"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40GYjtz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40MFg0Y"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40U7V4A"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42986dh"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1150"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61LR0A8ZgNS._SY355_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Car"
     },
     {
      "type": "plain",
      "text": " & Motorbike\n\n                Car & Motorbike \n                Car Parts \n                Lights, Bulbs & Indicators \nPryce1150 \nDicos23 \n\n❌Regular Price  : ₹ 1499   /- 😱"
     }
    ]
   },
   {
    "id": 19402,
    "type": "message",
    "date": "2023-04-28",
    "date_unixtime": "1682671733",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "561",
    "dicos": "57",
    "catagory": "Industrial",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Polycab Hohm RGB Smart Bulb 9 Watt Wi-Fi Enabled App Control LED Compatible with Amazon Alexa and Google Assistant (16 Million Colors, Warm & Cool White, 1 Piece)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oGEaGM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/448lyQj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VaP6ZD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vgb2T5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oSdWBq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VlWSzN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VbcO81"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LieS9P"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oKQ42t"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VdPAOq"
     },
     {
      "type": "plain",
      "text": "\n\n @561\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/41BbjX+GxJL._SX342_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Industrial"
     },
     {
      "type": "plain",
      "text": " & Scientific\n\n                Home & Kitchen \n                Indoor Lighting \n                Light Bulbs \nPryce561 \nDicos57 \n\n❌Regular Price  : ₹  1099   /- 😱"
     }
    ]
   },
   {
    "id": 19403,
    "type": "message",
    "date": "2023-04-28",
    "date_unixtime": "1682671836",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "398",
    "dicos": "75",
    "catagory": "Clothing",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   POLICE Elgon 20 Ltr Casual Backpack✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Li7V8s"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41N63LY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Azlxr2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44d8Lfi"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LcroHL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oRHZJv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40Gkikw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oSWEDY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hm3Ajk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/442FvrE"
     },
     {
      "type": "plain",
      "text": "\n\n @398\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61wpydUtfUS._UX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Clothing"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Bags, Wallets and Luggage \n                Bags & Backpacks \n                Backpacks \nPryce398 \nDicos75 \n\n❌Regular Price  : ₹  1199    /- 😱"
     }
    ]
   },
   {
    "id": 19404,
    "type": "message",
    "date": "2023-04-28",
    "date_unixtime": "1682671936",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "664",
    "dicos": "56",
    "catagory": "Clothing",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Mom's Love Unisex-Child Casual Pants✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/4214Q45"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/422y02a"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oMTG42"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41K5yT6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NlnBL1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oQsEsA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AzlR9e"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nc0cks"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LA3BD2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LA4Hyu"
     },
     {
      "type": "plain",
      "text": "\n\n @664\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71hBruBGEWL._UY606_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Clothing"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Fashion \n                Apparel Steal Deals \n                Apparel_1 \nPryce664 \nDicos56 \n\n❌Regular Price  : ₹ 1199    /- 😱"
     }
    ]
   },
   {
    "id": 19405,
    "type": "message",
    "date": "2023-04-28",
    "date_unixtime": "1682672040",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "82",
    "dicos": "41",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   PHILIPS Deco Mini 0.5-Watt B22 Base LED Bulb (Pink and Pack of 2)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lyp0fL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Ntm2dI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3njqMYN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NmsvHE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NoOaie"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40LwiBa"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3na4sAO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HlbTMv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Az9h9Q"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40RvACl"
     },
     {
      "type": "plain",
      "text": "\n\n @82\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/31QRScOZjKL._SY445_SX342_QL70_FMwebp_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Indoor Lighting \n                Light Bulbs \nPryce82 \nDicos41 \n\n❌Regular Price  : ₹  140    /- 😱"
     }
    ]
   },
   {
    "id": 19406,
    "type": "message",
    "date": "2023-04-28",
    "date_unixtime": "1682672147",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1159",
    "dicos": "16",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Cello Style Knit Basket Without Lid, Set of 5, Brown,Plastic✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NgL67S"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40Mc22j"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oGEB3S"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VgbC3d"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42aab8R"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vib5Om"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41Mc6k6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3njqYqZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VcqM9I"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3njr1mF"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1159"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/41EKxJi4uiS._SX300_SY300_QL70_FMwebp_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Home Storage & Organisation \n                Boxes, Baskets & Bins \nPryce1159 \nDicos16 \n\n❌Regular Price  : ₹  1299   /- 😱"
     }
    ]
   },
   {
    "id": 19407,
    "type": "message",
    "date": "2023-04-28",
    "date_unixtime": "1682672257",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "399",
    "dicos": "11",
    "catagory": "Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Kingston DataTraveler Exodia DTX/32 GB Pen Drive USB 3.2 Gen 1 (Multicolor)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41Ox1TI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3L9M9DU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nh4Xcj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ACgVR2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://www.amazon.in/Kingston-DataTraveler-Exodia-DTX-Flash/dp/B08JD36C6H?th=1&amp;linkCode=sl1&amp;tag=avi1deals5-21&amp;linkId=bc02d3617ec384965167256e1e57701c&amp;language=en_IN&amp;ref_=as_li_ss_tl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LiSiNY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/449C5Ds"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40U8AD6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44czetK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oR913F"
     },
     {
      "type": "plain",
      "text": "\n\n @399\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/21e4IoLXBFL._SY300_SX300_QL70_FMwebp_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Computers & Accessories \n                External Devices & Data Storage \n                 Pen Drives \nPryce399 \nDicos11 \n\n❌Regular Price  : ₹  450  /- 😱"
     }
    ]
   },
   {
    "id": 19408,
    "type": "message",
    "date": "2023-04-28",
    "date_unixtime": "1682672386",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "320",
    "dicos": "74",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Nirlon Cooking Non-Stick Gas Stove Compatible Bottom deep Fry kadhai 18.5cm - 1.2 LTR[3_mm_KD10] (Aluminium, Red)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/449amD3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41QSETd"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/423BYrs"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44em8MC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LcWgYx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LcWlLP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oQtuFK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41CYeZj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NllHtO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LDgxIe"
     },
     {
      "type": "plain",
      "text": "\n\n @320\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71+TNxBDCnL._SX355_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Dining \n                Cookware \nPryce320 \nDicos74 \n\n❌Regular Price  : ₹  999    /- 😱"
     }
    ]
   },
   {
    "id": 19409,
    "type": "message",
    "date": "2023-04-28",
    "date_unixtime": "1682672511",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "295",
    "dicos": "77",
    "catagory": "Industrial",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   APTECHDEALS 40 In 1 Pcs Wrench Tool Kit & Screwdriver And Socket Set (MS-HMK-TOOL-01)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LCjrNi"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40ORrdP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LBpRMF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LA5srk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NmHnFQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oUswYT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Axzztn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/449CtSq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vhadtm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vhadtm"
     },
     {
      "type": "plain",
      "text": "\n\n @295\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/41BXZC1hSgL._SX342_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Industrial"
     },
     {
      "type": "plain",
      "text": " & Scientific\n\n                Home Improvement \n                Power & Hand Tools \n                Hand Tools \nPryce295 \nDicos77 \n\n❌Regular Price  : ₹  999   /- 😱"
     }
    ]
   },
   {
    "id": 19410,
    "type": "message",
    "date": "2023-04-28",
    "date_unixtime": "1682672619",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1098",
    "dicos": "35",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Hindware Zorio Ax 100mm Exhaust Fan with Low Noise, Powerful Air Suction and High Speed, For Kitchen or Bathroom With Overload Protection For Odourless, Clean and Fresh Air (White)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/424LE4P"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/449aQcl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/420bHKk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3njRKPY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/420bOFK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LgfM6x"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3L76NV2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vic2Gq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41N71b4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LdAYKj"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1098"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/41SajrV3RKL._SY355_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Heating, Cooling & Air Quality \n                Fans \nPryce1098 \nDicos35 \n\n❌Regular Price  : ₹  1499   /- 😱"
     }
    ]
   },
   {
    "id": 19411,
    "type": "message",
    "date": "2023-04-28",
    "date_unixtime": "1682672743",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "89",
    "dicos": "78",
    "catagory": "Tools",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   GLUN® Multipurpose Door Gap Filler 39 inch Bottom Sealing Strip for Insects, Dust/ Water Proof Noise Cancellation and Cooling Leakage Protection (Large, 1)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oRJ5oB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40Rwu1H"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HnMzFD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/422z85U"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VefnpO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/427CtR4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41McUFE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40RwHSx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LdfLQY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nepCxG"
     },
     {
      "type": "plain",
      "text": "\n\n @89\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51Uq9hwfr+L._SX355_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Tools"
     },
     {
      "type": "plain",
      "text": " & Home Improvement\n\n                Home Improvement \n                Hardware \n                Door Hardware & Locks \nPryce89 \nDicos78 \n\n❌Regular Price  : ₹   399  /- 😱"
     }
    ]
   },
   {
    "id": 19412,
    "type": "message",
    "date": "2023-04-28",
    "date_unixtime": "1682672847",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1275",
    "dicos": "55",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Milton Trojan 4 Cont, Cream✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44beXEz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/427CzIq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/427CB30"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LCoopp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41MMxiD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41QTGP5"
     },
     {
      "type": "plain",
      "text": "\n\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HnoNJM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40MH8Xy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40H04Hb"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1275"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/41EBvbnnOTL._SY355_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Dining \n                Kitchen Storage & Containers \nPryce1275 \nDicos55 \n\n❌Regular Price  : ₹ 2499    /- 😱"
     }
    ]
   },
   {
    "id": 19413,
    "type": "message",
    "date": "2023-04-28",
    "date_unixtime": "1682672982",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "129",
    "dicos": "68",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Zebronics Zeb-Calyx Wired in Ear Earphones with Mic (Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LE9dwa"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HigzCM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40MHlKk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NjSoaU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nc1Xy4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41WOdWG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/448nVTh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vbvl44"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/4299HzN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/448lrUS"
     },
     {
      "type": "plain",
      "text": "\n\n @129\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71AUNLjgcEL._SX522_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Headphones, Earbuds & Accessories \n                Headphones \nPryce129 \nDicos68 \n\n❌Regular Price  : ₹   399  /- 😱"
     }
    ]
   },
   {
    "id": 19414,
    "type": "message",
    "date": "2023-04-28",
    "date_unixtime": "1682673110",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "13490",
    "dicos": "25",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Samsung 183 L 2 Star Digital Inverter Direct Cool Single Door Refrigerator (RR20C2412RH/NL, Scarlet Red)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44d9PzM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oRanLN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Li3ZVq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nh67Vd"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41LYTI0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VlZ7Dd"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VlgvrS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/442HjAW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AzjH9N"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AUbg9f"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@13490"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61NUB7uglNL._SY445_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Large Appliances \n                 Refrigerators \nPryce13490 \nDicos25 \n\n❌Regular Price  : ₹ 15999  /- 😱"
     }
    ]
   },
   {
    "id": 19415,
    "type": "message",
    "date": "2023-04-28",
    "date_unixtime": "1682673221",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "643",
    "dicos": "68",
    "catagory": "Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   ZEBRONICS Aluminium Alloy Laptop Stand, Compatible with 9-15.6 inch Laptops, 7 Angles Adjustable Tabletop, Anti Slip Silicon Rubber Pads, Foldable, Velvet Pouch Inside, Zeb-NS2000 (Dark Grey)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AC5rNy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hlw9xq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VaR9gh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oL4Bet"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VicPam"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vgl6eX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41Y5MWw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41MdGm2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LE9yiq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44cKKFq"
     },
     {
      "type": "plain",
      "text": "\n\n @643\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/41n3-joTUHL._SX300_SY300_QL70_FMwebp_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Computers & Accessories \n                Accessories & Peripherals \n                Laptop Accessories \nPryce643 \nDicos68 \n\n❌Regular Price  : ₹ 1499     /- 😱"
     }
    ]
   },
   {
    "id": 19416,
    "type": "message",
    "date": "2023-04-28",
    "date_unixtime": "1682673326",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1799",
    "dicos": "85",
    "catagory": "Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Fire-Boltt Tank 1.85\" Outdoor Rugged Bluetooth Calling Smart Watch, 123 Sports Mode, 8 UI Interactions, Built in Speaker & Mic, 7 Days Battery, Rs 100 Off on UPI (Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44a1CfS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NhEVAj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NgMDLa"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3n4GMOx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LE9XRY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LMpPC1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NlpVSf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40NNqq6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3n6O842"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NqOb5v"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1799"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61fVDvyHDiL._SX522_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Electronics \n                Wearable Technology \n                 Smart Watches \nPryce1799 \nDicos85 \n\n❌Regular Price  : ₹ 6999    /- 😱"
     }
    ]
   },
   {
    "id": 19417,
    "type": "message",
    "date": "2023-04-28",
    "date_unixtime": "1682673450",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "2700",
    "dicos": "31",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   CROMPTON Emergency 9W LED Bulb Base B22 Cool Day Light (Pack of 6)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3njt9uF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HlVpnm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40NNKVQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40MdIsD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40KJExM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40OT4It"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LBzHhx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oGGKws"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NgN6go"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3n6OrMe"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@2700"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/81WMpA28zkL._SX355_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Indoor Lighting \n                Light Bulbs \nPryce2700 \nDicos31 \n\n❌Regular Price  : ₹  3499   /- 😱"
     }
    ]
   },
   {
    "id": 19418,
    "type": "message",
    "date": "2023-04-28",
    "date_unixtime": "1682673575",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "367",
    "dicos": "57",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Butterfly Lynx Dry Iron 750 W✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HlMCBW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/422P0Fu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VbfjqV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oGGTQw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LfjgX5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41Ls2mC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44a1Svm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3L9O63c"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lh1D94"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41JuZE5"
     },
     {
      "type": "plain",
      "text": "\n\n @367\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61kuE5nGKZL._SY450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Home Appliances \n                Vacuum, Cleaning & Ironing \nPryce367 \nDicos57 \n\n❌Regular Price  : ₹  799   /- 😱"
     }
    ]
   },
   {
    "id": 19419,
    "type": "message",
    "date": "2023-04-28",
    "date_unixtime": "1682673738",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "199",
    "dicos": "60",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   \n by Nilkamal Dazzle Non Moving LED Candle White✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/449ckTX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41MYOne"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NhVNaj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/449EvSy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3n68XfZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LfjDRt"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LcYpDz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Nlo3c8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VimflZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LC9u2l"
     },
     {
      "type": "plain",
      "text": "\n\n @199\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71AxyWHxx1L._SY550_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Indoor Lighting \n                Light Bulbs \nPryce199 \nDicos60 \n\n❌Regular Price  : ₹   499 /- 😱"
     }
    ]
   },
   {
    "id": 19420,
    "type": "message",
    "date": "2023-04-28",
    "date_unixtime": "1682673840",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "409",
    "dicos": "74",
    "catagory": "Clothing",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Diverse Regular Men's Tshirt✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ADGaCk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AUclxP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HneZzH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HlTXl4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42acR6p"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/424qzaD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oPl3dQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VidZ5I"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/427Y0tc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/425gGtr"
     },
     {
      "type": "plain",
      "text": "\n\n @409\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/81tzUWjd1pL._UX342_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Clothing"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Clothing & Accessories \n                Men \n                T-Shirts & Polos \nPryce409 \nDicos74 \n\n❌Regular Price  : ₹ 1299    /- 😱"
     }
    ]
   },
   {
    "id": 19421,
    "type": "message",
    "date": "2023-04-28",
    "date_unixtime": "1682673943",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "465",
    "dicos": "61",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀  Luminous Plastic Solar Charge Controller 10Amp - Blue, AS03 ✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ADbMrM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NqPrFL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/448bzdQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VcOimM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HjEXUs"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oRLakp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41Y7yqE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44nUdtJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/429bwg7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LCqp4X"
     },
     {
      "type": "plain",
      "text": "\n\n @465\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71ZNx3OAPzL._SX522_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Outdoor Living \n                Solar & Wind Power \n                 Energy Controllers \nPryce465 \nDicos61 \n\n❌Regular Price  : ₹  999   /- 😱"
     }
    ]
   },
   {
    "id": 19422,
    "type": "message",
    "date": "2023-04-28",
    "date_unixtime": "1682674045",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "494",
    "dicos": "45",
    "catagory": "Health",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Nova NHT 1076 Cordless: 30 Minutes Runtime Trimmer for Men (Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oRc2B1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oVF3eA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oShOSY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LzF690"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Liifxv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LdikT6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oRZTff"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nfE9cz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/411FaTD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40KKB91"
     },
     {
      "type": "plain",
      "text": "\n\n @494\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51WJQh6Zb3S._SX522_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Health"
     },
     {
      "type": "plain",
      "text": " & Personal Care\n\n                Health & Personal Care \n                Personal Care \n                 Shaving, Waxing & Beard Care \nPryce494 \nDicos45 \n\n❌Regular Price  : ₹  899    /- 😱"
     }
    ]
   },
   {
    "id": 19423,
    "type": "message",
    "date": "2023-04-28",
    "date_unixtime": "1682674148",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "199",
    "dicos": "80",
    "catagory": "Clothing",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Pinkmint Women's Crepe Blue Color Floral Printed Straight Kurti (530367)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nc3Smg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NoRj1w"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/422Q5gw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VbgM0p"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AHJtZb"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vg0pzr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Ayim2W"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lhk911"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Nkswvu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41Y7Y0c"
     },
     {
      "type": "plain",
      "text": "\n\n @199\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71aU22sX7-L._UX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Clothing"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Clothing & Accessories \n                Women \n                Ethnic Wear \nPryce199 \nDicos80 \n\n❌Regular Price  : ₹  899   /- 😱"
     }
    ]
   },
   {
    "id": 19424,
    "type": "message",
    "date": "2023-04-28",
    "date_unixtime": "1682674254",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "806",
    "dicos": "77",
    "catagory": "Clothing",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   70L Travel Backpack Hiking Bag Adventure Camping Trekking Bag Rucksack -70L✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NnG1uE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VdSAu9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AAV24x"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HnOXMB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ndzZSy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41QW6x9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41N2p4X"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/447w6z3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41MPv6L"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LEPsEJ"
     },
     {
      "type": "plain",
      "text": "\n\n @806\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71OdW0cOeNL._UX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Clothing"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Bags, Wallets and Luggage \n                Bags & Backpacks \n                Backpacks \nPryce806 \nDicos77 \n\n❌Regular Price  : ₹ 1999   /- 😱"
     }
    ]
   },
   {
    "id": 19425,
    "type": "message",
    "date": "2023-04-28",
    "date_unixtime": "1682674357",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "699",
    "dicos": "68",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   ZEBRONICS PODS 2 Wireless TWS Earbuds with Bluetooth 5.3, LED Display, Voice Assistant, Call Function, Transparent Charging case, Touch Control & Built-in Rechargeable Battery - Neon✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40Ioi3T"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NlVsn8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NoqU3V"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LDjQ26"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oT0TiS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40OdmSe"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NgojJn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/424P5bJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oFpNT5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VcPuGM"
     },
     {
      "type": "plain",
      "text": "\n\n @699\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61rmtdRaDYL._SY355_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Headphones, Earbuds & Accessories \n                Headphones \nPryce699 \nDicos68 \n\n❌Regular Price  : ₹  1599    /- 😱"
     }
    ]
   },
   {
    "id": 19426,
    "type": "message",
    "date": "2023-04-28",
    "date_unixtime": "1682674461",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "799",
    "dicos": "60",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Ambrane Dots Play True Wireless Gaming in Ear Earbuds | 46ms Ultra-Low Latency | Lag-Free Audio | 19 Hours Long Playtime | Bluetooth V5.1 | 13mm Dynamic Drivers for Boosted Bass | IPX4 (Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41Lu5Hk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AC87uA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44dcQ34"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44a406k"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AAWXpJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40MKfyI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HmD49T"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NlsnrV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NtqMA2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3neSA0z"
     },
     {
      "type": "plain",
      "text": "\n\n @799\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/613N6k0uxxL._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Headphones, Earbuds & Accessories \n                Headphones \nPryce799 \nDicos60 \n\n❌Regular Price  : ₹  999   /- 😱"
     }
    ]
   },
   {
    "id": 19427,
    "type": "message",
    "date": "2023-04-28",
    "date_unixtime": "1682674563",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "799",
    "dicos": "60",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Ambrane Dots Play True Wireless Gaming in Ear Earbuds | 46ms Ultra-Low Latency | Lag-Free Audio | 19 Hours Long Playtime | Bluetooth V5.1 | 13mm Dynamic Drivers for Boosted Bass | IPX4 (Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NlwV1j"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oPge4d"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oRdd3p"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41JaeIX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VdZek8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Ljhfce"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VbEREn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44a4r0s"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LCnOYI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LCnOYI"
     },
     {
      "type": "plain",
      "text": "\n\n @799\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/613N6k0uxxL._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Headphones, Earbuds & Accessories \n                Headphones \nPryce799 \nDicos60 \n\n❌Regular Price  : ₹  1099   /- 😱"
     }
    ]
   },
   {
    "id": 19428,
    "type": "message",
    "date": "2023-04-28",
    "date_unixtime": "1682674663",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "298",
    "dicos": "83",
    "catagory": "Beauty",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Makeup Revolution Skincare Make Up Removal Oil, Nude✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3na0IiO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LeQ1Ue"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40H3k5n"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LE5Mph"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HnsVJM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3neT86D"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hhn20D"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LBUJN0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LA8sUM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LCohKs"
     },
     {
      "type": "plain",
      "text": "\n\n @298\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/516lX30o8fS._SY450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Beauty"
     },
     {
      "type": "plain",
      "text": "\n\n                Beauty \n                Make-up \n                Face \nPryce298 \nDicos83 \n\n❌Regular Price  : ₹ 1399    /- 😱"
     }
    ]
   },
   {
    "id": 19429,
    "type": "message",
    "date": "2023-04-28",
    "date_unixtime": "1682674786",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "399",
    "dicos": "80",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Tygot 10\" Portable LED Ring Light with 3 Color Modes Dimmable Lighting | for YouTube | Photo-Shoot | Video Shoot | Live Stream | Makeup & Vlogging | Compatible with iPhone/Android Phones & Cameras✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nbPf2m"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/449HsT8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oS1bXt"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/426zdWk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40NQNNM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/429d75B"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oKmfyV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HjH5vq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AGwQgY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41JGJX7"
     },
     {
      "type": "plain",
      "text": "\n\n @399\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61yKj3SE4uL._SX522_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Cameras & Photography \n                Flashes \nPryce399 \nDicos80 \n\n❌Regular Price  : ₹ 1499    /- 😱"
     }
    ]
   },
   {
    "id": 19430,
    "type": "message",
    "date": "2023-04-28",
    "date_unixtime": "1682674908",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "949",
    "dicos": "32",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Al Hind Mini Sewing Machine Motor (Full Copper Winding) with Speed Controller Red and Black Color✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41N1eCk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AC9aL2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oOFrM3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VejwKo"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3na1lsG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HnDHjo"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HlPL4I"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oUxgh9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40QeHIi"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hq09s3"
     },
     {
      "type": "plain",
      "text": "\n\n @949\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61rQ+GhETYL._SX355_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Home Appliances \n                Sewing Machines & Accessories \nPryce949 \nDicos32 \n\n❌Regular Price  : ₹   1399  /- 😱"
     }
    ]
   },
   {
    "id": 19431,
    "type": "message",
    "date": "2023-04-28",
    "date_unixtime": "1682675029",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1865",
    "dicos": "63",
    "catagory": "Car",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Woscher Air Purifier with True HEPA Air Purifiers Filter for Car Allergies and Pets, Smokers, Smoke, Dust, Mold, and Pollen, Air Cleaner for Hatchback,SUV & Sedans with USB Mobile Charging Point, W-0815A✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LmW9dg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hmvv2H"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NDt9Rh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NqSsWB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40KNreu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/427ad1e"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40RC7gl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3n9isLa"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Azf7rM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LeSWwe"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1865"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/31qk1JplMmL.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Car"
     },
     {
      "type": "plain",
      "text": " & Motorbike\n\n                Car & Motorbike \n                Car Accessories \n                Interior Accessories \nPryce1865 \nDicos63 \n\n❌Regular Price  : ₹   3999  /- 😱"
     }
    ]
   },
   {
    "id": 19432,
    "type": "message",
    "date": "2023-04-28",
    "date_unixtime": "1682675132",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "549",
    "dicos": "61",
    "catagory": "Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Dell Kb216 Wired Multimedia USB Keyboard with Super Quite Plunger Keys with Spill-Resistant Black✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/445yeYl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lfu879"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oGKDBy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NtsH7I"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VdVT4y"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Nmjn5J"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oP98N0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41JyJW9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3n8XU5D"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LdFAA7"
     },
     {
      "type": "plain",
      "text": "\n\n @549\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/811YM2Go9GL._SX522_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Computers & Accessories \n                Accessories & Peripherals \n                Keyboards, Mice & Input Devices \nPryce549 \nDicos61 \n\n❌Regular Price  : ₹   1199  /- 😱"
     }
    ]
   },
   {
    "id": 19433,
    "type": "message",
    "date": "2023-04-28",
    "date_unixtime": "1682675237",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "3999",
    "dicos": "41",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Crompton Energion Cromair 1200mm (48 inch) BLDC Antidust Ceiling Fan High Speed 5S 28W Energy Efficient with Remote (Satin Sand beige)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3njXBVs"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44bwaxN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ACAtVo"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VihjxI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/448ME9X"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oS2u8P"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oOa5W2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Azs1Go"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AzoLuP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VcRPS4"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@3999"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51ng5Sm263L._SY450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Heating, Cooling & Air Quality \n                Fans \nPryce3999 \nDicos41 \n\n❌Regular Price  : ₹  5999  /- 😱"
     }
    ]
   },
   {
    "id": 19434,
    "type": "message",
    "date": "2023-04-28",
    "date_unixtime": "1682675337",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "283",
    "dicos": "72",
    "catagory": "Baby",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   CHILDCHIC 90ML Newborn Baby Feeding Bottle Toddler Silicone Squeeze Feeding Spoon Milk Cereal Training Feeder and Fruit Feeder(Set of 2) (Yellow)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hq0Hy7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LBvoTr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HlrWK5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41LczmB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HjIlPa"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HlAGQs"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nbQyyi"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HnETmS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44df4zs"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44a6FNm"
     },
     {
      "type": "plain",
      "text": "\n\n @283\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/5116ErSG2iL._SX450_PIbundle-2,TopRight,0,0_SX450SY439SH20_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Baby"
     },
     {
      "type": "plain",
      "text": "\n\n                Baby \n                Feeding \n                Bottle Feeding \nPryce283 \nDicos72 \n\n❌Regular Price  : ₹   899  /- 😱"
     }
    ]
   },
   {
    "id": 19435,
    "type": "message",
    "date": "2023-04-28",
    "date_unixtime": "1682675460",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "249",
    "dicos": "43",
    "catagory": "Grocery",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   UNIBIC Cookies, Choconut Finger Cookies, 400g | Choconut Cookies | Choconut Finger | Choconut Cookies Sugar Free | Choconut Cookies Box✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NmNJoG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HhpV1J"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ncJMIw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ADzZhU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ncJQrK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40MMke0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HlsIqt"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VcS0wI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44dfVjG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HlR9Es"
     },
     {
      "type": "plain",
      "text": "\n\n @249\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71QMCqZAdvL._SX569_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Grocery"
     },
     {
      "type": "plain",
      "text": " & Gourmet Foods\n\n                Grocery & Gourmet Foods \n                Snacks & Sweets \n                Snack Foods \nPryce249 \nDicos43 \n\n❌Regular Price  : ₹  439    /- 😱"
     }
    ]
   },
   {
    "id": 19436,
    "type": "message",
    "date": "2023-04-28",
    "date_unixtime": "1682675584",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "2159",
    "dicos": "66",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Amazon Brand - Solimo - Multipurpose Rack for Shoes and Clothes, 8 Racks, Blue, Linen✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/429eOzZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/427Ic9w"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41QZ1G7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41OE7rk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/448fSWy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44esZpk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LcyvQt"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LzOMiL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AAXXu1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AxFL4B"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@2159"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71qlunPLjlL._SY879_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Home Storage & Organisation \n                Shoe Organization \nPryce2159 \nDicos66 \n\n❌Regular Price  : ₹  4999   /- 😱"
     }
    ]
   },
   {
    "id": 19437,
    "type": "message",
    "date": "2023-04-28",
    "date_unixtime": "1682675688",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "4490",
    "dicos": "44",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Pigeon Healthifry Digital Air Fryer, 360° High Speed Air Circulation Technology 1200 W with Non-Stick 4.2 L Basket - Green✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AC36St"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HilROE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HnFgxM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ncK8Pm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ncKaqs"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NlpIOF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Ngr11v"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NhKaQv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nc7apC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/448tMYL"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@4490"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61mfMId4dkL._SX466_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Home Appliances \n                Small Kitchen Appliances \nPryce4490 \nDicos44 \n\n❌Regular Price  : ₹   5999  /- 😱"
     }
    ]
   },
   {
    "id": 19438,
    "type": "message",
    "date": "2023-04-28",
    "date_unixtime": "1682675789",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1504",
    "dicos": "65",
    "catagory": "Clothing",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Lil Tomatoes Boys Drawstring Regular Fit Printed Hooded Cotton Fleece Track Suit✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40QgkWq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/411JiD7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NmkylF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LAiRzQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ACcmWY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41MiU18"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AzSmnF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41SqdEE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oOHn7h"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VdWmUl"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1504"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51+86ctsSRL._UX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Clothing"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Clothing & Accessories \n                Boys \n                Sportswear \nPryce1504 \nDicos65 \n\n❌Regular Price  : ₹   2999  /- 😱"
     }
    ]
   },
   {
    "id": 19439,
    "type": "message",
    "date": "2023-04-28",
    "date_unixtime": "1682675889",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "300",
    "dicos": "62",
    "catagory": "Clothing",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   W Women's Chudidar Bottom✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HnYtzs"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40MLs9a"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/426BWiw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44cFW2U"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/4284ckE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44dGzsM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vhh1qU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VbBJIA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vlmwos"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oOIPqr"
     },
     {
      "type": "plain",
      "text": "\n\n @300\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71QtxfJBJTL._UX342_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Clothing"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Clothing & Accessories \n                Women \n                Ethnic Wear \nPryce300 \nDicos62"
     }
    ]
   },
   {
    "id": 19440,
    "type": "message",
    "date": "2023-04-28",
    "date_unixtime": "1682675990",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "782",
    "dicos": "54",
    "catagory": "Shoes",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Carlton London Sports Mens Cl-m-5501_Brown Sports Shoes✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lh6GX4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Ldnkak"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Ljk64W"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3njyIJz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ncdZr7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HimrMk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AzSGTp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ADLqG4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hm1gsQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NlZoUW"
     },
     {
      "type": "plain",
      "text": "\n\n @782\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71KBzwOnZES._UX500_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Shoes"
     },
     {
      "type": "plain",
      "text": " & Handbags\n\n                Shoes & Handbags \n                Shoes \n                Men's Shoes \nPryce782 \nDicos54 \n\n❌Regular Price  : ₹ 1499   /- 😱"
     }
    ]
   },
   {
    "id": 19441,
    "type": "message",
    "date": "2023-04-28",
    "date_unixtime": "1682676093",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1179",
    "dicos": "76",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   PHILIPS Audio Tat1225 Bluetooth Truly Wireless in Ear Earbuds with Mic (TWS) with 18 Hours Playtime (6+12) Ipx4, Voice Assistant, Noise and Echo Cancellation (Blue)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LE9ajZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oOHGir"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40NERM3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40LIWjJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LhoIsb"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Li9xzd"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Nmld6D"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41IJb08"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NfxBFw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NkwOD6"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1179"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/718HvK+RQNL._SX522_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Headphones, Earbuds & Accessories \n                Headphones \nPryce1179 \nDicos76 \n\n❌Regular Price  : ₹  3999  /- 😱"
     }
    ]
   },
   {
    "id": 19442,
    "type": "message",
    "date": "2023-04-28",
    "date_unixtime": "1682676193",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "339",
    "dicos": "66",
    "catagory": "Clothing",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   AEROPOSTALE Men T-Shirt✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3n90yIB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HhpOmz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HjK5ba"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oVJZjC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40IsQat"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41MjXOC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40KPVJQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nk0qWy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NkOH4E"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oSnc8E"
     },
     {
      "type": "plain",
      "text": "\n\n @339\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/81IW7NhrOxL._UX342_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Clothing"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Clothing & Accessories \n                Men \n                T-Shirts & Polos \nPryce339 \nDicos66 \n\n❌Regular Price  : ₹  899  /- 😱"
     }
    ]
   },
   {
    "id": 19443,
    "type": "message",
    "date": "2023-04-28",
    "date_unixtime": "1682676296",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "2499",
    "dicos": "75",
    "catagory": "Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Fire-Boltt Gladiator 1.96\" Biggest Display Smart Watch with Bluetooth Calling, Voice Assistant &123 Sports Modes, 8 Unique UI Interactions, SpO2, 24/7 Heart Rate Tracking, Rs 100 Off on UPI (Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LdGrko"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nhcaJp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nceQIl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oLcbFX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ACq4Jm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AxH6IF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LBFTGj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LzKqcu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NlAR25"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/425mkvD"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@2499"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71IqWwSIxwL._SX522_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Electronics \n                Wearable Technology \n                 Smart Watches \nPryce2499 \nDicos75 \n\n❌Regular Price  : ₹  6999  /- 😱"
     }
    ]
   },
   {
    "id": 19444,
    "type": "message",
    "date": "2023-04-28",
    "date_unixtime": "1682676398",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "740",
    "dicos": "48",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀Bajaj 10W B22D Led Cool Day Light Linear Bulb, Pack of 3   ✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Nfy2zE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41KdJyM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HjKxGo"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41OO0oM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40Ohxxo"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Azrc0r"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LcAq7D"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oKq9YB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nf2GyA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NfyuxQ"
     },
     {
      "type": "plain",
      "text": "\n\n @740\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51YfC1qYC5L._SY355_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Indoor Lighting \n                Light Bulbs \nPryce740 \nDicos48 \n\n❌Regular Price  : ₹  897  /- 😱"
     }
    ]
   },
   {
    "id": 19445,
    "type": "message",
    "date": "2023-04-28",
    "date_unixtime": "1682676509",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1049",
    "dicos": "42",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   SYSKA Porta-Glow 7.5W Emergency Rechargeable 3 Stage Dimming And Color Changing LED Study/ Desk/Table Lamp- (Cool Day Light/Warm White/Neutral White, Plastic, Pack of 1)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NmBB7g"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NmQxSK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hldj9V"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ndFh0m"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ncM2iY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VlnX6k"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Nk08cY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3n6VyUW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40Itzsd"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oTknny"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1049"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61xwtZpyeeL._SX342_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Indoor Lighting \n                 Desk Lights \nPryce1049 \nDicos42 \n\n❌Regular Price  : ₹  1599   /- 😱"
     }
    ]
   },
   {
    "id": 19446,
    "type": "message",
    "date": "2023-04-28",
    "date_unixtime": "1682676612",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "398",
    "dicos": "31",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Bajaj Ivora HB LED LAMP 9W CDL B22 (Pack of 4)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40LENfy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/4285JaG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AA2kFx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oPry0e"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44eInSj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VbylNP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41OGjPA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oGOzlO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vg8tQW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oOKmgb"
     },
     {
      "type": "plain",
      "text": "\n\n @398\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61KwQDhZxbS._SX522_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Indoor Lighting \n                Light Bulbs \nPryce398 \nDicos31 \n\n❌Regular Price  : ₹  580  /- 😱"
     }
    ]
   },
   {
    "id": 19447,
    "type": "message",
    "date": "2023-04-28",
    "date_unixtime": "1682676715",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "790",
    "dicos": "51",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Bajaj Acrylonitrile Butadiene Styrene Softlite Led Rc Table Lamp 2W (Pack of 1, White)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oS5wdn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oOd68O"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LbzHDL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LC8M5i"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3n91VXL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LDk7Cj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40KgOxu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NtwZvQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Nmms5N"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/421dX4N"
     },
     {
      "type": "plain",
      "text": "\n\n @790\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/41KVkWEn6ML._SY450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Indoor Lighting \n                Light Bulbs \nPryce790 \nDicos51 \n\n❌Regular Price  : ₹ 1299    /- 😱"
     }
    ]
   },
   {
    "id": 19448,
    "type": "message",
    "date": "2023-04-28",
    "date_unixtime": "1682676819",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "205",
    "dicos": "32",
    "catagory": "Beauty",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Clean & Clear Foaming Facewash for Oily Skin, Brown, 240ml✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/420IDT4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/422HHh4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41LzvlE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/427KXro"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lh97Jc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41MlvYW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NDwPCz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oRiyaX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41JKZG5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41JKZG5"
     },
     {
      "type": "plain",
      "text": "\n\n @205\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51cJ-IDx5PL._SY450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Beauty"
     },
     {
      "type": "plain",
      "text": "\n\n                Beauty \n                Skin Care \n                Face \nPryce205 \nDicos32 \n\n❌Regular Price  : ₹  300   /- 😱"
     }
    ]
   },
   {
    "id": 19449,
    "type": "message",
    "date": "2023-04-28",
    "date_unixtime": "1682676919",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "332",
    "dicos": "80",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Halonix Kornet 5.5-Watt LED Downlighter (Cool Day Light)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vg6AU9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hm1twi"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LCgigp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oVLnCQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40NGHwr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oKqXN7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oKqZEJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/448Q78t"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VglGt1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3L9VrQi"
     },
     {
      "type": "plain",
      "text": "\n\n @332\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71J+5drcU1L._SX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Indoor Lighting \n                Light Bulbs \nPryce332 \nDicos80 \n\n❌Regular Price  : ₹   1399   /- 😱"
     }
    ]
   },
   {
    "id": 19450,
    "type": "message",
    "date": "2023-04-28",
    "date_unixtime": "1682677022",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "179",
    "dicos": "40",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   SYSKA B22D LED Bulb SSK-SRL - 9W, Pack of 2 (Cool Day Light)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/449Mj6O"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AB2CMv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42aksBX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/449cpac"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ncMSfC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hny18S"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hlj4UW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VdfGky"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3L7gxi4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AGCfEM"
     },
     {
      "type": "plain",
      "text": "\n\n @179\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/716F-aiWrpL._SY355_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Indoor Lighting \n                Light Bulbs \nPryce179 \nDicos40 \n\n❌Regular Price  : ₹  649   /- 😱"
     }
    ]
   },
   {
    "id": 19451,
    "type": "message",
    "date": "2023-04-28",
    "date_unixtime": "1682677126",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "250",
    "dicos": "75",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Philips Ace Saver 9 Watt LED Bulb, Base B22 (Cool Day Light), Pack of 2✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nf3WBO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LzRLaX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LEbWpp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/422InD8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NkyKvm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42anFkV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oKrbUt"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AyVP67"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/424xror"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41LA278"
     },
     {
      "type": "plain",
      "text": "\n\n @250\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61KP7EtFyQL._SY355_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Indoor Lighting \n                Light Bulbs \nPryce250 \nDicos75 \n\n❌Regular Price  : ₹ 899   /- 😱"
     }
    ]
   },
   {
    "id": 19452,
    "type": "message",
    "date": "2023-04-28",
    "date_unixtime": "1682677228",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "129",
    "dicos": "68",
    "catagory": "Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Tizum Mouse Pad/ Computer Mouse Mat with Anti-Slip Rubber Base | Smooth Mouse Control | Spill-Resistant Surface for Laptop, Notebook, MacBook Pro, Gaming, Laser/ Optical Mouse, 9.4”x 7.9”, Black-Green✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oTjxqR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41JLos5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40H8Ip7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LiiAQw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ncaMba"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41JLJuR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oQDRJG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oPlPrf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AC9ycj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NgVbSg"
     },
     {
      "type": "plain",
      "text": "\n\n @129\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71T4xJbYomL._SY450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Computers & Accessories \n                Accessories & Peripherals \n                Keyboards, Mice & Input Devices \nPryce129 \nDicos68 \n\n❌Regular Price  : ₹  399    /- 😱"
     }
    ]
   },
   {
    "id": 19453,
    "type": "message",
    "date": "2023-04-28",
    "date_unixtime": "1682677332",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "415",
    "dicos": "41",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   SYSKA 9W B22 6500K Cool Day Light Inverter Rechargeable Emergency Led Bulb Pack of 1✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3neYAGD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hm2owK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44mwIkG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Nlq5Zx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/447CJBr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44aathC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LibsUr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40NWkE2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/425ocEF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/425ocEF"
     },
     {
      "type": "plain",
      "text": "\n\n @415\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/717Yp2t-R9L._SX522_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Home & kitchen | Prime shopping days \n                Prime Units \nPryce415 \nDicos41 \n\n❌Regular Price  : ₹  699  /- 😱"
     }
    ]
   },
   {
    "id": 19454,
    "type": "message",
    "date": "2023-04-28",
    "date_unixtime": "1682677439",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "679",
    "dicos": "9",
    "catagory": "Clothing",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Max Boys Printed T-Shirt - Set of 3✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AUkSRn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41Lgn7n"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3n9njMo"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ndGKUq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44bofQX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LbAKDH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44dK5mY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/448yfKX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LzMp0q"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LAmiqe"
     },
     {
      "type": "plain",
      "text": "\n\n @679\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51iSTO4QDrL._UX425_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Clothing"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Clothing & Accessories \n                Baby \n                Baby Boys \nPryce679 \nDicos9"
     }
    ]
   },
   {
    "id": 19455,
    "type": "message",
    "date": "2023-04-28",
    "date_unixtime": "1682677542",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1150",
    "dicos": "71",
    "catagory": "Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Ant Esports H1000 Pro Wired Over Ear Headphones with Mic (Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AyWrsr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VimqOq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/422OkQv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ABUbR2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://www.amazon.in/dp/B08L41W6B3?th=1&amp;linkCode=sl1&amp;tag=avi1deals5-21&amp;linkId=3f502cd988f197c6d8e0d4a3e9022a05&amp;language=en_IN&amp;ref_=as_li_ss_tl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AB90TX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hhzi1u"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NhNsTR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NgVtsk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41JDbUR"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1150"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51iQBu-mARL._SY355_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Computers & Accessories \n                Accessories & Peripherals \n                PC Gaming Peripherals \nPryce1150 \nDicos71 \n\n❌Regular Price  : ₹  2999   /- 😱"
     }
    ]
   },
   {
    "id": 19456,
    "type": "message",
    "date": "2023-04-28",
    "date_unixtime": "1682677643",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "549",
    "dicos": "76",
    "catagory": "Health",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Lifelong LLM342 Facial Cleansing Massager Brush, Rechargeable Electric Handheld Face Massager, Waterproof Ultra Hygienic Soft Silicone Face Scrubber (Pink)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3n6XqNs"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41N7xG0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NkRDOI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oVMW3G"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Ve0q73"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3n3rQAc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HhsBw3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HJlPzN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3L7ht64"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40KrJqP"
     },
     {
      "type": "plain",
      "text": "\n\n @549\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61VaLhWeJyL._SY355_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Health"
     },
     {
      "type": "plain",
      "text": " & Personal Care\n\n                Health & Personal Care \n                Health Care \n                Massage & Relaxation \nPryce549 \nDicos76 \n\n❌Regular Price  : ₹  1499   /- 😱"
     }
    ]
   },
   {
    "id": 19457,
    "type": "message",
    "date": "2023-04-28",
    "date_unixtime": "1682677743",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "24999",
    "dicos": "83",
    "catagory": "Health",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀 Lifelong LLM567 Fully Body Foldable Chair Massager with Recliner and Powerful 3D Back, Leg and Foot Rollers for Massage for Home|Massage Chair for Full Body Relaxation at Home (1 Year Warranty)  ✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40GBBSB"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AC6WuR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/448RG6l"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44boZFJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HoalRP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3L7igEg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44dKIwQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vgnh1Z"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oRTfFL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LdqLxK"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@24999"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61CzYUuzwyL._SY450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Health"
     },
     {
      "type": "plain",
      "text": " & Personal Care\n\n                Health & Personal Care \n                Health Care \n                Massage & Relaxation \nPryce24999 \nDicos83 \n\n❌Regular Price  : ₹  49999   /- 😱"
     }
    ]
   },
   {
    "id": 19458,
    "type": "message",
    "date": "2023-04-28",
    "date_unixtime": "1682677845",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "537",
    "dicos": "67",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   GM Atmos 22 Watt LED Batten Tube Light, 100 Lumens Per Watt, 6500K, Long Life, Anti-glare Diffuser, Fluctuation Proof (Pack of, 3)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oUfKde"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HmBapt"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LgqhGZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HlV4Ba"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41JMzYx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HnJzJs"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LEkblk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LDma9t"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3n9o4oI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44ex5Oe"
     },
     {
      "type": "plain",
      "text": "\n\n @537\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/41fqGvjfauL._SX342_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Indoor Lighting \n                 Tube Lights & Battens \nPryce537 \nDicos67 \n\n❌Regular Price  : ₹  1299    /- 😱"
     }
    ]
   },
   {
    "id": 19459,
    "type": "message",
    "date": "2023-04-28",
    "date_unixtime": "1682677947",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "5999",
    "dicos": "76",
    "catagory": "Health",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Lifelong LLM432 Air Bag Foot Massager | Shiatsu Foot Massager Machine with Soothing Heat, Deep Kneading Therapy, Air Compression, for Blood Circulation and Foot Wellness, Brown✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/427WFlN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HmfNoa"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41JhgNR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LEkuN0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LEde3J"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NmTfYq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NkSdvS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40LH8XS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VgaJYq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NlBnNv"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@5999"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61SpdXSR1ZL._SX522_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Health"
     },
     {
      "type": "plain",
      "text": " & Personal Care\n\n                Health & Personal Care \n                Health Care \n                Massage & Relaxation \nPryce5999 \nDicos76 \n\n❌Regular Price  : ₹ 14999   /- 😱"
     }
    ]
   },
   {
    "id": 19460,
    "type": "message",
    "date": "2023-04-28",
    "date_unixtime": "1682678046",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "265",
    "dicos": "82",
    "catagory": "Clothing",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Atelier Women's Regular Fit T-Shirt✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HlfNVN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://www.amazon.in/Atelier-Womens-Regular-T-Shirt-BT11075_Yellow/dp/B08GL69J1W?crid=1V78ZRPQ5O0GL&amp;keywords=women%2Bclothing&amp;m=A1WYWER0W24N8S&amp;qid=1677640909&amp;refinements=p_n_pct-off-with-tax%3A27060457031%2Cp_85%3A10440599031%2Cp_6%3AA1WYWER0W24N8S&amp;rnid=1318474031&amp;rps=1&amp;s=apparel&amp;sprefix=women%2Bclothin%2Caps%2C261&amp;sr=1-288&amp;th=1&amp;psc=1&amp;linkCode=sl1&amp;tag=bmvavinash05-21&amp;linkId=b919b98898ce11d99c396f49e138ff78&amp;language=en_IN&amp;ref_=as_li_ss_tl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40QNmps"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HlkVsS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/422Ptrh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NlwZyc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/426xoZn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AxJW0h"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vgnq5r"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40QNvJw"
     },
     {
      "type": "plain",
      "text": "\n\n @265\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/711QSTBvGoL._UX342_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Clothing"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Clothing & Accessories \n                Women \n                Western Wear \nPryce265 \nDicos82 \n\n❌Regular Price  : ₹   999   /- 😱"
     }
    ]
   },
   {
    "id": 19461,
    "type": "message",
    "date": "2023-04-28",
    "date_unixtime": "1682678147",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "379",
    "dicos": "53",
    "catagory": "Tools",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   WIPRO ESSENTIAL 4+1 EXTENSION✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3n9XZWz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40G9aEc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/449eeE4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oS8dM1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40QNNA6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HkDtcG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oOfFaW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://www.amazon.in/dp/B0B7WYDNSH?th=1&amp;linkCode=sl1&amp;tag=avi1deals8-21&amp;linkId=f0913ba1d4833cd24a13ed1a1bb19bae&amp;language=en_IN&amp;ref_=as_li_ss_tl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nk4je6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42apFJX"
     },
     {
      "type": "plain",
      "text": "\n\n @379\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51puaVi7poL._SY355_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Tools"
     },
     {
      "type": "plain",
      "text": " & Home Improvement\n\n                Electronics \n                Power Accessories \n                 Extension Cords \nPryce379 \nDicos53 \n\n❌Regular Price  : ₹  749    /- 😱"
     }
    ]
   },
   {
    "id": 19462,
    "type": "message",
    "date": "2023-04-28",
    "date_unixtime": "1682678255",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "129",
    "dicos": "39",
    "catagory": "Tools",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Gala Steel Scrubber Combo Set (Pack of 6)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hno8Z3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3njDbfj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HiqBnq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LbC5KJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HkE5Pw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LAfCZa"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LikBw4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LCACOT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oKu2wF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AyrLYi"
     },
     {
      "type": "plain",
      "text": "\n\n @129\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51f1PryRdvS._SX300_SY300_QL70_FMwebp_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Tools"
     },
     {
      "type": "plain",
      "text": " & Home Improvement\n\n                Home & Kitchen \n                Home & kitchen | Prime shopping days \n                Prime Units \nPryce129 \nDicos39 \n\n❌Regular Price  : ₹  210  /- 😱"
     }
    ]
   },
   {
    "id": 19463,
    "type": "message",
    "date": "2023-04-28",
    "date_unixtime": "1682678359",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "165",
    "dicos": "35",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Bajaj 2-Watt LED Spot Light (White) (Model: 830048)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NjBGZ8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42amNwJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NmER2u"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AzywJi"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HnAqjU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vdi22S"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/4289jBm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41JNsQR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oGRACE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/425pShr"
     },
     {
      "type": "plain",
      "text": "\n\n @165\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/5106+3Z6unL._SY450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Indoor Lighting \n                Light Bulbs \nPryce165 \nDicos35 \n\n❌Regular Price  : ₹ 255    /- 😱"
     }
    ]
   },
   {
    "id": 19464,
    "type": "message",
    "date": "2023-04-28",
    "date_unixtime": "1682678459",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "261",
    "dicos": "25",
    "catagory": "Beauty",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Swiss Beauty HD Concealer Pen | Color Corrector for Natural Finish, Creamy Beige, 4ml✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3n94Hw9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3n6YG38"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44o3AJV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41N952M"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NDzufx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41N99zy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NmU0AK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/427OaqW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/427XuLp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/422LESW"
     },
     {
      "type": "plain",
      "text": "\n\n @261\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/41t3uS9z7QL._SY355_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Beauty"
     },
     {
      "type": "plain",
      "text": "\n\n                Beauty \n                Make-up \n                Face \nPryce261 \nDicos25 \n\n❌Regular Price  : ₹  349   /- 😱"
     }
    ]
   },
   {
    "id": 19465,
    "type": "message",
    "date": "2023-04-28",
    "date_unixtime": "1682678561",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "309",
    "dicos": "83",
    "catagory": "Clothing",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   T2F Boys Sweatshirt✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oT9oKO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/424Ai0D"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40NYwvg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40LI8LC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NmUneC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/448xK3w"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40MS9Io"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44dLLwM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Ve2JHg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VtTbIB"
     },
     {
      "type": "plain",
      "text": "\n\n @309\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/81muGdynNXL._UX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Clothing"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Clothing & Accessories \n                Boys \n                Winter Wear \nPryce309 \nDicos83 \n\n❌Regular Price  : ₹  1299   /- 😱"
     }
    ]
   },
   {
    "id": 19466,
    "type": "message",
    "date": "2023-04-28",
    "date_unixtime": "1682678661",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "337",
    "dicos": "44",
    "catagory": "Beauty",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   WOW Skin Science Retinol Face Serum | Vitamin B3 & E | Skin Plumping, Boost Collagen, Anti Acne, Anti Aging, Restoration | Face Serum For Women & Men | Paraben Free | 30 ml✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40KkgYY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AC8y7T"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3L7jvDq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hq6Wlx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/426GQvW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VgbWyW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hmh5Q2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HhvUUf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Ve2Wdv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oSrHA4"
     },
     {
      "type": "plain",
      "text": "\n\n @337\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/813nNmMEfgL._SY450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Beauty"
     },
     {
      "type": "plain",
      "text": "\n\n                Beauty \n                Skin Care \n                Face \nPryce337 \nDicos44 \n\n❌Regular Price  : ₹  599   /- 😱"
     }
    ]
   },
   {
    "id": 19467,
    "type": "message",
    "date": "2023-04-28",
    "date_unixtime": "1682678764",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1769",
    "dicos": "41",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀  Polycab Zoomer DLX High Speed 1200 mm 1 star rating Ceiling Fan (Luster Brown) ✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42andmN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3neBekg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LgZv13"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Ho344G"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hm6XXK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41NiAPw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41OS2xq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LyCI2d"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/422LZoG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40UkY64"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1769"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61BlD+ott6L._SY355_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Heating, Cooling & Air Quality \n                Fans \nPryce1769 \nDicos41 \n\n❌Regular Price  : ₹ 2999    /- 😱"
     }
    ]
   },
   {
    "id": 19468,
    "type": "message",
    "date": "2023-04-28",
    "date_unixtime": "1682678864",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "195",
    "dicos": "40",
    "catagory": "Beauty",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Parachute Advansed Deep Nourish Face & Body Cream, 280ml|Moisturiser For Face & Body, 100% Natural, 72h, Dry Skin✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44bqmUT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Nk4Oj2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/444D6Nj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oN6Pdo"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HmhnGC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/428i5PI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VlrGAQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NoAwM3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VbHVjO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LBKokb"
     },
     {
      "type": "plain",
      "text": "\n\n @195\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/81eHFbRpjjL._SY450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Beauty"
     },
     {
      "type": "plain",
      "text": "\n\n                Beauty \n                Skin Care \n                Face \nPryce195 \nDicos40 \n\n❌Regular Price  : ₹  325  /- 😱"
     }
    ]
   },
   {
    "id": 19469,
    "type": "message",
    "date": "2023-04-28",
    "date_unixtime": "1682678966",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "139",
    "dicos": "72",
    "catagory": "Clothing",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Life by Shoppers Stop Girls Round Neck Printed Top✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oN72NI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41DaokV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NjCwoK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41MYGUL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NtAQci"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NkTMde"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NkTH9q"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Nhi1cx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40P4ig5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LCwDli"
     },
     {
      "type": "plain",
      "text": "\n\n @139\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51SBzfPW3uL._UX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Clothing"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Fashion \n                Apparel Steal Deals \n                Apparel_1 \nPryce139 \nDicos72 \n\n❌Regular Price  : ₹  499    /- 😱"
     }
    ]
   },
   {
    "id": 19470,
    "type": "message",
    "date": "2023-04-28",
    "date_unixtime": "1682679173",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "529",
    "dicos": "62",
    "catagory": "Clothing",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Miss Olive Women's Regular Shirt✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/424YnED"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hq7S9x"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hlzn3X"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41KI7sR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VgYtGU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41NgMWN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LEYQIt"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44adeQ0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AyZot1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oOB5EI"
     },
     {
      "type": "plain",
      "text": "\n\n @529\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/81IIWap7W8L._UY445_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Clothing"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Clothing & Accessories \n                Women \n                Western Wear \nPryce529 \nDicos62 \n \n❌Regular Price  : ₹  1099  /- 😱"
     }
    ]
   },
   {
    "id": 19471,
    "type": "message",
    "date": "2023-04-28",
    "date_unixtime": "1682679273",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "252",
    "dicos": "83",
    "catagory": "Car",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Oshotto Car Backseat Storage Organizer Phone Holder,Multi-Pocket for Bottles, Tissue Boxes,Kids Toy Storage and Great Travel Accessory Compatible with Audi A3 (Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nbYcJ0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41LjTP7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AA6PzV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HkFtlc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41OLChY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Nk5uVC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LEZ4iN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LDtd1M"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/448TZ9v"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44adsGQ"
     },
     {
      "type": "plain",
      "text": "\n\n @252\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/514urUh6pzL._SX450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Car"
     },
     {
      "type": "plain",
      "text": " & Motorbike\n\n                Car & Motorbike \n                 Transporting & Storage \nPryce252 \nDicos83 \n\n❌Regular Price  : ₹  1099   /- 😱"
     }
    ]
   },
   {
    "id": 19472,
    "type": "message",
    "date": "2023-04-28",
    "date_unixtime": "1682679373",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1560",
    "dicos": "74",
    "catagory": "Watches",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   French Connection Full Touch Smartwatch with 1.69'' Large Display, Heart Rate Monitor, Multiple Watch Faces, Unisex Smart Watch✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LD7abG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3n966Tr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oSsOje"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Ve3HDn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LzPJbU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HnBLaq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40Iy85P"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40Mp7Zt"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LdtuY0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NjDmlo"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1560"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71OBiYrBIFL._UX342_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Watches"
     },
     {
      "type": "plain",
      "text": "\n\n                Fashion \n                Onam 2021 \n                Watches and fashion jewellery \nPryce1560 \nDicos74 \n\n❌Regular Price  : ₹  3999   /- 😱"
     }
    ]
   },
   {
    "id": 19473,
    "type": "message",
    "date": "2023-04-28",
    "date_unixtime": "1682679475",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "499",
    "dicos": "69",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   PTron Newly Launched Musicbot Lite 5W Mini Bluetooth Speaker with 6Hrs Playtime, Immersive Sound, 40mm Driver, BT5.1 with Strong Connectivity, Portable Design, Integrated Music & Call Control (Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3njEJ97"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oTniMZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40IyvNL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44dNan2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40Mpt2f"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40GzC0w"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lfvcbl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44egsSL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40MpzXF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ABbLEN"
     },
     {
      "type": "plain",
      "text": "\n\n @499\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71qpDfsAI6L._SY355_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Home Audio \n                Speakers \nPryce499 \nDicos69 \n\n❌Regular Price  : ₹  1299   /- 😱"
     }
    ]
   },
   {
    "id": 19474,
    "type": "message",
    "date": "2023-04-28",
    "date_unixtime": "1682679580",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1099",
    "dicos": "72",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   boAt Rockerz 330 Bluetooth Wireless in Ear Earphones with mic, Upto 30 Hours Playtime, ASAP Charge, Signature Sound, Dual Pairing & IPX5(Blazing Yellow)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40QPVb4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HlhLWb"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HhxmpF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vb49T7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VbriVr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oUiuaw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hmiia2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VcZShK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nbZUdj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HlqfMT"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1099"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51DwGiJSNXS._SX522_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Headphones, Earbuds & Accessories \n                Headphones \nPryce1099 \nDicos72 \n\n❌Regular Price  : ₹  2999   /- 😱"
     }
    ]
   },
   {
    "id": 19475,
    "type": "message",
    "date": "2023-04-28",
    "date_unixtime": "1682679684",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1889",
    "dicos": "66",
    "catagory": "Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   TP-Link AC750 Wifi Range Extender | Up to 750Mbps | Dual Band WiFi Extender, Repeater, Wifi Signal Booster, Access Point| Easy Set-Up | Extends Wifi to Smart Home & Alexa Devices (RE200)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LEfTuf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ACvK68"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HhD5Mg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3naiC4W"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41NhaVf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/422NlzM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/421iTXn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/427Qq1o"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/423NDXd"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/426A35j"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1889"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/41eyLfRsPIL._SY450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Computers & Accessories \n                Networking Devices \n                 Repeaters & Extenders \nPryce1889 \nDicos66 \n\n❌Regular Price  : ₹  3999    /- 😱"
     }
    ]
   },
   {
    "id": 19476,
    "type": "message",
    "date": "2023-04-28",
    "date_unixtime": "1682679785",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "69",
    "dicos": "83",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   MISTRONICS Spiral Charger Cable Protector Data Cable Saver Charging Cord Protective Cable Cover Set of 1 (4 Pieces)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/448Bzpp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41LEcvM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oL45ND"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41Mw3qP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41OTQGI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44dmMcS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41Yjl8o"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LMCFAd"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oOPn8v"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41JHFuF"
     },
     {
      "type": "plain",
      "text": "\n\n @69\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61eqW9cfk+L._SY450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Home Improvement \n                Electrical \n                Cord Management \nPryce69 \nDicos83 \n\n❌Regular Price  : ₹  399   /- 😱"
     }
    ]
   },
   {
    "id": 19477,
    "type": "message",
    "date": "2023-04-28",
    "date_unixtime": "1682679907",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "799",
    "dicos": "69",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   pTron Bassbuds Duo in Ear Earbuds with 32Hrs Total Playtime, Bluetooth 5.1 Wireless Headphones, Stereo Audio, Touch Control TWS, with Mic, Type-C Fast Charging, IPX4 & Voice Assistance (Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VdjOB4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oPxciW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/447GqqN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44eNIJl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oRnfl5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LDpl0T"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VfSS3F"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oOi5Gy"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oQIhQM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3n70IjM"
     },
     {
      "type": "plain",
      "text": "\n\n @799\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51ZT3aMrJIL._SX522_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Headphones, Earbuds & Accessories \n                Headphones \nPryce799 \nDicos69 \n\n❌Regular Price  : ₹ 1999   /- 😱"
     }
    ]
   },
   {
    "id": 19478,
    "type": "message",
    "date": "2023-04-28",
    "date_unixtime": "1682680031",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "498",
    "dicos": "67",
    "catagory": "Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   MATTSPY Bluetooth Smart Watch for Men Women, Touch Screen for Android iOS Phones Wrist Phone Watch, Daily Activity Tracker, Heart Rate Sensor, BP Monitor, Sports Watch - Black✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40LnxqS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40GFFSR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44mB9vQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oToccl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lj79YK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40RL9tL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40KvGMb"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AyHXZs"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Ve4x30"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3naaaCO"
     },
     {
      "type": "plain",
      "text": "\n\n @498\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61icsCcbdKL._SY355_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Electronics \n                Wearable Technology \n                 Smart Watches \nPryce498 \nDicos67 \n\n❌Regular Price  : ₹  1299    /- 😱"
     }
    ]
   },
   {
    "id": 19479,
    "type": "message",
    "date": "2023-04-28",
    "date_unixtime": "1682680133",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "599",
    "dicos": "78",
    "catagory": "All",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   VIMAL JONNEY Men Regular Fit Trackpants Pack of 3 Multicolor✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HitSTK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/427QWfQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Ve4C6O"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Ln5922"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lfw1AX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HlqHL5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vgy4cD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vd0xQg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40LPoar"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3L7lSG8"
     },
     {
      "type": "plain",
      "text": "\n\n @599\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61MXQ+vHwjL._UX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#All"
     },
     {
      "type": "plain",
      "text": "\n\n                Clothing & Accessories \n                Men \n                Sportswear \nPryce599 \nDicos78 \n\n❌Regular Price  : ₹  1699   /- 😱"
     }
    ]
   },
   {
    "id": 19480,
    "type": "message",
    "date": "2023-04-28",
    "date_unixtime": "1682680238",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1999",
    "dicos": "67",
    "catagory": "Computers",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Noise ColorFit Ultra Buzz Bluetooth Calling Smart Watch with 1.75\" HD Display, 320x385 px Resolution, 100 Sports Modes, Stock Market Info Smartwatch for Men & Women (Champagne Grey)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3najoim"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nhj3dJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LejWMf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3n53cz7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ACwDeY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HnNqWW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/420pOPO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/422SiIT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HhDQVC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oRnyfJ"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1999"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61IyCGgiKRL._SX522_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Computers"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Electronics \n                Wearable Technology \n                 Smart Watches \nPryce1999 \nDicos67 \n\n❌Regular Price  : ₹  3999   /- 😱"
     }
    ]
   },
   {
    "id": 19481,
    "type": "message",
    "date": "2023-04-28",
    "date_unixtime": "1682680342",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "342",
    "dicos": "62",
    "catagory": "Clothing",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Gear Superior 16L Water Resistant School Bag//Backpack/College Bag for Men/Women - Orange Black✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ViqwGi"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LF9AGQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40OnPgu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/428bWDe"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LDq1mX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42apSgh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/448nWqi"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LC6GT7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44cYkIU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NlvH63"
     },
     {
      "type": "plain",
      "text": "\n\n @342\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/91F36KkXG3L._UY606_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Clothing"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Bags, Wallets and Luggage \n                Bags & Backpacks \n                Backpacks \nPryce342 \nDicos62 \n\n❌Regular Price  : ₹  899   /- 😱"
     }
    ]
   },
   {
    "id": 19482,
    "type": "message",
    "date": "2023-04-28",
    "date_unixtime": "1682680463",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "469",
    "dicos": "61",
    "catagory": "Clothing",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀 Cazibe Men's Regular T-Shirt (CZ11105_Red/S.Green M)   ✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44btiAT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40P6pk1"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NhSgbR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40LPE9p"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40GAHW8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VbKbaM"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3n9rRCs"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40MVyqE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/448VLaF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AzBIVi"
     },
     {
      "type": "plain",
      "text": "\n\n @469\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/81n0Qp78i8L._UX342_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Clothing"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Clothing & Accessories \n                Men \n                T-Shirts & Polos \nPryce469 \nDicos61 \n\n❌Regular Price  : ₹   999  /- 😱"
     }
    ]
   },
   {
    "id": 19483,
    "type": "message",
    "date": "2023-04-28",
    "date_unixtime": "1682680565",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "427",
    "dicos": "22",
    "catagory": "Beauty",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   POND'S Triple Vitamin Moisturising Body Lotion 600 ml✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lj84Zc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40O1nnY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/449qZOZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40GB3vW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vb5ycl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/426AZql"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3n546eZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HnDJrk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VbKthS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VgcvbP"
     },
     {
      "type": "plain",
      "text": "\n\n @427\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/41z9LG4aZnL._SY450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Beauty"
     },
     {
      "type": "plain",
      "text": "\n\n                Beauty \n                Bath & Body \n                Body Washes \nPryce427 \nDicos22 \n\n❌Regular Price  : ₹  549  /- 😱"
     }
    ]
   },
   {
    "id": 19484,
    "type": "message",
    "date": "2023-04-28",
    "date_unixtime": "1682680688",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "389",
    "dicos": "61",
    "catagory": "Clothing",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Cazibe Men's Regular Fit T-Shirt✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3n6mrZ9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Nm74GK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LzXmht"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3n93BR5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oUH9vh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://www.amazon.in/dp/B095HLWPCC?th=1&amp;psc=1&amp;linkCode=sl1&amp;tag=avi1deals6-21&amp;linkId=4bfaf53e3b72f006768b19ef297fc251&amp;language=en_IN&amp;ref_=as_li_ss_tl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ABdUQR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nablCe"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HljSt5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/425t5O1"
     },
     {
      "type": "plain",
      "text": "\n\n @389\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/91yajvGB1DS._UX466_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Clothing"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Clothing & Accessories \n                Men \n                T-Shirts & Polos \nPryce389 \nDicos61 \n\n❌Regular Price  : ₹ 899   /- 😱"
     }
    ]
   },
   {
    "id": 19485,
    "type": "message",
    "date": "2023-04-28",
    "date_unixtime": "1682680788",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "190",
    "dicos": "76",
    "catagory": "Clothing",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Amazon Brand - Jam & Honey baby-girls Overalls✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ADoCpY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/425tobD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/428c5a2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/426JOR6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/427S17o"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oOPZL9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41MdnHS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Ho6apk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oPiAzY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41HoL7M"
     },
     {
      "type": "plain",
      "text": "\n\n @190\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/91bick38wpL._UY679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Clothing"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Clothing & Accessories \n                Baby \n                Baby Girls \nPryce190 \nDicos76 \n\n❌Regular Price  : ₹  799   /- 😱"
     }
    ]
   },
   {
    "id": 19486,
    "type": "message",
    "date": "2023-04-28",
    "date_unixtime": "1682680889",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "299",
    "dicos": "16",
    "catagory": "Beauty",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Khadi Essentials Rose Body Lotion for Dry & Dull Skin with Milk & Shea Butter, Nourishes, Hydrates & Moisturizes Skin, for Women & Men, All Skin Types, 200ml✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Ve5Te6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41KgNuH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LC83Bb"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://www.amazon.in/dp/B07VBNNGHD?th=1&amp;linkCode=sl1&amp;tag=avi1deals-21&amp;linkId=ddd56c4ff3017d619ce5eb8b803b0842&amp;language=en_IN&amp;ref_=as_li_ss_tl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vb5VUh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44afXsI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44bGUw7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/423TiN0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40KnADq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41LFWoO"
     },
     {
      "type": "plain",
      "text": "\n\n @299\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61vb6x+DCBL._SY450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Beauty"
     },
     {
      "type": "plain",
      "text": "\n\n                Beauty \n                Bath & Body \n                Body Washes \nPryce299 \nDicos16 \n\n❌Regular Price  : ₹  399   /- 😱"
     }
    ]
   },
   {
    "id": 19487,
    "type": "message",
    "date": "2023-04-28",
    "date_unixtime": "1682680993",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "689",
    "dicos": "66",
    "catagory": "Clothing",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Neostreak Men T-Shirt✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LCflVu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/449idAw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LC6eUG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41DdDc5"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HjS1cs"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44cP0Vu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oScpvf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3n4VHZ3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LcHBN7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oTpHY1"
     },
     {
      "type": "plain",
      "text": "\n\n @689\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71a6Yq6HjwL._UX522_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Clothing"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Clothing & Accessories \n                Men \n                T-Shirts & Polos \nPryce689 \nDicos66 \n\n❌Regular Price  : ₹  1499    /- 😱"
     }
    ]
   },
   {
    "id": 19488,
    "type": "message",
    "date": "2023-04-28",
    "date_unixtime": "1682681097",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "2399",
    "dicos": "66",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   IZI Smart Pro 1.9\" UHD 326 PPI Largest Bezel-less Display BT Calling Smartwatch - IP68, ECG, SpO2, BP 24/7 Heart Rate, 500+ Watch faces, AI Voice Assistant, 22+ Sports Mode | 2 Premium Straps Included Steel + Silicone✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LEhPmv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44dPGK0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VbFk9v"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ADoFSG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AEGWiF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NjFqd8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44dpvmE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44cZrIA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VgfYay"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40LMh28"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@2399"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/81eeUzqrrlL._SX522_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Wearable Technology \n                 Smart Watches \nPryce2399 \nDicos66 \n\n❌Regular Price  : ₹  4999  /- 😱"
     }
    ]
   },
   {
    "id": 19489,
    "type": "message",
    "date": "2023-04-28",
    "date_unixtime": "1682681203",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "872",
    "dicos": "54",
    "catagory": "Clothing",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Amazon Brand - Inkast Denim Co. Men Sweatshirt✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nhk6ub"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40OplPI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HivGw0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VtX4gF"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LgvxKJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NnV7QQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LCEsYj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nhkpoP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Nmtt6D"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nakCKu"
     },
     {
      "type": "plain",
      "text": "\n\n @872\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61zsh7-3TUL._UX466_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Clothing"
     },
     {
      "type": "plain",
      "text": " & Accessories\n\n                Clothing & Accessories \n                Men \n                Winter Wear \nPryce872 \nDicos54 \n\n❌Regular Price  : ₹  1699     /- 😱"
     }
    ]
   },
   {
    "id": 19490,
    "type": "message",
    "date": "2023-04-28",
    "date_unixtime": "1682681309",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "281",
    "dicos": "53",
    "catagory": "Tools",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   SYSKA SSK-PW-0302A-WGY Power Wheel with Surge Protection, Strong ABS Material Body, 4 Meter Long (White-Grey)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Livxdn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ACLFkS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nakA5k"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40NNHtd"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Nh1pl6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AA1dFV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nfQKMT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/423TBaC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/427SxSS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41LG2wG"
     },
     {
      "type": "plain",
      "text": "\n\n @281\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61sh8i5XIYL._SY450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Tools"
     },
     {
      "type": "plain",
      "text": " & Home Improvement\n\n                Electronics \n                Power Accessories \n                 Surge Protectors \nPryce281 \nDicos53 \n\n❌Regular Price  : ₹  599  /- 😱"
     }
    ]
   },
   {
    "id": 19491,
    "type": "message",
    "date": "2023-04-28",
    "date_unixtime": "1682681414",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "897",
    "dicos": "74",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Lifelong Egg Boiler 360-Watt (Transparent and Silver Grey), Boil 7 eggs | Easy to clean| 3 Boiling Modes, Stainless Steel Body and Heating Plate, Automatic Turn-Off✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/423TM5M"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41DdXHP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41Kll4k"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41YlfG4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LC8zPD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41NfzPp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LAsuhY"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/447I63z"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/444GWpH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NlFcT5"
     },
     {
      "type": "plain",
      "text": "\n\n @897\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61fiaM-RRCL._SY450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Home Appliances \n                Small Kitchen Appliances \nPryce897 \nDicos74 \n\n❌Regular Price  : ₹  2499   /- 😱"
     }
    ]
   },
   {
    "id": 19492,
    "type": "message",
    "date": "2023-04-28",
    "date_unixtime": "1682681543",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "899",
    "dicos": "25",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Zebronics ZEB-VITA Wireless Bluetooth 10W Portable Bar Speaker With Supporting USB, SD Card, AUX, FM, TWS & Call Function✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40MUnHG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40O2P9U"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40GCbzG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40QSYA2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NkXDXK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/447IAXr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/423TSKG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HmGBon"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/425O7fj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40MsShx"
     },
     {
      "type": "plain",
      "text": "\n\n @899\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/81Mg9W0JCNL._SY450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Home Audio \n                Speakers \nPryce899 \nDicos25 \n\n❌Regular Price  : ₹  1099   /- 😱"
     }
    ]
   },
   {
    "id": 19493,
    "type": "message",
    "date": "2023-04-28",
    "date_unixtime": "1682681689",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "633",
    "dicos": "65",
    "catagory": "Sports",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Silver's SB 719 Combo 2 Badminton Racquet✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VbLJS8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VtXRhD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VcOPVU"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LzYAcz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LioSj6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LhwTVp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AUqKtT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Az2WLR"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NmYojc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NmYojc"
     },
     {
      "type": "plain",
      "text": "\n\n @633\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/817wi71iEnL._SX466_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Sports"
     },
     {
      "type": "plain",
      "text": ", Fitness & Outdoors\n\n                Sports, Fitness & Outdoors \n                Badminton \n                 Racquets \nPryce633 \nDicos65 \n\n❌Regular Price  : ₹  1499    /- 😱"
     }
    ]
   },
   {
    "id": 19494,
    "type": "message",
    "date": "2023-04-28",
    "date_unixtime": "1682681817",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "2799",
    "dicos": "53",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Amazon Basics Zero Gravity Reclining Lounge Portable Chair (Beige, Fabric & Steel)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hm0e00"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LyGpF7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/426CDZ3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nf5Jqr"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41LmNmZ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41YlNvC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oFDHEL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41Kmg4M"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/420PYC6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/424EAVN"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@2799"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/91u8XPX9+5L._SY355_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Home & Kitchen \n                Furniture \n                Garden & Outdoor Furniture \nPryce2799 \nDicos53 \n\n❌Regular Price  : ₹  4999  /- 😱"
     }
    ]
   },
   {
    "id": 19495,
    "type": "message",
    "date": "2023-04-28",
    "date_unixtime": "1682681941",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "8999",
    "dicos": "82",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Amazon Brand - Solimo Nigella Fabric 2 Seater Sofa (Blue)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LCnMQx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41R9txl"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oNc3G2"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vh2088"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40OqxTc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44mDAP0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NmJDwW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NlGUUh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44d0g48"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vd2I6o"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@8999"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/41j58VhLaPL._SX300_SY300_QL70_FMwebp_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Home & Kitchen \n                Furniture \n                Living Room Furniture \nPryce8999 \nDicos82 \n\n❌Regular Price  : ₹   24999  /- 😱"
     }
    ]
   },
   {
    "id": 19496,
    "type": "message",
    "date": "2023-04-28",
    "date_unixtime": "1682682045",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "10499",
    "dicos": "79",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀  Amazon Brand - Solimo Rachel 3 Seater Sectional Sofa (Fabric ,Multicolor) ✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LC7irE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VghkCa"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LCnYPL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LdPP7x"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oOkS2u"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3njIV8R"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nk9zOS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40Gek32"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3n95rkX"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LDwWMO"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@10499"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/81nPKqf8JnL._SY450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Home & Kitchen \n                Furniture \n                Living Room Furniture \nPryce10499 \nDicos79 \n\n❌Regular Price  : ₹  24999   /- 😱"
     }
    ]
   },
   {
    "id": 19497,
    "type": "message",
    "date": "2023-04-28",
    "date_unixtime": "1682682171",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "3099",
    "dicos": "69",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Amazon Brand - Solimo Ellie Engineered Wall Mount Wood Study Desk (Walnut Finish, Black)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40P8Wux"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AzqFeC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3n4XltH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41MzUEj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VbGIcd"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42av8At"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41NdVgs"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40MVCGQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LCK1pG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40UpJws"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@3099"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71F6mzE5OsL._SY355_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Home & Kitchen \n                Furniture \n                Study & Home Office Furniture \nPryce3099 \nDicos69 \n\n❌Regular Price  : ₹ 7999    /- 😱"
     }
    ]
   },
   {
    "id": 19498,
    "type": "message",
    "date": "2023-04-28",
    "date_unixtime": "1682682274",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1050",
    "dicos": "77",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Mamta Decoration Sheesham Wood Bedside Table with 3 Drawers for Bedroom Living Room End Tables Wooden Nightstand Sofa Side Table with Storage (Light Walnut)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Np51Bw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LDsvlh"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3n56kel"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LzZeqv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LfFM1R"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lijs7W"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NnWxLa"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Ho7SHg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NfHZx0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AywXeK"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1050"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/81i5WT6RCqS._SY679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Home & Kitchen \n                Furniture \n                Bedroom Furniture \nPryce1050 \nDicos77 \n\n❌Regular Price  : ₹ 2999   /- 😱"
     }
    ]
   },
   {
    "id": 19499,
    "type": "message",
    "date": "2023-04-28",
    "date_unixtime": "1682682398",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "789",
    "dicos": "47",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Pigeon Stainless Steel Idly Maker 4 Plates Compatible with Induction and Gas Stove✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LCEGyz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/426DtFb"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/42833JN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41N31Y3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hm9YaG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HlqKqe"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3nfSANN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VecaGI"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/423UNe6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/448XWuR"
     },
     {
      "type": "plain",
      "text": "\n\n @789\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/71QMoDvDwsL._SX355_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Dining \n                Cookware \nPryce789 \nDicos47 \n\n❌Regular Price  : ₹   1299   /- 😱"
     }
    ]
   },
   {
    "id": 19500,
    "type": "message",
    "date": "2023-04-28",
    "date_unixtime": "1682682500",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "489",
    "dicos": "62",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   CG Stainless Steel Casserole - 1500ml, 1 Pc, Silver✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LE8NFK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44mDZku"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LC7XJE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44dR8vW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vgu4Zx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3n3y8zO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NhmXhz"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/448DvhE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44dqHGC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LjaKGe"
     },
     {
      "type": "plain",
      "text": "\n\n @489\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/716TFbPtosL._SY355_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Dining \n                Tableware \nPryce489 \nDicos62 \n\n❌Regular Price  : ₹  1099   /- 😱"
     }
    ]
   },
   {
    "id": 19501,
    "type": "message",
    "date": "2023-04-28",
    "date_unixtime": "1682682604",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "539",
    "dicos": "58",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   AGARO Classic Portable Yogurt Maker, 1.2L Capacity, Electric, Automatic, Grey and White, Medium (33603)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LC9ZcV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VgBb4j"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ACzLHK"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vgt9It"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Nhn3Wt"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40NUzXt"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LMGxkJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40MW9sk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LBPsVJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HhCbzh"
     },
     {
      "type": "plain",
      "text": "\n\n @539\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61B9OtVFdjL._SY450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Kitchen & Home Appliances \n                Small Kitchen Appliances \nPryce539 \nDicos58 \n\n❌Regular Price  : ₹  1149  /- 😱"
     }
    ]
   },
   {
    "id": 19502,
    "type": "message",
    "date": "2023-04-28",
    "date_unixtime": "1682682708",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1649",
    "dicos": "45",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Polycab Zoomer DLX High Speed 1200 mm 1 star rating Ceiling Fan (Creamy White)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Nr3V8B"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hm1oZq"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3n6pm43"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HohH7T"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41Lo3Xf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VtZcVH"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VfWF0T"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LDtj9N"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40KzX2b"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3n3ytCA"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1649"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/51l-9lOa-cL._SY355_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home & Kitchen \n                Heating, Cooling & Air Quality \n                Fans \nPryce1649 \nDicos45 \n\n❌Regular Price  : ₹   2999  /- 😱"
     }
    ]
   },
   {
    "id": 19503,
    "type": "message",
    "date": "2023-04-28",
    "date_unixtime": "1682682831",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "603",
    "dicos": "70",
    "catagory": "Watches",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Amazon Brand - Symbol Analog Men's Watch✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Ln89eO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ABa1eN"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LdcGjD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AzF4rm"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AHXZ37"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41YmZiA"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41KMVOV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HohRfv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vgflxv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HjUrI4"
     },
     {
      "type": "plain",
      "text": "\n\n @603\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/815aJ2kiX+L._UY445_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Watches"
     },
     {
      "type": "plain",
      "text": "\n\n                Fashion \n                Made for Amazon brands \n                All SPB apparel \nPryce603 \nDicos70 \n\n❌Regular Price  : ₹ 1599     /- 😱"
     }
    ]
   },
   {
    "id": 19504,
    "type": "message",
    "date": "2023-04-28",
    "date_unixtime": "1682682934",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1003",
    "dicos": "78",
    "catagory": "Watches",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Daniel Klein Analog Multicolor Dial Women's Watch-DK.1.12562-3✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HhCQ3J"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HkKqui"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oGYp7e"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/427UBu6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40QrISa"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oL8Q9X"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40QrM4m"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/447Kyah"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41YnjOk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/448rMjc"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1003"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61ZNEh+CrmL._UX679_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Watches"
     },
     {
      "type": "plain",
      "text": "\n\n                Fashion \n                Onam 2021 \n                Watches and fashion jewellery \nPryce1003 \nDicos78 \n\n❌Regular Price  : ₹  4299    /- 😱"
     }
    ]
   },
   {
    "id": 19505,
    "type": "message",
    "date": "2023-04-28",
    "date_unixtime": "1682683037",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "629",
    "dicos": "52",
    "catagory": "Home",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Cheston 8 Piece Hand Tool Kit | Non-Slip & Corrosion Resistant Handles | Multi-Utility Household & Professional Hand Tools | Screwdriver, Socket Set, Cutter, Pliers✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44bwbSf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41KjUmn"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oNdmEW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NlGYnd"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3neH1q0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lh527P"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LdcL6V"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Lf5CDk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LikrF9"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VgtECl"
     },
     {
      "type": "plain",
      "text": "\n\n @629\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61nQyRne74L._SY450_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Home"
     },
     {
      "type": "plain",
      "text": " & Kitchen\n\n                Home Improvement \n                Power & Hand Tools \n                Hand Tools \nPryce629 \nDicos52 \n\n❌Regular Price  : ₹ 1099    /- 😱"
     }
    ]
   },
   {
    "id": 19506,
    "type": "message",
    "date": "2023-04-28",
    "date_unixtime": "1682683136",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "378",
    "dicos": "28",
    "catagory": "Beauty",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀  Wild Stone Classic Cologne Long Lasting Deodorant for Men, 225ml and Beyond Deodorant for Men 150ml, Pack of 2 ✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ncjdTS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ADW0gg"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oOmidk"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3VdnWB4"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Hoiohv"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HlsboC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40NQr9Z"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AAbRwj"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40GK5cp"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40NVreH"
     },
     {
      "type": "plain",
      "text": "\n\n @378\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/61jEvf-H6HL._SY450_PIbundle-2,TopRight,0,0_AA450SH20_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Beauty"
     },
     {
      "type": "plain",
      "text": "\n\n                Beauty \n                Bath & Body \n                Deodorants & Antiperspirants \nPryce378 \nDicos28 \n\n❌Regular Price  : ₹  525   /- 😱"
     }
    ]
   },
   {
    "id": 19507,
    "type": "message",
    "date": "2023-04-28",
    "date_unixtime": "1682683237",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "479",
    "dicos": "81",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Bouncefit D20 Y68 Fitness Band Smartwatch – Single Touch Interface, Water Resistant, Workout Modes, Quick Charge Sports Smartwatch for Boys and Girls (Suitable for Kids and Teens only) – Jet Black✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40MuwQf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40Pahl3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40LP63e"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oFFytd"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41JTYah"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/420urcE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/423756k"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/4284gAP"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LdQCFC"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ACoirK"
     },
     {
      "type": "plain",
      "text": "\n\n @479\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/41IfkQrRSQL._SX300_SY300_QL70_FMwebp_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Electronics \n                Wearable Technology \n                 Smart Watches \nPryce479 \nDicos81 \n\n❌Regular Price  : ₹ 1299    /- 😱"
     }
    ]
   },
   {
    "id": 19508,
    "type": "message",
    "date": "2023-04-28",
    "date_unixtime": "1682683338",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "289",
    "dicos": "52",
    "catagory": "Beauty",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   Sunaina Makeup Brush Set With Storage Barrel - Pack of 12 (Pink)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HoiAxf"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HlMWjW"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/41OYy7m"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/428oXwu"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oFG6iL"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44mFGhQ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44eFAZE"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LjuQAi"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3L7qqwo"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AChM4b"
     },
     {
      "type": "plain",
      "text": "\n\n @289\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/512JJSYM7NL._SX466_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Beauty"
     },
     {
      "type": "plain",
      "text": "\n\n                Beauty \n                Tools & Accessories \n                Make-up Brushes & Tools \nPryce289 \nDicos52 \n\n❌Regular Price  : ₹  599   /- 😱"
     }
    ]
   },
   {
    "id": 19509,
    "type": "message",
    "date": "2023-04-28",
    "date_unixtime": "1682683440",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "240",
    "dicos": "76",
    "catagory": "Tools",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   IONIX Tap, Tap Filter, Tap Extender for Kitchen Sink, Sink tap for Kitchen, Water tap, Kitchen Sink tap, Kitchen tap, Kitchen tap Extension, Flexible Anti Splash Water Saving Movable Sink Faucet✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ncpsHb"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HmbIke"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40OsWxc"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Ho5ct3"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NjITIG"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40UrzNS"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Np7ewO"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40Pb2KV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/40HhX8N"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ACBoVS"
     },
     {
      "type": "plain",
      "text": "\n\n @240\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/21YXlyrerKS._SX300_SY300_QL70_FMwebp_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Tools"
     },
     {
      "type": "plain",
      "text": " & Home Improvement\n\n                Home & Kitchen \n                Home & kitchen | Prime shopping days \n                Prime Units \nPryce240 \nDicos76 \n\n❌Regular Price  : ₹  899   /- 😱"
     }
    ]
   },
   {
    "id": 19510,
    "type": "message",
    "date": "2023-04-28",
    "date_unixtime": "1682683550",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "249",
    "dicos": "75",
    "catagory": "Watches",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   SWADESI STUFF Analog Unique Arrow Black Silicon Strap Watch for Men and Boys✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AUtXcV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NfK7Vw"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3NoHrVx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oL9Vyx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Vb9REx"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AUu2gJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/427oPh6"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3Az6nlJ"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AELghT"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AELghT"
     },
     {
      "type": "plain",
      "text": "\n\n @249\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/41Y24uryRcL._UY445_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Watches"
     },
     {
      "type": "plain",
      "text": "\n\n                Fashion \n                Onam 2021 \n                Watches and fashion jewellery \nPryce249 \nDicos75 \n\n❌Regular Price  : ₹  899    /- 😱"
     }
    ]
   },
   {
    "id": 19511,
    "type": "message",
    "date": "2023-04-28",
    "date_unixtime": "1682683676",
    "from": "All1App daily",
    "from_id": "channel1858370886",
    "photo": "(File not included. Change data exporting settings to download.)",
    "pryce": "1499",
    "dicos": "57",
    "catagory": "Electronics",
    "text_entities": [
     {
      "type": "plain",
      "text": "Offer 🚀🚀   boAt Airdopes 191G True Wireless in Ear Earbuds with ENx Tech Equipped Quad Mics, Beast Mode(Low Latency- 65ms) for Gaming, 2x6mm Dual Drivers, 30H Playtime, IPX5, IWP (White Siberia)✅\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/4238EBe"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3LzVnL8"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/44bLYAD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oOHyQ0"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/4490pp7"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/447MDTD"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3ABcjKV"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3HltT9w"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3AELC8d"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://amzn.to/3oVXAra"
     },
     {
      "type": "plain",
      "text": "\n\n "
     },
     {
      "type": "mention",
      "text": "@1499"
     },
     {
      "type": "plain",
      "text": "\n"
     },
     {
      "type": "link",
      "text": "https://m.media-amazon.com/images/I/510RsgHQn9L._SX522_.jpg"
     },
     {
      "type": "plain",
      "text": " \n\n\n"
     },
     {
      "type": "hashtag",
      "text": "#Electronics"
     },
     {
      "type": "plain",
      "text": "\n\n                Computers & Accessories \n                Accessories & Peripherals \n                PC Gaming Peripherals \nPryce1499 \nDicos57 \n\n❌Regular Price  : ₹  2999  /- 😱"
     }
    ]
   }
       
    ]


export default Input;