import React, { useEffect, useState } from 'react';

import './ImageSlider.scss';

function ImageSlider({ product, tag }) {
  //  let picture="https://m.media-amazon.com/images/W/WEBP_402378-T1/images/I/8119UoZWDBL._UX522_.jpg";
  let picture = product.photo;
  console.log('Image slider tag is ' + tag);
  //  picture =require(`../../images/${product.photo}`);
  // if(product.photo != undefined){
  //   if(tag == "avinash"){
  //     picture =require(`../../images/${product.photo}`);
  //   }
  //   else if(tag == "others"){
  //     picture =require(`../../images/pictures/${product.photo}`);
  //   }
  // }
  // if(product.text_entities[i].text.includes("amzn"))
  // prd = product.text_entities[i].text;
  let i, len;
  // for(i=0;i<product.text_entities.length-1;i++)
  try {
    if (tag == 'items') {
      if (product != undefined && product.photo != undefined)
        picture = product.photo;
    }
    // if(product.text_entities.length>0)
    // len = product.text_entities.length-1;
    // if(product.text_entities.length>0)
    // for(i=0;i<len;i++){
    //   if(product.text_entities[i].type=="link")
    //   if(tag!="items"){
    //     if(product.text_entities[i].text.includes("media"))
    //     picture = product.text_entities[i].text;
    //   }
    //   else if(tag=="items"){
    //     if(picture!=undefined)
    //     picture = product.photo
    //   }
    // }
  } catch (e) {
    console.log('Image Slider Error');
  }
  const [images, setImages] = useState([]);
  const [image, setImage] = useState('');
  useEffect(
    function () {
      // setImages(product.images);
      if (tag != 'items') {
        if (product != undefined && product.photo) setImages(picture);
          let imagesarr = ["https://m.media-amazon.com/images/I/71+cZ8KaWJL._SX425_.jpg","https://m.media-amazon.com/images/I/81PI0QPkaUL._SY879_.jpg"]
  setImages(imagesarr);
      } else {
        if (product.photo != undefined)
          if (product.photo[0] != undefined) setImages(product.photo);
      }
      // if (product.images) {
      // if (product.photo) {

      if (tag == 'items') {
        if (product.photo != undefined)
          if (product.photo[0].length != undefined) {
            // console.log(product.photo.type)
            setImage(product.photo[0]);
          } else {
            setImage(product.photo);
          }
        // setImage(product.images[0]);
      } else {
        if (picture != undefined) setImage(picture);
      }
    },
    [product],
  );

  try {
    // let pic = require(`../../images/${product.photo}`);
    let pic = `../../images/${product.photo}`;
  } catch (error) {
    console.log('Error in Image slider is ' + error);
  }

  const imageClick = (src) => {
    setImage(src);
  };

  // let imagesarr = ["https://m.media-amazon.com/images/I/71+cZ8KaWJL._SX425_.jpg","https://m.media-amazon.com/images/I/81PI0QPkaUL._SY879_.jpg"]
  // setImages(imagesarr);
  return (
    <div className="slider">
      <div className="slider__left">
        <ul className="slider__listing">
          {images &&
            images.length > 1 &&
            tag == 'items' &&
            // images.length>0 &&  tag=="items" &&
            // images.length>0 && product.id==142612 && tag=="items" &&
            images.map((image, i) => (
              <li
                className="slider__list"
                key={i}
                onClick={() => {
                  imageClick(image);
                }}
              >
                {/* <img alt={product.title} src={picture} /> */}
                {/* {tag=="items" ?
                {tag=="items" && product.photo!=undefined && product.photo.length>1 && product.id==14261? */}

                <img alt={product.title} src={image} />
                {/* :null} */}
              </li>
            ))}
        </ul>
      </div>
      <div className="slider__right">
        <div className="slider__display">
          {/* <img src={require(`../../images/${product.photo}`)} alt="not working" /> */}
          {/* <img alt={product.title} src={image} /> */}
          {tag != 'items' ? (
            <img alt={product.title} src={picture} />
          ) : (
            <img alt={product.title} src={image} />
          )}
        </div>
      </div>
    </div>
  );
}

export default ImageSlider;
