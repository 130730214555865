import React, { useEffect } from 'react';
import './Information.scss';
import Rating from 'components/general/Rating';
import { calculatePriceDetails } from 'utils.js/product';
import CurrencyFormat from 'components/general/CurrencyFormat';
import AddToCard from 'components/product/AddToCart';
import { Link } from 'react-router-dom';
import { Container,  Card,  CardContent,  CardMedia,  CardHeader, Button, Box,Typography } from '@mui/material';
import { Helmet } from 'react-helmet';

function Information({ product, person, tag, source }) {
  console.log('product in information is ' + product.id);
  debugger;
  console.log('person is ' + person);
  // debugger
  console.log('Source is ' + source);
  let text = '',
    link = '',
    prd=product?.links?.avinashbmv,
    prd1 = '',
    prd2 = '',
    prd3 = '',
    prd4 = '',
    prd5 = '',
    prd6 = '',
    prd7 = '',
    prd8 = '',
    prd9 = '',
    prd10 = '',
    wats,
    watsMsg,
    usmMsg,
    watsError,
    btntext = 'Buy in Amazon';
  let start = 0;
  let b = '',
    len;
  let pricearr;

  let j = 0;
  let price = 0,
    discount = 0,
    rating = 0;
  let a = '',
    k = 0,
    anum = 0;
  // let titlearr,newtitle;
  let i, title, titlearr, newtitle=""
  newtitle=product?.producttext || "" ;
  if(product?.producttext==""){
    newtitle = product?.urltext
  }

  
// Title filter
  // if(newtitle==""){
  //   newtitle = product?.urltext
  // }
  // if (newtitle.includes(",")) {
  //   newtitle = newtitle.substring(0, newtitle.indexOf(","));
  // }
  // if (newtitle.includes("|")) {
  //   newtitle = newtitle.substring(0, newtitle.indexOf("|"));
  // }
  // if (newtitle.includes("(")) {
  //   newtitle = newtitle.substring(0, newtitle.indexOf("("));
  // }




  let tagid="dealshubglo04-21"
  let startTimeStamp=1706886912000;
  try {
    // len = product.text_entities.length - 1;
    if (product != undefined && product.price != undefined) {
      price = product.price;
    }
    if (product != undefined && product.discount != undefined) {
      discount = product.discount;
    }
  } catch (e) {
    console.log('len Error');
  }
  try {
    watsMsg = `https://wa.me/919951797149?text=Interested%20in%20${product.id}`;
    usmMsg = `https://wa.me/919951797149?text=Interested%20in%20USM-Go-Ranch`;
    watsError = `https://wa.me/919951797149?text=Error%20in%20${product.id}`;
    // text = '';
    text = product.urltext;
    link = watsError;
    if (price > 0) {
      try {
        if (text.includes('@')) {
          // debugger
          text = text.substring(0, text.indexOf('@'));
        }
      } catch (e) {
        console.log(e);
      }

      try {
        if (text.includes('Deal Price')) {
          // debugger
          text = text.substring(0, text.indexOf('Deal Price'));
        }
      } catch (e) {
        console.log(e);
      }
    }
  } catch (e) {
    console.log(e);
  }
  try {

    try {
      // if (
      //   product != undefined &&
      //   product.text_entities != undefined &&
      //   product.text_entities.length > 0
      // )
      //   for (i = 0; i < product.text_entities.length; i++) {
      //     if (product.text_entities[i].type == 'plain') {
      //       // text = product.text_entities[i].text;
      //       title = product.text_entities[i].text;
      //       newtitle = title;
      //     } else break;
      //   }
      // if (
      //   text.includes('Loot') ||
      //   text.includes('LOOOT') ||
      //   text.includes('LOOOOT') ||
      //   text.includes('LLOOT') ||
      //   text.includes('LLOOOT') ||
      //   text.includes('LLOOOOT') ||
      //   text.includes('LOOOTTTT')
      // ) {
      //   text = text.replace('Loot', '');
      // }
      // if (text.includes('LOOOTTTT')) {
      //   text = text.replace('LOOOTTTT', '');
      // }
      // if (text == '') {
      //   text = 'details';
      // }
    } catch (e) {
      console.log(e);
    }
    // try{
    // if(text.indexOf("@")!= -1){
    try {
      if (text.includes('@')) {
        debugger;
        text = text.substring(0, text.indexOf('@'));
      }
    } catch (e) {
      console.log(e);
    }
    try {
      if (text.includes('✴️')) {
        text = text.substring(text.indexOf('✴️'), text.length);
      }
    } catch (e) {
      console.log(e);
    }
    try {
      if (text.includes('Deal Price')) {
        debugger;
        text = text.substring(0, text.indexOf('Deal Price'));
      }
    } catch (e) {
      console.log(e);
    }





    try {
      try {
        if (newtitle.includes('@')) {
          newtitle = newtitle.substring(0, newtitle.indexOf('@'));
        }
      } catch (e) {
        console.log(e);
      }
      if (newtitle.includes('✴️')) {
        newtitle = newtitle.substring(newtitle.indexOf('✴️'), newtitle.length);
      }
      try {
        if (newtitle.includes('✅Deal Price :\n  ')) {
          newtitle = newtitle.substring(
            0,
            newtitle.indexOf('✅Deal Price :\n  '),
          );
        }
      } catch (e) {
        console.log(e);
      }
      try {
        if (newtitle.includes('✅Deal Price')) {
          newtitle = newtitle.substring(0, newtitle.indexOf('✅Deal Price'));
        }
      } catch (e) {
        console.log(e);
      }
      try {
        if (newtitle.includes('✅ Deal Price')) {
          newtitle = newtitle.substring(0, newtitle.indexOf('✅ Deal Price'));
        }
      } catch (e) {
        console.log(e);
      }
      try {
        if (newtitle.includes('✅ Deal Price :\n  ')) {
          newtitle = newtitle.substring(
            0,
            newtitle.indexOf('✅ Deal Price :\n  '),
          );
        }
      } catch (e) {
        console.log(e);
      }
      try {
        if (newtitle.includes('✅Deal Price : Rs .')) {
          newtitle = newtitle.substring(
            0,
            newtitle.indexOf('✅Deal Price : Rs .'),
          );
        }
      } catch (e) {
        console.log(e);
      }
      try {
        if (newtitle.includes('✅ Deal Price : Rs .')) {
          newtitle = newtitle.substring(
            0,
            newtitle.indexOf('✅ Deal Price : Rs .'),
          );
        }
      } catch (e) {
        console.log(e);
      }
      try {
        if (newtitle.includes('Rs')) {
          newtitle = newtitle.substring(
            0,
            newtitle.indexOf('Rs'),
          );
        }
      } catch (e) {
        console.log(e);
      }
      try {
        if (newtitle.includes('RS')) {
          newtitle = newtitle.substring(
            0,
            newtitle.indexOf('RS'),
          );
        }
      } catch (e) {
        console.log(e);
      }
      try {
        if (newtitle.includes(' at')) {
          newtitle = newtitle.substring(
            0,
            newtitle.indexOf(' at'),
          );
        }
      } catch (e) {
        console.log(e);
      }
      try {
        if (newtitle.includes(' At')) {
          newtitle = newtitle.substring(
            0,
            newtitle.indexOf(' At'),
          );
        }
      } catch (e) {
        console.log(e);
      }
      try {
        if (newtitle.includes(' AT')) {
          newtitle = newtitle.substring(
            0,
            newtitle.indexOf(' AT'),
          );
        }
      } catch (e) {
        console.log(e);
      }
      try {
        if (newtitle.includes('http')) {
          newtitle = newtitle.substring(
            0,
            newtitle.indexOf('http'),
          );
        }
      } catch (e) {
        console.log(e);
      }
      if (newtitle.includes('TTT')) {
        console.log(newtitle);
        newtitle.replaceAll('TTT', ' ');
        console.log(newtitle);
      }
    } catch (e) {
      console.log(e);
    }
  
    if(newtitle.length < 10){
      if(product?.category?.c5 != "" ){
        newtitle = product.category.c5
      }
      else if(product?.category?.c4 != "" ){
        newtitle = product.category.c4
      }
      else if(product?.category?.c3 != "" ){
        newtitle = product.category.c3
      }
      else if(product?.category?.c2 != "" ){
        newtitle = product.category.c2
      }
      else if(product?.category?.c1 != "" ){
        newtitle = product.category.c1
      }
    }

    



    

    try {
      titlearr = title.split(' ');
      for (i = 0; i < titlearr.length; i++) {
        console.log('title arr ' + i + ' ' + titlearr[i]);
        if (price <= 0) {
          if (titlearr[i].indexOf('@') != -1) {
            price = Number(titlearr[i].substring(titlearr[i].indexOf('@')));
          }
        }
      }
      if (price <= 0) {
        console.log('price in arr is ' + titlearr[titlearr.length - 1]);
        debugger;
        price = Number(titlearr[titlearr.length - 1].substr(1));
      }
    } catch (e) {
      console.log('Price error');
    }
    if (tag == 'items') {
      link = watsMsg;
      btntext = 'Interested';
    }
    if (product.id == '14261' && tag == 'items') {
      link = usmMsg;
    }
    // <div className="information__header">{product.text_entities[0].type!="link" ? product.text_entities[0].text : "hai"}</div>
  } catch (error) {
    console.log('Error in Info' + error);
  }

  try {
  } catch (e) {
    console.log(e);
  }
  

  if (discount > 80) {
    rating = 5;
  } else if (discount > 40) {
    rating = 4;
  } else if (discount > 20) {
    rating = 3;
  } else {
    rating = 2;
  }




  useEffect(() => {
    // Set the document title when the component mounts
    document.title = newtitle;

    // Optionally, you can return a cleanup function to reset the title
    return () => {
      document.title = newtitle;
    };
  }, []); 





  if (tag == 'avinash') {
    // path=`/product/${product.id}`
    link = prd1;
  } else if (tag == 'others') {
    // path=`/deals/product/${product.id}`
    link = prd2;
    if (prd2 == '') {
      link = prd1;
    }
  } else if (tag == 'deals') {
    // path=`/deals/product/${product.id}`
    link = prd3;
    if (prd3 == '') {
      link = prd1;
    }
  }
  // else if(tag=="offers"){
  //   // path=`/offers/product/${product.id}`
  //   link=prd3
  //   if(prd3==""){
  //     link=prd1
  //   }
  // }
  else if (tag == 'avideals') {
    // path=`/deals/product/${product.id}`
    link = prd4;
    if (prd4 == '') {
      link = prd1;
    }
  } else if (tag == 'avideals5') {
    // path=`/deals/product/${product.id}`
    link = prd5;
    if (prd5 == '') {
      link = prd1;
    }
  } else if (tag == 'avideals6') {
    // path=`/deals/product/${product.id}`
    link = prd6;
    if (prd6 == '') {
      link = prd1;
    }
  } else if (tag == 'avideals7') {
    // path=`/deals/product/${product.id}`
    link = prd7;
    if (prd7 == '') {
      link = prd1;
    }
  } else if (tag == 'avideals8') {
    // path=`/deals/product/${product.id}`
    link = prd8;
    if (prd8 == '') {
      link = prd1;
    }
  } else if (tag == 'avideals9') {
    // path=`/deals/product/${product.id}`
    link = prd9;
    if (prd9 == '') {
      link = prd1;
    }
  } else if (tag == 'avideals10') {
    // path=`/deals/product/${product.id}`
    link = prd10;
    if (prd10 == '') {
      link = prd1;
    }
  } else if (tag == 'items') {
    // path=`/items/product/${product.id}`
  }

  if(product.datetime>startTimeStamp){
    console.log("Short Link")
    prd = product?.links?.avinashbmv;
  }
  else{
    console.log("Custom Link")
    prd=`https://amazon.in/dp/${product?.productCode}?tag=${tagid}`
  }


  return (
    <>

    <Helmet>
      <meta property="og:title" content={newtitle} />
      <meta property="og:description" content="Description " />
      <meta property="og:image" content={product.photo} />
      <meta property="og:url" content={prd} />
      <meta property="og:type" content="website" />
    </Helmet>
    {product && (
      <Box className="information">
        <Typography variant="h4" className="information__header">
          {newtitle}
          {/* {text} */}
        </Typography>
        {/* <br /> */}

        {source === 'main' && price > 0 && (
          <Box className="information__pricing">
            <Typography className='information__price' variant="h4">
              <span>Deal Price : </span>
              
              {/* <span className='information__label'>Deal Price : </span> */}
              <CurrencyFormat
                className="product-card__amount"
                currencyCode="INR"
                value={product.price}
              />
            </Typography>
          </Box>
        )}
        {/* <br /> */}

        {source === 'main' && rating > 0 && tag !== 'items' && (
          <Box className="product-card__rating">
             {/* <Typography variant="h4"> */}
               {/* <span>Rating : </span> */}
             {/* </Typography> */}
             {/* <Rating rating={rating} maxRating={5} size={2} /> */}
            <Typography className='information__price' variant="h4">Discount : </Typography>
            {/* <Typography className='information__price' variant="h4">Discount : {product.discount}%</Typography> */}
            {/* <br /> */}
            {/* <span className='information__label'>Discount : {product.discount}%</span> */}
            {/* <br /> */}
            <Typography className='information__price' variant="h4">{product.discount}%</Typography>
            {/* <span className='product-card__price'>{product.discount}%</span> */}
            {/* <span className='product-card__amount'>{product.discount}%</span> */}
          </Box>
        )}

        <br/>
        <br/>




{prd != '' ? (
            <Link to={{ pathname: prd }} target="_blank">
              {product && product.storeType && product.storeType!="" ? (
                <Button 
                variant="contained"
            color="primary"
                // className="add-to-cart-button" 
                style={{
                //   // color: 'crimson',
                  fontSize: 15,
                  lineHeight: 1,
                  alignContent: 'center',
                  fillOpacity: 'blue',
                }}
                >
                  View in {product.storeType}
                </Button>
                
              ) : product &&
                product.links &&
                product.links.avinashbmv &&
                product.links.avinashbmv.includes('amzn') ? (
                  <Button
            variant="contained"
            color="primary"
            style={{
              // color: 'crimson',
              fontSize: 15,
              lineHeight: 1,
              alignContent: 'center',
              fillOpacity: 'blue',
            }}
          >
            Buy
          </Button>
                // <button className="add-to-cart-button" style={{
                //   color: 'crimson',
                //   fontSize: 20,
                //   lineHeight: 1,
                //   alignContent: 'center',
                //   fillOpacity: 'blue',
                // }}>View in Amazon</button>
              ) : (
                <Button
            variant="contained"
            color="primary"
            // style={{
            //   color: 'crimson',
            //   fontSize: 20,
            //   lineHeight: 1,
            //   alignContent: 'center',
            //   fillOpacity: 'blue',
            // }}
          >
            Buy
          </Button>
                // <button className="add-to-cart-button" style={{
                //   color: 'crimson',
                //   fontSize: 20,
                //   lineHeight: 1,
                //   alignContent: 'center',
                //   fillOpacity: 'blue',
                // }}>View</button>
              )}

              {/* <button
                className="add-to-cart-button"
                style={{
                  color: 'crimson',
                  fontSize: 20,
                  lineHeight: 1,
                  alignContent: 'center',
                  fillOpacity: 'blue',
                }}
              >
                {btntext}
              </button> */}
              {/* <button className='add-to-cart-button' style={{ color: 'crimson', fontSize:20, lineHeight : 1 ,marginLeft: 100, fillOpacity:'blue'}}>{btntext}</button> */}
            </Link>
          ) : null}






{/*           

        {link !== '' && (
          <Link to={{ pathname: link }} target="_blank">
            <Button variant="contained" color="primary" className="add-to-cart-button">
              {product && product.storeType && product.storeType!=""
                ? `Buy in ${product.storeType}`
                : product &&
                  product.links &&
                  product.links.avinashbmv &&
                  product.links.avinashbmv.includes('amzn')
                ? 'Buy in Amazon'
                : 'Buy'}
            </Button>

            <Button
              variant="contained"
              color="secondary"
              style={{
                color: 'crimson',
                fontSize: 20,
                lineHeight: 1,
                alignContent: 'center',
                fillOpacity: 'blue',
              }}
            >
              {btntext}
            </Button>
          </Link>
        )} */}
        <br/>
        <br/>
        <Link to="/home" className="logo">
          <Button
            variant="contained"
            color="primary"
            
            style={{
            //   color: 'crimson',
              fontSize: 15,
              lineHeight: 1,
              alignContent: 'right',
              fillOpacity: 'blue',
            }}
          >
            View all Products
          </Button>
        </Link>
        <br />
        <br />

        <Link to={{ pathname: link }} target="_blank">
          <Typography variant="body1">Facing Issue ?</Typography>
        </Link>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
      </Box>
    )}
  </>
  );
}

export default Information;
