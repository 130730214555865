import React from 'react';

import './index.scss';

import Banner from './Banner';
import Products from './Products';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import { useState } from 'react';
// import HomePage from './';
import HomePage from '.';
import { Button } from '@mui/material';
import './Home.scss'

function Home(data) {
  const [allprods,setAllProds]=useState(false);
  const [allitems,setAllItems]=useState(false);
  var all1app="https://telegram.me/dealshubglobal"
  var avideals=`https://telegram.me/${data.tag}`
  var all1appdeals="https://telegram.me/dealshubglobal2"
  var watsall1app="https://chat.whatsapp.com/FfH1v13f7dVA5ZHVvzEW3E"
  var watsall1appdeals="https://chat.whatsapp.com/DSyvXzBJuax5uJ6MFylXJk"
  var path='/'

  useEffect(()=>{
    if(allprods){
    }
    return(
      <Home source="main" tag="avinash" />
    );
  },[allprods]);

  useEffect(()=>{
    if(allitems){
    }
    return(
      <Home source="main" tag="items" />
    );
  },[allitems]);

  const allprod = () =>{
    console.log('All Prod');
    setAllProds(true);
  } 

  const allitem = () =>{
    console.log('All Prod');
    setAllItems(true);
  } 
  
  console.log("Home Page tag is "+data.tag)
  return (
    // <div className="home">
    <div className='home'>
      <div>
        <br />
        <br />

          {allitems?
          <div>
        <label style={{ color: 'crimson', fontSize:15, lineHeight : 1,lineWidth :8, padding: 10}}>
      &nbsp;
        <tr>
            <td>All Items : </td>
        </tr>
  </label>
          <button onClick={allitem}>All Deals</button>
          </div>
          :null}

          {allprods?
          <div>
          <HomePage json={data.json} source="main" tag={data.tag!=undefined?data.tag:"avinash"} category={data.category} searchValue={data.searchValue}
            />
            </div>:null
          }
        {/* </Link> */}
        <br />
        <br />
      <label style={{ color: 'crimson', fontSize:15, lineHeight : 1,lineWidth :8, padding: 10}}>
    {/* &nbsp; */}
        <tr>
            <td>Telegram Deals : </td>
        </tr>
  </label>
  {/* {data.tag=="avinash"? */}
  {data.tag!="items"?
  <>

        <Link to={{ pathname: all1app }} target="_blank" >
        <Button 
                variant="contained"
                color="primary"
                // className="add-to-cart-button" 
                style={{
                //   // color: 'crimson',
                  fontSize: 12,
                  lineHeight: 1,
                  alignContent: 'center',
                  fillOpacity: 'blue',
                }}
                >
                  Live Deals
                </Button>
        </Link>

      <label style={{ color: 'crimson', fontSize:15, lineHeight : 1,lineWidth :4, padding: 10}}>
        {/* &nbsp; */}
       
  </label>
        <Link to={{ pathname: all1appdeals }} target="_blank" >
          <Button 
                variant="contained"
                color="primary"
                // className="add-to-cart-button" 
                style={{
                //   // color: 'crimson',
                  fontSize: 12,
                  lineHeight: 1,
                  alignContent: 'center',
                  fillOpacity: 'blue',
                }}
                >
                  Hot Deals
                </Button>
        </Link>
  </>
        :
        
        <Link to={{ pathname: avideals }} target="_blank" >
          <button >All Deals</button>
        </Link>
        
  }




        <br />
        <br />
      <label style={{ color: 'crimson', fontSize:15, lineHeight : 1,lineWidth :8, padding: 10}}>
    {/* &nbsp; */}
        <tr>
            <td>Whatsapp Deals : </td>
        </tr>
  </label>
        <Link to={{ pathname: watsall1app }} target="_blank" >
        <Button
                variant="contained"
                color="primary"
                // className="add-to-cart-button" 
                style={{
                //   // color: 'crimson',
                  fontSize: 12,
                  lineHeight: 1,
                  alignContent: 'center',
                  fillOpacity: 'blue',
                }}
                >
                  Live Deals
                </Button>
        </Link>

      <label style={{ color: 'crimson', fontSize:15, lineHeight : 1,lineWidth :8, padding: 10}}>
    &nbsp;
       
  </label>
        <Link to={{ pathname: watsall1appdeals }} target="_blank" >
        <Button 
                variant="contained"
                color="primary"
                // className="add-to-cart-button" 
                style={{
                //   // color: 'crimson',
                  fontSize: 12,
                  lineHeight: 1,
                  alignContent: 'center',
                  fillOpacity: 'blue',
                }}
                >
                  Hot Deals
                </Button>
        </Link>
          
      </div>

     
      
      {!allprods?
       <div>
      <label style={{ color: 'crimson', fontSize:15, lineHeight : 1,lineWidth :8, padding: 10}}>
      &nbsp; 
        <HomePage json={data.json} source="main" tag={data.tag!=undefined?data.tag:"avinash"} category={data.category} searchValue={data.searchValue}/>
        {/* <HomePage source="main" tag="avinash" category={data.category} searchValue={data.searchValue}/> */}
        {/* <Link to={{ pathname: path }} target="_blank" > */}
        {/* <button onClick={
          <HomePage source="main" tag="avinash" />
        }>All Deals</button> */}
      
        
      
      
      {/* <tr>
          <td>All Deals : </td>
      </tr> */}
      </label>
        {/* <button onClick={allprod}>All Deals</button> */}
        </div>
        :null}
    </div>
  );
}

export default Home;
