import * as React from 'react';
import { useState } from 'react';
import { styled, alpha } from '@mui/material/styles';
import { Link } from 'react-router-dom'; // Import Link
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import InputBase from '@mui/material/InputBase';
import Badge from '@mui/material/Badge';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import MoreIcon from '@mui/icons-material/MoreVert';
import { Button } from '@mui/material';

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
}));

// export default function TitleSearchBar(data) {


const SubmitButton = styled(Button)({
  backgroundColor: '#1976D2', // Primary color, you can replace it with your desired color
  color: '#fff', // Text color
  '&:hover': {
    backgroundColor: '#135692', // Darker shade for hover effect
  },
});

export default function TitleSearchBar({ onSearch }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const [categoryvalue, setCategoryValue] = useState(0);
  const [category, setCategory] = useState("");
  const [search, setSearch] = useState("");
  const [searchCategory, setSearchCategory] = useState("");

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
    // onSearch(event.target.value); // Notify parent component about search changes
  };

  const handleSearchSubmit = (event) => {
    // Trigger search and call the onSearch callback
    onSearch(search);
    // Add your logic to hit the API or perform any relevant action here
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
// Prevent the default behavior of the Enter key (e.g., form submission)
    event.preventDefault();
    // Trigger search and call the onSearch callback
      handleSearchSubmit(event);
    }
  };

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
<MenuItem component={Link} to="/best-deals" onClick={handleMenuClose}>
        Best Deals
      </MenuItem>
<MenuItem component={Link} to="/trending-deals" onClick={handleMenuClose}>
        Trending Deals
      </MenuItem>
<MenuItem component={Link} to="/live-deals" onClick={handleMenuClose}>
        Live Deals
      </MenuItem>
      <MenuItem component={Link} to="/hot-deals" onClick={handleMenuClose}>
        Hot Deals
      </MenuItem>
      {/* <MenuItem onClick={handleMenuClose}>Live Deals</MenuItem>
      <MenuItem onClick={handleMenuClose}>Hot Deals</MenuItem> */}

      
      {/* <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
      <MenuItem onClick={handleMenuClose}>My account</MenuItem> */}
    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem component={Link} to="/best-deals" onClick={handleMobileMenuClose}>
        Best Deals
      </MenuItem>
      <MenuItem component={Link} to="/trending-deals" onClick={handleMobileMenuClose}>
        Trending Deals
      </MenuItem>
      <MenuItem component={Link} to="/live-deals" onClick={handleMobileMenuClose}>
        Live Deals
      </MenuItem>
      <MenuItem component={Link} to="/hot-deals" onClick={handleMobileMenuClose}>
        Hot Deals
      </MenuItem>
      <MenuItem component={Link} to="/contact-us" onClick={handleProfileMenuOpen}>
        Contact Us
      </MenuItem>
    </Menu>
  );

  return (
    <div className="header">
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="open drawer"
            // sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ display: { xs: 'none', sm: 'block' } }}
          >
            DealsHubGlobal
          </Typography>
          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search…"
              inputProps={{ 'aria-label': 'search' }}
              value={search}
              onChange={(event)=>handleSearchChange(event)}
              // onChange={handleSearchChange}
              onKeyDown={handleKeyDown}
            />
          </Search>
          <SubmitButton variant="contained" onClick={handleSearchSubmit}>

          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ display: { xs: 'none', sm: 'block' } }}
          >
           Submit
          </Typography>
          </SubmitButton>
          {/* <Box sx={{ flexGrow: 1 }} /> */}
          {/* <Box sx={{ display: { xs: 'none', md: 'flex', } }}> */}
          {/* <Box sx={{ display: 'flex',alignItems:'center',gap:2 }}> */}
            {/* <p>Live Deals</p> */}
            {/* <IconButton size="large" aria-label="show 4 new mails" color="inherit">
              <Badge badgeContent={4} color="error">
                <MailIcon />
              </Badge>
            </IconButton> */}
            {/* <IconButton
              size="large"
              aria-label="show 17 new notifications"
              color="inherit"
            >
              <Badge badgeContent={17} color="error">
                <NotificationsIcon />
              </Badge>
            </IconButton> */}
            {/* <IconButton
              size="large"
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              <AccountCircle />
            </IconButton> */}
          {/* </Box> */}
          {/* <Box sx={{ display: { xs: 'flex',sm: 'flex', md: 'flex' } }}> */}
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: { xs: 'none', sm: 'flex', md: 'flex' }, alignItems: 'center', gap: 2 }}>
            <MenuItem component={Link} to="/best-deals" onClick={handleMenuClose}>
            <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ display: { xs: 'none', sm: 'block' } }}
          >
         
              Best Deals
          </Typography>
              </MenuItem>
            <MenuItem component={Link} to="/trending-deals" onClick={handleMenuClose}>
            <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ display: { xs: 'none', sm: 'block' } }}
          >
         
              Trending Deals
          </Typography>
            </MenuItem>
            <MenuItem component={Link} to="/live-deals" onClick={handleMenuClose}>
            <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ display: { xs: 'none', sm: 'block' } }}
          >
         
              Live Deals
          </Typography>
            </MenuItem>
            <MenuItem component={Link} to="/hot-deals" onClick={handleMenuClose}>
            <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ display: { xs: 'none', sm: 'block' } }}
          >
              Hot Deals
          </Typography>
            </MenuItem>
          </Box>
            <IconButton
              size="large"
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
              sx={{ display: { xs: 'flex',sm:'none', md: 'none' } }}
            >
              <MoreIcon />
            </IconButton>
          {/* </Box> */}
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
    </Box>
    </div>
  );
}
