import { BrowserRouter as Router, Switch, Route, useParams } from 'react-router-dom';
import React, { useEffect, useState, useCallback } from 'react';
import Header from './components/header';
import CartPage from './pages/cart';
import FlagPage from './pages/flag';
import DealsPage from './pages/flag/deals';
import ProductPage from './pages/product';
import LoginPage from './pages/login';
import HomePage from './pages/home';
// import { useMediaQuery } from 'react-responsive'
// import MediaQuery from 'react-responsive'
import './App.scss';
import { Mobile } from 'components/mobile/mobile.component';
import { Desktop } from 'components/desktop/desktop.component';
import { Laptop } from 'components/laptop/laptop.component';
// import { BigScreen } from 'components/big-screen/big-screen.component';
import { TabletMobile } from 'components/tablet/tablet-mobile.component';
import Products from 'pages/home/Products';
import ItemProducts from 'pages/home/ItemProducts';
import Logo from 'components/header/Logo';
import Search from 'components/header/Search';
import Home from 'pages/home/Home';
import DealPicker from 'pages/home/dealPicker';
import TitleBar from 'components/product/CategorySearch';
import BottomAppBar from 'pages/home/bottombar';
import CategoryProducts from 'pages/home/CategoryProducts';
import TitleSearchBar from 'pages/home/TitleSearchBar';
import ResponsiveTitleBar from 'pages/home/CustomizeTitleBar';
import ResponsiveHeader from 'pages/home/ResHeader';

function App() {
  //searchData-Category     searchValue - Search
  const [tag, setTag] = useState("");
  const [source, setSource] = useState("main");
  const [searchData, setSearchData] = useState("");
  const [searchValue, setSearchValue] = useState("");
  
  const [price, setprice] = useState(0);
  console.log('App Search Data is '+searchData);

  // let { asinId } = useParams();
  // console.log('Asin Id in App ' +asinId)


  const categoryHandler = (e) =>{
    console.log('Search Value in App is '+e);
    setSearchData(e);
    // setCategory(e);
    // console.log('Search Value in header is '+category);
  }

  const searchHandler = (e) =>{
    console.log('Search Data in App is '+e);
    setSearchValue(e);
    // setCategory(e);
    // console.log('Search Value in header is '+category);
  }

  const mainHandler = (e) =>{
    if(source=="main")
    setSource("others");
    if(source=="others")
    setSource("main");
    
  }

  const searchDataHandler = (e) =>{
    console.log('App Value is '+e);
    // setCategory(e);
    // console.log('Search Value in header is '+category);
  }
  // const isMobileDevice = useMediaQuery({
  //   query: "(min-device-width: 480px)",
  // });

  // const isTabletDevice = useMediaQuery({
  //   query: "(min-device-width: 768px)",
  // });

  // const isLaptop = useMediaQuery({
  //   query: "(min-device-width: 1024px)",
  // });

  // const isDesktop = useMediaQuery({
  //   query: "(min-device-width: 1200px)",
  // });

  // const isBigScreen = useMediaQuery({
  //   query: "(min-device-width: 1201px )",
  // });
  // const isMobileDevice=true
  // const isTabletDevice=false
  // const isDesktop=false
  // const isLaptop=false
  // console.log("Home Page tag is "+data.tag)

  const priceHandler = (e) =>{
    setprice(e);
  }

  return (
    <Router>
      <meta name="viewport" content="width=device-width, initial-scale=1.0"></meta>
      {/* <h1>React Responsive - a guide</h1>       */}
{/* {isMobileDevice && <Mobile />}
  {isTabletDevice && <>
  <TabletMobile />
  {isDesktop && <Desktop />}
  {isLaptop && <Laptop />} */}
  {/* {isBigScreen && <BigScreen />} */}
{/* </>} */}

      {/* <CustomizeTitleBar/> */}
      {/* <Header searchDataHandler={searchDataHandler} searchValue={(e)=> categoryHandler(e)}  searchData={(e)=> searchHandler(e)}/> */}
      <TitleSearchBar onSearch={(e)=> searchHandler(e)} searchDataHandler={searchDataHandler} searchValue={(e)=> categoryHandler(e)}/>
      {/* <TitleSearchBar onSearch={handleSearch}/> */}
      {/* Chatgpt Not working <ResponsiveHeader /> */}
      
      {/* <ResponsiveTitleBar />  */}
      {/* <BottomAppBar /> */}
      <br/>
      {/* <TitleBar /> */}
      {/* searchValueHandler={searchValueHandler} */}
      {/* <div>
          <h4>Price Range</h4>
          <input type="text" value={price} onChange={priceHandler} />
        </div> */}
      {/* <div>
        <Logo></Logo>
      </div>
      <div>
        
      <Search searchValue={(e) => categoryHandler(e)}></Search>
    </div> */}

      <div className="page-container">
      {/* <div > */}
        <Switch>

        <Route exact path="/best-deals">
            {/* <CategoryProducts tag="avideals" source={source} category={searchData} mainHandler={mainHandler} searchValue={searchValue} /> */}
            <Home tag="bestselling" source={source} category={searchData} mainHandler={mainHandler} searchValue={searchValue} />
            {/* <Home tag="avinash" source={source} category={searchData} mainHandler={mainHandler} searchValue={searchValue} /> */}
          </Route>

        <Route exact path="/best-deals/p/:productId">
            <ProductPage tag="bestselling"  source={source}/>
        </Route>

        <Route exact path="/hot-deals/p/:productId">
            <ProductPage tag="hotdeals"  source={source}/>
        </Route>
          
          <Route exact path="/product/:productId">
            <ProductPage tag="avinash"  source={source}/>
          </Route>
          <Route exact path="/cart">
            <CartPage  source={source}/>
          </Route>

          <Route exact path="/flag">
            <FlagPage  source={source}/>
          </Route>

          <Route exact path="/dealpicker">
            <DealPicker  source={source}/>
          </Route>

          <Route exact path="/live-deals">
            <Home tag="avinash" source={source} category={searchData} searchValue={searchValue} />
            {/* <HomePage tag="others" category={searchData} searchValue={searchValue} source={source}/> */}
            {/* <Products tag="others" category={searchData} source={source}/> */}
            {/* <DealsPage /> */}
          </Route>

          <Route exact path="/hot-deals">
            {/* <CategoryProducts tag="avideals" source={source} category={searchData} mainHandler={mainHandler} searchValue={searchValue} /> */}
            <Home tag="loots" source={source} category={searchData} mainHandler={mainHandler} searchValue={searchValue} />
            {/* <Home tag="avinash" source={source} category={searchData} mainHandler={mainHandler} searchValue={searchValue} /> */}
          </Route>

          

          <Route exact path="/trending-deals">
            {/* <CategoryProducts tag="avideals" source={source} category={searchData} mainHandler={mainHandler} searchValue={searchValue} /> */}
            <Home tag="category" source={source} category={searchData} mainHandler={mainHandler} searchValue={searchValue} />
            {/* <Home tag="avinash" source={source} category={searchData} mainHandler={mainHandler} searchValue={searchValue} /> */}
          </Route>

          <Route exact path="/deals">
         
            <Home tag="others" source={source} category={searchData} searchValue={searchValue} />
            {/* <HomePage tag="others" category={searchData} searchValue={searchValue} source={source}/> */}
            {/* <Products tag="others" category={searchData} source={source}/> */}
            {/* <DealsPage /> */}
          </Route> 

          <Route exact path="/offers">
            <Home tag="deals" source={source} category={searchData} searchValue={searchValue} />
          </Route>
          
          <Route exact path="/avideals">
            <Home tag="avideals" source={source} category={searchData} searchValue={searchValue} />
          </Route>
          
          <Route exact path="/avideals5">
            <Home tag="avideals5" source={source} category={searchData} searchValue={searchValue} />
          </Route>
          
          <Route exact path="/avideals6">
            <Home tag="avideals6" source={source} category={searchData} searchValue={searchValue} />
          </Route>
          
          <Route exact path="/avideals7">
            <Home tag="avideals7" source={source} category={searchData} searchValue={searchValue} />
          </Route>
          
          <Route exact path="/avideals8">
            <Home tag="avideals8" source={source} category={searchData} searchValue={searchValue} />
          </Route>
          
          <Route exact path="/avideals9">
            <Home tag="avideals9" source={source} category={searchData} searchValue={searchValue} />
          </Route>
          
          <Route exact path="/avideals10">
            <Home tag="avideals10" source={source} category={searchData} searchValue={searchValue} />
          </Route>
            
          <Route exact path="/login">
            <LoginPage source={source}/>
          </Route>

          {/* <Route exact path="/offers"> */}

          <Route exact path="/home/Watches">
            {/* <CategoryProducts tag="avideals" source={source} category={searchData} mainHandler={mainHandler} searchValue={searchValue} /> */}
            <Home tag="category" json="Watches" source={source} category={searchData} mainHandler={mainHandler} searchValue={searchValue} />
            {/* <Home tag="avinash" source={source} category={searchData} mainHandler={mainHandler} searchValue={searchValue} /> */}
          </Route>

          <Route exact path="/home/Shoes">
            {/* <CategoryProducts tag="avideals" source={source} category={searchData} mainHandler={mainHandler} searchValue={searchValue} /> */}
            <Home tag="category" json="Shoes" source={source} category={searchData} mainHandler={mainHandler} searchValue={searchValue} />
            {/* <Home tag="avinash" source={source} category={searchData} mainHandler={mainHandler} searchValue={searchValue} /> */}
          </Route>

          <Route exact path="/home/Wireless-Accessories">
            {/* <CategoryProducts tag="avideals" source={source} category={searchData} mainHandler={mainHandler} searchValue={searchValue} /> */}
            <Home tag="category" json="Wireless & accessories" source={source} category={searchData} mainHandler={mainHandler} searchValue={searchValue} />
            {/* <Home tag="avinash" source={source} category={searchData} mainHandler={mainHandler} searchValue={searchValue} /> */}
          </Route>

          {/* <Route exact path="/home"> */}
          <Route exact path="/home">
            {/* <CategoryProducts tag="avideals" source={source} category={searchData} mainHandler={mainHandler} searchValue={searchValue} /> */}
            <Home tag="category" source={source} category={searchData} mainHandler={mainHandler} searchValue={searchValue} />
            {/* <Home tag="avinash" source={source} category={searchData} mainHandler={mainHandler} searchValue={searchValue} /> */}
          </Route>
          {/* <Route exact path="/home/:asinId">
            <Home tag="avinash" source={source} category={searchData} searchValue={searchValue} />
          </Route> */}

          <Route exact path="/items">
            {/* <Home tag="items" category={searchData} searchValue={searchValue} source={source}/> */}
            <HomePage tag="items" category={searchData} searchValue={searchValue} source={source}/>
            {/* <Products tag="items" category={searchData} source={source}/> */}
            {/* <ItemProducts tag="items" category={searchData}/> */}
          </Route>

          <Route exact path="/items/:asinId">
            {/* <Home tag="items" category={searchData} searchValue={searchValue} source={source}/> */}
            <HomePage tag="items" category={searchData} searchValue={searchValue} source={source}/>
            {/* <Products tag="items" category={searchData} source={source}/> */}
            {/* <ItemProducts tag="items" category={searchData}/> */}
          </Route>

          <Route exact path="/product/:productId">
            <ProductPage tag="avinash"  source={source}/>
          </Route>

          <Route exact path="/p/:productId">
            <ProductPage tag="avinash"  source={source}/>
          </Route>

          <Route exact path="/items/product/:productId">
            <ProductPage tag="items"  source={source}/>
          </Route>

          <Route exact path="/:asinId">
            <Home tag="avinash" category={searchData} searchValue={searchValue} source={source}/>
            {/* <HomePage tag="avinash" category={searchData} searchValue={searchValue} source={source}/> */}
            {/* <Products tag="avinash" category={searchData} searchValue="" source={source}/> */}
            {/* <Products tag="avinash" category={searchData} searchValue={searchValue} source={source}/> */}
            {/* <HomePage tag="avinash" category={searchData} /> */}
          </Route>

          <Route exact path="/deals/:asinId">
            <Home tag="others" category={searchData} searchValue={searchValue} source={source}/>
          </Route>

          <Route exact path="/avideals/:asinId">
            <Home tag="avideals" category={searchData} searchValue={searchValue} source={source}/>
          </Route>


          <Route exact path="/deals/product/:productId">
            <ProductPage tag="others"  source={source}/>
          </Route>

          <Route exact path="/offers/product/:productId">
            <ProductPage tag="deals"  source={source}/>
          </Route>

          <Route exact path="/avideals/product/:productId">
            <ProductPage tag="avideals"  source={source}/>
          </Route>

          <Route exact path="/avideals5/product/:productId">
            <ProductPage tag="avideals5"  source={source}/>
          </Route>

          <Route exact path="/avideals6/product/:productId">
            <ProductPage tag="avideals6"  source={source}/>
          </Route>

          <Route exact path="/avideals7/product/:productId">
            <ProductPage tag="avideals7"  source={source}/>
          </Route>

          <Route exact path="/avideals8/product/:productId">
            <ProductPage tag="avideals8"  source={source}/>
          </Route>

          <Route exact path="/avideals9/product/:productId">
            <ProductPage tag="avideals9"  source={source}/>
          </Route>

          <Route exact path="/avideals10/product/:productId">
            <ProductPage tag="avideals10"  source={source}/>
          </Route>


          {/* <Route exact path="/"> */}
          <Route>
            <Home tag="avinash" category={searchData} searchValue={searchValue} mainHandler={mainHandler} source={source}/>
            {/* <HomePage tag="avinash" category={searchData} searchValue={searchValue} mainHandler={mainHandler} source={source}/> */}
          </Route>

          <BottomAppBar />
        </Switch>
      </div>
    </Router>
  );
}

export default App;
