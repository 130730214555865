import React from 'react';

import './index.scss';

import Banner from './Banner';
import Products from './Products';
import { useState } from 'react';
import CategoryProducts from './CategoryProducts';
import Productsloots from './Productsloots';
import ProductsBest from './ProductsBest';

function HomePage(data) {
  const [price, setprice] = useState(0);
  const [discount, setdiscount] = useState(0);
  console.log("Home Page tag is "+data.tag)

  const priceHandler = (event) =>{
    setprice(event.target.value);
  }
  const submitHandler = (event) =>{
    // setprice(event.target.value);
    return(
      <div>
        {data.tag=="category" ? (
        <CategoryProducts json={data.json} tag = {data.tag} />):
        (data.tag=="loots")?
        (<Products tag = {data.tag} category={data.category} source={data.source} searchValue={data.searchValue} discount={discount} price={price}/>):
        (<Productsloots tag = {data.tag} category={data.category} source={data.source} searchValue={data.searchValue} discount={discount} price={price}/>)
        // null
        }
      </div>
    )
  }
  const discountHandler = (event) =>{
    setdiscount(event.target.value);
  }
  return (
    <div className="home">
      <div>
        <div>

      {/* <Banner /> */}
        </div>

      {/* Discount and Price */}
        {/* <div>
          <h4>Price Range</h4>
          <input type="text" value={price} onChange={event => priceHandler(event)} />
          {/* <input type="text" value={price} onChange={priceHandler} /> 
        </div>
        <div>
          <h4>Discount Range</h4>
          <input type="text" value={discount} onChange={event => discountHandler(event)}/>
        </div>
        <button onClick={submitHandler}>Submit</button> */}


      <br/>
      </div>
      {/* <h3>Watches</h3> */}
      <div className="home__section">

      {/* <div> */}

      {data.tag === "category" ? (
  <CategoryProducts json={data.json} tag={data.tag} />
) : data.tag === "loots" ? (
  <Productsloots
    tag={data.tag}
    category={data.category}
    source={data.source}
    searchValue={data.searchValue}
    discount={discount}
    price={price}
  />
) : data.tag === "bestselling" ? (
  <ProductsBest
    tag={data.tag}
    category={data.category}
    source={data.source}
    searchValue={data.searchValue}
    discount={discount}
    price={price}
  />
) :  (
  <Products
    tag={data.tag}
    category={data.category}
    source={data.source}
    searchValue={data.searchValue}
    discount={discount}
    price={price}
  />
)}


      {/* {data.tag=="category" ? (
        <CategoryProducts json={data.json} tag = {data.tag} />):
        (data.tag=="loots")?
        (<Productsloots tag = {data.tag} category={data.category} source={data.source} searchValue={data.searchValue} discount={discount} price={price}/>):
        (data.tag=="bestselling")?
        (<ProductsBest tag = {data.tag} category={data.category} source={data.source} searchValue={data.searchValue} discount={discount} price={price}/>):
        :null
        } */}
{/*         
        {data.tag=="category" ? (
        <CategoryProducts json={data.json}  tag = {data.tag} category={data.category} source={data.source} searchValue={data.searchValue} discount={discount} price={price}/>):
        (<Products tag = {data.tag} category={data.category} source={data.source} searchValue={data.searchValue} discount={discount} price={price}/>)} */}


      {/* </div> */}
        {/* <Products  /> */}
        {/* <Products tag = {data.tag} category={data.category} searchValue={data.searchValue} source={data.source} discount={discount} price={price}/> */}
      </div>
    </div>
  );
}

export default HomePage;
