import React, { useCallback, useEffect, useState } from 'react';

import './Products.scss';

import productsApi from 'api/products';
import Loader from 'components/general/Loader';
import Error from 'components/general/Error';
import Information from '../../components/product/Information';
import ProductCard from './ProductCard';
// import CardProduct from './CardProduct';
import Input from './Input';
import Deals from './Deals';
// import Container from './@mui/material/core/Container';
import { useLocation, useParams } from 'react-router-dom';

// import Container from '@mui/material/core/Container';
// import Card from '@mui/material/core/Card';
// import CardContent from '@mui/material/core/CardContent';
// import CardMedia from '@mui/material/core/CardMedia';
// import CardHeader from '@mui/material/core/CardHeader';
// import Grid from '@mui/material/Grid';
import { Container, Card,  CardContent,  CardMedia,  CardHeader,  Grid, Button, Menu, MenuItem, Typography, Link, Radio, FormControlLabel, RadioGroup } from '@mui/material';
import useStyles from './productcd';
import Items from './Items';
// import { orange } from '@mui/material';
import { format } from 'date-fns';
import CardProd from './CardProd';
import MediaCard from './CardProduct';
import RecipeReviewCard from './avatar';

// require("core-js/actual/array/group-by-to-map");
// require("core-js/actual/array/group-by");

// import FlagPage from '../flag/index';
let categoryvalue = '';
let arr=''

// Function to group products based on category 'c1'




function getUniqueProductsByImageLink(apiData) {
  // Use reduce to build the uniqueProducts object
  const uniqueProducts = Object.values(apiData).reduce((result, product) => {
    const imageLink = product?.ImageLink;

    if (imageLink && !result[imageLink]) {
      debugger
      result[imageLink] = product;
    }

    return result;
  }, {});
  console.log("uniqueProducts is ",uniqueProducts)
  const resultArray = Object.values(uniqueProducts);
  console.log("resultArray is ",resultArray)
  return uniqueProducts;
  // return resultArray;
}



function groupProductsByCategory(products) {
  return Object.values(products).reduce((result, product) => {
    const categoryKey = product.urltext.trim(); // Assuming you want to remove leading/trailing whitespaces

    if (!result[categoryKey]) {
      result[categoryKey] = [];
    }

    result[categoryKey].push(product);
    return result;
  }, {});
}

// function groupProductsByCategory(products) {
//   debugger
//   return products.reduce((result, product) => {
//     const categoryKey = product.category.c1;

//     if (!result[categoryKey]) {
//       result[categoryKey] = [];
//     }

//     result[categoryKey].push(product);
//     return result;
//   }, {});
// }
function CategoryProducts(data) {
  let { asinId } = useParams();
  console.log('Tag value is ' + data.tag);
  console.log('Category value is ' + data.category);
  let tag = data.tag;
  // let source = data.source;
  console.log('Asin Value is ' + asinId);
  console.log('Source Value is ' + data.source);
  // if(asinId != ""){

  const classes = useStyles();

  // }
  // function Products(setCounted) {
  const [products, setProducts] = useState([]);
  const [categorizedProducts, setCategorizedProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [askDiscount, setAskDiscount] = useState(data.discount);
  const [askPrice, setAskPrice] = useState(data.price);
  const [source, setSource] = useState(data.source);
  const [error, setError] = useState(null);
  const [filtertogg, setfiltertogg] = useState(true);
  const [dealsFiltertogg, setdealsFiltertogg] = useState(false);
  const [asinFlag, setAsinFlag] = useState(false);
  // const [asinFlag, setAsinFlag] = useState(data.asin!=undefined?asin:false);

  const [price, setprice] = useState(0);
  const [discount, setdiscount] = useState(0);

  // const [cat, setCat] = useState("");
  // const [category, setCategory] = useState(asinId != undefined ? asinId.length==10? asinId :"" : "");
  const [category, setCategory] = useState(
    asinId != undefined ? asinId : data.category,
  );
  //  data.tag=="avinash"?data.category:"");
  const [searchValue, setSearchValue] = useState(
    data.searchValue != undefined ? data.searchValue : '',
  );
  // const [searchValue, setSearchValue] = useState(data.searchValue!=undefined?data.searchValue:"");
  // data.tag=="avinash"?data.searchValue:"");
  // const [category, setCategory] = useState(data.category);
  const [isAmazonSelected, setIsAmazonSelected] = useState(true);
  const [isFlipkartSelected, setIsFlipkartSelected] = useState(true);
  const [isAllSelected, setIsAllSelected] = useState(true);
  const [jsonFileName, setJsonFileName] = useState((data?.json!=undefined && data?.json!="")? data?.json : "Watches");
  // const [selectedValue, setSelectedValue] = useState('');




  const [anchorElC1, setAnchorElC1] = useState(null);
  const [anchorElC2, setAnchorElC2] = useState(null);
  const [hoveredC1, setHoveredC1] = useState(null);
  const [hoveredC2, setHoveredC2] = useState(null);

  const uniqueProducts = [];
  const uniqueImageLinks = new Set();


  const { pathname } = useLocation();
  let cat = '';
  let newtitle = "",links="";
    // date;
  let len = 0;
  const currentDate = new Date(); // Get the current date
  let uniqueProductsOutput;

  // Format the date in "yyyy-MM-dd" format
  let date = format(currentDate, 'yyyy-MM-dd');

  currentDate.setDate(currentDate.getDate() - 1);

// Format the previous day's date in "yyyy-MM-dd" format
  const previousDate = format(currentDate, 'yyyy-MM-dd');
  // date = new Date();
  console.log("Today's Date is " + date);
  // date=Input[0].date.split('T');
  console.log('Date is ', date);
  try {
    // len=products.text_entities.length-1;
    // if(Input[0].date.split('T')!=undefined){

    console.log('Search Value is ' + searchValue);
    console.log('Search Value default is ' + data.searchValue);
    // }
  } catch (e) {
    console.log('Date Error');
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname, searchValue, category]);

  useEffect(() => {
    // preventDefault();
    if (data != undefined) {
      if (tag != 'items') {
        if (asinId != undefined && asinFlag == false) {
          setCategory(asinId);
          // asinCount+=1
          // if(asinCount==2)
        } else {
          setCategory(data.category);
        }
        if (data.searchValue != undefined) setSearchValue(data.searchValue);
        setdealsFiltertogg(false);
      }
      if (tag != 'items' && category == 'All Categories') {
        setCategory('');
      }
      if (
        tag != 'items' &&
        data.category != 'All Categories' &&
        data.category != ''
      ) {
        setAsinFlag(true);
        setCategory(data.category);
      }
    }
  }, [data]);
  useEffect(() => {
    if (asinId != undefined) {
      // setCategory(data.category);
      setCategory(asinId);
    } else {
      // setCategory(data.category);
    }
  }, [asinId]);

  // useEffect(() => {
  //   debugger
  //   loadProducts();
  //   // if (asinId != undefined) {
  //   //   // setCategory(data.category);
  //   //   setCategory(asinId);
  //   // } else {
  //   //   // setCategory(data.category);
  //   // }
  // }, [jsonFileName]);


  // useEffect(() => {
  //   if (product && product.tipe && product.tipe!=undefined){
  //     product.tipe=product.tipe
  //   } else {
  //     if(products.links.avinashbmv.includes("amzn")){
  //       product.tipe="Amazon";
  //     } else{
  //       products.tipe="Flipkart"
  //     }
  //   }
  // }, [searchValue, data.searchValue]);

  useEffect(() => {
    if (isAmazonSelected && isFlipkartSelected) {
      setIsAllSelected(true);
    } else {
      setIsAllSelected(false);
    }
  }, [isAmazonSelected, isFlipkartSelected]);

  // let { check } = useParams();
  // console.log('Params is '+check);
  const searchHandler = (event) => {
    setCategory(event.target.value);
    // setCategory(category.toLowerCase());
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    if (name == 'amazon') {
      setIsAmazonSelected(checked);
    } else if (name == 'flipkart') {
      setIsFlipkartSelected(checked);
    } else if (name == 'all') {
      setIsAllSelected(checked);
    }
  };

  // if(asinId!=undefined){
  //   setCategory(asinId);
  // }

  let i;


  // const groupByCategoryReduce = Object.values(products).reduce(
  //   (group, product) => {
  //     // const groupByCategoryReduce = products.reduce((products[key].category, product) => {

  //     // let { cat } = product.category.c1;
  //     let { cat } = product;
  //     // setCat(product);
  //     try {
  //       //     if(product.text_entities!=undefined)
  //       // for(i=0;i<product?.text_entities?.length;i++){
  //       //   if(product.text_entities[i].type=="hashtag"){
  //       //     cat=product.text_entities[i].text.substr(1);
  //       //     // setCat(product.text_entities[i].text.substr(1));
  //       //   }

  //       // }
  //       if (cat == '') {
  //         cat = 'all';
  //         // setCat("all");
  //       }
  //     } catch (e) {
  //       console.log('Undefined Len Err');
  //     }

  //     // const { category } = product;
  //     // console.log('product in reduce is '+{product});
  //     // console.log('group in reduce is '+{group});

  //     // group[category] = group[category] ?? [];
  //     // group[category].push(product);
  //     group[cat] = group[cat] ?? [];
  //     group[cat].push(product);
  //     return group;
  //   },
  //   {},
  // );
  // // debugger
  // console.log('groupByCategoryReduce is ' + products);
  // console.log('groupByCategoryReduce is ' + groupByCategoryReduce);
  // // console.log('Json groupByCategoryReduce is '+ JSON.parse(groupByCategoryReduce));
  // console.log(
  //   'Json groupByCategoryReduce is ' +
  //     JSON.stringify(groupByCategoryReduce.Beauty),
  // );
  // const arr = { ...groupByCategoryReduce };
  // // setCategorizedProducts(JSON.stringify(groupByCategoryReduce));
  // // setProducts(groupByCategoryReduce);
  // console.log('arr is ' + arr);

  // const groupByCategory = products.groupBy(product => {
  //   for(i=0;i<product.text_entities.length;i++){
  //     if(product.text_entities[i].type=="hashtag"){
  //       product.category=product.text_entities[i].text;
  //     }
  //   }
  //   if(product.category=""){
  //     product.category="others"
  //   }
  //   return product.category;
  // });
  // console.log(groupByCategory);

  // let date=Input[0].date.split('T');
  // console.log(date[0]);
  // const json = await productsApi.getList();
  const loadProducts = useCallback(async () => {
    if (
      loading ||
      Object.keys(categorizedProducts).length > 0 ||
      Object.keys(products).length > 1
    )
      return;
    // if (loading || products.length > 0) return;

    setLoading(true);
    setError(null);

    let json;
    try {
       json = await productsApi.getList(date,dealsFiltertogg,"category",jsonFileName);
      //  json = await productsApi.getList();
      console.log(json);
      
      if (Object.keys(json).length > 0) {
        // setdealsFiltertogg(true);
        // JSON object is not empty
        // You can proceed with further actions here
      } else {
        // setdealsFiltertogg(true);
         json = await productsApi.getList(previousDate,dealsFiltertogg,"category");
        // JSON object is empty
        // Handle the case when there are no items in the JSON object
      }
      
      if (tag == 'items') {
        json = Items;
      } else {
        // json = Input;
        // json = Deals;
      }
      setProducts(json);
      // setProducts(...products,{})

      uniqueProductsOutput = getUniqueProductsByImageLink(json);
          setCategorizedProducts(uniqueProductsOutput);
      // setCategorizedProducts(json);
      // const groupedProducts = groupProductsByCategory(json);
      // setCategorizedProducts(groupedProducts["Fashion"]);
      // const groupedProducts = groupProductsByCategory(products);
      debugger
      // console.log(groupedProducts);
      console.log('Json value in Products is ' + json);
      // console.log(products.id);

    } catch (_error) {
      setError(_error);
    }
    setLoading(false);
  }, [loading, categorizedProducts, products,jsonFileName]);


  
  useEffect(async() => {
    // preventDefault();
    if (data != undefined) {
      if (tag != 'items') {

        try {
          let json;
          // if(data?.searchValue != ""){
            console.log("Inside Radio Change")
            
            json = await productsApi.getList(date,dealsFiltertogg,"category",jsonFileName);
            console.log(json);
          if(jsonFileName=="Wireless & accessories"){
            uniqueProductsOutput = getUniqueProductsByImageLink(json);
          // for (let i = 0; i < Object.keys(json).length; i++) {
          //   const product = json[i];
          //   const imageLink = product.ImageLink;
          
          //   if (imageLink && !uniqueImageLinks.has(imageLink)) {
          //     uniqueImageLinks.add(imageLink);
          //     uniqueProducts.push(product);
          //   }
          // }
          // console.log(uniqueProducts);
          // setCategorizedProducts(uniqueProducts);
          setCategorizedProducts(uniqueProductsOutput);
        }else{
          //  json = await productsApi.getList();
          console.log(json);
          uniqueProductsOutput = getUniqueProductsByImageLink(json);
          setCategorizedProducts(uniqueProductsOutput);
          // setCategorizedProducts(json);
        }
        // }
    }
    catch(e){
      console.log(e);
    }


        // if () {
        //   setCategory(asinId);
        //   // asinCount+=1
        //   // if(asinCount==2)
        // } else {
        //   setCategory(data.category);
        // }
  }
  }
  }, [jsonFileName]);


  useEffect(() => {
    loadProducts();
  }, [loadProducts,jsonFileName]);

  if (categoryvalue == '') {
    categoryvalue = 'Realestate';
  }

  if (loading) {
    return <Loader size={50} />;
  } else if (error) {
    return <Error message="Failed to load products" actionFn={loadProducts} />;
  } else {
    let i;
    try {
      if (products.text_entities != undefined)
        for (i = 0; i < products.text_entities.length; i++) {
          if (products.text_entities[i].type == 'hashtag') {
            products.category = products.text_entities[i].text.substr(1);
          }
        }
      if ((products.category = '')) {
        products.category = 'others';
      }
    } catch (e) {
      console.log('len error');
    }

    const priceHandler = (event) => {
      setprice(event.target.value);
    };
    const submitHandler = () => {
      setAskDiscount(discount);
      setAskPrice(price);
      // setprice(event.target.value);
      // return(
      // <Products tag = {data.tag} category={data.category} source={data.source} discount={discount} price={price}/>
      // )
    };
    const discountHandler = (event) => {
      setdiscount(event.target.value);
    };
    const toggleHandler = (event) => {
      if (filtertogg) setfiltertogg(false);
      else setfiltertogg(true);
    };
    const dealsToggleHandler = () => {
      setdealsFiltertogg(!dealsFiltertogg);
      // if (!dealsFiltertogg) setdealsFiltertogg(true);
      // else setdealsFiltertogg(false);
    };



    
  const handleC1Click = (event) => {
    setAnchorElC1(event.currentTarget);
  };

  const handleC2Click = (event) => {
    setAnchorElC2(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorElC1(null);
    setAnchorElC2(null);
  };

  const getC1Values = () => {
    // return [...new Set(products.map(product => product.category.c1))];
    setProducts(...new Set(products.map(product => product.category.c1)));
  };

  const getC2Values = (c1) => {
    setProducts(...new Set(products.filter(product => product.category.c1 === c1).map(product => product.category.c2)));
    // return [...new Set(products.filter(product => product.category.c1 === c1).map(product => product.category.c2))];
  };

  const getC3Values = (c1, c2) => {
    // return products
    setProducts(products
      .filter(product => product?.category?.c1 === c1 && product?.category?.c2 === c2)
      .map(product => product?.category?.c3));
  };

  const handleRadio = (event) => {
    setJsonFileName(event.target.value);

    // You can make API calls here based on the selected radio button value
    // Example API call:
    // makeApiCall(event.target.value);
  };



    return (
      <div id="slider" className="overflow-x-scroll scroll">
         <RadioGroup row value={jsonFileName} onChange={handleRadio}>
      <FormControlLabel
        value="Watches" 
        control={<Radio sx={{ '& .MuiSvgIcon-root': { fontSize: 28 }, color: 'primary' }} />}
        label={<span style={{ fontSize: 18 }}>Watches</span>}
      />
      <FormControlLabel
        value="Shoes"
        control={<Radio sx={{ '& .MuiSvgIcon-root': { fontSize: 28 }, color: 'primary' }} />}
        label={<span style={{ fontSize: 18 }}>Shoes</span>}
      />
      <FormControlLabel
        value="Wireless & accessories"
        control={<Radio sx={{ '& .MuiSvgIcon-root': { fontSize: 28 }, color: 'primary' }} />}
        label={<span style={{ fontSize: 18 }}>Wireless & Accessories</span>}
      />
      <FormControlLabel
        value="OnePlus" 
        control={<Radio sx={{ '& .MuiSvgIcon-root': { fontSize: 28 }, color: 'primary' }} />}
        label={<span style={{ fontSize: 18 }}>OnePlus</span>}
      />
      <FormControlLabel
        value="Plant Stands"
        control={<Radio sx={{ '& .MuiSvgIcon-root': { fontSize: 28 }, color: 'primary' }} />}
        label={<span style={{ fontSize: 18 }}>Plant Stands</span>}
      />
      <FormControlLabel
        value="Plastic Flower Pots"
        control={<Radio sx={{ '& .MuiSvgIcon-root': { fontSize: 28 }, color: 'primary' }} />}
        label={<span style={{ fontSize: 18 }}>Plastic Flower Pots</span>}
      />
      <FormControlLabel
        value="Private Brands" 
        control={<Radio sx={{ '& .MuiSvgIcon-root': { fontSize: 28 }, color: 'primary' }} />}
        label={<span style={{ fontSize: 18 }}>Private Brands</span>}
      />
      <FormControlLabel
        value="Smartwatches"
        control={<Radio sx={{ '& .MuiSvgIcon-root': { fontSize: 28 }, color: 'primary' }} />}
        label={<span style={{ fontSize: 18 }}>Smartwatches</span>}
      />
      <FormControlLabel
        value="Solar Panel"
        control={<Radio sx={{ '& .MuiSvgIcon-root': { fontSize: 28 }, color: 'primary' }} />}
        label={<span style={{ fontSize: 18 }}>Solar Panel</span>}
      />
      <FormControlLabel
        value="Sports"
        control={<Radio sx={{ '& .MuiSvgIcon-root': { fontSize: 28 }, color: 'primary' }} />}
        label={<span style={{ fontSize: 18 }}>Sports</span>}
      />
      <FormControlLabel
        value="furniture"
        control={<Radio sx={{ '& .MuiSvgIcon-root': { fontSize: 28 }, color: 'primary' }} />}
        label={<span style={{ fontSize: 18 }}>furniture</span>}
      />
    </RadioGroup>
    <br/>
    <br/>
    <br/>
    <br/>
    <br/>
        <Container 
        maxWidth="lg" >
            <div>
            <Grid
              className="products"
              container
              // spacing={0}
              spacing={2}
            >
              {Object.values(categorizedProducts).map(
                (product) =>
                  // Number(product.dicos)>50?
                  //  { tag=="avinash"?
                  (tag != 'items' &&
                    // Number(product.discount) >= askDiscount &&
                    // Number(product.price) >= askPrice &&
                    product?.links?.avinashbmv?.length>0 && product?.links?.avinashbmv?.length>0 &&
                    // (product.date == date || dealsFiltertogg) &&
                    // (isAllSelected ||
                    //   (isAmazonSelected &&
                    //     (product.storeType == 'Amazon' || product.storeType == '')) ||
                    //   (isFlipkartSelected && product.storeType != 'Amazon')) &&
                    // (isFlipkartSelected && product.tipe == 'Flipkart')) &&
                    // ((product.catagory.toLowerCase().includes(category.toLowerCase()) || (category=="All Categories")) ||
                    (
                      // (product &&
                      // product.category &&
                      // product.category.c1
                      //   .toLowerCase()
                      //   .includes(category.toLowerCase()) &&
                      // product.urltext
                      //   .toLowerCase()
                      //   .includes(searchValue.toLowerCase())) 
                        // || // &&
                      // (product && product.category && product.category.toLowerCase().includes(category.toLowerCase()) && product.urltext.toLowerCase().includes(searchValue.toLowerCase())) || // &&
                      // (
                      (category == 'All Categories' &&
                        product?.urltext
                          .toLowerCase()
                          .includes(searchValue?.toLowerCase())) || // &&
                      (product?.category?.c1.includes(category?.toLowerCase()) &&
                        product?.urltext
                          .toLowerCase()
                          .includes(searchValue?.toLowerCase())))) ||

                  // tag == 'items' ? (
                  tag != 'items' ? (

                    <div key={product?.id} style={{ marginBottom: '20px' }}>
                    <h2  style={{ fontSize: 18 }}>{product?.PromotionText}</h2>
                    {/* <Link to={product?.links?.avinashbmv}  target="_blank"> */}
                    <a href={product?.links?.avinashbmv} target="_blank" rel="noopener noreferrer">

                      <img
                        src={product?.ImageLink}
                        alt={product?.Title}
                        style={{ width: '100%', maxWidth: '100%', height: 'auto' }}
                        />
                        </a>
                    {/* </Link> */}
                  </div>

                  
                    // <Grid item xs={6} sm={4} md={3} lg={6} xl={2}>
                      
                    //   <MediaCard

                    //     key={product.id}
                    //     product={product}
                    //     tag={tag}
                    //     source={source}
                    //   />
                    // </Grid>
                  ) : 
                  null,
              
              )}
            </Grid>
          </div>
        
        </Container>
        <br/>
        <br/>
        {/* {!dealsFiltertogg ? (
          <Button 
                variant="contained"
                onClick={dealsToggleHandler}
                color="primary"
                // className="add-to-cart-button" 
                style={{
                //   // color: 'crimson',
                  fontSize: 12,
                  lineHeight: 1,
                  alignContent: 'center',
                  fillOpacity: 'blue',
                }}
                >
                  View all deals
                </Button>
        ) : null} */}
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <div>
          <h1></h1>
        </div>
      </div>
    );
  }
}

export default CategoryProducts;
