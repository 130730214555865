import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import {CardHeader,CardMedia, Link} from '@mui/material';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import ProductCard from './ProductCard';
import './CardProduct.scss';
import useStyles from './productcd';
import {useHistory} from 'react-router-dom';
import CurrencyFormat from 'components/general/CurrencyFormat';

export default function MediaCard({ className, product, tag, source }) {
  
  let newtitle = product?.producttext || ""; 
  let tagid="dealshubglo03-21"
  let startTimeStamp=1706886912000;


  
  if(newtitle==""){
    newtitle = product?.urltext
  }
  if (newtitle.includes(",")) {
    newtitle = newtitle.substring(0, newtitle.indexOf(","));
  }
  if (newtitle.includes("|")) {
    newtitle = newtitle.substring(0, newtitle.indexOf("|"));
  }
  if (newtitle.includes("(")) {
    newtitle = newtitle.substring(0, newtitle.indexOf("("));
  }
  // let newtitle = product?.urltext;

  let i,
    picture,
    path,
    discount = 0,
    price = 0,
    rating = 0,
    title = '';


    let prd = '',
    prd1 = '',
    prd2 = '',
    prd3 = '',
    prd4 = '',
    prd5 = '',
    prd6 = '',
    prd7 = '',
    prd8 = '',
    prd9 = '',
    prd10 = '';
  // const classes = useStyles();
  let imag = product.photo;
  // let imag = '';
  if (tag == 'items') {
    imag = product.photo;
  }
  console.log('source is ' + source);
  let wats = `https://wa.me/919951797149?text=Interested%20in%20${product.id}`;
  let usmMsg = `https://wa.me/919951797149?text=Interested%20in%20USM-Go-Ranch`;
  if (product.id == '14261') {
    wats = usmMsg;
  }
  
  const classes = useStyles();

  const history = useHistory();

  try {
    try {
      if (newtitle.includes('@')) {
        newtitle = newtitle.substring(0, newtitle.indexOf('@'));
      }
    } catch (e) {
      console.log(e);
    }
    if (newtitle.includes('✴️')) {
      newtitle = newtitle.substring(newtitle.indexOf('✴️'), newtitle.length);
    }
    try {
      if (newtitle.includes('✅Deal Price :\n  ')) {
        newtitle = newtitle.substring(
          0,
          newtitle.indexOf('✅Deal Price :\n  '),
        );
      }
    } catch (e) {
      console.log(e);
    }
    try {
      if (newtitle.includes('✅Deal Price')) {
        newtitle = newtitle.substring(0, newtitle.indexOf('✅Deal Price'));
      }
    } catch (e) {
      console.log(e);
    }
    try {
      if (newtitle.includes('✅ Deal Price')) {
        newtitle = newtitle.substring(0, newtitle.indexOf('✅ Deal Price'));
      }
    } catch (e) {
      console.log(e);
    }
    try {
      if (newtitle.includes('✅ Deal Price :\n  ')) {
        newtitle = newtitle.substring(
          0,
          newtitle.indexOf('✅ Deal Price :\n  '),
        );
      }
    } catch (e) {
      console.log(e);
    }
    try {
      if (newtitle.includes('✅Deal Price : Rs .')) {
        newtitle = newtitle.substring(
          0,
          newtitle.indexOf('✅Deal Price : Rs .'),
        );
      }
    } catch (e) {
      console.log(e);
    }
    try {
      if (newtitle.includes('✅ Deal Price : Rs .')) {
        newtitle = newtitle.substring(
          0,
          newtitle.indexOf('✅ Deal Price : Rs .'),
        );
      }
    } catch (e) {
      console.log(e);
    }
    try {
      if (newtitle.includes('Rs')) {
        newtitle = newtitle.substring(
          0,
          newtitle.indexOf('Rs'),
        );
      }
    } catch (e) {
      console.log(e);
    }
    try {
      if (newtitle.includes('RS')) {
        newtitle = newtitle.substring(
          0,
          newtitle.indexOf('RS'),
        );
      }
    } catch (e) {
      console.log(e);
    }
    try {
      if (newtitle.includes(' at')) {
        newtitle = newtitle.substring(
          0,
          newtitle.indexOf(' at'),
        );
      }
    } catch (e) {
      console.log(e);
    }
    try {
      if (newtitle.includes(' At')) {
        newtitle = newtitle.substring(
          0,
          newtitle.indexOf(' At'),
        );
      }
    } catch (e) {
      console.log(e);
    }
    try {
      if (newtitle.includes(' AT')) {
        newtitle = newtitle.substring(
          0,
          newtitle.indexOf(' AT'),
        );
      }
    } catch (e) {
      console.log(e);
    }
    try {
      if (newtitle.includes('http')) {
        newtitle = newtitle.substring(
          0,
          newtitle.indexOf('http'),
        );
      }
    } catch (e) {
      console.log(e);
    }
    if (newtitle.includes('TTT')) {
      console.log(newtitle);
      newtitle.replaceAll('TTT', ' ');
      console.log(newtitle);
    }
  } catch (e) {
    console.log(e);
  }

  if(newtitle.length < 10){
    if(product?.category?.c5 != "" ){
      newtitle = product.category.c5
    }
    else if(product?.category?.c4 != "" ){
      newtitle = product.category.c4
    }
    else if(product?.category?.c3 != "" ){
      newtitle = product.category.c3
    }
    else if(product?.category?.c2 != "" ){
      newtitle = product.category.c2
    }
    else if(product?.category?.c1 != "" ){
      newtitle = product.category.c1
    }
  }

  if (tag == 'avinash') {
    path = `/p/${product.id}`;
    prd = prd1;
  } 
  else if (tag == 'bestselling') {
    path = `/best-deals/p/${product.id}`;
  } 
  else if (tag == 'loots') {
    path = `/hot-deals/p/${product.id}`;
  } 
  // else if (tag == 'loots') {
  //   path = `/p/hot-deals/${product.id}`;
  // } 
  else if (tag == 'items') {
    path = `/items/p/${product.id}`;
  } else if (tag == 'others') {
    path = `/deals/product/${product.id}`;
    prd = prd2;
    if (prd2 == '') {
      prd = prd1;
    }
  } else if (tag == 'deals') {
    path = `/offers/product/${product.id}`;
    prd = prd3;
    if (prd3 == '') {
      prd = prd1;
    }
  } else if (tag == 'avideals') {
    path = `/avideals/product/${product.id}`;
    prd = prd4;
    if (prd4 == '') {
      prd = prd1;
    }
  } else if (tag == 'avideals5') {
    path = `/avideals5/product/${product.id}`;
    prd = prd5;
    if (prd5 == '') {
      prd = prd1;
    }
  } else if (tag == 'avideals6') {
    path = `/avideals6/product/${product.id}`;
    prd = prd6;
    if (prd6 == '') {
      prd = prd1;
    }
  } else if (tag == 'avideals7') {
    path = `/avideals7/product/${product.id}`;
    prd = prd7;
    if (prd7 == '') {
      prd = prd1;
    }
  } else if (tag == 'avideals8') {
    path = `/avideals8/product/${product.id}`;
    prd = prd8;
    if (prd8 == '') {
      prd = prd1;
    }
  } else if (tag == 'avideals9') {
    path = `/avideals9/product/${product.id}`;
    prd = prd9;
    if (prd9 == '') {
      prd = prd1;
    }
  } else if (tag == 'avideals10') {
    path = `/avideals10/product/${product.id}`;
    prd = prd10;
    if (prd9 == '') {
      prd = prd1;
    }
  }

  if (source == 'internal') {
    path = `http://localhost:3000/product/${product.id}`;
  }

  if (discount > 80) {
    rating = 5;
  } else if (discount > 40) {
    rating = 4;
  } else if (discount > 20) {
    rating = 3;
  } else {
    rating = 2;
  }
  if (product.photo != '') {
    imag = product.photo;
  }
  prd = product.links.avinashbmv;
  price = product.price;
  discount = product.discount;
  // newtitle = product.urltext;

  if(product.datetime>startTimeStamp){
    debugger
    console.log("Short Link")
    prd = product?.links?.avinashbmv;
  }
  else{
    debugger
    console.log("Custom Link")
    prd=`https://amazon.in/dp/${product?.productCode}?tag=${tagid}`
  }

  // }

  // const navigate = useNavigate()

  const handleClick = () => {
    // Replace '/new-path' with the path you want to redirect to
    // navigate.push(`/product/${product.id}`);
    // history.push(`/product/${product.id}`);
    history.push(path);
  };
  const handleurl = () => {
    // Replace '/new-path' with the path you want to redirect to
    // navigate.push(`/product/${product.id}`);
    // history.push(`/product/${product.id}`);

    // window.open(product.links.avinashbmv, '_blank');
    window.open(prd, '_blank');
  };

  return (
    <Card 
    className="product-card" 
    sx={{ maxWidth: 300 }} 
    // sx={{ maxWidth: 345 }} 
    // onClick={handleClick}
    // sx={{ maxWidth: 500 }} 
    lg={{ maxwidth:200 }}
    >
      {/* <Link to={{ pathname: `/product/${product.id}` }}> */}
      <CardMedia
        // className={classes.media}
        className='product-card__gallery'
        component="img"
        // height="1994"
        // sx={{ height: 140 }}
        // lg={{ height: 2040 }}
        height="194"
        image={product.photo}
        onClick={handleClick}
        // image="/static/images/cards/contemplative-reptile.jpg"
        title={newtitle}
      />
      {/* </Link> */}
      <CardHeader
                    className="product-card__title"
                    title={newtitle}
                    onClick={handleClick}
                    // title={product.urltext}
                  ></CardHeader>
      <CardContent onClick={handleClick}>
        {/* <Typography gutterBottom variant="h5" component="div">
          {product.urltext}
        </Typography> */}

        {source == 'main' && product.price > 0 ? (
            <div className="product-card__price">
              {/* <div */}
              <CurrencyFormat
                className="product-card__amount"
                //  currencyCode={product.price.currency}
                currencyCode="INR"
                //  value={price>0?price:100}
                value={price}
                //  value="100"
              >
                {/* {price>0?price:null} */}
                {/* </div> */}
              </CurrencyFormat>
              <h3>{discount}%</h3>

              {/* {isDiscounted && (
                 
                 <CurrencyFormat
                 className="product-card__amount product-card__amount--discount"
                 currencyCode={product.price.currency}
                 //  value="50"
                   value={basePrice}
                   />
                   )} */}
            </div>
          ) : null}
        {/* <Typography className='product-card__amount' variant="body2" color="text.secondary">
            {product.price} */}
          {/* Lizards are a widespread group of squamate reptiles, with over 6,000
          species, ranging across all continents except Antarctica */}
        {/* </Typography> */}
      </CardContent>
      {/* <CardActions 
      // className='product-card__button'
      > */}
        {/* <Link to={{ pathname: product.links.avinashbmv }} target="_blank">

        <button size="large"
        //  onClick={product.links.avinashbmv}
         onClick={handleurl}
         >Share</button>
        </Link>
        <Button size="large">Learn More</Button> */}




   <div className='product-card__buttondiv'>

{prd != '' ? (
            <Link to={{ pathname: prd }} target="_blank">
              {product && product.storeType && product.storeType!="" ? (
                <Button 
                variant="contained"
            color="primary"
            onClick={handleurl}
                // className="add-to-cart-button" 
                style={{
                //   // color: 'crimson',
                  fontSize: 12,
                  lineHeight: 1,
                  alignContent: 'center',
                  fillOpacity: 'blue',
                }}
                >
                  View in {product.storeType}
                </Button>
                
              ) : product &&
                product.links &&
                product.links.avinashbmv &&
                product.links.avinashbmv.includes('amzn') ? (
                  <Button
            variant="contained"
            color="primary"
            onClick={handleurl}
            style={{
              // color: 'crimson',
              fontSize: 12,
              lineHeight: 1,
              alignContent: 'center',
              fillOpacity: 'blue',
            }}
          >
            Buy in Amazon
          </Button>
                // <button className="add-to-cart-button" style={{
                //   color: 'crimson',
                //   fontSize: 20,
                //   lineHeight: 1,
                //   alignContent: 'center',
                //   fillOpacity: 'blue',
                // }}>View in Amazon</button>
              ) : (
                <Button
            variant="contained"
            color="primary"
            onClick={handleurl}
            style={{
              //   // color: 'crimson',
                fontSize: 12,
                lineHeight: 1,
                alignContent: 'center',
                fillOpacity: 'blue',
              }}
          >
            Buy
          </Button>
                // <button className="add-to-cart-button" style={{
                //   color: 'crimson',
                //   fontSize: 20,
                //   lineHeight: 1,
                //   alignContent: 'center',
                //   fillOpacity: 'blue',
                // }}>View</button>
              )}

              {/* <button
                className="add-to-cart-button"
                style={{
                  color: 'crimson',
                  fontSize: 20,
                  lineHeight: 1,
                  alignContent: 'center',
                  fillOpacity: 'blue',
                }}
              >
                {btntext}
              </button> */}
              {/* <button className='add-to-cart-button' style={{ color: 'crimson', fontSize:20, lineHeight : 1 ,marginLeft: 100, fillOpacity:'blue'}}>{btntext}</button> */}
            </Link>
          ) : null}

</div>     
<br/>
      {/* </CardActions> */}
    </Card>
  );
}